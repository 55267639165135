import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import { SelectField } from '../../forms';

import styles from './DashboardFilter.module.scss';

export default function DashboardFilter({
	allPeriods,
	dashboardData,
	period,
	portfolios,
	setPeriod,
	setScrollDashboard,
}) {
	const [t] = useTranslation();
	const trackGTMEvent = useTrackGTMEvent();

	const periodOptions = [{
		label: t('dashboard.filter.options.day'),
		value: 'day',
	}, {
		label: t('dashboard.filter.options.week'),
		value: 'week',
	}, {
		label: t('dashboard.filter.options.month'),
		value: 'month',
	}, {
		label: t('dashboard.filter.options.threemonths'),
		value: '3_months',
	}, {
		label: t('dashboard.filter.options.sixmonths'),
		value: '6_months',
	}, {
		label: t('dashboard.filter.options.year'),
		value: 'year',
	}];
	const periodIndex = periodOptions.findIndex((p) => (p.value === (allPeriods ? 'year' : dashboardData.maxPeriod)));
	const options = [...periodOptions.slice(0, periodIndex + 1), {
		label: t('dashboard.filter.options.fromstart'),
		value: 'all',
	}];

	return (
		<div className={styles.root}>
			<div className={styles.title}>
				{t('dashboard.filter.title')}
			</div>
			<div className={styles.select}>
				<SelectField
					id="timeRange"
					label="timeRange"
					isLabelHidden
					options={options}
					name="name"
					onChange={(_, value) => {
						setPeriod(value);
						if (setScrollDashboard) {
							setScrollDashboard(true);
						}
						trackGTMEvent('interactions', {
							eventCategory: 'interactions',
							eventAction: 'buttonClick',
							eventLabel: portfolios
								? `change_period_of_growth_portfolios_${value}`
								: `change_period_of_growth_total_value_${value}`,
						});
					}}
					value={dashboardData.period ? dashboardData.period : period}
				/>
			</div>
		</div>
	);
}

DashboardFilter.propTypes = {
	allPeriods: PropTypes.bool,
	dashboardData: PropTypes.shape({
		maxPeriod: PropTypes.string,
		period: PropTypes.string,
	}).isRequired,
	period: PropTypes.string,
	portfolios: PropTypes.bool,
	setPeriod: PropTypes.func.isRequired,
	setScrollDashboard: PropTypes.func,
};

DashboardFilter.defaultProps = {
	allPeriods: false,
	period: '',
	portfolios: false,
	setScrollDashboard: null,
};
