import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { useUserContext, useUserReload } from '../../../contexts/UserContext';
import { fetchCountryNationality, fetchProfilePersonalData, saveProfilePersonalData } from '../../../utils/api';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import useFetchAuth from '../../../utils/useFetchAuth';
import useRequestAuth from '../../../utils/useRequestAuth';
import {
	checkPersonalIdentificationNumber,
	checkPlaceOfBirth,
	checkStreetName,
	checkStreetNumber,
	validatePersonalData,
} from '../../../utils/validators';
import {
	BankAccountField,
	CheckboxField,
	PostalCodeField,
	RadioList,
	SelectField,
	TextField,
} from '../../forms';
import { Col, Row } from '../../layout';
import {
	Alert,
	Button,
} from '../../ui';

import styles from './PersonalData.module.scss';

const useFetchProfilePersonalData = useFetchAuth(fetchProfilePersonalData);
const useFetchCountryNationality = useFetchAuth(fetchCountryNationality);

const initialValues = {
	bankAccount: '',
	bankAccountIBAN: '',
	bankAccountType: 'local',
	bankCode: '',
	bankPrefix: '',
	birthDateDay: '',
	birthDateMonth: '',
	birthDateYear: '',
	city: '',
	confirm: false,
	contactAddressCity: '',
	contactAddressPostalCode: '',
	contactAddressStreetName: '',
	contactAddressStreetNumber: '',
	name: 'user',
	nationality: null,
	personalIdentificationNumber: '',
	placeOfBirth: '',
	postalCode: '',
	streetName: '',
	streetNumber: '',
};

export default function PersonalData({ setCheckOnboarding }) {
	const [personalData, loading] = useFetchProfilePersonalData(null);
	const saveProfilePersonalDataAuth = useRequestAuth(saveProfilePersonalData);
	const [initValue, setInitValue] = useState(initialValues);
	const [error, setError] = useState(false);
	const [errorMajority, setErrorMajority] = useState(false);
	const [t] = useTranslation();
	const trackGTMEvent = useTrackGTMEvent();
	const [user] = useUserContext();
	const reloadUser = useUserReload();
	const country = useCountry();
	const language = useLanguage();
	const [nationality, loadingNationality] = useFetchCountryNationality(null, country, language);

	const userCountryNationality = () => {
		if (country === 'CZ') {
			return 'CZE';
		} if (country === 'PL') {
			return 'POL';
		} if (country === 'SK') {
			return 'SVK';
		}

		return null;
	};

	useEffect(() => {
		if (personalData && personalData.address?.status && personalData.address?.ocr) {
			setInitValue((prevState) => ({
				...prevState,
				streetName: personalData.address.ocr.streetName ?? '',
				streetNumber: personalData.address.ocr.streetNumber ?? '',
				city: personalData.address.ocr.city ?? '',
				postalCode: personalData.address.ocr.postalCode ?? '',
			}));
		}

		if (personalData && personalData.nationality) {
			setInitValue((prevState) => ({
				...prevState,
				nationality: personalData.nationality,
			}));
		} else {
			setInitValue((prevState) => ({
				...prevState,
				nationality: userCountryNationality(),
			}));
		}
	}, [personalData]);

	if (loading || loadingNationality) {
		return null;
	}

	const nameList = personalData && personalData.name && !personalData.name.matching ? [
		{
			label: `${personalData.name.ocr?.firstName} ${personalData.name.ocr?.lastName}`,
			value: 'ocr',
		},
		{
			label: `${personalData.name.user?.firstName} ${personalData.name.user?.lastName}`,
			value: 'user',
		},
	] : [];

	const isBirthDate = (user?.birth_date ?? null) === null;

	const nationalityOptions = nationality.map((item) => ({
		label: item.name,
		value: item.code,
	}));

	return (
		<div className={styles.root}>
			<Formik
				enableReinitialize
				initialValues={initValue}
				onSubmit={async (values, { setErrors }) => {
					const birthDate = `${values.birthDateYear}-${values.birthDateMonth}-${values.birthDateDay}`;
					const isTypeIBAN = values.bankAccountType === 'iban';
					const bankPrefix = !isTypeIBAN && country !== 'PL' && values.bankPrefix.length > 0 ? values.bankPrefix : null;
					const bankAccount = !isTypeIBAN ? values.bankAccount.replace(/\s+/g, '') : null;
					const bankCode = !isTypeIBAN && country !== 'PL' ? values.bankCode : null;
					const bankAccountIBAN = isTypeIBAN ? values.bankAccountIBAN.replace(/\s+/g, '') : null;
					setError(false);
					setErrorMajority(false);

					try {
						await saveProfilePersonalDataAuth(
							personalData?.name?.matching ? null : values.name,
							values.contactAddress ? values.contactAddress : null,
							values.streetName,
							values.streetNumber,
							values.city,
							values.postalCode,
							values.contactAddressStreetName,
							values.contactAddressStreetNumber,
							values.contactAddressCity,
							values.contactAddressPostalCode,
							isBirthDate ? birthDate : null,
							values.personalIdentificationNumber,
							values.nationality,
							values.placeOfBirth,
							bankPrefix,
							bankAccount,
							bankCode,
							bankAccountIBAN,
						);
					} catch (e) {
						const fieldErrors = {};
						const errorMessage = e.responseJson?.message;
						if (typeof errorMessage === 'string') {
							if (errorMessage.indexOf('Bank code') !== -1) {
								if (isTypeIBAN) {
									fieldErrors.bankAccountIBAN = 'forms.fields.bankAccount.invalidCode';
								} else {
									fieldErrors.bankCode = 'forms.fields.bankAccount.invalidCode';
								}
							} else if (errorMessage.indexOf('Failed assertion \'majority\'') !== -1) {
								setErrorMajority(true);
							}
						}

						const hasFieldErrors = Object.keys(fieldErrors).length > 0;
						if (hasFieldErrors) {
							setErrors(fieldErrors);
						}
						setError(!hasFieldErrors);
						return;
					}
					trackGTMEvent('conversionFunnel', {
						eventCategory: 'checkedPersonalInformation',
						eventAction: '',
						eventLabel: '',
					});
					setCheckOnboarding(true);
					reloadUser();
				}}
				validate={(values) => validatePersonalData(values, country, isBirthDate)}
			>
				{({
					errors,
					handleBlur,
					handleChange,
					handleSubmit,
					isSubmitting,
					setFieldValue,
					touched,
					values,
				}) => (
					<form onSubmit={handleSubmit}>
						{error && !errorMajority && (
							<Alert type="danger">
								{t('forms.error')}
							</Alert>
						)}
						{errorMajority && (
							<Alert type="danger">
								{t('forms.fields.birthDate.majority')}
							</Alert>
						)}
						{personalData && personalData.name && (
							<div className={styles.section}>
								<h3 className={styles.title}>{t('onboarding.steps.personalData.form.name.title')}</h3>
								{nameList && (
									<RadioList
										id="name"
										name="name"
										onChange={handleChange}
										options={nameList}
										value={values.name}
									/>
								)}
								<p className={styles.text}>
									{t('onboarding.steps.personalData.form.name.text')}
									{' '}
									{`${personalData?.name?.[values.name]?.firstName} ${personalData?.name?.[values.name]?.lastName}`}
								</p>
							</div>
						)}
						<div className={styles.section}>
							<h3 className={styles.title}>{t('onboarding.steps.personalData.form.address.title')}</h3>
							<Row>
								<Col xs={8}>
									<TextField
										error={
											errors.streetName
											&& touched.streetName
											&& t(errors.streetName)
										}
										id="streetName"
										label={t('forms.fields.streetName.label')}
										name="streetName"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										type="text"
										value={values.streetName}
										warning={
											touched.streetName
											&& !errors.streetName
											&& !checkStreetName(values.streetName, country)
												? t('forms.fields.streetName.warning')
												: ''
										}
									/>
								</Col>
								<Col xs={4}>
									<TextField
										error={
											errors.streetNumber
											&& touched.streetNumber
											&& t(errors.streetNumber)
										}
										id="streetNumber"
										label={t('forms.fields.streetNumber.label')}
										name="streetNumber"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										type="text"
										value={values.streetNumber}
										warning={
											touched.streetNumber
											&& !errors.streetNumber
											&& !checkStreetNumber(values.streetNumber, country)
												? t('forms.fields.streetNumber.warning')
												: ''
										}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={8}>
									<TextField
										error={
											errors.city
											&& touched.city
											&& t(errors.city)
										}
										id="city"
										label={t('forms.fields.city.label')}
										name="city"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										type="text"
										value={values.city}
									/>
								</Col>
								<Col xs={4}>
									<PostalCodeField
										country={country}
										error={
											errors.postalCode
											&& touched.postalCode
											&& t(errors.postalCode)
										}
										id="postalCode"
										label={t('forms.fields.postalCode.label')}
										name="postalCode"
										onBlur={handleBlur}
										onChange={setFieldValue}
										required
										type="text"
										value={values.postalCode}
									/>
								</Col>
							</Row>
							<CheckboxField
								checked={values.contactAddress}
								disabled={isSubmitting}
								id="contactAddress"
								label={t('onboarding.steps.personalData.form.contactAddress.add')}
								name="contactAddress"
								onChange={handleChange}
							/>
						</div>

						<div className={`${styles.section} ${!values.contactAddress ? styles.isHidden : ''}`.trim()}>
							<h3 className={styles.title}>{t('onboarding.steps.personalData.form.contactAddress.title')}</h3>
							<Row>
								<Col xs={8}>
									<TextField
										error={
											errors.contactAddressStreetName
											&& touched.contactAddressStreetName
											&& t(errors.contactAddressStreetName)
										}
										id="contactAddressStreetName"
										label={t('forms.fields.streetName.label')}
										name="contactAddressStreetName"
										onBlur={handleBlur}
										onChange={handleChange}
										required={values.contactAddress}
										type="text"
										value={values.contactAddressStreetName}
										warning={
											touched.contactAddressStreetName
											&& !errors.contactAddressStreetName
											&& !checkStreetName(values.contactAddressStreetName, country)
												? t('forms.fields.streetName.warning')
												: ''
										}
									/>
								</Col>
								<Col xs={4}>
									<TextField
										error={
											errors.contactAddressStreetNumber
											&& touched.contactAddressStreetNumber
											&& t(errors.contactAddressStreetNumber)
										}
										id="contactAddressStreetNumber"
										label={t('forms.fields.streetNumber.label')}
										name="contactAddressStreetNumber"
										onBlur={handleBlur}
										onChange={handleChange}
										required={values.contactAddress}
										type="text"
										value={values.contactAddressStreetNumber}
										warning={
											touched.contactAddressStreetNumber
											&& !errors.contactAddressStreetNumber
											&& !checkStreetNumber(values.contactAddressStreetNumber, country)
												? t('forms.fields.streetNumber.warning')
												: ''
										}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={8}>
									<TextField
										error={
											errors.contactAddressCity
											&& touched.contactAddressCity
											&& t(errors.contactAddressCity)
										}
										id="contactAddressCity"
										label={t('forms.fields.city.label')}
										name="contactAddressCity"
										onBlur={handleBlur}
										onChange={handleChange}
										required={values.contactAddress}
										type="text"
										value={values.contactAddressCity}
									/>
								</Col>
								<Col xs={4}>
									<PostalCodeField
										country={country}
										error={
											errors.contactAddressPostalCode
											&& touched.contactAddressPostalCode
											&& t(errors.contactAddressPostalCode)
										}
										id="contactAddressPostalCode"
										label={t('forms.fields.postalCode.label')}
										name="contactAddressPostalCode"
										onBlur={handleBlur}
										onChange={setFieldValue}
										required={values.contactAddress}
										type="text"
										value={values.contactAddressPostalCode}
									/>
								</Col>
							</Row>
						</div>

						{isBirthDate && (
							<div className={`${styles.section} ${styles.textLeft}`.trim()}>
								<h3 className={styles.title}>{t('onboarding.steps.personalData.form.birthDate.title')}</h3>
								<Row>
									<Col xs={4}>
										<TextField
											error={
												errors.birthDateDay
												&& touched.birthDateDay
												&& t(errors.birthDateDay)
											}
											id="birthDateDay"
											label={t('forms.fields.birthDate.day.label')}
											name="birthDateDay"
											onBlur={handleBlur}
											onChange={handleChange}
											required
											type="text"
											value={values.birthDateDay}
										/>
									</Col>
									<Col xs={4}>
										<TextField
											error={
												errors.birthDateMonth
												&& touched.birthDateMonth
												&& t(errors.birthDateMonth)
											}
											id="birthDateMonth"
											label={t('forms.fields.birthDate.month.label')}
											name="birthDateMonth"
											onBlur={handleBlur}
											onChange={handleChange}
											required
											type="text"
											value={values.birthDateMonth}
										/>
									</Col>
									<Col xs={4}>
										<TextField
											error={
												errors.birthDateYear
												&& touched.birthDateYear
												&& t(errors.birthDateYear)
											}
											id="birthDateYear"
											label={t('forms.fields.birthDate.year.label')}
											name="birthDateYear"
											onBlur={handleBlur}
											onChange={handleChange}
											required
											type="text"
											value={values.birthDateYear}
										/>
									</Col>
								</Row>
							</div>
						)}

						<div className={styles.section}>
							<h3 className={styles.title}>{t('onboarding.steps.personalData.form.placeOfBirth.title')}</h3>
							<TextField
								error={
									errors.placeOfBirth
									&& touched.placeOfBirth
									&& t(errors.placeOfBirth)
								}
								id="placeOfBirth"
								isLabelHidden
								label={t('forms.fields.placeOfBirth.label')}
								name="placeOfBirth"
								onBlur={handleBlur}
								onChange={handleChange}
								required
								type="text"
								value={values.placeOfBirth}
								warning={
									touched.placeOfBirth
									&& !errors.placeOfBirth
									&& !checkPlaceOfBirth(values.placeOfBirth)
										? t('forms.fields.placeOfBirth.warning')
										: ''
								}
							/>
						</div>

						<div className={`${styles.section} ${styles.textLeft}`.trim()}>
							<h3 className={styles.title}>{t('onboarding.steps.personalData.form.nationality.title')}</h3>
							<SelectField
								error={
									errors.nationality
									&& touched.nationality
									&& t(errors.nationality)
								}
								id="nationality"
								isLabelHidden
								label={t('forms.fields.nationality.label')}
								name="nationality"
								onChange={setFieldValue}
								options={nationalityOptions}
								required
								value={values.nationality}
							/>
						</div>

						<div className={styles.section}>
							<h3 className={styles.title}>{t('onboarding.steps.personalData.form.personalIdentificationNumber.title')}</h3>
							<p className={styles.text}>
								{t('onboarding.steps.personalData.form.personalIdentificationNumber.text')}
							</p>
							<TextField
								error={
									errors.personalIdentificationNumber
									&& touched.personalIdentificationNumber
									&& t(errors.personalIdentificationNumber)
								}
								id="personalIdentificationNumber"
								isLabelHidden
								label={t('forms.fields.personalIdentificationNumber.label')}
								name="personalIdentificationNumber"
								onBlur={handleBlur}
								onChange={handleChange}
								required
								type="text"
								value={values.personalIdentificationNumber}
								warning={
									touched.personalIdentificationNumber
									&& !errors.personalIdentificationNumber
									&& !checkPersonalIdentificationNumber(values.personalIdentificationNumber, country)
										? t('forms.fields.personalIdentificationNumber.warning')
										: ''
								}
							/>
						</div>

						<div className={styles.section}>
							<h3 className={styles.title}>{t('onboarding.steps.personalData.form.bankAccount.title')}</h3>
							<div className={styles.warning}>
								<svg
									height={18}
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 576 512"
								>
									<path
										fill="currentColor"
										d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
									/>
								</svg>
								{t('onboarding.steps.personalData.form.bankAccount.text')}
							</div>
							<p className={`${styles.warning} ${styles.textRed}`.trim()}>
								<Trans i18nKey="onboarding.steps.personalData.form.bankAccount.warning">
									<strong />
								</Trans>
							</p>
							<p className={styles.warning}>
								<Trans i18nKey="onboarding.steps.personalData.form.bankAccount.ibanText">
									<strong />
								</Trans>
							</p>
							<BankAccountField
								country={country}
								errorBankAccount={(
									errors.bankPrefix
									&& touched.bankPrefix
									&& t(errors.bankPrefix)
								) || (
									errors.bankAccount
									&& touched.bankAccount
									&& t(errors.bankAccount)
								) || (
									errors.bankCode
									&& touched.bankCode
									&& t(errors.bankCode)
								)}
								errorBankAccountIBAN={
									errors.bankAccountIBAN
									&& touched.bankAccountIBAN
									&& t(errors.bankAccountIBAN)
								}
								idAccount="bankAccount"
								idAccountIBAN="bankAccountIBAN"
								idAccountType="bankAccountType"
								idCode="bankCode"
								idPrefix="bankPrefix"
								isLabelHidden
								label={t('forms.fields.bankAccount.label')}
								nameAccount="bankAccount"
								nameAccountIBAN="bankAccountIBAN"
								nameAccountType="bankAccountType"
								nameCode="bankCode"
								namePrefix="bankPrefix"
								onBlur={handleBlur}
								onChange={setFieldValue}
								required
								valueAccount={values.bankAccount}
								valueAccountIBAN={values.bankAccountIBAN}
								valueAccountType={values.bankAccountType}
								valueCode={values.bankCode}
								valuePrefix={values.bankPrefix}
								warning={
									touched.bankAccountIBAN
									&& !errors.bankAccountIBAN
									&& values.bankAccountType === 'iban'
									&& values.bankAccountIBAN
									&& values.bankAccountIBAN.length !== (country === 'PL' ? 28 : 24)
										? t('forms.fields.bankAccountIBAN.warning')
										: ''
								}
							/>
						</div>
						<div className={styles.confirm}>
							<CheckboxField
								checked={values.confirm}
								error={
									errors.confirm
									&& touched.confirm
									&& t(errors.confirm)
								}
								id="confirm"
								label={t('onboarding.steps.personalData.form.confirm.label')}
								name="confirm"
								onBlur={handleBlur}
								onChange={handleChange}
								required
							/>

							<CheckboxField
								checked={values.confirmAddress}
								error={
									errors.confirm
									&& touched.confirm
									&& t(errors.confirm)
								}
								id="confirmAddress"
								label={t('onboarding.steps.personalData.form.contactAddress.confirm')}
								name="confirmAddress"
								onBlur={handleBlur}
								onChange={handleChange}
								required
							/>
						</div>
						<div className={styles.section}>
							<Button
								disabled={isSubmitting}
								isSubmit
								label={t('onboarding.steps.personalData.form.submit')}
							/>
						</div>
					</form>
				)}
			</Formik>
		</div>
	);
}

PersonalData.propTypes = {
	setCheckOnboarding: PropTypes.func.isRequired,
};
