import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import {
	Col,
	Container,
	Row,
	Section,
} from '../../layout';

import styles from './FooterLegal.module.scss';

const HeartIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="12" height="12">
		<path
			fill="currentColor"
			d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"
		/>
	</svg>
);

export default function FooterLegal({
	investing,
	short,
}) {
	const [t] = useTranslation();
	return (
		<Section variant="gray-extra-light" condensed={short}>
			<Container center>
				<Row center>
					<Col lg={6}>
						<p className={styles.text}>
							{t('footer.secondary.info')}
							{investing && t('footer.secondary.investing')}
						</p>
						{!short && (
							<>
								<address className={`${styles.text} ${styles.textSpacy}`}>
									<Trans i18nKey="footer.secondary.address">
										<br />
									</Trans>
								</address>
								<p className={styles.text}>
									{t('footer.secondary.legal')}
								</p>
							</>
						)}
						<p className={styles.text}>
							<Trans i18nKey="footer.secondary.love">
								<HeartIcon />
							</Trans>
						</p>
					</Col>
				</Row>
			</Container>
		</Section>
	);
}

FooterLegal.propTypes = {
	investing: PropTypes.bool,
	short: PropTypes.bool,
};

FooterLegal.defaultProps = {
	investing: false,
	short: false,
};
