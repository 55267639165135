import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import styles from './BlogPostList.module.scss';

export default function BlogPostList({
	list,
}) {
	return (
		<ul className={styles.list}>
			{Object.keys(list).map((item) => (
				<li key={item} className={styles.item}>
					{typeof list[item] === 'string' ? (
						<Trans i18nKey={list[item]} components={[<strong />]} />
					) : (
						<>
							<Trans i18nKey={list[item][0]} components={[<strong />]} />
							<ul className={styles.list}>
								{Object.keys(list[item]).map((nestedItem, i) => i !== 0 && (
									<li key={nestedItem} className={styles.item}>
										<Trans i18nKey={list[item][nestedItem]} components={[<strong />]} />
									</li>
								))}
							</ul>
						</>
					)}
				</li>
			))}
		</ul>
	);
}

BlogPostList.propTypes = {
	list: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.arrayOf(
				PropTypes.string,
			),
		]),
	),
};

BlogPostList.defaultProps = {
	list: null,
};
