import React from 'react';
import { Route } from 'react-router-dom';

export default function childRoute(key, route, element) {
	if (route === null) {
		return null;
	}

	return (
		<Route
			key={key}
			element={element}
			index={route === ''}
			path={route !== '' ? route : undefined}
		/>
	);
}
