import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { TextField } from '../../components/forms';
import { FormLayout } from '../../components/layout';
import { Alert, Button } from '../../components/ui';
import { useCountry, useLanguage } from '../../contexts/LocaleContext';
import { useUserLogin } from '../../contexts/UserContext';
import { twoFactorSocialLogin } from '../../utils/api';

import styles from './LoginSocial.module.scss';

export default function LoginSocial({
	dashboardUrl,
	social,
}) {
	const [t] = useTranslation();
	const [error, setError] = useState(null);
	const country = useCountry();
	const language = useLanguage();
	const handleLogin = useUserLogin();

	return (
		<FormLayout title={t('login.social.twoFactorTitle')}>
			<Formik
				initialValues={{ twoFactorCode: '' }}
				onSubmit={async (values, { setErrors }) => {
					setError(null);
					try {
						await twoFactorSocialLogin(
							country,
							language,
							values.twoFactorCode,
							social,
						);
					} catch (e) {
						const fieldErrors = {};
						const errorMessage = e.responseJson?.message;

						if (typeof errorMessage === 'string' && errorMessage.indexOf('Bad two factor authentication code') !== -1) {
							fieldErrors.twoFactorCode = 'forms.twoFactorAuthentication.error';
						}

						const hasFieldErrors = Object.keys(fieldErrors).length > 0;
						if (hasFieldErrors) {
							setErrors(fieldErrors);
						} else {
							const formError = 'forms.error';
							setError(formError);
						}
						return;
					}

					handleLogin(dashboardUrl || null);
				}}
			>
				{({
					errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values,
				}) => (
					<form
						className={styles.form}
						onSubmit={handleSubmit}
					>
						{error !== null && (
							<Alert type="danger">
								{t(error)}
							</Alert>
						)}
						<div className={styles.content}>
							<p className={styles.text}>
								{t('forms.twoFactorAuthentication.text')}
							</p>
							<TextField
								autocomplete="one-time-code"
								onBlur={handleBlur}
								onChange={handleChange}
								error={
									errors.twoFactorCode
									&& touched.twoFactorCode
									&& t(errors.twoFactorCode)
								}
								id="twoFactorCode"
								name="twoFactorCode"
								label={t('forms.twoFactorAuthentication.text')}
								isLabelHidden
								type="text"
								inputmode="numeric"
								pattern="[0-9]*"
								value={values.twoFactorCode}
								autoFocus
								required
							/>
							<Button
								label={t('forms.twoFactorAuthentication.button')}
								disabled={isSubmitting}
								isSubmit
							/>
						</div>
					</form>
				)}
			</Formik>
		</FormLayout>
	);
}

LoginSocial.propTypes = {
	dashboardUrl: PropTypes.string.isRequired,
	social: PropTypes.string.isRequired,
};
