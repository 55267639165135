import React from 'react';
import PropTypes from 'prop-types';

import styles from './BlogPostBox.module.scss';

export default function BlogPostBox({
	children,
	info,
}) {
	return (
		<div className={`${styles.root} ${info ? styles.info : ''}`.trim()}>
			{info && (
				<div className={styles.icon}>
					<svg xmlns="http://www.w3.org/2000/svg" width="51" height="51">
						<path fill="currentColor" d="M27.6 18h-5v-5h5zm0 20h-5V23h5zM25 .4a25 25 0 1 0 0 50.1A25 25 0 0 0 25 .4z" />
					</svg>
				</div>
			)}
			{children}
		</div>
	);
}

BlogPostBox.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	info: PropTypes.bool,
};

BlogPostBox.defaultProps = {
	info: false,
};
