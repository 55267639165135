import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { TextField } from '../../../components/forms';
import { Alert, Button, Modal } from '../../../components/ui';
import { changePassword } from '../../../utils/api';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import useRequestAuth from '../../../utils/useRequestAuth';
import { validateChangePassword } from '../../../utils/validators';

import styles from './PasswordChange.module.scss';

const initialValues = {
	currentPassword: '',
	newPassword: '',
	checkNewPassword: '',
};

export default function PasswordChange() {
	const changePasswordAuth = useRequestAuth(changePassword);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);
	const [t] = useTranslation();
	const trackGTMEvent = useTrackGTMEvent();

	useEffect(() => {
		setSuccess(false);
		setError(false);
	}, [isModalOpen]);

	return (
		<div className={styles.root}>
			<Button
				onClick={() => {
					trackGTMEvent('interactions', {
						eventCategory: 'interactions',
						eventAction: 'buttonClick',
						eventLabel: 'zmenit_heslo_button_click',
					});
					setModalOpen(true);
				}}
				label={t('account.passwordChange.button')}
				icon={(
					<svg viewBox="0 0 980 877" width="25" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
						<path d="M570.16 876.011c110.66-9.32 205.45-52.55 281.61-128.71 132.47-132.08 165.98-335.55 83.28-502.53-65.64-131.88-187.41-220.33-333.17-241.35-28.36-4.17-90.63-4.56-116.02-.99-52.16 7.54-95.98 20.82-140.01 42.64-42.44 21.22-72.78 42.04-105.9 73.18-12.49 11.7-13.88 13.68-13.88 20.82 0 9.52 6.35 16.26 15.47 16.26 4.96 0 9.32-2.78 20.43-12.89 112.64-103.32 265.15-136.64 407.94-89.24 62.47 20.82 116.21 54.93 163.02 103.52 33.51 34.71 54.93 65.44 75.56 108.68 26.37 55.72 38.27 109.66 38.07 173.52 0 65.64-11.9 116.81-40.26 175.51-22.41 46.41-45.81 78.73-82.7 114.23-45.41 43.63-93.01 73.18-149.53 92.61-148.34 50.97-310.17 13.88-420.43-96.58-68.02-68.42-107.88-152.7-117.8-250.07l-1.39-12.89 38.48 38.27c35.1 34.71 39.07 38.08 45.41 38.08 13.49 0 21.02-11.7 14.87-23.2-1.59-2.97-26.38-28.95-55.13-57.51-50.57-50.37-52.75-52.16-60.29-52.16-7.34 0-9.52 1.79-62.67 55.13C6.74 508.731 0 516.071 0 521.421c0 9.52 6.74 16.66 15.47 16.66 6.74-.2 10.31-2.97 45.81-37.68 21.22-20.82 39.27-37.68 39.86-37.68.79 0 1.98 8.13 2.58 18.05 4.17 57.91 30.14 134.66 64.45 189.79 67.63 108.87 181.26 182.64 310.17 201.68 21.61 3.17 73.57 5.16 91.82 3.77z" fillRule="nonzero" />
						<path d="M555.48 203.92c30.14 2.78 58.7 16.46 80.91 39.27 28.36 28.56 39.86 60.49 39.86 110.26v18.64h14.68c12.1 0 16.46.99 25.19 5.55 11.7 5.95 22.01 18.84 25.58 31.73 1.19 4.56 1.98 42.04 1.98 96.78 0 98.76-.4 102.33-12.3 116.61-3.37 4.16-10.71 9.72-16.26 12.29l-10.11 4.76h-329.4l-10.51-5.16c-11.11-5.55-20.03-15.87-24.39-28.16-1.98-5.55-2.58-29.15-2.58-101.54 0-91.42.2-94.4 4.16-103.12 4.76-10.11 14.08-20.43 23.4-25.19 3.57-1.98 13.09-3.77 23-4.56l16.86-.99 1.19-30.74c1.39-33.71 3.77-44.22 14.87-65.44 21.02-40.06 60.68-67.03 104.91-71.19 7.54-.59 20.63-.59 28.96.2zm-34.11 33.32c-18.05 3.37-38.27 14.48-52.36 28.36-22.01 22.01-30.74 46.01-30.74 85.08v21.41h204.27v-22.81c0-26.57-2.18-39.27-10.31-55.93-19.24-41.04-65.84-64.64-110.86-56.11zm-141 167.58l-9.52 11.1v180.66l11.5 11.5h315.32l6.15-5.15 6.15-5.16V415.33l-5.75-5.75-5.95-5.75-158.85.4-159.05.59z" fillRule="nonzero" />
					</svg>
				)}
				outline
			/>
			<Modal
				isVisible={isModalOpen}
				onClose={() => setModalOpen(false)}
				size="large"
				title={t('account.passwordChange.title')}
			>
				<div className={styles.modal}>
					<Formik
						enableReinitialize
						initialValues={initialValues}
						validate={validateChangePassword}
						onSubmit={async (values, { setErrors, setFieldValue }) => {
							setError(false);
							setSuccess(false);
							try {
								await changePasswordAuth(
									values.currentPassword,
									values.newPassword,
									values.checkNewPassword,
								);
							} catch (e) {
								const fieldErrors = {};
								const errorMessage = e.responseJson?.message;
								if (typeof errorMessage === 'string') {
									if (errorMessage.indexOf('current password is incorrect') !== -1) {
										fieldErrors.currentPassword = 'forms.fields.password.incorrect';
									}
								}

								const hasFieldErrors = Object.keys(fieldErrors).length > 0;
								if (hasFieldErrors) {
									setErrors(fieldErrors);
								}
								setError(!hasFieldErrors);
								return;
							}
							setFieldValue('currentPassword', '', false);
							setFieldValue('newPassword', '', false);
							setFieldValue('checkNewPassword', '', false);

							setSuccess(true);
						}}
					>
						{({
							errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values,
						}) => (
							<form onSubmit={handleSubmit}>
								{error && (
									<Alert type="danger">
										{t('forms.error')}
									</Alert>
								)}
								{success && (
									<Alert type="success">
										{t('account.passwordChange.success')}
									</Alert>
								)}
								<TextField
									autoComplete="current-password"
									onBlur={handleBlur}
									onChange={handleChange}
									error={
										errors.currentPassword
										&& touched.currentPassword
										&& t(errors.currentPassword)
									}
									id="currentPassword"
									name="currentPassword"
									label={t('forms.fields.oldPassword.label')}
									required
									type="password"
									value={values.currentPassword}
								/>
								<TextField
									autoComplete="new-password"
									onBlur={handleBlur}
									onChange={handleChange}
									error={
										errors.newPassword
										&& touched.newPassword
										&& t(errors.newPassword)
									}
									helper={t('forms.fields.password.helper')}
									id="newPassword"
									name="newPassword"
									label={t('forms.fields.newPassword.label')}
									required
									type="password"
									value={values.newPassword}
								/>
								<TextField
									autoComplete="new-password"
									onBlur={handleBlur}
									onChange={handleChange}
									error={
										errors.checkNewPassword
										&& touched.checkNewPassword
										&& t(errors.checkNewPassword)
									}
									id="checkNewPassword"
									name="checkNewPassword"
									label={t('forms.fields.passwordConfirm.label')}
									required
									type="password"
									value={values.checkNewPassword}
								/>
								<Button
									label={t('forms.save')}
									disabled={isSubmitting}
									isSubmit
								/>
							</form>
						)}
					</Formik>
				</div>
			</Modal>
		</div>
	);
}
