import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { fetchInvestorProfile, fetchOurApproach } from '../../../utils/api';
import useFetch from '../../../utils/useFetch';
import useFormatNumber from '../../../utils/useFormatNumber';
import { Col, Row } from '../../layout';
import { ExternalLink } from '../../ui';
import PortfolioOverviewGrowth from '../PortfolioOverview/PortfolioOverviewGrowth';
import DashboardChart from '../DashboardChart';
import DashboardFilter from '../DashboardFilter';
import InvestorProfileChart from '../InvestorProfileChart';

import styles from './TransparentAccount.module.scss';

const useFetchOurApproach = useFetch(fetchOurApproach);
const useFetchInvestorProfile = useFetch(fetchInvestorProfile);

export default function TransparentAccount({
	howItWorksUrl,
	noRoot,
}) {
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const [period, setPeriod] = useState('all');
	const [data, loading, error] = useFetchOurApproach({}, country, language, period);
	const [investorProfiles] = useFetchInvestorProfile({}, country, language);
	const formatCurrency = useFormatNumber({ style: 'currency', currency: data.currency?.code });

	if (error || loading) return null;

	const investorProfile = data.investorProfile && investorProfiles.investorProfiles
		? investorProfiles.investorProfiles.filter(({ name }) => name === data.investorProfile.name)[0] ?? null
		: null;

	return (
		<div className={!noRoot ? styles.root : ''}>
			<div className={styles.head}>
				<Row>
					<Col lg={8} xl={7}>
						<p className={styles.pretitle}>{t('transparentAccount.pretitle')}</p>
						<h2 className={styles.sum}>
							{formatCurrency(data.totalValue ?? null)}
						</h2>
						<Row>
							{data !== null && (
								<Col md={12}>
									<DashboardFilter
										allPeriods
										dashboardData={data}
										period={period}
										setPeriod={setPeriod}
									/>
								</Col>
							)}
							<Col md={6}>
								<div className={styles.growthLabel}>{t('transparentAccount.netGrowth')}</div>
								<div className={styles.growthValue}>
									<PortfolioOverviewGrowth
										currency={data.currency ?? null}
										value={data.netGrowth ?? null}
									/>
								</div>
							</Col>
							<Col md={6}>
								<div className={styles.growthLabel}>{t('transparentAccount.netGrowthRatio')}</div>
								<div className={styles.growthValue}>
									<PortfolioOverviewGrowth
										value={data.netGrowthRatio ?? null}
										ratio
									/>
								</div>
							</Col>
						</Row>
					</Col>
					<Col lg={4} xl={5}>
						<div className={styles.donut}>
							{investorProfile && investorProfiles.productCategories && (
								<InvestorProfileChart
									onlyChart
									profile={investorProfile}
									categories={investorProfiles.productCategories}
								/>
							)}
						</div>
					</Col>
				</Row>
			</div>
			<div className={styles.info}>
				<Row middle>
					<Col lg={5} xl={4}>
						{data.investorProfile && (
							<div className={styles.infoProfile}>
								<img
									src={`${process.env.PUBLIC_URL}/images/faces/${data.investorProfile.faceImageFile}`}
									width={75}
									height={75}
									alt={data.investorProfile.name}
								/>
								<div>
									<div className={styles.infoLabel}>
										{t('transparentAccount.type')}
									</div>
									<div className={styles.infoValue}>
										{data.investorProfile.name}
									</div>
								</div>
							</div>
						)}
					</Col>
					<Col lg={3}>
						<div className={styles.infoLabel}>
							{t('transparentAccount.initialDeposit')}
						</div>
						<div className={styles.infoValue}>
							{formatCurrency(data.initialDeposit ?? null)}
						</div>
					</Col>
					<Col lg={3}>
						<div className={styles.infoLabel}>
							{t('transparentAccount.monthlyDeposit')}
						</div>
						<div className={styles.infoValue}>
							{formatCurrency(data.monthlyDeposit ?? null)}
						</div>
					</Col>
					<Col lg={1} xl={2} />
				</Row>
			</div>
			{data !== null && (
				<DashboardChart
					currency={data.currency ?? null}
					data={data.valueGraph ?? null}
					evaluation={false}
				/>
			)}
			{t('transparentAccount.note') !== '' && (
				<p className={styles.note}>{t('transparentAccount.note')}</p>
			)}
			<p className={styles.legendNote}>
				<Trans i18nKey="productAccount.note">
					<ExternalLink
						to={howItWorksUrl}
						blank
					/>
				</Trans>
			</p>
		</div>
	);
}

TransparentAccount.propTypes = {
	howItWorksUrl: PropTypes.string.isRequired,
	noRoot: PropTypes.bool,
};

TransparentAccount.defaultProps = {
	noRoot: false,
};
