import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Col, Row } from '../../layout';
import TextField from '../TextField';

import styles from './BankAccountField.module.scss';

export default function BankAccountField({
	country,
	disabled,
	errorBankAccount,
	errorBankAccountIBAN,
	helper,
	idAccount,
	idAccountIBAN,
	idAccountType,
	idCode,
	idPrefix,
	isLabelHidden,
	label,
	nameAccount,
	nameAccountIBAN,
	nameAccountType,
	nameCode,
	namePrefix,
	onBlur,
	onChange,
	readonly,
	required,
	valueAccount,
	valueAccountIBAN,
	valueAccountType,
	valueCode,
	valuePrefix,
	warning,
}) {
	const [t] = useTranslation();
	const isTypeLocal = valueAccountType === 'local';
	const isTypeIBAN = valueAccountType === 'iban';

	const handleRadioChange = (e) => {
		if (onChange !== null && e.currentTarget.checked) {
			onChange(e.currentTarget.name, e.currentTarget.value);
		}
	};

	const handleTextChange = (e) => {
		if (onChange !== null) {
			const input = e.currentTarget;
			const selectionStart = input.selectionStart ?? input.value.length;
			const selectionEnd = input.selectionEnd ?? input.value.length;
			const { value } = input;
			onChange(e.currentTarget.name, value.replace(/\s/g, ''));

			Promise.resolve().then(() => {
				input.setSelectionRange(
					value.substring(0, selectionStart).replace(/\s/g, '').length,
					value.substring(0, selectionEnd).replace(/\s/g, '').length,
				);
			});
		}
	};

	const handlePaste = (e) => {
		if (onChange !== null) {
			e.preventDefault();

			const input = e.currentTarget;
			const selectionStart = input.selectionStart ?? input.value.length;
			const selectionEnd = input.selectionEnd ?? input.value.length;
			const maxLength = input.maxLength ?? null;

			const prefix = input.value.substring(0, selectionStart).replace(/\s/g, '');
			const suffix = input.value.substring(selectionEnd).replace(/\s/g, '');
			const pasteText = e.clipboardData.getData('text').replace(/\s/g, '')
				.substring(0, maxLength !== null ? maxLength - prefix.length - suffix.length : undefined);
			onChange(input.name, `${prefix}${pasteText}${suffix}`);

			Promise.resolve().then(() => {
				const selection = `${prefix}${pasteText}`.length;
				input.setSelectionRange(selection, selection);
			});
		}
	};

	const handleMask = (e) => {
		if (onChange !== null) {
			const mask = t('forms.fields.bankAccount.accountMask');
			const input = e.currentTarget;
			const prevValue = input.value;
			const { value } = input;
			const prevCursorPos = input.selectionStart ?? value.length;
			const isDeleting = prevValue.length > value.length;
			const cursorOffset = isDeleting ? prevValue.length - value.length : 0;
			let maskRegEx = '';
			let newValue = [];
			let cursorPosition = 0;

			mask.split(' ').forEach((item) => {
				maskRegEx += `(\\d{0,${item.length}})`;
			});

			const inputMasked = value.replace(/\D/g, '').match(new RegExp(maskRegEx));

			inputMasked.forEach((item, index) => {
				if (index > 0 && item) {
					newValue = [...newValue, item];
					cursorPosition += item.length;
					if (index < inputMasked.length - 1) {
						cursorPosition += 1;
					}
				}
			});

			if (isDeleting) {
				cursorPosition = Math.max(0, prevCursorPos - cursorOffset);
			} else {
				const lengthDifference = value.length - prevValue.length;
				cursorPosition = prevCursorPos + lengthDifference;
			}

			onChange(input.name, newValue.join(' '));

			Promise.resolve().then(() => {
				input.setSelectionRange(cursorPosition, cursorPosition);
			});
		}
	};

	return (
		<div className={`${styles.root} ${(errorBankAccount || errorBankAccountIBAN) && styles.rootError}`.trim()}>
			<div className={styles.toggle}>
				<label
					className={`${styles.toggleItem} ${isTypeLocal ? styles.toggleItemActive : ''}`.trim()}
					htmlFor={`${idAccountType}-local`}
				>
					<input
						checked={isTypeLocal}
						className={styles.toggleItemInput}
						id={`${idAccountType}-local`}
						name={nameAccountType}
						onChange={handleRadioChange}
						type="radio"
						value="local"
					/>
					{t('forms.fields.toggle.local')}
				</label>
				<label
					className={`${styles.toggleItem} ${isTypeIBAN ? styles.toggleItemActive : ''}`.trim()}
					htmlFor={`${idAccountType}-iban`}
				>
					<input
						checked={isTypeIBAN}
						className={styles.toggleItemInput}
						id={`${idAccountType}-iban`}
						name={nameAccountType}
						onChange={handleRadioChange}
						type="radio"
						value="iban"
					/>
					{t('forms.fields.toggle.iban')}
				</label>
				<div
					className={`${styles.toggleActive} ${isTypeIBAN ? styles.toggleActiveIban : ''}`.trim()}
				/>
			</div>
			<div className={`${styles.labelWrap} ${isLabelHidden ? styles.labelWrapHidden : ''}`.trim()}>
				{!isTypeIBAN && <div className={styles.label}>{label}</div>}
				{helper && <div className={styles.helper}>{helper}</div>}
			</div>
			{isTypeIBAN ? (
				<TextField
					disabled={disabled}
					id={idAccountIBAN}
					label={t('forms.fields.bankAccountIBAN.label')}
					maxLength={34}
					name={nameAccountIBAN}
					noError
					onBlur={onBlur}
					onChange={handleTextChange}
					onPaste={handlePaste}
					pattern="[A-Za-z]{2}[0-9]{2}[A-Za-z0-9]{8,30}"
					readonly={readonly}
					required={required}
					type="text"
					value={valueAccountIBAN}
				/>
			) : (
				<>
					{country === 'PL' ? (
						<TextField
							disabled={disabled}
							id={idAccount}
							label={t('forms.fields.bankAccount.labelAccount')}
							maxLength={32}
							minLength={32}
							name={nameAccount}
							noError
							onBlur={onBlur}
							onChange={handleMask}
							onPaste={handleMask}
							pattern="[0-9\s]+"
							readonly={readonly}
							required={required}
							type="text"
							value={valueAccount}
						/>
					) : (
						<div className={styles.inputWrap}>
							<Row>
								<Col xs={3}>
									<TextField
										disabled={disabled}
										id={idPrefix}
										label={t('forms.fields.bankAccount.labelPrefix')}
										maxLength={6}
										name={namePrefix}
										noError
										onBlur={onBlur}
										onChange={handleTextChange}
										onPaste={handlePaste}
										pattern="[0-9]+"
										readonly={readonly}
										type="text"
										value={valuePrefix}
									/>
								</Col>
								<Col xs={6}>
									<TextField
										disabled={disabled}
										id={idAccount}
										label={t('forms.fields.bankAccount.labelAccount')}
										maxLength={10}
										name={nameAccount}
										noError
										onBlur={onBlur}
										onChange={handleTextChange}
										onPaste={handlePaste}
										pattern="[0-9]+"
										readonly={readonly}
										required={required}
										type="text"
										value={valueAccount}
									/>
								</Col>
								<Col xs={3}>
									<TextField
										disabled={disabled}
										id={idCode}
										label={t('forms.fields.bankAccount.labelCode')}
										maxLength={4}
										minLength={4}
										name={nameCode}
										noError
										onBlur={onBlur}
										onChange={handleTextChange}
										onPaste={handlePaste}
										pattern="[0-9]+"
										readonly={readonly}
										required={required}
										type="text"
										value={valueCode}
									/>
								</Col>
							</Row>
						</div>
					)}
				</>
			)}
			{errorBankAccount && !isTypeIBAN && (
				<div className={styles.error}>{errorBankAccount}</div>
			)}
			{errorBankAccountIBAN && isTypeIBAN && (
				<div className={styles.error}>{errorBankAccountIBAN}</div>
			)}
			{!errorBankAccount && !errorBankAccountIBAN && isTypeIBAN && warning && (
				<div className={styles.warning}>{warning}</div>
			)}
			{country === 'PL' && !errorBankAccount && !errorBankAccountIBAN && !isTypeIBAN && warning && (
				<div className={styles.warning}>{warning}</div>
			)}
		</div>
	);
}

BankAccountField.propTypes = {
	country: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	errorBankAccount: PropTypes.string,
	errorBankAccountIBAN: PropTypes.string,
	helper: PropTypes.string,
	idPrefix: PropTypes.string.isRequired,
	idAccount: PropTypes.string.isRequired,
	idAccountIBAN: PropTypes.string.isRequired,
	idAccountType: PropTypes.string.isRequired,
	idCode: PropTypes.string.isRequired,
	isLabelHidden: PropTypes.bool,
	label: PropTypes.string.isRequired,
	namePrefix: PropTypes.string.isRequired,
	nameAccount: PropTypes.string.isRequired,
	nameAccountIBAN: PropTypes.string.isRequired,
	nameAccountType: PropTypes.string.isRequired,
	nameCode: PropTypes.string.isRequired,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	readonly: PropTypes.bool,
	required: PropTypes.bool,
	valuePrefix: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	valueAccount: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	valueAccountIBAN: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	valueAccountType: PropTypes.oneOf(['local', 'iban']),
	valueCode: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	warning: PropTypes.string,
};

BankAccountField.defaultProps = {
	disabled: false,
	errorBankAccount: '',
	errorBankAccountIBAN: '',
	helper: '',
	isLabelHidden: false,
	onBlur: null,
	onChange: null,
	readonly: false,
	required: false,
	valuePrefix: undefined,
	valueAccount: undefined,
	valueAccountIBAN: undefined,
	valueAccountType: undefined,
	valueCode: undefined,
	warning: '',
};
