import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import useFocusOnError from '../../../utils/useFocusOnError';

import styles from './DateField.module.scss';

const CalendarIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24}>
		<path fill="currentColor" d="M17.5 7H17v-.5c0-.275-.225-.5-.5-.5h-1c-.275 0-.5.225-.5.5V7h-5v-.5c0-.275-.225-.5-.5-.5h-1c-.275 0-.5.225-.5.5V7h-.5c-.275 0-.5.225-.5.5V9h11V7.5c0-.275-.225-.5-.5-.5zM7 17.5c0 .275.225.5.5.5h10c.275 0 .5-.225.5-.5V10H7v7.5zm9-5.5h1v1h-1v-1zm0 2h1v1h-1v-1zm0 2h1v1h-1v-1zm-2-4h1v1h-1v-1zm0 2h1v1h-1v-1zm0 2h1v1h-1v-1zm-2-4h1v1h-1v-1zm0 2h1v1h-1v-1zm0 2h1v1h-1v-1zm-2-4h1v1h-1v-1zm0 2h1v1h-1v-1zm0 2h1v1h-1v-1zm-2-4h1v1H8v-1zm0 2h1v1H8v-1zm0 2h1v1H8v-1z" />
	</svg>
);

export default function DateField({
	disabled,
	id,
	isLabelHidden,
	label,
	maxDate,
	minDate,
	name,
	onChange,
	required,
	value,
}) {
	const country = useCountry();
	const language = useLanguage();

	const elementRef = useRef();
	useFocusOnError(name, null, elementRef);

	return (
		<div className={styles.root}>
			<div className={`${styles.labelWrap} ${isLabelHidden ? styles.labelWrapHidden : ''}`.trim()}>
				<label className={styles.label} htmlFor={id}>{label}</label>
			</div>
			<div ref={elementRef} className={styles.inputWrap}>
				<DatePicker
					className={styles.input}
					calendarIcon={<CalendarIcon />}
					clearIcon={null}
					disabled={disabled}
					id={id}
					locale={`${language}-${country}`}
					maxDate={maxDate}
					minDate={minDate}
					name={name}
					onChange={onChange}
					required={required}
					value={value}
				/>
			</div>
		</div>
	);
}

DateField.propTypes = {
	disabled: PropTypes.bool,
	id: PropTypes.string.isRequired,
	isLabelHidden: PropTypes.bool,
	label: PropTypes.string.isRequired,
	maxDate: PropTypes.instanceOf(Date),
	minDate: PropTypes.instanceOf(Date),
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	required: PropTypes.bool,
	value: PropTypes.instanceOf(Date),
};

DateField.defaultProps = {
	disabled: false,
	isLabelHidden: false,
	maxDate: null,
	minDate: null,
	onChange: null,
	required: false,
	value: null,
};
