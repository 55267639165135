import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Button, Tooltip } from '../../ui';

import styles from './FileField.module.scss';

export default function FileField({
	accept,
	center,
	disabled,
	id,
	inputMode,
	isLabelHidden,
	label,
	maxFileSize,
	name,
	onBlur,
	onChange,
	required,
	tooltip,
	setFieldValue,
}) {
	const [t] = useTranslation();
	const [file, setFile] = useState(null);
	const [sizeError, setSizeError] = useState(null);
	const inputRef = useRef();

	const fileSize = (size) => size / 1024 > maxFileSize;

	const fileType = (type) => accept.includes(type);

	const handleFileRemove = (e) => {
		e.preventDefault();
		inputRef.current.value = '';
		setFile(null);
		setFieldValue(name, undefined);
	};

	const handleFileChange = (e) => {
		if (fileSize(e.target.files[0].size) || fileType(e.target.files[0].type)) {
			setSizeError(true);
			handleFileRemove(e);
		} else {
			setFile(e.target.files[0]);
		}
	};

	const handleClick = () => {
		inputRef.current?.click();
		setSizeError(false);
	};

	return (
		<div className={`${styles.root} ${center ? styles.center : ''} ${disabled ? styles.disabled : ''}`.trim()}>
			<div className={`${styles.labelWrap} ${isLabelHidden ? styles.labelWrapHidden : ''}`.trim()}>
				<label className={styles.label} htmlFor={id}>{label}</label>
				{tooltip && (
					<Tooltip
						text={tooltip}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 512 512"
							width={15}
							className={styles.tooltip}
						>
							<path
								fill="currentColor"
								d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
							/>
						</svg>
					</Tooltip>
				)}
			</div>
			<div className={styles.inputWrap}>
				<Button
					label={label}
					onClick={handleClick}
					outline
				/>
				<input
					accept={accept}
					ref={inputRef}
					className={styles.input}
					disabled={disabled}
					id={id}
					inputMode={inputMode}
					name={name}
					onBlur={onBlur}
					onChange={(e) => {
						handleFileChange(e);
						onChange(e);
					}}
					required={required}
					type="file"
				/>

				{file && (
					<div className={styles.fileWrap}>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={23} height={12} viewBox="0 0 23 12">
							<path fill="#47202A" d="M17.5 10H6a4 4 0 1 1 0-8h12.5a2.5 2.5 0 0 1 0 5H8a1 1 0 0 1-1-1 1 1 0 0 1 1-1h9.5V3.5H8a2.5 2.5 0 0 0 0 5h10.5a4 4 0 1 0 0-8H6a5.5 5.5 0 1 0 0 11h11.5V10Z" />
						</svg>
						<span className={styles.fileName}>
							{file?.name}
						</span>
						<a
							className={styles.fileRemove}
							href="#removeFile"
							onClick={(e) => handleFileRemove(e)}
						>
							{t('forms.fields.file.deleteAttachment')}
						</a>
					</div>
				)}

			</div>
			{sizeError && (
				<span className={styles.error}>
					{t('forms.fields.file.sizeError', { value: maxFileSize / 1024 })}
				</span>
			)}
		</div>
	);
}

FileField.propTypes = {
	accept: PropTypes.arrayOf(
		PropTypes.string,
	),
	center: PropTypes.bool,
	disabled: PropTypes.bool,
	id: PropTypes.string.isRequired,
	inputMode: PropTypes.string,
	isLabelHidden: PropTypes.bool,
	label: PropTypes.string.isRequired,
	maxFileSize: PropTypes.number,
	name: PropTypes.string.isRequired,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	required: PropTypes.bool,
	tooltip: PropTypes.string,
	setFieldValue: PropTypes.func.isRequired,
};

FileField.defaultProps = {
	accept: null,
	center: false,
	disabled: false,
	inputMode: null,
	isLabelHidden: false,
	maxFileSize: 4096,
	onBlur: null,
	onChange: null,
	required: false,
	tooltip: null,
};
