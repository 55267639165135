import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ButtonLink } from '../../../components/ui';

import styles from '../Products.module.scss';

export default function ChildCard({
	childProductUrl,
}) {
	const [t] = useTranslation();

	return (
		<div className={styles.content}>
			<div className={styles.icon}>
				<svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} viewBox="0 0 32 32" fill="none">
					<g fill="#A6516C" fillRule="evenodd" clipPath="url(#child-other-product-a)" clipRule="evenodd">
						<path d="M16 .738C7.58.738.73 7.588.73 16.008S7.58 31.279 16 31.279s15.27-6.85 15.27-15.27S24.42.739 16 .739ZM4.76 16.008c0 6.197 5.043 11.239 11.24 11.239s11.24-5.04 11.24-11.238c0-6.2-5.043-11.241-11.24-11.241S4.76 9.812 4.76 16.008Z" />
						<path d="M16.282 11.1c1.49-1.396 3.268-1.698 5.143-.878 1.72.752 2.713 2.194 2.799 4.06v.019c-.015 1.526-.438 2.656-1.325 3.553l-.496.503a87.874 87.874 0 0 1-2.305 2.283c-1.096 1.032-2.105 2.118-3.218 3.332-.204.223-.462.454-.804.46h-.012c-.34 0-.61-.23-.809-.43a296.046 296.046 0 0 0-2.78-2.78l-.152-.15c-1.054-1.044-2.14-2.12-3.188-3.199a4.73 4.73 0 0 1-1.187-4.59 4.697 4.697 0 0 1 3.344-3.314c1.656-.445 3.185-.061 4.423 1.108.151.143.224.212.297.211.068 0 .136-.064.27-.189Z" />
					</g>
					<defs>
						<clipPath id="child-other-product-a">
							<path fill="#fff" d="M0 0h32v32H0z" />
						</clipPath>
					</defs>
				</svg>
			</div>
			<p className={styles.subTitle}>
				{t('products.list.child.title')}
			</p>
			<p className={styles.text}>
				{t('products.list.child.text')}
			</p>
			<ButtonLink
				label={t('products.list.child.button')}
				to={childProductUrl}
				outline
			/>
		</div>
	);
}

ChildCard.propTypes = {
	childProductUrl: PropTypes.string.isRequired,
};
