import cs, { subpages as csSubpages } from './cs';
import en, { subpages as enSubpages } from './en';
import pl, { subpages as plSubpages } from './pl';
import sk, { subpages as skSubpages } from './sk';

export default {
	CZ: { cs, en },
	SK: { sk },
	PL: { pl },
};

export const subpages = {
	CZ: { cs: csSubpages, en: enSubpages },
	SK: { sk: skSubpages },
	PL: { pl: plSubpages },
};
