export function toNumber(number) {
	if (typeof number === 'number') {
		return number;
	}

	const value = +number;
	return value || Number.isNaN(value)
		? value
		: parseFloat(number); // +'' === 0, +false === 0, +null === 0
}

export function toInt(number) {
	const value = toNumber(number);
	return value < 0 ? Math.ceil(value) : Math.floor(value);
}

export function format(number, locale, {
	style = 'decimal',
	currency = null,
	fractionDigits = null,
	forceFractionDigits = false,
} = {}) {
	const value = toNumber(number);
	if (Number.isNaN(value)) {
		return '—';
	}

	let digits = fractionDigits;
	if (digits === null) {
		const limit = style === 'percent' ? 0.1 : 10;
		digits = Math.abs(value) < limit ? 1 : 0;
	}

	return value.toLocaleString(locale, {
		style: style === 'currency' && currency === null ? 'decimal' : style,
		currency: style === 'currency' ? currency : undefined,
		minimumFractionDigits: forceFractionDigits ? digits : 0,
		maximumFractionDigits: digits,
	});
}
