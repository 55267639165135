const CHARLIST = [
	['Á', 'A'], ['Ä', 'A'], ['Č', 'C'], ['Ç', 'C'], ['Ď', 'D'], ['É', 'E'], ['Ě', 'E'],
	['Ë', 'E'], ['Í', 'I'], ['Ň', 'N'], ['Ó', 'O'], ['Ö', 'O'], ['Ř', 'R'], ['Š', 'S'],
	['Ť', 'T'], ['Ú', 'U'], ['Ů', 'U'], ['Ü', 'U'], ['Ý', 'Y'], ['Ž', 'Z'], ['á', 'a'],
	['ä', 'a'], ['č', 'c'], ['ç', 'c'], ['ď', 'd'], ['é', 'e'], ['ě', 'e'], ['ë', 'e'],
	['í', 'i'], ['ň', 'n'], ['ó', 'o'], ['ö', 'o'], ['ř', 'r'], ['š', 's'], ['ť', 't'],
	['ú', 'u'], ['ů', 'u'], ['ü', 'u'], ['ý', 'y'], ['ž', 'z'],
];

function replaceDiacritics(str) {
	let stringWithoutDiacritics = str;

	for (let i = 0; i < CHARLIST.length; i += 1) {
		stringWithoutDiacritics = stringWithoutDiacritics.replace(new RegExp(CHARLIST[i][0], 'g'), CHARLIST[i][1]);
	}

	return stringWithoutDiacritics;
}

export default function webalize(str) {
	return replaceDiacritics(str)
		.replace(/[?]+/g, '')
		.replace(/[^a-z0-9]/ig, '-')
		.replace(/--+/g, '-')
		.toLowerCase();
}
