import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ButtonLink } from '../../ui';

import styles from './FooterCta.module.scss';

export default function FooterCta({
	registrationUrl,
}) {
	const [t] = useTranslation();

	return (
		<div className={styles.root}>
			<div className={styles.wrap}>
				<div className={styles.copy}>
					<h2 className={styles.title}>
						{t('footerCta.title')}
					</h2>
					<p className={styles.text}>
						{t('footerCta.text')}
					</p>
				</div>
				<ButtonLink
					label={t('footerCta.button')}
					to={registrationUrl}
				/>
			</div>
			<div className={styles.bubbles}>
				<div className={`${styles.bubble} ${styles.bubble1}`.trim()} />
				<div className={`${styles.bubble} ${styles.bubble2}`.trim()} />
				<div className={`${styles.bubble} ${styles.bubble3}`.trim()} />
			</div>
		</div>
	);
}

FooterCta.propTypes = {
	registrationUrl: PropTypes.string.isRequired,
};
