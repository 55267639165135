import React from 'react';
import PropTypes from 'prop-types';

import TextField from '../TextField';

function trimPostalCode(postalCode) {
	return postalCode.trim().replace(/[^0-9]/g, '');
}

function formatPostalCode(postalCode, country) {
	const separator = country === 'PL' ? '-' : ' ';
	const index = country === 'PL' ? 2 : 3;
	const trimmed = trimPostalCode(postalCode);

	if (trimmed.length < index) {
		return trimmed;
	}

	return `${trimmed.substring(0, index)}${separator}${trimmed.substring(index)}`;
}

export default function PostalCodeField({
	country,
	disabled,
	error,
	id,
	label,
	name,
	onBlur,
	onChange,
	required,
	value,
}) {
	const formattedValue = formatPostalCode(value, country);

	const handleChange = (e) => {
		if (onChange !== null) {
			const newValue = e.currentTarget.value;
			const newValueTrimmed = trimPostalCode(newValue);
			const separatorDeleted = newValue.length < formattedValue.length
				&& formattedValue.startsWith(newValue)
				&& formatPostalCode(newValueTrimmed, country) === formattedValue;
			const newValueAfterDelete = separatorDeleted
				? newValueTrimmed.substring(0, newValueTrimmed.length - 1)
				: newValueTrimmed;
			onChange(name, newValueAfterDelete);
		}
	};

	return (
		<TextField
			disabled={disabled}
			error={error}
			id={id}
			inputmode="numeric"
			label={label}
			name={name}
			onBlur={onBlur}
			onChange={handleChange}
			pattern="[0-9\s-]*"
			required={required}
			type="text"
			value={formattedValue}
		/>
	);
}

PostalCodeField.propTypes = {
	country: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	error: PropTypes.string,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	required: PropTypes.bool,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
};

PostalCodeField.defaultProps = {
	disabled: false,
	error: '',
	onBlur: null,
	onChange: null,
	required: false,
	value: undefined,
};
