import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './ProfileAvatars.module.scss';

const faces = {
	classic: {
		row: [
			'fox-01.svg',
			'fox-02.svg',
			'fox-03.svg',
			'fox-04.svg',
			'fox-05.svg',
			'fox-06.svg',
			'fox-07.svg',
		],
		row2: [
			'face-01.svg',
			'face-02.svg',
			'face-03.svg',
			'face-04.svg',
			'face-05.svg',
			'face-06.svg',
			'face-07.svg',
		],
		row3: [
			'face-female-01.svg',
			'face-female-02.svg',
			'face-female-03.svg',
			'face-female-04.svg',
			'face-female-05.svg',
			'face-female-06.svg',
			'face-female-07.svg',
		],
	},
	esg: {
		row: [
			'fox-esg-01.svg',
			'fox-esg-02.svg',
			'fox-esg-03.svg',
			'fox-esg-04.svg',
			'fox-esg-05.svg',
			'fox-esg-06.svg',
			'fox-esg-07.svg',
		],
		row2: [
			'face-esg-01.svg',
			'face-esg-02.svg',
			'face-esg-03.svg',
			'face-esg-04.svg',
			'face-esg-05.svg',
			'face-esg-06.svg',
			'face-esg-07.svg',
		],
		row3: [
			'face-female-esg-01.svg',
			'face-female-esg-02.svg',
			'face-female-esg-03.svg',
			'face-female-esg-04.svg',
			'face-female-esg-05.svg',
			'face-female-esg-06.svg',
			'face-female-esg-07.svg',
		],
	},
};

export default function ProfileAvatars({
	esg,
	selectedAvatar,
	setSelectedAvatar,
}) {
	const type = esg ? 'esg' : 'classic';
	const [t] = useTranslation();

	return (
		<div>
			<div className={styles.grid}>
				{Object.keys(faces[type]).map((key) => (
					<div className={styles.row} key={key}>
						{faces[type][key].map((face, i) => (
							<button
								key={face}
								type="button"
								className={`${styles.button} ${faces[type][key][i] === selectedAvatar ? styles.active : ''}`.trim()}
								onClick={() => setSelectedAvatar(faces[type][key][i])}
							>
								<img
									alt={t(`profileAvatar.faces.${type}.${i}`)}
									className={styles.avatarImage}
									src={`${process.env.PUBLIC_URL}/images/faces/noBorder/${face}`}
								/>
							</button>
						))}
					</div>
				))}
			</div>
		</div>
	);
}

ProfileAvatars.propTypes = {
	esg: PropTypes.bool.isRequired,
	selectedAvatar: PropTypes.string,
	setSelectedAvatar: PropTypes.func.isRequired,
};

ProfileAvatars.defaultProps = {
	selectedAvatar: null,
};
