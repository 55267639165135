import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useLanguage } from '../../../contexts/LocaleContext';
import { useUserContext } from '../../../contexts/UserContext';
import { fetchEmployers, fetchProfileBrokerAccounts } from '../../../utils/api';
import { toNumber } from '../../../utils/number';
import useFetchAuth from '../../../utils/useFetchAuth';
import { Container } from '../../layout';
import { ButtonLink, TopUpModal } from '../../ui';

import styles from './ClientHeader.module.scss';

const useFetchProfileBrokerAccounts = useFetchAuth(fetchProfileBrokerAccounts);
const useFetchEmployers = useFetchAuth((enabled) => (enabled ? fetchEmployers() : Promise.resolve(null)));

export default function ClientHeader({
	accountUrl,
	closeTopUpModal,
	faqUrl,
	hasTrading,
	isPension,
	portfolioId,
	portfolios,
	states,
	topUpModalOpen,
}) {
	const [t] = useTranslation();
	const language = useLanguage();
	const [user] = useUserContext();
	const [brokerAccountsData, loading] = useFetchProfileBrokerAccounts(null, language);
	const [employers, loadingEmployers] = useFetchEmployers(null, isPension);

	const depositDefaultPortfolioId = user !== null && user.deposit_default_portfolio_id !== null
		? toNumber(user.deposit_default_portfolio_id)
		: null;

	let label = 'clientHeader.titles.trading';
	let isOutlined = true;

	if (states?.hasDepositAndWithdrawal === 1) {
		label = 'clientHeader.titles.depositAndWithdrawal';
		isOutlined = false;
	} else if (states?.withdrawalStatus > 0) {
		label = 'clientHeader.titles.withdrawal';
		isOutlined = false;
	} else if (states?.hasDeposit === 1) {
		label = 'clientHeader.titles.deposit';
		isOutlined = false;
	}

	const hasActiveEmployer = employers && employers.filter((employer) => employer.active).length > 0;
	const pensionPortfolios = portfolios.filter((portfolio) => portfolio.categoryName === 'pension');

	return (
		<Container>
			<div className={styles.root}>
				<div className={styles.item}>
					<TopUpModal
						key={portfolioId}
						defaultPortfolioId={portfolioId ?? depositDefaultPortfolioId}
						defaultModalOpen={topUpModalOpen}
						portfolios={portfolios}
						onModalClose={closeTopUpModal}
					/>
				</div>
				{isPension && accountUrl && !hasActiveEmployer && (
					<div className={styles.item}>
						<ButtonLink
							label={t('employer.create.open')}
							to={`${accountUrl}#${t('anchors.employerList')}`}
							outline
						/>
					</div>
				)}
				{isPension && !loadingEmployers && hasActiveEmployer && (
					<TopUpModal
						key={portfolioId}
						defaultPortfolioId={portfolioId ?? depositDefaultPortfolioId}
						portfolios={pensionPortfolios}
						employer
					/>
				)}
				{hasTrading && (
					<div className={styles.item}>
						<ButtonLink label={t(label)} outline={isOutlined} to="#trading" />
					</div>
				)}
				<div className={styles.item}>
					<Link
						className={styles.link}
						to={faqUrl}
					>
						{t('clientHeader.faqLink')}
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 512 512"
						>
							<path
								fill="currentColor"
								d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
							/>
						</svg>
					</Link>
				</div>
				<div className={styles.item}>
					{!loading && brokerAccountsData !== null && (
						<>
							{t('clientHeader.nextTradingDay')}
							&nbsp;
							<strong>
								{new Date(brokerAccountsData.nextTradingDate).toLocaleDateString(language)}
							</strong>
						</>
					)}
				</div>
			</div>
		</Container>
	);
}

ClientHeader.propTypes = {
	accountUrl: PropTypes.string,
	closeTopUpModal: PropTypes.func,
	faqUrl: PropTypes.string.isRequired,
	hasTrading: PropTypes.bool,
	isPension: PropTypes.bool,
	portfolioId: PropTypes.number,
	portfolios: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number.isRequired,
		name: PropTypes.string,
		faceImageFile: PropTypes.string.isRequired,
	}).isRequired),
	states: PropTypes.shape({
		hasDeposit: PropTypes.number,
		hasDepositAndWithdrawal: PropTypes.number,
		withdrawalStatus: PropTypes.number,
	}),
	topUpModalOpen: PropTypes.bool,
};

ClientHeader.defaultProps = {
	accountUrl: null,
	closeTopUpModal: null,
	hasTrading: false,
	isPension: false,
	portfolioId: null,
	portfolios: null,
	states: null,
	topUpModalOpen: false,
};
