import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
	Container,
	Section,
} from '../../components/layout';
import { fetchProfileRegistrationProcess } from '../../utils/api';
import useFetchAuth from '../../utils/useFetchAuth';
import OnboardingSteps from './OnboardingSteps';

import styles from './OnboardingParent.module.scss';

const useFetchProfileRegistrationProcess = useFetchAuth(() => fetchProfileRegistrationProcess());

export default function OnboardingParent({
	blogPostEsgRegulationUrl,
	dashboardUrl,
	faqUrl,
}) {
	const [t] = useTranslation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [checkOnboarding, setCheckOnboarding] = useState(false);
	const [registrationProcess, loading, error] = useFetchProfileRegistrationProcess({}, checkOnboarding);
	const category = searchParams.get('category') ?? null;

	useEffect(() => {
		if (!loading && registrationProcess.completeRegistration) {
			navigate(dashboardUrl);
			return;
		}

		if (!loading && registrationProcess.category !== null && registrationProcess.category !== category) {
			const newSearchParams = new URLSearchParams(searchParams);
			newSearchParams.set('category', registrationProcess.category);
			setSearchParams(newSearchParams);
		}

		setCheckOnboarding(false);
	}, [registrationProcess, loading, category, navigate, searchParams, setSearchParams]);

	if (loading || error) return null;

	return (
		<Section>
			<Container>
				<>
					<h1 className={styles.title}>
						{t('onboarding.title')}
					</h1>
					<p className={styles.text}>
						{t('onboarding.text')}
					</p>
					<p className={styles.textSmaller}>
						{t('onboarding.info')}
					</p>
					<OnboardingSteps
						blogPostEsgRegulationUrl={blogPostEsgRegulationUrl}
						category={category}
						dashboardUrl={dashboardUrl}
						faqUrl={faqUrl}
						process={registrationProcess}
						setCheckOnboarding={setCheckOnboarding}
					/>
				</>
			</Container>
		</Section>
	);
}

OnboardingParent.propTypes = {
	blogPostEsgRegulationUrl: PropTypes.string,
	dashboardUrl: PropTypes.string.isRequired,
	faqUrl: PropTypes.string.isRequired,
};

OnboardingParent.defaultProps = {
	blogPostEsgRegulationUrl: null,
};
