import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
	Container,
	Row,
	Section,
} from '../../layout';
import { SectionAnchor } from '../../ui';
import HowItWorksSteps from '../HowItWorksSteps';

import styles from './HowItWorksHero.module.scss';

export default function HowItWorksHero({
	blogPostETFUrl,
	portfolioSectionAnchor,
}) {
	const [t] = useTranslation();

	return (
		<Section bordered>
			<div className={styles.root}>
				<Container>
					<h1 className={styles.title}>
						{t('howItWorksHero.title')}
					</h1>
					<p className={styles.description}>
						{t('howItWorksHero.description')}
					</p>
					<Row center>
						<HowItWorksSteps blogPostETFUrl={blogPostETFUrl} />
					</Row>
				</Container>
				<SectionAnchor title={t('howItWorksHero.anchor')} target={portfolioSectionAnchor} />
			</div>
		</Section>
	);
}

HowItWorksHero.propTypes = {
	blogPostETFUrl: PropTypes.string,
	portfolioSectionAnchor: PropTypes.string.isRequired,
};

HowItWorksHero.defaultProps = {
	blogPostETFUrl: null,
};
