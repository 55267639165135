import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { changeSkipRebalancing } from '../../../../utils/api';
import useRequestAuth from '../../../../utils/useRequestAuth';
import { CheckboxField, OnChangeSubmit } from '../../../forms';
import { Alert } from '../../../ui';

import styles from './SkipRebalancing.module.scss';

export default function SkipRebalancing({
	closed,
	isChild,
	portfolioId,
	skipRebalancing,
}) {
	const changeSkipRebalancingAuth = useRequestAuth(changeSkipRebalancing);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [t] = useTranslation();

	return (
		<div className={styles.root}>
			<h2 className={styles.title}>{t('portfolio.settings.additional.title')}</h2>
			<Formik
				initialValues={{ rebalancingActive: !skipRebalancing }}
				onSubmit={async (values) => {
					setError(false);
					setSuccess(false);

					try {
						await changeSkipRebalancingAuth(
							portfolioId,
							!values.rebalancingActive,
						);
					} catch {
						setError(true);
						return;
					}
					setSuccess(true);
				}}
			>
				{({
					handleChange, handleSubmit, isSubmitting, values,
				}) => (
					<form onSubmit={handleSubmit}>
						<OnChangeSubmit />
						{error && (
							<Alert type="danger">
								{t('forms.error')}
							</Alert>
						)}
						<CheckboxField
							checked={values.rebalancingActive}
							disabled={closed || isChild || isSubmitting}
							id="rebalancingActive"
							label={t('forms.fields.rebalancingActive.label')}
							name="rebalancingActive"
							onChange={handleChange}
						/>
						{success && (
							<Alert
								autoClose
								close
								type="success"
								onClose={() => setSuccess(false)}
							>
								{t('forms.success')}
							</Alert>
						)}
					</form>
				)}
			</Formik>
		</div>
	);
}

SkipRebalancing.propTypes = {
	closed: PropTypes.bool.isRequired,
	isChild: PropTypes.bool,
	portfolioId: PropTypes.number.isRequired,
	skipRebalancing: PropTypes.bool.isRequired,
};

SkipRebalancing.defaultProps = {
	isChild: false,
};
