import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './BlogPostReferences.module.scss';

export default function BlogPostReferences({
	references,
}) {
	const [t] = useTranslation();

	return (
		<div className={styles.root}>
			<h3 className={styles.title}>
				{t('blogPost.references.title')}
			</h3>
			<ul>
				{Object.keys(references).map((item) => (
					<li key={item} className={styles.item}>
						<a
							href={references[item]}
							rel="noopener noreferrer"
							target="_blank"
						>
							{references[item]}
						</a>
					</li>
				))}
			</ul>
		</div>
	);
}

BlogPostReferences.propTypes = {
	references: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};
