import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import isScreenWidthUp from '../../../utils/isScreenWidthUp';
import { toInt } from '../../../utils/number';
import useFocusOnError from '../../../utils/useFocusOnError';
import useWindowSize from '../../../utils/useWindowSize';
import { Tooltip } from '../../ui';

import styles from './RangerDouble.module.scss';

const GRADIENT_INNER = '#B63D45';
const GRADIENT_OUTER = '#EBEBEB';
const THUMB_SIZE = 25;

const tooltipIcon = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		width={15}
	>
		<path
			fill="currentColor"
			d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
		/>
	</svg>
);

export default function RangerDouble({
	disabled,
	id,
	max,
	maxLabel,
	maxTooltip,
	min,
	minLabel,
	minTooltip,
	name,
	onChange,
	steps,
	valueFrom,
	valueFromLabel,
	valueTo,
	valueToLabel,
}) {
	const windowSize = useWindowSize();
	const elementRef = useRef();
	useFocusOnError(name, null, elementRef);

	const handleChange = (newFrom, newTo) => {
		if (disabled) {
			return;
		}

		if (onChange !== null) {
			const newFromClamped = Math.min(Math.max(min, newFrom), max - 1);
			const newToClamped = Math.min(Math.max(newFromClamped + 1, newTo), max);

			onChange(name, newFromClamped, newToClamped);
		}
	};

	const handleFieldChange = (field, newValue) => {
		const newFrom = field === 'from' ? newValue : valueFrom;
		const newTo = field === 'to' ? newValue : valueTo;
		handleChange(newFrom, newTo);
	};

	const handleValueChange = (newValue) => {
		const isFrom = Math.abs(valueFrom - newValue) < Math.abs(valueTo - newValue);
		const newFrom = isFrom ? newValue : valueFrom;
		const newTo = !isFrom ? newValue : valueTo;
		handleChange(newFrom, newTo);
	};

	const handleInputChange = (field, e) => {
		handleFieldChange(field, toInt(e.currentTarget.value) || 0);
	};

	const avg = Math.floor((valueFrom + valueTo) / 2);
	const trackSize = (value, thumbs = 1) => {
		if (value === min) {
			return thumbs !== 0
				? `${thumbs * (THUMB_SIZE + 1)}px` // Firefox thumb bugs when input width === thumb width
				: '0';
		}

		const ratio = (value - min) / (max - min);
		return `calc(${ratio * 100}% - ${ratio * THUMB_SIZE}px${thumbs !== 0 ? ` + ${thumbs * THUMB_SIZE}px)` : ''}`;
	};

	const fromFilled = trackSize(valueFrom, 0.4);
	const toFilled = trackSize(valueTo, 0.4);

	const style = {
		background: {
			background: `linear-gradient(
				to right,
				${GRADIENT_OUTER} ${fromFilled},
				${GRADIENT_INNER} ${fromFilled},
				${GRADIENT_INNER} ${toFilled},
				${GRADIENT_OUTER} ${toFilled}
			)`,
		},
		from: {
			left: 0,
			width: trackSize(avg),
		},
		to: {
			left: trackSize(avg, 0),
			width: trackSize(max - (avg - min)),
		},
	};

	const renderSteps = () => {
		if (steps === null) {
			return null;
		}

		return steps.map(({ value, label }) => (
			<button
				key={value}
				className={styles.label}
				disabled={disabled}
				onClick={() => handleValueChange(value)}
				type="button"
			>
				{label ?? value}
			</button>
		));
	};

	return (
		<div className={styles.root}>
			{(minLabel || maxLabel) && (
				<div className={`${styles.labels} ${steps !== null ? styles.labelsSmall : ''}`.trim()}>
					<button
						className={styles.label}
						disabled={disabled}
						onClick={() => handleFieldChange('from', min)}
						type="button"
					>
						{minLabel}
						{minTooltip && (
							<span className={styles.labelTooltip}>
								<Tooltip
									text={minTooltip}
								>
									{tooltipIcon}
								</Tooltip>
							</span>
						)}
					</button>

					{isScreenWidthUp(windowSize.width, 'md') && renderSteps()}

					<button
						className={styles.label}
						disabled={disabled}
						onClick={() => handleFieldChange('to', max)}
						type="button"
					>
						{maxLabel}
						{maxTooltip && (
							<span className={styles.labelTooltip}>
								<Tooltip
									text={maxTooltip}
								>
									{tooltipIcon}
								</Tooltip>
							</span>
						)}
					</button>
				</div>
			)}

			<div ref={elementRef} className={styles.rangerWrap}>
				<input
					className={`${styles.ranger} ${styles.rangerBackground}`}
					style={style.background}
					type="range"
				/>

				<input
					className={styles.ranger}
					disabled={disabled}
					id={`${id}-from`}
					max={avg}
					min={min}
					onChange={(e) => handleInputChange('from', e)}
					name={`${name}-from`}
					step={1}
					style={style.from}
					value={String(valueFrom)}
					type="range"
				/>

				<input
					className={styles.ranger}
					disabled={disabled}
					id={`${id}-to`}
					max={max}
					min={avg}
					onChange={(e) => handleInputChange('to', e)}
					name={`${name}-to`}
					step={1}
					style={style.to}
					value={String(valueTo)}
					type="range"
				/>
			</div>

			<div className={styles.controlWrap}>
				{/* CONTROL FOR MIN VALUE */}
				<div className={styles.control}>
					<button
						type="button"
						className={styles.button}
						disabled={disabled || valueFrom <= min}
						onClick={() => handleFieldChange('from', valueFrom - 1)}
					>
						-
					</button>
					<input
						className={styles.input}
						disabled
						type="text"
						value={valueFromLabel ?? valueFrom}
					/>
					<button
						type="button"
						className={styles.button}
						disabled={disabled || valueFrom >= valueTo - 1}
						onClick={() => handleFieldChange('from', valueFrom + 1)}
					>
						+
					</button>
				</div>

				{/* CONTROL FOR MAX VALUE */}
				<div className={styles.control}>
					<button
						type="button"
						className={styles.button}
						disabled={disabled || valueTo <= valueFrom + 1}
						onClick={() => handleFieldChange('to', valueTo - 1)}
					>
						-
					</button>
					<input
						className={styles.input}
						disabled
						type="text"
						value={valueToLabel ?? valueTo}
					/>
					<button
						type="button"
						className={styles.button}
						disabled={disabled || valueTo >= max}
						onClick={() => handleFieldChange('to', valueTo + 1)}
					>
						+
					</button>
				</div>
			</div>
		</div>
	);
}

RangerDouble.propTypes = {
	disabled: PropTypes.bool,
	id: PropTypes.string.isRequired,
	max: PropTypes.number.isRequired,
	maxLabel: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	maxTooltip: PropTypes.string,
	min: PropTypes.number.isRequired,
	minLabel: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	minTooltip: PropTypes.string,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	steps: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.number.isRequired,
		label: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
		]),
	}).isRequired),
	valueFrom: PropTypes.number.isRequired,
	valueFromLabel: PropTypes.string,
	valueTo: PropTypes.number.isRequired,
	valueToLabel: PropTypes.string,
};

RangerDouble.defaultProps = {
	disabled: false,
	maxLabel: null,
	maxTooltip: '',
	minLabel: null,
	minTooltip: '',
	onChange: null,
	steps: null,
	valueFromLabel: null,
	valueToLabel: null,
};
