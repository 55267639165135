/* global document, window */
import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from '@sentry/react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Routes } from 'react-router-dom';

import App from './App';
import { LocaleProvider } from './contexts/LocaleContext';
import { Error, Error404 } from './pages';
import childRoute from './utils/childRoute';
import initSentry from './utils/initSentry';
import initTranslator from './utils/initTranslator';
import {
	countryLanguages,
	defaultLanguage,
	languageRoute,
	urlLanguage,
} from './utils/routes';
import saveUrlUtmCampaign from './utils/saveUrlUtmCampaign';

import './scss/index.scss';

export default function renderApp(country, translations, publicUrl, gtmId = '') {
	initSentry();
	initTranslator(translations, urlLanguage(country, window.location.pathname, publicUrl));
	saveUrlUtmCampaign(window.location.href);

	const root = document.getElementById('react-root');
	if (root !== null) {
		const languages = countryLanguages(country);

		const affiliate = {
			code: root.dataset.affiliateCode ?? null,
			source: root.dataset.affiliateSource ?? null,
		};
		const baseUrls = JSON.parse(root.dataset.baseUrls ?? 'null');
		const blogData = JSON.parse(root.dataset.blogData ?? 'null');
		const geoIpCountry = root.dataset.geoIpCountry ?? null;
		const languageSwitched = root.dataset.languageSwitched !== undefined;
		const referral = root.dataset.referral ?? null;

		const appElement = (language) => (
			<LocaleProvider
				country={country}
				language={language}
				publicUrl={publicUrl}
				switched={languageSwitched}
			>
				<App
					affiliate={affiliate}
					baseUrls={baseUrls}
					blogData={blogData}
					geoIpCountry={geoIpCountry}
					gtmId={gtmId}
					publicUrl={publicUrl}
					referral={referral}
				/>
			</LocaleProvider>
		);

		ReactDOM.render(
			<React.StrictMode>
				<ErrorBoundary fallback={<Error homepageUrl={`${publicUrl}/`} />}>
					<BrowserRouter basename={publicUrl}>
						<HelmetProvider>
							{languages.length > 1 ? (
								<Routes>
									{languages.map((language) => childRoute(
										language,
										`${languageRoute(country, language)}/*`,
										appElement(language),
									))}
								</Routes>
							) : appElement(defaultLanguage(country))}
						</HelmetProvider>
					</BrowserRouter>
				</ErrorBoundary>
			</React.StrictMode>,
			root,
		);
	}

	const error404Root = document.getElementById('error-404-root');
	if (error404Root !== null) {
		ReactDOM.render(
			<React.StrictMode>
				<ErrorBoundary fallback={<Error homepageUrl={error404Root.dataset.homepageUrl} />}>
					<Error404
						homepageUrl={error404Root.dataset.homepageUrl}
					/>
				</ErrorBoundary>
			</React.StrictMode>,
			error404Root,
		);
	}
}
