/* global window */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ExternalLink } from '../../../components/ui';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';

import styles from './SocialShare.module.scss';

export default function SocialShare({
	code,
	link,
}) {
	const [t] = useTranslation();
	const trackGTMEvent = useTrackGTMEvent();

	const linkEncoded = encodeURIComponent(link);
	const onClick = (e) => {
		e.preventDefault();
		if (e.currentTarget.href) {
			window.open(e.currentTarget.href, 'sharer', 'toolbar=0,status=0,resizable=1,width=626,height=436');
		}
	};

	return (
		<div className={styles.root}>
			<ExternalLink
				className={styles.link}
				onClick={(e) => {
					trackGTMEvent('interactions', {
						eventCategory: 'interactions',
						eventAction: 'socialShare',
						eventLabel: 'sdileni_bonus_kodu_facebook',
					});
					onClick(e);
				}}
				to={`https://www.facebook.com/dialog/share?app_id=593779431066921&display=popup&href=${linkEncoded}&quote=${encodeURIComponent(t('referrals.socialShare.facebook'))}`}
				blank
			>
				<span className={styles.icon}>
					<svg viewBox="0 0 1024 1024" width={50} height={50} xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
						<path d="M1024 512C1024 229.23 794.77 0 512 0S0 229.23 0 512c0 255.554 187.231 467.37 432 505.78V660H302V512h130V399.2C432 270.88 508.438 200 625.39 200 681.407 200 740 210 740 210v126h-64.562C611.835 336 592 375.467 592 415.957V512h142l-22.7 148H592v357.78c244.769-38.41 432-250.226 432-505.78z" fill="#1877f2" />
						<path d="M711.3 660L734 512H592v-96.043c0-40.49 19.835-79.957 83.438-79.957H740V210s-58.593-10-114.61-10C508.438 200 432 270.88 432 399.2V512H302v148h130v357.78a515.834 515.834 0 0080 6.22c27.216 0 53.933-2.13 80-6.22V660h119.3z" fill="#fff" />
					</svg>
				</span>
				<span className={styles.text}>
					{t('referrals.socialShare.sendVia')}
					{' '}
					Facebook
				</span>
			</ExternalLink>
			<ExternalLink
				className={styles.link}
				onClick={(e) => {
					trackGTMEvent('interactions', {
						eventCategory: 'interactions',
						eventAction: 'socialShare',
						eventLabel: 'sdileni_bonus_kodu_twitter',
					});
					onClick(e);
				}}
				to={`https://twitter.com/intent/tweet?text=${encodeURIComponent(t('referrals.socialShare.twitter', { code }))}&url=${linkEncoded}`}
				blank
			>
				<span className={styles.icon}>
					<svg width={50} height={50} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.197 112.197">
						<circle cx="56.099" cy="56.098" r="56.098" fill="#1DA1F2" />
						<path d="M90.461 40.316a26.753 26.753 0 01-7.702 2.109 13.445 13.445 0 005.897-7.417 26.843 26.843 0 01-8.515 3.253 13.396 13.396 0 00-9.79-4.233c-7.404 0-13.409 6.005-13.409 13.409 0 1.051.119 2.074.349 3.056-11.144-.559-21.025-5.897-27.639-14.012a13.351 13.351 0 00-1.816 6.742c0 4.651 2.369 8.757 5.965 11.161a13.314 13.314 0 01-6.073-1.679l-.001.17c0 6.497 4.624 11.916 10.757 13.147a13.362 13.362 0 01-3.532.471c-.866 0-1.705-.083-2.523-.239 1.706 5.326 6.657 9.203 12.526 9.312a26.904 26.904 0 01-16.655 5.74c-1.08 0-2.15-.063-3.197-.188a37.929 37.929 0 0020.553 6.025c24.664 0 38.152-20.432 38.152-38.153 0-.581-.013-1.16-.039-1.734a27.192 27.192 0 006.692-6.94z" fill="#fff" />
					</svg>
				</span>
				<span className={styles.text}>
					{t('referrals.socialShare.sendVia')}
					{' '}
					Twitter
				</span>
			</ExternalLink>
		</div>
	);
}

SocialShare.propTypes = {
	code: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
};
