import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { Container, Section } from '../../components/layout';
import { Button, EmailLink, ExternalLink } from '../../components/ui';

import styles from './Cookies.module.scss';
import { FooterCta } from '../../components/common';

export default function Cookies({
	personalInfoAgreementUrl,
	registrationUrl,
	setShowCookies,
}) {
	const [t] = useTranslation();

	return (
		<>
			<Section>
				<Container>
					<h1 className={styles.title}>{t('cookies.title')}</h1>
					<Trans i18nKey="cookies.intro">
						<p className={styles.text}>
							<strong />
						</p>
					</Trans>
					<h2 className={styles.subtitle}>{t('cookies.about.title')}</h2>
					<Trans i18nKey="cookies.about.text">
						<p className={styles.text} />
					</Trans>
					<h2 className={styles.subtitle}>{t('cookies.types.title')}</h2>
					{Object.keys(t('cookies.types.texts', { returnObjects: true })).map((text) => (
						<Fragment key={text}>
							<Trans i18nKey={`cookies.types.texts.${text}.text`}>
								<p className={styles.text} />
							</Trans>
							<ul className={styles.list}>
								{Object.keys(t(`cookies.types.texts.${text}.list`, { returnObjects: true })).map((item) => (
									<li key={item}>
										<Trans i18nKey={`cookies.types.texts.${text}.list.${item}`}>
											<strong />
										</Trans>
									</li>
								))}
							</ul>
						</Fragment>
					))}
					<h2 className={styles.subtitle}>{t('cookies.about.app.title')}</h2>
					<Trans i18nKey="cookies.about.app.text">
						<p className={styles.text} />
					</Trans>
					<h2 className={styles.subtitle}>{t('cookies.table.title')}</h2>
					<div className={styles.tableWrap}>
						<table className={styles.table}>
							<thead>
								<tr>
									{Object.keys(t('cookies.table.head', { returnObjects: true })).map((item) => (
										<th key={item}>
											{t(`cookies.table.head.${item}`)}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{Object.keys(t('cookies.table.rows', { returnObjects: true })).map((row) => (
									<tr key={row}>
										<td>{t(`cookies.table.rows.${row}.name`)}</td>
										<td>{t(`cookies.table.rows.${row}.domain`)}</td>
										<td>{t(`cookies.table.rows.${row}.party`)}</td>
										<td>{t(`cookies.table.rows.${row}.purpose`)}</td>
										<td>{t(`cookies.table.rows.${row}.category`)}</td>
										<td>{t(`cookies.table.rows.${row}.period`)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<h2 className={styles.subtitle}>{t('cookies.options.title')}</h2>
					<Trans i18nKey="cookies.options.text">
						<p className={styles.text} />
					</Trans>
					<h2 className={styles.subtitle}>{t('cookies.management.title')}</h2>
					<Trans i18nKey="cookies.management.text">
						<p className={styles.text} />
					</Trans>
					<ul className={styles.list}>
						{Object.keys(t('cookies.management.list', { returnObjects: true })).map((item) => (
							<li key={item}>
								<strong>{t(`cookies.management.list.${item}.title`)}</strong>
						&nbsp;&mdash;&nbsp;
								<a
									href={t(`cookies.management.list.${item}.link`)}
									target="_blank"
									rel="noopener noreferrer"
								>
									{t(`cookies.management.list.${item}.link`)}
								</a>
							</li>
						))}
					</ul>
					<h2 className={styles.subtitle}>{t('cookies.final.title')}</h2>
					<Trans i18nKey="cookies.final.text">
						<p className={styles.text}>
							<ExternalLink to={personalInfoAgreementUrl} />
							<EmailLink />
						</p>
					</Trans>
					<Button label={t('cookies.edit')} onClick={() => setShowCookies([true, true])} />
				</Container>
			</Section>
			<FooterCta registrationUrl={registrationUrl} />
		</>
	);
}

Cookies.propTypes = {
	personalInfoAgreementUrl: PropTypes.string.isRequired,
	registrationUrl: PropTypes.string.isRequired,
	setShowCookies: PropTypes.func.isRequired,
};
