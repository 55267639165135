const countryCheck = {
	modal: {
		CZ: {
			title: 'Jste na správné stránce?',
			text: 'Služby nabízíme i\u00a0ve Vašem jazyce.',
			stay: 'Zůstat na této stránce',
			redirect: 'Přejít na fondee.cz',
		},
		PL: {
			title: 'Czy jesteś na właściwej stronie?',
			text: 'Oferujemy również usługi w Twoim języku.',
			stay: 'Pozostań na tej stronie',
			redirect: 'Przejdź do fondee.pl',
		},
		SK: {
			title: 'Ste na správnej stránke?',
			text: 'Služby ponúkame aj vo Vašom jazyku.',
			stay: 'Zostať na tejto stránke',
			redirect: 'Prejsť na fondee.sk',
		},
	},
};

const languageSwitcher = {
	label: {
		cs: 'CZ',
		en: 'EN',
		sk: 'SK',
		pl: 'PL',
	},
	name: {
		cs: 'Čeština',
		en: 'English',
		sk: 'Slovenčina',
		pl: 'Język polski',
	},
};

export {
	countryCheck,
	languageSwitcher,
};
