import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Container, Section } from '../../layout';
import TeamMember from '../TeamMember';

import data from './data';
import styles from './OurApproachTeam.module.scss';

export default function OurApproachTeam({
	teamSectionAnchor,
}) {
	const [t] = useTranslation();

	return (
		<Section id={teamSectionAnchor}>
			<Container>
				<h2 className={styles.title}>{t('ourApproachTeam.title')}</h2>
				{data.members.map((member) => (
					<TeamMember
						key={member.slug}
						img={member.img}
						imgWebP={member.imgWebP}
						link={member.link}
						linkText={t(`ourApproachTeam.team.${member.slug}.link`)}
						name={t(`ourApproachTeam.team.${member.slug}.name`)}
						text={`ourApproachTeam.team.${member.slug}.text`}
					/>
				))}
			</Container>
		</Section>
	);
}

OurApproachTeam.propTypes = {
	teamSectionAnchor: PropTypes.string.isRequired,
};
