import React from 'react';
import PropTypes from 'prop-types';

import styles from './Container.module.scss';

export default function Container({
	center,
	children,
	variant,
}) {
	let variantClass = styles.default;

	if (variant === 'narrow') {
		variantClass = styles.narrow;
	} else if (variant === 'narrower') {
		variantClass = styles.narrower;
	}

	return (
		<div className={`${styles.root} ${variantClass} ${center ? styles.center : ''}`.trim()}>
			{children}
		</div>
	);
}

Container.propTypes = {
	center: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	variant: PropTypes.oneOf(['default', 'narrow', 'narrower']),
};

Container.defaultProps = {
	center: false,
	children: null,
	variant: 'default',
};
