import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Tooltip from '../Tooltip';
import isScreenWidthUp from '../../../utils/isScreenWidthUp';
import useWindowSize from '../../../utils/useWindowSize';

import styles from './ToggleMulti.module.scss';

const BORDER_WIDTH = 4;

export default function ToggleMulti({
	isVertical,
	onClick,
	options,
	selected,
	setSelected,
}) {
	const [labelWidth, setLabelWidth] = useState(null);
	const [offsetWidth, setOffsetWidth] = useState(null);
	const [offsetTopHeight, setOffsetTopHeight] = useState(null);
	const [t, i18n] = useTranslation();
	const wrapRef = useRef(null);
	const windowSize = useWindowSize();

	useEffect(() => {
		const wrap = wrapRef.current;
		const firstElement = wrap.childNodes[selected];
		const parentRect = firstElement.getBoundingClientRect();
		const wrapRect = wrap.getBoundingClientRect();
		setOffsetWidth(parentRect.left - wrapRect.left);
		setOffsetTopHeight(parentRect.top - wrapRect.top - BORDER_WIDTH);
		setLabelWidth(firstElement.clientWidth);
	}, [selected, windowSize]);

	return (
		<div ref={wrapRef} className={`${styles.toggle} ${isVertical ? styles.toggleVertical : ''}`.trim()}>
			{Object.keys(t(`${options}`, { returnObjects: true })).map((item, index) => (
				<label
					key={item}
					className={`${styles.toggleItem} ${selected === index ? styles.toggleItemActive : ''}`.trim()}
					htmlFor={t(`${options}.${item}.label`)}
				>
					<input
						checked={selected === index}
						className={styles.toggleItemInput}
						id={t(`${options}.${item}.label`)}
						name={t(`${options}.${item}.label`)}
						onChange={() => {
							setSelected(index);
							if (onClick !== null) {
								onClick();
							}
						}}
						type="radio"
						value={index}
					/>
					{t(`${options}.${item}.label`)}
					{i18n.exists(`${options}.${item}.tooltip`) && (
						<Tooltip text={t(`${options}.${item}.tooltip`)}>
							<span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 512 512"
									width={15}
									height={15}
									className={styles.tooltip}
								>
									<path
										fill="currentColor"
										d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
									/>
								</svg>
							</span>
						</Tooltip>
					)}
				</label>
			))}
			<div
				className={styles.toggleActive}
				style={{
					width: labelWidth,
					transform: `translateX(${offsetWidth}px) ${!isScreenWidthUp(windowSize.width, 'sm') && isVertical ? `translateY(${offsetTopHeight}px)` : ''}`,
				}}
			/>
		</div>
	);
}

ToggleMulti.propTypes = {
	isVertical: PropTypes.bool,
	onClick: PropTypes.func,
	options: PropTypes.string.isRequired,
	selected: PropTypes.number.isRequired,
	setSelected: PropTypes.func.isRequired,
};

ToggleMulti.defaultProps = {
	isVertical: false,
	onClick: null,
};
