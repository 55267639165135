import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '../../layout';

import styles from './BlogHeader.module.scss';

export default function BlogHeader() {
	const [t] = useTranslation();

	return (
		<Container center>
			<h1 className={styles.title}>
				{t('blog.title')}
			</h1>
			<p className={styles.text}>
				{t('blog.text')}
			</p>
		</Container>
	);
}
