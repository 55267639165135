import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { fetchInvestorProfile } from '../../../utils/api';
import useFetch from '../../../utils/useFetch';
import { Tooltip } from '../../ui';

import styles from './OnboardingProfile.module.scss';

const useFetchInvestorProfile = useFetch(fetchInvestorProfile);

export default function OnboardingProfile({
	selectedProfileId,
}) {
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const [profiles, loading] = useFetchInvestorProfile({}, country, language);

	const currentProfile = profiles.investorProfiles?.filter(
		(investorProfile) => investorProfile.id === selectedProfileId,
	)[0] ?? profiles.investorProfilesEsg?.filter(
		(investorProfile) => investorProfile.id === selectedProfileId,
	)[0] ?? null;

	if (loading || !currentProfile) return null;

	return (
		<div>
			<div className={styles.wrap}>
				<img
					className={styles.image}
					src={`${process.env.PUBLIC_URL}/images/faces/${currentProfile.faceImageFile}`}
					alt={currentProfile.name}
				/>
				<div className={styles.text}>
					<p>
						{t('onboarding.profilePreview.text')}
					</p>
					<span className={styles.name}>
						{currentProfile.name}
						<Tooltip text={currentProfile.description}>
							<svg className={styles.icon} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM7.7 11.9H6.3V10.5H7.7V11.9ZM9.149 6.475L8.519 7.119C8.015 7.63 7.7 8.05 7.7 9.1H6.3V8.75C6.3 7.98 6.615 7.28 7.119 6.769L7.987 5.887C8.246 5.635 8.4 5.285 8.4 4.9C8.4 4.13 7.77 3.5 7 3.5C6.23 3.5 5.6 4.13 5.6 4.9H4.2C4.2 3.353 5.453 2.1 7 2.1C8.547 2.1 9.8 3.353 9.8 4.9C9.8 5.516 9.548 6.076 9.149 6.475Z" fill="#47202A" />
							</svg>
						</Tooltip>
					</span>
				</div>
			</div>
			<p className={styles.info}>
				{t('onboarding.profilePreview.info')}
			</p>
		</div>
	);
}

OnboardingProfile.propTypes = {
	selectedProfileId: PropTypes.number.isRequired,
};
