import React from 'react';
import PropTypes from 'prop-types';

const icons = {
	completed: (
		<svg
			width={30}
			height={30}
			viewBox="0 0 30 30"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit="1.5"
		>
			<path d="M7.247 15l4.454 4.269L22.837 8.774" fill="none" stroke="#48ba58" strokeWidth="3.75" />
		</svg>
	),
	processing: (
		<svg
			width={30}
			height={30}
			viewBox="0 0 64 64"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeLinejoin="round"
			strokeMiterlimit="2"
		>
			<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(5.074 -26.936) scale(1.02762)" />
			<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(-17.538 -26.619) scale(1.20166)" />
			<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="matrix(.3802 0 0 .3802 -2.085 19.179)" />
			<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="matrix(.48317 0 0 .48317 1.289 33.752)" />
			<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(16.138 36.19) scale(.60537)" />
			<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(30.551 25.014) scale(.74228)" />
			<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(33.962 3.65) scale(.84072)" />
			<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(25.674 -17.054) scale(.9284)" />
		</svg>
	),
};

export default function ProgressIcon({
	completed,
}) {
	return completed
		? icons.completed
		: icons.processing;
}

ProgressIcon.propTypes = {
	completed: PropTypes.bool,
};

ProgressIcon.defaultProps = {
	completed: false,
};
