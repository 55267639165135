import React from 'react';
import PropTypes from 'prop-types';

import styles from './Loader.module.scss';

export default function Loader({
	fullPage,
	variant,
}) {
	if (fullPage) {
		return (
			<div className={styles.wrap}>
				<div className={styles.loader}>
					<i className={styles.root} />
				</div>
			</div>
		);
	}
	return (
		<i className={`${styles.root} ${styles[variant]}`} />
	);
}

Loader.propTypes = {
	fullPage: PropTypes.bool,
	variant: PropTypes.oneOf(['default', 'large']),
};

Loader.defaultProps = {
	fullPage: false,
	variant: 'default',
};
