/* global navigator */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import getCookieValue from '../../../utils/getCookieValue';
import setCookieValue from '../../../utils/setCookieValue';
import { ExternalLink } from '../../ui';

import styles from './AppBanner.module.scss';

const BANNER_COOKIE_NAME = 'fondee_app_banner';

const getDevice = () => {
	const ua = navigator.userAgent;
	if (/Android/i.test(ua)) {
		return 'Android';
	}
	if (/iPhone|iPad|iPod/i.test(ua)) {
		return 'iOS';
	}
	return null;
};

export default function AppBanner({
	setHasAppBanner,
}) {
	const [active, setActive] = useState(true);
	const [t] = useTranslation();

	const device = getDevice();
	const show = device !== null && active && !getCookieValue(BANNER_COOKIE_NAME);

	useEffect(() => {
		if (!show) {
			return undefined;
		}

		setHasAppBanner(true);
		return () => {
			setHasAppBanner(false);
		};
	}, [show]);

	if (!show) {
		return null;
	}

	const appUrl = device === 'Android'
		? 'https://play.google.com/store/apps/details?id=cz.fondee.app&utm_source=web_banner_android&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
		: 'https://apps.apple.com/cz/app/fondee/id1616141130?&utm_source=web_banner_apple&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1itsct=apps_box_badge&itscg=30200';

	return (
		<div className={styles.root}>
			<div className={styles.wrap}>
				<button
					type="button"
					className={styles.close}
					aria-label="Close"
					onClick={() => {
						setActive(false);
						setCookieValue(BANNER_COOKIE_NAME, 15, true);
					}}
				>
					<span aria-hidden="true">×</span>
				</button>
				<div className={styles.icon}>
					<svg xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" clipRule="evenodd" width={60} height={64} viewBox="0 0 256.1 269">
						<path fill="#a5516b" fillRule="nonzero" d="M175 54.5a79.5 79.5 0 0 1 0 159 79.8 79.8 0 0 1-19.2-2.3 80.2 80.2 0 0 0 3.3-22.7 79 79 0 0 0-21.6-54.4A79.3 79.3 0 0 0 159 79.6a78.2 78.2 0 0 0-3.3-22.8 80.3 80.3 0 0 1 19.3-2.3" />
						<path fill="#743a4b" fillRule="nonzero" d="M155.8 56.9a78.2 78.2 0 0 1 3.3 22.7c0 21-8.3 40.3-21.6 54.5a78.8 78.8 0 0 0-38.7-22.8 79.6 79.6 0 0 1 57-54.4" />
						<path fill="#733922" fillRule="nonzero" d="M159.1 188.5a80.3 80.3 0 0 1-3.3 22.7 79.5 79.5 0 0 1-57-54.5 79.2 79.2 0 0 0 38.7-22.6 79 79 0 0 1 21.6 54.4" />
						<path fill="#e94549" fillRule="nonzero" d="M79.6 0c36 0 66.5 24 76.2 56.9a79.6 79.6 0 0 0-57 54.4 79.8 79.8 0 0 0-19.2-2.3 79.2 79.2 0 0 0-58 25 79.5 79.5 0 0 1 58-134" />
						<path fill="#f39200" fillRule="nonzero" d="M155.8 211.2a79.6 79.6 0 1 1-134.1-77.1 79 79 0 0 0 57.9 25 79.8 79.8 0 0 0 19.2-2.4 79.5 79.5 0 0 0 57 54.5" />
						<path fill="#fff" fillRule="nonzero" d="M98.8 111.3a78.8 78.8 0 0 1 38.7 22.8 79.2 79.2 0 0 1-38.7 22.6 78 78 0 0 1 0-45.4" />
						<path fill="#c60c0e" fillRule="nonzero" d="M98.8 111.3a80.4 80.4 0 0 0-3.3 22.8c0 7.9 1.1 15.4 3.3 22.6a79.8 79.8 0 0 1-19.2 2.4 79.2 79.2 0 0 1-58-25 79.2 79.2 0 0 1 77.2-22.8" />
					</svg>
				</div>
				<div className={styles.info}>
					<p className={styles.title}>
						{t('appBanner.title')}
					</p>
					<p className={styles.small}>
						{t('appBanner.author')}
					</p>
					<p className={styles.small}>
						{t(device === 'Android' ? 'appBanner.downloadGoogle' : 'appBanner.downloadIOS') }
					</p>
				</div>
				<ExternalLink
					blank
					to={appUrl}
					className={styles.link}
					onClick={() => {
						setActive(false);
						setCookieValue(BANNER_COOKIE_NAME, 90, true);
					}}
				>
					{t('appBanner.visit')}
				</ExternalLink>
			</div>
		</div>
	);
}

AppBanner.propTypes = {
	setHasAppBanner: PropTypes.func.isRequired,
};
