import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './LanguageToggle.module.scss';
import { useChangeLanguage, useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { countries, countryLanguages } from '../../../utils/routes';
import { useCountrySwitchUrl, useLanguageSwitchUrl } from '../../../utils/useSwitchUrl';

export default function LanguageToggle({ allCountries, baseUrls, setNavWrapOpen }) {
	const [t] = useTranslation();
	const [rootOpen, setRootOpen] = useState(false);
	const currentCountry = useCountry();
	const currentLanguage = useLanguage();
	const changeLanguage = useChangeLanguage();
	const countrySwitchUrl = useCountrySwitchUrl(baseUrls);
	const languageSwitchUrl = useLanguageSwitchUrl();

	const handleClick = useCallback((e, language = null) => {
		if (language !== null) {
			changeLanguage(language);
		}

		setRootOpen(false);
		if (setNavWrapOpen !== null) {
			setNavWrapOpen(false);
		}
		e.currentTarget.blur();
	}, [changeLanguage, setRootOpen]);

	const switchCountries = allCountries ? countries : [currentCountry];
	if (!switchCountries.find((country) => (
		country !== currentCountry || !!countryLanguages(country).find((language) => language !== currentLanguage)
	))) {
		return null;
	}

	const isActive = (language) => language === currentLanguage;

	return (
		<div className={`${styles.root} ${rootOpen ? styles.isRootOpen : ''}`.trim()}>
			<button
				className={styles.trigger}
				onClick={() => setRootOpen(!rootOpen)}
				type="button"
			>
				{t(`languageSwitcher.label.${currentLanguage}`)}
			</button>
			<div className={styles.list}>
				{switchCountries.map((country) => (
					<Fragment key={country}>
						{countryLanguages(country).map((language) => (country === currentCountry ? (
							<Link
								key={language}
								className={`${styles.listItem} ${isActive(language) ? styles.isItemActive : ''}`.trim()}
								onClick={(e) => handleClick(e, language)}
								to={languageSwitchUrl(language)}
							>
								{t(`languageSwitcher.name.${language}`)}
							</Link>
						) : (
							<a
								key={language}
								className={styles.listItem}
								onClick={(e) => handleClick(e)}
								href={countrySwitchUrl(country, language)}
							>
								{t(`languageSwitcher.name.${language}`)}
							</a>
						)))}
					</Fragment>
				))}
			</div>
		</div>
	);
}

LanguageToggle.propTypes = {
	allCountries: PropTypes.bool,
	baseUrls: PropTypes.objectOf(PropTypes.string.isRequired),
	setNavWrapOpen: PropTypes.func,
};

LanguageToggle.defaultProps = {
	allCountries: false,
	baseUrls: null,
	setNavWrapOpen: null,
};
