import React from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';

import useFormatNumber from '../../../../utils/useFormatNumber';

import styles from '../PortfolioOverview.module.scss';

const chartOptions = (colors, labels, formatLabel, formatTooltip) => ({
	colors,
	dataLabels: {
		formatter: formatLabel,
	},
	labels,
	legend: {
		show: false,
	},
	plotOptions: {
		pie: {
			expandOnClick: false,
		},
	},
	responsive: [
		{
			breakpoint: 4000,
			options: {
				chart: {
					height: 370,
					width: 350,
				},
			},
		},
		{
			breakpoint: 992,
			options: {
				chart: {
					height: 330,
					width: 300,
				},
			},
		},
		{
			breakpoint: 576,
			options: {
				chart: {
					height: 330,
					width: 280,
				},
			},
		},
	],
	tooltip: {
		enabledOnSeries: [0],
		y: {
			formatter: formatTooltip,
		},
	},
});

export default function PortfolioOverviewChart({
	categories,
}) {
	const formatPercent = useFormatNumber({ style: 'percent', fractionDigits: 1 });
	const formatLabel = (value) => (value >= 3.5 ? formatPercent(value / 100) : '');
	const formatTooltip = (value) => (value !== undefined ? formatPercent(value) : null);

	const volumes = categories.map(({ volume }) => volume);
	const sum = volumes.reduce((acc, volume) => acc + volume, 0);
	const series = sum ? volumes.map((volume) => volume / sum) : volumes;
	const colors = categories.map(({ colorHex }) => `#${colorHex}`);
	const labels = categories.map(({ titleText }) => titleText);

	return (
		<div className={styles.chartWrap}>
			<Chart
				className={styles.chart}
				height={350}
				options={chartOptions(colors, labels, formatLabel, formatTooltip)}
				series={series}
				type="donut"
				width={350}
			/>
		</div>
	);
}

PortfolioOverviewChart.propTypes = {
	categories: PropTypes.arrayOf(
		PropTypes.shape({
			colorHex: PropTypes.string.isRequired,
			titleText: PropTypes.string.isRequired,
			volume: PropTypes.number.isRequired,
		}),
	).isRequired,
};
