import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import {
	Container,
	Section,
} from '../../../components/layout';
import { ButtonLink } from '../../../components/ui';

import styles from './CampaignCalculation.module.scss';

export default function CampaignCalculation({
	setTypeFondee,
	isTypeFondee,
	onRegistractionClick,
	registrationUrl,
}) {
	const [t] = useTranslation();
	const context = isTypeFondee ? 'fondee' : 'account';

	return (
		<Section
			id="jak-to-ze-na-sporaku-o-sve-penize-prichazite"
			variant="orange-lighter"
			condensed
		>
			<Container center>
				<div className={styles.root}>
					<h2 className={styles.title}>
						{t('campaign.calculation.title')}
					</h2>
					<p className={styles.paragraph}>
						{t(`campaign.calculation.${context}.enumeration`)}
					</p>
					<p className={styles.enumeration}>
						{t(`campaign.calculation.${context}.enumarationResult`)}
					</p>
					<p className={styles.paragraph}>
						<Trans i18nKey={`campaign.calculation.${context}.info`}>
							<span className={styles.result} />
						</Trans>
					</p>
					<p className={styles.paragraph}>
						{t(`campaign.calculation.${context}.comparation`)}
					</p>
					<div className={styles.buttonGroup}>
						<button
							className={styles.linkButton}
							onClick={() => setTypeFondee(!isTypeFondee)}
							type="button"
						>
							{t(`campaign.calculation.${context}.link`)}
						</button>
						<ButtonLink
							label={t('campaign.calculation.button')}
							onClick={onRegistractionClick}
							to={registrationUrl}
						/>
					</div>
				</div>
			</Container>
		</Section>
	);
}

CampaignCalculation.propTypes = {
	isTypeFondee: PropTypes.bool,
	onRegistractionClick: PropTypes.func.isRequired,
	registrationUrl: PropTypes.string.isRequired,
	setTypeFondee: PropTypes.func.isRequired,
};

CampaignCalculation.defaultProps = {
	isTypeFondee: false,
};
