import React from 'react';
import { Trans } from 'react-i18next';

import { Container, Section } from '../../layout';
import { Logo } from '../../ui';

import styles from './HomepageJT.module.scss';

export default function HomepageJT() {
	return (
		<Section condensed>
			<Container>
				<div className={styles.root}>
					<div className={styles.logoWrap}>
						<div className={styles.logoJt}>
							<svg viewBox="0 0 601 86" width="193" height="49" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
								<g fill="#a8a8a8" fillRule="nonzero">
									<path d="M20 .5v58.8H0v25.1h28.7c2.4 0 3.9-.2 3.9-.2 8.8-1.3 15.6-8.7 15.8-17.8V.5H20Zm69.5 76.3c-19.8 0-22.4-1.2-22.4-17.6 0-13.3 1.2-15 13.5-17.8l32.8 26.9c-1.9 9.3-14.8 8.5-23.9 8.5m33.7-11c.8-3.8 1-7.8 1-11.7 0-2.2-.1-4.4-.2-6.6h-8.7c0 1.9.4 3.9.2 5.8-.1 1.9-.2 3.7-.3 5.6L82.6 31.5c-4.4-3.7-5.8-5.8-5.8-11.7C76.8 8.7 83.9 8.4 93 8.4c12.5 0 14.1 2.1 14.2 12.7v4.6h9.5v-4.6C116.7 3.4 108.2 0 93 0 76.6 0 67.3 3.3 67.3 19.8c0 9.4 2.1 12 7.6 16.6-10.7 3-17.1 2.5-17.1 22.8 0 26.2 13.3 26.1 31.9 26.1 13.4 0 26.5 0 31.3-11l12.4 10.3 5.1-6.6-15.3-12.2ZM127.6.5v25.2h16.1v58.7h28.5V25.7h16.1V.5h-60.7Zm96.1 45.4h31.1c11.5.1 12.8 5.1 12.9 15.8-.1 12.6-4.5 13.4-14.7 13.5h-29.3V45.9Zm26.9-9.2h-26.9v-27h27.9c9.9.1 13.6.9 13.7 11.9-.1 12.9-3.4 15-14.7 15.1m15.5 3.7c7.9-3.1 9.6-9.1 9.6-17.5 0-7.3-.9-12.8-3.9-16.7-3-3.8-8-5.7-15.8-5.7h-42.6v83.9H258c7.1 0 12.1-2.1 15.4-6.1 3.2-4 4.6-9.9 4.6-17.5.1-9.9-1.6-18-11.9-20.4m71.2 17.8h-35.5L319.4 10l17.9 48.2ZM312.3.5l-30.5 83.9h10.8l6.4-17.6h41.3l6.5 17.6h10.7L326.2.5h-13.9Zm267.5 57.7h-35.5L561.9 10l17.9 48.2ZM554.8.5l-30.5 83.9h10.8l6.4-17.6h41.3l6.5 17.6h10.8L568.8.5h-14ZM426.9 75.6 380.8.5h-16.1v83.9h10.2V9.9l46.2 74.5h16.1V.5h-10.3v75.1Z" />
									<path d="M482 41.1 520.4.5h-13.5L473 36.8h-7.5V.5h-10.3v83.9h10.3V46h7.5l38 38.4h14l-43-43.3Z" />
								</g>
							</svg>
						</div>
						<div className={styles.logo}>
							<Logo />
						</div>
					</div>
					<div className={styles.textWrap}>
						<Trans i18nKey="homepageJT.text">
							<p className={styles.text} />
						</Trans>
					</div>
				</div>
			</Container>
		</Section>
	);
}
