import React from 'react';
import { useTranslation } from 'react-i18next';

import {
	fetchProfileStatistics,
} from '../../../utils/api';
import useFetchAuth from '../../../utils/useFetchAuth';
import useFormatNumber from '../../../utils/useFormatNumber';

import styles from './Stats.module.scss';

const useFetchProfileStatistics = useFetchAuth(fetchProfileStatistics);

export default function Stats() {
	const [statistics, loading, error] = useFetchProfileStatistics({});
	const formatNumber = useFormatNumber();
	const [t] = useTranslation();

	if (loading || error) return null;

	return (
		<div className={styles.root}>
			<div className={styles.title}>
				{t('referrals.stats.title')}
			</div>
			<div className={styles.number}>
				{formatNumber(statistics.allUsers ?? null)}
			</div>
		</div>
	);
}
