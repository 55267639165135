import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useLanguage } from '../../../contexts/LocaleContext';
import { clientCheckManualStartInvestmentQuestionnaire } from '../../../utils/api';
import useRequestAuth from '../../../utils/useRequestAuth';
import {
	Alert,
	Button,
	Modal,
	Tooltip,
} from '../../ui';

import styles from './ManualQuestionnaireTriggerModal.module.scss';

export default function ManualQuestionnaireTriggerModal({
	clientCheckUrl,
	lastCheck,
	nextCheck,
}) {
	const [t] = useTranslation();
	const navigate = useNavigate();
	const language = useLanguage();
	const [isModalOpen, setModalOpen] = useState(false);
	const [error, setError] = useState(false);
	const manualStartInvestmentQuestionnaireAuth = useRequestAuth(clientCheckManualStartInvestmentQuestionnaire);

	const formatDate = (date) => new Date(date).toLocaleDateString(language);

	const isActive = new Date(nextCheck) <= new Date();

	const handleConfirm = async () => {
		try {
			await manualStartInvestmentQuestionnaireAuth();
		} catch {
			setError(true);
			return;
		}
		setModalOpen(false);
		navigate(clientCheckUrl);
	};

	return (
		<div className={styles.root}>
			<h2 className={styles.title}>{t('account.investingQuestionaire.title')}</h2>
			<div className={styles.lastFill}>
				<span>{t('account.investingQuestionaire.lastFill')}</span>
				{formatDate(lastCheck)}
			</div>
			<div className={styles.possibleFill}>
				<span>{t('account.investingQuestionaire.possibleFill')}</span>
				{isActive ? t('account.investingQuestionaire.now') : (
					<>
						{formatDate(nextCheck)}
						<span className={styles.dateIcon}>
							<Tooltip text={t('account.investingQuestionaire.tooltip')}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 512 512"
									width={15}
								>
									<path
										fill="currentColor"
										d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
									/>
								</svg>
							</Tooltip>
						</span>
					</>
				)}
			</div>
			<div className={styles.triggerButton}>
				<Button
					disabled={!isActive}
					label={t('account.investingQuestionaire.fill')}
					onClick={() => setModalOpen(true)}
					outline
				/>
			</div>
			<Modal
				isVisible={isModalOpen}
				onClose={() => setModalOpen(false)}
				size="default"
				title={t('account.investingQuestionaire.modal.title')}
			>
				<div className={styles.modal}>
					<p>{t('account.investingQuestionaire.modal.text')}</p>
					<Button
						label={t('account.investingQuestionaire.modal.confirm')}
						onClick={handleConfirm}
						outline
					/>
					{error && (
						<Alert type="danger">{t('error.title')}</Alert>
					)}
				</div>
			</Modal>
		</div>
	);
}

ManualQuestionnaireTriggerModal.propTypes = {
	clientCheckUrl: PropTypes.string.isRequired,
	lastCheck: PropTypes.string,
	nextCheck: PropTypes.string,
};

ManualQuestionnaireTriggerModal.defaultProps = {
	lastCheck: null,
	nextCheck: null,
};
