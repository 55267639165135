import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Container, SectionAdaptable } from '../../../components/layout';
import { ButtonLink, Picture } from '../../../components/ui';

import space from './images/space.png';
import space2x from './images/space@2x.png';
import spaceWebP from './images/space.webp';
import spaceWebP2x from './images/space@2x.webp';
import spaceMobile from './images/spaceMobile.png';
import spaceMobile2x from './images/spaceMobile@2x.png';
import spaceMobileWebP from './images/spaceMobile.webp';
import spaceMobileWebP2x from './images/spaceMobile@2x.webp';

import styles from './NewspaperHero.module.scss';

export default function NewspaperHero({
	pdfUrl,
}) {
	const [t] = useTranslation();

	return (
		<SectionAdaptable>
			<Container center>
				<h1 className={styles.title}>
					{t('newspaper.title')}
				</h1>
				<p className={styles.text}>
					{t('newspaper.text')}
				</p>
				<ButtonLink
					label={t('newspaper.button')}
					to={pdfUrl}
					download
					secondary
				/>
			</Container>
			<div className={styles.hero}>
				<Picture
					alt={t('newspaper.title')}
					breakpoint="sm"
					src={spaceMobile}
					src2x={spaceMobile2x}
					srcWebP={spaceMobileWebP}
					src2xWebP={spaceMobileWebP2x}
					srcBreakpoint={space}
					srcBreakpoint2x={space2x}
					srcBreakpointWebP={spaceWebP}
					srcBreakpoint2xWebP={spaceWebP2x}
				/>
			</div>
		</SectionAdaptable>
	);
}

NewspaperHero.propTypes = {
	pdfUrl: PropTypes.string.isRequired,
};
