import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useCallback } from 'react';

export default function useRecaptchaToken() {
	const { executeRecaptcha } = useGoogleReCaptcha();

	const getRecaptchaToken = useCallback(async () => {
		if (!executeRecaptcha) {
			return null;
		}

		try {
			return await executeRecaptcha();
		} catch (err) {
			return null;
		}
	}, [executeRecaptcha]);

	return { getRecaptchaToken };
}
