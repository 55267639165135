import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import webalize from '../../../utils/webalize';

import BlogPost from '../BlogPost';
import BlogPostContent from '../BlogPost/BlogPostContent';
import BlogPostImage from '../BlogPost/BlogPostImage';
import BlogPostList from '../BlogPost/BlogPostList';

import exemptImage from './images/taxes/exempt.png';
import exemptImage2x from './images/taxes/exempt-2x.png';
import exemptImageWebP from './images/taxes/exempt.webp';
import exemptImage2xWebP from './images/taxes/exempt-2x.webp';
import exemptImageMobile from './images/taxes/exempt-mobile.png';
import exemptImage2xMobile from './images/taxes/exempt-2x-mobile.png';
import exemptImageWebPMobile from './images/taxes/exempt-mobile.webp';
import exemptImage2xWebPMobile from './images/taxes/exempt-2x-mobile.webp';

import fifolifoImage from './images/taxes/fifolifo.png';
import fifolifoImage2x from './images/taxes/fifolifo-2x.png';
import fifolifoImageWebP from './images/taxes/fifolifo.webp';
import fifolifoImage2xWebP from './images/taxes/fifolifo-2x.webp';
import fifolifoImageMobile from './images/taxes/fifolifo-mobile.png';
import fifolifoImage2xMobile from './images/taxes/fifolifo-2x-mobile.png';
import fifolifoImageWebPMobile from './images/taxes/fifolifo-mobile.webp';
import fifolifoImage2xWebPMobile from './images/taxes/fifolifo-2x-mobile.webp';

import fxImage from './images/taxes/fx.png';
import fxImage2x from './images/taxes/fx-2x.png';
import fxImageWebP from './images/taxes/fx.webp';
import fxImage2xWebP from './images/taxes/fx-2x.webp';
import fxImageMobile from './images/taxes/fx-mobile.png';
import fxImage2xMobile from './images/taxes/fx-2x-mobile.png';
import fxImageWebPMobile from './images/taxes/fx-mobile.webp';
import fxImage2xWebPMobile from './images/taxes/fx-2x-mobile.webp';

import incomeImage from './images/taxes/income.png';
import incomeImage2x from './images/taxes/income-2x.png';
import incomeImageWebP from './images/taxes/income.webp';
import incomeImage2xWebP from './images/taxes/income-2x.webp';
import incomeImageMobile from './images/taxes/income-mobile.png';
import incomeImage2xMobile from './images/taxes/income-2x-mobile.png';
import incomeImageWebPMobile from './images/taxes/income-mobile.webp';
import incomeImage2xWebPMobile from './images/taxes/income-2x-mobile.webp';

import lossImage from './images/taxes/loss.png';
import lossImage2x from './images/taxes/loss-2x.png';
import lossImageWebP from './images/taxes/loss.webp';
import lossImage2xWebP from './images/taxes/loss-2x.webp';
import lossImageMobile from './images/taxes/loss-mobile.png';
import lossImage2xMobile from './images/taxes/loss-2x-mobile.png';
import lossImageWebPMobile from './images/taxes/loss-mobile.webp';
import lossImage2xWebPMobile from './images/taxes/loss-2x-mobile.webp';

import messImage from './images/taxes/mess.png';
import messImage2x from './images/taxes/mess-2x.png';
import messImageWebP from './images/taxes/mess.webp';
import messImage2xWebP from './images/taxes/mess-2x.webp';
import messImageMobile from './images/taxes/mess-mobile.png';
import messImage2xMobile from './images/taxes/mess-2x-mobile.png';
import messImageWebPMobile from './images/taxes/mess-mobile.webp';
import messImage2xWebPMobile from './images/taxes/mess-2x-mobile.webp';

import taxesImage from './images/taxes/taxes.png';
import taxesImage2x from './images/taxes/taxes-2x.png';
import taxesImageWebP from './images/taxes/taxes.webp';
import taxesImage2xWebP from './images/taxes/taxes-2x.webp';
import taxesImageMobile from './images/taxes/taxes-mobile.png';
import taxesImage2xMobile from './images/taxes/taxes-2x-mobile.png';
import taxesImageWebPMobile from './images/taxes/taxes-mobile.webp';
import taxesImage2xWebPMobile from './images/taxes/taxes-2x-mobile.webp';

import whenImage from './images/taxes/when.png';
import whenImage2x from './images/taxes/when-2x.png';
import whenImageWebP from './images/taxes/when.webp';
import whenImage2xWebP from './images/taxes/when-2x.webp';
import whenImageMobile from './images/taxes/when-mobile.png';
import whenImage2xMobile from './images/taxes/when-2x-mobile.png';
import whenImageWebPMobile from './images/taxes/when-mobile.webp';
import whenImage2xWebPMobile from './images/taxes/when-2x-mobile.webp';

import previewImage from './images/taxes/preview.png';
import previewImage2x from './images/taxes/preview-2x.png';
import previewImageWebp from './images/taxes/preview.webp';
import previewImage2xWebp from './images/taxes/preview-2x.webp';

const title = 'Jak na zdanění investic do\u00a0ETF, akcií a\u00a0dalších cenných papírů';
const slug = `${webalize(title)}-2022`;

const date = '2022-03-11';
const topics = [{
	id: 'dane',
	name: 'Daně',
	slug: 'dane',
}];
const updated = false;

const perex = 'Začali jste investovat a\u00a0nemáte ještě úplně jasno v\u00a0tom, jaký díl '
	+ 'Vašich zisků budete odvádět na daních? A\u00a0budou-li se Vás týkat nějaké nové povinnosti? '
	+ 'V\u00a0tomto článku vám vysvětlíme, jak na zdanění cenných papírů, mezi které patří akcie, '
	+ 'dluhopisy, podílové listy nebo právě ETF, do kterých můžete investovat prostřednictvím\u00a0Fondee.';

const listPrerequisites = [
	'bydlíte v\u00a0České republice a\u00a0máte zde středisko životních zájmů, neboli jste '
	+ 'českým daňovým rezidentem a\u00a0platíte tu daně',
	'v\u00a0rámci investování zhodnocujete svůj soukromý majetek, neboli investice nejsou '
	+ 'součástí Vašeho podnikání a\u00a0nezařazujete je do obchodního majetku',
];

const content = [
	'Přehledné shrnutí',
	'Jak pohlížet na zhodnocení',
	'Dividendy a přijaté úroky',
	'Prodej cenných papírů',
	'Jak řešit přepočet na\u00a0koruny',
	'Osvobození příjmů z\u00a0prodeje',
	'Jak vyčíslit neosvobozené příjmy z prodeje cenných papírů a\u00a0zisk ke\u00a0zdanění',
	'Kdy podávat daňové přiznání',
	'Perličky na závěr',
];

const slugs = content.map((section) => webalize(section));

const listLimits = [
	'limit u\u00a0zaměstnanců je 6000\u00a0Kč ročně -\u00a0jde o\u00a0součet všech zdanitelných '
	+ 'příjmů mimo zaměstnání, a',
	'limit u\u00a0všech ostatních představuje 15\u00a0000\u00a0Kč ročně -\u00a0jde o\u00a0součet '
	+ 'veškerých zdanitelných příjmů',
];

const listIncomes = [
	'Příjem z\u00a0dividend, který se považuje za\u00a0kapitálový příjem, a\u00a0je vždy zdanitelný.',
	[
		'Příjem z\u00a0prodeje cenných papírů (například ETF, akcií nebo dluhopisů). Zde je hlavním kritériem, '
		+ 'kolik peněz jste získali prodejem cenných papírů v\u00a0daném kalendářním roce (a\u00a0ne to, kolik jste '
		+ 'prodejem vydělali).',
		'Pokud je příjem <0>nižší než 100\u00a0000\u00a0Kč</0> za všechny cenné papíry (v\u00a0součtu s\u00a0dalšími prodeji '
		+ 'mimo Fondee, tj. mohou sem spadat i\u00a0prodeje Vašich akcií, dluhopisů, podílových listů, atd., a\u00a0do '
		+ 'celkové částky se počítají i\u00a0osvobozené prodeje na základě časového testu, viz poslední bod), <0>pak je vše '
		+ 'osvobozeno a\u00a0nic nedaníte</0>.',
		'Pokud je příjem <0>vyšší než 100\u00a0000\u00a0Kč</0>, pak je zdanitelný, ale daníte jen zisk, neboli rozdíl mezi '
		+ 'prodejní a\u00a0nákupní cenou za všechny cenné papíry, které jste v\u00a0daném kalendářním roce prodali. Pokud '
		+ 'je výsledkem ztráta, uplatnit ji nemůžete.',
		'Pokud čas mezi nákupem a\u00a0prodejem cenného papíru přesáhl 3\u00a0roky (tzv. časový test), pak je tento prodej '
		+ 'osvobozen od daně (tzn. zisk se nedaní, případná ztráta se neuplatňuje).',
	],
];

const listDividends = [
	'Dividendy vyplácené českými emitenty cenných papírů jsou již zdaněné, podléhají totiž srážkové dani '
	+ 'v\u00a0České republice.',
	'S\u00a0dividendami ETF je to složitější, mají totiž zdroj v\u00a0zahraničí a\u00a0české srážkové dani '
	+ 'nepodléhají. V\u00a0tom se podobají například dividendám zahraničních akcií. I\u00a0když je možné, že bude '
	+ 'daň z\u00a0dividendy sražena v\u00a0zahraničí, je třeba v\u00a0České republice dividendu v\u00a0hrubé výši '
	+ 'zdanit a\u00a0strženou zahraniční daň lze případně započíst v\u00a0rámci zamezení dvojího zdanění.',
	'V\u00a0případě úroků z\u00a0dluhopisů vše funguje obdobně. Pokud jde o\u00a0českého emitenta dluhopisu, ten '
	+ 'srazí českou srážkovou daň (v\u00a0takovém případě je již zdaněno a\u00a0kupón se nikde v\u00a0investorově '
	+ 'přiznání neobjeví), ale pokud jde o\u00a0zahraničního emitenta, je třeba hrubý kupón zdanit v\u00a0rámci '
	+ 'kapitálového majetku.',
];

const listFifoLifo = [
	'FIFO je z\u00a0anglického first in first out, a\u00a0znamená, že první cenný papír, který prodáváte, je ten, '
	+ 'který jste nakoupili jako první.',
	'Naopak metoda LIFO je z\u00a0anglického last in first out, znamená, že prodáváte cenný papír, který jste '
	+ 'nakoupili jako poslední.',
];

const listFx = [
	'Aktuálním kurzem\u00a0- každý nákup a\u00a0prodej bude mít svůj denní kurz devizového trhu vyhlašovaný '
	+ 'Českou národní bankou.',
	'Jednotným kurzem vyhlašovaným Generálním finančním ředitelstvím za daný kalendářní rok. Jedná se '
	+ 'o\u00a0průměr kurzů konce měsíce vyhlašovaných Českou národní bankou a\u00a0všechny prodeje cenných papírů '
	+ 'a\u00a0také i\u00a0přijaté dividendy se přepočítají do korun použitím tohoto jednotného kurzu. Zde je ale '
	+ 'důležité si uvědomit, že spárované nákupy uskutečněné v\u00a0předchozích letech musí být přepočteny '
	+ 'jednotným kurzem roku, ve kterém byly uskutečněny. Tento přístup využíváme ve Fondee v\u00a0našem ročním '
	+ 'daňovém výpisu pro klienty.',
];

const listExempt = [
	'„Časový test“ 3\u00a0roky\u00a0- pokud doba mezi nákupem a\u00a0prodejem cenného papíru přesáhne 3\u00a0roky, '
	+ 'pak je možnost osvobodit od daně veškeré příjmy z\u00a0prodeje. Pokud se tedy prodej ETF spáruje s\u00a0nákupem '
	+ 'starším než 3\u00a0roky, tak se realizovaný zisk vůbec nezdaňuje.',
	[
		'Druhá možnost osvobození příjmů z\u00a0prodeje cenných papírů cílí na drobné investory. Pokud se totiž veškeré '
		+ 'příjmy z\u00a0prodejů cenných papírů v\u00a0daném kalendářním roce vejdou do hranice 100\u00a0000\u00a0Kč, '
		+ 'jsou od daně osvobozeny. Koncept tohoto osvobození je celkem složitý. Je třeba dát si pozor hned na čtyři věci.',
		'Jedná se o\u00a0souhrn příjmů z\u00a0prodejů všech druhů cenných papírů dohromady u\u00a0všech společností. '
		+ 'Tuto hranici nelze počítat zvlášť například pro akcie, dluhopisy, podílové fondy a\u00a0ETF, stejně tak nelze '
		+ 'tuto hranici počítat zvlášť pro jednotlivé poskytovatele investic (například různé investiční platformy, brokery, '
		+ 'nebo podílové fondy).',
		'Jedná se skutečně o\u00a0hodnotu příjmů, to znamená prodejních cen daných cenných papírů. Nejde o\u00a0hodnotu '
		+ 'zisku z\u00a0daného prodeje.',
		'Tato hranice zahrnuje veškeré prodeje cenných papírů, tedy i\u00a0ty osvobozené na základě časového testu.',
		'Pokud je hranice 100\u00a0000\u00a0Kč překročena, zdaňují se veškeré příjmy, nejen ty, které tuto hranici překročí.',
	],
];

const listWhen = [
	'Nejprve se zastavíme u\u00a0investorů, kteří z\u00a0jakéhokoli důvodu podávají daňové přiznání. Typicky půjde '
	+ 'o\u00a0podnikatele, či pronajímatele nemovitostí. Daňové přiznání již znají jako své boty, případně ho pro ně '
	+ 'zpracovává specialista. Každopádně je třeba do daňového přiznání zahrnovat příjmy ze zahraničních dividend, ať '
	+ 'jsou v\u00a0jakékoliv výši. Jedná se o\u00a0příjmy z\u00a0kapitálového majetku podle §\u00a08 ZDP a\u00a0vypisují '
	+ 'se do řádku číslo 38\u00a0daňového přiznání. Příjmy z\u00a0prodeje cenných papírů do přiznání patří pouze '
	+ 'v\u00a0případě, že nejsou od daně osvobozené.',
	'A\u00a0jak jsou na tom ostatní investoři, kteří doposud daňové přiznání podávat nemuseli? V\u00a0zákoně existuje hranice '
	+ 'pro výši příjmů, od které je třeba podávat daňové přiznání. Je to 15\u00a0000\u00a0Kč ročně. Do této hranice '
	+ 'se přitom zahrnují veškeré příjmy, které nejsou od daně osvobozené a\u00a0ani z\u00a0nich není sražena srážková '
	+ 'daň. Do této hranice se tedy zahrnují mimo jiné příjmy z\u00a0podnikání nebo z\u00a0pronájmu nemovitosti. Zaměstnanci, '
	+ 'kteří podepíší u\u00a0zaměstnavatele daňové prohlášení („růžový formulář“) a\u00a0mají možnost si nechat zpracovat '
	+ 'roční zúčtování záloh na daň, mají pro povinnost podat daňové přiznání hranici nižší, a\u00a0to 6\u00a0000\u00a0Kč '
	+ 'ročně pro souhrn všech příjmů mimo zaměstnání. Opět je třeba upozornit na to, že tato hranice se týká zdanitelných příjmů, '
	+ 'ne zisku!',
];

const listWhenShort = [
	'inkasoval dividendy převyšující 15\u00a0000\u00a0Kč (resp. 6\u00a0000\u00a0Kč pro zaměstnance splňujícího výše '
	+ 'uvedené podmínky), nebo',
	'má neosvobozený příjem z\u00a0prodeje cenných papírů',
];

export const data = {
	id: slug,
	contentText: '',
	date,
	languageVersions: {
		en: null,
	},
	perex,
	previewImage: {
		src: previewImage,
		src2x: previewImage2x,
		src2xWebP: previewImage2xWebp,
		srcWebP: previewImageWebp,
	},
	slug,
	title,
	topics,
	updated,
};

export default function PostTaxes({
	baseUrl,
	blogUrl,
	blogPostUrl,
	blogPostETFUrl,
	feesUrl,
	handleTagClick,
	howItWorksUrl,
	postsRecommended,
	shareUrl,
	topReadPosts,
}) {
	return (
		<BlogPost
			blogUrl={blogUrl}
			blogPostId={slug}
			blogPostUrl={blogPostUrl}
			date={date}
			handleTagClick={handleTagClick}
			metaImage={`${baseUrl}/images/blog/fondee-og-blog-taxes.png`}
			perex={perex}
			perexRich={(
				<>
					Začali jste investovat a&nbsp;nemáte ještě úplně jasno v&nbsp;tom, jaký díl
					Vašich zisků budete odvádět na daních? A&nbsp;budou-li se Vás týkat nějaké nové povinnosti?
					V&nbsp;tomto článku vám vysvětlíme, jak na zdanění cenných papírů, mezi které patří akcie,
					dluhopisy, podílové listy nebo právě
					{' '}
					{blogPostETFUrl ? (
						<Link to={blogPostETFUrl}>ETF, do kterých můžete investovat prostřednictvím&nbsp;Fondee</Link>
					) : (
						<>ETF, do kterých můžete investovat prostřednictvím&nbsp;Fondee</>
					)}
					.
				</>
			)}
			postsRecommended={postsRecommended}
			shareUrl={shareUrl}
			title={title}
			topics={topics}
			topReadPosts={topReadPosts}
			updated={updated}
		>
			<p>
				Český daňový řád je skutečně velmi komplexní a&nbsp;proto budeme hned na úvod předpokládat, že:
			</p>
			<BlogPostList list={listPrerequisites} />
			<BlogPostImage
				breakpoint="md"
				src={taxesImageMobile}
				src2x={taxesImage2xMobile}
				srcWebP={taxesImageWebPMobile}
				src2xWebP={taxesImage2xWebPMobile}
				srcBreakpoint={taxesImage}
				srcBreakpoint2x={taxesImage2x}
				srcBreakpointWebP={taxesImageWebP}
				srcBreakpoint2xWebP={taxesImage2xWebP}
				alt="Daně"
			/>
			<BlogPostContent content={content} slugs={slugs} />
			<p>
				Pro ty z&nbsp;Vás, kteří hledají stručnou odpověď na daňovou otázku, jsme připravili velmi
				zjednodušené shrnutí hned na&nbsp;začátek. V&nbsp;dalších částech článku se tématu věnujeme
				detailněji a&nbsp;uvádíme v&nbsp;nich konkrétní příklady, které se vám možná budou hodit.
			</p>
			<h2 id={slugs[0]}>Přehledné shrnutí</h2>
			<p>
				Opravdu zjednodušeně můžeme říct, že se příjmy z&nbsp;investic dělí do dvou kategorií
				-&nbsp;osvobozené od&nbsp;daně, které není třeba danit vůbec, nebo zdanitelné.
				A&nbsp;pokud Vaše příjmy z&nbsp;investic spadají do&nbsp;druhé kategorie, ještě to nemusí
				znamenat, že je automaticky musíte danit. Stačí se vejít do limitu, který je následující:
			</p>
			<BlogPostList list={listLimits} />
			<BlogPostImage
				breakpoint="md"
				src={whenImageMobile}
				src2x={whenImage2xMobile}
				srcWebP={whenImageWebPMobile}
				src2xWebP={whenImage2xWebPMobile}
				srcBreakpoint={whenImage}
				srcBreakpoint2x={whenImage2x}
				srcBreakpointWebP={whenImageWebP}
				srcBreakpoint2xWebP={whenImage2xWebP}
				alt="Kdy podávám přiznání?"
			/>
			<p>
				V rámci investování do cenných papírů, jako jsou například ETF, se můžete setkat se dvěma druhy příjmů.
				Jaké to jsou?
			</p>
			<BlogPostList list={listIncomes} />
			<p>
				Obecný rámec už znáte, ale jak je (nejen) pro daně typické, drobné detaily mohou hrát velkou roli.
			</p>
			<h2 id={slugs[1]}>Jak pohlížet na zhodnocení</h2>
			<p>
				Jako investor určitě chodíte pravidelně okouknout hodnotu svého portfolia, neboli součet aktuálních
				cen všech Vašich cenných papírů. Vzrostla, zůstala stejná nebo klesa?
				{' '}
				<Link to={howItWorksUrl}>
					V&nbsp;případě investování s&nbsp;Fondee hodnota portfolia zobrazuje aktuální hodnotu nakoupených
					ETF a&nbsp;zůstatek hotovosti
				</Link>
				{' '}
				včetně připsaných dividend, to vše přepočteno do korun aktuálním kurzem.
			</p>
			<p>
				Pokud odečtete hodnotu vámi vložených prostředků, vidíte, jak si Vaše portfolio vede v&nbsp;reálném
				čase. Kladné zhodnocení Vašeho investičního účtu ještě ovšem neznamená, že je třeba Vaše zhodnocení
				zdanit. Zároveň je ale také možné, že budete mít daňovou povinnost i&nbsp;v&nbsp;případě, že je Vaše
				aktuální celkové zhodnocení záporné.
			</p>
			<p>
				V&nbsp;čem je tedy háček?
			</p>
			<p>
				Investor a&nbsp;daňový úřad totiž na zisk a&nbsp;ztrátu nahlíží jinou perspektivou. Přepneme-li se
				z&nbsp;investorského do daňového pohledu, pak klíčovou otázkou bude, kdy jsou zisky (či ztráty)
				z&nbsp;obchodů s&nbsp;cennými papíry již dosažené, tedy realizované. Tím je moment obdržení dividendy
				nebo úroku z&nbsp;dluhopisu nebo moment prodeje cenného papíru.
			</p>
			<BlogPostImage
				breakpoint="md"
				src={incomeImageMobile}
				src2x={incomeImage2xMobile}
				srcWebP={incomeImageWebPMobile}
				src2xWebP={incomeImage2xWebPMobile}
				srcBreakpoint={incomeImage}
				srcBreakpoint2x={incomeImage2x}
				srcBreakpointWebP={incomeImageWebP}
				srcBreakpoint2xWebP={incomeImage2xWebP}
				alt="Realizovaný získ"
			/>
			<h2 id={slugs[2]}>Dividendy a&nbsp;přijaté úroky</h2>
			<p>
				V&nbsp;tomto smyslu je jednoznačným zdanitelným příjmem obdržená dividenda nebo úrok z&nbsp;dluhopisu
				v&nbsp;daném kalendářním roce. Jak dividendy, tak úroky (neboli kupóny) z&nbsp;dluhopisů jsou příjmem
				z&nbsp;kapitálového majetku dle §8&nbsp;ZDP.
			</p>
			<p>
				Pokud
				{' '}
				{blogPostETFUrl ? (
					<Link to={blogPostETFUrl}>investujete do ETF</Link>
				) : (
					<>investujete do ETF</>
				)}
				{' '}
				nebo akcií, pak vám tyto cenné papíry mohou vyplácet dividendy. Dividenda je tedy výnosem z&nbsp;držby
				konkrétního ETF nebo akcie a&nbsp;je vyplácena průběžně na Váš investiční účet. Již v&nbsp;tomto
				okamžiku se jedná o&nbsp;zdanitelný příjem, nezáleží na tom, zda jste nějaké peníze
				z&nbsp;investičního účtu „vybrali“ a&nbsp;převedli na Váš běžný účet.
			</p>
			<BlogPostList list={listDividends} />
			<p>
				Že to zní složitě? V&nbsp;případě investování s&nbsp;Fondee najdete informaci o&nbsp;přijatých
				dividendách přehledně na ročním daňovém výpisu včetně přepočtu do korun. Jak to se skutečným zdaněním
				ETF dividend nakonec dopadne, bude záležet na tom, zda budete podávat daňové přiznání, jak
				vysvětlíme dále.
			</p>
			<h2 id={slugs[3]}>Prodej cenného papíru</h2>
			<p>
				Dalším okamžikem, kdy dojde k&nbsp;realizaci zisku či ztráty, je prodej konkrétního cenného
				papíru.
				{' '}
				<Link to={howItWorksUrl}>
					V&nbsp;případě investování s&nbsp;Fondee dochází k&nbsp;prodeji i&nbsp;v&nbsp;rámci
					rebalancování portfolia
				</Link>
				. Abyste zjistili, jaký zisk nebo ztrátu jste prodejem dosáhli, je třeba zjistit, jaký cenný
				papír z&nbsp;Vašeho portfolia byl prodán. Můžete vlastnit několik stejných cenných papírů,
				které jste nakoupili v&nbsp;různé dny a&nbsp;za různé ceny, a&nbsp;pokud jeden z&nbsp;nich
				prodáte. Který to byl? Ten který byl nakoupený první? Nebo ten poslední nakoupený?
			</p>
			<p>
				Jak spárovat nákupy a&nbsp;prodeje?
			</p>
			<p>
				Podle daňových předpisů si metodu párování můžeme vybrat libovolně. Existuje k&nbsp;tomu vícero
				přístupů, lze využít i&nbsp;průměrování nákupních cen. Obecně nejznámější jsou však dva
				přístupy: FIFO a&nbsp;LIFO.
			</p>
			<BlogPostList list={listFifoLifo} />
			<p>
				Nejběžnější a&nbsp;nejintuitivnější je využití metody FIFO. Díky tomu zjistíte, jak dlouho jste
				daný cenný papír drželi a&nbsp;kolik jste na něm vydělali nebo prodělali. Ve Fondee takové
				spárování nákupů a&nbsp;prodejů ETF připravujeme pro klienty v&nbsp;rámci ročního daňového výpisu.
			</p>
			<BlogPostImage
				breakpoint="md"
				src={fifolifoImageMobile}
				src2x={fifolifoImage2xMobile}
				srcWebP={fifolifoImageWebPMobile}
				src2xWebP={fifolifoImage2xWebPMobile}
				srcBreakpoint={fifolifoImage}
				srcBreakpoint2x={fifolifoImage2x}
				srcBreakpointWebP={fifolifoImageWebP}
				srcBreakpoint2xWebP={fifolifoImage2xWebP}
				alt="Prodej cenného papíru"
			/>
			<h2 id={slugs[4]}>Jak řešit přepočet na koruny?</h2>
			<p>
				Cenné papíry, které držíte, mohou být obchodovány v&nbsp;zahraničních měnách. Například ve
				Fondee probíhá celé investování v&nbsp;EUR. Otázkou potom je, jak pro daňové účely řešit
				přepočet všech hodnot na koruny. Existují dva přístupy:
			</p>
			<BlogPostList list={listFx} />
			<p>
				Přepočet na koruny musí být proveden pro všechny cenné papíry stejným způsobem. Není tedy
				možné kombinovat použití aktuálního a&nbsp;jednotného kurzu.
			</p>
			<BlogPostImage
				breakpoint="md"
				src={fxImageMobile}
				src2x={fxImage2xMobile}
				srcWebP={fxImageWebPMobile}
				src2xWebP={fxImage2xWebPMobile}
				srcBreakpoint={fxImage}
				srcBreakpoint2x={fxImage2x}
				srcBreakpointWebP={fxImageWebP}
				srcBreakpoint2xWebP={fxImage2xWebP}
				alt="Jak řešit přepočet na koruny?"
			/>
			<h2 id={slugs[5]}>Osvobození příjmů z&nbsp;prodeje cenných papírů</h2>
			<p>
				Velmi podstatnou informací pro investora je fakt, že dlouhodobé investice do cenných papírů
				jsou daňově výhodné, protože zákon umožňuje hned dvojí osvobození od daně při jejich prodeji.
			</p>
			<p>
				Existují dva typy osvobození od daně:
			</p>
			<BlogPostList list={listExempt} />
			<BlogPostImage
				breakpoint="md"
				src={exemptImageMobile}
				src2x={exemptImage2xMobile}
				srcWebP={exemptImageWebPMobile}
				src2xWebP={exemptImage2xWebPMobile}
				srcBreakpoint={exemptImage}
				srcBreakpoint2x={exemptImage2x}
				srcBreakpointWebP={exemptImageWebP}
				srcBreakpoint2xWebP={exemptImage2xWebP}
				alt="Osvobození od daně"
			/>
			<h2 id={slugs[6]}>
				Jak tedy vyčíslit neosvobozené příjmy z&nbsp;prodeje cenných papírů a&nbsp;zisk ke zdanění?
			</h2>
			<p>
				Dostáváme se tedy k&nbsp;nejdůležitější věci. Příjmy z&nbsp;prodejů cenných papírů, které
				nemůžeme od daně osvobodit, spadají do kategorie ostatních příjmů podle §&nbsp;10 zákona
				o&nbsp;daních z&nbsp;příjmů (dále „ZDP“). Konkrétně jde o&nbsp;druh ostatních příjmů
				s&nbsp;názvem „prodej cenných papírů“. V&nbsp;rámci tohoto druhu se přitom veškeré zisky
				a&nbsp;ztráty navzájem mohou kompenzovat.
			</p>
			<p>
				Jak jsme si již řekli u&nbsp;metody FIFO, celkový realizovaný zisk nebo ztrátu
				z&nbsp;investování za daný kalendářní rok spočítáme za všechny prodané cenné papíry (příjmy),
				se kterými jsme spárovali odpovídající nákupní ceny (výdaje). Celkový výsledek je tedy součet
				všech zisků a&nbsp;ztrát ze všech cenných papírů v&nbsp;daném roce.
			</p>
			<p>
				Kromě toho můžeme celkový zisk snížit o&nbsp;zaplacené poplatky, které mohou být součástí
				souvisejících výdajů. Zákon definuje tyto poplatky jako poplatky za obchodování na burze,
				které přímo souvisí se spárovanými prodejními transakcemi. Jinými slovy lze uplatnit poplatky
				za nákup a&nbsp;prodej těch cenných papírů, které byly v&nbsp;daném kalendářním roce prodány.
				Poplatky za nákup cenných papírů, které investor stále drží, ale uplatněny být nemohou. Obecně
				se ale jedná o&nbsp;poplatky související s&nbsp;obchodováním na&nbsp;burze.
				{' '}
				<Link to={feesUrl}>
					Poplatky za investování u&nbsp;Fondee
				</Link>
				{' '}
				jsou definovány jako poplatky za obhospodařování majetku. Názory odborníků na zahrnutí poplatků
				za obhospodařování majetku do souvisejících výdajů se různí.
			</p>
			<p>
				Máme-li k dispozici spárované prodeje s&nbsp;nákupy cenných papírů, jako je tomu na daňovém
				výpisu Fondee, můžeme sečíst veškeré prodejní ceny ETF (v&nbsp;korunách), které tvoří hodnotu
				příjmů z&nbsp;prodeje cenných papírů. Zároveň pak sečteme veškeré nákupní (pořizovací) ceny ETF
				(též v&nbsp;korunách) a&nbsp;případně související poplatky (jejich zahrnutí do výdajů jsme
				popisovali v&nbsp;odstavci výše), což nám umožní vyčíslit hodnotu souvisejících výdajů.
				V&nbsp;rámci tohoto druhu příjmů je třeba k&nbsp;prodejům ETF přidat i&nbsp;případné další
				zdanitelné prodeje cenných papírů v&nbsp;daném kalendářním roce. Vše se zkrátka potká na
				jednom řádku daňového přiznání v&nbsp;příloze číslo&nbsp;2.
			</p>
			<BlogPostImage
				breakpoint="md"
				src={lossImageMobile}
				src2x={lossImage2xMobile}
				srcWebP={lossImageWebPMobile}
				src2xWebP={lossImage2xWebPMobile}
				srcBreakpoint={lossImage}
				srcBreakpoint2x={lossImage2x}
				srcBreakpointWebP={lossImageWebP}
				srcBreakpoint2xWebP={lossImage2xWebP}
				alt="Zisk ke zdanění / ztráta"
			/>
			<h3>Příklad</h3>
			<p>
				Kompenzace zisků a&nbsp;ztrát v&nbsp;praxi funguje například takto: V&nbsp;rámci Fondee
				došlo k&nbsp;dvěma obchodům s&nbsp;ETF –&nbsp;prodej za 60&nbsp;000&nbsp;Kč spárován se
				souvisejícími výdaji 40&nbsp;000&nbsp;Kč (zisk 20&nbsp;000&nbsp;Kč), druhý prodej za
				20&nbsp;000&nbsp;Kč s&nbsp;výdaji za 25&nbsp;000&nbsp;Kč (ztráta 5&nbsp;000&nbsp;Kč). Mimo
				Fondee došlo ještě k&nbsp;prodeji akcií za 10&nbsp;000&nbsp;Kč s&nbsp;výdaji 8&nbsp;000&nbsp;Kč
				(zisk 2&nbsp;000&nbsp;Kč) a&nbsp;dalšímu prodeji akcií za 20&nbsp;000&nbsp;Kč s&nbsp;výdaji
				25&nbsp;000&nbsp;Kč (ztráta 5&nbsp;000&nbsp;Kč). Poslední prodej akcií přitom splňuje časový test,
				neboť k prodeji došlo déle než po třech letech od nákupu daných akcií.
			</p>
			<p>
				Posouzení: Prodeje ETF a&nbsp;první prodej akcií za 10&nbsp;000&nbsp;Kč nejsou osvobozené,
				jelikož v&nbsp;rámci stejného druhu příjmů, tj. prodeje cenných papírů, se v daném roce
				realizovaly příjmy ve výši 110&nbsp;000&nbsp;Kč. Poslední prodej akcií bude ovšem coby
				osvobozený příjem na základě časového testu vyjmut. Zdanitelné příjmy z&nbsp;prodeje cenných
				papírů celkem tedy budou ve výši 90&nbsp;000&nbsp;Kč. Související výdaje s&nbsp;těmito příjmy
				byly vyčísleny na 73&nbsp;000&nbsp;Kč a&nbsp;zdaňuje se celkový zisk 17&nbsp;000&nbsp;Kč.
				Ztráta 5&nbsp;000&nbsp;Kč z&nbsp;druhého obchodu s&nbsp;ETF kompenzuje ostatní zisky.
			</p>
			<p>
				Na závěr ještě upozorníme na fakt, že v&nbsp;rámci kategorie ostatních příjmů nelze vyčíslit
				celkovou ztrátu. Pokud k ní reálně došlo, je třeba související výdaje vyčíslit maximálně do
				výše příjmů, s&nbsp;výsledným ziskem ke zdanění ve výši nula.
			</p>
			<h2 id={slugs[7]}>Kdy podávat daňové přiznání?</h2>
			<p>
				Co z&nbsp;toho všeho plyne? Jak s&nbsp;těmito znalostmi o&nbsp;zdanění investic v&nbsp;praxi
				naložíme?
			</p>
			<BlogPostList list={listWhen} />
			<p>
				Zjednodušeně řečeno, daňové přiznání jako důsledek investování musí podat investor, který:
			</p>
			<BlogPostList list={listWhenShort} />
			<p>
				Pokud jsou Vaše přijaté dividendy nižší než uvedené hranice a&nbsp;příjmy z&nbsp;prodejů
				cenných papírů jsou osvobozené (viz bod&nbsp;6), pak daňové přiznání nepodáváte a&nbsp;zisky
				zůstanou nezdaněny.
			</p>
			<p>
				Pokud investor bude mít povinnost podat daňové přiznání, pak se bude chovat obdobně, jako
				investor, který daňové přiznání podává z&nbsp;jiného důvodu. Bude tedy do daňového přiznání
				zahrnovat veškeré příjmy ze zahraničních dividend a&nbsp;neosvobozené příjmy z&nbsp;prodeje
				cenných papírů.
			</p>
			<p>
				Toto shrnutí je samozřejmě zjednodušující, ale není třeba se obávat. Daňový výpis Fondee
				obsahuje přehledné shrnutí výsledků obchodování z&nbsp;daňového pohledu a&nbsp;s&nbsp;orientací
				v&nbsp;otázkách daňového přiznání pomůže investorům daňový dotazník.
			</p>
			<h2 id={slugs[8]}>Perličky na&nbsp;závěr</h2>
			<p>
				Daňové spletitosti jsou nekonečné. Pro doplnění celkového obrazu ještě přidáváme další střípky
				informací.
			</p>
			<p>
				Není pravdou, že pokud máme osvobozené příjmy, nemáme žádné daňové povinnosti. Jedna taková existuje,
				a&nbsp;to v&nbsp;případě, pokud
				{' '}
				<strong>osvobozený příjem překročí hranici 5&nbsp;000&nbsp;000&nbsp;Kč</strong>
				. Muselo by se jednat o&nbsp;jeden prodej ETF osvobozený na základě časového testu s&nbsp;prodejní
				cenou nad 5&nbsp;milionů&nbsp;Kč. Konkrétně to znamená, že pokud prodáte cenné papíry jednoho ISINu
				(identifikátor cenného papíru) během jednoho obchodního dne v&nbsp;objemu přesahujícím
				5&nbsp;milionů&nbsp;Kč, a&nbsp;tento příjem splňuje podmínku časového testu, pak je třeba prodej
				oznámit finančnímu úřadu. Jedná se o oznamovací povinnost podle §38v ZDP. Pokud nebude takový
				osvobozený příjem oznámen, hrozí obrovská pokuta až ve výši 15&nbsp;% tohoto příjmu.
			</p>
			<p>
				Osvobozené příjmy se započítávají do vlastních příjmů manžela či manželky pro účely zhodnocení
				hranice pro
				{' '}
				<strong>slevu na manžela či manželku</strong>
				. Co to přesně znamená? Podle §35ba odst.1 písm. b) ZDP má manžel/manželka nárok na slevu na
				manželku/manžela, který nemá vlastní příjmy vyšší než je stanovená hranice (nyní je to
				68&nbsp;000&nbsp;Kč za rok). Zákon však přesně vymezuje, které příjmy se do té hranice
				nezapočítávají (a&nbsp;ne všechny osvobozené příjmy od daně tam jsou vyjmenovány). Například se do
				vlastních příjmů započítávají příjmy z mateřské nebo z dědictví, ačkoli jsou osvobozené, ale
				nezapočítávají se příjmy z&nbsp;rodičovské. Co je důležité je, že osvobozené příjmy z&nbsp;prodeje
				cenných papírů se do vlastního příjmu manžela/manželky započítávají. Je tak možné, že limit ročních
				vlastních příjmů bude překročen a&nbsp;nárok na slevu na dani nebude moci být uplatněn.
			</p>
			<p>
				Novinkou roku&nbsp;2021 je novelizovaná
				{' '}
				<strong>paušální daň</strong>
				. Týká se podnikatelů bez DPH, kteří se přihlásili do paušálního režimu do 10.&nbsp;ledna daného
				roku a&nbsp;platí pouze jednu společnou zálohu na daň a&nbsp;sociální a&nbsp;zdravotní pojištění
				a&nbsp;nemají povinnost podávat daňové přiznání. Je přitom možné investovat a&nbsp;zároveň zůstat
				v&nbsp;paušálním režimu? Ano! Bez problémů to jde v&nbsp;případě, že budete mít veškeré příjmy
				z&nbsp;prodejů cenných papírů a&nbsp;dividendy (spolu s&nbsp;veškerými neosvobozenými a&nbsp;již
				nezdaněnými příjmy z&nbsp;kapitálového majetku, nájmů a&nbsp;ostatními příjmy) nedosáhnou hranice
				15&nbsp;000&nbsp;Kč. V&nbsp;případě, že bude tato hranice v&nbsp;průběhu roku neplánovaně překročena,
				paušální režim nebude porušen a&nbsp;může dál pokračovat, jen v&nbsp;daném roce bude třeba podat
				daňové přiznání a&nbsp;přehledy o&nbsp;příjmech OSVČ.
			</p>
			<p>
				Pokud Vám roční zúčtování záloh na daň provedl zaměstnavatel a&nbsp;dodatečně zjistíte,
				že jste měli povinnost podat daňové přiznání, nezoufejte. Požádejte mzdové oddělení
				o&nbsp;Potvrzení o&nbsp;zdanitelných příjmech, obsahující informaci o&nbsp;provedeném
				ročním zúčtování záloh na daň. Použijete ho při podání daňového přiznání a&nbsp;o&nbsp;hodnotu
				případného přeplatku na dani snížíte sražené zálohy na daň.
			</p>
			<p>
				Pokud jste dočetli až sem, gratulujeme k&nbsp;Vašemu zápalu pro pochopení daňové
				problematiky investování. Snad jsme vám ukázali, jak pár základními principy přetnout
				neuvěřitelně zamotaný gordický uzel složitých daňových ustanovení a&nbsp;mechanik.
				A&nbsp;teď směle za investicí!
			</p>
			<BlogPostImage
				breakpoint="md"
				src={messImageMobile}
				src2x={messImage2xMobile}
				srcWebP={messImageWebPMobile}
				src2xWebP={messImage2xWebPMobile}
				srcBreakpoint={messImage}
				srcBreakpoint2x={messImage2x}
				srcBreakpointWebP={messImageWebP}
				srcBreakpoint2xWebP={messImage2xWebP}
				alt="Daně - různé pohledy"
			/>
		</BlogPost>
	);
}

PostTaxes.propTypes = {
	baseUrl: PropTypes.string.isRequired,
	blogUrl: PropTypes.string.isRequired,
	blogPostUrl: PropTypes.func.isRequired,
	blogPostETFUrl: PropTypes.string,
	feesUrl: PropTypes.string.isRequired,
	handleTagClick: PropTypes.func.isRequired,
	howItWorksUrl: PropTypes.string.isRequired,
	postsRecommended: BlogPost.propTypes.postsRecommended,
	shareUrl: PropTypes.string.isRequired,
	topReadPosts: BlogPost.propTypes.topReadPosts,
};

PostTaxes.defaultProps = {
	blogPostETFUrl: null,
	postsRecommended: null,
	topReadPosts: null,
};
