import React from 'react';
import PropTypes from 'prop-types';

import isScreenWidthUp from '../../../../utils/isScreenWidthUp';
import useWindowSize from '../../../../utils/useWindowSize';

import styles from './ShimmerImage.module.scss';

export default function ShimmerImage({
	children,
	width,
	widthMobile,
	maxWidth,
	maxWidthMobile,
	height,
	heightMobile,
}) {
	const windowSize = useWindowSize();

	return (
		<div
			className={styles.image}
			style={{
				width: isScreenWidthUp(windowSize.width, 'lg') ? width : widthMobile,
				maxWidth: isScreenWidthUp(windowSize.width, 'lg') ? maxWidth : maxWidthMobile,
				height: isScreenWidthUp(windowSize.width, 'lg') ? height : heightMobile,
			}}
		>
			{children}
		</div>
	);
}

ShimmerImage.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	width: PropTypes.number,
	widthMobile: PropTypes.number,
	maxWidth: PropTypes.number,
	maxWidthMobile: PropTypes.number,
	height: PropTypes.number,
	heightMobile: PropTypes.number,
};

ShimmerImage.defaultProps = {
	children: null,
	width: null,
	widthMobile: null,
	maxWidth: null,
	maxWidthMobile: null,
	height: null,
	heightMobile: null,
};
