import React from 'react';
import PropTypes from 'prop-types';

import styles from './Button.module.scss';

export default function Button({
	disabled,
	icon,
	isFlat,
	isNarrow,
	isLink,
	isLong,
	isSmall,
	isSubmit,
	label,
	onClick,
	outline,
	outlineHeavy,
	tooltip,
	variant,
}) {
	const buttonEl = (
		<button
			className={`${styles.root} ${variant === 'orange' ? styles.orange : ''} ${isNarrow ? styles.narrow : ''} ${outline ? styles.outline : ''} ${outlineHeavy ? styles.outlineHeavy : ''} ${isFlat ? styles.flat : ''} ${isSmall ? styles.small : ''} ${isLink ? styles.variantLink : ''} ${isLong ? styles.long : ''}`.trim()}
			disabled={disabled}
			onClick={onClick}
			type={isSubmit ? 'submit' : 'button'}
		>
			{icon}
			{label}
		</button>
	);

	return tooltip ? (
		<div className={styles.tooltipWrap}>
			{buttonEl}
			<div className={styles.tooltip}>
				<p>
					{tooltip}
				</p>
			</div>
		</div>
	) : buttonEl;
}

Button.propTypes = {
	disabled: PropTypes.bool,
	icon: PropTypes.node,
	isFlat: PropTypes.bool,
	isNarrow: PropTypes.bool,
	isSmall: PropTypes.bool,
	isSubmit: PropTypes.bool,
	isLink: PropTypes.bool,
	isLong: PropTypes.bool,
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	outline: PropTypes.bool,
	outlineHeavy: PropTypes.bool,
	tooltip: PropTypes.string,
	variant: PropTypes.oneOf(['red', 'orange']),
};

Button.defaultProps = {
	disabled: false,
	icon: undefined,
	isFlat: false,
	isNarrow: false,
	isSmall: false,
	isSubmit: false,
	isLink: false,
	isLong: false,
	onClick: null,
	outline: false,
	outlineHeavy: false,
	tooltip: '',
	variant: 'red',
};
