import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CookieConsent, FooterLegal } from '../../components/common';
import { getCookieConsentValue } from '../../utils/cookieConsent';
import GTMProvider, { useTrackGTMEvent } from '../../utils/GTMProvider';
import InvestingHero from './InvestingHero';
import InvestingInflation from './InvestingInflation';
import InvestingPortfolio from './InvestingPortfolio';
import InvestingPriceGrowth from './InvestingPriceGrowth';
import InvestingTips from './InvestingTips';
import InvestingTryout from './InvestingTryout';

const cookieConsentActive = getCookieConsentValue(true) === null;

function TrackGTMPageview() {
	const trackGTMEvent = useTrackGTMEvent();

	useEffect(() => {
		trackGTMEvent('pageview', {
			page: {
				url: '/',
			},
		});
	}, [trackGTMEvent]);

	return null;
}

export default function Investing({
	gtmId,
	homepageUrl,
	cookiesUrl,
}) {
	const [t] = useTranslation();
	const investingHeroAnchor = t('anchors.inflation');
	const [showCookies, setShowCookies] = useState([cookieConsentActive, false]);

	return (
		<GTMProvider id={gtmId}>
			<CookieConsent
				cookiesUrl={cookiesUrl}
				personalInfoAgreementUrl={t('documentUrls.personalInfoAgreement')}
				showCookies={showCookies}
				setShowCookies={setShowCookies}
				investing
			/>
			<TrackGTMPageview />
			<InvestingHero investingHeroAnchor={investingHeroAnchor} />
			<InvestingPriceGrowth investingHeroAnchor={investingHeroAnchor} />
			<InvestingInflation />
			<InvestingPortfolio />
			<InvestingTips />
			<InvestingTryout homepageUrl={homepageUrl} />
			<footer role="contentinfo">
				<FooterLegal
					investing
					short
				/>
			</footer>
		</GTMProvider>
	);
}

Investing.propTypes = {
	gtmId: PropTypes.string,
	homepageUrl: PropTypes.string.isRequired,
	cookiesUrl: PropTypes.string.isRequired,
};

Investing.defaultProps = {
	gtmId: '',
};
