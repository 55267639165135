export default function convertUser(user) {
	if (user === null) {
		return null;
	}

	return {
		email: user.email,
		firstName: user.first_name,
		lastName: user.last_name,
		phonePrefix: user.phone_prefix,
		phoneNumber: user.phone_number,
		phonePrefixPlanned: user.phone_prefix_planned,
		phoneNumberPlanned: user.phone_number_planned,
		streetName: user.street_name,
		streetNumber: user.street_number,
		city: user.city,
		postalCode: user.postal_code,
		contactAddressStreetName: user.contact_address_street_name,
		contactAddressStreetNumber: user.contact_address_street_number,
		contactAddressCity: user.contact_address_city,
		contactAddressPostalCode: user.contact_address_postal_code,
		birthDate: user.birth_date,
		accountClosure: user.account_closure,
	};
}
