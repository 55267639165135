export const breakpoints = {
	xs: 0,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
	xxl: 1560,
};

export default function isScreenWidthUp(screenSize, breakpoint) {
	if (!(breakpoint in breakpoints)) {
		throw (
			new Error(
				`Undefined breakpoint value. Defined values are:
				${Object.keys(breakpoints).map((key) => ` ${key}:${breakpoints[key]}`)}.`,
			)
		);
	}
	return screenSize !== null && screenSize >= breakpoints[breakpoint];
}
