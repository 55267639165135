import PostETF, { data as postETFData } from './PostETF';
import PostTaxes, { data as postTaxesData } from './PostTaxes';

export default [
	{
		component: PostTaxes,
		data: postTaxesData,
	},
	{
		component: PostETF,
		data: postETFData,
	},
];
