import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { changeCashRatio } from '../../../../utils/api';
import { useTrackGTMEvent } from '../../../../utils/GTMProvider';
import { toNumber } from '../../../../utils/number';
import useFormatNumber from '../../../../utils/useFormatNumber';
import useRequestAuth from '../../../../utils/useRequestAuth';
import { Ranger } from '../../../forms';
import {
	Alert,
	Button,
	Modal,
	Tooltip,
} from '../../../ui';

import styles from './CashControl.module.scss';

const CASH_MIN = 2;
const CASH_MAX = 100;

const getPercentage = (string) => toNumber(string) * 100;

export default function CashControl({
	closed,
	isChild,
	planned,
	portfolioId,
	ratio,
	setCheckPortfolio,
}) {
	const changeCashRatioAuth = useRequestAuth(changeCashRatio);
	const [error, setError] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);
	const [t] = useTranslation();
	const trackGTMEvent = useTrackGTMEvent();
	const formatPercent = useFormatNumber({ style: 'percent' });

	useEffect(() => {
		setError(false);
	}, [isModalOpen]);

	let title = t('account.cashControl.titlePlanned', { current: formatPercent(ratio), planned: formatPercent(planned) });

	if (getPercentage(ratio) === getPercentage(planned)) {
		if (getPercentage(planned) > 2) {
			title = t('account.cashControl.titleSet', { amount: formatPercent(ratio) });
		} else {
			title = t('account.cashControl.title');
		}
	}

	return (
		<div className={styles.root}>
			<div className={styles.title}>
				{title}
				<span className={styles.titleIcon}>
					<Tooltip
						text={t('account.cashControl.titleTooltip')}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 512 512"
							width={15}
						>
							<path
								fill="currentColor"
								d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
							/>
						</svg>
					</Tooltip>
				</span>
			</div>
			{closed ? (
				<>
					<Button
						label={t('account.cashControl.button')}
						disabled
						outline
						isFlat
					/>
					<span className={styles.titleIcon}>
						<Tooltip
							text={t('account.cashControl.closedTooltip')}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512"
								width={15}
							>
								<path
									fill="currentColor"
									d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
								/>
							</svg>
						</Tooltip>
					</span>
				</>
			) : (
				<>
					<Tooltip text={t('account.cashControl.buttonTooltip')} trigger="mouseenter" touch={false}>
						<span>
							<Button
								disabled={isChild}
								isFlat
								label={t('account.cashControl.button')}
								onClick={() => setModalOpen(true)}
								outline
							/>
						</span>
					</Tooltip>
					<Formik
						initialValues={{
							cash: getPercentage(planned),
						}}
						onSubmit={async (values) => {
							setError(false);
							try {
								await changeCashRatioAuth(
									portfolioId,
									values.cash / 100,
								);
							} catch (e) {
								setError(true);
								return;
							}
							trackGTMEvent('interactions', {
								eventCategory: 'interactions',
								eventAction: 'buttonClick',
								eventLabel: 'zmenit_pomer_hotovosti',
							});
							setCheckPortfolio(true);
							setModalOpen(false);
						}}
					>
						{({
							setFieldValue, handleSubmit, isSubmitting, handleReset, values,
						}) => (
							<Modal
								isVisible={isModalOpen}
								onClose={() => {
									setModalOpen(false);
									handleReset();
								}}
								size="large"
								title={t('account.cashControl.modal.title')}
							>
								<form onSubmit={handleSubmit}>
									{error && (
										<Alert type="danger">
											{t('forms.error')}
										</Alert>
									)}
									<p className={styles.modalText}>
										{t('account.cashControl.modal.text')}
									</p>
									<Ranger
										min={CASH_MIN}
										minLabel={formatPercent(CASH_MIN / 100)}
										max={CASH_MAX}
										maxLabel={formatPercent(CASH_MAX / 100)}
										maxTooltip={t('account.cashControl.modal.maxTooltip')}
										id="cash"
										name="cash"
										onChange={setFieldValue}
										value={values.cash}
									/>
									<div className={styles.modalControl}>
										<div className={styles.modalControlItem}>
											<Button
												label={t('account.cashControl.modal.submit')}
												disabled={isSubmitting}
												isSubmit
											/>
										</div>
										<div className={styles.modalControlItem}>
											<Button
												label={t('account.cashControl.modal.cancel')}
												disabled={isSubmitting}
												outline
												onClick={() => {
													setModalOpen(false);
													handleReset();
												}}
											/>
										</div>
									</div>
								</form>
							</Modal>
						)}
					</Formik>
				</>
			)}
		</div>
	);
}

CashControl.propTypes = {
	closed: PropTypes.bool.isRequired,
	isChild: PropTypes.bool,
	planned: PropTypes.number.isRequired,
	portfolioId: PropTypes.number.isRequired,
	ratio: PropTypes.number.isRequired,
	setCheckPortfolio: PropTypes.func,
};

CashControl.defaultProps = {
	isChild: false,
	setCheckPortfolio: null,
};
