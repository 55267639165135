import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ButtonLink, Icon } from '../../../components/ui';
import ChildOnboardingCancel from '../ChildOnboardingCancel';

import styles from './ChildOnboarding.module.scss';

export default function ChildOnboarding({
	child,
	childOnboardingUrl,
	reloadChildrenList,
	reloadUser,
}) {
	const [t] = useTranslation();

	return (
		<div className={styles.root}>
			<div className={styles.content}>
				<div className={styles.icon}>
					<Icon type="danger" width={22} height={20} />
				</div>
				<h2 className={styles.title}>
					{child.firstName}
					{' '}
					{child.lastName}
				</h2>
				<div className={styles.wrap}>
					<ChildOnboardingCancel
						childId={child.id}
						reloadChildrenList={reloadChildrenList}
						reloadUser={reloadUser}
					/>
					<ButtonLink
						label={t('account.childList.finishOnboarding')}
						to={`${childOnboardingUrl}?childId=${child.id}`}
						outline
						isFlat
					/>
				</div>
			</div>
		</div>
	);
}

ChildOnboarding.propTypes = {
	child: PropTypes.shape({
		firstName: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
		lastName: PropTypes.string.isRequired,
	}).isRequired,
	childOnboardingUrl: PropTypes.string.isRequired,
	reloadChildrenList: PropTypes.func.isRequired,
	reloadUser: PropTypes.func.isRequired,
};
