import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BlogSearch } from '../../../../components/common';
import BlogTagFilter from '../BlogTagFilter';
import BlogTopRead from '../BlogTopRead';

import styles from './BlogMenu.module.scss';

export default function BlogMenu({
	blogPostUrl,
	handleTagClick,
	searchQuery,
	searchTags,
	searchTopRead,
	setSearchQuery,
	setSearchTopRead,
	topics,
	topReadPosts,
}) {
	const toggleRef = useRef(null);
	const wrapRef = useRef(null);
	const [navWrapOpen, setNavWrapOpen] = useState(false);
	const [t] = useTranslation();

	const handleKeyDownClose = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			setNavWrapOpen(false);
		}
	};

	const closeMenu = () => {
		setNavWrapOpen(false);
	};

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};

	const handleTagClickMenu = (tag) => {
		handleTagClick(tag);
		closeMenu();
	};

	return (
		<div
			ref={wrapRef}
			className={styles.wrap}
		>
			<div className={`${styles.root} ${styles.fixed}`.trim()}>
				<button
					ref={toggleRef}
					className={`${styles.toggle} ${navWrapOpen ? styles.toggleOpen : ''}`.trim()}
					onClick={() => setNavWrapOpen(!navWrapOpen)}
					title={t('header.menu')}
					type="button"
				>
					{t('blog.menu.title')}
				</button>
				<div className={`${styles.content} ${navWrapOpen ? styles.open : ''}`.trim()}>
					<div className={styles.title}>{t('blog.menu.title')}</div>
					<BlogSearch
						handleKeyDownClose={handleKeyDownClose}
						handleSearchChange={handleSearchChange}
						searchQuery={searchQuery}
					/>
					<BlogTagFilter
						handleTagClick={handleTagClickMenu}
						searchTags={searchTags}
						topics={topics}
					/>
					<BlogTopRead
						blogPostUrl={blogPostUrl}
						closeMenu={closeMenu}
						handleTagClick={handleTagClickMenu}
						searchTopRead={searchTopRead}
						setSearchTopRead={setSearchTopRead}
						topReadPosts={topReadPosts}
					/>
				</div>
			</div>
		</div>
	);
}

BlogMenu.propTypes = {
	blogPostUrl: PropTypes.func.isRequired,
	handleTagClick: PropTypes.func.isRequired,
	searchQuery: PropTypes.string,
	searchTags: BlogTagFilter.propTypes.searchTags,
	searchTopRead: PropTypes.bool.isRequired,
	setSearchQuery: PropTypes.func.isRequired,
	setSearchTopRead: PropTypes.func.isRequired,
	topics: BlogTagFilter.propTypes.topics,
	topReadPosts: BlogTopRead.propTypes.topReadPosts,
};

BlogMenu.defaultProps = {
	searchQuery: '',
	searchTags: null,
	topics: null,
	topReadPosts: null,
};
