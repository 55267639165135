/* global document, window */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { Container } from '../../../components/layout';
import { Loader, ScrollToTop } from '../../../components/ui';
import { useLanguage } from '../../../contexts/LocaleContext';
import blogTagShape from '../blogTagShape';
import BlogPostRecommended from './BlogPostRecommended';
import BlogPostReferences from './BlogPostReferences';
import BlogPostShare from './BlogPostShare';

import styles from './BlogPost.module.scss';

const cleanSpaces = (content) => (content !== null ? content.replace(/\u00A0|&nbsp;/g, ' ') : null);

export default function BlogPost({
	blogUrl,
	blogPostId,
	blogPostUrl,
	children,
	date,
	handleTagClick,
	metaDescription,
	metaImage,
	perex,
	perexRich,
	postsRecommended,
	postsRecommendedFilter,
	references,
	shareUrl,
	title,
	topics,
	topReadPosts,
	updated,
}) {
	const [t] = useTranslation();
	const language = useLanguage();
	const [display, setDisplay] = useState(false);
	const metaTitle = cleanSpaces(`${title}${t('blogPost.meta.titleSuffix')}`);
	const metaDescriptionAttribute = cleanSpaces(metaDescription ?? perex);

	// do not display content on first render to prevent jump to bottom before scrolling to top
	useEffect(() => {
		const timeoutId = setTimeout(() => setDisplay(true), 100);
		return () => clearTimeout(timeoutId);
	}, []);

	useEffect(() => {
		const anchor = window.location.hash.split('#')[1];
		if (display) {
			setTimeout(() => {
				document.getElementById(anchor)?.scrollIntoView({
					behavior: 'smooth',
				});
			}, 700);
		}
	}, [display]);

	const postsRecommendedFiltered = (
		postsRecommendedFilter
			? postsRecommended?.filter(postsRecommendedFilter)
			: postsRecommended
	) ?? null;

	return (
		<article className={styles.root}>
			<Helmet>
				<title>{metaTitle}</title>
				<meta content={metaDescriptionAttribute} name="description" />
				<meta content={metaTitle} property="og:title" />
				<meta content={metaDescriptionAttribute} property="og:description" />
				{metaImage && <meta content={metaImage} property="og:image" />}
				{metaImage && <meta content={metaImage} name="twitter:image" />}
			</Helmet>
			<Container variant="narrow">
				<header>
					<Link to={blogUrl} className={styles.linkBack}>
						{t('blog.back')}
					</Link>
					<div className={styles.headerContent}>
						<time className={styles.date} dateTime={date}>
							{updated && (
								<>
									{t('blogPost.updated')}
									{' '}
								</>
							)}
							{new Date(date).toLocaleString(language, { dateStyle: 'medium' })}
						</time>
						{(topics ?? [])?.map((topic) => (
							<button
								key={topic.id}
								className={styles.badgeButton}
								onClick={() => handleTagClick(topic.slug)}
								type="button"
							>
								{topic.name}
							</button>
						))}
						<h1 className={styles.title}>
							{title}
						</h1>
						{perexRich !== null ? (
							<div className={styles.perexRich}>
								{perexRich}
							</div>
						) : (perex && (
							<p className={styles.perex}>
								{perex}
							</p>
						))}
					</div>
				</header>
				<div className={styles.content}>
					{display ? children : <Loader />}
				</div>
				<BlogPostShare link={shareUrl} />
				{references && <BlogPostReferences references={references} />}
				<ScrollToTop title={t('scrollToTop.title')} />
			</Container>
			<BlogPostRecommended
				blogPostId={blogPostId}
				blogPostUrl={blogPostUrl}
				handleTagClick={handleTagClick}
				postsRecommended={postsRecommendedFiltered}
				topReadPosts={topReadPosts}
			/>
		</article>
	);
}

BlogPost.propTypes = {
	blogUrl: PropTypes.string.isRequired,
	blogPostId: PropTypes.string.isRequired,
	blogPostUrl: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	date: PropTypes.string.isRequired,
	handleTagClick: PropTypes.func.isRequired,
	metaDescription: PropTypes.string,
	metaImage: PropTypes.string,
	perex: PropTypes.string,
	perexRich: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	postsRecommended: BlogPostRecommended.propTypes.postsRecommended,
	postsRecommendedFilter: PropTypes.func,
	references: PropTypes.arrayOf(PropTypes.string),
	shareUrl: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	topics: PropTypes.arrayOf(blogTagShape.isRequired),
	topReadPosts: BlogPostRecommended.propTypes.topReadPosts,
	updated: PropTypes.bool,
};

BlogPost.defaultProps = {
	metaDescription: null,
	metaImage: null,
	perex: null,
	perexRich: null,
	postsRecommended: null,
	postsRecommendedFilter: null,
	references: null,
	topics: null,
	topReadPosts: null,
	updated: false,
};
