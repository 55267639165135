import React from 'react';
import PropTypes from 'prop-types';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { fetchFaqs } from '../../../utils/api';
import useFetch from '../../../utils/useFetch';
import { Loader } from '../../ui';
import FaqList from '../FaqList';

const useFetchFaqs = useFetch(fetchFaqs);

export default function FaqPublic({
	faqSectionAnchor,
}) {
	const country = useCountry();
	const language = useLanguage();
	const [faqs, loading] = useFetchFaqs({}, country, language);

	if (loading) return <Loader variant="large" />;

	return (
		<FaqList
			id={faqSectionAnchor}
			sections={faqs.sections ?? []}
		/>
	);
}

FaqPublic.propTypes = {
	faqSectionAnchor: PropTypes.string.isRequired,
};
