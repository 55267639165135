import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

import { useUserContext } from '../../../contexts/UserContext';

import styles from './AccountSelect.module.scss';

const responsiveSettings = [
	{
		breakpoint: 576,
		settings: {
			slidesToShow: 1,
			centerMode: true,
		},
	}, {
		breakpoint: 780,
		settings: {
			slidesToShow: 2,
		},
	}, {
		breakpoint: 1080,
		settings: {
			slidesToShow: 3,
		},
	}, {
		breakpoint: 1200,
		settings: {
			slidesToShow: 4,
		},
	}, {
		breakpoint: 1350,
		settings: {
			slidesToShow: 4,
		},
	}, {
		breakpoint: 1500,
		settings: {
			slidesToShow: 5,
		},
	},
];

const icons = {
	check: (
		<svg
			width={20}
			height={16}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
		>
			<path stroke="#4FBD5E" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" d="m2 9.25 4.5 4L18 2.75" />
		</svg>
	),
	processing: (
		<svg
			width={30}
			height={30}
			viewBox="0 0 64 64"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeLinejoin="round"
			strokeMiterlimit="2"
		>
			<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(5.074 -26.936) scale(1.02762)" />
			<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(-17.538 -26.619) scale(1.20166)" />
			<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="matrix(.3802 0 0 .3802 -2.085 19.179)" />
			<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="matrix(.48317 0 0 .48317 1.289 33.752)" />
			<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(16.138 36.19) scale(.60537)" />
			<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(30.551 25.014) scale(.74228)" />
			<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(33.962 3.65) scale(.84072)" />
			<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(25.674 -17.054) scale(.9284)" />
		</svg>
	),
};

const PrevArrow = (props) => {
	const { onClick, className } = props;
	const [t] = useTranslation();
	const isDisabled = className && className.includes('slick-disabled');

	return (
		<button
			className={styles.sliderArrow}
			disabled={isDisabled}
			type="button"
			onClick={onClick}
		>
			<svg xmlns="http://www.w3.org/2000/svg" width={22} height={24} fill="none">
				<path
					fill="#47202A"
					d="m12.1 3.136-1.043-1.04a1.125 1.125 0 0 0-1.593 0L.33 11.202a1.118 1.118 0 0 0 0 1.59l9.135 9.112c.442.44 1.156.44 1.593 0l1.044-1.04a1.124 1.124 0 0 0-.02-1.609l-5.662-5.38h13.506c.625 0 1.128-.502 1.128-1.125v-1.5c0-.623-.503-1.125-1.128-1.125H6.419l5.663-5.381c.46-.436.47-1.163.019-1.608Z"
				/>
			</svg>
			<p className={styles.text}>
				{t('clientCheck.profileSelect.prev')}
			</p>
		</button>
	);
};

PrevArrow.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
};

PrevArrow.defaultProps = {
	className: '',
	onClick: null,
};

const NextArrow = (props) => {
	const { onClick, className } = props;
	const [t] = useTranslation();
	const isDisabled = className && className.includes('slick-disabled');

	return (
		<button
			className={styles.sliderArrow}
			disabled={isDisabled}
			type="button"
			onClick={onClick}
		>
			<svg xmlns="http://www.w3.org/2000/svg" width={22} height={24} fill="none">
				<path fill="#47202A" d="m9.9 3.136 1.043-1.04a1.125 1.125 0 0 1 1.593 0l9.135 9.107c.442.44.442 1.153 0 1.59l-9.135 9.112c-.442.44-1.156.44-1.593 0l-1.044-1.04a1.124 1.124 0 0 1 .02-1.609l5.662-5.381H2.075A1.124 1.124 0 0 1 .947 12.75v-1.5c0-.623.503-1.125 1.128-1.125h13.506L9.918 4.744A1.116 1.116 0 0 1 9.9 3.136Z" />
			</svg>
			<p className={styles.text}>
				{t('clientCheck.profileSelect.next')}
			</p>
		</button>
	);
};

NextArrow.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
};

NextArrow.defaultProps = {
	className: '',
	onClick: null,
};

export default function AccountSelect({
	childId,
	childrenList,
	selectChild,
}) {
	const sliderRef = useRef();
	const [t] = useTranslation();
	const [user] = useUserContext();
	const userClientCheckDue = user !== null && (
		user.aml_check_due
		|| user.document_expiry_check_due
		|| user.personal_information_check_due
		|| user.questionnaire_check_due
	);

	return (
		<div className={styles.root}>
			<div className={styles.wrapper}>
				<Slider
					ref={sliderRef}
					arrows
					prevArrow={<PrevArrow />}
					nextArrow={<NextArrow />}
					centerMode={false}
					className={styles.slider}
					infinite={false}
					responsive={responsiveSettings}
					swipe
					variableWidth
					slidesToShow={6}
				>
					<div className={`${styles.wrap} ${childId === null ? styles.active : ''}`.trim()}>
						<button
							className={styles.box}
							title="name"
							type="button"
							onClick={() => selectChild(null)}
						>
							<div className={styles.inner}>
								<div className={styles.icon}>
									{userClientCheckDue ? icons.processing : icons.check}
								</div>
								<p className={styles.name}>
									{t('clientCheck.profileSelect.myAccount')}
								</p>
							</div>
						</button>
					</div>

					{childrenList.map((child, index) => (
						<div
							key={child.id}
							className={`${styles.wrap} ${childId === child.id ? styles.active : ''} ${childrenList.length - 1 === index ? styles.last : ''}`.trim()}
						>
							<button
								className={styles.box}
								title="name"
								type="button"
								onClick={() => selectChild(child.id)}
							>
								<div className={styles.inner}>
									<span className={styles.icon}>
										{child.clientCheckDue ? icons.processing : icons.check}
									</span>
									<p className={styles.name}>
										{child.firstName}
										{' '}
										{child.lastName}
									</p>
								</div>
							</button>
						</div>
					))}
				</Slider>
			</div>
		</div>
	);
}

AccountSelect.propTypes = {
	childId: PropTypes.number,
	childrenList: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number.isRequired,
		firstName: PropTypes.string.isRequired,
		lastName: PropTypes.string.isRequired,
	}).isRequired).isRequired,
	selectChild: PropTypes.func.isRequired,
};

AccountSelect.defaultProps = {
	childId: null,
};
