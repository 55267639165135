/* global FormData */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { postClientCheckDocumentUpload } from '../../../utils/api';
import useRequestAuth from '../../../utils/useRequestAuth';
import { FileField, RadioList } from '../../forms';
import { Alert, Button } from '../../ui';

import styles from './DocumentCheckUpload.module.scss';

export default function DocumentCheckUpload({
	childId,
	id,
	onSuccess,
}) {
	const [t] = useTranslation();
	const [error, setError] = useState(null);
	const postClientCheckDocumentUploadAuth = useRequestAuth(postClientCheckDocumentUpload);

	const options = [
		{
			label: t('onboarding.steps.documents.upload.fileTypes.identification'),
			value: 'nationalID',
		}, {
			label: t('onboarding.steps.documents.upload.fileTypes.passport'),
			value: 'passport',
		}, {
			label: t(childId ? 'onboarding.steps.documents.upload.fileTypes.birthCertificate' : 'onboarding.steps.documents.upload.fileTypes.drivingLicense'),
			value: childId ? 'birthCertificate' : 'drivingLicense',
		},
	];

	return (
		<div className={styles.root}>
			<div className={styles.wrap}>
				<Formik
					initialValues={{
						fileType: 'nationalID',
						fileFieldFront: undefined,
						fileFieldBack: undefined,
					}}
					onSubmit={async (values, { setErrors }) => {
						const formData = new FormData();
						const nameFileFront = `${values.fileFieldFront.name.replace(/(.)\.[^/.]*$/, '$1')}.${values.fileFieldFront.type.split('/')[1]}`;
						const nameFileBack = values.fileFieldBack !== undefined
							? `${values.fileFieldBack.name.replace(/(.)\.[^/.]*$/, '$1')}.${values.fileFieldBack.type.split('/')[1]}`
							: '';
						setError(null);

						if (values.fileType === 'nationalID') {
							formData.append('nationalIDFront', values.fileFieldFront, nameFileFront);
							formData.append('nationalIDBack', values.fileFieldBack, nameFileBack);
						} else if (values.fileType === 'passport') {
							formData.append('passport', values.fileFieldFront, nameFileFront);
						} else if (values.fileType === 'birthCertificate') {
							formData.append('birthCertificate', values.fileFieldFront, nameFileFront);
						} else if (values.fileType === 'drivingLicense') {
							formData.append('drivingLicenseFront', values.fileFieldFront, nameFileFront);
							formData.append('drivingLicenseBack', values.fileFieldBack, nameFileBack);
						}

						try {
							await postClientCheckDocumentUploadAuth(childId, formData);
						} catch (e) {
							const fieldErrors = {};

							const hasFieldErrors = Object.keys(fieldErrors).length > 0;
							if (hasFieldErrors) {
								setErrors(fieldErrors);
							} else {
								const formError = 'forms.error';
								setError(formError);
							}

							return;
						}

						onSuccess();
					}}
				>
					{({
						handleChange, handleSubmit, isSubmitting, values, setFieldValue,
					}) => (
						<form
							className={styles.form}
							onSubmit={handleSubmit}
						>
							{error !== null && (
								<Alert type="danger">
									{t(error)}
								</Alert>
							)}
							<div className={styles.selectWrap}>
								<RadioList
									id={`${id}-fileType`}
									name="fileType"
									options={options}
									onChange={(value) => handleChange(value)}
									value={values.fileType}
									horizontal
								/>
							</div>
							<FileField
								accept={['.jpeg', '.jpg', '.png']}
								id={`${id}-fileFieldFront`}
								name="fileFieldFront"
								maxFileSize={4096}
								label={t('onboarding.steps.documents.upload.frontSide')}
								setFieldValue={setFieldValue}
								onChange={(e) => {
									setFieldValue('fileFieldFront', e.currentTarget.files[0]);
								}}
								isLabelHidden
								required
							/>
							{values.fileType !== 'passport' && (
								<FileField
									accept={['.jpeg', '.jpg', '.png']}
									id={`${id}-fileFieldBack`}
									name="fileFieldBack"
									maxFileSize={4096}
									label={t('onboarding.steps.documents.upload.backSide')}
									setFieldValue={setFieldValue}
									onChange={(e) => {
										setFieldValue('fileFieldBack', e.currentTarget.files[0]);
									}}
									isLabelHidden
									required
								/>
							)}
							<div className={styles.buttons}>
								<Button
									label={t('onboarding.steps.documents.upload.submit')}
									disabled={
										isSubmitting
										|| !values.fileFieldFront
										|| (
											values.fileType !== 'passport'
											&& !values.fileFieldBack
										)
									}
									isSubmit
								/>
							</div>
						</form>
					)}
				</Formik>
			</div>
		</div>
	);
}

DocumentCheckUpload.propTypes = {
	childId: PropTypes.number,
	id: PropTypes.string.isRequired,
	onSuccess: PropTypes.func.isRequired,
};

DocumentCheckUpload.defaultProps = {
	childId: null,
};
