import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import useFormatNumber from '../../../utils/useFormatNumber';
import { Modal } from '../../ui';

import styles from './InvestorProfileDetail.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const responsiveSettings = [
	{
		breakpoint: 768,
		settings: {
			centerMode: false,
		},
	},
];

function getProductInfo(productId, products) {
	return products.filter(({ id }) => id === productId)[0];
}

export default function InvestorProfileDetailSlider({
	currentSlide,
	products,
	profiles,
	setCurrentSlide,
	showProducts,
}) {
	const [t] = useTranslation();
	const [isModalOpen, setModalOpen] = useState(false);
	const [activeSlide, setActiveSlide] = useState(currentSlide);
	const sliderRef = useRef();
	const trackGTMEvent = useTrackGTMEvent();
	const formatPercent = useFormatNumber({ style: 'percent', fractionDigits: 2 });

	useEffect(() => {
		if (sliderRef.current) {
			sliderRef.current?.slickGoTo(currentSlide);
			setActiveSlide(currentSlide);
		}
	}, [currentSlide, profiles]);

	return (
		<div className={styles.sliderWrap}>
			<div className={styles.sliderArrows}>
				<button
					type="button"
					className={styles.sliderArrow}
					onClick={() => sliderRef.current?.slickPrev()}
				>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={25} height={48} viewBox="0 0 25 48">
						<path stroke="#602918" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.65" d="m1.57 1.69 22.57 22.5-22.57 22.5" />
					</svg>
				</button>
				<button
					type="button"
					className={styles.sliderArrow}
					onClick={() => sliderRef.current?.slickNext()}
				>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={25} height={48} viewBox="0 0 25 48">
						<path stroke="#602918" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.65" d="m1.57 1.69 22.57 22.5-22.57 22.5" />
					</svg>
				</button>
			</div>
			<Slider
				ref={sliderRef}
				centerMode
				swipe={false}
				arrows={false}
				responsive={responsiveSettings}
				beforeChange={(oldSlide, newSlide) => setCurrentSlide(newSlide)}
				afterChange={() => {
					trackGTMEvent('interactions', {
						eventCategory: 'interactions',
						eventAction: 'slider',
						eventLabel: 'portfolio_slider',
					});
				}}
			>
				{profiles.map((investorProfile) => (
					<div
						key={investorProfile.id}
					>
						<div className={styles.sliderItem}>
							<img
								src={`${process.env.PUBLIC_URL}/images/faces/${investorProfile.faceImageFile}`}
								alt={investorProfile.name}
								width={149}
								height={149}
								className={styles.sliderItemImage}
							/>
							<div className={styles.sliderItemTitle}>
								{investorProfile.name}
								{' '}
								{t('investorProfileDetail.investorPostfix')}
							</div>
							<p className={styles.sliderItemDescription}>
								{investorProfile.description}
							</p>
							{showProducts && (
								<button
									type="button"
									className={styles.sliderItemLink}
									onClick={() => setModalOpen(true)}
									tabIndex="-1"
								>
									{t('investorProfileDetail.showProducts')}
								</button>
							)}
						</div>
					</div>
				))}
			</Slider>
			{showProducts && (
				<Modal
					isVisible={isModalOpen}
					onClose={() => setModalOpen(false)}
					title={t('investorProfileDetail.productsTitle')}
				>
					<div className={styles.tableWrap}>
						<table className={styles.table}>
							<tbody>
								{profiles[activeSlide]?.products.map((product) => (
									<tr key={product.id}>
										<td title={getProductInfo(product.productId, products)?.description}>
											{getProductInfo(product.productId, products)?.name}
										</td>
										<td className={styles.right}>
											{formatPercent(product.ratio)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Modal>
			)}
		</div>
	);
}

InvestorProfileDetailSlider.propTypes = {
	currentSlide: PropTypes.number.isRequired,
	products: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			description: PropTypes.string.isRequired,
		}),
	).isRequired,
	profiles: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			faceImageFile: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			description: PropTypes.string.isRequired,
			products: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.number.isRequired,
					productId: PropTypes.number.isRequired,
					ratio: PropTypes.string.isRequired,
				}),
			).isRequired,
		}),
	).isRequired,
	setCurrentSlide: PropTypes.func.isRequired,
	showProducts: PropTypes.bool,
};

InvestorProfileDetailSlider.defaultProps = {
	showProducts: true,
};
