import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import TransparentFee from '../TransparentFee';

import styles from './ProductFees.module.scss';

export default function ProductFees({
	howItWorksUrl,
	product,
}) {
	const [t] = useTranslation();

	return (
		<div className={styles.feeWrap}>
			<div className={styles.fee}>
				<h3 className={styles.title}>
					{t(`products.${product}.fees.title`)}
				</h3>
				<Trans i18nKey={`products.${product}.fees.text`}>
					<p className={styles.text}>
						<strong />
					</p>
				</Trans>
				<ul className={styles.list}>
					<Trans i18nKey={`products.${product}.fees.list`}>
						<li className={styles.listItem}>
							<strong />
							<Link to={`${howItWorksUrl}#${t('anchors.rebalancing')}`} />
						</li>
					</Trans>
				</ul>
			</div>
			<TransparentFee product={product} />
		</div>
	);
}

ProductFees.propTypes = {
	howItWorksUrl: PropTypes.string.isRequired,
	product: PropTypes.string.isRequired,
};
