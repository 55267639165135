import React from 'react';
import { useTranslation } from 'react-i18next';

import { FaqList } from '../../components/common';
import {
	Row,
	Col,
	Section,
	Container,
} from '../../components/layout';
import { useCountry, useLanguage } from '../../contexts/LocaleContext';
import { fetchFaqs } from '../../utils/api';
import useFetch from '../../utils/useFetch';

import styles from './Faq.module.scss';

const useFetchFaqs = useFetch(fetchFaqs);

const filterTopFaqs = ({ sections }, topValue) => (sections ?? []).map((section) => ({
	...section,
	faqs: section.faqs.filter(({ top }) => top === topValue),
})).filter(({ faqs }) => faqs.length > 0);

export default function Faq() {
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const [faqs, loading] = useFetchFaqs({}, country, language, 'client');

	if (loading) {
		return null;
	}

	const faqListSections = filterTopFaqs(faqs, false);
	const topSections = filterTopFaqs(faqs, true);

	const halfLength = Math.ceil(topSections.length / 2);
	const sides = [
		topSections.slice(0, halfLength),
		topSections.slice(halfLength),
	];

	return (
		<>
			<Section bordered>
				<Container>
					<Row>
						<Col lg={1} xl={2} />
						<Col lg={10} xl={8}>
							<div className={styles.root}>
								<h2 className={styles.title}>
									{t('faq.title')}
								</h2>
								<Row>
									{sides.map((side, i) => {
										const sideKey = `side${i}`;
										return (
											<Col lg={6} key={sideKey}>
												{side.map((section) => section.faqs.map((faq) => (
													<div key={faq.id}>
														<h3 className={styles.sectionTitle}>{faq.question}</h3>
														<p
															className={styles.sectionText}
															// eslint-disable-next-line react/no-danger
															dangerouslySetInnerHTML={{ __html: faq.answer }}
														/>
													</div>
												)))}
											</Col>
										);
									})}
								</Row>
							</div>
						</Col>
						<Col lg={1} xl={2} />
					</Row>
				</Container>
			</Section>
			<FaqList sections={faqListSections} />
		</>
	);
}
