/* global localStorage */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { fetchInvestorProfile } from '../../../utils/api';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import useFetch from '../../../utils/useFetch';
import FindOutInfoBox from '../FindOutInfoBox';
import InvestorProfileChart from '../InvestorProfileChart';
import InvestorProfilePreviewSlider from './InvestorProfilePreviewSlider';

import styles from './InvestorProfilePreview.module.scss';

const useFetchInvestorProfile = useFetch(fetchInvestorProfile);

export default function InvestorProfilePreview({
	esg,
	registrationUrl,
}) {
	const [t] = useTranslation();
	const navigate = useNavigate();
	const country = useCountry();
	const language = useLanguage();
	const [data, loading] = useFetchInvestorProfile({}, country, language);
	const [currentSlide, setCurrentSlide] = useState(3);
	const [hasSeenFindOutInfoBox, setHasSeenFindOutInfoBox] = useState(JSON.parse(localStorage.getItem('seen_find_out_profile_popup')) === true);
	const [isInfoBoxVisible, setIsInfoBoxVisible] = useState(false);
	const trackGTMEvent = useTrackGTMEvent();

	useEffect(() => {
		if (!hasSeenFindOutInfoBox && currentSlide !== 3) {
			setTimeout(() => {
				setIsInfoBoxVisible(true);
			}, 5000);
		}
	}, [currentSlide]);

	const handleInfoBoxClose = () => {
		setHasSeenFindOutInfoBox(true);
		setIsInfoBoxVisible(false);
		localStorage.setItem('seen_find_out_profile_popup', 'true');
	};

	const handleInfoBoxApprove = () => {
		handleInfoBoxClose();
		trackGTMEvent('interactions', {
			eventCategory: 'interactions',
			eventAction: 'buttonClick',
			eventLabel: 'portfolio_slider_popup_click',
		});
		if (registrationUrl) {
			navigate(registrationUrl);
		}
	};

	if (loading) return null;

	const investorProfiles = esg ? data.investorProfilesEsg : data.investorProfiles;

	return (
		<div className={styles.root}>
			<div className={styles.wallet}>
				<svg viewBox="0 0 125 123" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinecap="round" strokeMiterlimit="13">
					<path d="M31.579 40.581h65.085M97.702 40.345a7.86 7.86 0 00-7.856-7.855H30.672a7.86 7.86 0 00-7.855 7.855v51.663a7.86 7.86 0 007.855 7.855h59.174a7.86 7.86 0 007.856-7.855V40.345z" fill="none" stroke="#e92420" strokeWidth="3.5" />
					<path d="M41.51 40.542l26.822-16.246c5.028-3.046 11.467-1.236 14.37 4.039l6.718 12.207H41.51zM89.696 58.502c.222 0 .443.009.661.027a1.88 1.88 0 01.321-.027h9.074c1.037 0 1.878.841 1.878 1.877v14.404a1.878 1.878 0 01-1.878 1.878h-9.074a1.88 1.88 0 01-.321-.027 8.042 8.042 0 01-.661.027h-5.371a7.892 7.892 0 01-7.888-7.887v-2.385a7.892 7.892 0 017.888-7.887h5.371z" fill="#fff" stroke="#e92420" strokeWidth="3.5" />
					<circle cx="86.186" cy="67.917" r="3.777" fill="#e92420" />
				</svg>
				<div className="h5">{t('investorProfilePreview.walletTitle')}</div>
			</div>
			<div className={styles.chevron}>
				<svg viewBox="0 0 512 512" width={50} height={50} xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
					<path d="M386.235 248.308L140.902 2.975c-4.267-4.053-10.987-3.947-15.04.213a10.763 10.763 0 000 14.827l237.76 237.76-237.76 237.867c-4.267 4.053-4.373 10.88-.213 15.04 4.053 4.267 10.88 4.373 15.04.213l.213-.213 245.333-245.333a10.624 10.624 0 000-15.041z" fill="#47202a" fillRule="nonzero" stroke="#47202a" />
				</svg>
			</div>
			<InvestorProfilePreviewSlider
				profiles={investorProfiles ?? []}
				currentSlide={currentSlide}
				setCurrentSlide={setCurrentSlide}
			/>
			<div className={styles.chevron}>
				<svg viewBox="0 0 512 512" width={50} height={50} xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
					<path d="M386.235 248.308L140.902 2.975c-4.267-4.053-10.987-3.947-15.04.213a10.763 10.763 0 000 14.827l237.76 237.76-237.76 237.867c-4.267 4.053-4.373 10.88-.213 15.04 4.053 4.267 10.88 4.373 15.04.213l.213-.213 245.333-245.333a10.624 10.624 0 000-15.041z" fill="#47202a" fillRule="nonzero" stroke="#47202a" />
				</svg>
			</div>
			{investorProfiles && investorProfiles[currentSlide] && (
				<InvestorProfileChart
					categories={data.productCategories ?? []}
					profile={investorProfiles[currentSlide]}
				/>
			)}
			{isInfoBoxVisible && (
				<FindOutInfoBox
					onDismiss={handleInfoBoxClose}
					onApprove={handleInfoBoxApprove}
				/>
			)}
		</div>
	);
}

InvestorProfilePreview.propTypes = {
	esg: PropTypes.bool,
	registrationUrl: PropTypes.string,
};

InvestorProfilePreview.defaultProps = {
	esg: false,
	registrationUrl: '',
};
