import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function EmailLink({
	className,
	children,
}) {
	const [t] = useTranslation();

	return (
		<a
			href={`mailto:${t('email.address')}`}
			className={className}
		>
			{children}
		</a>
	);
}

EmailLink.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

EmailLink.defaultProps = {
	className: null,
	children: null,
};
