import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './PortfolioRanger.module.scss';

export default function PortfolioRanger({
	currentProfile,
	esgPlanned,
	plannedProfile,
	investorProfiles,
	investorProfilesEsg,
	recommendedProfiles,
	recommendedProfilesEsg,
}) {
	const [t] = useTranslation();

	const profiles = esgPlanned ? investorProfilesEsg : investorProfiles;
	const profilesRecommend = esgPlanned ? recommendedProfilesEsg : recommendedProfiles;
	const profilesRecommendIndexes = profilesRecommend ? profilesRecommend.map((profileRecommend) => {
		const profileRecommendId = profiles.filter(({ id }) => id === profileRecommend);
		return profileRecommendId.length ? profiles.indexOf(profileRecommendId[0]) : -1;
	}).filter((index) => index >= 0) : [];

	const barWidthClass = profilesRecommendIndexes.length > 0
		? styles[`rangerRecommendBar${Math.max(...profilesRecommendIndexes) - Math.min(...profilesRecommendIndexes) + 1}`] ?? ''
		: '';
	const barOffsetClass = profilesRecommendIndexes.length > 0
		? styles[`rangerRecommendBarOffset${Math.min(...profilesRecommendIndexes)}`] ?? ''
		: '';

	return (
		<div className={styles.ranger}>
			<div className={styles.rangerTitles}>
				<div
					className={styles.rangerTitle}
					title={t('investorProfileDetail.ranger.info')}
				>
					{t('investorProfileDetail.ranger.less1')}
					<br />
					{t('investorProfileDetail.ranger.less2')}
				</div>
				<div
					className={styles.rangerTitle}
					title={t('investorProfileDetail.ranger.info')}
				>
					{t('investorProfileDetail.ranger.more1')}
					<br />
					{t('investorProfileDetail.ranger.more2')}
				</div>
			</div>
			<div className={styles.rangerWrap}>
				<div className={styles.rangerSeparators}>
					{profilesRecommend && profilesRecommendIndexes.length > 0 && (
						<div className={styles.rangerRecommend}>
							<div className={`${styles.rangerRecommendBar} ${barWidthClass} ${barOffsetClass}`.trim()} />
						</div>
					)}
					{profiles.map((profile, index) => (
						<span key={profile.id}>
							{(
								investorProfiles[index]?.id === currentProfile
								|| investorProfilesEsg[index]?.id === currentProfile
							) && (
								<div className={styles.currentPosition}>
									<svg xmlns="http://www.w3.org/2000/svg" width="21" height="17" viewBox="0 0 21 17" fill="none">
										<path d="M11.7672 15.4336C11.1783 16.3635 9.82171 16.3635 9.23277 15.4336L1.54979 3.30258C0.917263 2.30385 1.63484 1 2.81702 1H18.183C19.3652 1 20.0827 2.30385 19.4502 3.30258L11.7672 15.4336Z" fill="#D84E4B" stroke="white" />
									</svg>
								</div>
							)}
							{profile.id === plannedProfile && (
								<div className={styles.plannedPosition}>
									<svg xmlns="http://www.w3.org/2000/svg" width="21" height="17" viewBox="0 0 21 17" fill="none">
										<path d="M11.7672 1.5664C11.1783 0.636504 9.82171 0.636499 9.23277 1.5664L1.54979 13.6974C0.917263 14.6961 1.63484 16 2.81702 16H18.183C19.3652 16 20.0827 14.6962 19.4502 13.6974L11.7672 1.5664Z" fill="#48B958" stroke="white" />
									</svg>
								</div>
							)}
						</span>
					))}
				</div>
			</div>
		</div>
	);
}

PortfolioRanger.propTypes = {
	currentProfile: PropTypes.number.isRequired,
	esgPlanned: PropTypes.bool.isRequired,
	plannedProfile: PropTypes.number.isRequired,
	investorProfiles: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
		}).isRequired,
	).isRequired,
	investorProfilesEsg: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
		}).isRequired,
	).isRequired,
	recommendedProfiles: PropTypes.arrayOf(PropTypes.number.isRequired),
	recommendedProfilesEsg: PropTypes.arrayOf(PropTypes.number.isRequired),
};

PortfolioRanger.defaultProps = {
	recommendedProfiles: null,
	recommendedProfilesEsg: null,
};
