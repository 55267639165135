import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
} from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

import useFocusOnError from '../../../utils/useFocusOnError';

import styles from './MultiSelectField.module.scss';

// eslint-disable-next-line react/prop-types
const Control = ({ children, ...props }) => (
// eslint-disable-next-line react/jsx-props-no-spreading
	<components.Control {...props}>
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={24} height={24} viewBox="0 0 24 24">
			<path
				fill="#555"
				d="M14 13v6c0 .3-.1.5-.3.7a1 1 0 0 1-.7.3h-2a1 1 0 0 1-.7-.3 1 1 0 0 1-.3-.7v-6L4.2 5.6a1 1 0 0 1-.1-1c.2-.4.5-.6.9-.6h14c.4 0 .7.2 1 .5 0 .4 0 .8-.2 1.1L14 13Zm-2 .1 6-7.6H6l6 7.6Z"
			/>
		</svg>
		{children}
	</components.Control>
);

const Option = (props) => {
	// eslint-disable-next-line react/prop-types,react/destructuring-assignment
	const { selected = props.isSelected } = props.selectProps;
	// eslint-disable-next-line react/prop-types,react/destructuring-assignment
	const { label = props.label } = props.selectProps;
	return (
		<div>
			{/* eslint-disable-next-line react/jsx-props-no-spreading */}
			<components.Option {...props}>
				<label className={styles.inputWrap} htmlFor={label}>
					<input
						type="checkbox"
						className={styles.input}
						checked={selected}
						onChange={() => null}
						id={label}
					/>
					<span className={styles.optionLabel}>
						{label}
					</span>
				</label>
			</components.Option>
		</div>
	);
};

export default function MultiSelectField({
	autoComplete,
	closeMenuOnSelect,
	onBlur,
	onChange,
	error,
	disabled,
	hideSelectedOptions,
	id,
	isLabelHidden,
	isMulti,
	label,
	name,
	noOptionsMessage,
	options,
	placeholder,
	required,
	searchable,
	value,
}) {
	const handleChange = useCallback((data) => {
		if (onChange) {
			onChange(name, Array.isArray(data) ? data.map((x) => x.value) : []);
		}
	}, [onChange, name]);

	const blurred = useRef(null);
	const elementRef = useRef();
	const selectRef = useRef();

	const handleBlur = useCallback((e) => {
		if (e.persist) {
			e.persist();
		}
		blurred.current = e;
	}, []);

	useFocusOnError(name, selectRef, elementRef);

	useEffect(() => {
		if (blurred.current) {
			if (onBlur !== null) {
				onBlur(name)(blurred.current);
			}
			blurred.current = null;
		}
	});

	const selectValue = useMemo(() => options.filter((obj) => value.includes(obj.value)), [options, value]);

	return (
		<div ref={elementRef} className={styles.root}>
			<div className={`${styles.labelWrap} ${isLabelHidden ? styles.labelWrapHidden : ''}`.trim()}>
				<label className={styles.label} htmlFor={id}>{label}</label>
			</div>
			<div
				className={`${styles.selectFieldWrap} ${error && styles.selectFieldWrapError}`.trim()}
			>
				<Select
					ref={selectRef}
					allowSelectAll
					autoComplete={autoComplete}
					blurInputOnSelect={false}
					components={{ Control, Option }}
					classNamePrefix="selectField"
					closeMenuOnSelect={closeMenuOnSelect}
					closeOnSelect={false}
					hideSelectedOptions={hideSelectedOptions}
					inputId={id}
					isDisabled={disabled}
					isSearchable={searchable}
					isMulti={isMulti}
					name={name}
					noOptionsMessage={() => noOptionsMessage}
					onChange={handleChange}
					onBlur={handleBlur}
					openMenuOnFocus
					options={options}
					placeholder={placeholder}
					required={required}
					value={selectValue}
				/>
			</div>
			{error && (
				<div className={styles.error}>{error}</div>
			)}
		</div>
	);
}

MultiSelectField.propTypes = {
	autoComplete: PropTypes.string,
	closeMenuOnSelect: PropTypes.bool,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	error: PropTypes.string,
	disabled: PropTypes.bool,
	hideSelectedOptions: PropTypes.bool,
	id: PropTypes.string.isRequired,
	isLabelHidden: PropTypes.bool,
	isMulti: PropTypes.bool,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	noOptionsMessage: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.shape({
		isDisabled: PropTypes.bool,
		label: PropTypes.string.isRequired,
		value: PropTypes.oneOfType([
			PropTypes.string.isRequired,
			PropTypes.number.isRequired,
		]).isRequired,
	}).isRequired).isRequired,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	searchable: PropTypes.bool,
	value: PropTypes.arrayOf(PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.number.isRequired,
	])),
};

MultiSelectField.defaultProps = {
	autoComplete: null,
	closeMenuOnSelect: false,
	onBlur: null,
	onChange: null,
	error: '',
	disabled: false,
	hideSelectedOptions: false,
	isLabelHidden: false,
	isMulti: true,
	noOptionsMessage: '',
	placeholder: '',
	required: false,
	searchable: false,
	value: undefined,
};
