import getCookieValue from './getCookieValue';
import setCookieValue from './setCookieValue';

export const COOKIE_CONSENT_NAME = 'fondee_cookie_consent';
export const INVESTING_COOKIE_CONSENT_NAME = 'investing_cookie_consent';
export const COOKIE_CONSENT_REVISION = 1;

export function getCookieConsentValue(investing = false) {
	let value;
	try {
		value = JSON.parse(decodeURIComponent(getCookieValue(
			investing ? INVESTING_COOKIE_CONSENT_NAME : COOKIE_CONSENT_NAME,
		)));
	} catch {
		return null;
	}
	if (value?.revision !== COOKIE_CONSENT_REVISION || !Array.isArray(value?.categories)) {
		return null;
	}
	return value.categories;
}

export function setCookieConsentValue(categories, uuid, investing = false) {
	const cookieValue = encodeURIComponent(JSON.stringify({
		categories,
		revision: COOKIE_CONSENT_REVISION,
		uuid,
	}));
	setCookieValue(investing ? INVESTING_COOKIE_CONSENT_NAME : COOKIE_CONSENT_NAME, 365, cookieValue);
}
