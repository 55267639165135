import React from 'react';

export default function Logo() {
	return (
		<svg viewBox="0 0 1051 269" width="143" height="37" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
			<path d="M342.461 199.453h-18.008c-2.971 0-4.45-1.48-4.45-4.446V67.677c0-2.966 1.479-4.45 4.45-4.45h67.583c2.82 0 4.233 1.484 4.233 4.45v14.405c0 2.967-1.412 4.45-4.233 4.45h-42.575c-1.704 0-2.55.775-2.55 2.33v32.837c0 1.696.846 2.546 2.55 2.546h36.22c2.968 0 4.45 1.483 4.45 4.45v14.191c0 3.109-1.482 4.663-4.45 4.663h-36.22c-1.704 0-2.55.775-2.55 2.333v45.125c0 2.967-1.483 4.446-4.45 4.446" fill="currentColor" fillRule="nonzero" />
			<path d="M494.073 199.453h-23.73c-11.166 0-19.778-2.967-25.853-8.896-6.071-5.933-9.109-14.475-9.109-25.637V97.76c0-11.158 3.038-19.704 9.109-25.637 6.075-5.93 14.687-8.896 25.854-8.896h23.729c11.158 0 19.767 2.967 25.846 8.896 6.07 5.933 9.112 14.479 9.112 25.637v67.159c0 11.162-3.041 19.704-9.112 25.637-6.08 5.93-14.688 8.896-25.846 8.896m-19.5-23.304h15.046c4.525 0 7.737-.95 9.646-2.859 1.904-1.908 2.854-5.116 2.854-9.641V99.032c0-4.52-.95-7.733-2.854-9.642-1.909-1.908-5.121-2.858-9.646-2.858h-15.046c-4.517 0-7.696.95-9.533 2.858-1.83 1.909-2.746 5.121-2.746 9.642v64.617c0 4.525.916 7.733 2.746 9.641 1.837 1.909 5.016 2.859 9.533 2.859" fill="currentColor" fillRule="nonzero" />
			<path d="M595.275 199.453h-17.58c-2.974 0-4.453-1.48-4.453-4.446V67.677c0-2.966 1.479-4.45 4.454-4.45h15.883c2.967 0 4.942.988 5.942 2.968l42.787 80.295h.85V67.678c0-2.967 1.488-4.45 4.455-4.45h17.575c2.966 0 4.458 1.483 4.458 4.45v127.33c0 2.966-1.492 4.445-4.458 4.445H649.72c-3.108 0-5.367-1.412-6.775-4.237l-42.375-78.813h-.85v78.604c0 2.967-1.48 4.446-4.446 4.446" fill="currentColor" fillRule="nonzero" />
			<path d="M715.38 195.006V67.676c0-2.966 1.48-4.446 4.446-4.446h52.333c11.017 0 19.588 3 25.734 9 6.141 6.005 9.225 14.517 9.225 25.534v67.158c0 11.017-3.084 19.53-9.225 25.53-6.146 6.004-14.717 9.004-25.734 9.004h-52.333c-2.967 0-4.446-1.484-4.446-4.45m29.238-18.855h23.091c4.521 0 7.734-.954 9.638-2.862 1.908-1.904 2.862-5.117 2.862-9.638v-64.62c0-4.517-.954-7.734-2.862-9.638-1.904-1.908-5.117-2.863-9.638-2.863h-23.091c-1.559 0-2.334.78-2.334 2.334v84.958c0 1.55.775 2.33 2.334 2.33" fill="currentColor" fillRule="nonzero" />
			<path d="M925.036 199.453h-69.275c-2.97 0-4.454-1.48-4.454-4.446V67.677c0-2.966 1.483-4.45 4.454-4.45h69.275c2.82 0 4.238 1.484 4.238 4.45v14.405c0 2.967-1.417 4.45-4.238 4.45h-44.275c-1.7 0-2.546.775-2.546 2.33v27.54c0 1.555.846 2.33 2.546 2.33h36.22c2.967 0 4.455 1.483 4.455 4.45v14.408c0 2.967-1.488 4.45-4.454 4.45H880.76c-1.7 0-2.546.775-2.546 2.33v29.45c0 1.554.846 2.329 2.546 2.329h44.275c2.82 0 4.238 1.483 4.238 4.45v14.408c0 2.967-1.417 4.446-4.238 4.446" fill="currentColor" fillRule="nonzero" />
			<path d="M1045.93 199.453h-69.28c-2.966 0-4.445-1.48-4.445-4.446V67.677c0-2.966 1.48-4.45 4.446-4.45h69.279c2.82 0 4.238 1.484 4.238 4.45v14.405c0 2.967-1.417 4.45-4.238 4.45h-44.28c-1.695 0-2.54.775-2.54 2.33v27.54c0 1.555.845 2.33 2.54 2.33h36.23c2.962 0 4.45 1.483 4.45 4.45v14.408c0 2.967-1.488 4.45-4.45 4.45h-36.23c-1.695 0-2.54.775-2.54 2.33v29.45c0 1.554.845 2.329 2.54 2.329h44.28c2.82 0 4.238 1.483 4.238 4.45v14.408c0 2.967-1.417 4.446-4.238 4.446" fill="currentColor" fillRule="nonzero" />
			<path d="M175.082 54.496c43.904 0 79.508 35.604 79.508 79.567 0 43.9-35.604 79.508-79.508 79.508a79.85 79.85 0 01-19.246-2.354 80.236 80.236 0 003.296-22.717c0-21.07-8.238-40.254-21.654-54.437 13.358-14.184 21.654-33.371 21.654-54.496a78.23 78.23 0 00-3.354-22.717 80.331 80.331 0 0119.304-2.354" fill="#a5516b" fillRule="nonzero" />
			<path d="M155.778 56.85a78.217 78.217 0 013.355 22.716c.002 21.124-8.298 40.315-21.659 54.494-10.239-10.944-23.598-19.005-38.661-22.713 7.942-26.838 29.659-47.674 56.965-54.497" fill="#743a4b" fillRule="nonzero" />
			<path d="M159.132 188.5a80.337 80.337 0 01-3.292 22.716c-27.366-6.768-49.086-27.662-57.032-54.496 15.008-3.707 28.371-11.714 38.668-22.66 13.415 14.183 21.656 33.369 21.656 54.44" fill="#733922" fillRule="nonzero" />
			<path d="M79.567 0c36.016 0 66.5 23.954 76.212 56.85-27.308 6.824-49.025 27.658-56.966 54.495a79.85 79.85 0 00-19.246-2.354c-22.834 0-43.43 9.654-57.909 25.071C8.238 119.879 0 100.695 0 79.566 0 35.604 35.604 0 79.567 0" fill="#e94549" fillRule="nonzero" />
			<path d="M155.838 211.216c-9.77 32.9-40.254 56.85-76.27 56.85C35.604 268.066 0 232.462 0 188.5c0-21.07 8.18-40.254 21.659-54.437 14.475 15.42 35.075 25.012 57.908 25.012a79.79 79.79 0 0019.242-2.354c7.946 26.834 29.662 47.73 57.029 54.496" fill="#f39200" fillRule="nonzero" />
			<path d="M98.811 111.347c15.067 3.708 28.425 11.77 38.667 22.717-10.3 10.945-23.659 18.95-38.667 22.658-2.175-7.18-3.296-14.77-3.296-22.658 0-7.888 1.18-15.538 3.296-22.717" fill="#fff" fillRule="nonzero" />
			<path d="M98.81 111.347a80.363 80.363 0 00-3.295 22.717c0 7.887 1.12 15.479 3.296 22.658a79.824 79.824 0 01-19.246 2.354c-22.833 0-43.43-9.596-57.908-25.012 14.479-15.421 35.075-25.071 57.908-25.071 6.654 0 13.067.825 19.246 2.354" fill="#c60c0e" fillRule="nonzero" />
		</svg>
	);
}
