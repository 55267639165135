import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

import { filterChild, useUserContext } from '../../../../contexts/UserContext';
import { fetchChildrenList, portfolioWithdrawal } from '../../../../utils/api';
import useFetchAuth from '../../../../utils/useFetchAuth';
import { useTrackGTMEvent } from '../../../../utils/GTMProvider';
import useRequestAuth from '../../../../utils/useRequestAuth';
import { RadioList, SelectField, TextField } from '../../../forms';
import { Container } from '../../../layout';
import {
	Alert,
	Button,
	Loader,
	Modal,
} from '../../../ui';

import styles from '../PortfolioWithdrawals.module.scss';

const useFetchChildrenList = useFetchAuth(fetchChildrenList);

export default function PortfolioCancel({
	currencies,
	pensionCheck,
	portfolio,
	portfoliosOptions,
	portfoliosOwnerOptions,
	setCheck,
}) {
	const [confirmCancel, setConfirmCancel] = useState(false);
	const [cancelError, setCancelError] = useState(false);
	const [cancelHas2fa, setCancelHas2fa] = useState(false);
	const [orderError, setOrderError] = useState(false);
	const [transferWarning, setTransferWarning] = useState(false);
	const [defaultPortfolioSelect, setDefaultPortfolioSelect] = useState(false);
	const portfolioWithdrawalAuth = useRequestAuth(portfolioWithdrawal);
	const [t] = useTranslation();
	const trackGTMEvent = useTrackGTMEvent();
	const [user] = useUserContext();
	const [childrenList, loadingChildren] = useFetchChildrenList(null, user.child);

	const loading = currencies === null || loadingChildren;

	const currencySelect = currencies !== null && currencies.length > 1;
	const currencyDefault = currencies !== null
		? currencies.find(({ code }) => code === portfolio.currency) ?? currencies[0] ?? null
		: null;
	const currencyDefaultValue = currencyDefault !== null ? currencyDefault.code : null;
	const currencyOptions = currencies !== null ? currencies.map(({ code }) => ({
		label: code,
		value: code,
	})) : null;

	const isDefaultUserPortfolio = portfolio.id === user.deposit_default_portfolio_id && portfoliosOptions?.length > 0;
	const isDefaultChildPortfolio = portfolio.id === (childrenList?.find(
		(child) => child?.id === portfolio.child?.id,
	)?.depositDefaultPortfolioId ?? null) && portfoliosOwnerOptions?.length > 0;

	const cancel = (
		<Formik
			initialValues={{
				twoFactorCode: '',
				currency: currencySelect ? currencyDefaultValue : null,
				newUserDefaultPortfolioId: null,
				newOwnerDefaultPortfolioId: null,
			}}
			onSubmit={async (values, { setErrors }) => {
				const currency = currencySelect ? values.currency : currencyDefaultValue;
				if (currency === null) {
					return;
				}

				let show2fa = false;
				setCancelError(false);
				try {
					await portfolioWithdrawalAuth(
						portfolio.id,
						3,
						null,
						cancelHas2fa ? values.twoFactorCode : null,
						currency,
						null,
						null,
						values.newUserDefaultPortfolioId,
						values.newOwnerDefaultPortfolioId,
					);
				} catch (e) {
					const fieldErrors = {};
					const errorMessage = e.responseJson?.message;
					if (typeof errorMessage === 'string' && errorMessage.indexOf('Two factor authentication code needed') !== -1) {
						setCancelHas2fa(true);
						show2fa = true;
					}

					if (typeof errorMessage === 'string' && errorMessage.indexOf('Bad two factor authentication code') !== -1) {
						fieldErrors.twoFactorCode = 'forms.twoFactorAuthentication.error';
					} else if (errorMessage.indexOf('This type of withdrawal cannot be created now') !== -1) {
						setOrderError(true);
						setConfirmCancel(false);
					} else if (errorMessage.indexOf('There is an existing transfer to this portfolio') !== -1) {
						setTransferWarning(true);
						setConfirmCancel(false);
					}

					const hasFieldErrors = Object.keys(fieldErrors).length > 0;
					if (hasFieldErrors) {
						setErrors(fieldErrors);
					}
					setCancelError(!hasFieldErrors && !show2fa);
					return;
				}
				trackGTMEvent('interactions', {
					eventCategory: 'interactions',
					eventAction: 'buttonClick',
					eventLabel: 'insert_withdrawal_closure_portfolio',
				});
				setCheck(true);
				setCancelHas2fa(false);
				setConfirmCancel(false);
			}}
		>
			{({
				handleSubmit, values, errors, touched, handleBlur, handleChange, isSubmitting, setFieldValue,
			}) => (
				<form onSubmit={handleSubmit}>
					{pensionCheck === false && (
						<Alert
							autoScroll={false}
							type="danger"
						>
							<h4 className={styles.listTitle}>
								{t('account.withdrawals.withdrawals.pensionWarningTitle')}
							</h4>
							<ul className={styles.list}>
								<Trans i18nKey="account.withdrawals.withdrawals.pensionWarning">
									<li className={styles.listItem} />
								</Trans>
							</ul>
						</Alert>
					)}
					<p className={styles.text}>
						{t('account.withdrawals.cancel.portfolio.text')}
					</p>
					<p className={styles.text}>
						{t('account.withdrawals.cancel.portfolio.subtext')}
					</p>
					<p className={styles.note}>
						{t('account.withdrawals.cancel.portfolio.note')}
					</p>
					{values.currency !== null && values.currency !== portfolio.currency && (
						<Alert type="warning">
							{t('account.withdrawals.withdrawals.currencyWarning')}
						</Alert>
					)}
					{filterChild(portfolio.child, user) && (
						<Alert
							autoScroll={false}
							type="danger"
						>
							{t('account.withdrawals.withdrawals.childPortfolioWarning')}
						</Alert>
					)}
					{currencySelect && (
						<RadioList
							name="currency"
							id="withdrawalCurrencyCancel"
							onChange={handleChange}
							options={currencyOptions}
							value={values.currency}
						/>
					)}
					<Modal
						isVisible={confirmCancel}
						onClose={() => {
							setCancelHas2fa(false);
							setConfirmCancel(false);
						}}
						title={t('account.withdrawals.cancel.portfolio.popup.title')}
					>
						<div className={styles.modal}>
							<p className={styles.modalText}>
								{t('account.withdrawals.cancel.portfolio.popup.text')}
							</p>
							{cancelError && (
								<Alert type="danger">
									{t('forms.error')}
								</Alert>
							)}
							{values.currency !== null && values.currency !== portfolio.currency && (
								<Alert type="warning">
									{t('account.withdrawals.withdrawals.currencyWarning')}
								</Alert>
							)}
							{filterChild(portfolio.child, user) && (
								<Alert
									autoScroll={false}
									type="danger"
								>
									{t('account.withdrawals.withdrawals.childPortfolioWarning')}
								</Alert>
							)}
							{cancelHas2fa && (
								<TextField
									autocomplete="one-time-code"
									autoFocus={cancelHas2fa}
									onBlur={handleBlur}
									onChange={handleChange}
									error={
										errors.twoFactorCode
												&& touched.twoFactorCode
												&& t(errors.twoFactorCode)
									}
									id="twoFactorCode"
									name="twoFactorCode"
									label={t('account.withdrawals.cancel.portfolio.popup.twoFactorAuthentication')}
									required={cancelHas2fa}
									type="text"
									inputMode="numeric"
									pattern="[0-9]*"
									value={values.twoFactorCode}
								/>
							)}
							<Button
								disabled={isSubmitting}
								isSubmit={confirmCancel && !isDefaultUserPortfolio && !isDefaultChildPortfolio}
								label={t('account.withdrawals.cancel.portfolio.popup.button')}
								onClick={(e) => {
									if (isDefaultUserPortfolio || isDefaultChildPortfolio) {
										e.preventDefault();
										setDefaultPortfolioSelect(true);
									}
								}}
							/>
						</div>
					</Modal>
					<Modal
						isVisible={orderError}
						onClose={() => {
							setOrderError(false);
						}}
						title={t('account.withdrawals.cancel.portfolio.error.title')}
					>
						<div className={styles.modal}>
							<p className={styles.modalText}>
								{t('account.withdrawals.cancel.portfolio.error.text')}
							</p>
							<Button
								disabled={isSubmitting}
								label={t('account.withdrawals.cancel.portfolio.error.button')}
								onClick={() => setOrderError(false)}
							/>
						</div>
					</Modal>
					<Modal
						isVisible={transferWarning}
						onClose={() => setTransferWarning(false)}
						title={t('account.withdrawals.cancel.portfolio.transferWarning.title')}
					>
						<div className={styles.modal}>
							<p className={styles.modalText}>
								{t('account.withdrawals.cancel.portfolio.transferWarning.text')}
							</p>
							<Button
								disabled={isSubmitting}
								label={t('account.withdrawals.cancel.portfolio.transferWarning.button')}
								onClick={() => setTransferWarning(false)}
							/>
						</div>
					</Modal>
					<Modal
						isVisible={defaultPortfolioSelect}
						onClose={() => setDefaultPortfolioSelect(false)}
						title={t('account.withdrawals.cancel.portfolio.defaultPortfolioSelect.title')}
						overflow
					>
						<div className={styles.modal}>
							<p className={styles.modalText}>
								{isDefaultUserPortfolio && !isDefaultChildPortfolio && (
									t('account.withdrawals.cancel.portfolio.defaultPortfolioSelect.text')
								)}
								{!isDefaultUserPortfolio && isDefaultChildPortfolio && (
									t('account.withdrawals.cancel.portfolio.defaultPortfolioSelect.textChild')
								)}
								{isDefaultUserPortfolio && isDefaultChildPortfolio && (
									t('account.withdrawals.cancel.portfolio.defaultPortfolioSelect.textBoth')
								)}
							</p>
							{isDefaultUserPortfolio && (
								<div className={styles.select}>
									<SelectField
										id="newUserDefaultPortfolioId"
										name="newUserDefaultPortfolioId"
										label={t('account.withdrawals.cancel.portfolio.defaultPortfolioSelect.label')}
										options={portfoliosOptions}
										onChange={setFieldValue}
										value={values.newUserDefaultPortfolioId}
										required
									/>
								</div>
							)}
							{isDefaultChildPortfolio && (
								<div className={styles.select}>
									<SelectField
										id="newOwnerDefaultPortfolioId"
										name="newOwnerDefaultPortfolioId"
										label={t('account.withdrawals.cancel.portfolio.defaultPortfolioSelect.labelChild')}
										options={portfoliosOwnerOptions}
										onChange={setFieldValue}
										value={values.newOwnerDefaultPortfolioId}
										required
									/>
								</div>
							)}
							<Button
								disabled={isSubmitting}
								isSubmit={isDefaultUserPortfolio || isDefaultChildPortfolio}
								label={t('account.withdrawals.cancel.portfolio.defaultPortfolioSelect.button')}
							/>
						</div>
					</Modal>
					<Button
						disabled={
							isSubmitting
							|| (currencySelect ? values.currency : currencyDefaultValue) === null
						}
						isSubmit={!confirmCancel}
						label={t('account.withdrawals.cancel.portfolio.confirm')}
						onClick={(e) => {
							e.preventDefault();
							setConfirmCancel(true);
						}}
					/>
				</form>
			)}
		</Formik>
	);

	return (
		<Container>
			<div className={loading ? styles.loader : ''}>
				{loading && <Loader />}
				{!loading && cancel}
			</div>
		</Container>
	);
}

PortfolioCancel.propTypes = {
	currencies: PropTypes.arrayOf(PropTypes.shape({
		code: PropTypes.string.isRequired,
		codeShort: PropTypes.string.isRequired,
		codeShortBefore: PropTypes.bool.isRequired,
	}).isRequired),
	pensionCheck: PropTypes.bool,
	portfolio: PropTypes.shape({
		child: PropTypes.shape({
			id: PropTypes.number.isRequired,
		}),
		currency: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
	}).isRequired,
	portfoliosOptions: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node,
		]).isRequired,
		value: PropTypes.number.isRequired,
	}).isRequired).isRequired,
	portfoliosOwnerOptions: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node,
		]).isRequired,
		value: PropTypes.number.isRequired,
	}).isRequired).isRequired,
	setCheck: PropTypes.func.isRequired,
};

PortfolioCancel.defaultProps = {
	currencies: null,
	pensionCheck: null,
};
