import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import isScreenWidthUp from '../../../utils/isScreenWidthUp';
import { toInt } from '../../../utils/number';
import useFocusOnError from '../../../utils/useFocusOnError';
import useWindowSize from '../../../utils/useWindowSize';
import { Tooltip } from '../../ui';

import styles from './Ranger.module.scss';

const GRADIENT_LEFT = '#B63D45';
const GRADIENT_RIGHT = '#EBEBEB';
const THUMB_SIZE = 25;

const tooltipIcon = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		width={15}
	>
		<path
			fill="currentColor"
			d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
		/>
	</svg>
);

export default function Ranger({
	disabled,
	id,
	max,
	maxLabel,
	maxTooltip,
	min,
	minLabel,
	minTooltip,
	name,
	onChange,
	showSteps,
	step,
	value,
}) {
	const [textFieldValue, setTextFieldValue] = useState(String(value));
	const windowSize = useWindowSize();
	const gradientRatio = (value - min) / (max - min);
	const gradientFilled = `calc(${gradientRatio * 100}% - ${gradientRatio * THUMB_SIZE}px + ${THUMB_SIZE / 2}px)`;

	const inputRef = useRef();
	useFocusOnError(name, inputRef);

	useEffect(() => {
		setTextFieldValue(String(value));
	}, [value]);

	const handleChange = (newValue) => {
		if (disabled) {
			return;
		}

		const newValueClamped = Math.min(Math.max(min, newValue), max);
		setTextFieldValue(String(newValueClamped));
		if (onChange !== null) {
			onChange(name, newValueClamped);
		}
	};

	const handleInputChange = (e) => {
		handleChange((Math.round(toInt(e.currentTarget.value) / step) * step) || 0);
	};

	const renderSteps = () => {
		if (max - min <= 1) {
			return null;
		}

		return (
			[...Array(max - min - 1)].map((_, i) => min + i + 1).map((buttonValue) => (
				<button
					key={buttonValue}
					className={styles.label}
					disabled={disabled}
					onClick={() => handleChange(buttonValue)}
					type="button"
				>
					{buttonValue}
				</button>
			))
		);
	};

	return (
		<div className={styles.root}>
			{(minLabel || maxLabel) && (
				<div className={`${styles.labels} ${showSteps ? styles.labelsSmall : ''}`.trim()}>
					<button
						className={styles.label}
						disabled={disabled}
						onClick={() => handleChange(min)}
						type="button"
					>
						{minLabel}
						{minTooltip && (
							<span className={styles.labelTooltip}>
								<Tooltip
									text={minTooltip}
								>
									{tooltipIcon}
								</Tooltip>
							</span>
						)}
					</button>

					{showSteps && isScreenWidthUp(windowSize.width, 'md') && renderSteps()}

					<button
						className={styles.label}
						disabled={disabled}
						onClick={() => handleChange(max)}
						type="button"
					>
						{maxLabel}
						{maxTooltip && (
							<span className={styles.labelTooltip}>
								<Tooltip
									text={maxTooltip}
								>
									{tooltipIcon}
								</Tooltip>
							</span>
						)}
					</button>
				</div>
			)}

			<input
				ref={inputRef}
				className={styles.ranger}
				disabled={disabled}
				id={id}
				max={max}
				min={min}
				onChange={handleInputChange}
				name={name}
				step={step}
				style={{
					background: `linear-gradient(to right, ${GRADIENT_LEFT} ${gradientFilled}, ${GRADIENT_RIGHT} ${gradientFilled})`,
				}}
				value={String(value)}
				type="range"
			/>
			<div className={styles.control}>
				<button
					className={styles.button}
					disabled={disabled || value === min}
					onClick={() => handleChange(value - step)}
					type="button"
				>
					-
				</button>

				<input
					className={styles.input}
					disabled={disabled}
					id={`${id}-control`}
					max={max}
					min={min}
					onBlur={handleInputChange}
					onChange={(e) => setTextFieldValue(e.currentTarget.value)}
					required
					step={step}
					type="number"
					value={textFieldValue}
				/>

				<button
					className={styles.button}
					disabled={disabled || value === max}
					onClick={() => handleChange(value + step)}
					type="button"
				>
					+
				</button>
			</div>
		</div>
	);
}

Ranger.propTypes = {
	disabled: PropTypes.bool,
	id: PropTypes.string.isRequired,
	max: PropTypes.number.isRequired,
	maxLabel: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	maxTooltip: PropTypes.string,
	min: PropTypes.number.isRequired,
	minLabel: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	minTooltip: PropTypes.string,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	showSteps: PropTypes.bool,
	step: PropTypes.number,
	value: PropTypes.number.isRequired,
};

Ranger.defaultProps = {
	disabled: false,
	maxLabel: null,
	maxTooltip: '',
	minLabel: null,
	minTooltip: '',
	onChange: null,
	showSteps: false,
	step: 1,
};
