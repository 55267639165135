import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TextField } from '../../forms';

import styles from './BlogSearch.module.scss';

export default function BlogSearch({
	handleKeyDownClose,
	handleSearchChange,
	searchQuery,
}) {
	const [t] = useTranslation();

	return (
		<div className={styles.searchWrap}>
			<TextField
				id="blogSearch"
				label={t('blog.menu.search.label')}
				name="blogSearch"
				onChange={handleSearchChange}
				onKeyDown={handleKeyDownClose}
				type="search"
				value={searchQuery}
			/>
		</div>
	);
}

BlogSearch.propTypes = {
	handleKeyDownClose: PropTypes.func.isRequired,
	handleSearchChange: PropTypes.func.isRequired,
	searchQuery: PropTypes.string,
};

BlogSearch.defaultProps = {
	searchQuery: '',
};
