import React from 'react';
import PropTypes from 'prop-types';

import isScreenWidthUp from '../../../utils/isScreenWidthUp';
import useWindowSize from '../../../utils/useWindowSize';

import styles from './SectionAdaptable.module.scss';

export default function SectionAdaptable({
	bordered,
	breakpoint,
	children,
	isHomepageHero,
	offset,
}) {
	const windowSize = useWindowSize();
	const style = isScreenWidthUp(windowSize.width, breakpoint) && windowSize.height !== null
		? { height: `${windowSize.height + offset}px` }
		: null;

	return (
		<section
			className={
				`${styles.root} ${bordered ? styles.isRootBordered : ''} ${isHomepageHero ? styles.isRootHomepageHero : ''}`.trim()
			}
			style={style}
		>
			{children}
		</section>
	);
}

SectionAdaptable.propTypes = {
	bordered: PropTypes.bool,
	breakpoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	isHomepageHero: PropTypes.bool,
	offset: PropTypes.number,
};

SectionAdaptable.defaultProps = {
	breakpoint: 'xs',
	bordered: false,
	children: null,
	isHomepageHero: false,
	offset: 0,
};
