import React from 'react';
import PropTypes from 'prop-types';

import { Trans } from 'react-i18next';

import { ExternalLink, Picture } from '../../ui';

import styles from './TeamMember.module.scss';

export default function TeamMember({
	img,
	imgWebP,
	link,
	linkText,
	name,
	text,
}) {
	return (
		<article className={styles.root}>
			<div className={styles.imageWrap}>
				<Picture
					alt={name}
					className={styles.image}
					src={img}
					srcWebP={imgWebP}
				/>
			</div>
			<div className={styles.content}>
				<h3 className={styles.name}>{name}</h3>
				{text && (
					<Trans i18nKey={text}>
						<p className={styles.text}>
							{text}
							<strong />
						</p>
					</Trans>
				)}
				{link && (
					<ExternalLink
						className={styles.link}
						to={link}
						blank
					>
						{linkText || link}
					</ExternalLink>
				)}
			</div>
		</article>
	);
}

TeamMember.propTypes = {
	img: PropTypes.string.isRequired,
	imgWebP: PropTypes.string,
	link: PropTypes.string,
	linkText: PropTypes.string,
	name: PropTypes.string.isRequired,
	text: PropTypes.string,
};

TeamMember.defaultProps = {
	imgWebP: null,
	link: '',
	linkText: '',
	text: '',
};
