import { useCallback } from 'react';

import { useUserLogout } from '../contexts/UserContext';

export default function useRequestAuth(sendRequest) {
	const logout = useUserLogout();

	return useCallback(async (...args) => {
		try {
			return await sendRequest(...args);
		} catch (e) {
			if (e?.response?.status === 401) {
				logout(null, false);
			}
			throw e;
		}
	}, [sendRequest, logout]);
}
