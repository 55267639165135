import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormLayout } from '../../components/layout';
import { ButtonLink, EmailLink } from '../../components/ui';
import { useUserContext } from '../../contexts/UserContext';
import { emailVerification } from '../../utils/api';

import styles from './EmailVerificationResponse.module.scss';

export default function EmailVerificationResponse({
	code,
	dashboardUrl,
	email,
	registrationCompletionUrl,
}) {
	const navigate = useNavigate();
	const [success, setSuccess] = useState(null);
	const [t] = useTranslation();
	const [user, loading] = useUserContext();

	useEffect(() => {
		if (user?.email_verified) {
			navigate(dashboardUrl);
		}
	}, [user, navigate]);

	useEffect(() => {
		if (loading || user?.email_verified) {
			return;
		}

		emailVerification(code, email).then(() => {
			setSuccess(true);
		}).catch(() => {
			setSuccess(false);
		});
	}, [user, loading, code, email]);

	if (loading || success === null) return null;

	return (
		<FormLayout title={t(`emailVerification.response.title.${success ? 'success' : 'error'}`)}>
			<p className={styles.content}>
				{success ? t('emailVerification.response.content.success') : (
					<Trans i18nKey="emailVerification.response.content.error">
						<EmailLink />
					</Trans>
				)}
			</p>
			{success && <ButtonLink label={t('emailVerification.response.button')} to={registrationCompletionUrl} />}
		</FormLayout>
	);
}

EmailVerificationResponse.propTypes = {
	code: PropTypes.string,
	dashboardUrl: PropTypes.string.isRequired,
	email: PropTypes.string,
	registrationCompletionUrl: PropTypes.string.isRequired,
};

EmailVerificationResponse.defaultProps = {
	code: '',
	email: '',
};
