import React from 'react';

import { BlogHeader } from '../../../components/common';
import { Container, Section } from '../../../components/layout';
import { BlogPostPreviewSkeleton } from '../BlogPostPreview';
import { BlogMenuSkeleton } from './BlogMenu';

import styles from './BlogList.module.scss';

export default function BlogListSkeleton() {
	return (
		<div className={styles.root}>
			<Section condensed>
				<BlogHeader />
				<Container>
					<div className={styles.wrap}>
						<div className={styles.menu}>
							<BlogMenuSkeleton />
						</div>
						<div className={styles.content}>
							<div className={styles.list}>
								<BlogPostPreviewSkeleton />
								<BlogPostPreviewSkeleton />
								<BlogPostPreviewSkeleton />
								<BlogPostPreviewSkeleton />
							</div>
						</div>
					</div>
				</Container>
			</Section>
		</div>
	);
}
