import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Container, Section } from '../../layout';
import TransparentAccount from '../TransparentAccount';

import styles from './OurApproachAccount.module.scss';

export default function OurApproachAccount({
	howItWorksUrl,
}) {
	const [t] = useTranslation();

	return (
		<Section>
			<Container center>
				<h2 className={styles.title}>{t('ourApproachAccount.title')}</h2>
				<p className={styles.text}>{t('ourApproachAccount.text')}</p>
				<TransparentAccount howItWorksUrl={howItWorksUrl} />
			</Container>
		</Section>
	);
}

OurApproachAccount.propTypes = {
	howItWorksUrl: PropTypes.string.isRequired,
};
