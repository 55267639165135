import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
	ContactHero,
	ContactUs,
	FaqPublic,
	FooterCta,
} from '../../components/common';

export default function Contact({
	personalInfoAgreementUrl,
	registrationUrl,
	termsUrl,
}) {
	const [t] = useTranslation();
	const contactFormSectionAnchor = t('anchors.contactForm');
	const faqSectionAnchor = t('anchors.faq');

	return (
		<>
			<ContactHero
				contactFormSectionAnchor={contactFormSectionAnchor}
				faqSectionAnchor={faqSectionAnchor}
				personalInfoAgreementUrl={personalInfoAgreementUrl}
			/>
			<FaqPublic faqSectionAnchor={faqSectionAnchor} />
			<ContactUs
				contactFormSectionAnchor={contactFormSectionAnchor}
				personalInfoAgreementUrl={personalInfoAgreementUrl}
				termsUrl={termsUrl}
			/>
			<FooterCta registrationUrl={registrationUrl} />
		</>
	);
}

Contact.propTypes = {
	personalInfoAgreementUrl: PropTypes.string.isRequired,
	registrationUrl: PropTypes.string.isRequired,
	termsUrl: PropTypes.string.isRequired,
};
