import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { FooterCta } from '../../components/common';
import { Container, Section } from '../../components/layout';
import { ExternalLink } from '../../components/ui';

import styles from './PrivacyPolicy.module.scss';

export default function PrivacyPolicy({
	registrationUrl,
}) {
	const [t] = useTranslation();

	return (
		<>
			<Section>
				<Container>
					<h1 className={styles.title}>{t('privacyPolicy.title')}</h1>
					<div className={styles.offset}>
						<Trans i18nKey="privacyPolicy.intro">
							<p className={styles.text}>
								<strong />
							</p>
						</Trans>
					</div>

					<ul className={styles.list}>
						<Trans i18nKey="privacyPolicy.text">
							<li className={styles.text}>
								<strong />
								<ExternalLink blank to="https://www.uoou.cz/" />
							</li>
							<ol type="I">
								<li className={styles.listText}>
									<strong />
								</li>
							</ol>
						</Trans>
					</ul>

					<div className={styles.offset}>
						<Trans i18nKey="privacyPolicy.contact">
							<p className={styles.contactText}>
								<a href="mailto:honza@fondee.cz">
									{t('privacyPolicy.consent.title')}
								</a>
							</p>
						</Trans>

						<p className={styles.contactTitle}>
							<Trans i18nKey="privacyPolicy.consent.title">
								<a href={`mailto:${t('privacyPolicy.consent.email')}`}>
									{t('privacyPolicy.consent.title')}
								</a>
							</Trans>
						</p>

						<Trans i18nKey="privacyPolicy.consent.text">
							<p className={styles.listText} />
							<p className={styles.contactText} />
						</Trans>
					</div>
				</Container>
			</Section>
			<FooterCta registrationUrl={registrationUrl} />
		</>
	);
}

PrivacyPolicy.propTypes = {
	registrationUrl: PropTypes.string.isRequired,
};
