import React from 'react';
import PropTypes from 'prop-types';

export default function Icon({
	type,
	width,
	height,
}) {
	return (
		<>
			{type === 'danger' && (
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={width} height={height} viewBox="0 0 22 20">
					<path fill="#D84E4B" d="M0 19.5h22L11 .5l-11 19Zm12-3h-2v-2h2v2Zm0-4h-2v-4h2v4Z" />
				</svg>
			)}
		</>
	);
}

Icon.propTypes = {
	type: PropTypes.oneOf(['danger']).isRequired,
	width: PropTypes.number,
	height: PropTypes.number,
};

Icon.defaultProps = {
	width: 22,
	height: 20,
};
