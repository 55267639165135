import React from 'react';
import PropTypes from 'prop-types';

import styles from './Col.module.scss';

export default function Col({
	children,
	xs,
	sm,
	md,
	lg,
	xl,
	xxl,
}) {
	let rootClass = styles.root;
	if (xs !== null) rootClass += ` ${styles[`xs-${xs}`]}`;
	if (sm !== null) rootClass += ` ${styles[`sm-${sm}`]}`;
	if (md !== null) rootClass += ` ${styles[`md-${md}`]}`;
	if (lg !== null) rootClass += ` ${styles[`lg-${lg}`]}`;
	if (xl !== null) rootClass += ` ${styles[`xl-${xl}`]}`;
	if (xxl !== null) rootClass += ` ${styles[`xxl-${xxl}`]}`;

	return (
		<div className={rootClass}>
			{children}
		</div>
	);
}

Col.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	xs: PropTypes.number,
	sm: PropTypes.number,
	md: PropTypes.number,
	lg: PropTypes.number,
	xl: PropTypes.number,
	xxl: PropTypes.number,
};

Col.defaultProps = {
	children: null,
	xs: null,
	sm: null,
	md: null,
	lg: null,
	xl: null,
	xxl: null,
};
