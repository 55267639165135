/* global Event, EventTarget, sessionStorage */
import { useSyncExternalStore } from 'use-sync-external-store/shim';

import { toInt } from './number';

const eventTarget = new EventTarget();
const eventType = 'portfolioChange';
const storageKey = 'portfolioId';

export function getSessionPortfolioId() {
	const storageValue = sessionStorage.getItem('portfolioId');
	if (storageValue === null) {
		return null;
	}

	const intValue = toInt(storageValue);
	return !Number.isNaN(intValue) ? intValue : null;
}

export function setSessionPortfolioId(id) {
	if (id !== null) {
		sessionStorage.setItem(storageKey, String(id));
	} else {
		sessionStorage.removeItem(storageKey);
	}

	eventTarget.dispatchEvent(new Event(eventType));
}

export function subscribeSessionPortfolioId(callback) {
	eventTarget.addEventListener(eventType, callback);
	return () => {
		eventTarget.removeEventListener(eventType, callback);
	};
}

export function useSessionPortfolio(portfolios, proxyPortfolioId = null, archivePortfolioId = null) {
	const sessionPortfolioId = useSyncExternalStore(subscribeSessionPortfolioId, getSessionPortfolioId, null);

	if (proxyPortfolioId !== null) {
		return [proxyPortfolioId, null, false];
	}

	const archivePortfolio = archivePortfolioId !== null
		? portfolios?.closed.find((portfolio) => portfolio.id === archivePortfolioId) ?? null
		: null;
	if (archivePortfolio !== null) {
		return [archivePortfolioId, archivePortfolio, true];
	}

	const sessionPortfolio = sessionPortfolioId !== null
		? portfolios?.active.find((portfolio) => portfolio.id === sessionPortfolioId) ?? null
		: null;
	if (sessionPortfolio !== null) {
		return [sessionPortfolioId, sessionPortfolio, false];
	}

	if (portfolios?.active.length > 0) {
		const portfolio = portfolios.active[0];
		return [portfolio.id, portfolio, false];
	}

	return [null, null, false];
}
