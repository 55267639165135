import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { useCountry } from '../../../contexts/LocaleContext';
import {
	addEmployer,
	checkEmployer,
} from '../../../utils/api';
import useRequestAuth from '../../../utils/useRequestAuth';
import { validateEmployerCreate } from '../../../utils/validators';
import { TextField } from '../../forms';
import {
	Alert,
	Button,
	Modal,
} from '../../ui';

import styles from './EmployerCreate.module.scss';

export default function EmployerCreate({
	isDisabled,
	reloadEmployers,
}) {
	const [t] = useTranslation();
	const country = useCountry();
	const [showModal, setShowModal] = useState(false);
	const [error, setError] = useState(false);
	const [errorExists, setErrorExists] = useState(false);
	const [success, setSuccess] = useState(false);
	const [employer, setEmployer] = useState(null);
	const checkEmployerAuth = useRequestAuth(checkEmployer);
	const addEmployerAuth = useRequestAuth(addEmployer);

	const handleClose = () => {
		setShowModal(false);
	};

	const handleOpen = () => {
		setShowModal(true);
	};

	return (
		<>
			<Button
				disabled={isDisabled}
				label={t('employer.create.open')}
				onClick={isDisabled ? null : handleOpen}
				tooltip={isDisabled ? t('employer.create.disabled') : ''}
			/>
			<Modal
				isVisible={showModal}
				onClose={handleClose}
				size="medium"
				title={t('employer.create.title')}
			>
				<div className={styles.modalControl}>
					<p className={styles.text}>
						{t('employer.create.text')}
					</p>
					<Formik
						initialValues={{
							businessId: '',
						}}
						validate={(values) => validateEmployerCreate(values, country)}
						onSubmit={async (values, { setErrors }) => {
							setSuccess(false);
							setError(false);
							setErrorExists(false);
							try {
								const result = await checkEmployerAuth(
									values.businessId,
								);
								if (result.data) {
									setEmployer(result.data);
								} else {
									setEmployer(null);
								}
							} catch (e) {
								const fieldErrors = {};
								const errorMessage = e.responseJson?.message;
								if (typeof errorMessage === 'string') {
									if ((errorMessage.indexOf('ARES search failed')) !== -1) {
										fieldErrors.businessId = 'employer.create.form.aresError';
									} else if ((errorMessage.indexOf('ARES cannot find subject for businessId:')) !== -1) {
										fieldErrors.businessId = 'employer.create.form.invalidBusinessId';
									}
								}
								const hasFieldErrors = Object.keys(fieldErrors).length > 0;
								if (hasFieldErrors) {
									setErrors(fieldErrors);
								}
								setError(!hasFieldErrors);
								return;
							}
							setSuccess(true);
						}}
					>
						{({
							errors,
							handleBlur,
							handleChange,
							handleSubmit,
							isSubmitting,
							touched,
							values,
						}) => (
							<form onSubmit={handleSubmit}>
								{error && (
									<Alert type="danger">
										{t('forms.error')}
									</Alert>
								)}
								{errorExists && (
									<Alert type="danger">
										{t('employer.create.form.employerExistsError')}
									</Alert>
								)}
								<div className={styles.wrap}>
									<TextField
										error={
											errors.businessId
											&& touched.businessId
											&& t(errors.businessId)
										}
										id="businessId"
										label={t('employer.create.form.label')}
										placeholder={t('employer.create.form.placeholder')}
										name="businessId"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										value={values.businessId}
									/>
									<span className={styles.offset}>
										<Button
											disabled={isSubmitting}
											label={t('employer.create.form.submit')}
											isSubmit
											outline
										/>
									</span>
								</div>
							</form>
						)}
					</Formik>
					{employer && success && (
						<Formik
							initialValues={{}}
							onSubmit={async () => {
								setError(false);
								setErrorExists(false);
								try {
									await addEmployerAuth(
										employer.id,
									);
								} catch (e) {
									let nextErrorExists = false;
									const errorMessage = e.responseJson?.message;
									if (typeof errorMessage === 'string') {
										if ((errorMessage.indexOf('User employer already exists.')) !== -1) {
											setErrorExists(nextErrorExists = true);
										}
									}
									setError(!nextErrorExists);
									return;
								}
								reloadEmployers();
								handleClose();
							}}
						>
							{({
								handleSubmit,
							}) => (
								<form onSubmit={handleSubmit}>
									<div className={styles.result}>
										<p className={styles.subtitle}>
											{t('employer.create.form.found')}
										</p>
										<div className={styles.box}>
											<div className={styles.row}>
												<strong>
													{t('employer.create.form.name')}
												</strong>
												{employer.title}
											</div>
											<div className={styles.row}>
												<strong>
													{t('employer.create.form.address')}
												</strong>
												{employer.address}
											</div>
											<div className={styles.confirm}>
												<Button
													label={t('employer.create.form.confirm')}
													isSubmit
												/>
											</div>
										</div>
									</div>
								</form>
							)}
						</Formik>
					)}
				</div>
			</Modal>
		</>
	);
}

EmployerCreate.propTypes = {
	isDisabled: PropTypes.bool.isRequired,
	reloadEmployers: PropTypes.func.isRequired,
};
