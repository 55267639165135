import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import InvestorProfileChart from '../InvestorProfileChart';

import styles from './InvestorProfileDetail.module.scss';

export default function InvestorProfileDetailInfo({
	categories,
	profile,
}) {
	const [t] = useTranslation();

	return (
		<div className={styles.infoContainer}>
			<div className={styles.infoContainerChart}>
				<InvestorProfileChart
					categories={categories}
					profile={profile}
				/>
			</div>
			<div
				className={styles.infoContainerDetail}
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{ __html: profile.descriptionContent }}
			/>
			<p className={styles.infoDisclaimer}>
				{t('investorProfilePreview.disclaimer.text')}
			</p>
		</div>
	);
}

InvestorProfileDetailInfo.propTypes = {
	categories: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
		}),
	).isRequired,
	profile: PropTypes.shape({
		id: PropTypes.number.isRequired,
		descriptionContent: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		chart: PropTypes.arrayOf(
			PropTypes.shape({
				colorHex: PropTypes.string.isRequired,
				productCategoryId: PropTypes.number.isRequired,
				ratio: PropTypes.string.isRequired,
			}),
		).isRequired,
	}).isRequired,
};
