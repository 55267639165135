/* global document */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import {
	Container,
	Section,
} from '../../components/layout';
import { EmailLink } from '../../components/ui';
import { fetchProfileRegistrationProcess } from '../../utils/api';
import useFetchAuth from '../../utils/useFetchAuth';
import SignAgreementSteps from './SignAgreementSteps';

import styles from './SignAgreement.module.scss';

const useFetchProfileRegistrationProcess = useFetchAuth(() => fetchProfileRegistrationProcess());

export default function SignAgreement({
	accountUrl,
	blogPostEsgRegulationUrl,
	faqUrl,
}) {
	const [t] = useTranslation();
	const [checkOnboarding, setCheckOnboarding] = useState(false);
	const [registrationProcess, loading, error] = useFetchProfileRegistrationProcess({}, checkOnboarding);

	useEffect(() => {
		if (registrationProcess.completeRegistration) {
			document.getElementById('top').scrollIntoView({
				behavior: 'smooth',
			});
		}

		setCheckOnboarding(false);
	}, [loading]);

	if (loading || error) return null;

	return (
		<Section id="top">
			<Container>
				<h1 className={styles.title}>
					{t('signAgreement.title')}
				</h1>
				{registrationProcess.completeRegistration ? (
					<p className={styles.text}>
						{t('signAgreement.done')}
					</p>
				) : (
					<>
						<p className={styles.text}>
							{t('signAgreement.text')}
						</p>
						<p className={styles.textSmaller}>
							<Trans i18nKey="signAgreement.info">
								<EmailLink />
							</Trans>
						</p>
						<SignAgreementSteps
							accountUrl={accountUrl}
							blogPostEsgRegulationUrl={blogPostEsgRegulationUrl}
							faqUrl={faqUrl}
							process={registrationProcess}
							setCheckOnboarding={setCheckOnboarding}
						/>
					</>
				)}
			</Container>
		</Section>
	);
}

SignAgreement.propTypes = {
	accountUrl: PropTypes.string.isRequired,
	blogPostEsgRegulationUrl: PropTypes.string,
	faqUrl: PropTypes.string.isRequired,
};

SignAgreement.defaultProps = {
	blogPostEsgRegulationUrl: null,
};
