import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './BlogPostContent.module.scss';

export default function BlogPostContent({
	content,
	slugs,
}) {
	const [t] = useTranslation();

	return (
		<div className={styles.root}>
			<div className={styles.content}>
				<h2 className={styles.title}>
					{t('blogPost.content.title')}
				</h2>
				<ul className={styles.list}>
					{Object.keys(content)
						.map((item) => (
							<li key={item} className={styles.item}>
								<a
									className={styles.link}
									href={`#${slugs[item]}`}
								>
									{content[item]}
								</a>
							</li>
						))}
				</ul>
			</div>
		</div>
	);
}

BlogPostContent.propTypes = {
	content: PropTypes.arrayOf(PropTypes.string).isRequired,
	slugs: PropTypes.arrayOf(PropTypes.string).isRequired,
};
