import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
	Col, Container, Row, Section,
} from '../../components/layout';

import styles from './EmailVerification.module.scss';

export default function EmailVerification() {
	const [t] = useTranslation();

	return (
		<Section>
			<Container center>
				<h1 className={styles.title}>{t('emailVerification.default.title')}</h1>
				<Row center>
					<Col lg={6} xl={4}>
						<p className={styles.text}>
							{t('emailVerification.default.text')}
						</p>
					</Col>
				</Row>
				<Row center>
					<Col lg={8} xl={7} xxl={6}>
						<p className={styles.listTitle}>
							{t('emailVerification.default.listTitle')}
						</p>
						<ul className={styles.list}>
							<Trans i18nKey="emailVerification.default.list">
								<li className={styles.listItem}>
									<strong />
								</li>
							</Trans>
						</ul>
					</Col>
				</Row>
			</Container>
		</Section>
	);
}
