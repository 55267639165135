import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import useFocusOnError from '../../../utils/useFocusOnError';

import styles from './TextareaField.module.scss';

export default function TextareaField({
	cols,
	disabled,
	error,
	helper,
	id,
	label,
	maxLength,
	minLength,
	name,
	onBlur,
	onChange,
	placeholder,
	readonly,
	required,
	rows,
	value,
}) {
	const inputRef = useRef();
	useFocusOnError(name, inputRef);

	return (
		<div className={`${styles.root} ${error && styles.rootError}`.trim()}>
			<div className={styles.labelWrap}>
				<label className={styles.label} htmlFor={id}>{label}</label>
				{helper && <div className={styles.helper}>{helper}</div>}
			</div>
			<div className={styles.inputWrap}>
				<textarea
					ref={inputRef}
					className={styles.textarea}
					cols={cols}
					disabled={disabled}
					id={id}
					maxLength={maxLength}
					minLength={minLength}
					name={name}
					onBlur={onBlur}
					onChange={onChange}
					placeholder={placeholder}
					readOnly={readonly}
					required={required}
					rows={rows}
					value={value}
				/>
			</div>
			{error && (
				<div className={styles.error}>{error}</div>
			)}
		</div>
	);
}

TextareaField.propTypes = {
	cols: PropTypes.number,
	disabled: PropTypes.bool,
	error: PropTypes.string,
	helper: PropTypes.string,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	maxLength: PropTypes.number,
	minLength: PropTypes.number,
	name: PropTypes.string.isRequired,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	readonly: PropTypes.bool,
	required: PropTypes.bool,
	rows: PropTypes.number,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
};

TextareaField.defaultProps = {
	cols: null,
	disabled: false,
	error: '',
	helper: '',
	maxLength: null,
	minLength: null,
	onBlur: null,
	onChange: null,
	placeholder: null,
	readonly: false,
	required: false,
	rows: 5,
	value: undefined,
};
