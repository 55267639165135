import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Container, Section } from '../../components/layout';
import { ExternalLink } from '../../components/ui';

import styles from './Error.module.scss';

export default function Error({ homepageUrl, page, resetError }) {
	const pageRef = useRef(undefined);
	const [t] = useTranslation();

	useEffect(() => {
		if (pageRef.current === undefined) {
			pageRef.current = page;
		} else if (pageRef.current !== page && resetError !== null) {
			pageRef.current = page;
			resetError();
		}
	}, [page, resetError]);

	return (
		<Section>
			<Container center>
				<h1 className={styles.title}>{t('error.title', { defaultValue: 'Něco se nepovedlo' })}</h1>
				<h2 className={styles.subtitle}>{t('error.subtitle', { defaultValue: 'Omlouváme se, na stránce došlo k chybě.' })}</h2>
				<ExternalLink to={homepageUrl}>{t('error.link', { defaultValue: 'Zpět na hlavní stránku' })}</ExternalLink>
			</Container>
		</Section>
	);
}

Error.propTypes = {
	homepageUrl: PropTypes.string.isRequired,
	page: PropTypes.string,
	resetError: PropTypes.func,
};

Error.defaultProps = {
	page: null,
	resetError: null,
};
