import React from 'react';
import PropTypes from 'prop-types';

import { Picture } from '../../../../components/ui';

import styles from './BlogPostImage.module.scss';

export default function BlogPostImage({
	alt,
	breakpoint,
	captionVisible,
	responsive,
	src,
	src2x,
	src2xWebP,
	srcBreakpoint,
	srcBreakpoint2x,
	srcBreakpoint2xWebP,
	srcBreakpointWebP,
	srcWebP,
}) {
	return (
		<figure className={styles.root}>
			<Picture
				alt={alt}
				breakpoint={breakpoint}
				className={`${styles.image} ${responsive ? styles.responsive : ''}`.trim()}
				src={src}
				src2x={src2x}
				src2xWebP={src2xWebP}
				srcBreakpoint={srcBreakpoint}
				srcBreakpoint2x={srcBreakpoint2x}
				srcBreakpoint2xWebP={srcBreakpoint2xWebP}
				srcBreakpointWebP={srcBreakpointWebP}
				srcWebP={srcWebP}
				lazyLoad={false}
			/>
			{captionVisible && <figcaption className={styles.caption}>{alt}</figcaption>}
		</figure>
	);
}

BlogPostImage.propTypes = {
	alt: PropTypes.string.isRequired,
	breakpoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
	captionVisible: PropTypes.bool,
	responsive: PropTypes.bool,
	src: PropTypes.string.isRequired,
	src2x: PropTypes.string,
	src2xWebP: PropTypes.string,
	srcBreakpoint: PropTypes.string,
	srcBreakpoint2x: PropTypes.string,
	srcBreakpoint2xWebP: PropTypes.string,
	srcBreakpointWebP: PropTypes.string,
	srcWebP: PropTypes.string,
};

BlogPostImage.defaultProps = {
	breakpoint: null,
	captionVisible: false,
	responsive: true,
	src2x: null,
	src2xWebP: null,
	srcBreakpoint: null,
	srcBreakpoint2x: null,
	srcBreakpoint2xWebP: null,
	srcBreakpointWebP: null,
	srcWebP: null,
};
