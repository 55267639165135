import React, { useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	GTMContext,
	GTMContextDispatch,
	GTMProvider as ReactGTMProvider,
	sendToGTM,
} from '@elgorditosalsero/react-gtm-hook';
import { useLocation } from 'react-router-dom';

const noop = () => {};

export function useSendDataToGTM() {
	const { dataLayerName } = useContext(GTMContext);
	const dispatch = useContext(GTMContextDispatch);

	if (dispatch === undefined) {
		throw new Error('useSendDataToGTM must be used within a GTMProvider');
	}

	if (dispatch === noop) {
		return noop;
	}

	return useCallback((data) => {
		// async 1) to not crash React, but be reported to Sentry
		//       2) because dataLayer is initialized asynchronously via inserted script
		Promise.resolve().then(() => sendToGTM({ dataLayerName, data }));
	}, [dataLayerName]);
}

export function useTrackGTMUser() {
	const sendDataToGTM = useSendDataToGTM();

	return useCallback((
		id,
		createdAt = null,
		userFirstName = null,
		userSurname = null,
		userEmail = null,
		userCountry = null,
	) => {
		const data = id !== null ? {
			userIDhash: id * +new Date(createdAt),
			userFirstName,
			userSurname,
			userEmail,
			userCountry,
		} : {
			userIDhash: '',
			userFirstName: '',
			userSurname: '',
			userEmail: '',
			userCountry: '',
		};

		sendDataToGTM(data);
	}, [sendDataToGTM]);
}

export function useTrackGTMEvent() {
	const sendDataToGTM = useSendDataToGTM();

	return useCallback((eventName, props = null) => {
		const data = props !== null ? {
			event: eventName,
			...props,
		} : { event: eventName };

		sendDataToGTM(data);
	}, [sendDataToGTM]);
}

export function TrackGTMPageview() {
	const location = useLocation();
	const trackGTMEvent = useTrackGTMEvent();

	useEffect(() => {
		trackGTMEvent('pageview', {
			page: {
				url: location.pathname,
			},
		});
	}, [location, trackGTMEvent]);

	return null;
}

export default function GTMProvider({
	id,
	children,
}) {
	if (id === '') {
		return (
			<GTMContextDispatch.Provider value={noop}>
				{children}
			</GTMContextDispatch.Provider>
		);
	}

	return (
		<ReactGTMProvider state={{ id }}>
			{children}
		</ReactGTMProvider>
	);
}

GTMProvider.propTypes = {
	id: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

GTMProvider.defaultProps = {
	children: null,
};
