import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import {
	Col,
	Container,
	Section,
	Row,
} from '../../components/layout';
import {
	Box,
	CopyLink,
	Loader,
} from '../../components/ui';
import { useUserContext } from '../../contexts/UserContext';
import EmailShare from './EmailShare';
import EmailStats from './EmailStats';
import SocialShare from './SocialShare';
import Stats from './Stats';

import styles from './Referrals.module.scss';

export default function Referrals({
	baseUrl,
	homepageUrl,
}) {
	const [t] = useTranslation();
	const [user] = useUserContext();

	if (user === null) return <Loader fullPage />;

	return (
		<Section bordered>
			<Container>
				<Row>
					<Col lg={4}>
						<div className={styles.root}>
							<h1 className={styles.title}>{t('referrals.title')}</h1>
							<p className={styles.text}>
								<Trans i18nKey="referrals.text">
									<strong />
									<br />
								</Trans>
							</p>
						</div>
						<EmailShare
							code={user.generated_reference_code}
							name={`${user.first_name} ${user.last_name}`}
						/>
						<EmailStats />
					</Col>
					<Col lg={1} />
					<Col lg={7}>
						<Box>
							<div className={styles.box}>
								<p className={styles.text}>{t('referrals.code')}</p>
								<div className={styles.code}>{user.generated_reference_code}</div>
								<CopyLink
									label={t('referrals.copyLink')}
									link={`${baseUrl}${homepageUrl}?referral=${user.generated_reference_code}`}
								/>
								<SocialShare
									code={user.generated_reference_code}
									link={`${baseUrl}${homepageUrl}?referral=${user.generated_reference_code}`}
								/>
								<Stats />
							</div>
						</Box>
					</Col>
				</Row>
			</Container>
		</Section>
	);
}

Referrals.propTypes = {
	baseUrl: PropTypes.string.isRequired,
	homepageUrl: PropTypes.string.isRequired,
};
