import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { fetchInvestorProfile, fetchInvestorProfileHistory } from '../../../utils/api';
import useFetch from '../../../utils/useFetch';
import useFormatNumber from '../../../utils/useFormatNumber';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import { ExternalLink, ToggleMulti } from '../../ui';
import { Col, Container, Row } from '../../layout';
import PortfolioOverviewGrowth from '../PortfolioOverview/PortfolioOverviewGrowth';
import {
	EvaluationChart,
	InvestorProfileChart,
	ProductChart,
} from '../index';

import styles from './ProductAccount.module.scss';

const useFetchInvestorProfileHistory = useFetch(fetchInvestorProfileHistory);
const useFetchInvestorProfile = useFetch(fetchInvestorProfile);

export default function ProductAccount({
	currentSlide,
	profiles,
	howItWorksUrl,
}) {
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const [data, loading, error] = useFetchInvestorProfileHistory({}, profiles[currentSlide].id, country, language);
	const [investorProfiles] = useFetchInvestorProfile({}, country, language);
	const formatCurrency = useFormatNumber({ style: 'currency', currency: data.currency?.code });
	const trackGTMEvent = useTrackGTMEvent();
	const [selected, setSelected] = useState(0);

	if (error || loading) return null;

	const investorProfile = profiles[currentSlide];

	return (
		<div className={styles.root}>
			<div className={styles.head}>
				<Row>
					<Col lg={8} xl={7}>
						<p className={styles.title}>{t('productAccount.title')}</p>
						<Row>
							<Col md={6}>
								<div className={styles.growthLabel}>{t('transparentAccount.netGrowth')}</div>
								<div className={styles.growthValue}>
									<PortfolioOverviewGrowth
										currency={data.currency ?? null}
										value={data.netGrowth ?? null}
									/>
								</div>
							</Col>
							<Col md={6}>
								<div className={styles.growthLabel}>{t('transparentAccount.netGrowthRatio')}</div>
								<div className={styles.growthValue}>
									<PortfolioOverviewGrowth
										value={(data.netGrowthRatio / 100) ?? null}
										ratio
									/>
								</div>
							</Col>
						</Row>
						<div className={styles.info}>
							<Row middle start>
								<Col md={6}>
									{investorProfile && (
										<div className={styles.infoProfile}>
											<img
												src={`${process.env.PUBLIC_URL}/images/faces/${investorProfile.faceImageFile}`}
												width={75}
												height={75}
												alt={investorProfile.name}
											/>
											<div>
												<div className={styles.infoLabel}>
													{t('transparentAccount.type')}
												</div>
												<div className={styles.infoValue}>
													{investorProfile.name}
												</div>
											</div>
										</div>
									)}
								</Col>
								<Col md={6}>
									<div className={styles.infoLabel}>
										{t('transparentAccount.initialDeposit')}
									</div>
									<div className={styles.infoValue}>
										{formatCurrency(data.initialDeposit ?? null)}
									</div>
								</Col>
							</Row>
						</div>
					</Col>
					<Col lg={4} xl={5}>
						<div className={styles.donut}>
							{investorProfile && investorProfiles.productCategories && (
								<InvestorProfileChart
									onlyChart
									profile={investorProfile}
									categories={investorProfiles.productCategories}
								/>
							)}
						</div>
					</Col>
				</Row>
			</div>
			{data !== null && (
				<>
					<Container>
						<div className={styles.toggleWrap}>
							<ToggleMulti
								isVertical
								selected={selected}
								setSelected={setSelected}
								options="productAccount.toggleOptions"
								onClick={() => trackGTMEvent('interactions', {
									eventCategory: 'interactions',
									eventAction: 'buttonClick',
									eventLabel: selected ? 'history_chart_to_value' : 'history_chart_to_growth',
								})}
							/>
						</div>
					</Container>
					{selected === 0 || selected === 1 ? (
						<ProductChart
							currency={data.currency ?? null}
							data={data.valueGraph ?? null}
							evaluation={selected}
						/>
					) : (
						<EvaluationChart
							data={data.valueGraph ?? null}
						/>
					)}
				</>
			)}
			<p className={styles.note}>
				<Trans i18nKey="productAccount.note">
					<ExternalLink
						to={howItWorksUrl}
						blank
					/>
				</Trans>
			</p>
		</div>
	);
}

ProductAccount.propTypes = {
	currentSlide: PropTypes.number.isRequired,
	profiles: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			faceImageFile: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			description: PropTypes.string.isRequired,
			products: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.number.isRequired,
					productId: PropTypes.number.isRequired,
					ratio: PropTypes.string.isRequired,
				}),
			).isRequired,
		}),
	).isRequired,
	howItWorksUrl: PropTypes.string.isRequired,
};
