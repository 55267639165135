import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router-dom';

import { fetchChildrenList } from '../../../utils/api';
import { useUserContext } from '../../../contexts/UserContext';
import useFetchWithReload from '../../../utils/useFetchWithReload';
import {
	checkChildCheckSessionDate,
	saveChildCheckSessionDate,
} from '../../../utils/sessionChildAgeCheck';
import { Modal } from '../../ui';
import ChildPersonalInformationForm from '../ChildPersonalInformationForm';

import styles from './ChildAgeCheck.module.scss';

const useFetchChildrenList = useFetchWithReload(fetchChildrenList);

export default function ChildAgeCheck() {
	const [t] = useTranslation();
	const [user] = useUserContext();
	const location = useLocation();
	const [childrenList, loading, , , reloadChildrenList] = useFetchChildrenList(null, user.child);
	const ageCheckChild = childrenList?.filter((child) => child.checkEmailAndPhone === true)[0] ?? null;
	const [modalOpen, setModalOpen] = useState(false);

	useEffect(() => {
		setModalOpen(
			ageCheckChild !== null
			&& !user.client_zone_locked
			&& !user.contract_missing
			&& !user.personal_information_check_due
			&& checkChildCheckSessionDate(),
		);
	}, [ageCheckChild, location]);

	if (user === null || loading) {
		return null;
	}

	const handleClose = () => {
		saveChildCheckSessionDate();
		setModalOpen(false);
	};

	return (
		<Modal
			isVisible={modalOpen}
			onClose={handleClose}
			size="medium"
			title={t('childAgeCheck.modal.title')}
		>
			<div className={styles.root}>
				<h3 className={styles.subtitle}>
					{`${ageCheckChild?.firstName ? ageCheckChild.firstName : ''} ${ageCheckChild?.lastName ? ageCheckChild?.lastName : ''}`}
				</h3>
				<p className={styles.text}>
					{t('childAgeCheck.modal.text')}
				</p>
				{ageCheckChild !== null && (
					<ChildPersonalInformationForm
						child={ageCheckChild}
						confirm
						reloadChildrenList={reloadChildrenList}
					/>
				)}
			</div>
		</Modal>
	);
}
