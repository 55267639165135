/* global document, window */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function SmoothScroll() {
	const { key, hash, pathname } = useLocation();

	useEffect(() => {
		const clearHash = hash.substring(1);
		const element = clearHash !== '' ? document.getElementById(clearHash) : null;

		if (element) {
			element.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
				inline: 'nearest',
			});
		} else {
			window.scroll({
				top: 0,
				left: 0,
			});
		}
	}, [key, hash, pathname]);

	return null;
}
