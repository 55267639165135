import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';

import { useLanguage } from '../../../contexts/LocaleContext';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import { fromISODate } from '../../../utils/date';
import { toInt } from '../../../utils/number';
import useFormatNumber from '../../../utils/useFormatNumber';
import {
	Container,
	Section,
} from '../../layout';
import { Loader, Toggle } from '../../ui';

import styles from './HistoryChart.module.scss';

function roundYAxis(value) {
	if (value < 1000) {
		return Math.round(value);
	}

	const log = Math.floor(Math.log10(value));
	const quotient = 10 ** (log - 2);
	return Math.round(value / quotient) * quotient;
}

const chartOptions = (formatCurrency, formatDate, min, max, tooltipValue, evaluation) => ({
	chart: {
		animations: {
			enabled: false,
		},
		toolbar: {
			show: false,
		},
		zoom: {
			enabled: false,
		},
	},
	colors: ['#48b958', '#48b958'],
	dataLabels: {
		enabled: false,
	},
	fontFamily: 'Rajdhani, sans-serif',
	legend: {
		show: false,
	},
	stroke: {
		show: !evaluation,
		width: 3,
	},
	plotOptions: {
		candlestick: {
			colors: {
				upward: '#f39200',
				downward: '#99556a',
			},
		},
		bar: {
			colors: {
				ranges: [{
					from: -9999999,
					to: 0,
					color: '#99556a',
				}],
			},
		},
	},
	tooltip: {
		custom: ({ series, dataPointIndex, w: { config } }) => {
			const value = series[0][dataPointIndex];
			const dataPoint = config.series[0].data[dataPointIndex];

			const transactionTypes = dataPoint.transactionTypes.filter(
				({ transactionValue }) => transactionValue > 0,
			).map(({ name, transactionValue }) => (
				`<div class="apexcharts-tooltip-text">${name}:&nbsp;<strong>${formatCurrency(transactionValue)}</strong></div>`
			)).join('');

			return (
				`<div>
						<div class="apexcharts-tooltip-title">
							${formatDate(dataPoint.x)} ${formatDate(dataPoint.dateTo) !== formatDate(dataPoint.x) ? `- ${formatDate(dataPoint.dateTo)}` : ''}
						</div>
						<div class="apexcharts-tooltip-series-group ${styles.tooltipGroup}">
							<div class="apexcharts-tooltip-text">
								${tooltipValue}:&nbsp;<strong>${formatCurrency(value)}</strong>
							</div>
							${transactionTypes}
						</div>
					</div>`
			);
		},
		marker: {
			show: false,
		},
	},
	xaxis: {
		type: 'datetime',
		labels: {
			rotateAlways: true,
			minHeight: 100,
			offsetX: 5,
			style: {
				colors: '#999',
				fontSize: '13px',
			},
			formatter(value, timestamp) {
				return formatDate(new Date(timestamp));
			},
		},
		axisTicks: {
			show: false,
		},
		tooltip: {
			enabled: false,
		},
	},
	yaxis: {
		tickAmount: Math.min(5, max - min),
		min,
		max,
		forceNiceScale: false,
		labels: {
			style: {
				color: '#999',
				fontSize: '15px',
			},
			formatter(val) {
				return val !== null ? formatCurrency(roundYAxis(val)) : null;
			},
		},
	},
	annotations: {
		position: 'back',
		yaxis: [
			{
				y: 0,
				strokeDashArray: 0,
				borderColor: evaluation ? '#b6b6b6' : '#ffffff',
			},
		],
	},
});

function HistoryChart({
	currency,
	data,
	evaluation,
	onlyChart,
	setEvaluation,
}) {
	const [t] = useTranslation();
	const language = useLanguage();
	const formatCurrency = useFormatNumber({ style: 'currency', currency: currency?.code });
	const formatDate = (date) => date.toLocaleDateString(language);
	const trackGTMEvent = useTrackGTMEvent();
	let min = null;
	let max = 0;
	let minEvaluation = null;
	let maxEvaluation = 0;

	const lineChartData = data ? data.map((day) => {
		const value = day.totalValue !== null ? toInt(day.totalValue) : null;

		if (value !== null || day.transactionTotal) {
			const transactionTotal = day.transactionTotal ?? 0;
			const minValue = value !== null ? Math.max(0, value - Math.max(0, transactionTotal)) : 0;
			const maxValue = value !== null ? value + Math.max(0, -transactionTotal) : Math.abs(transactionTotal);

			if (min === null || minValue < min) {
				min = minValue;
			}

			if (maxValue > max) {
				max = maxValue;
			}
		}
		return {
			x: fromISODate(day.dateFrom),
			y: value,
			transactionTypes: [
				{
					name: t('historyChart.tooltip.nonGrowthPlus'),
					transactionValue: day.transactionValueNonGrowthPlus,
				},
				{
					name: t('historyChart.tooltip.nonGrowthMinus'),
					transactionValue: day.transactionValueNonGrowthMinus,
				},
				{
					name: t('historyChart.tooltip.growthPlus'),
					transactionValue: day.transactionValueGrowthPlus,
				},
				{
					name: t('historyChart.tooltip.growthMinus'),
					transactionValue: day.transactionValueGrowthMinus,
				},
			],
			dateTo: fromISODate(day.dateTo),
		};
	}) : [];

	const barChartDataEvaluation = data ? data.map((day) => {
		const value = day.netGrowth !== null ? toInt(day.netGrowth) : null;

		if (value !== null) {
			if (minEvaluation === null || value < minEvaluation) {
				minEvaluation = value;
			}

			if (value > maxEvaluation) {
				maxEvaluation = value;
			}
		}
		return {
			x: fromISODate(day.dateFrom),
			y: value,
			transactionTypes: [],
			dateTo: fromISODate(day.dateTo),
		};
	}) : [];

	const candlestickChartData = data ? data.map((day) => {
		let val = [null, null, null, null];
		if (day.transactionTotal !== 0) {
			val = day.transactionTotal !== null ? [
				toInt(day.totalValue) - day.transactionTotal,
				toInt(day.totalValue) - day.transactionTotal,
				toInt(day.totalValue),
				toInt(day.totalValue),
			] : [
				Math.max(0, -day.transactionTotal),
				Math.max(0, -day.transactionTotal),
				Math.max(0, day.transactionTotal),
				Math.max(0, day.transactionTotal),
			];
		}

		return {
			x: fromISODate(day.dateFrom),
			y: val,
		};
	}) : [];

	min = min ?? 0;
	const chartMin = Math.round(Math.max(0, min - Math.max(min / 20, 1)));
	const chartMax = Math.round(max + Math.max(max / 20, 1));

	minEvaluation = minEvaluation ?? 0;
	const chartMinEvaluation = Math.round(minEvaluation - Math.max(Math.abs(minEvaluation / 20), 1));
	const chartMaxEvaluation = Math.round(maxEvaluation + Math.max(Math.abs(maxEvaluation / 20), 1));

	const tooltipValue = t(evaluation ? 'historyChart.tooltip.evaluation' : 'historyChart.tooltip.value');

	const chart = (
		<div className={`${styles.root} ${onlyChart ? styles.rootOnlyChart : ''}`.trim()}>
			{data ? (
				<>
					<div className={styles.chartWrap}>
						<div className={styles.chart}>
							<Chart
								height={300}
								options={chartOptions(
									formatCurrency,
									formatDate,
									evaluation ? chartMinEvaluation : chartMin,
									evaluation ? chartMaxEvaluation : chartMax,
									tooltipValue,
									evaluation,
								)}
								series={evaluation ? [
									{
										name: 'bar',
										type: 'bar',
										data: barChartDataEvaluation,
									},
								] : [
									{
										name: 'line',
										type: 'line',
										data: lineChartData,
									}, {
										name: 'candle',
										type: 'candlestick',
										data: candlestickChartData,
									},
								]}
								type={evaluation ? 'bar' : 'line'}
							/>
						</div>
					</div>
					{!onlyChart && (
						<>
							<div className={styles.legendList}>
								{evaluation ? (
									<>
										<div className={styles.legendListItem}>
											<span className={`${styles.legendCircle} ${styles.legendCircleValue}`} />
											{t('historyChart.legend.evaluation')}
										</div>
										<div className={styles.legendListItem}>
											<span className={`${styles.legendCircle} ${styles.legendCircleDecrease}`} />
											{t('historyChart.legend.devaluation')}
										</div>
									</>
								) : (
									<>
										<div className={styles.legendListItem}>
											<span className={`${styles.legendCircle} ${styles.legendCircleValue}`} />
											{t('historyChart.legend.value')}
										</div>
										<div className={styles.legendListItem}>
											<span className={`${styles.legendCircle} ${styles.legendCircleIncrease}`} />
											{t('historyChart.legend.increase')}
										</div>
										<div className={styles.legendListItem}>
											<span className={`${styles.legendCircle} ${styles.legendCircleDecrease}`} />
											{t('historyChart.legend.decrease')}
										</div>
									</>
								)}
							</div>
						</>
					)}
				</>
			) : (
				<div className={styles.loading}>
					<p>
						{t('historyChart.loading')}
					</p>
					<Loader />
				</div>
			)}
		</div>
	);

	if (onlyChart) return chart;

	return (
		<Section bordered>
			<Container>
				<div className={styles.toggleWrap}>
					<Toggle
						labelFirst={t('historyChart.toggle.value')}
						labelSecond={t('historyChart.toggle.evaluation')}
						setToggle={setEvaluation}
						toggle={evaluation}
						onClick={() => trackGTMEvent('interactions', {
							eventCategory: 'interactions',
							eventAction: 'buttonClick',
							eventLabel: evaluation ? 'history_chart_to_value' : 'history_chart_to_growth',
						})}
					/>
				</div>
			</Container>
			<Container>
				{chart}
			</Container>
		</Section>
	);
}

HistoryChart.propTypes = {
	currency: PropTypes.shape({
		code: PropTypes.string.isRequired,
		codeShort: PropTypes.string.isRequired,
		codeShortBefore: PropTypes.bool.isRequired,
	}),
	data: PropTypes.arrayOf(
		PropTypes.shape({
			dateFrom: PropTypes.string,
			dateTo: PropTypes.string,
			netGrowth: PropTypes.number,
			totalValue: PropTypes.number,
			transactionTotal: PropTypes.number,
			transactionValueGrowthMinus: PropTypes.number,
			transactionValueGrowthPlus: PropTypes.number,
			transactionValueNonGrowthMinus: PropTypes.number,
			transactionValueNonGrowthPlus: PropTypes.number,
		}).isRequired,
	).isRequired,
	onlyChart: PropTypes.bool,
	evaluation: PropTypes.bool,
	setEvaluation: PropTypes.func,
};

HistoryChart.defaultProps = {
	currency: null,
	onlyChart: false,
	evaluation: false,
	setEvaluation: null,
};

export default memo(HistoryChart);
