import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, useFormikContext } from 'formik';

import { TextareaField, TextField } from '../../../components/forms';
import { Alert, Button } from '../../../components/ui';
import { useLanguage } from '../../../contexts/LocaleContext';
import { sendRecommendation } from '../../../utils/api';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import useRequestAuth from '../../../utils/useRequestAuth';
import { validateEmailShare } from '../../../utils/validators';
import useRecaptchaToken from '../../../utils/useRecaptchaToken';

import styles from './EmailShare.module.scss';

const ChangeInitialMessage = ({ message }) => {
	const messageRef = useRef(message);
	const { setFieldValue, values } = useFormikContext();
	const isInitial = values.message === messageRef.current;

	useEffect(() => {
		if (message !== messageRef.current) {
			if (isInitial) {
				setFieldValue('message', message);
			}
			messageRef.current = message;
		}
	}, [isInitial, message]);

	return null;
};

export default function EmailShare({
	code,
	name,
}) {
	const language = useLanguage();
	const sendRecommendationAuth = useRequestAuth(sendRecommendation);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [t] = useTranslation();
	const trackGTMEvent = useTrackGTMEvent();
	const { getRecaptchaToken } = useRecaptchaToken('formSubmit');

	const message = t('referrals.form.messageText', { code, name });

	return (
		<Formik
			initialValues={{
				email: '',
				message,
			}}
			validate={validateEmailShare}
			onSubmit={async (values, { setFieldValue }) => {
				setError(false);
				setSuccess(false);

				const recaptchaToken = await getRecaptchaToken();

				if (!recaptchaToken) {
					setError(true);
					return;
				}

				try {
					await sendRecommendationAuth(
						language,
						values.email,
						values.message,
						recaptchaToken,
					);
				} catch {
					setError(true);
					return;
				}
				setFieldValue('email', '', false);

				setSuccess(true);

				trackGTMEvent('interactions', {
					eventCategory: 'interactions',
					eventAction: 'formSend',
					eventLabel: 'odeslat_bonus_kod',
				});
			}}
		>
			{({
				errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values,
			}) => (
				<form onSubmit={handleSubmit} className={styles.root}>
					<ChangeInitialMessage message={message} />
					{error && (
						<Alert type="danger">
							{t('forms.error')}
						</Alert>
					)}
					{success && (
						<Alert type="success" close onClose={() => setSuccess(false)}>
							{t('referrals.form.success')}
						</Alert>
					)}
					<TextField
						onBlur={handleBlur}
						onChange={handleChange}
						error={
							errors.email
							&& touched.email
							&& t(errors.email)
						}
						id="email"
						name="email"
						label={t('referrals.form.email.label')}
						required
						type="email"
						value={values.email}
					/>
					<TextareaField
						onBlur={handleBlur}
						onChange={handleChange}
						error={
							errors.message
							&& touched.message
							&& t(errors.message)
						}
						id="message"
						name="message"
						label={t('referrals.form.message.label')}
						required
						value={values.message}
					/>
					<Button
						label={t('referrals.form.send')}
						disabled={isSubmitting}
						isSubmit
					/>
				</form>
			)}
		</Formik>
	);
}

EmailShare.propTypes = {
	code: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
};

ChangeInitialMessage.propTypes = {
	message: PropTypes.string.isRequired,
};
