/* global localStorage */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { fetchInvestorProfile } from '../../../utils/api';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import useFetch from '../../../utils/useFetch';
import { Container, Section } from '../../layout';
import { ButtonLink, ToggleMulti } from '../../ui';
import FindOutInfoBox from '../FindOutInfoBox';
import InvestorProfileDetailInfo from './InvestorProfileDetailInfo';
import InvestorProfileDetailSelection from './InvestorProfileDetailSelection';
import InvestorProfileDetailSlider from './InvestorProfileDetailSlider';

import styles from './InvestorProfileDetail.module.scss';

const useFetchInvestorProfile = useFetch(fetchInvestorProfile);

export default function InvestorProfileDetail({
	childProductUrl,
	classicProductUrl,
	esgProductUrl,
	registrationUrl,
}) {
	const [t] = useTranslation();
	const navigate = useNavigate();
	const country = useCountry();
	const language = useLanguage();
	const [data, loading] = useFetchInvestorProfile({}, country, language);
	const [currentSlide, setCurrentSlide] = useState(3);
	const [accountType, setAccountType] = useState(0);
	const [profileType, setProfileType] = useState(0);
	const [hasSeenFindOutInfoBox, setHasSeenFindOutInfoBox] = useState(JSON.parse(localStorage.getItem('seen_find_out_profile_popup')) === true);
	const [isInfoBoxVisible, setIsInfoBoxVisible] = useState(false);
	const trackGTMEvent = useTrackGTMEvent();

	useEffect(() => {
		if (!hasSeenFindOutInfoBox && currentSlide !== 3) {
			setTimeout(() => {
				trackGTMEvent('interactions', {
					eventCategory: 'interactions',
					eventAction: 'popUp',
					eventLabel: 'portfolio_slider_popup_display',
				});
				setIsInfoBoxVisible(true);
			}, 5000);
		}
	}, [currentSlide]);

	if (loading) {
		return null;
	}

	const investorProfiles = profileType === 1 ? data.investorProfilesEsg : data.investorProfiles;

	const handleInfoBoxClose = () => {
		setHasSeenFindOutInfoBox(true);
		setIsInfoBoxVisible(false);
		localStorage.setItem('seen_find_out_profile_popup', 'true');
	};

	const handleInfoBoxApprove = () => {
		handleInfoBoxClose();
		trackGTMEvent('interactions', {
			eventCategory: 'interactions',
			eventAction: 'buttonClick',
			eventLabel: 'portfolio_slider_popup_click',
		});
		navigate(registrationUrl);
	};

	return (
		<Section id="portfolio">
			<Container>
				<h2 className={styles.title}>{t('investorProfileDetail.title')}</h2>
				<div className={styles.wrapper}>
					<h4 className={styles.subtitle}>
						<Trans i18nKey="investorProfileDetail.subtitle1">
							<strong />
						</Trans>
					</h4>
				</div>
				<div className={styles.contentWrap}>
					<div className={styles.toggleWrap}>
						<div className={styles.toggleColumn}>
							<h4 className={styles.toggleTitle}>
								{t('investorProfileDetail.productToggle')}
							</h4>
							<ToggleMulti
								options="investorProfileDetail.profileAccountOptions"
								selected={accountType}
								setSelected={setAccountType}
							/>
						</div>
						<div className={styles.toggleColumn}>
							<h4 className={styles.toggleTitle}>
								{t('investorProfileDetail.portfolioToggle')}
							</h4>
							<ToggleMulti
								options="investorProfileDetail.profileTypeOptions"
								selected={profileType}
								setSelected={setProfileType}
							/>
						</div>
					</div>
					<div className={styles.toggleColumn}>
						<h4 className={styles.toggleTitle}>
							{t('investorProfileDetail.selectionTitle')}
						</h4>
						<InvestorProfileDetailSelection
							currentSlide={currentSlide}
							onSelectionChange={setCurrentSlide}
							profiles={investorProfiles ?? []}
						/>
					</div>
					<InvestorProfileDetailSlider
						currentSlide={currentSlide}
						products={data.products ?? []}
						profiles={investorProfiles ?? []}
						setCurrentSlide={setCurrentSlide}
					/>
					{investorProfiles && investorProfiles[currentSlide] && (
						<InvestorProfileDetailInfo
							categories={data.productCategories ?? []}
							profile={investorProfiles[currentSlide]}
						/>
					)}
					<div className={styles.buttonWrap}>
						{accountType === 0 ? (
							<ButtonLink
								label={t('investorProfilePreview.aboutButton')}
								to={profileType === 1 ? esgProductUrl : classicProductUrl}
								outline
							/>
						) : (
							<ButtonLink
								label={t('investorProfilePreview.aboutButton')}
								to={childProductUrl}
								outline
							/>
						)}
						<ButtonLink label={t('register.title')} to={registrationUrl} />
					</div>
				</div>
			</Container>
			{isInfoBoxVisible && (
				<FindOutInfoBox
					onApprove={handleInfoBoxApprove}
					onDismiss={handleInfoBoxClose}
				/>
			)}
		</Section>
	);
}

InvestorProfileDetail.propTypes = {
	childProductUrl: PropTypes.string.isRequired,
	classicProductUrl: PropTypes.string.isRequired,
	esgProductUrl: PropTypes.string.isRequired,
	registrationUrl: PropTypes.string.isRequired,
};
