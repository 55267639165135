import React from 'react';
import PropTypes from 'prop-types';

import useFormatNumber from '../../../../utils/useFormatNumber';

import styles from '../PortfolioOverview.module.scss';

const getGrowth = (value) => {
	if (value > 0) {
		return [styles.netGrowthUp, '+\u00a0'];
	}
	if (value < 0) {
		return [styles.netGrowthDown, '-\u00a0'];
	}
	return ['', '', 1];
};

export default function PortfolioOverviewGrowth({
	currency,
	ratio,
	value,
}) {
	const formatOptions = ratio
		? { style: 'percent', fractionDigits: 1 }
		: { style: 'currency', currency: currency?.code, fractionDigits: 0 };
	const formatNumber = useFormatNumber(formatOptions);

	const [netGrowthClass, sign] = getGrowth(value);

	return (
		<div className={`${styles.netGrowth} ${netGrowthClass}`.trim()}>
			{netGrowthClass !== '' && (
				<svg
					width={30}
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 448 512"
				>
					<path
						fill="currentColor"
						d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
					/>
				</svg>
			)}
			<span className={styles.netGrowthValue}>
				{sign}
				{formatNumber(value < 0 ? -value : value)}
			</span>
		</div>
	);
}

PortfolioOverviewGrowth.propTypes = {
	currency: PropTypes.shape({
		code: PropTypes.string.isRequired,
		codeShort: PropTypes.string.isRequired,
		codeShortBefore: PropTypes.bool.isRequired,
	}),
	ratio: PropTypes.bool,
	value: PropTypes.number,
};

PortfolioOverviewGrowth.defaultProps = {
	currency: null,
	ratio: false,
	value: null,
};
