import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import blogTagShape from '../../blogTagShape';

import styles from './BlogTagFilter.module.scss';

export default function BlogTagFilter({
	handleTagClick,
	searchTags,
	topics,
}) {
	const [t] = useTranslation();

	return (
		<div className={styles.wrap}>
			<div className={styles.title}>
				{t('blog.menu.tags.title')}
			</div>
			<div className={styles.list}>
				{(topics ?? []).map((topic) => (
					<button
						key={topic.id}
						className={`${styles.button} ${searchTags?.includes(topic.slug) ? styles.selected : ''}`.trim()}
						onClick={() => handleTagClick(topic.slug)}
						type="button"
					>
						{topic.name}
					</button>
				))}
			</div>
		</div>
	);
}

BlogTagFilter.propTypes = {
	handleTagClick: PropTypes.func.isRequired,
	searchTags: PropTypes.arrayOf(PropTypes.string),
	topics: PropTypes.arrayOf(blogTagShape.isRequired),
};

BlogTagFilter.defaultProps = {
	searchTags: null,
	topics: null,
};
