import React from 'react';
import PropTypes from 'prop-types';

import styles from './ShimmerRect.module.scss';

export default function ShimmerRect({
	width,
	maxWidth,
	minWidth,
	height,
	gap,
}) {
	return (
		<div
			className={`${styles.root} ${styles[`gap${gap}`]}`.trim()}
			style={{
				width,
				maxWidth,
				minWidth,
				height,
			}}
		/>
	);
}

ShimmerRect.propTypes = {
	width: PropTypes.number,
	maxWidth: PropTypes.number,
	minWidth: PropTypes.number,
	height: PropTypes.number,
	gap: PropTypes.number,
};

ShimmerRect.defaultProps = {
	width: null,
	maxWidth: null,
	minWidth: null,
	height: null,
	gap: 1,
};
