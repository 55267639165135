import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './HowItWorksSteps.module.scss';

const icons = [
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={120} height={121} viewBox="0 0 120 121">
		<mask id="howItWorksIcon1-a" width="120" height="121" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
			<circle cx="60" cy="60.84" r="60" fill="#F4DFBF" />
		</mask>
		<g mask="url(#howItWorksIcon1-a)">
			<circle cx="60" cy="60.84" r="60" fill="#F4DFBF" />
			<rect width="106" height="135" x="53" y="23.84" fill="#fff" stroke="#47202A" strokeWidth="2" rx="5" />
			<rect width="89" height="8" x="81" y="81.84" fill="#F1F1F1" rx="2" />
			<rect width="89" height="8" x="81" y="41.84" fill="#F1F1F1" rx="2" />
			<path stroke="#5BBE23" strokeLinejoin="round" strokeWidth="3" d="m62 45.24 4.74 3.6 7.26-9m-12 25.4 4.74 3.6 7.26-9m-12 25.4 4.74 3.6 7.26-9" />
			<rect width="89" height="8" x="81" y="61.84" fill="#F1F1F1" rx="2" />
			<circle cx="60" cy="60.84" r="59" stroke="#47202A" strokeWidth="2" />
		</g>
		<path fill="#FAB43D" d="M38.2 53.39c-.91-1.85-.61-4.03.39-5.83a19.9 19.9 0 0 0 .37-18.58c-4.92-9.89-16.83-14.02-26.6-9.24-9.78 4.79-13.72 16.68-8.8 26.56a20.21 20.21 0 0 0 15.1 11.01c2.05.31 3.98 1.4 4.9 3.25a5.34 5.34 0 0 0 7.11 2.41l5.14-2.51a5.25 5.25 0 0 0 2.4-7.07Zm-7.19 14.68a1.99 1.99 0 0 1 .45-3.26l5.34-2.62a2.01 2.01 0 0 1 2.89 1.63l.09 1.06c.07.82-.37 1.59-1.1 1.95l-4.65 2.27a2 2 0 0 1-2.23-.32l-.79-.7Z" />
		<path stroke="#F29C39" strokeLinecap="round" strokeWidth="2" d="M30.13 60.62c-4.25-12.91-9.61-27.56-12.92-21.19-3.3 6.38 7.22 6.9 9.96 4.74 2.73-2.15 3.5-10.94-.65-9.46-3.32 1.18 2.13 15.64 5.94 22" />
	</svg>,
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={122} height={121} viewBox="0 0 122 121">
		<mask id="howItWorksIcon2-a" width="121" height="121" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
			<circle cx="60.75" cy="60.84" r="60" fill="#F4DFBF" />
		</mask>
		<g mask="url(#howItWorksIcon2-a)">
			<circle cx="60.31" cy="60.84" r="59" fill="#F4DFBF" stroke="#47202A" strokeWidth="2" />
			<path stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m31.75 84.34 28.5-26.5-34.5-16m39 60.5-4-44h44.5" />
			<path fill="#F29C39" stroke="#F4DFBF" strokeWidth="4" d="M103.58 68.57a9.32 9.32 0 0 0 9.26-9.36 9.32 9.32 0 0 0-9.26-9.37 9.32 9.32 0 0 0-9.27 9.37 9.32 9.32 0 0 0 9.27 9.36Z" />
			<circle cx="22.25" cy="93.34" r="11.5" fill="#F29C39" stroke="#F4DFBF" strokeWidth="4" />
			<path fill="#F29C39" stroke="#F4DFBF" strokeWidth="4" d="M24.22 49.84c4.94 0 8.9-4.06 8.9-9s-3.96-9-8.9-9a8.95 8.95 0 0 0-8.9 9c0 4.94 3.95 9 8.9 9Zm40.36 56.73a9.32 9.32 0 0 0 9.26-9.36 9.32 9.32 0 0 0-9.26-9.37 9.32 9.32 0 0 0-9.27 9.37 9.32 9.32 0 0 0 9.27 9.36Z" />
		</g>
		<path fill="#F29C39" stroke="#47202A" strokeWidth="2" d="M108.03 72.09a12.8 12.8 0 0 0 12.72-12.88 12.8 12.8 0 0 0-12.72-12.89 12.8 12.8 0 0 0-12.72 12.89 12.8 12.8 0 0 0 12.72 12.88Z" />
		<path fill="#E94549" d="M108.03 47.32c2.32 0 4.58.7 6.51 2a11.85 11.85 0 0 1 4.32 5.34 12.04 12.04 0 0 1-2.54 12.95l-8.29-8.4V47.32Z" />
		<path fill="#F29C39" stroke="#47202A" strokeWidth="2" d="M21.3 105.9c6.39 0 11.54-5.25 11.54-11.7 0-6.44-5.15-11.69-11.54-11.69a11.62 11.62 0 0 0-11.55 11.7c0 6.44 5.16 11.69 11.55 11.69Z" />
		<path fill="#E94549" d="M21.3 83.51a10.63 10.63 0 0 1 9.25 5.55 10.81 10.81 0 0 1-.36 10.9 10.42 10.42 0 0 1-14.86 3.06 10.65 10.65 0 0 1-3.6-4.31l9.57-4.5V83.5Z" />
		<path fill="#F29C39" stroke="#47202A" strokeWidth="2" d="M23.58 49.57a8.32 8.32 0 0 0 8.26-8.36 8.31 8.31 0 0 0-8.26-8.37 8.32 8.32 0 0 0-8.27 8.37c0 4.6 3.69 8.36 8.27 8.36Z" />
		<path fill="#E94549" d="M23.58 33.84a7.19 7.19 0 0 1 6.37 3.83 7.45 7.45 0 0 1-3.1 10.11A7.18 7.18 0 0 1 17 44.31l6.59-3.1v-7.37Z" />
		<path fill="#F29C39" stroke="#47202A" strokeWidth="2" d="M64.58 105.57a8.32 8.32 0 0 0 8.26-8.36 8.31 8.31 0 0 0-8.26-8.37 8.32 8.32 0 0 0-8.27 8.37c0 4.6 3.69 8.36 8.27 8.36Z" />
		<path fill="#E94549" d="M64.58 89.84c1.92 0 3.77.78 5.13 2.16a7.42 7.42 0 0 1 0 10.42 7.21 7.21 0 0 1-5.13 2.15V89.84Z" />
		<path fill="#FFE5A2" stroke="#F4DFBF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M81.63 67.8a1.5 1.5 0 0 0 1.09-1.15c.6-2.79.09-9.06-6.16-13.07a18.26 18.26 0 0 0-2.91-1.43c-1-.44-1.89-.87-2.61-1.41a3.9 3.9 0 0 1-1.46-1.91c-.26-.79-.33-1.91.11-3.57a67.32 67.32 0 0 1 2.54-6.64c.4-.95.78-1.85 1.12-2.72.95-2.47 1.6-4.98.18-7.33a2.04 2.04 0 0 1-.15-.5 15.8 15.8 0 0 1-.21-1.21l-.07-.45c-.12-.91-.28-2.04-.56-3.23-.65-2.84-2-6.21-5.33-9a1.5 1.5 0 0 0-.67-.31C56.08 11.77 50 16 48.41 19.17c-.17.34-.3.79-.4 1.2-.12.44-.23.98-.34 1.58a90.29 90.29 0 0 0-.67 4.11c-.4 2.9-.75 6.03-.75 7.28 0 2.1.74 4.09 1.64 6 .38.83.81 1.68 1.25 2.53l.23.45a66.93 66.93 0 0 1 1.5 3.13c.03.05.1.26.12.66a7.54 7.54 0 0 1-.21 2.37c-.07.23-.12.32-.13.34-.56.56-1.06 1.12-1.5 1.61l-.2.2a15 15 0 0 1-1.38 1.44 4.53 4.53 0 0 1-2.93 1.27c-3.02.22-5.48 2.44-6.89 4.97-1.41 2.54-2.02 5.81-.89 8.6.17.42.54.75.98.87 11.59 3.28 19.14 4.2 25.56 3.8 5.24-.31 9.68-1.5 14.79-2.88 1.1-.3 2.25-.6 3.44-.9Z" />
		<path stroke="#47202A" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M60.72 70.34h-2.14c-2.84 0-12.03 0-20.67-3.83-1.53-9.18 8.04-12.25 11.49-14.16 2.75-1.53 2.93-5.74 2.68-7.66-2.15-1.83-2.68-5.6-2.68-7.27-.77-.38-1.53-.76-1.92-4.6-.3-3.05.64-4.33 1.15-4.58-.76-9.57 3.45-13.4 9.95-13.4h2.14" />
		<path stroke="#47202A" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M59.18 70.34h2.14c2.84 0 12.03 0 20.66-3.83 1.54-9.18-8.03-12.25-11.48-14.16-2.75-1.53-2.93-5.74-2.68-7.66 2.15-1.83 2.68-5.6 2.68-7.27.77-.38 1.53-.76 1.92-4.6.3-3.05-.64-4.33-1.15-4.58.76-9.57-3.45-13.4-9.95-13.4h-2.14" />
	</svg>,
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={121} height={122} viewBox="0 0 121 122">
		<mask id="howItWorksIcon3-a" width="121" height="122" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
			<ellipse cx="60.5" cy="60.84" fill="#F4DFBF" rx="60" ry="60.84" />
		</mask>
		<g mask="url(#howItWorksIcon3-a)">
			<path fill="#F4DFBF" stroke="#47202A" strokeWidth="2" d="M119.06 60.84c0 33.06-26.43 59.84-59 59.84s-59-26.78-59-59.84S27.5 1 60.06 1s59 26.78 59 59.84Z" />
			<rect width="71" height="50" x="21.5" y="42" fill="#FFE5A2" stroke="#47202A" strokeWidth="2" rx="5" />
			<rect width="25" height="18" x="72.5" y="60" fill="#F29C39" stroke="#47202A" strokeWidth="2" rx="3" />
			<rect width="8" height="8" x="77.5" y="65" fill="#E94549" stroke="#47202A" strokeWidth="2" rx="4" />
			<path stroke="#47202A" strokeLinecap="round" strokeWidth="2" d="M92.5 51h-64" />
			<mask id="howItWorksIcon3-b" width="66" height="40" x="30" y="10" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
				<path fill="#D9D9D9" d="M30.5 10h65v40h-65z" />
			</mask>
			<g mask="url(#howItWorksIcon3-b)">
				<mask id="howItWorksIcon3-c" width="74" height="84" x="6" y="25" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
					<path fill="#F29C39" d="m6.87 88.73 41.6-63.6L79.45 45.4 37.84 109z" />
				</mask>
				<g stroke="#47202A" strokeWidth="2" mask="url(#howItWorksIcon3-c)">
					<path fill="#F29C39" d="m8.25 88.44 40.51-61.92 29.3 19.16-40.52 61.92z" />
					<circle cx="47.96" cy="29.58" r="9" transform="rotate(-57 47.96 29.58)" />
					<circle cx="76.41" cy="48.19" r="9" transform="rotate(-57 76.41 48.2)" />
				</g>
				<rect width="18" height="6" x="47.89" y="53.43" stroke="#47202A" strokeWidth="2" rx="2" transform="rotate(-57 47.89 53.43)" />
				<mask id="howItWorksIcon3-d" width="74" height="84" x="22" y="13" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
					<path fill="#F29C39" d="M22.5 76.6 64.11 13l30.96 20.26-41.6 63.6z" />
				</mask>
				<g stroke="#47202A" strokeWidth="2" mask="url(#howItWorksIcon3-d)">
					<path fill="#F29C39" d="M23.88 76.31 64.4 14.4l29.29 19.16-40.52 61.93z" />
					<circle cx="58.37" cy="54.65" r="9" fill="#fff" transform="rotate(-57 58.37 54.65)" />
					<circle cx="63.59" cy="17.44" r="9" transform="rotate(-57 63.6 17.44)" />
					<circle cx="92.05" cy="36.06" r="9" transform="rotate(-57 92.05 36.06)" />
				</g>
				<rect width="18" height="6" x="63.52" y="41.3" fill="#fff" stroke="#47202A" strokeWidth="2" rx="2" transform="rotate(-57 63.52 41.3)" />
			</g>
		</g>
	</svg>,
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={121} height={122} viewBox="0 0 121 122">
		<mask id="mask0_4143_4492" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="121" height="122">
			<ellipse cx="60.25" cy="60.8392" rx="60" ry="60.8392" fill="#F4DFBF" />
		</mask>
		<g mask="url(#mask0_4143_4492)">
			<path d="M119.25 60.8392C119.25 93.9006 92.8218 120.678 60.25 120.678C27.6782 120.678 1.25 93.9006 1.25 60.8392C1.25 27.7778 27.6782 1 60.25 1C92.8218 1 119.25 27.7778 119.25 60.8392Z" fill="#F4DFBF" stroke="#47202A" strokeWidth="2" />
			<path d="M59.25 99.9998C81.3414 99.9998 99.25 82.0913 99.25 59.9999C99.25 37.9086 81.3414 20 59.25 20C37.1586 20 19.25 37.9086 19.25 59.9999C19.25 82.0913 37.1586 99.9998 59.25 99.9998Z" fill="#F29C39" stroke="#47202A" strokeWidth="2" />
			<path d="M59.25 21.0002C65.7021 21.0002 72.0535 22.6009 77.7349 25.6591C83.4162 28.7172 88.2501 33.1371 91.8033 38.5227C95.3565 43.9083 97.5181 50.0913 98.0942 56.5177C98.6703 62.944 97.6431 69.4129 95.1045 75.3447C92.5658 81.2764 88.5952 86.4856 83.5484 90.5055C78.5016 94.5255 72.5362 97.2304 66.187 98.3781C59.8377 99.5257 53.3029 99.0802 47.1682 97.0814C41.0335 95.0826 35.4905 91.593 31.036 86.9253L59.25 60.0001L59.25 21.0002Z" fill="#F16D20" />
			<path d="M59.25 21.0002C65.518 21.0002 71.6936 22.5109 77.2538 25.4044C82.8139 28.2979 87.5947 32.489 91.1912 37.6225C94.7877 42.756 97.0939 48.6807 97.9144 54.8948C98.7349 61.1088 98.0456 67.4291 95.9048 73.3201L59.25 60.0001L59.25 21.0002Z" fill="#E94549" />
			<path d="M59.25 21.0002C68.5179 21.0002 77.4828 24.3006 84.5383 30.31L59.25 60.0001L59.25 21.0002Z" fill="#FFE5A3" />
		</g>
		<circle cx="87.75" cy="83.5" r="19" fill="#F4DFBF" stroke="#F4DFBF" />
		<path d="M102.411 94.8776C101.377 93.9627 99.7973 94.0594 98.8824 95.0935C97.9676 96.1276 98.0643 97.7076 99.0984 98.6224L102.411 94.8776ZM99.0984 98.6224L103.787 102.77L107.1 99.025L102.411 94.8776L99.0984 98.6224Z" fill="#F4DFBF" />
		<path d="M100.755 96.75L107.693 102.644" stroke="#47202A" strokeWidth="3" strokeLinecap="round" />
		<path d="M108.469 103.185L112.264 106.316" stroke="#47202A" strokeWidth="5" strokeLinecap="round" />
		<mask id="mask1_4143_4492" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="69" y="65" width="38" height="37">
			<circle cx="87.75" cy="83.5" r="17.5" fill="#F29C39" stroke="#47202A" strokeWidth="2" />
		</mask>
		<g mask="url(#mask1_4143_4492)">
			<circle cx="87.75" cy="83.5" r="17.5" fill="#F4DFBF" stroke="#47202A" strokeWidth="2" />
			<path d="M105.62 78C105.62 77.724 105.609 77.46 105.585 77.184L107.785 75.492C108.258 75.132 108.388 74.46 108.092 73.932L105.881 70.056C105.585 69.528 104.946 69.312 104.402 69.552L101.859 70.644C101.422 70.332 100.961 70.056 100.476 69.828L100.133 67.056C100.062 66.456 99.5531 66 98.9617 66H94.5501C93.9469 66 93.4384 66.456 93.3674 67.056L93.0244 69.828C92.5395 70.056 92.0782 70.332 91.6406 70.644L89.0978 69.552C88.5537 69.312 87.915 69.528 87.6194 70.056L85.4077 73.944C85.112 74.472 85.2421 75.132 85.7152 75.504L87.915 77.196C87.8914 77.46 87.8796 77.724 87.8796 78C87.8796 78.276 87.8914 78.54 87.915 78.816L85.7152 80.508C85.2421 80.868 85.112 81.54 85.4077 82.068L87.6194 85.944C87.915 86.472 88.5537 86.688 89.0978 86.448L91.6406 85.356C92.0782 85.668 92.5395 85.944 93.0244 86.172L93.3674 88.944C93.4384 89.544 93.9469 90 94.5383 90H98.9499C99.5412 90 100.05 89.544 100.121 88.944L100.464 86.172C100.949 85.944 101.41 85.668 101.848 85.356L104.39 86.448C104.934 86.688 105.573 86.472 105.869 85.944L108.081 82.068C108.376 81.54 108.246 80.88 107.773 80.508L105.573 78.816C105.609 78.54 105.62 78.276 105.62 78ZM96.7973 82.2C94.5146 82.2 92.6578 80.316 92.6578 78C92.6578 75.684 94.5146 73.8 96.7973 73.8C99.08 73.8 100.937 75.684 100.937 78C100.937 80.316 99.08 82.2 96.7973 82.2Z" fill="#47202A" />
			<path d="M92.0905 92.4229C92.0303 92.2455 91.9651 92.0784 91.8896 91.9062L92.9518 90.3339C93.1811 89.9982 93.1193 89.5376 92.812 89.2634L90.5293 87.2596C90.222 86.9854 89.7596 86.9874 89.4581 87.2615L88.0421 88.5239C87.6896 88.4198 87.3295 88.344 86.9644 88.3044L86.1376 86.5983C85.9607 86.2283 85.5307 86.0473 85.1461 86.1776L82.2769 87.1499C81.8847 87.2829 81.6532 87.6881 81.7378 88.0894L82.1185 89.9466C81.8528 90.2 81.6129 90.4791 81.3963 90.7761L79.5046 90.6347C79.0985 90.6003 78.7302 90.8799 78.6529 91.2844L78.0614 94.2709C77.9841 94.6754 78.2125 95.0709 78.6012 95.2058L80.4004 95.8084C80.4426 95.9833 80.4924 96.1556 80.5525 96.333C80.6126 96.5104 80.6778 96.6775 80.7533 96.8497L79.6911 98.422C79.4619 98.7577 79.5236 99.2183 79.8309 99.4925L82.1136 101.496C82.4209 101.77 82.8833 101.769 83.1849 101.494L84.6008 100.232C84.9534 100.336 85.3135 100.412 85.6785 100.452L86.5054 102.158C86.6822 102.528 87.1123 102.709 87.4969 102.578L90.366 101.606C90.7506 101.476 90.982 101.07 90.8975 100.669L90.5168 98.8119C90.7825 98.5585 91.0223 98.2794 91.239 97.9824L93.1306 98.1239C93.5367 98.1582 93.905 97.8786 93.9823 97.4741L94.5765 94.4954C94.6538 94.0908 94.4254 93.6953 94.0367 93.5605L92.2374 92.9578C92.2004 92.7726 92.1506 92.6003 92.0905 92.4229ZM87.2671 97.067C85.7825 97.5701 84.1645 96.7685 83.66 95.2799C83.1556 93.7913 83.9529 92.1711 85.4374 91.668C86.922 91.1649 88.54 91.9666 89.0444 93.4552C89.5489 94.9438 88.7516 96.5639 87.2671 97.067Z" fill="#47202A" />
			<path d="M79.422 84.4521C79.4131 84.2809 79.3971 84.1176 79.3734 83.9472L80.6993 82.8258C80.9846 82.5871 81.0445 82.166 80.8419 81.8482L79.3286 79.5165C79.126 79.1987 78.7182 79.0856 78.3845 79.2522L76.8239 80.0126C76.5392 79.8334 76.2408 79.6773 75.9291 79.5518L75.6243 77.8437C75.5604 77.4739 75.2264 77.2077 74.8553 77.227L72.0867 77.3712C71.7081 77.391 71.4037 77.6904 71.3785 78.0649L71.2528 79.7954C70.9559 79.9526 70.6753 80.1389 70.4107 80.3467L68.7796 79.7526C68.4304 79.6215 68.0365 79.7764 67.868 80.1135L66.6056 82.5973C66.4371 82.9344 66.5401 83.3395 66.849 83.5548L68.2843 84.5323C68.278 84.6968 68.2791 84.861 68.288 85.0321C68.2969 85.2033 68.3129 85.3667 68.3366 85.5371L67.0107 86.6584C66.7254 86.8972 66.6655 87.3182 66.8681 87.6361L68.3814 89.9678C68.584 90.2856 68.9918 90.3987 69.3255 90.232L70.8861 89.4716C71.1708 89.6508 71.4692 89.8069 71.7809 89.9325L72.0857 91.6406C72.1496 92.0104 72.4836 92.2766 72.8547 92.2573L75.6233 92.113C75.9945 92.0937 76.2989 91.7942 76.3241 91.4198L76.4498 89.6893C76.7467 89.532 77.0273 89.3457 77.2918 89.1379L78.923 89.7321C79.2722 89.8631 79.666 89.7083 79.8345 89.3711L81.0973 86.8948C81.2659 86.5577 81.1629 86.1526 80.854 85.9373L79.4187 84.9598C79.432 84.7874 79.4309 84.6233 79.422 84.4521ZM74.0204 87.3456C72.5878 87.4202 71.3616 86.3124 71.2868 84.8759C71.2119 83.4395 72.3164 82.2102 73.749 82.1356C75.1816 82.061 76.4078 83.1688 76.4826 84.6052C76.5575 86.0417 75.453 87.2709 74.0204 87.3456Z" fill="#47202A" />
		</g>
	</svg>,
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={120} height={122} viewBox="0 0 120 122">
		<mask id="howItWorksIcon5-a" width="120" height="122" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
			<ellipse cx="60" cy="60.84" fill="#F4DFBF" rx="60" ry="60.84" />
		</mask>
		<g mask="url(#howItWorksIcon5-a)">
			<path fill="#F4DFBF" stroke="#47202A" strokeWidth="2" d="M119 60.84c0 33.06-26.43 59.84-59 59.84S1 93.9 1 60.84 27.43 1 60 1s59 26.78 59 59.84Z" />
			<path stroke="#47202A" strokeLinecap="round" strokeWidth="2" d="M16 61a44 44 0 1 0 11.88-30.07" />
			<path stroke="#47202A" strokeLinecap="round" strokeWidth="2" d="M26.2 21.97V32.6h10.64" />
			<mask id="howItWorksIcon5-b" width="46" height="54" x="35" y="34" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
				<path fill="#F29C39" d="m35 74.5 26.04-39.82 19.38 12.68-26.04 39.81z" />
			</mask>
			<g stroke="#47202A" strokeWidth="2" mask="url(#howItWorksIcon5-b)">
				<path fill="#F29C39" d="m36.38 74.2 24.95-38.13 17.7 11.59L54.1 85.79z" />
				<circle cx="57.45" cy="60.76" r="5.26" fill="#fff" transform="rotate(-57 57.45 60.76)" />
				<circle cx="37.08" cy="73.61" r="5.26" transform="rotate(-57 37.08 73.6)" />
				<circle cx="60.72" cy="37.46" r="5.26" transform="rotate(-57 60.72 37.46)" />
				<circle cx="54.89" cy="85.26" r="5.26" transform="rotate(-57 54.89 85.26)" />
				<circle cx="78.53" cy="49.11" r="5.26" transform="rotate(-57 78.53 49.11)" />
			</g>
			<rect width="10.52" height="3.01" x="45.43" y="76.38" fill="#fff" stroke="#47202A" strokeWidth="2" rx="1.5" transform="rotate(-57 45.43 76.38)" />
			<rect width="10.52" height="3.01" x="61.2" y="52.29" fill="#fff" stroke="#47202A" strokeWidth="2" rx="1.5" transform="rotate(-57 61.2 52.29)" />
			<mask id="howItWorksIcon5-c" width="46" height="53" x="43" y="34" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
				<path fill="#F29C39" d="m43 73.81 26.05-39.8 19.38 12.67L62.38 86.5z" />
			</mask>
			<g stroke="#47202A" strokeWidth="2" mask="url(#howItWorksIcon5-c)">
				<path fill="#F29C39" d="M44.38 73.52 69.33 35.4l17.7 11.59L62.1 85.1z" />
				<circle cx="65.45" cy="60.07" r="5.26" fill="#fff" transform="rotate(-57 65.45 60.07)" />
				<circle cx="45.08" cy="72.92" r="5.26" transform="rotate(-57 45.08 72.92)" />
				<circle cx="68.72" cy="36.78" r="5.26" transform="rotate(-57 68.72 36.78)" />
				<circle cx="62.89" cy="84.58" r="5.26" transform="rotate(-57 62.89 84.58)" />
				<circle cx="86.53" cy="48.43" r="5.26" transform="rotate(-57 86.53 48.43)" />
			</g>
			<rect width="10.52" height="3.01" x="53.43" y="75.7" fill="#fff" stroke="#47202A" strokeWidth="2" rx="1.5" transform="rotate(-57 53.43 75.7)" />
			<rect width="10.52" height="3.01" x="69.2" y="51.6" fill="#fff" stroke="#47202A" strokeWidth="2" rx="1.5" transform="rotate(-57 69.2 51.6)" />
			<path fill="#F68C82" stroke="#F4DFBF" strokeLinecap="round" strokeWidth="1.5" d="M54.7 66.89c-4.2-1.36-6.7-1.97-10.03-1.46-3.19.48-7.13 2-14.16 4.7l-.39.15a3.95 3.95 0 0 0-2.58 2.4 6.27 6.27 0 0 0-.14 3.28c.34 1.83 1.2 3.65 1.64 4.6l.19.41c.83 1.88 3.55 4.38 8.28 3.36a5.07 5.07 0 0 1 2.79.29c1.03.36 2.12.94 3.38 1.63l.46.25c2.39 1.33 5.26 2.92 8.88 3.68 7.68 1.63 14.14-.48 20.92-3.42a146.64 146.64 0 0 1 4.44-1.83c1.27-.52 2.36-.98 3.2-1.44a4.47 4.47 0 0 0 1.91-1.66c.4-.77.3-1.59-.11-2.4a4.64 4.64 0 0 0-2.33-1.98c-1-.45-2.22-.73-3.5-.85-2.56-.24-5.5.17-7.76 1.38-.9.48-2.1.71-3.48.74-1.38.02-2.88-.16-4.33-.45a24.54 24.54 0 0 1-6.33-2.07c-.29-.18-.4-.33-.43-.4l.03-.01c.14-.09.48-.2 1.09-.17.6.03 1.39.2 2.38.57 2.18.82 4.36 1.17 6.21 1.2 1.83.03 3.44-.25 4.42-.8a4.25 4.25 0 0 0 2.12-2.7 2.6 2.6 0 0 0-.34-1.9 3.99 3.99 0 0 0-1.84-1.47c-1.45-.64-2.92-.9-4.77-1.23l-.65-.12a64.42 64.42 0 0 1-9.16-2.28Z" />
		</g>
	</svg>,
];

export default function HowItWorksSteps({
	blogPostETFUrl,
}) {
	const [t] = useTranslation();

	return (
		<>
			{Object.keys(t('howItWorksHero.list', { returnObjects: true })).map((item) => (
				<div key={item} className={styles.column}>
					<div className={styles.icon}>
						{icons[+item] ?? null}
					</div>
					<div className={styles.itemWrap}>
						<span className={styles.number}>
							{+item + 1}
							.&nbsp;
							{t(`howItWorksHero.list.${item}.name`)}
						</span>
						<p className={styles.itemDescription}>
							<Trans i18nKey={`howItWorksHero.list.${item}.description`}>
								<strong>
									{blogPostETFUrl ? <Link to={blogPostETFUrl} target="_blank" /> : <></>}
								</strong>
							</Trans>
						</p>
					</div>
				</div>
			))}
		</>
	);
}

HowItWorksSteps.propTypes = {
	blogPostETFUrl: PropTypes.string,
};

HowItWorksSteps.defaultProps = {
	blogPostETFUrl: null,
};
