/* global sessionStorage */

export function checkSessionDate() {
	const timeCheck = sessionStorage.getItem('personalInformationWarning');
	return !(timeCheck && new Date().getTime() < +timeCheck + (10 * 60 * 1000)); // 10 minutes
}

export function saveSessionDate() {
	const date = new Date();
	sessionStorage.setItem('personalInformationWarning', date.getTime().toString());
}
