import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { getProfilePortfolioHistoryPDF } from '../../../utils/api';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import useFormatNumber from '../../../utils/useFormatNumber';
import { MultiSelectField } from '../../forms';
import {
	Col,
	Container,
	Row,
	Section,
} from '../../layout';
import { ButtonLink, Loader, Tooltip } from '../../ui';

import styles from './HistoryTransactions.module.scss';

const PDF_TYPE = 'transaction-history';

const toUpperFirst = (string) => `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

export default function HistoryTransactions({
	fromDate,
	toDate,
	currency,
	portfolioId,
	transactions,
	isProxy,
}) {
	const [limit, setLimit] = useState(10);
	const [open, setOpen] = useState(null);
	const [filter, setFilter] = useState([]);
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const trackGTMEvent = useTrackGTMEvent();
	const formatNumber = useFormatNumber();
	const formatCurrency = useFormatNumber({ style: 'currency', currency: currency?.code });

	const options = transactions?.map((transaction) => ({
		value: transaction?.category ?? transaction.name,
		label: transaction.category === 'trading' ? t('historyTransactions.table.rows.trading') : transaction.name,
	})).filter((ele, id, array) => id === array.findIndex((obj) => obj.value === ele.value)) ?? [];

	return (
		<Section bordered>
			<Container>
				<Row>
					<Col lg={4}>
						<h2 className={styles.title}>
							{t('historyTransactions.title')}
						</h2>
					</Col>
					{!isProxy && (
						<Col lg={8}>
							<div className={styles.button}>
								{portfolioId !== null && transactions && transactions.length > 0 && (
									<ButtonLink
										label={t('historyTransactions.downloadPdf')}
										onClick={(e) => {
											trackGTMEvent('interactions', {
												eventCategory: 'interactions',
												eventAction: 'buttonClick',
												eventLabel: 'historie_transakce_stahnout_pdf',
											});
											e.currentTarget.blur();
										}}
										to={getProfilePortfolioHistoryPDF(
											portfolioId,
											PDF_TYPE,
											country,
											language,
											fromDate,
											toDate,
										)}
										download
										outline
									/>
								)}
							</div>
						</Col>
					)}
				</Row>
				<div className={styles.text}>
					{t('historyTransactions.text')}
					<Tooltip
						text={t('historyTransactions.textTooltip')}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 512 512"
							width={15}
							className={styles.textIcon}
						>
							<path
								fill="currentColor"
								d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
							/>
						</svg>
					</Tooltip>
					<div className={styles.selectWrap}>
						<MultiSelectField
							id="transactionFilter"
							label="transactionFilter"
							isLabelHidden
							options={options}
							name="transactionFilter"
							noOptionsMessage={t('historyTransactions.filter.noOption')}
							placeholder={t('historyTransactions.filter.placeHolder')}
							value={filter}
							onChange={(_, value) => {
								setFilter(value);
								trackGTMEvent('interactions', {
									eventCategory: 'interactions',
									eventAction: 'formSend',
									eventLabel: 'filter_transactions',
								});
							}}
						/>
					</div>
				</div>
				{transactions && currency ? (
					<>
						{transactions && transactions.length > 0 ? (
							<>
								<div className={styles.tableWrap}>
									<table className={styles.table}>
										<thead>
											<tr>
												<th>
													{t('historyTransactions.table.columns.date')}
												</th>
												<th>
													{t('historyTransactions.table.columns.type')}
												</th>
												<th className={styles.right}>
													{t('historyTransactions.table.columns.change')}
												</th>
												<th>
													{' '}
												</th>
											</tr>
										</thead>
										<tbody>
											{(filter.length > 0
												? transactions.filter(
													(transaction) => filter.includes(
														transaction.category || transaction.name,
													),
												)
												: transactions).map((transaction, i) => {
												const key = `transaction${i}`;
												if (i >= limit) return null;
												return (
													<Fragment key={key}>
														<tr className={open === i ? styles.highlight : null}>
															<td>
																{new Date(transaction.date)
																	.toLocaleDateString(language)}
															</td>
															<td>
																{{
																	deposit: transaction.employer !== null
																		? t('historyTransactions.table.rows.employerDeposit')
																		: null,
																	trading: t('historyTransactions.table.rows.trading'),
																}[transaction.category] ?? transaction.name}
															</td>
															<td className={`${styles.right} ${transaction.chartVector === 'plus' || transaction.chartVector === 'minus' ? styles[transaction.chartVector] : null}`.trim()}>
																{formatCurrency(transaction.volume)}
															</td>
															<td className={styles.right}>
																{(
																	(transaction.category === 'deposit' && transaction.employer !== null)
																	|| transaction.category === 'trading'
																	|| (transaction.category === 'dividend' && transaction.product)
																	|| (transaction.category === 'fx' && transaction.fx)
																	|| transaction.category === 'transferEtfOut'
																	|| transaction.category === 'transferCashOut'
																	|| transaction.category === 'transferEtfIn'
																	|| transaction.category === 'transferCashIn'
																) && (
																	<button
																		type="button"
																		className={styles.collapseTrigger}
																		onClick={
																			() => setOpen(open === i ? null : i)
																		}
																	>
																		{t(open === i ? 'historyTransactions.table.rows.hide' : 'historyTransactions.table.rows.more')}
																	</button>
																)}
															</td>
														</tr>
														{transaction.category === 'deposit' && transaction.employer !== null && open === i && (
															<tr>
																<td className={styles.hideMobile}>
																	{' '}
																</td>
																<td className={`${styles.cellWrap} ${styles.hideMobile}`}>
																	{t('historyTransactions.table.rows.fromEmployer')}
																	{' '}
																	{transaction.employer.businessId}
																	{' '}
																	{transaction.employer.title}
																</td>
																<td className={`${styles.cellWrap} ${styles.hideDesktop}`} colSpan={2}>
																	{t('historyTransactions.table.rows.fromEmployer')}
																	{' '}
																	{transaction.employer.businessId}
																	{' '}
																	{transaction.employer.title}
																</td>
																<td />
																<td />
															</tr>
														)}
														{transaction.category === 'trading'
															&& open === i
															&& transaction.children.map((child, index) => {
																const childKey = `child${index}`;
																const name = (
																	<>
																		{child.name}
																		{' '}
																		{formatNumber(child.amount)}
																		{' '}
																		{child.product?.name}
																		{', '}
																		{child.product?.isin}
																		{' '}
																		{t('historyTransactions.table.rows.for')}
																		{' '}
																		{formatNumber(child.price)}
																		{' '}
																		{child.product?.currencyCode}
																	</>
																);
																return (
																	<tr key={childKey}>
																		<td className={styles.hideMobile}>
																			{' '}
																		</td>
																		<td className={`${styles.cellWrap} ${styles.hideMobile}`}>
																			{name}
																		</td>
																		<td className={`${styles.cellWrap} ${styles.hideDesktop}`} colSpan={2}>
																			{name}
																		</td>
																		<td className={styles.right}>
																			{formatCurrency(child.volume)}
																		</td>
																		<td />
																	</tr>
																);
															})}
														{transaction.category === 'dividend' && transaction.product && open === i && (
															<tr>
																<td className={styles.hideMobile}>
																	{' '}
																</td>
																<td className={`${styles.cellWrap} ${styles.hideMobile}`}>
																	{transaction.product.name}
																	{', '}
																	{transaction.product.isin}
																</td>
																<td className={`${styles.cellWrap} ${styles.hideDesktop}`} colSpan={2}>
																	{transaction.product.name}
																	{', '}
																	{transaction.product.isin}
																</td>
																<td />
																<td />
															</tr>
														)}
														{transaction.category === 'fx' && transaction.fx && open === i && (
															<tr>
																<td className={styles.hideMobile}>
																	{' '}
																</td>
																<td className={`${styles.cellWrap} ${styles.hideMobile}`}>
																	{t('historyTransactions.table.rows.rate')}
																	{' '}
																	{formatNumber(transaction.fx.quantity ?? 1)}
																	{' '}
																	{transaction.fx.currencyCode}
																	{' / '}
																	{formatNumber(transaction.fx.rate, {
																		fractionDigits: 4,
																	})}
																	{' '}
																	{transaction.fx.currencyFromCode}
																</td>
																<td className={`${styles.cellWrap} ${styles.hideDesktop}`} colSpan={2}>
																	{t('historyTransactions.table.rows.rate')}
																	{' '}
																	{formatNumber(transaction.fx.quantity ?? 1)}
																	{' '}
																	{transaction.fx.currencyCode}
																	{' / '}
																	{formatNumber(transaction.fx.rate, {
																		fractionDigits: 4,
																	})}
																	{' '}
																	{transaction.fx.currencyFromCode}
																</td>
																<td />
																<td />
															</tr>
														)}
														{transaction.category === 'transferEtfOut'
															&& open === i
															&& transaction.children.map((child, index) => {
																const childKey = `child${index}`;
																const name = (
																	<>
																		{formatNumber(child.amount)}
																		{' '}
																		{child.product?.name}
																		{', '}
																		{child.product?.isin}
																		{' '}
																		{t('historyTransactions.table.rows.toPortfolio')}
																		{' '}
																		{child.portfolioTo}
																	</>
																);
																return (
																	<tr key={childKey}>
																		<td className={styles.hideMobile}>
																			{' '}
																		</td>
																		<td className={`${styles.cellWrap} ${styles.hideMobile}`}>
																			{name}
																		</td>
																		<td className={`${styles.cellWrap} ${styles.hideDesktop}`} colSpan={2}>
																			{name}
																		</td>
																		<td className={styles.right}>
																			{formatCurrency(child.volume)}
																		</td>
																		<td />
																	</tr>
																);
															})}
														{transaction.category === 'transferCashOut' && open === i && (
															<tr>
																<td className={styles.hideMobile}>
																	{' '}
																</td>
																<td className={`${styles.cellWrap} ${styles.hideMobile}`}>
																	{toUpperFirst(t('historyTransactions.table.rows.toPortfolio'))}
																	{' '}
																	{transaction.portfolioTo}
																</td>
																<td className={`${styles.cellWrap} ${styles.hideDesktop}`} colSpan={2}>
																	{toUpperFirst(t('historyTransactions.table.rows.toPortfolio'))}
																	{' '}
																	{transaction.portfolioTo}
																</td>
																<td className={styles.right}>
																	{formatCurrency(transaction.volume)}
																</td>
																<td />
															</tr>
														)}
														{transaction.category === 'transferEtfIn'
															&& open === i
															&& transaction.children.map((child, index) => {
																const keyChildren = `child${index}`;
																const name = (
																	<>
																		{formatNumber(child.amount)}
																		{' '}
																		{child.product?.name}
																		{', '}
																		{child.product?.isin}
																		{' '}
																		{t('historyTransactions.table.rows.fromPortfolio')}
																		{' '}
																		{child.portfolioFrom}
																	</>
																);
																return (
																	<tr key={keyChildren}>
																		<td className={styles.hideMobile}>
																			{' '}
																		</td>
																		<td className={`${styles.cellWrap} ${styles.hideMobile}`}>
																			{name}
																		</td>
																		<td className={`${styles.cellWrap} ${styles.hideDesktop}`} colSpan={2}>
																			{name}
																		</td>
																		<td className={styles.right}>
																			{formatCurrency(child.volume)}
																		</td>
																		<td />
																	</tr>
																);
															})}
														{transaction.category === 'transferCashIn' && open === i && (
															<tr>
																<td className={styles.hideMobile}>
																	{' '}
																</td>
																<td className={`${styles.cellWrap} ${styles.hideMobile}`}>
																	{toUpperFirst(t('historyTransactions.table.rows.fromPortfolio'))}
																	{' '}
																	{transaction.portfolioFrom}
																</td>
																<td className={`${styles.cellWrap} ${styles.hideDesktop}`} colSpan={2}>
																	{toUpperFirst(t('historyTransactions.table.rows.fromPortfolio'))}
																	{' '}
																	{transaction.portfolioFrom}
																</td>
																<td className={styles.right}>
																	{formatCurrency(transaction.volume)}
																</td>
																<td />
															</tr>
														)}
													</Fragment>
												);
											})}
										</tbody>
									</table>
								</div>
								{limit < transactions.length && (
									<button
										type="button"
										className={styles.loadMore}
										onClick={() => setLimit((prevState) => prevState + 10)}
									>
										{t('historyTransactions.table.loadMore')}
									</button>
								)}
							</>
						) : (
							<div className={styles.noResult}>
								{t('historyTransactions.noResult')}
							</div>
						)}
					</>
				) : (
					<Loader />
				)}
			</Container>
		</Section>
	);
}

HistoryTransactions.propTypes = {
	fromDate: PropTypes.instanceOf(Date),
	toDate: PropTypes.instanceOf(Date),
	currency: PropTypes.shape({
		code: PropTypes.string.isRequired,
		codeShort: PropTypes.string.isRequired,
		codeShortBefore: PropTypes.bool.isRequired,
	}),
	portfolioId: PropTypes.number,
	transactions: PropTypes.arrayOf(
		PropTypes.shape({
			category: PropTypes.string,
			chartVector: PropTypes.string,
			children: PropTypes.arrayOf(
				PropTypes.shape({
					amount: PropTypes.number,
					name: PropTypes.string,
					portfolioFrom: PropTypes.string,
					portfolioTo: PropTypes.string,
					price: PropTypes.number,
					product: PropTypes.shape({
						name: PropTypes.string,
						isin: PropTypes.string,
						currencyCode: PropTypes.string,
					}),
					volume: PropTypes.number,
				}),
			),
			date: PropTypes.string,
			employer: PropTypes.shape({
				businessId: PropTypes.string,
				title: PropTypes.string,
			}),
			fx: PropTypes.shape({
				currencyCode: PropTypes.string,
				currencyFromCode: PropTypes.string,
				quantity: PropTypes.number,
				rate: PropTypes.number.isRequired,
			}),
			name: PropTypes.string,
			portfolioFrom: PropTypes.string,
			portfolioTo: PropTypes.string,
			product: PropTypes.shape({
				name: PropTypes.string,
				currencyCode: PropTypes.string,
			}),
			volume: PropTypes.number,
		}),
	),
	isProxy: PropTypes.bool,
};

HistoryTransactions.defaultProps = {
	fromDate: null,
	toDate: null,
	currency: null,
	portfolioId: null,
	transactions: null,
	isProxy: false,
};
