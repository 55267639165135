import React from 'react';
import { useTranslation } from 'react-i18next';

import {
	Container,
	Section,
} from '../../layout';

import styles from './ContactHero.module.scss';

export default function ContactHero() {
	const [t] = useTranslation();

	return (
		<Section condensed>
			<Container center>
				<div className={styles.root}>
					<h1 className={styles.title}>{t('contact.title')}</h1>
					<p className={styles.text}>
						{t('contact.text')}
					</p>
				</div>
			</Container>
		</Section>
	);
}
