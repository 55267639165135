import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
	Col,
	Container,
	Row,
	Section,
} from '../../layout';
import { Mark, SectionAnchor } from '../../ui';

import styles from './FeesHero.module.scss';

export default function FeesHero({
	productsAnchor,
}) {
	const [t] = useTranslation();

	return (
		<Section bordered>
			<Container>
				<div className={styles.root}>
					<h1 className={styles.title}>
						{t('feesHero.title')}
					</h1>
					<p className={styles.description}>
						{t('feesHero.description')}
					</p>
				</div>
				<Row>
					{Object.keys(t('feesHero.benefitsList', { returnObjects: true })).map((item) => (
						<Col key={item} sm={6} lg={3}>
							<Mark isDirectionRow>
								<span className={styles.benefitsTitle}>
									{t(`feesHero.benefitsList.${item}`)}
								</span>
							</Mark>
						</Col>
					))}
				</Row>
			</Container>
			<SectionAnchor title={t('feesHero.anchor')} target={productsAnchor} />
		</Section>
	);
}

FeesHero.propTypes = {
	productsAnchor: PropTypes.string.isRequired,
};
