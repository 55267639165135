import React from 'react';
import PropTypes from 'prop-types';

import useFormatNumber from '../../../../utils/useFormatNumber';

import styles from './PortfolioTableGrowth.module.scss';

const getGrowth = (value) => {
	if (value > 0) {
		return [styles.netGrowthUp, '+\u00a0'];
	}
	if (value < 0) {
		return [styles.netGrowthDown, '-\u00a0'];
	}
	return ['', '', 1];
};

export default function PortfolioTableGrowth({
	currency,
	ratio,
	value,
}) {
	const formatOptions = ratio
		? { style: 'percent', fractionDigits: 1 }
		: { style: 'currency', currency: currency?.code, fractionDigits: 0 };
	const formatNumber = useFormatNumber(formatOptions);

	const [netGrowthClass, sign] = getGrowth(value);

	return (
		<div className={netGrowthClass}>
			<span className={styles.netGrowthValue}>
				{sign}
				{formatNumber(value < 0 ? -value : value)}
			</span>
		</div>
	);
}

PortfolioTableGrowth.propTypes = {
	currency: PropTypes.shape({
		code: PropTypes.string.isRequired,
		codeShort: PropTypes.string.isRequired,
		codeShortBefore: PropTypes.bool.isRequired,
	}),
	ratio: PropTypes.bool,
	value: PropTypes.number,
};

PortfolioTableGrowth.defaultProps = {
	currency: null,
	ratio: false,
	value: null,
};
