import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
	FeesChart,
	FeesComparison,
	FeesHero,
	FeesProducts,
	FooterCta,
} from '../../components/common';

export default function Fees({
	blogPostETFUrl,
	registrationUrl,
}) {
	const [t] = useTranslation();
	const chartAnchor = t('anchors.chart');
	const comparisonAnchor = t('anchors.comparison');
	const productsAnchor = t('anchors.products');

	return (
		<>
			<FeesHero blogPostETFUrl={blogPostETFUrl} productsAnchor={productsAnchor} />
			<FeesProducts
				comparisonAnchor={comparisonAnchor}
				productsAnchor={productsAnchor}
				registrationUrl={registrationUrl}
			/>
			<FeesComparison comparisonAnchor={comparisonAnchor} chartAnchor={chartAnchor} />
			<FeesChart chartAnchor={chartAnchor} />
			<FooterCta registrationUrl={registrationUrl} />
		</>
	);
}

Fees.propTypes = {
	blogPostETFUrl: PropTypes.string,
	registrationUrl: PropTypes.string.isRequired,
};

Fees.defaultProps = {
	blogPostETFUrl: null,
};
