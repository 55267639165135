import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './BlogPostShare.module.scss';

export default function BlogPostShare({
	link,
}) {
	const [t] = useTranslation();
	const linkEncoded = encodeURIComponent(link);

	const socials = {
		fb: {
			icon: <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47"><path fill="#0080ff" d="M46.2 23.1a23.1 23.1 0 1 0-23 23.1h.3v-18h-5v-5.8h5v-4.2c0-5 3-7.6 7.4-7.6l4.5.2v5.1h-3c-2.4 0-3 1.2-3 2.9v3.6h5.8l-.7 5.8h-5v17.1a23.1 23.1 0 0 0 16.7-22.2z" /></svg>,
			label: 'Facebook',
			link: `https://www.facebook.com/sharer.php?u=${linkEncoded}`,
		},
		twitter: {
			icon: <svg xmlns="http://www.w3.org/2000/svg" width="48" height="47"><path fill="#41a1f2" d="M24 .1a23.1 23.1 0 1 0 0 46.2A23.1 23.1 0 0 0 24 .1m9.2 18.4v.6a13.4 13.4 0 0 1-20.8 11.4h1.2a9.5 9.5 0 0 0 5.9-2 4.7 4.7 0 0 1-4.5-3.3 4.8 4.8 0 0 0 2.2 0 4.7 4.7 0 0 1-3.8-4.7c.6.3 1.3.5 2.1.5a4.7 4.7 0 0 1-1.4-6.3 13.5 13.5 0 0 0 9.7 5 4.7 4.7 0 0 1 8.1-4.4 9.5 9.5 0 0 0 3-1.1 4.8 4.8 0 0 1-2 2.6 9.5 9.5 0 0 0 2.7-.8 9.7 9.7 0 0 1-2.4 2.5" /></svg>,
			label: 'Twitter',
			link: `https://twitter.com/intent/tweet?url=${linkEncoded}`,
		},
		linkedin: {
			icon: <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47"><path fill="#0e76a8" d="M36.7 35H31v-9.1c0-2.3-.9-3.8-2.9-3.8-1.5 0-2.5 1-2.9 2l-.2 1.4v9.4h-5.6V18h5.6v2.4c.8-1.1 2.1-2.8 5.1-2.8 3.7 0 6.5 2.4 6.5 7.6zM13.5 15.6c-1.8 0-3-1.3-3-3 0-1.6 1.2-2.9 3.1-2.9 2 0 3.1 1.3 3.2 3 0 1.6-1.3 2.9-3.2 2.9zm2.9 19.2h-5.6V18h5.6zM23 0a23.1 23.1 0 1 0 0 46.2 23.1 23.1 0 0 0 0-46.2z" /></svg>,
			label: 'LinkedIn',
			link: `https://www.linkedin.com/sharing/share-offsite/?url=${linkEncoded}`,
		},
	};

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<h3 className={styles.title}>
					{t('blogPost.share.title')}
				</h3>
				<p className={styles.description}>
					{t('blogPost.share.description')}
				</p>
			</div>
			<ul className={styles.list}>
				{Object.keys(socials).map((item) => (
					<li key={item}>
						<a
							className={styles.link}
							href={socials[item].link}
							rel="noopener noreferrer"
							target="_blank"
						>
							<span className={styles.icon}>
								{socials[item].icon}
							</span>
							<span className={styles.label}>
								{t('blogPost.share.button')}
								{socials[item].label}
							</span>
						</a>
					</li>
				))}
			</ul>
		</div>
	);
}

BlogPostShare.propTypes = {
	link: PropTypes.string.isRequired,
};
