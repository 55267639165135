import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Trans, useTranslation } from 'react-i18next';

import { useCountry } from '../../../contexts/LocaleContext';
import isScreenWidthUp from '../../../utils/isScreenWidthUp';
import useFormatNumber from '../../../utils/useFormatNumber';
import useWindowSize from '../../../utils/useWindowSize';
import {
	Col,
	Container,
	Row,
	Section,
} from '../../layout';
import { ExternalLink } from '../../ui';

import styles from './FeesChart.module.scss';

const dataFondee = {
	CZ: [
		100000, 106100, 112600, 119500, 126800, 134500, 142800, 151500, 160800, 170600,
		181000, 192100, 203900, 216300, 229500, 243600,
	],
	PL: [
		10000, 10610, 11260, 11950, 12680, 13450, 14280, 15150, 16080, 17060,
		18100, 19210, 20390, 21630, 22950, 24360,
	],
	SK: [
		5000, 5310, 5630, 5980, 6340, 6730, 7140, 7580, 8040, 8530,
		9050, 9610, 10190, 10820, 11480, 12190,
	],
};

const dataManaged = {
	CZ: [
		100000, 104400, 109000, 113700, 118700, 123900, 129400, 135000, 141000, 147200,
		153600, 160300, 167400, 174700, 182400, 190400,
	],
	PL: [
		10000, 10440, 10900, 11370, 11870, 12390, 12940, 13500, 14100, 14720,
		15360, 16030, 16740, 17470, 18240, 19040,
	],
	SK: [
		5000, 5220, 5450, 5680, 5930, 6200, 6470, 6750, 7050, 7360,
		7680, 8020, 8370, 8740, 9120, 9520,
	],
};

const dataMin = { CZ: 100000, PL: 10000, SK: 5000 };
const dataMax = { CZ: 240000, PL: 24000, SK: 12000 };

const chartOptions = (formatTooltip, formatX, formatY, min, max) => ({
	chart: {
		toolbar: {
			show: false,
		},
		zoom: {
			enabled: false,
		},
	},
	colors: ['#e94549', '#f39200'],
	dataLabels: {
		enabled: false,
	},
	fontFamily: 'Rajdhani, sans-serif',
	legend: {
		horizontalAlign: 'left',
		fontSize: '17px',
		formatter(seriesName) {
			return seriesName;
		},
		itemMargin: {
			horizontal: 15,
			vertical: 8,
		},
	},
	stroke: {
		curve: 'straight',
		width: 3,
	},
	tooltip: {
		shared: false,
		custom({ series, seriesIndex, dataPointIndex }) {
			return (
				`<div class="${styles.tooltip} ${seriesIndex === 0 ? styles.tooltipRed : styles.tooltipOrange}">
					<b>
						${formatTooltip(series[seriesIndex][dataPointIndex])}
					</b>
				</div>`
			);
		},
		x: {
			show: false,
		},
		marker: {
			show: false,
		},
	},
	xaxis: {
		type: 'numeric',
		tickAmount: 5,
		min: 0,
		max: 15,
		labels: {
			style: {
				fontSize: '14px',
			},
			formatter: formatX,
		},
		tooltip: {
			enabled: false,
		},
	},
	yaxis: {
		tickAmount: 7,
		min,
		max,
		labels: {
			style: {
				fontSize: '14px',
			},
			formatter: formatY,
		},
	},
});

export default function FeesChart({
	chartAnchor,
}) {
	const [t] = useTranslation();
	const country = useCountry();
	const formatNumber = useFormatNumber({ fractionDigits: 0 });
	const windowSize = useWindowSize();

	const formatX = (value) => t('feesChart.chart.years', { count: value });
	const formatY = (value) => `${formatNumber(value / 1000)}${t('feesChart.chart.currencyThousandsSuffix')}`;

	const min = dataMin[country] ?? dataMin.CZ;
	const max = dataMax[country] ?? dataMax.CZ;

	const formatTooltip = min < 10000 ? (value) => `${formatNumber(value)}${t('feesChart.chart.currencySuffix')}` : formatY;

	const series = [{
		name: 'Fondee',
		data: (dataFondee[country] ?? dataFondee.CZ).map((y, x) => ({ x, y })),
	}, {
		name: t('feesChart.chart.curveLabel'),
		data: (dataManaged[country] ?? dataManaged.CZ).map((y, x) => ({ x, y })),
	}];

	return (
		<Section id={chartAnchor} bordered>
			<Container>
				<h2 className={styles.title}>
					<Trans i18nKey="feesChart.title">
						<br />
					</Trans>
				</h2>
				<Row center middle>
					<Col lg={6}>
						<div className={styles.wrap}>
							<Chart
								height={500}
								options={chartOptions(formatTooltip, formatX, formatY, min, max)}
								series={series}
								type="line"
							/>
						</div>
					</Col>
					{isScreenWidthUp(windowSize.width, 'lg') && (
						<Col lg={1} />
					)}
					<Col lg={3}>
						<h3 className={styles.subtitle}>
							{t('feesChart.subtitle')}
						</h3>
						<Trans i18nKey="feesChart.info">
							<p className={styles.paragraph}>
								<strong />
								<ExternalLink
									className={styles.link}
									to={t('documentUrls.feesIllustratedUrl')}
									blank
								/>
							</p>
						</Trans>
					</Col>
				</Row>
			</Container>
		</Section>
	);
}

FeesChart.propTypes = {
	chartAnchor: PropTypes.string.isRequired,
};
