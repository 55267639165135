/* global window */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useChangeLanguage, useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { usePage } from '../../../contexts/PageContext';
import { useUserContext } from '../../../contexts/UserContext';
import { pageOrRootUrl } from '../../../utils/routes';
import { useCountrySwitchUrl, useLanguageSwitchUrl } from '../../../utils/useSwitchUrl';
import { Loader } from '../../ui';

import styles from './UserCheck.module.scss';

export const clientZonePages = {
	account: true,
	clientCheck: true,
	dashboard: true,
	faq: true,
	history: true,
	offers: true,
	onboarding: true,
	onboardingChild: true,
	onboardingParent: true,
	portfolio: true,
	referrals: true,
	taxes: true,
	signAgreement: true,
	signNewAgreement: true,
};

export const legacyZonePages = {
	account: true,
	signAgreement: true,
};

export const prospectZonePages = {
	faq: true,
	offers: true,
	onboarding: true,
	referrals: true,
};

export default function UserCheck({
	baseUrls,
	children,
}) {
	const country = useCountry();
	const language = useLanguage();
	const changeLanguage = useChangeLanguage();
	const page = usePage();
	const [user, loading] = useUserContext();
	const countrySwitchUrl = useCountrySwitchUrl(baseUrls);
	const languageSwitchUrl = useLanguageSwitchUrl();
	const location = useLocation();
	const navigate = useNavigate();

	const clientZone = page !== null && (clientZonePages[page] ?? false);
	const legacyZone = page !== null && (legacyZonePages[page] ?? false);
	const prospectZone = page !== null && (prospectZonePages[page] ?? false);

	const countrySwitch = clientZone && user !== null && country !== user.country_id
		? countrySwitchUrl(user.country_id)
		: null;
	const languageSwitch = user !== null && language !== user.language_id
		? languageSwitchUrl(user.language_id)
		: null;

	useEffect(() => {
		if (countrySwitch !== null) {
			window.location.href = countrySwitch;
		} else if (languageSwitch !== null && changeLanguage(user?.language_id, false)) {
			navigate(languageSwitch);
		}
	}, [countrySwitch, languageSwitch, navigate, user?.language_id]);

	if (!clientZone) {
		return children;
	}

	if (loading || countrySwitch !== null) {
		return (
			<div className={styles.root}>
				<Loader />
			</div>
		);
	}

	const navigateUrl = (pageTo) => pageOrRootUrl(country, language, pageTo);

	if (user === null) {
		return <Navigate to={navigateUrl('login')} state={{ from: location }} />;
	}

	if (!prospectZone && !user.email_verified) {
		return <Navigate to={navigateUrl('emailVerification')} />;
	}

	if (!prospectZone && !user.active_client) {
		return <Navigate to={navigateUrl('onboarding')} />;
	}

	if ((!legacyZone || (page !== 'signAgreement' && user.client_zone_locked)) && user.legacy_client) {
		return <Navigate to={navigateUrl('signAgreement')} />;
	}

	if (page !== 'signNewAgreement' && !user.legacy_client && user.contract_missing) {
		const contractMissing = user.documents?.contractMissing ?? {};
		const category = Object.keys(contractMissing).find((key) => contractMissing[key]) ?? null;
		const url = `${navigateUrl('signNewAgreement')}${category !== null ? `?category=${category}` : ''}`;
		return <Navigate to={url} />;
	}

	if (page !== 'clientCheck' && !user.legacy_client && !user.contract_missing && user.client_zone_locked) {
		return <Navigate to={navigateUrl('clientCheck')} />;
	}

	return children;
}

UserCheck.propTypes = {
	baseUrls: PropTypes.objectOf(PropTypes.string.isRequired),
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

UserCheck.defaultProps = {
	baseUrls: null,
	children: null,
};
