import React from 'react';
import PropTypes from 'prop-types';

import styles from './Steps.module.scss';

export default function Steps({
	disabled,
	disabledPositions,
	position,
	positionList,
	setPosition,
}) {
	const rawPositionIndex = positionList.indexOf(position);
	const positionIndex = rawPositionIndex >= 0 ? rawPositionIndex : positionList.length;

	return (
		<ul className={styles.root}>
			{positionList.map((pos, index) => (
				<li key={pos} className={styles.item}>
					<button
						type="button"
						onClick={() => setPosition(pos)}
						disabled={index >= positionIndex || disabled || disabledPositions.includes(pos)}
						className={`${styles.button} ${pos === position ? styles.buttonActive : ''}`.trim()}
					>
						{index + 1}
					</button>
				</li>
			))}
		</ul>
	);
}

Steps.propTypes = {
	disabled: PropTypes.bool,
	disabledPositions: PropTypes.arrayOf(PropTypes.number),
	position: PropTypes.number.isRequired,
	positionList: PropTypes.arrayOf(PropTypes.number).isRequired,
	setPosition: PropTypes.func.isRequired,
};

Steps.defaultProps = {
	disabled: false,
	disabledPositions: [],
};
