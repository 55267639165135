import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { OnChangeSubmit, SelectField } from '../../../components/forms';
import { Alert, Badge } from '../../../components/ui';
import { useCountry } from '../../../contexts/LocaleContext';
import { filterChild, useUserContext } from '../../../contexts/UserContext';
import { updateDepositDefaultPortfolio } from '../../../utils/api';
import useRequestAuth from '../../../utils/useRequestAuth';

import styles from './DepositDefaultPortfolio.module.scss';

export default function DepositDefaultPortfolio({
	portfolios,
	reloadUser,
}) {
	const updateDepositDefaultPortfolioAuth = useRequestAuth(updateDepositDefaultPortfolio);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [t] = useTranslation();
	const country = useCountry();
	const [user] = useUserContext();

	if (portfolios === null) {
		return null;
	}

	const depositDefaultPortfolio = user !== null && user.deposit_default_portfolio_id !== null
		? String(user.deposit_default_portfolio_id)
		: null;

	const portfolioOptions = portfolios.filter(({ blocked }) => !blocked).map((portfolio) => ({
		label: (
			<>
				<img
					src={`${process.env.PUBLIC_URL}/images/faces/${portfolio.faceImageFile}`}
					alt={portfolio.name}
					width={40}
					height={40}
				/>
				{filterChild(portfolio.child, user) && (
					<span className={styles.badgeWrap}>
						<Badge label={portfolio.child.firstName} size="tiny" variant="orange" />
					</span>
				)}
				{portfolio.categoryName === 'pension' && (
					<span className={styles.badgeWrap}>
						<Badge label={t('productSelect.pension')} size="tiny" variant="wine" />
						{country === 'CZ' && <Badge label="DIP" size="tiny" variant="wine" />}
					</span>
				)}
				<span>{portfolio.name}</span>
			</>
		),
		value: String(portfolio.id),
	}));

	return (
		<div className={styles.root}>
			<h2 className={styles.title}>{t('account.depositDefaultPortfolio.title')}</h2>
			<h3 className={styles.subtitle}>{t('account.depositDefaultPortfolio.subtitle')}</h3>
			<Formik
				initialValues={{ portfolio: depositDefaultPortfolio }}
				onSubmit={async (values) => {
					setError(false);
					setSuccess(false);
					try {
						await updateDepositDefaultPortfolioAuth(values.portfolio);
					} catch {
						setError(true);
						return;
					}
					setSuccess(true);
					reloadUser();
				}}
			>
				{({
					handleSubmit, isSubmitting, setFieldValue, values,
				}) => (
					<form onSubmit={handleSubmit}>
						<OnChangeSubmit />
						{error && (
							<Alert type="danger">
								{t('forms.error')}
							</Alert>
						)}
						<SelectField
							disabled={isSubmitting}
							id="depositDefaultPortfolio"
							label={t('account.depositDefaultPortfolio.label')}
							name="portfolio"
							noOptionsMessage={t('account.depositDefaultPortfolio.noOptionsMessage')}
							onChange={setFieldValue}
							options={portfolioOptions}
							value={values.portfolio}
						/>
						{success && (
							<Alert
								autoClose
								close
								onClose={() => setSuccess(false)}
								type="success"
							>
								{t('forms.success')}
							</Alert>
						)}
					</form>
				)}
			</Formik>
		</div>
	);
}

DepositDefaultPortfolio.propTypes = {
	portfolios: PropTypes.arrayOf(PropTypes.shape({
		child: PropTypes.shape({
			firstName: PropTypes.string.isRequired,
			id: PropTypes.number.isRequired,
		}),
		faceImageFile: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
		name: PropTypes.string,
	}).isRequired),
	reloadUser: PropTypes.func.isRequired,
};

DepositDefaultPortfolio.defaultProps = {
	portfolios: null,
};
