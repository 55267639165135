import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { OnChangeSubmit, RadioList } from '../../../components/forms';
import { Alert } from '../../../components/ui';
import { updateUserCurrency } from '../../../utils/api';
import useRequestAuth from '../../../utils/useRequestAuth';

import styles from './CurrencySelect.module.scss';

export default function CurrencySelect({
	currencies,
	reloadUser,
}) {
	const updateUserCurrencyAuth = useRequestAuth(updateUserCurrency);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [t] = useTranslation();

	if (currencies === null || currencies.length <= 1) return null;

	const currencyOptions = currencies.map(({ code }) => ({
		label: code,
		value: code,
	}));

	return (
		<div className={styles.root}>
			<h2 className={styles.title}>{t('account.currencySelect.title')}</h2>
			<h3 className={styles.subtitle}>{t('account.currencySelect.subtitle')}</h3>
			<Formik
				initialValues={{ currency: currencies.find(({ selected }) => selected)?.code ?? null }}
				onSubmit={async (values) => {
					setError(false);
					setSuccess(false);
					try {
						await updateUserCurrencyAuth(values.currency);
					} catch {
						setError(true);
						return;
					}
					reloadUser();
					setSuccess(true);
				}}
			>
				{({
					handleChange, handleSubmit, isSubmitting, values,
				}) => (
					<form onSubmit={handleSubmit}>
						<OnChangeSubmit />
						{error && (
							<Alert type="danger">
								{t('forms.error')}
							</Alert>
						)}
						<RadioList
							name="currency"
							id="portfolioCurrency"
							disabled={isSubmitting}
							onChange={handleChange}
							options={currencyOptions}
							value={values.currency}
						/>
						{success && (
							<Alert
								autoClose
								close
								type="success"
								onClose={() => setSuccess(false)}
							>
								{t('forms.success')}
							</Alert>
						)}
					</form>
				)}
			</Formik>
		</div>
	);
}

CurrencySelect.propTypes = {
	currencies: PropTypes.arrayOf(PropTypes.shape({
		code: PropTypes.string.isRequired,
		codeShort: PropTypes.string.isRequired,
		codeShortBefore: PropTypes.bool.isRequired,
	}).isRequired),
	reloadUser: PropTypes.func.isRequired,
};

CurrencySelect.defaultProps = {
	currencies: null,
};
