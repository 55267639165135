import React from 'react';
import PropTypes from 'prop-types';

import {
	ShimmerImage,
	ShimmerPill,
	ShimmerRect,
	ShimmerText,
	ShimmerTitle,
} from '../../../components/ui/Shimmer';

import styles from './BlogPostPreview.module.scss';

export default function BlogPostPreviewSkeleton({
	forcedMobile,
}) {
	return (
		<article className={(`${styles.wrap} ${forcedMobile ? styles.forcedMobile : ''}`).trim()}>
			<div className={(`${styles.header} ${forcedMobile ? styles.forcedMobile : ''}`).trim()}>
				<div className={(`${styles.imageWrap} ${forcedMobile ? styles.forcedMobile : ''}`).trim()}>
					<ShimmerImage height={forcedMobile ? 90 : 290} heightMobile={90} />
				</div>
				<div className={(`${styles.info} ${forcedMobile ? styles.forcedMobile : ''}`).trim()}>
					<div className={(`${styles.date} ${forcedMobile ? styles.forcedMobile : ''}`).trim()}>
						<ShimmerRect width={77} height={26} />
					</div>
					<div className={(`${styles.title} ${forcedMobile ? styles.forcedMobile : ''}`).trim()}>
						<ShimmerTitle lines={2} gap={1} />
					</div>
				</div>
			</div>

			<div className={(`${styles.content} ${forcedMobile ? styles.forcedMobile : ''}`).trim()}>
				<div className={(`${styles.text} ${forcedMobile ? styles.forcedMobile : ''}`).trim()}>
					<ShimmerText lines={5} gap={2} />
				</div>
				<div className={(`${styles.badgeWrapMobile} ${forcedMobile ? styles.forcedMobile : ''}`).trim()}>
					<ShimmerPill width={110} height={22} />
				</div>
			</div>
		</article>
	);
}

BlogPostPreviewSkeleton.propTypes = {
	forcedMobile: PropTypes.bool,
};

BlogPostPreviewSkeleton.defaultProps = {
	forcedMobile: false,
};
