/* global document */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { TextField } from '../../../components/forms';
import {
	Container, Section,
} from '../../../components/layout';
import { Button } from '../../../components/ui';
import useFormatNumber from '../../../utils/useFormatNumber';
import { validateSavingsCalculator } from '../../../utils/validators';

import styles from './CampaignCalculator.module.scss';

const initialValues = {
	accountInterestRate: 1,
	deposit: 100000,
	fondeeInterestRate: 8,
	inflationRate: 3.5,
	years: 5,
};

function countSavings(deposit, inflationRate, interestRate, years) {
	let result = '–';

	// TODO: better conversion?
	if (deposit && inflationRate && (interestRate || interestRate === 0) && years) {
		result = Math.round(+deposit * (1 + ((+interestRate - +inflationRate) / 100)) ** +years);
	}

	return result;
}

function countSavingsDifference(deposit, finalValue) {
	let result = '–';

	// TODO: better conversion?
	if (deposit && finalValue) {
		result = Math.round(+finalValue - +deposit);
	}

	return result;
}

const initialAccountSavings = countSavings(
	initialValues.deposit,
	initialValues.inflationRate,
	initialValues.accountInterestRate,
	initialValues.years,
);
const initialFondeeSavings = countSavings(
	initialValues.deposit,
	initialValues.inflationRate,
	initialValues.fondeeInterestRate,
	initialValues.years,
);
const initialSavingsDifferenceAccount = countSavingsDifference(
	initialValues.deposit,
	initialAccountSavings,
);
const initialSavingsDifferenceFondee = countSavingsDifference(
	initialValues.deposit,
	initialFondeeSavings,
);

export default function CampaignCalculator({
	isTypeFondee,
	setTypeFondee,
}) {
	const [t] = useTranslation();
	const formatCurrency = useFormatNumber({ style: 'currency', currency: 'CZK' });

	const [accountSavings, setAccountSavings] = useState(initialAccountSavings);
	const [fondeeSavings, setFondeeSavings] = useState(initialFondeeSavings);
	const result = isTypeFondee ? fondeeSavings : accountSavings;
	const [savingsDifferenceFondee, setSavingsDifferenceFondee] = useState(initialSavingsDifferenceFondee);
	const [savingsDifferenceAccount, setSavingsDifferenceAccount] = useState(initialSavingsDifferenceAccount);

	return (
		<Section variant="orange-lighter" condensed>
			<Container center>
				<h2 className={styles.title}>
					{t('campaign.calculator.title')}
				</h2>
				<p className={styles.paragraph}>
					{t('campaign.calculator.subtitle')}
				</p>
				<Formik
					initialValues={initialValues}
					validate={(values) => validateSavingsCalculator(values, isTypeFondee)}
					onSubmit={async (values) => {
						const newFondeeSavings = countSavings(
							values.deposit,
							values.inflationRate,
							values.fondeeInterestRate,
							values.years,
						);
						const newAccountSavings = countSavings(
							values.deposit,
							values.inflationRate,
							values.accountInterestRate,
							values.years,
						);

						setAccountSavings(newAccountSavings);
						setFondeeSavings(newFondeeSavings);
						setSavingsDifferenceAccount(
							countSavingsDifference(
								values.deposit,
								newAccountSavings,
							),
						);
						setSavingsDifferenceFondee(
							countSavingsDifference(
								values.deposit,
								newFondeeSavings,
							),
						);
					}}
				>
					{({
						errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values,
					}) => (
						<>
							<form
								className={styles.form}
								onSubmit={handleSubmit}
							>
								<div className={styles.toggle}>
									<label
										className={`${styles.toggleItem} ${isTypeFondee ? '' : styles.toggleItemActive}`.trim()}
										htmlFor="type-account"
									>
										<input
											checked={!isTypeFondee}
											className={styles.toggleItemInput}
											name="type"
											onChange={(e) => {
												if (e.currentTarget.checked) {
													setTypeFondee(false);
												}
											}}
											type="radio"
											value="type-account"
										/>
										{t('campaign.calculator.toggle.account')}
									</label>
									<label
										className={`${styles.toggleItem} ${isTypeFondee ? styles.toggleItemActive : ''}`.trim()}
										htmlFor="type-fondee"
									>
										<input
											checked={isTypeFondee}
											className={styles.toggleItemInput}
											name="type"
											onChange={(e) => {
												if (e.currentTarget.checked) {
													setTypeFondee(true);
												}
											}}
											type="radio"
											value="type-fondee"
										/>
										{t('campaign.calculator.toggle.fondee')}
									</label>
									<div
										className={`${styles.toggleActive} ${isTypeFondee ? styles.toggleActiveFondee : ''}`.trim()}
									/>
								</div>
								<div className={styles.inputWrap}>
									<p className={styles.inputLabel}>
										{t('campaign.calculator.fields.deposit.label')}
									</p>
									<TextField
										error={
											errors.deposit
											&& touched.deposit
											&& t(errors.deposit)
										}
										id="deposit"
										label={t('campaign.calculator.fields.deposit.label')}
										min={1000}
										max={10000000}
										name="deposit"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										type="number"
										unit="Kč"
										value={values.deposit}
										isFlat
										isLabelHidden
									/>
								</div>
								<div className={styles.inputWrap}>
									<p className={styles.inputLabel}>
										{t('campaign.calculator.fields.inflationRate.label')}
									</p>
									<TextField
										id="inflationRate"
										error={
											errors.inflationRate
											&& touched.inflationRate
											&& t(errors.inflationRate)
										}
										label={t('campaign.calculator.fields.inflationRate.label')}
										min={1}
										max={15}
										name="inflationRate"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										step={0.01}
										type="number"
										unit="%"
										value={values.inflationRate}
										isFlat
										isLabelHidden
									/>
								</div>
								<div className={styles.inputWrap}>
									{isTypeFondee ? (
										<>
											<p className={styles.inputLabel}>
												{t('campaign.calculator.fields.interestRate.fondee.label')}
											</p>
											<TextField
												error={
													errors.fondeeInterestRate
													&& touched.fondeeInterestRate
													&& t(errors.fondeeInterestRate)
												}
												id="fondeeInterestRate"
												label={t('campaign.calculator.fields.interestRate.fondee.label')}
												name="fondeeInterestRate"
												onBlur={handleBlur}
												onChange={handleChange}
												required
												type="number"
												unit="%"
												value={values.fondeeInterestRate}
												isFlat
												isLabelHidden
												readonly
											/>
										</>
									) : (
										<>
											<p className={styles.inputLabel}>
												{t('campaign.calculator.fields.interestRate.account.label')}
											</p>
											<TextField
												error={
													errors.accountInterestRate
													&& touched.accountInterestRate
													&& t(errors.accountInterestRate)
												}
												id="accountInterestRate"
												label={t('campaign.calculator.fields.interestRate.account.label')}
												min={0}
												max={10}
												name="accountInterestRate"
												onBlur={handleBlur}
												onChange={handleChange}
												required
												step={0.01}
												type="number"
												unit="%"
												value={values.accountInterestRate}
												isFlat
												isLabelHidden
											/>
										</>
									)}
								</div>
								<div className={styles.inputWrap}>
									<p className={styles.inputLabel}>
										{t(`campaign.calculator.fields.years.${isTypeFondee ? 'fondee' : 'account'}.label`)}
									</p>
									<TextField
										onBlur={handleBlur}
										onChange={handleChange}
										error={
											errors.years
											&& touched.years
											&& t(errors.years)
										}
										id="years"
										min={1}
										max={50}
										name="years"
										label={t(`campaign.calculator.fields.years.${isTypeFondee ? 'fondee' : 'account'}.label`)}
										required
										type="number"
										value={values.years}
										isFlat
										isLabelHidden
									/>
								</div>
								<Button
									label={t('campaign.calculator.button')}
									disabled={isSubmitting}
									isSubmit
								/>
							</form>
							<div className={styles.resultBox}>
								<p className={styles.paragraph}>
									{t('campaign.calculator.result.info')}
									<span className={styles.result}>
										{t('campaign.calculator.result.value', { value: formatCurrency(result) })}
									</span>
								</p>
								{isTypeFondee ? (
									<>
										{savingsDifferenceFondee > 0 && (
											<p className={styles.paragraph}>
												<Trans i18nKey="campaign.calculator.result.fondee">
													<strong />
													{{ value: formatCurrency(savingsDifferenceFondee) }}
												</Trans>
											</p>
										)}
										{savingsDifferenceAccount < 0 && (
											<p className={styles.paragraph}>
												<Trans i18nKey="campaign.calculator.result.fondeeComparation">
													<strong />
													{{ value: formatCurrency(-savingsDifferenceAccount) }}
												</Trans>
											</p>
										)}
									</>
								) : (
									<>
										{savingsDifferenceAccount < 0 && (
											<p className={styles.paragraph}>
												<Trans i18nKey="campaign.calculator.result.account">
													<strong />
													{{ value: formatCurrency(-savingsDifferenceAccount) }}
												</Trans>
											</p>
										)}
										{savingsDifferenceFondee > 0 && (
											<p className={styles.paragraph}>
												<Trans i18nKey="campaign.calculator.result.accountComparation">
													<strong className={styles.textOrange} />
													<strong />
													{{ value: formatCurrency(savingsDifferenceFondee) }}
												</Trans>
											</p>
										)}
									</>
								)}
								<button
									className={styles.link}
									onClick={() => {
										document
											.getElementById('jak-to-ze-na-sporaku-o-sve-penize-prichazite')
											.scrollIntoView({
												behavior: 'smooth',
											});
									}}
									type="button"
								>
									{t('campaign.calculator.how')}
								</button>
							</div>
						</>
					)}
				</Formik>
			</Container>
		</Section>
	);
}

CampaignCalculator.propTypes = {
	isTypeFondee: PropTypes.bool,
	setTypeFondee: PropTypes.func.isRequired,
};

CampaignCalculator.defaultProps = {
	isTypeFondee: false,
};
