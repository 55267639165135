/* global document */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function useAnchorLoader(elementId) {
	const location = useLocation();
	const [scrolled, setScrolled] = useState(false);
	const scroll = location.hash === `#${elementId}` && !scrolled;

	useEffect(() => {
		if (!scroll) {
			return;
		}

		const element = document.getElementById(elementId);
		if (element) {
			element.scrollIntoView({
				behavior: 'smooth',
			});
			setScrolled(true);
		}
	}, [elementId, scroll]);

	return scrolled;
}
