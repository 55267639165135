import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLanguage } from '../../../contexts/LocaleContext';
import { fetchSentRecommendation } from '../../../utils/api';
import useFetchAuth from '../../../utils/useFetchAuth';

import styles from './EmailStats.module.scss';

const useFetchSentRecommendation = useFetchAuth(fetchSentRecommendation);

export default function EmailStats() {
	const language = useLanguage();
	const [sentRecommendation, loading, error] = useFetchSentRecommendation(null);
	const [t] = useTranslation();

	if (loading || error || !sentRecommendation || sentRecommendation.length === 0) {
		return null;
	}

	return (
		<div className={styles.root}>
			<div className={styles.title}>
				{t('referrals.emailStats.title')}
			</div>
			<table className={styles.table}>
				<tbody>
					{sentRecommendation.map((item, i) => {
						const key = `recipient${i}`;
						return (
							<tr key={key}>
								<td>
									{item.recipient}
								</td>
								<td>
									{new Date(item.createdAt).toLocaleDateString(language)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}
