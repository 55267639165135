import React from 'react';
import PropTypes from 'prop-types';

import Container from '../Container';
import Section from '../Section';

import styles from './FormLayoutFull.module.scss';

export default function FormLayoutFull({
	children,
	title,
	text,
}) {
	return (
		<Section>
			<Container center>
				{title && (<h1 className={`${styles.title} ${!text ? styles.titleOffset : ''}`.trim()}>{title}</h1>)}
				{text && (<p className={styles.text}>{text}</p>)}
				{children}
			</Container>
		</Section>
	);
}

FormLayoutFull.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	title: PropTypes.string,
	text: PropTypes.string,
};

FormLayoutFull.defaultProps = {
	title: '',
	text: '',
};
