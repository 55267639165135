import { useLocation } from 'react-router-dom';

import { useCountry, useLanguage } from '../contexts/LocaleContext';
import { usePage, useSubpages } from '../contexts/PageContext';
import { changeCountryUrl, pageOrRootUrl, pageUrl } from './routes';

export function useCountrySwitchUrl(baseUrls = null) {
	const page = usePage();

	return (country, language = null, isSwitch = true) => changeCountryUrl(baseUrls, country, language, page, isSwitch);
}

export function useLanguageSwitchUrl() {
	const { pathname } = useLocation();
	const country = useCountry();
	const currentLanguage = useLanguage();
	const page = usePage();
	const subpages = useSubpages(currentLanguage);

	const currentPageUrl = pageUrl(country, currentLanguage, page);
	const prefix = currentPageUrl !== null ? `${currentPageUrl}/` : '';
	const slug = prefix !== null && pathname.startsWith(prefix) ? pathname.substring(prefix.length) : null;
	const subpage = slug !== null ? subpages[slug] ?? null : null;

	return (language) => (
		subpage !== null
			? pageOrRootUrl(country, language, page, language === currentLanguage ? slug : subpage[language] ?? null)
			: pageOrRootUrl(country, language, page)
	);
}
