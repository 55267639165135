import React from 'react';

import {
	ShimmerPill,
	ShimmerRect,
} from '../../../../components/ui/Shimmer';
import { BlogPostPreviewSkeleton } from '../../BlogPostPreview';

import styles from './BlogMenu.module.scss';

export default function BlogMenu() {
	return (
		<div className={styles.wrap}>
			<div className={styles.root}>
				<div className={styles.content}>
					<ShimmerPill width={110} height={22} gap={2} />
					<ShimmerPill width={75} height={14} />
					<ShimmerRect height={53} gap={2} />

					<ShimmerPill width={120} height={14} gap={2} />

					<ShimmerPill width={110} height={30} gap={2} />

					<ShimmerPill width={120} height={14} />

					<BlogPostPreviewSkeleton forcedMobile />
					<BlogPostPreviewSkeleton forcedMobile />
					<BlogPostPreviewSkeleton forcedMobile />
				</div>
			</div>
		</div>
	);
}
