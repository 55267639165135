import {
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';

const noop = () => {};

export default function useFetch(fetcher) {
	function useFetcher(initialData, ...args) {
		const [data, setData] = useState(initialData);
		const [loading, setLoading] = useState(true);
		const [error, setError] = useState(null);

		const initialRef = useRef(true);
		const cancelRef = useRef(noop);

		useEffect(() => {
			let cancelled = false;
			if (initialRef.current) {
				initialRef.current = false;
			} else {
				setLoading(true);
				setError(null);
				setData(initialData);
			}

			fetcher(...args).then((fetchedData) => {
				if (!cancelled) {
					setData(fetchedData);
				}
			}, (fetchError) => {
				if (!cancelled) {
					setError(fetchError);
				}
			}).finally(() => {
				if (!cancelled) {
					setLoading(false);
				}
			});

			const cancelFn = () => {
				cancelled = true;
			};

			cancelRef.current = cancelFn;
			return cancelFn;
		}, [fetcher, ...args]);

		const cancel = useCallback(() => {
			(cancelRef.current)();
		}, []);

		return [data, loading, error, cancel];
	}

	return useFetcher;
}
