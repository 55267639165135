import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
	activateEmployer,
	fetchEmployers,
} from '../../../utils/api';
import useFetchWithReload from '../../../utils/useFetchWithReload';
import useRequestAuth from '../../../utils/useRequestAuth';
import { CheckboxField } from '../../forms';
import { Col } from '../../layout';
import { Alert, Tooltip, TopUpModal } from '../../ui';
import EmployerCreate from '../EmployerCreate';

import styles from './EmployerList.module.scss';

const useFetchEmployers = useFetchWithReload(fetchEmployers);

function EmployerListItem({
	employer,
	onActiveChange,
}) {
	const [submitting, setSubmitting] = useState(false);

	const handleActiveChange = async (e) => {
		setSubmitting(true);
		await onActiveChange(employer.id, e.target.checked);
		setSubmitting(false);
	};

	return (
		<li className={styles.item}>
			<p className={styles.title}>
				{employer.title}
			</p>
			<CheckboxField
				checked={employer.active}
				disabled={submitting}
				id={`employerActivation-${employer.id}`}
				label=""
				name="employerActivation"
				noError
				noLabel
				onChange={handleActiveChange}
			/>
		</li>
	);
}

EmployerListItem.propTypes = {
	employer: PropTypes.shape({
		active: PropTypes.bool.isRequired,
		id: PropTypes.number.isRequired,
		title: PropTypes.string.isRequired,
	}).isRequired,
	onActiveChange: PropTypes.func.isRequired,
};

export default function EmployerList({
	portfolios,
}) {
	const [t] = useTranslation();
	const [error, setError] = useState(false);
	const [employers, loading, errorEmployers, , reloadEmployers] = useFetchEmployers(null);
	const activateEmployerAuth = useRequestAuth(activateEmployer);

	if (loading || errorEmployers || portfolios === null) {
		return null;
	}

	const isDisabled = employers.length === 5;
	const pensionPortfolios = portfolios.filter((portfolio) => portfolio.categoryName === 'pension');

	const handleActivateEmployer = async (employerId, active) => {
		setError(false);

		try {
			await activateEmployerAuth(
				employerId,
				active,
			);
		} catch (e) {
			setError(true);
		} finally {
			reloadEmployers();
		}
	};

	return (
		<Col xl={6} lg={8}>
			<p className={styles.info}>
				{t('employer.list.info')}
			</p>
			<div className={styles.buttons}>
				<EmployerCreate
					isDisabled={isDisabled}
					reloadEmployers={reloadEmployers}
				/>
				<TopUpModal
					portfolios={pensionPortfolios}
					employer
				/>
			</div>
			<p className={styles.tooltip}>
				<span>{t('employer.list.tooltip.title')}</span>
				<Tooltip
					text={t('employer.list.tooltip.text')}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 512 512"
						width={15}
					>
						<path
							fill="currentColor"
							d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
						/>
					</svg>
				</Tooltip>
			</p>
			{error && (
				<Alert type="danger">
					{t('forms.error')}
				</Alert>
			)}
			<ul>
				{employers.map((employer) => (
					<EmployerListItem
						key={employer.id}
						employer={employer}
						onActiveChange={handleActivateEmployer}
					/>
				))}
			</ul>
		</Col>
	);
}

EmployerList.propTypes = {
	portfolios: PropTypes.arrayOf(PropTypes.shape({
		faceImageFile: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
		name: PropTypes.string,
	}).isRequired),
};

EmployerList.defaultProps = {
	portfolios: null,
};
