import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from '../../ui';

import styles from './FindOutInfoBox.module.scss';

export default function FindOutInfoBox({
	onApprove,
	onDismiss,
}) {
	const [t] = useTranslation();
	return (
		<div role="presentation" className={styles.root}>
			<button type="button" onClick={onDismiss} className={styles.close}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" width={10}>
					<path
						fill="currentColor"
						d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
					/>
				</svg>
			</button>
			<div className={styles.text}>
				{t('findOutInfoBox.text')}
			</div>
			<div className={styles.button}>
				<Button
					onClick={onApprove}
					isNarrow
					label={t('findOutInfoBox.button')}
				/>
			</div>
		</div>
	);
}

FindOutInfoBox.propTypes = {
	onApprove: PropTypes.func.isRequired,
	onDismiss: PropTypes.func.isRequired,
};
