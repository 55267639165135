import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useInRouterContext } from 'react-router-dom';

import { Container, Section } from '../../components/layout';
import { ExternalLink } from '../../components/ui';

import styles from './Error404.module.scss';

export default function Error404({ homepageUrl }) {
	const [t] = useTranslation();
	const inRouter = useInRouterContext();
	const LinkComponent = inRouter ? Link : ExternalLink;

	return (
		<Section>
			<Container center>
				<h1 className={styles.title}>{t('error404.title')}</h1>
				<h2 className={styles.subtitle}>{t('error404.subtitle')}</h2>
				<LinkComponent to={homepageUrl}>{t('error404.link')}</LinkComponent>
			</Container>
		</Section>
	);
}

Error404.propTypes = {
	homepageUrl: PropTypes.string.isRequired,
};
