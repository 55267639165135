import React from 'react';
import PropTypes from 'prop-types';

import { FooterCta, LoginForm } from '../../components/common';
import { useLogoutMessage } from '../../contexts/UserContext';

import styles from './Login.module.scss';

export default function Login({
	dashboardUrl,
	forgotPasswordUrl,
	registrationUrl,
}) {
	const [logoutMessage] = useLogoutMessage();

	return (
		<>
			{logoutMessage && (
				<div className={styles.message}>
					{logoutMessage}
				</div>
			)}
			<LoginForm
				dashboardUrl={dashboardUrl}
				forgotPasswordUrl={forgotPasswordUrl}
			/>
			<FooterCta registrationUrl={registrationUrl} />
		</>
	);
}

Login.propTypes = {
	dashboardUrl: PropTypes.string.isRequired,
	forgotPasswordUrl: PropTypes.string.isRequired,
	registrationUrl: PropTypes.string.isRequired,
};
