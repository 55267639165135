import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
	Modal,
} from '../../ui';

import { useCountry } from '../../../contexts/LocaleContext';
import getCookieValue from '../../../utils/getCookieValue';
import setCookieValue from '../../../utils/setCookieValue';
import { useCountrySwitchUrl } from '../../../utils/useSwitchUrl';

import styles from './VisitorCountryCheck.module.scss';

const CHECK_COOKIE_NAME = 'fondee_country_check';

const icons = {
	CZ:
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<mask id="pl-circle-flag-mask">
			<circle cx="256" cy="256" r="256" fill="#fff" />
		</mask>
		<g mask="url(#pl-circle-flag-mask)">
			<path fill="#eee" d="M0 0h512v256l-265 45.2z" />
			<path fill="#d80027" d="M210 256h302v256H0z" />
			<path fill="#0052b4" d="M0 0v512l256-256L0 0z" />
		</g>
	</svg>,
	PL:
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<mask id="pl-circle-flag-mask">
			<circle cx="256" cy="256" r="256" fill="#fff" />
		</mask>
		<g mask="url(#pl-circle-flag-mask)">
			<path fill="#d80027" d="m0 256 256.4-44.3L512 256v256H0z" />
			<path fill="#eee" d="M0 0h512v256H0z" />
		</g>
	</svg>,
	SK:
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<mask id="sk-circle-flag-mask">
			<circle cx="256" cy="256" r="256" fill="#fff" />
		</mask>
		<g mask="url(#sk-circle-flag-mask)">
			<path fill="#0052b4" d="m0 160 256-32 256 32v192l-256 32L0 352z" />
			<path fill="#eee" d="M0 0h512v160H0z" />
			<path fill="#d80027" d="M0 352h512v160H0z" />
			<path fill="#eee" d="M64 63v217c0 104 144 137 144 137s144-33 144-137V63z" />
			<path fill="#d80027" d="M96 95v185a83 78 0 0 0 9 34h206a83 77 0 0 0 9-34V95z" />
			<path fill="#eee" d="M288 224h-64v-32h32v-32h-32v-32h-32v32h-32v32h32v32h-64v32h64v32h32v-32h64z" />
			<path fill="#0052b4" d="M152 359a247 231 0 0 0 56 24c12-3 34-11 56-24a123 115 0 0 0 47-45 60 56 0 0 0-34-10l-14 2a60 56 0 0 0-110 0 60 56 0 0 0-14-2c-12 0-24 4-34 10a123 115 0 0 0 47 45z" />
		</g>
	</svg>,
	EN:
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<mask id="gb-circle-flag-mask">
			<circle cx="256" cy="256" r="256" fill="#fff" />
		</mask>
		<g mask="url(#gb-circle-flag-mask)">
			<path fill="#eee" d="m0 0 8 22-8 23v23l32 54-32 54v32l32 48-32 48v32l32 54-32 54v68l22-8 23 8h23l54-32 54 32h32l48-32 48 32h32l54-32 54 32h68l-8-22 8-23v-23l-32-54 32-54v-32l-32-48 32-48v-32l-32-54 32-54V0l-22 8-23-8h-23l-54 32-54-32h-32l-48 32-48-32h-32l-54 32L68 0H0z" />
			<path fill="#0052b4" d="M336 0v108L444 0Zm176 68L404 176h108zM0 176h108L0 68ZM68 0l108 108V0Zm108 512V404L68 512ZM0 444l108-108H0Zm512-108H404l108 108Zm-68 176L336 404v108z" />
			<path fill="#d80027" d="M0 0v45l131 131h45L0 0zm208 0v208H0v96h208v208h96V304h208v-96H304V0h-96zm259 0L336 131v45L512 0h-45zM176 336 0 512h45l131-131v-45zm160 0 176 176v-45L381 336h-45z" />
		</g>
	</svg>,
};

export default function VisitorCountryCheck({
	baseUrls,
	country,
}) {
	const [open, setOpen] = useState(false);
	const [t] = useTranslation();
	const currentCountry = useCountry();
	const countrySwitchUrl = useCountrySwitchUrl(baseUrls);
	const switchUrl = country !== currentCountry ? countrySwitchUrl(country) : null;
	const isSwitchUrl = switchUrl !== null;

	useEffect(() => {
		if (isSwitchUrl && getCookieValue(CHECK_COOKIE_NAME) === null) {
			setOpen(true);
		}
	}, [isSwitchUrl]);

	if (!isSwitchUrl) {
		return null;
	}

	const handleClose = () => {
		setOpen(false);
		setCookieValue(CHECK_COOKIE_NAME, 30, false);
	};

	return (
		<Modal
			isVisible={open}
			onClose={() => handleClose()}
		>
			<div className={styles.modal}>
				<h3 className={styles.title}>
					{t(`countryCheck.modal.${country}.title`)}
				</h3>

				<p className={styles.text}>
					{t(`countryCheck.modal.${country}.text`)}
				</p>

				<div className={styles.modalControl}>
					<div className={styles.modalControlItem}>
						 <button className={styles.btn} type="button" onClick={() => handleClose()}>
							{icons[currentCountry]}
							<p className={styles.btnText}>{t(`countryCheck.modal.${country}.stay`)}</p>
						 </button>
					</div>
					<div className={styles.modalControlItem}>
						<a className={styles.btn} href={switchUrl}>
							{icons[country]}
							<p className={styles.btnText}>
								{t(`countryCheck.modal.${country}.redirect`)}
							</p>
						</a>
					</div>
				</div>
			</div>
		</Modal>
	);
}

VisitorCountryCheck.propTypes = {
	baseUrls: PropTypes.objectOf(PropTypes.string.isRequired),
	country: PropTypes.string.isRequired,
};

VisitorCountryCheck.defaultProps = {
	baseUrls: null,
};
