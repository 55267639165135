import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import BlogPostPreview from '../../BlogPostPreview';
import blogPostShape from '../../blogPostShape';

import styles from './BlogTopRead.module.scss';

export default function BlogTopRead({
	blogPostUrl,
	closeMenu,
	handleTagClick,
	searchTopRead,
	setSearchTopRead,
	topReadPosts,
}) {
	const [t] = useTranslation();

	if (!topReadPosts) {
		return null;
	}

	return (
		<div className={styles.wrap}>
			<div className={styles.title}>
				{t('blog.menu.topRead.title')}
			</div>
			<div className={styles.list}>
				{topReadPosts.slice(0, 3).map((post) => (
					<BlogPostPreview
						key={post.id}
						blogPostUrl={blogPostUrl}
						forcedMobile
						handleTagClick={handleTagClick}
						post={post}
					/>
				))}
			</div>
			<div className={styles.buttonWrap}>
				<button
					type="button"
					className={`${styles.button} ${searchTopRead ? styles.selected : ''}`.trim()}
					onClick={() => {
						setSearchTopRead(!searchTopRead);
						closeMenu();
					}}
				>
					{t('blog.menu.topRead.button')}
				</button>
			</div>
		</div>
	);
}

BlogTopRead.propTypes = {
	blogPostUrl: PropTypes.func.isRequired,
	closeMenu: PropTypes.func.isRequired,
	handleTagClick: PropTypes.func.isRequired,
	searchTopRead: PropTypes.bool.isRequired,
	setSearchTopRead: PropTypes.func.isRequired,
	topReadPosts: PropTypes.arrayOf(blogPostShape.isRequired),
};

BlogTopRead.defaultProps = {
	topReadPosts: null,
};
