import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { HowItWorksSteps, SocialLogin } from '../../components/common';
import {
	CheckboxField,
	PhoneField,
	TextField,
} from '../../components/forms';
import { Col, FormLayoutFull, Row } from '../../components/layout';
import {
	Alert,
	Button,
	ButtonLink,
	ExternalLink,
	Modal,
} from '../../components/ui';
import { useCountry, useLanguage } from '../../contexts/LocaleContext';
import { useUserLogin } from '../../contexts/UserContext';
import {
	fetchAppleUserData,
	fetchFacebookUserData,
	fetchGoogleUserData,
	fetchLinkedinUserData,
	fetchValidateFirstName,
	fetchValidateLastName,
	register,
} from '../../utils/api';
import { useTrackGTMEvent } from '../../utils/GTMProvider';
import useFetch from '../../utils/useFetch';
import { validatePhoneWarning, validateRegistration } from '../../utils/validators';

import styles from './Registration.module.scss';

const trueInPL = (fetcher) => (country, name) => (country === 'PL' ? Promise.resolve(true) : fetcher(country, name));
const useFetchValidateFirstName = useFetch(trueInPL(fetchValidateFirstName));
const useFetchValidateLastName = useFetch(trueInPL(fetchValidateLastName));

const SOCIAL_LOGINS = ['apple', 'facebook', 'google', 'linkedin'];

export default function Registration({
	affiliateCode,
	affiliateSource,
	blogPostETFUrl,
	conditionsUrl,
	emailAliasUrl,
	emailVerificationUrl,
	personalInfoAgreementUrl,
	referral,
	social,
}) {
	const country = useCountry();
	const language = useLanguage();
	const [t] = useTranslation();
	const handleLogin = useUserLogin();
	const trackGTMEvent = useTrackGTMEvent();

	const initialValues = {
		firstName: '',
		lastName: '',
		email: '@',
		password: '',
		passwordConfirm: '',
		phonePrefix: {
			CZ: '+420',
			PL: '+48',
			SK: '+421',
		}[country] ?? '+420',
		phoneNumber: '',
		hasReferenceCode: false,
		referenceCode: '',
		personalInfoAgreed: false,
		conditionsAgreed: false,
	};

	const isSocial = SOCIAL_LOGINS.includes(social);

	const [error, setError] = useState(false);
	const [firstName, setFirstName] = useState(initialValues.firstName);
	const [lastName, setLastName] = useState(initialValues.lastName);
	const [validFirstName, checkingFirstName] = useFetchValidateFirstName(true, country, firstName);
	const [validLastName, checkingLastName] = useFetchValidateLastName(true, country, lastName);
	const [loading, setLoading] = useState(isSocial);
	const [privateModalOpen, setPrivateModalOpen] = useState(false);

	const [initValue, setInitValue] = useState(() => ({
		...initialValues,
		...(referral ? {
			hasReferenceCode: true,
			referenceCode: referral,
		} : {}),
	}));

	useEffect(() => {
		if (!initValue.hasReferenceCode && referral) {
			setInitValue((prevState) => ({
				...prevState,
				hasReferenceCode: true,
				referenceCode: referral,
			}));
		}
	}, [initValue.hasReferenceCode, referral]);

	useEffect(() => {
		if (!SOCIAL_LOGINS.includes(social)) {
			setLoading(false);
			return undefined;
		}

		let cancelled = false;
		setLoading(true);

		if (social === 'apple') {
			fetchAppleUserData().then((data) => {
				if (cancelled) {
					return;
				}

				setInitValue((prevState) => ({ ...prevState, ...data }));
				if (data.email.includes('@privaterelay.appleid.com')) {
					setPrivateModalOpen(true);
				}
			}).finally(() => {
				if (!cancelled) {
					setLoading(false);
				}
			});
		}

		if (social === 'facebook') {
			fetchFacebookUserData().then((data) => {
				if (cancelled) {
					return;
				}

				setInitValue((prevState) => ({ ...prevState, ...data }));
			}).finally(() => {
				if (!cancelled) {
					setLoading(false);
				}
			});
		}

		if (social === 'google') {
			fetchGoogleUserData().then((data) => {
				if (cancelled) {
					return;
				}

				setInitValue((prevState) => ({ ...prevState, ...data }));
			}).finally(() => {
				if (!cancelled) {
					setLoading(false);
				}
			});
		}

		if (social === 'linkedin') {
			fetchLinkedinUserData().then((data) => {
				if (cancelled) {
					return;
				}

				setInitValue((prevState) => ({ ...prevState, ...data }));
			}).finally(() => {
				if (!cancelled) {
					setLoading(false);
				}
			});
		}

		return () => {
			cancelled = true;
		};
	}, [social]);

	return (
		<FormLayoutFull title={t('register.title')} text={t('register.text')}>
			<Formik
				enableReinitialize
				initialValues={initValue}
				validate={(values) => validateRegistration(values)}
				onSubmit={async (values, { setErrors }) => {
					setError(false);
					try {
						await register(
							country,
							language,
							values.firstName,
							values.lastName,
							values.email,
							values.password,
							values.passwordConfirm,
							values.phonePrefix,
							values.phoneNumber,
							values.hasReferenceCode ? values.referenceCode : null,
							values.personalInfoAgreed,
							values.conditionsAgreed,
							affiliateSource,
							affiliateCode,
						);
					} catch (e) {
						const fieldErrors = {};
						const errorMessage = e.responseJson?.message;
						if (typeof errorMessage === 'string') {
							if (errorMessage.indexOf('e-mail uniqueness') !== -1) {
								fieldErrors.email = 'forms.fields.email.unique';
							}
							if (errorMessage.indexOf('passwords matching') !== -1) {
								fieldErrors.passwordConfirm = 'forms.fields.passwordConfirm.invalid';
							}
							if (errorMessage.indexOf('phone prefix validity') !== -1) {
								fieldErrors.phoneNumber = 'forms.fields.phoneNumber.invalid';
							}
							if (errorMessage.indexOf('reference code validity') !== -1) {
								fieldErrors.referenceCode = 'forms.fields.referenceCode.invalid';
							}
						}

						const hasFieldErrors = Object.keys(fieldErrors).length > 0;
						if (hasFieldErrors) {
							setErrors(fieldErrors);
						}
						setError(!hasFieldErrors);
						return;
					}
					if (emailVerificationUrl) {
						trackGTMEvent('conversionFunnel', {
							eventCategory: 'step0finished',
							eventAction: '',
							eventLabel: '',
						});
					}
					handleLogin(emailVerificationUrl || null);
				}}
			>
				{({
					errors,
					handleBlur,
					handleChange,
					handleSubmit,
					isSubmitting,
					setFieldValue,
					touched,
					values,
				}) => (
					<form onSubmit={handleSubmit}>
						<div className={styles.steps}>
							<Row center>
								<HowItWorksSteps blogPostETFUrl={blogPostETFUrl} />
							</Row>
						</div>
						<Row center>
							<Col lg={6} xl={4}>
								<SocialLogin isRegistration />
							</Col>
						</Row>
						<Row center>
							<Col lg={6} xl={4}>
								{error && (
									<Alert type="danger">
										{t('forms.error')}
									</Alert>
								)}
								<p className={styles.text}>
									{t('register.info')}
								</p>
								<TextField
									onBlur={(e) => {
										handleBlur(e);
										setFirstName(values.firstName);
									}}
									onChange={handleChange}
									error={
										errors.firstName
										&& touched.firstName
										&& t(errors.firstName)
									}
									id="firstName"
									name="firstName"
									label={t('forms.fields.firstName.label')}
									disabled={loading}
									required
									type="text"
									value={values.firstName}
									warning={firstName !== '' && !checkingFirstName && !validFirstName ? t('forms.fields.firstName.warning') : ''}
								/>
								<TextField
									onBlur={(e) => {
										handleBlur(e);
										setLastName(values.lastName);
									}}
									onChange={handleChange}
									error={
										errors.lastName
										&& touched.lastName
										&& t(errors.lastName)
									}
									id="lastName"
									name="lastName"
									label={t('forms.fields.lastName.label')}
									disabled={loading}
									required
									type="text"
									value={values.lastName}
									warning={lastName !== '' && !checkingLastName && !validLastName ? t('forms.fields.lastName.warning') : ''}
								/>
								<TextField
									autoComplete="email"
									onBlur={handleBlur}
									onChange={handleChange}
									error={
										errors.email
										&& touched.email
										&& t(errors.email)
									}
									helper={t('forms.fields.email.helper')}
									id="email"
									name="email"
									label={t('forms.fields.email.label')}
									disabled={loading}
									required
									type="email"
									value={values.email}
								/>
								<PhoneField
									error={
										(
											errors.phonePrefix
											&& touched.phonePrefix
											&& t(errors.phonePrefix)
										) || (
											errors.phoneNumber
											&& touched.phoneNumber
											&& t(errors.phoneNumber)
										)
									}
									helper={t('forms.fields.phoneNumber.helper')}
									id="phoneNumber"
									label={t('forms.fields.phoneNumber.label')}
									name="phoneNumber"
									onBlur={handleBlur}
									onChange={setFieldValue}
									phonePrefixId="phonePrefix"
									phonePrefixName="phonePrefix"
									phonePrefixValue={values.phonePrefix}
									required
									value={values.phoneNumber}
									warning={
										values.phoneNumber !== '' && !validatePhoneWarning(values.phonePrefix, values.phoneNumber)
											? t('forms.fields.phoneNumber.warning') : ''
									}
								/>
								<TextField
									autoComplete="new-password"
									onBlur={handleBlur}
									onChange={handleChange}
									error={
										errors.password
										&& touched.password
										&& t(errors.password)
									}
									helper={t('forms.fields.password.helper')}
									id="password"
									name="password"
									label={t('forms.fields.password.label')}
									required
									type="password"
									value={values.password}
								/>
								<TextField
									autoComplete="new-password"
									onBlur={handleBlur}
									onChange={handleChange}
									error={
										errors.passwordConfirm
										&& touched.passwordConfirm
										&& t(errors.passwordConfirm)
									}
									id="passwordConfirm"
									name="passwordConfirm"
									label={t('forms.fields.passwordConfirm.label')}
									required
									type="password"
									value={values.passwordConfirm}
								/>
								<CheckboxField
									onBlur={handleBlur}
									onChange={handleChange}
									checked={values.hasReferenceCode}
									label={t('forms.fields.hasReferenceCode.label')}
									id="hasReferenceCode"
									name="hasReferenceCode"
								/>
								{values.hasReferenceCode && (
									<TextField
										onBlur={handleBlur}
										onChange={(e) => {
											trackGTMEvent('interactions', {
												eventCategory: 'interactions',
												eventAction: 'inputField',
												eventLabel: 'reference_code_filled',
											});
											handleChange(e);
										}}
										error={
											errors.referenceCode
											&& touched.referenceCode
											&& t(errors.referenceCode)
										}
										id="referenceCode"
										name="referenceCode"
										isLabelHidden
										label={t('forms.fields.referenceCode.label')}
										type="text"
										required={values.hasReferenceCode}
										value={values.referenceCode}
									/>
								)}
								<CheckboxField
									onBlur={handleBlur}
									onChange={handleChange}
									checked={values.personalInfoAgreed}
									error={
										errors.personalInfoAgreed
										&& touched.personalInfoAgreed
										&& t(errors.personalInfoAgreed)
									}
									label={(
										<Trans i18nKey="forms.fields.personalInfoAgreed.label">
											<ExternalLink
												to={personalInfoAgreementUrl}
												blank
											/>
										</Trans>
									)}
									id="personalInfoAgreed"
									name="personalInfoAgreed"
									required
								/>
								<CheckboxField
									onBlur={handleBlur}
									onChange={handleChange}
									checked={values.conditionsAgreed}
									error={
										errors.conditionsAgreed
										&& touched.conditionsAgreed
										&& t(errors.conditionsAgreed)
									}
									label={(
										<Trans i18nKey="forms.fields.conditionsAgreed.label">
											<ExternalLink
												to={conditionsUrl}
												blank
											/>
										</Trans>
									)}
									id="conditionsAgreed"
									name="conditionsAgreed"
									required
								/>
								<Button
									label={t('register.button')}
									disabled={isSubmitting}
									isSubmit
								/>
								{isSocial && (
									<>
										<div className={styles.line}>
											<span>{t('register.social.or')}</span>
										</div>
										<p className={styles.emailAliasText}>
											{t('register.emailAlias.text')}
										</p>
										<ButtonLink label={t('register.emailAlias.button')} to={`${emailAliasUrl}?social=${social}`} />
									</>
								)}
							</Col>
						</Row>
					</form>
				)}
			</Formik>
			<Modal
				isVisible={privateModalOpen}
				onClose={() => setPrivateModalOpen(false)}
				title={t('register.privateModal.title')}
			>
				<div className={styles.modal}>
					<p className={styles.modalText}>
						{t('register.privateModal.text')}
					</p>
					<ButtonLink
						label={t('register.privateModal.button')}
						to={`${emailAliasUrl}?social=${social}`}
					/>
					<p className={styles.modalText}>
						<Trans i18nKey="register.privateModal.close">
							<a
								href="#close"
								onClick={(e) => {
									e.preventDefault();
									setPrivateModalOpen(false);
								}}
							>
								{t('register.privateModal.close')}
							</a>
						</Trans>
					</p>
				</div>
			</Modal>
		</FormLayoutFull>
	);
}

Registration.propTypes = {
	affiliateCode: PropTypes.string,
	affiliateSource: PropTypes.string,
	blogPostETFUrl: PropTypes.string,
	conditionsUrl: PropTypes.string.isRequired,
	emailAliasUrl: PropTypes.string.isRequired,
	emailVerificationUrl: PropTypes.string.isRequired,
	personalInfoAgreementUrl: PropTypes.string.isRequired,
	referral: PropTypes.string,
	social: PropTypes.string,
};

Registration.defaultProps = {
	affiliateCode: null,
	affiliateSource: null,
	blogPostETFUrl: null,
	referral: undefined,
	social: '',
};
