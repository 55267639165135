import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import { useCountry } from '../../../contexts/LocaleContext';
import { fetchPhonePrefixes } from '../../../utils/api';
import useFetch from '../../../utils/useFetch';
import useFocusOnError from '../../../utils/useFocusOnError';
import SelectField from '../SelectField';

import styles from './PhoneField.module.scss';

const useFetchPhonePrefixes = useFetch(fetchPhonePrefixes);

export default function PhoneField({
	disabled,
	error,
	helper,
	id,
	inputMode,
	isLabelHidden,
	label,
	maxLength,
	minLength,
	name,
	onBlur,
	onChange,
	pattern,
	phonePrefixId,
	phonePrefixName,
	phonePrefixValue,
	placeholder,
	readonly,
	required,
	value,
	warning,
}) {
	const country = useCountry();
	const [phonePrefixes, loading] = useFetchPhonePrefixes([], country);

	const phonePrefixOptions = useMemo(() => (
		!loading ? phonePrefixes.map((x) => ({ label: x, value: x })) : []
	), [phonePrefixes, loading]);

	const inputRef = useRef();
	useFocusOnError(name, inputRef);

	const handleChange = (e) => {
		if (onChange !== null) {
			onChange(name, e.currentTarget.value);
		}
	};

	return (
		<div className={`${styles.root} ${error && styles.rootError} ${disabled ? styles.isDisabled : ''}`.trim()}>
			<div className={`${styles.labelWrap} ${isLabelHidden ? styles.labelWrapHidden : ''}`.trim()}>
				<label className={styles.label} htmlFor={id}>{label}</label>
				{helper && <div className={styles.helper}>{helper}</div>}
			</div>
			<div className={styles.inputWrap}>
				<div className={styles.select}>
					<SelectField
						autoComplete="phone-prefix"
						disabled={disabled}
						id={phonePrefixId}
						isLabelHidden
						label={label}
						name={phonePrefixName}
						onBlur={onBlur}
						onChange={onChange}
						options={phonePrefixOptions}
						required={required}
						searchable
						value={phonePrefixValue}
					/>
				</div>
				<input
					ref={inputRef}
					autoComplete="tel-local"
					className={styles.input}
					disabled={disabled}
					id={id}
					inputMode={inputMode}
					maxLength={maxLength}
					minLength={minLength}
					name={name}
					onBlur={onBlur}
					onChange={handleChange}
					pattern={pattern}
					placeholder={placeholder}
					readOnly={readonly}
					required={required}
					type="text"
					value={value}
				/>
			</div>
			{error && (
				<div className={styles.error}>{error}</div>
			)}
			{warning && (
				<div className={styles.warning}>{warning}</div>
			)}
		</div>
	);
}

PhoneField.propTypes = {
	disabled: PropTypes.bool,
	error: PropTypes.string,
	helper: PropTypes.string,
	id: PropTypes.string.isRequired,
	inputMode: PropTypes.string,
	isLabelHidden: PropTypes.bool,
	label: PropTypes.string.isRequired,
	maxLength: PropTypes.number,
	minLength: PropTypes.number,
	name: PropTypes.string.isRequired,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	pattern: PropTypes.string,
	phonePrefixId: PropTypes.string.isRequired,
	phonePrefixName: PropTypes.string.isRequired,
	phonePrefixValue: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	placeholder: PropTypes.string,
	readonly: PropTypes.bool,
	required: PropTypes.bool,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	warning: PropTypes.string,
};

PhoneField.defaultProps = {
	disabled: false,
	error: '',
	helper: '',
	inputMode: null,
	isLabelHidden: false,
	maxLength: null,
	minLength: null,
	onBlur: null,
	onChange: null,
	pattern: null,
	phonePrefixValue: undefined,
	placeholder: null,
	readonly: false,
	required: false,
	value: undefined,
	warning: '',
};
