import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { FooterLegal } from '../../components/common';
import NewspaperEmbed from './NewspaperEmbed';
import NewspaperHero from './NewspaperHero';

const pdfUrl = `${process.env.PUBLIC_URL}/files/newspaper/zitradnes.pdf`;

export default function Newspaper({
	baseUrl,
}) {
	const [t] = useTranslation();
	const metaImage = `${baseUrl}/images/newspaper/fondee-og-newspaper.png`;

	return (
		<>
			<Helmet>
				<title>{t('newspaper.meta.title')}</title>
				<meta content={t('newspaper.meta.description')} name="description" />
				<meta content={t('newspaper.meta.title')} property="og:title" />
				<meta content={t('newspaper.meta.description')} property="og:description" />
				<meta content={metaImage} property="og:image" />
				<meta content={metaImage} name="twitter:image" />
			</Helmet>
			<NewspaperHero pdfUrl={pdfUrl} />
			<NewspaperEmbed pdfUrl={pdfUrl} />
			<footer role="contentinfo">
				<FooterLegal />
			</footer>
		</>
	);
}

Newspaper.propTypes = {
	baseUrl: PropTypes.string.isRequired,
};
