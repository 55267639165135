import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { fetchInvestorProfile } from '../../../utils/api';
import useFetch from '../../../utils/useFetch';
import { Loader, Toggle } from '../../ui';
import InvestorProfileDetailSelection from '../InvestorProfileDetail/InvestorProfileDetailSelection';
import InvestorProfileDetailSlider from '../InvestorProfileDetail/InvestorProfileDetailSlider';
import { ProductAccount } from '../index';

import styles from './ProductProfileDetail.module.scss';

const useFetchInvestorProfile = useFetch(fetchInvestorProfile);

export default function ProductProfileDetail({
	defaultEsg,
	product,
	toggleEsg,
	howItWorksUrl,
}) {
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const [data, loading] = useFetchInvestorProfile({}, country, language);
	const [currentSlide, setCurrentSlide] = useState(3);
	const [esg, setEsg] = useState(defaultEsg);

	if (loading) {
		return <Loader variant="large" />;
	}

	const investorProfiles = esg ? data.investorProfilesEsg : data.investorProfiles;

	return (
		<>
			<h3 className={styles.title}>{t(`products.${product}.profileDetail.title`)}</h3>
			<div className={styles.wrap}>
				{toggleEsg && (
					<Toggle
						labelFirst={t('investorProfilePreview.classicPortfolios')}
						labelSecond={t('investorProfilePreview.esgPortfolios')}
						setToggle={setEsg}
						toggle={esg}
						tooltipFirst={t('investorProfileChoice.tooltip.classic')}
						tooltipSecond={t('investorProfileChoice.tooltip.esg')}
					/>
				)}
				<InvestorProfileDetailSlider
					currentSlide={currentSlide}
					products={data.products ?? []}
					profiles={investorProfiles ?? []}
					setCurrentSlide={setCurrentSlide}
				/>
				<InvestorProfileDetailSelection
					currentSlide={currentSlide}
					onSelectionChange={setCurrentSlide}
					profiles={investorProfiles ?? []}
				/>
				<ProductAccount
					currentSlide={currentSlide}
					profiles={investorProfiles ?? []}
					howItWorksUrl={howItWorksUrl}
				/>
			</div>
		</>
	);
}

ProductProfileDetail.propTypes = {
	defaultEsg: PropTypes.bool,
	product: PropTypes.string,
	toggleEsg: PropTypes.bool,
	howItWorksUrl: PropTypes.string.isRequired,
};

ProductProfileDetail.defaultProps = {
	defaultEsg: false,
	product: null,
	toggleEsg: false,
};
