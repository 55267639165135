import React from 'react';
import PropTypes from 'prop-types';

import styles from './BlogPostTable.module.scss';

export default function BlogPostTable({
	children,
}) {
	return (
		<div className={styles.wrap}>
			<div className={styles.root}>
				{children}
			</div>
		</div>
	);
}

BlogPostTable.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};
