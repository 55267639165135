import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { Ranger } from '../../../components/forms';
import {
	Container,
	Section,
} from '../../../components/layout';
import { Loader } from '../../../components/ui';
import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { fetchInvestmentMicrositeInstrument } from '../../../utils/api';
import useFetch from '../../../utils/useFetch';
import useFormatNumber from '../../../utils/useFormatNumber';

import styles from './InvestingPriceGrowth.module.scss';

const DEFAULT_INSTRUMENT = 2;
const RANGER_YEARS_MIN = 2006;
const RANGER_YEARS_MAX = 2022;

const icons = {
	butter:
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" fill="none" viewBox="0 0 32 33">
		<path fill="url(#butter-a)" d="M5 13c0-1.1.9-2 2-2h10.2a6 6 0 0 1 3.35 1.03l6.13 4.13A3 3 0 0 1 28 18.64v3.23a2 2 0 0 1-2 2H13.54a3 3 0 0 1-1.7-.53L6.28 19.5A3 3 0 0 1 5 17.04V13Z" />
		<path fill="#42222A" fillRule="evenodd" d="M7 10h10.2a7 7 0 0 1 3.91 1.2l6.13 4.13A4 4 0 0 1 29 18.64v3.23a3 3 0 0 1-3 3H13.54a4 4 0 0 1-2.28-.71l-5.54-3.84A4 4 0 0 1 4 17.04V13a3 3 0 0 1 3-3Zm0 1a2 2 0 0 0-2 2v4.04a3 3 0 0 0 1.3 2.46l5.53 3.84a3 3 0 0 0 1.7.53H26a2 2 0 0 0 2-2v-3.23a3 3 0 0 0-1.32-2.48l-6.13-4.13a6 6 0 0 0-3.35-1.02H7Z" clipRule="evenodd" />
		<path fill="url(#butter-b)" d="M11.43 18.46c0-1.1.9-2 2-2H26a2 2 0 0 1 2 2v3.41a2 2 0 0 1-2 2H13.43a2 2 0 0 1-2-2v-3.4Z" />
		<defs>
			<linearGradient id="butter-a" x1="13.87" x2="5.78" y1="9.06" y2="20.17" gradientUnits="userSpaceOnUse">
				<stop stopColor="#FFFEFC" />
				<stop offset=".49" stopColor="#FDF6E9" />
				<stop offset="1" stopColor="#F5E9D8" />
			</linearGradient>
			<linearGradient id="butter-b" x1="28" x2="11.43" y1="20.24" y2="20.24" gradientUnits="userSpaceOnUse">
				<stop stopColor="#E28335" />
				<stop offset="1" stopColor="#D95C47" />
			</linearGradient>
		</defs>
	</svg>,
	meat:
	<svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="none" viewBox="0 0 33 33">
		<path fill="#FDF7F1" d="M28.58 19.5a30.3 30.3 0 0 1-9.5-3.76c-1.39 1.03-3.59 3.1-1.26 3.18 2.34.08 7.19 2.84 9.32 4.21.43.35 1.46.58 2.18-1.23.72-1.82-.2-2.35-.74-2.4Z" />
		<path fill="#42222A" fillRule="evenodd" d="m19.06 15.14.29.18a29.86 29.86 0 0 0 9.3 3.69c.39.04.9.25 1.21.78.31.55.31 1.31-.07 2.3-.4.97-.92 1.53-1.52 1.71-.59.19-1.1-.02-1.42-.26A49.42 49.42 0 0 0 22.34 21a25 25 0 0 0-2.54-1.1 7.14 7.14 0 0 0-2-.47 2.46 2.46 0 0 1-.84-.16c-.24-.1-.46-.27-.57-.52-.12-.26-.1-.51-.04-.73.06-.2.17-.42.29-.6a9.67 9.67 0 0 1 2.15-2.07l.27-.2Zm9.93 5.14a.57.57 0 0 0-.45-.28h-.05a30.8 30.8 0 0 1-9.38-3.65c-.62.48-1.3 1.1-1.64 1.6a1.21 1.21 0 0 0-.17.37l.04.02c.09.03.24.07.5.08.66.02 1.46.23 2.3.53.83.3 1.74.7 2.64 1.15 1.79.88 3.55 1.92 4.63 2.61l.02.02.02.02c.12.1.32.16.52.1s.55-.3.89-1.14c.33-.83.23-1.25.13-1.43Zm-11.7-1.98Zm.01.04Z" clipRule="evenodd" />
		<path fill="url(#meat-a)" d="M20.95 18.58c-1.22 3.08-9.26 2.79-13.36 1.16-4.11-1.63-5.52-5.08-4.3-8.16 1.22-3.08 4.61-4.62 8.72-3 4.1 1.63 10.16 6.92 8.94 10Z" />
		<path fill="#42222A" fillRule="evenodd" d="M18.57 19.36c.86-.33 1.3-.75 1.45-1.15.16-.4.13-.99-.27-1.83-.4-.82-1.1-1.73-1.99-2.64a19.63 19.63 0 0 0-6.12-4.23 6.52 6.52 0 0 0-4.72-.26 4.63 4.63 0 0 0-2.7 2.7 4.63 4.63 0 0 0 .12 3.82 6.52 6.52 0 0 0 3.62 3.04c1.9.76 4.8 1.22 7.35 1.12 1.28-.05 2.4-.24 3.26-.57Zm-10.98.38c4.1 1.63 12.14 1.92 13.36-1.16 1.22-3.08-4.83-8.37-8.94-10-4.11-1.62-7.5-.08-8.72 3-1.22 3.08.19 6.53 4.3 8.16Z" clipRule="evenodd" />
		<path fill="url(#meat-b)" d="M30.25 22.27c-.61 1.54-1.66 2.57-2.9 2.08-1.23-.5-3.29-2.75-2.68-4.3.61-1.53 3.66-1.77 4.9-1.28 1.23.49 1.29 1.96.68 3.5Z" />
		<path fill="#42222A" fillRule="evenodd" d="M28.42 23.28c.3-.24.63-.7.9-1.38.27-.68.35-1.25.29-1.63-.05-.32-.18-.48-.41-.57a4.43 4.43 0 0 0-2.13-.07c-.92.18-1.36.53-1.47.8-.1.26-.02.82.53 1.58.52.72 1.2 1.25 1.6 1.4.23.1.43.08.69-.13Zm-1.07 1.07c1.24.49 2.29-.54 2.9-2.08.61-1.54.55-3.01-.68-3.5-1.24-.49-4.29-.25-4.9 1.29-.6 1.54 1.45 3.8 2.68 4.29Z" clipRule="evenodd" />
		<defs>
			<linearGradient id="meat-a" x1="20.91" x2="3.25" y1="18.69" y2="11.69" gradientUnits="userSpaceOnUse">
				<stop stopColor="#E28335" />
				<stop offset="1" stopColor="#D95C47" />
			</linearGradient>
			<linearGradient id="meat-b" x1="30.23" x2="24.65" y1="22.32" y2="20.11" gradientUnits="userSpaceOnUse">
				<stop stopColor="#E28335" />
				<stop offset="1" stopColor="#D95C47" />
			</linearGradient>
		</defs>
	</svg>,
	beer:
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" fill="none" viewBox="0 0 32 33">
		<path fill="#42222A" fillRule="evenodd" d="M22.85 13.03c-.53.08-1.17.41-1.6.71l-1.15-1.63c.55-.4 1.47-.9 2.42-1.06a2.85 2.85 0 0 1 1.6.16c.57.24 1 .7 1.26 1.35.26.64.49 1.66.58 2.78.09 1.12.05 2.44-.28 3.66a5.37 5.37 0 0 1-2.15 3.22c-1.2.78-2.78 1-4.74.57l.42-1.96c1.61.36 2.6.12 3.22-.29a3.4 3.4 0 0 0 1.32-2.06c.25-.93.3-2 .21-2.98a8.37 8.37 0 0 0-.43-2.2c-.08-.2-.16-.24-.2-.26a.9.9 0 0 0-.48-.01Z" clipRule="evenodd" />
		<path fill="url(#beer-a)" d="M6 9c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2v4.4a2 2 0 0 1-.12.69l-.76 2.08a2 2 0 0 0-.12.69V24a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2v-7.15a2 2 0 0 0-.12-.69l-.76-2.08A2 2 0 0 1 6 13.4V9Z" />
		<path fill="#FDF7F1" d="M14 14c-.83.34-2.7.8-5.5 0l-2-.5v-5l1.5-1h12l1.5.5v5.5l-2 .5c-2 1-4.5.5-5.5 0Z" />
		<path fill="#42222A" fillRule="evenodd" d="M20 8H8a1 1 0 0 0-1 1v4.4a1 1 0 0 0 .06.34l.76 2.1A3 3 0 0 1 8 16.85V24a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-7.15a3 3 0 0 1 .18-1.03l.76-2.09a1 1 0 0 0 .06-.34V9a1 1 0 0 0-1-1ZM8 7a2 2 0 0 0-2 2v4.4a2 2 0 0 0 .12.69l.76 2.08a2 2 0 0 1 .12.69V24c0 1.1.9 2 2 2h10a2 2 0 0 0 2-2v-7.15a2 2 0 0 1 .12-.69l.76-2.08a2 2 0 0 0 .12-.69V9a2 2 0 0 0-2-2H8Z" clipRule="evenodd" />
		<path fill="#42222A" fillRule="evenodd" d="m14.02 13.46.2.1c.43.21 1.24.45 2.19.5.94.07 1.97-.05 2.87-.5a.5.5 0 1 1 .44.9c-1.1.54-2.32.67-3.38.6a7.45 7.45 0 0 1-2.36-.51c-1 .35-2.9.71-5.62-.06a.5.5 0 0 1 .28-.97c2.68.77 4.44.32 5.17.02l.21-.08ZM10.5 16.5c.28 0 .5.23.5.5v6a.5.5 0 0 1-1 0v-6c0-.27.22-.5.5-.5ZM14 16.5c.28 0 .5.23.5.5v6a.5.5 0 0 1-1 0v-6c0-.27.22-.5.5-.5ZM17.5 16.5c.28 0 .5.23.5.5v6a.5.5 0 0 1-1 0v-6c0-.27.22-.5.5-.5Z" clipRule="evenodd" />
		<defs>
			<linearGradient id="beer-a" x1="22" x2="6" y1="16.68" y2="16.68" gradientUnits="userSpaceOnUse">
				<stop stopColor="#E28335" />
				<stop offset="1" stopColor="#D95C47" />
			</linearGradient>
		</defs>
	</svg>,
	cinema:
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" fill="none" viewBox="0 0 32 33">
		<path fill="url(#cinema-a)" d="M4 9a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v2c0 .56-.46 1-.98 1.16A3.96 3.96 0 0 0 24.4 16c0 1.82 1.1 3.37 2.62 3.84.52.17.98.6.98 1.16v2a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-2c0-.56.46-.99.98-1.16a3.96 3.96 0 0 0 2.62-3.84c0-1.83-1.1-3.38-2.62-3.85-.52-.17-.98-.6-.98-1.15V9Z" />
		<path fill="url(#cinema-b)" fillRule="evenodd" d="M27 9H5v2l.03.05c.05.06.14.12.26.15A4.96 4.96 0 0 1 8.6 16a4.96 4.96 0 0 1-3.31 4.8c-.12.04-.2.1-.26.16A.16.16 0 0 0 5 21v2h22v-2l-.03-.05a.59.59 0 0 0-.26-.15A4.96 4.96 0 0 1 23.4 16a4.96 4.96 0 0 1 3.31-4.8c.12-.04.2-.1.26-.16A.16.16 0 0 0 27 11V9Zm0 2ZM27 21Zm-22 0ZM5 11Zm0-3a1 1 0 0 0-1 1v2c0 .56.46 1 .98 1.16A3.96 3.96 0 0 1 7.6 16c0 1.82-1.1 3.37-2.62 3.84-.52.17-.98.6-.98 1.16v2a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1v-2c0-.56-.46-.99-.98-1.16a3.96 3.96 0 0 1-2.62-3.84c0-1.83 1.1-3.38 2.62-3.85.52-.17.98-.6.98-1.15V9a1 1 0 0 0-1-1H5Z" clipRule="evenodd" />
		<path fill="#FDF7F1" d="M4 9a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-2c0-.55.46-.98.99-1.14a3.97 3.97 0 0 0 2.7-3.85A3.97 3.97 0 0 0 5 12.15c-.53-.16-.99-.6-.99-1.14V9Z" />
		<path stroke="#42222A" d="M4.5 9c0-.27.22-.5.5-.5h22c.28 0 .5.23.5.5v2c0 .26-.23.55-.64.68a4.46 4.46 0 0 0-2.96 4.33 4.46 4.46 0 0 0 2.96 4.32c.41.13.64.42.64.68v2a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5v-2c0-.26.23-.55.64-.68a4.46 4.46 0 0 0 2.96-4.32 4.46 4.46 0 0 0-2.96-4.33c-.41-.13-.64-.42-.64-.67V9Z" />
		<path fill="#42222A" d="M17 9.01h1v2h-1zM17 21.01h1v2h-1zM17 18.01h1v2h-1zM17 12.01h1v2h-1zM17 15.01h1v2h-1z" />
		<defs>
			<linearGradient id="cinema-a" x1="28" x2="4" y1="16.16" y2="16.16" gradientUnits="userSpaceOnUse">
				<stop stopColor="#E28335" />
				<stop offset="1" stopColor="#D95C47" />
			</linearGradient>
			<linearGradient id="cinema-b" x1="28" x2="4" y1="16.16" y2="16.16" gradientUnits="userSpaceOnUse">
				<stop stopColor="#E28335" />
				<stop offset="1" stopColor="#D95C47" />
			</linearGradient>
		</defs>
	</svg>,
	flat:
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" fill="none" viewBox="0 0 32 33">
		<path fill="#FDF7F1" d="M7 12.76 16 6l9 6.75v10.6c0 .51-.21 1-.59 1.37-.37.36-.88.56-1.41.56H9c-.53 0-1.04-.2-1.41-.56A1.9 1.9 0 0 1 7 23.36v-10.6Z" />
		<path fill="#FDF7F1" fillRule="evenodd" d="M15.7 5.6a.5.5 0 0 1 .6 0l9 6.76c.13.09.2.24.2.4v10.6a2.4 2.4 0 0 1-.74 1.73c-.47.45-1.1.7-1.76.7H9c-.66 0-1.3-.25-1.76-.7a2.4 2.4 0 0 1-.74-1.73v-10.6c0-.16.07-.31.2-.4l9-6.75ZM7.5 13v10.36c0 .38.15.74.43 1 .28.28.67.43 1.07.43h14c.4 0 .79-.15 1.07-.42s.43-.63.43-1V13L16 6.63l-8.5 6.38Z" clipRule="evenodd" />
		<path fill="url(#flat-a)" d="M13.43 25.3v-9h5.14v9" />
		<path fill="#42222A" fillRule="evenodd" d="M12.93 16.3c0-.28.22-.5.5-.5h5.14c.28 0 .5.22.5.5v9a.5.5 0 0 1-1 0v-8.5h-4.14v8.5a.5.5 0 1 1-1 0v-9Z" clipRule="evenodd" />
		<path stroke="#42222A" strokeLinecap="round" strokeLinejoin="round" d="M7 12.76 16 6l9 6.75v10.6c0 .51-.21 1-.59 1.37-.37.36-.88.56-1.41.56H9c-.53 0-1.04-.2-1.41-.56A1.9 1.9 0 0 1 7 23.36v-10.6Z" />
		<defs>
			<linearGradient id="flat-a" x1="18.57" x2="13.43" y1="20.88" y2="20.88" gradientUnits="userSpaceOnUse">
				<stop stopColor="#E28335" />
				<stop offset="1" stopColor="#D95C47" />
			</linearGradient>
		</defs>
	</svg>,
};

const useFetchInvestmentMicrositeInstrumentData = useFetch(fetchInvestmentMicrositeInstrument);

export default function InvestingPriceGrowth({
	investingHeroAnchor,
}) {
	const [t] = useTranslation();
	const country = useCountry();
	const formatNumber = useFormatNumber();
	const language = useLanguage();
	const [data, loadingData] = useFetchInvestmentMicrositeInstrumentData(null, country, language, 'inflation');
	const [rangeValue, setRangeValue] = useState(RANGER_YEARS_MIN);
	const [instrumentIndex, setInstrumentIndex] = useState(DEFAULT_INSTRUMENT);
	const instrument = !loadingData && data !== null
		? data[instrumentIndex] ?? null
		: null;
	const price = (year) => instrument.prices[`${year}-01-01`];

	const instrumentPriceFlow = (value, ratio = false) => {
		let typeClassName = '';
		let sign = '';
		if (value > 0) {
			sign = '+';
		} else if (value < 0) {
			typeClassName = styles.priceFlowNegative;
			sign = '-';
		}

		const formatOptions = ratio
			? { style: 'percent', fractionDigits: 2 }
			: { style: 'currency', currency: 'CZK', fractionDigits: 2 };

		return (
			<span className={`${styles.priceFlow} ${typeClassName}`}>
				<span className={styles.iconPriceFlow}>
					<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
						<path fill="currentColor" d="M1.55 2.42V1.25c0-.5.4-.9.9-.89l10.2-.01c.5 0 .9.4.9.89l-.02 10.21c0 .5-.4.9-.9.9h-1.16c-.5 0-.9-.42-.89-.92l.16-6.18-7.56 7.56a.89.89 0 0 1-1.26 0l-.84-.84a.89.89 0 0 1 0-1.26l7.56-7.56-6.18.16a.88.88 0 0 1-.91-.9Z" />
					</svg>
				</span>
				{sign}
				&nbsp;
				{formatNumber(value < 0 ? -value : value, formatOptions)}
			</span>
		);
	};

	const priceDifference = instrument !== null && rangeValue !== RANGER_YEARS_MIN
		? price(rangeValue) - price(RANGER_YEARS_MIN)
		: 0;
	const priceDifferenceRatio = instrument !== null && rangeValue !== RANGER_YEARS_MIN
		? (price(rangeValue) / price(RANGER_YEARS_MIN)) - 1
		: 0;

	return (
		<Section id={investingHeroAnchor}>
			<Container center>
				<div className={styles.wrapper}>
					<h2 className={styles.title}>
						{t('investing.inflation.title')}
					</h2>
					<p className={styles.text}>
						{t('investing.inflation.text')}
					</p>
					{!loadingData && data !== null ? (
						<>
							{instrument !== null && (
								<div className={styles.card}>
									<div className={styles.priceWrap}>
										<p className={styles.priceLabel}>
											{instrument.priceDescription}
										</p>
										<p className={styles.price}>
											{formatNumber(price(rangeValue), { style: 'currency', currency: 'CZK', fractionDigits: 0 })}
										</p>
									</div>
									<div className={styles.flowWrap}>
										<p className={styles.flowLabel}>
											{t('investing.inflation.flowLabel')}
										</p>
										<p className={styles.range}>
											<Trans
												i18nKey="investing.inflation.range"
												values={{ start: RANGER_YEARS_MIN, end: rangeValue }}
											>
												<span className={styles.medium} />
											</Trans>
										</p>
										<p className={styles.priceFlowWrap}>
											{instrumentPriceFlow(priceDifference)}
											{instrumentPriceFlow(priceDifferenceRatio, true)}
										</p>
									</div>
								</div>
							)}
							<div className={styles.slider}>
								<Ranger
									min={RANGER_YEARS_MIN}
									minLabel={RANGER_YEARS_MIN}
									max={RANGER_YEARS_MAX}
									maxLabel={RANGER_YEARS_MAX}
									onChange={(_, value) => setRangeValue(value)}
									id="ranger"
									name="ranger"
									value={rangeValue}
									showSteps
								/>
							</div>
							<div className={styles.instrumentListWrap}>
								<ul className={styles.instrumentList}>
									{data.map((item, index) => (
										<li key={item.id} className={styles.instrumentItem}>
											<button
												className={`${styles.button} ${index === instrumentIndex ? styles.buttonActive : ''}`.trim()}
												onClick={() => setInstrumentIndex(index)}
												type="button"
											>
												<span className={styles.icon}>
													{icons[item.icon]}
												</span>
												<span>{item.name}</span>
											</button>
										</li>
									))}
								</ul>
							</div>
							{/* eslint-disable-next-line react/no-danger */}
							<div
								className={styles.source}
								dangerouslySetInnerHTML={{ __html: instrument?.description }}
							/>
						</>
					) : (
						<div className={styles.loaderWrap}>
							<Loader />
						</div>
					)}
				</div>
			</Container>
			<div className={styles.bubble} />
		</Section>
	);
}

InvestingPriceGrowth.propTypes = {
	investingHeroAnchor: PropTypes.string.isRequired,
};
