import React from 'react';
import PropTypes from 'prop-types';

import styles from './RadioField.module.scss';

export default function RadioField({
	checked,
	disabled,
	id,
	isLabelHidden,
	label,
	name,
	onChange,
	required,
	value,
}) {
	return (
		<label
			className={styles.root}
			htmlFor={`${id}__${value}`}
		>
			<input
				checked={checked}
				className={styles.input}
				disabled={disabled}
				id={`${id}__${value}`}
				name={name}
				onChange={onChange}
				type="radio"
				required={required}
				value={value}
			/>
			<span className={`${styles.label} ${isLabelHidden ? styles.labelHidden : ''}`.trim()}>
				{label}
			</span>
		</label>
	);
}

RadioField.propTypes = {
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	id: PropTypes.string.isRequired,
	isLabelHidden: PropTypes.bool,
	label: PropTypes.node.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	required: PropTypes.bool,
	value: PropTypes.string.isRequired,
};

RadioField.defaultProps = {
	checked: false,
	disabled: false,
	isLabelHidden: false,
	onChange: null,
	required: false,
};
