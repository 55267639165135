import React from 'react';
import PropTypes from 'prop-types';

import { breakpoints } from '../../../utils/isScreenWidthUp';

export default function Picture({
	alt,
	breakpoint,
	className,
	lazyLoad,
	width,
	height,
	src,
	src2x,
	src2xWebP,
	srcBreakpoint,
	srcBreakpoint2x,
	srcBreakpoint2xWebP,
	srcBreakpointWebP,
	srcWebP,
}) {
	const breakpointValue = breakpoints[breakpoint] ? `${breakpoints[breakpoint]}px` : `${breakpoints.lg}px`;

	const sourceLgWebP = srcBreakpointWebP ? (
		<source
			media={`(min-width: ${breakpointValue})`}
			srcSet={`${srcBreakpointWebP} 1x${srcBreakpoint2xWebP ? `, ${srcBreakpoint2xWebP} 2x` : ''}`}
			type="image/webp"
		/>
	) : null;
	const sourceWebP = srcWebP ? (
		<source
			srcSet={`${srcWebP} 1x${src2xWebP ? `, ${src2xWebP} 2x` : ''}`}
			type="image/webp"
		/>
	) : null;
	const sourceLg = srcBreakpoint ? (
		<source
			media={`(min-width: ${breakpointValue})`}
			srcSet={`${srcBreakpoint} 1x${srcBreakpoint2x ? `, ${srcBreakpoint2x} 2x` : ''}`}
		/>
	) : null;
	return (
		<picture>
			{sourceLgWebP}
			{sourceWebP}
			{sourceLg}
			<img
				alt={alt}
				className={className}
				loading={lazyLoad ? 'lazy' : null}
				src={src}
				srcSet={src && src2x ? `${src} 1x${src2x ? `, ${src2x} 2x` : ''}` : null}
				width={width}
				height={height}
			/>
		</picture>
	);
}

Picture.propTypes = {
	alt: PropTypes.string.isRequired,
	breakpoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
	className: PropTypes.string,
	lazyLoad: PropTypes.bool,
	width: PropTypes.number,
	height: PropTypes.number,
	src: PropTypes.string.isRequired,
	src2x: PropTypes.string,
	src2xWebP: PropTypes.string,
	srcBreakpoint: PropTypes.string,
	srcBreakpoint2x: PropTypes.string,
	srcBreakpoint2xWebP: PropTypes.string,
	srcBreakpointWebP: PropTypes.string,
	srcWebP: PropTypes.string,
};

Picture.defaultProps = {
	breakpoint: 'lg',
	className: null,
	lazyLoad: true,
	width: null,
	height: null,
	src2x: null,
	src2xWebP: null,
	srcBreakpoint: null,
	srcBreakpoint2x: null,
	srcBreakpoint2xWebP: null,
	srcBreakpointWebP: null,
	srcWebP: null,
};
