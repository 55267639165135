import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { onboardingCloseAccount } from '../../../utils/api';
import useRequestAuth from '../../../utils/useRequestAuth';
import {
	Alert,
	Button,
	Modal,
} from '../../ui';

import styles from './AgeCheckBlock.module.scss';

export default function AgeCheckBlock() {
	const [error, setError] = useState(false);
	const [closedAccount, setClosedAccount] = useState(false);
	const [t] = useTranslation();
	const closeAccountAuth = useRequestAuth(onboardingCloseAccount);

	const handleCloseAccount = async () => {
		setError(false);
		setClosedAccount(false);

		try {
			await closeAccountAuth();
		} catch (e) {
			setError(true);
			return;
		}

		setClosedAccount(true);
	};

	return (
		<Modal title={t('ageCheckBlock.title')} isVisible>
			<div className={styles.root}>
				{error && (
					<Alert type="danger">
						{t('forms.error')}
					</Alert>
				)}
				<p className={styles.text}>
					{t(`ageCheckBlock.${closedAccount ? 'aborted' : 'text'}`)}
				</p>
				{!closedAccount && (
					<Button
						label={t('ageCheckBlock.button')}
						onClick={handleCloseAccount}
					/>
				)}
			</div>
		</Modal>
	);
}
