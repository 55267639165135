import { saveUtmCampaign } from './api';

export default function saveUrlUtmCampaign(url) {
	const { searchParams } = new URL(url);
	const source = searchParams.get('utm_source');
	const medium = searchParams.get('utm_medium');
	const campaign = searchParams.get('utm_campaign');
	const term = searchParams.get('utm_term');
	const content = searchParams.get('utm_content');

	if (source !== null || medium !== null || campaign !== null || term !== null || content !== null) {
		saveUtmCampaign(source, medium, campaign, term, content);
	}
}
