import imgEva from './images/eva.jpg';
import imgEvaWebP from './images/eva.webp';
import imgHonza from './images/honza.jpg';
import imgHonzaWebP from './images/honza.webp';
import imgPavel from './images/pavel_stepanek.jpg';
import imgPavelWebP from './images/pavel_stepanek.webp';

export default {
	members: [
		{
			slug: 'eva',
			link: 'https://www.linkedin.com/in/eva-hlavsova-20a243b/',
			img: imgEva,
			imgWebP: imgEvaWebP,
		},
		{
			slug: 'honza',
			link: 'https://www.linkedin.com/in/jan-hlavsa-240034b/',
			img: imgHonza,
			imgWebP: imgHonzaWebP,
		},
		{
			slug: 'pavel',
			link: 'https://www.linkedin.com/in/pavel-%C5%A1t%C4%9Bp%C3%A1nek-58703945/',
			img: imgPavel,
			imgWebP: imgPavelWebP,
		},
	],
};
