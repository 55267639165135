import { useUserLogout } from '../contexts/UserContext';
import useFetch from './useFetch';

export default function useFetchAuth(fetcher) {
	const useFetcher = useFetch(async (logout, ...args) => {
		try {
			return await fetcher(...args);
		} catch (e) {
			if (e?.response?.status === 401) {
				e.isLoggedOut = true;
				logout(null, false);
			}
			throw e;
		}
	});

	function useFetcherAuth(initialData, ...args) {
		const logout = useUserLogout();
		const [data, loading, error, cancel] = useFetcher(initialData, logout, ...args);

		return error?.isLoggedOut ? [data, true, null, cancel] : [data, loading, error, cancel];
	}

	return useFetcherAuth;
}
