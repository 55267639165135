import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Picture } from '../../../components/ui';
import { useLanguage } from '../../../contexts/LocaleContext';
import isScreenWidthUp from '../../../utils/isScreenWidthUp';
import useWindowSize from '../../../utils/useWindowSize';
import blogPostShape from '../blogPostShape';

import styles from './BlogPostPreview.module.scss';

const PEREX_LENGTH_LG = 270;
const PEREX_LENGTH_XL = 345;

export default function BlogPostPreview({
	blogPostUrl,
	forcedMobile,
	handleTagClick,
	largePreview,
	post,
}) {
	const [t] = useTranslation();
	const language = useLanguage();
	const windowSize = useWindowSize();

	const {
		date = '',
		perex = '',
		previewImage,
		slug,
		title,
		topics = null,
		updated = false,
	} = post;
	const url = blogPostUrl(slug);

	let perexTruncated;
	if (isScreenWidthUp(windowSize.width, 'xl') && perex.length > PEREX_LENGTH_XL && !largePreview) {
		perexTruncated = `${perex.substring(0, PEREX_LENGTH_XL)}\u2026`;
	} else if (isScreenWidthUp(windowSize.width, 'lg') && !isScreenWidthUp(windowSize.width, 'xl') && perex.length > PEREX_LENGTH_LG && !largePreview) {
		perexTruncated = `${perex.substring(0, PEREX_LENGTH_LG)}\u2026`;
	} else {
		perexTruncated = perex;
	}

	return (
		<article className={(`${styles.wrap} ${forcedMobile ? styles.forcedMobile : ''}`).trim()}>
			<Link
				className={styles.postLink}
				to={url}
			/>
			<div className={(`${styles.header} ${forcedMobile ? styles.forcedMobile : ''} ${largePreview ? styles.largePreview : ''}`).trim()}>
				<div className={(`${styles.imageWrap} ${forcedMobile ? styles.forcedMobile : ''} ${largePreview ? styles.largePreview : ''}`).trim()}>
					<Picture
						alt={previewImage.alt ?? title}
						className={styles.image}
						lazyLoad
						width={395}
						height={274.3}
						src={previewImage.src}
						src2x={previewImage.src2x}
						src2xWebP={previewImage.src2xWebP}
						srcBreakpoint={previewImage.srcBreakpoint}
						srcBreakpoint2x={previewImage.srcBreakpoint2x}
						srcBreakpoint2xWebP={previewImage.srcBreakpoint2xWebP}
						srcBreakpointWebP={previewImage.srcBreakpointWebP}
						srcWebP={previewImage.srcWebP}
					/>
					<div className={(`${styles.badgeWrap} ${forcedMobile ? styles.forcedMobile : ''}`).trim()}>
						{topics?.[0] && (
							<button
								className={styles.badgeButton}
								onClick={() => handleTagClick(topics[0].slug, true)}
								type="button"
							>
								{topics[0].name}
							</button>
						)}
					</div>
				</div>
				<div className={(`${styles.info} ${forcedMobile ? styles.forcedMobile : ''} ${largePreview ? styles.largePreview : ''}`).trim()}>
					<time className={(`${styles.date} ${forcedMobile ? styles.forcedMobile : ''}`).trim()} dateTime={date}>
						{updated && (
							<>
								{t('blogPost.updated')}
								{' '}
							</>
						)}
						{new Date(date).toLocaleString(language, { dateStyle: 'medium' })}
					</time>
					<h3 className={(`${styles.title} ${forcedMobile ? styles.forcedMobile : ''}`).trim()}>
						{title}
					</h3>
				</div>
			</div>

			<div className={(`${styles.content} ${forcedMobile ? styles.forcedMobile : ''}`).trim()}>
				<p className={(`${styles.text} ${forcedMobile ? styles.forcedMobile : ''}`).trim()}>
					{perexTruncated}
				</p>
				<div className={(`${styles.badgeWrapMobile} ${forcedMobile ? styles.forcedMobile : ''}`).trim()}>
					{topics?.[0] && (
						<button
							className={styles.badgeButton}
							onClick={() => handleTagClick(topics[0].slug, true)}
							type="button"
						>
							{topics[0].name}
						</button>
					)}
				</div>
			</div>
		</article>
	);
}

BlogPostPreview.propTypes = {
	blogPostUrl: PropTypes.func.isRequired,
	forcedMobile: PropTypes.bool,
	handleTagClick: PropTypes.func.isRequired,
	largePreview: PropTypes.bool,
	post: blogPostShape.isRequired,
};

BlogPostPreview.defaultProps = {
	forcedMobile: false,
	largePreview: false,
};
