import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { changePortfolioName } from '../../../../utils/api';
import useRequestAuth from '../../../../utils/useRequestAuth';
import { validatePortfolioCreate } from '../../../../utils/validators';
import { TextField } from '../../../forms';
import { Alert, Button } from '../../../ui';

import styles from './PortfolioNameChange.module.scss';

export default function PortfolioNameChange({
	portfolioId,
	portfolioName,
	setCheckPortfolio,
}) {
	const [t] = useTranslation();
	const [portfolioError, setPortfolioError] = useState(false);
	const [success, setSuccess] = useState(false);
	const changePortfolioNameAuth = useRequestAuth(changePortfolioName);

	return (
		<Formik
			enableReinitialize
			initialValues={{
				portfolioName,
			}}
			validate={(values) => validatePortfolioCreate(values)}
			onSubmit={async (values, { setErrors }) => {
				setSuccess(false);
				try {
					await changePortfolioNameAuth(
						portfolioId,
						values.portfolioName,
					);
				} catch (e) {
					const fieldErrors = {};
					const errorMessage = e.responseJson?.message;
					if (typeof errorMessage === 'string') {
						if (errorMessage.indexOf(errorMessage.indexOf('name uniqueness')) !== -1) {
							fieldErrors.portfolioName = 'portfolio.new.error.unique';
						}
					}
					const hasFieldErrors = Object.keys(fieldErrors).length > 0;
					if (hasFieldErrors) {
						setErrors(fieldErrors);
					}
					setPortfolioError(!hasFieldErrors);
				}
				setSuccess(true);
				setCheckPortfolio(true);
			}}
		>
			{({
				errors, handleSubmit, isSubmitting, handleBlur, handleChange, touched, values,
			}) => (
				<form onSubmit={handleSubmit}>
					<div className={styles.wrap}>
						{portfolioError && (
							<Alert type="danger">
								{t('forms.error')}
							</Alert>
						)}
						<div className={styles.inputWrap}>
							<TextField
								id="portfolioName"
								name="portfolioName"
								onBlur={handleBlur}
								onChange={handleChange}
								error={
									errors.portfolioName
									&& touched.portfolioName
									&& t(errors.portfolioName)
								}
								label={t('portfolio.settings.name.label')}
								required
								type="text"
								value={values.portfolioName}
								minLength={1}
								maxLength={30}
							/>
						</div>
						<Button
							label={t('portfolio.settings.name.button')}
							isSubmit
							outline
							disabled={isSubmitting || values.portfolioName === portfolioName}
						/>
					</div>
					{success && (
						<Alert
							autoClose
							close
							type="success"
							onClose={() => setSuccess(false)}
						>
							{t('forms.success')}
						</Alert>
					)}
				</form>
			)}
		</Formik>
	);
}

PortfolioNameChange.propTypes = {
	portfolioId: PropTypes.number.isRequired,
	portfolioName: PropTypes.string.isRequired,
	setCheckPortfolio: PropTypes.func.isRequired,
};
