import React from 'react';
import PropTypes from 'prop-types';

import styles from './ShimmerTitle.module.scss';

export default function ShimmerTitle({
	lines,
	gap,
}) {
	return (
		<div className={styles.root}>
			{Array.from({ length: lines }).map((_, index) => (
				<div
					key={`shimmerTitle${index - lines}`}
					className={`${styles.row} ${index === lines - 1 ? styles.lastRow : ''} ${styles[`gap${gap}`]}`.trim()}
				/>
			))}
		</div>
	);
}

ShimmerTitle.propTypes = {
	lines: PropTypes.number,
	gap: PropTypes.number,
};

ShimmerTitle.defaultProps = {
	lines: 1,
	gap: 1,
};
