export default function formatAccountNumber(account, country) {
	if (account === undefined || account === null) {
		return null;
	}

	const PL_MASK = country === 'PL' ? '## #### #### #### #### #### ####' : '#### #### #### #### #### #### ####';

	const maskAccount = (accountNumber, mask) => {
		let maskRegEx = '';

		mask.split(' ').forEach((item) => {
			maskRegEx += `(\\S{0,${item.length}})`;
		});

		const accountMasked = accountNumber.match(new RegExp(maskRegEx));
		let newValue = [];

		accountMasked.forEach((item, index) => {
			if (index > 0 && item) {
				newValue = [...newValue, item];
			}
		});

		return newValue.join(' ');
	};

	switch (account.country ?? country) {
		case 'CZ':
		case 'SK':
			return [
				account.prefix ? `${account.prefix}-` : '',
				account.number ?? '',
				account.bankCode ? `/${account.bankCode}` : '',
			].join('');
		case 'PL':
			return maskAccount((account.iban ?? '').substring(country === 'PL' ? 2 : 0), PL_MASK);
		default:
			return account.iban ?? '';
	}
}
