import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { TextField } from '../../../components/forms';
import { Col, Row } from '../../../components/layout';
import {
	Alert,
	Button,
	ExternalLink,
	Modal,
} from '../../../components/ui';
import { fetchProfileTwoFactorAuthentication, set2fa } from '../../../utils/api';
import useFetchAuth from '../../../utils/useFetchAuth';
import useRequestAuth from '../../../utils/useRequestAuth';

import styles from './TwoFactorAuthenticationControl.module.scss';

const useFetchProfileTwoFactorAuthentication = useFetchAuth(
	(isModalOpen) => (isModalOpen ? fetchProfileTwoFactorAuthentication() : Promise.resolve(null)),
);

export default function TwoFactorAuthenticationControl() {
	const [error, setError] = useState(false);
	const [activated, setActivated] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);
	const [twoFactor, loading] = useFetchProfileTwoFactorAuthentication(null, isModalOpen);
	const set2faAuth = useRequestAuth(set2fa);
	const [t] = useTranslation();

	useEffect(() => {
		setError(false);
		setActivated(false);
		setDisabled(false);
	}, [isModalOpen]);

	const handleDisable = async () => {
		try {
			await set2faAuth(false);
		} catch (e) {
			setError(true);
			return;
		}
		setDisabled(true);
		setTimeout(() => setModalOpen(false), 3000);
	};

	return (
		<div className={styles.root}>
			<Button
				onClick={() => setModalOpen(true)}
				label={t('account.twoFactorAuthenticationControl.title')}
				outline
			/>
			{twoFactor !== null && !loading && (
				<Modal
					isVisible={isModalOpen}
					onClose={() => setModalOpen(false)}
					size="large"
					title={t('account.twoFactorAuthenticationControl.title')}
				>
					{twoFactor.twoFactorEnabled ? (
						<div className={styles.active}>
							<p className={styles.activeText}>
								{t('account.twoFactorAuthenticationControl.active')}
							</p>
							<Button
								disabled={disabled}
								label={t('account.twoFactorAuthenticationControl.disable')}
								onClick={handleDisable}
							/>
							{disabled && (
								<div className={styles.alert}>
									<Alert
										type="success"
									>
										{t('account.twoFactorAuthenticationControl.disabled')}
									</Alert>
								</div>
							)}
							{error && (
								<div className={styles.alert}>
									<Alert type="danger">
										{t('forms.error')}
									</Alert>
								</div>
							)}
						</div>
					) : (
						<Formik
							initialValues={{
								code: '',
							}}
							onSubmit={async (values) => {
								setError(false);
								try {
									await set2faAuth(true, values.code);
								} catch (e) {
									setError(true);
									return;
								}
								setActivated(true);
							}}
						>
							{({
								handleChange, handleBlur, handleSubmit, isSubmitting, values,
							}) => (
								<form onSubmit={handleSubmit} className={styles.modal}>
									<Row>
										<Col lg={6}>
											<img
												src={twoFactor.twoFactorSecretQrCode}
												className={styles.qr}
												alt={twoFactor.twoFactorSecret}
											/>
											<p className={styles.qrCode}>{twoFactor.twoFactorSecret}</p>
										</Col>
										<Col lg={6}>
											<div className={styles.text}>
												{t('account.twoFactorAuthenticationControl.info.title')}
												<ul className={styles.list}>
													<li>
														<Trans i18nKey="account.twoFactorAuthenticationControl.info.line1">
															<ExternalLink
																to="https://apps.apple.com/us/app/google-authenticator/id388497605"
																blank
															/>
															<ExternalLink
																to="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
																blank
															/>
														</Trans>
													</li>
													<li>
														{t('account.twoFactorAuthenticationControl.info.line2')}
													</li>
													<li>
														{t('account.twoFactorAuthenticationControl.info.line3')}
													</li>
													<li>
														{t('account.twoFactorAuthenticationControl.info.line4')}
													</li>
												</ul>
											</div>
										</Col>
									</Row>
									<div className={styles.form}>
										<Row middle>
											<Col lg={6}>
												<TextField
													autocomplete="one-time-code"
													onBlur={handleBlur}
													onChange={handleChange}
													error={error ? t('account.twoFactorAuthenticationControl.code.error') : ''}
													id="code"
													name="code"
													label={t('account.twoFactorAuthenticationControl.code.label')}
													required
													type="text"
													inputmode="numeric"
													pattern="[0-9]*"
													value={values.code}
												/>
											</Col>
											<Col lg={6}>
												<Button
													label={t('account.twoFactorAuthenticationControl.activate')}
													disabled={isSubmitting || activated}
													isSubmit
												/>
											</Col>
										</Row>
									</div>
									{activated && (
										<div className={styles.alert}>
											<Alert
												type="success"
											>
												{t('account.twoFactorAuthenticationControl.activated')}
											</Alert>
										</div>
									)}
								</form>
							)}
						</Formik>
					)}
				</Modal>
			)}
		</div>
	);
}
