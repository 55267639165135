import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { paymentConfirmation } from '../../../utils/api';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import useRequestAuth from '../../../utils/useRequestAuth';
import { validateOnboardingPayment } from '../../../utils/validators';
import { CheckboxField } from '../../forms';
import { Alert, Button, TopUpModal } from '../../ui';
import TopUp from '../TopUp';

import styles from './OnboardingPayment.module.scss';

export default function OnboardingPayment({
	childId,
	isPension,
	portfolioId,
	setCheckOnboarding,
}) {
	const paymentConfirmationAuth = useRequestAuth(paymentConfirmation);
	const [error, setError] = useState(false);
	const [t] = useTranslation();
	const trackGTMEvent = useTrackGTMEvent();

	return (
		<>
			{isPension && (
				<div className={styles.wrap}>
					<TopUpModal
						defaultPortfolioId={portfolioId}
						employer
					/>
				</div>
			)}

			<TopUp
				childId={childId}
				defaultPortfolioId={portfolioId}
			/>
			<Formik
				initialValues={{
					sentMoney: false,
				}}
				validate={(values) => validateOnboardingPayment(values)}
				onSubmit={async () => {
					setError(false);

					try {
						await paymentConfirmationAuth(childId);
					} catch {
						setError(true);
						return;
					}

					trackGTMEvent('conversionFunnel', {
						eventCategory: 'checkedSendingInitialPayment',
						eventAction: '',
						eventLabel: '',
					});
					setCheckOnboarding(true);
				}}
			>
				{({
					errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values,
				}) => (
					<form onSubmit={handleSubmit} className={styles.root}>
						{error && (
							<Alert type="danger">
								{t('forms.error')}
							</Alert>
						)}
						<CheckboxField
							onBlur={handleBlur}
							onChange={handleChange}
							checked={values.sentMoney}
							error={
								errors.sentMoney
								&& touched.sentMoney
								&& t(errors.sentMoney)
							}
							label={t('onboarding.steps.payment.sentMoney.label')}
							id="sentMoney"
							name="sentMoney"
							required
						/>
						<p className={styles.text}>
							{t('onboarding.steps.payment.emailInfo')}
						</p>
						<Button
							label={t('onboarding.steps.payment.submit')}
							disabled={!values.sentMoney || isSubmitting}
							isSubmit
						/>
					</form>
				)}
			</Formik>
		</>
	);
}

OnboardingPayment.propTypes = {
	childId: PropTypes.number,
	isPension: PropTypes.bool,
	portfolioId: PropTypes.number,
	setCheckOnboarding: PropTypes.func.isRequired,
};

OnboardingPayment.defaultProps = {
	childId: null,
	isPension: false,
	portfolioId: null,
};
