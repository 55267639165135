import {
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';

import useFetch from './useFetch';

const noop = () => {};

export function useFetchWithHardReload(fetcher) {
	const useFetcher = useFetch((counter, ...args) => fetcher(...args));

	function useFetcherWithReload(initialData, ...args) {
		const [counter, setCounter] = useState(0);
		const [data, loading, error, cancel] = useFetcher(initialData, counter, ...args);

		const reload = useCallback(() => {
			setCounter((lastCounter) => lastCounter + 1);
		}, []);

		return [data, loading, error, cancel, loading ? noop : reload];
	}

	return useFetcherWithReload;
}

export default function useFetchWithReload(fetcher) {
	const useFetcher = useFetchWithHardReload(fetcher);

	function useFetcherWithReload(initialData, ...args) {
		const [[lastData, lastValid], setLast] = useState([null, false]);
		const initialRef = useRef(true);

		useEffect(() => {
			if (initialRef.current) {
				initialRef.current = false;
			} else {
				setLast([null, false]);
			}
		}, [fetcher, ...args]);

		const [data, loading, error, cancel, doReload] = useFetcher(initialData, ...args);

		const reload = useCallback((hardReload = false) => {
			setLast(hardReload || error !== null ? [null, false] : [data, true]);
			doReload();
		}, [data, error, doReload]);

		if (loading && lastValid) {
			return [lastData, false, null, cancel, noop];
		}

		return [data, loading, error, cancel, loading ? noop : reload];
	}

	return useFetcherWithReload;
}
