import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TextField } from '../../forms';
import {
	Col,
	Container,
	Row,
	Section,
} from '../../layout';
import FaqListItem from './FaqListItem';

import styles from './FaqList.module.scss';

function splitFaqSection(section, parts) {
	const shift = section.faqs.length >= parts ? 1 : 0;
	const partLength = (section.faqs.length + shift) / parts;

	return [...Array(parts)].map((_, i) => ({
		...section,
		title: i === 0 ? section.title : null,
		faqs: section.faqs.slice(
			i > 0 ? Math.ceil(partLength * i) - shift : 0,
			i < parts - 1 ? Math.ceil(partLength * (i + 1)) - shift : section.faqs.length,
		),
	}));
}

function splitFaqSections(sections, parts) {
	const lengthPartialSums = sections.reduce((acc, { faqs }, i) => {
		acc[i] = (i > 0 ? acc[i - 1] : 0) + faqs.length + 1;
		return acc;
	}, Array(sections.length));

	const partLength = sections.reduce((acc, { faqs }) => acc + faqs.length + 1, 0) / parts;
	const splitIndexes = [...Array(parts - 1)].map((_, i) => {
		const partPartialSum = Math.ceil(partLength * (i + 1));
		const index = lengthPartialSums.findIndex((partialSum) => partialSum >= partPartialSum);
		return index >= 0 ? index + 1 : lengthPartialSums.length;
	});

	return [...Array(parts)].map((_, i) => sections.slice(
		i > 0 ? splitIndexes[i - 1] : 0,
		i < parts - 1 ? splitIndexes[i] : sections.length,
	));
}

export default function FaqList({
	id,
	scrollToOpen,
	sectionRows,
	sections,
	spacy,
}) {
	const [t] = useTranslation();
	const [openItem, setOpenItem] = useState(null);
	const [search, setSearch] = useState('');

	const handleSearchChange = (e) => {
		setSearch(e.target.value);
	};

	const query = search.toLowerCase();
	const filteredSections = query !== '' ? sections.map((section) => ({
		...section,
		faqs: section.faqs.filter(
			(item) => item.question.toLowerCase().indexOf(query) !== -1
				|| item.answer.toLowerCase().indexOf(query) !== -1,
		),
	})).filter(({ faqs }) => faqs.length > 0) : sections;
	const rows = sectionRows
		? filteredSections.map((section) => splitFaqSection(section, 3).map((sectionPart) => [sectionPart]))
		: [splitFaqSections(filteredSections, 3)];

	return (
		<Section bordered condensed id={id}>
			<Container>
				<div className={styles.searchWrap}>
					<TextField
						label="faqSearch"
						id="faqSearch"
						name="faqSearch"
						placeholder={t('faq.search')}
						isLabelHidden
						onChange={handleSearchChange}
					/>
				</div>

				<div className={styles.body}>
					{rows.map((row, i) => [row, i]).map(([row, i]) => (
						<Row key={i} start>
							{row.map((column, j) => [column, j]).map(([column, j]) => column.length > 0 && (
								<Col key={j} lg={4}>
									{column.map((section) => (
										<div
											key={section.id}
											className={sectionRows ? styles.sectionPart : styles.section}
										>
											{section.title && (
												<h4 className={styles.sectionTitle}>{section.title}</h4>
											)}
											{section.faqs.map((faq) => (
												<FaqListItem
													key={faq.id}
													answer={faq.answer}
													id={faq.id}
													openItem={openItem}
													question={faq.question}
													scrollToOpen={scrollToOpen}
													setOpenItem={setOpenItem}
													spacy={spacy}
												/>
											))}
										</div>
									))}
								</Col>
							))}
						</Row>
					))}
				</div>
			</Container>
		</Section>
	);
}

FaqList.propTypes = {
	id: PropTypes.string,
	scrollToOpen: PropTypes.bool,
	sectionRows: PropTypes.bool,
	sections: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			title: PropTypes.string.isRequired,
			faqs: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.number.isRequired,
					answer: PropTypes.string.isRequired,
					question: PropTypes.string.isRequired,
				}),
			).isRequired,
		}),
	).isRequired,
	spacy: PropTypes.bool,
};

FaqList.defaultProps = {
	id: null,
	sectionRows: false,
	scrollToOpen: false,
	spacy: false,
};
