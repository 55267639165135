import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';

import { useLanguage } from '../../../contexts/LocaleContext';
import { fromISODate } from '../../../utils/date';
import isScreenWidthUp from '../../../utils/isScreenWidthUp';
import { toInt } from '../../../utils/number';
import useFormatNumber from '../../../utils/useFormatNumber';
import useWindowSize from '../../../utils/useWindowSize';
import {
	Container,
	Section,
} from '../../layout';
import { Loader } from '../../ui';

import styles from './ProductChart.module.scss';

function roundYAxis(value) {
	if (value < 1000) {
		return Math.round(value);
	}

	const log = Math.floor(Math.log10(value));
	const quotient = 10 ** (log - 2);
	return Math.round(value / quotient) * quotient;
}

const chartOptions = (formatCurrency, formatDate, min, max, tooltipValue, evaluation) => ({
	chart: {
		animations: {
			enabled: false,
		},
		toolbar: {
			show: false,
		},
		zoom: {
			enabled: false,
		},
	},
	colors: ['#48b958', '#48b958'],
	dataLabels: {
		enabled: false,
	},
	fontFamily: 'Rajdhani, sans-serif',
	legend: {
		show: false,
	},
	stroke: {
		show: !evaluation,
		width: 3,
	},
	plotOptions: {
		bar: {
			colors: {
				ranges: [{
					from: -9999999,
					to: 0,
					color: '#99556a',
				}],
			},
		},
	},
	tooltip: {
		custom: ({ series, dataPointIndex, w: { config } }) => {
			const value = series[0][dataPointIndex];
			const dataPoint = config.series[0].data[dataPointIndex];

			return (
				`<div>
						<div class="apexcharts-tooltip-title">
							${formatDate(dataPoint.x)} ${formatDate(dataPoint.toDate) !== formatDate(dataPoint.x) ? `- ${formatDate(dataPoint.toDate)}` : ''}
						</div>
						<div class="apexcharts-tooltip-series-group ${styles.tooltipGroup}">
							<div class="apexcharts-tooltip-text">
								${tooltipValue}:&nbsp;<strong>${formatCurrency(value)}</strong>
							</div>
						</div>
					</div>`
			);
		},
		marker: {
			show: false,
		},
	},
	xaxis: {
		type: 'datetime',
		labels: {
			rotateAlways: true,
			minHeight: 100,
			offsetX: 5,
			style: {
				colors: '#999',
				fontSize: '13px',
			},
			formatter(value, timestamp) {
				return formatDate(new Date(timestamp));
			},
		},
		axisTicks: {
			show: false,
		},
		tooltip: {
			enabled: false,
		},
	},
	yaxis: {
		tickAmount: Math.min(5, max - min),
		min,
		max,
		forceNiceScale: true,
		labels: {
			style: {
				color: '#999',
				fontSize: '15px',
			},
			formatter(val) {
				return val !== null ? formatCurrency(roundYAxis(val)) : null;
			},
		},
	},
	annotations: {
		position: 'back',
	},
});

function ProductChart({
	currency,
	data,
	evaluation,
	onlyChart,
}) {
	const [t] = useTranslation();
	const language = useLanguage();
	const formatCurrency = useFormatNumber({ style: 'currency', currency: currency?.code });
	const formatDate = (date) => date.toLocaleDateString(language);
	const windowSize = useWindowSize();
	const periodIncrement = isScreenWidthUp(windowSize.width, 'md') ? 30 : 15;
	const [period, setPeriod] = useState(periodIncrement);
	const periodData = period < data.length
		? data.slice(data.length - period)
		: data;
	let min = null;
	let max = 0;
	let minEvaluation = null;
	let maxEvaluation = 0;

	const handlePeriod = (loadAll = false) => {
		setPeriod(loadAll ? data.length : (oldPeriod) => (
			Math.min(oldPeriod + periodIncrement, data.length)
		));
	};

	const lineChartData = periodData ? periodData.map((day) => {
		const value = day.totalValue !== null ? toInt(day.totalValue) : null;

		if (value !== null || day.totalValue) {
			const totalValue = day.totalValue ?? 0;
			const minValue = value !== null ? value : 0;
			const maxValue = value !== null ? value + Math.max(0, -totalValue) : Math.abs(totalValue);

			if (min === null || minValue < min) {
				min = minValue;
			}

			if (maxValue > max) {
				max = maxValue;
			}
		}
		return {
			x: fromISODate(day.toDate),
			y: value,
			toDate: fromISODate(day.toDate),
		};
	}) : [];

	const barChartDataEvaluation = periodData ? periodData.slice(1).map((day) => {
		const value = day.netGrowth !== null ? toInt(day.netGrowth) : null;

		if (value !== null) {
			if (minEvaluation === null || value < minEvaluation) {
				minEvaluation = value;
			}

			if (value > maxEvaluation) {
				maxEvaluation = value;
			}
		}
		return {
			x: fromISODate(day.toDate),
			y: value,
			toDate: fromISODate(day.toDate),
		};
	}) : [];

	min = min ?? 0;
	const chartMin = Math.round(Math.max(0, min - Math.max(min / 20, 1)));
	const chartMax = Math.round(max + Math.max(max / 20, 1));

	minEvaluation = minEvaluation ?? 0;
	const chartMinEvaluation = Math.round(minEvaluation - Math.max(Math.abs(minEvaluation / 20), 1));
	const chartMaxEvaluation = Math.round(maxEvaluation + Math.max(Math.abs(maxEvaluation / 20), 1));

	const tooltipValue = t(evaluation ? 'dashboard.chart.tooltip.evaluation' : 'dashboard.chart.tooltip.value');

	const chart = (
		<div className={`${styles.root} ${onlyChart ? styles.rootOnlyChart : ''}`.trim()}>
			{data ? (
				<>
					<div className={styles.chartWrap}>
						<div className={styles.chart}>
							<button
								className={styles.loadButton}
								type="button"
								onClick={() => handlePeriod()}
								disabled={period >= data.length}
							>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" width="10" height="12" viewBox="0 0 10 12">
									<path fill="currentColor" d="m5.7 10.4-.4.6c-.3.2-.6.2-.8 0L.2 6.4a.6.6 0 0 1 0-.8L4.5 1c.2-.2.5-.2.8 0l.4.6c.3.2.3.6 0 .8L3 5h6.5c.3 0 .5.2.5.5v.8c0 .3-.2.5-.5.5H3l2.7 2.7c.3.2.3.6 0 .8Z" />
								</svg>
							</button>
							<Chart
								height={300}
								options={chartOptions(
									formatCurrency,
									formatDate,
									evaluation ? chartMinEvaluation : chartMin,
									evaluation ? chartMaxEvaluation : chartMax,
									tooltipValue,
									evaluation,
								)}
								series={evaluation ? [
									{
										name: 'bar',
										type: 'bar',
										data: barChartDataEvaluation,
									},
								] : [
									{
										name: 'line',
										type: 'line',
										data: lineChartData,
									},
								]}
								type={evaluation ? 'bar' : 'line'}
							/>
						</div>
					</div>
					{!onlyChart && (
						<>
							<div className={styles.legendTitle}>
								{t('historyChart.legend.title')}
							</div>
							<div className={styles.legendList}>
								{evaluation ? (
									<>
										<div className={styles.legendListItem}>
											<span className={`${styles.legendCircle} ${styles.legendCircleValue}`} />
											{t('historyChart.legend.evaluation')}
										</div>
										<div className={styles.legendListItem}>
											<span className={`${styles.legendCircle} ${styles.legendCircleDecrease}`} />
											{t('historyChart.legend.devaluation')}
										</div>
									</>
								) : (
									<>
										<div className={styles.legendListItem}>
											<span className={`${styles.legendCircle} ${styles.legendCircleValue}`} />
											{t('historyChart.legend.value')}
										</div>
									</>
								)}
							</div>
						</>
					)}
				</>
			) : (
				<div className={styles.loading}>
					<p>
						{t('historyChart.loading')}
					</p>
					<Loader />
				</div>
			)}
		</div>
	);

	if (onlyChart) return chart;

	return (
		<Section
			bordered
			condensed
		>
			<Container>
				{chart}
			</Container>
		</Section>
	);
}

ProductChart.propTypes = {
	currency: PropTypes.shape({
		code: PropTypes.string.isRequired,
		codeShort: PropTypes.string.isRequired,
		codeShortBefore: PropTypes.bool.isRequired,
	}),
	data: PropTypes.arrayOf(
		PropTypes.shape({
			toDate: PropTypes.string,
			netGrowth: PropTypes.number,
			netGrowthRatio: PropTypes.number,
			totalValue: PropTypes.number,
		}).isRequired,
	).isRequired,
	onlyChart: PropTypes.bool,
	evaluation: PropTypes.number.isRequired,
};

ProductChart.defaultProps = {
	currency: null,
	onlyChart: false,
};

export default memo(ProductChart);
