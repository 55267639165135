import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Container } from '../../layout';

import styles from './HowItWorksPrinciples.module.scss';

export default function HowItWorksPrinciples() {
	const [t] = useTranslation();

	return (
		<Container>
			<h3 className={styles.title}>
				{t('howItWorksPrinciples.title')}
			</h3>
			<div className={styles.wrap}>
				{Object.keys(t('howItWorksPrinciples.list', { returnObjects: true })).map((item, index) => (
					<div key={item} className={styles.box}>
						<p className={styles.subtitle}>
							<span className={styles.number}>
								{index + 1}
								{'.'}
							</span>
							{t(`howItWorksPrinciples.list.${item}.title`)}
						</p>
						<p className={styles.text}>
							<Trans i18nKey={`howItWorksPrinciples.list.${item}.text`}>
								<strong />
							</Trans>
						</p>
					</div>
				))}
			</div>
		</Container>
	);
}
