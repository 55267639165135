import React from 'react';
import PropTypes from 'prop-types';

import useFormatNumber from '../../../utils/useFormatNumber';

import styles from './InvestingPriceChange.module.scss';

export default function InvestingPriceChange({
	arrow,
	ratio,
	value,
}) {
	const formatOptions = ratio
		? { style: 'percent', fractionDigits: 2 }
		: { style: 'currency', currency: 'CZK', fractionDigits: 0 };
	const formatNumber = useFormatNumber(formatOptions);

	let typeClassName = '';
	let sign = '';
	if (value > 0) {
		typeClassName = styles.priceFlowPositive;
		sign = '+';
	} else if (value < 0) {
		typeClassName = styles.priceFlowNegative;
		sign = '-';
	}

	return (
		<span className={`${styles.priceFlow} ${typeClassName}`}>
			{arrow && value !== 0 && (
				<span className={styles.iconPriceFlow}>
					<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
						<path
							fill="currentColor"
							d="M1.55 2.42V1.25c0-.5.4-.9.9-.89l10.2-.01c.5 0 .9.4.9.89l-.02 10.21c0 .5-.4.9-.9.9h-1.16c-.5 0-.9-.42-.89-.92l.16-6.18-7.56 7.56a.89.89 0 0 1-1.26 0l-.84-.84a.89.89 0 0 1 0-1.26l7.56-7.56-6.18.16a.88.88 0 0 1-.91-.9Z"
						/>
					</svg>
				</span>
			)}
			{sign}
			&nbsp;
			{formatNumber(value < 0 ? -value : value)}
		</span>
	);
}

InvestingPriceChange.propTypes = {
	arrow: PropTypes.bool,
	ratio: PropTypes.bool,
	value: PropTypes.number.isRequired,
};

InvestingPriceChange.defaultProps = {
	arrow: false,
	ratio: false,
};
