import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { useLanguage } from '../../../contexts/LocaleContext';
import {
	closeAccount,
	fetchUserCurrency,
} from '../../../utils/api';
import useFetchAuth from '../../../utils/useFetchAuth';
import useRequestAuth from '../../../utils/useRequestAuth';
import { RadioList, TextField } from '../../forms';
import { Alert, Button, Modal } from '../../ui';

import styles from './QuestionnaireAccountClosure.module.scss';

const useFetchUserCurrency = useFetchAuth(fetchUserCurrency);

export default function QuestionnaireAccountClosure({
	childId,
	isVisible,
	onClose,
	onSuccess,
	userCurrency,
}) {
	const language = useLanguage();
	const [t] = useTranslation();
	const [cancelError, setCancelError] = useState(false);
	const [has2fa, setHas2fa] = useState(false);
	const [currencies] = useFetchUserCurrency(null, language);
	const closeAccountAuth = useRequestAuth(closeAccount);

	if (currencies === null) {
		return null;
	}

	const currencySelect = currencies.length > 1;
	const currencyDefault = currencies.find(({ code }) => code === userCurrency) ?? currencies[0] ?? null;
	const currencyDefaultValue = currencyDefault !== null ? currencyDefault.code : null;
	const currencyOptions = currencies.map(({ code }) => ({
		label: code,
		value: code,
	}));

	return (
		<Formik
			initialValues={{
				twoFactorCode: '',
				currency: currencySelect ? currencyDefaultValue : null,
			}}
			onSubmit={async (values, { setErrors }) => {
				const currency = currencySelect ? values.currency : currencyDefaultValue;
				if (currency === null) {
					return;
				}

				let show2fa = false;
				setCancelError(false);
				try {
					await closeAccountAuth(
						has2fa ? values.twoFactorCode : null,
						currency,
						childId,
					);
				} catch (e) {
					const fieldErrors = {};
					const errorMessage = e.responseJson?.message;
					if (typeof errorMessage === 'string' && errorMessage.indexOf('Two factor authentication code needed') !== -1) {
						setHas2fa(true);
						show2fa = true;
					}

					if (typeof errorMessage === 'string' && errorMessage.indexOf('Bad two factor authentication code') !== -1) {
						fieldErrors.twoFactorCode = 'forms.twoFactorAuthentication.error';
					}

					const hasFieldErrors = Object.keys(fieldErrors).length > 0;
					if (hasFieldErrors) {
						setErrors(fieldErrors);
					}
					setCancelError(!hasFieldErrors && !show2fa);
					return;
				}

				setHas2fa(false);
				onSuccess();
			}}
		>
			{({
				errors,
				handleBlur,
				handleChange,
				handleSubmit,
				isSubmitting,
				touched,
				values,
			}) => (
				<form onSubmit={handleSubmit}>
					<Modal
						isVisible={isVisible}
						onClose={onClose}
						title={t('clientCheck.questionnaire.aml.closeAccount.title')}
					>
						<div className={styles.modal}>
							{cancelError && (
								<Alert type="danger">
									{t('forms.error')}
								</Alert>
							)}
							{values.currency !== null && values.currency !== userCurrency && (
								<Alert type="warning">
									{t('account.withdrawals.withdrawals.currencyWarning')}
								</Alert>
							)}
							<p className={styles.modalText}>
								{t('clientCheck.questionnaire.aml.closeAccount.text')}
							</p>
							{currencySelect && (
								<RadioList
									name="currency"
									id="withdrawalCurrencyCancel"
									onChange={handleChange}
									options={currencyOptions}
									value={values.currency}
								/>
							)}
							{has2fa && (
								<>
									<TextField
										autocomplete="one-time-code"
										autoFocus={has2fa}
										onBlur={handleBlur}
										onChange={handleChange}
										error={
											errors.twoFactorCode
												&& touched.twoFactorCode
												&& t(errors.twoFactorCode)
										}
										id="twoFactorCode"
										name="twoFactorCode"
										label={t('forms.fields.twoFactorAuthentication.title')}
										required={has2fa}
										type="text"
										inputMode="numeric"
										pattern="[0-9]*"
										value={values.twoFactorCode}
									/>
									<Button
										disabled={isSubmitting}
										isSubmit
										label={t('forms.fields.twoFactorAuthentication.button')}
									/>
								</>
							)}
							{!has2fa && (
								<Button
									disabled={isSubmitting}
									isSubmit
									label={t('clientCheck.questionnaire.aml.closeAccount.button')}
								/>
							)}
						</div>
					</Modal>
				</form>
			)}
		</Formik>
	);
}

QuestionnaireAccountClosure.propTypes = {
	childId: PropTypes.number,
	isVisible: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	userCurrency: PropTypes.string.isRequired,
};

QuestionnaireAccountClosure.defaultProps = {
	childId: null,
};
