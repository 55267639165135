import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
	Container,
	Section,
} from '../../../components/layout';
import CampaignVocabularyItem from './CampaignVocabularyItem';

import styles from './CampaignVocabulary.module.scss';

export default function CampaignVocabulary({
	howItWorksUrl,
	isTypeFondee,
}) {
	const [t] = useTranslation();
	const [openItem, setOpenItem] = useState(null);
	const context = isTypeFondee ? 'fondee' : 'account';
	const vocabularyObject = isTypeFondee
		? 'campaign.explanation.fondee.vocabulary.collapse'
		: 'campaign.explanation.account.vocabulary.collapse';

	return (
		<Section condensed>
			<Container center>
				<div className={styles.root}>
					<h2 className={styles.title}>
						{t(`campaign.explanation.${context}.title`)}
					</h2>
					<p className={styles.paragraph}>
						{t(`campaign.explanation.${context}.info`)}
					</p>
					<h3 className={styles.title}>
						{t(`campaign.explanation.${context}.vocabulary.title`)}
					</h3>
					<div className={styles.vocabulary}>
						{Object.keys(t(vocabularyObject, { returnObjects: true })).map((item) => (
							<CampaignVocabularyItem
								key={item}
								description={`${vocabularyObject}.${item}.description`}
								id={item}
								howItWorksUrl={howItWorksUrl}
								name={`${vocabularyObject}.${item}.name`}
								openItem={openItem}
								setOpenItem={setOpenItem}
							/>
						))}
					</div>
				</div>
			</Container>
		</Section>
	);
}

CampaignVocabulary.propTypes = {
	howItWorksUrl: PropTypes.string.isRequired,
	isTypeFondee: PropTypes.bool,
};

CampaignVocabulary.defaultProps = {
	isTypeFondee: false,
};
