import React from 'react';
import PropTypes from 'prop-types';

import styles from './Mark.module.scss';

export default function Mark({
	children,
	isDirectionRow,
	type,
}) {
	return (
		<div className={`${styles.root} ${isDirectionRow ? styles.rootRow : ''}`.trim()}>
			<i className={styles.icon}>
				{type === 'check' && (
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={22} height={18} viewBox="0 0 22 18">
						<path stroke="#E94549" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="m2.04 9.98 7.17 5.54L19.96 1.67" />
					</svg>
				)}
			</i>
			{children}
		</div>
	);
}

Mark.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	isDirectionRow: PropTypes.bool,
	type: PropTypes.oneOf(['check', 'cons', 'pros']),
};

Mark.defaultProps = {
	children: null,
	isDirectionRow: false,
	type: 'check',
};
