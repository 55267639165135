import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Container, SectionAdaptable } from '../../../components/layout';
import { SectionAnchor } from '../../../components/ui';

import styles from './InvestingHero.module.scss';

const HERO_OFFSET = -55;

export default function InvestingHero({
	investingHeroAnchor,
}) {
	const [t] = useTranslation();

	return (
		<SectionAdaptable
			breakpoint="md"
			offset={HERO_OFFSET}
			bordered
		>
			<Container center>
				<h1 className={styles.title}>
					{t('investing.hero.title')}
				</h1>
				<h2 className={styles.subtitle}>
					{t('investing.hero.subtitle')}
				</h2>
				<p className={styles.text}>
					{t('investing.hero.text')}
				</p>
			</Container>
			<div className={styles.bubbles}>
				<div className={`${styles.bubble} ${styles.bubble1}`.trim()} />
				<div className={`${styles.bubble} ${styles.bubble2}`.trim()} />
				<div className={`${styles.bubble} ${styles.bubble3}`.trim()} />
			</div>
			<SectionAnchor
				title={t('investing.hero.anchor')}
				target={investingHeroAnchor}
			/>
		</SectionAdaptable>
	);
}

InvestingHero.propTypes = {
	investingHeroAnchor: PropTypes.string.isRequired,
};
