import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useLanguage } from '../../../contexts/LocaleContext';
import {
	clientCheckSaveAMLAnswer,
	fetchClientCheckAMLQuestionnaire,
} from '../../../utils/api';
import { useFetchWithHardReload } from '../../../utils/useFetchWithReload';
import useRequestAuth from '../../../utils/useRequestAuth';
import { Button } from '../../ui';
import Questionnaire from '../Questionnaire';

import styles from './AmlQuestionnaire.module.scss';

const useFetchAMLQuestionnaire = useFetchWithHardReload(fetchClientCheckAMLQuestionnaire);

const initialData = {
	questionnaire: [],
	lastAnsweredQuestionId: null,
	skippedQuestionIds: [],
	notConsistent: false,
	usPerson: false,
};

export default function AmlQuestionnaire({
	blogPostEsgRegulationUrl,
	childId,
	closeAccount,
	disabled,
	faqUrl,
	overwriteEsgPreferenceAnswers,
	reloadClientCheck,
	resetEsgPreferenceQuestionnaire,
}) {
	const language = useLanguage();
	const [t] = useTranslation();
	const [started, setStarted] = useState(false);
	const [
		questionnaireData,
		loading,
		error,,
		reloadQuestionnaire,
	] = useFetchAMLQuestionnaire(initialData, language, childId);
	const saveAMLAnswerAuth = useRequestAuth(clientCheckSaveAMLAnswer);

	if (loading || error) {
		return null;
	}

	const handleReloadQuestionnaire = () => {
		reloadQuestionnaire();
		reloadClientCheck();
	};

	return (
		<>
			<p className={styles.text}>
				{t('clientCheck.questionnaire.aml.text')}
			</p>
			{!started ? (
				<Button label={t('clientCheck.questionnaire.aml.start')} onClick={() => setStarted(true)} outline />
			) : (
				<Questionnaire
					blogPostEsgRegulationUrl={blogPostEsgRegulationUrl}
					childId={childId}
					closeAccount={closeAccount}
					disabled={disabled}
					faqUrl={faqUrl}
					isStarted={started}
					overwriteEsgPreferenceAnswers={overwriteEsgPreferenceAnswers}
					questionnaireData={questionnaireData}
					reloadQuestionnaire={handleReloadQuestionnaire}
					resetEsgPreferenceQuestionnaire={resetEsgPreferenceQuestionnaire}
					saveQuestionnaireAnswer={saveAMLAnswerAuth}
					type="aml"
				/>
			)}
		</>
	);
}

AmlQuestionnaire.propTypes = {
	blogPostEsgRegulationUrl: PropTypes.string,
	childId: PropTypes.number,
	closeAccount: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	faqUrl: PropTypes.string.isRequired,
	overwriteEsgPreferenceAnswers: PropTypes.func.isRequired,
	reloadClientCheck: PropTypes.func.isRequired,
	resetEsgPreferenceQuestionnaire: PropTypes.func.isRequired,
};

AmlQuestionnaire.defaultProps = {
	blogPostEsgRegulationUrl: null,
	childId: null,
	disabled: false,
};
