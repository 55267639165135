import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

import { updatePortfolioCurrency } from '../../../../utils/api';
import useRequestAuth from '../../../../utils/useRequestAuth';
import { OnChangeSubmit, RadioList } from '../../../forms';
import { Alert } from '../../../ui';

import styles from './PortfolioCurrencySelect.module.scss';

export default function PortfolioCurrencySelect({
	currencies,
	currencyChange,
	isChild,
	openTopUpModal,
	portfolioId,
	setCheckPortfolio,
	setCurrencyChange,
}) {
	const updatePortfolioCurrencyAuth = useRequestAuth(updatePortfolioCurrency);
	const [error, setError] = useState(false);
	const [t] = useTranslation();

	if (currencies === null || currencies.length <= 1) return null;

	const currencyOptions = currencies.map(({ code }) => ({
		label: code,
		value: code,
	}));

	return (
		<div className={styles.root}>
			<h2 className={styles.title}>{t('portfolio.settings.currency.title')}</h2>
			<Formik
				initialValues={{ currency: currencies.find(({ selected }) => selected)?.code ?? null }}
				onSubmit={async (values) => {
					setError(false);
					setCurrencyChange(false);
					try {
						await updatePortfolioCurrencyAuth(
							portfolioId,
							values.currency,
						);
					} catch {
						setError(true);
						return;
					}
					setCurrencyChange(true);
					setCheckPortfolio(true);
				}}
			>
				{({
					handleChange, handleSubmit, isSubmitting, values,
				}) => (
					<form onSubmit={handleSubmit}>
						<OnChangeSubmit />
						{error && (
							<Alert type="danger">
								{t('forms.error')}
							</Alert>
						)}
						<RadioList
							disabled={isChild || isSubmitting}
							id="portfolioCurrency"
							name="currency"
							onChange={handleChange}
							options={currencyOptions}
							value={values.currency}
						/>
						{currencyChange && (
							<Alert
								close
								type="success"
								onClose={() => setCurrencyChange(false)}
							>
								<Trans i18nKey="portfolio.settings.currency.success">
									<button
										className={styles.smallButton}
										onClick={openTopUpModal}
										type="button"
									>
										{null}
									</button>
								</Trans>
							</Alert>
						)}
					</form>
				)}
			</Formik>
		</div>
	);
}

PortfolioCurrencySelect.propTypes = {
	currencies: PropTypes.arrayOf(PropTypes.shape({
		code: PropTypes.string.isRequired,
		codeShort: PropTypes.string.isRequired,
		codeShortBefore: PropTypes.bool.isRequired,
	}).isRequired),
	currencyChange: PropTypes.bool,
	isChild: PropTypes.bool,
	openTopUpModal: PropTypes.func.isRequired,
	portfolioId: PropTypes.number.isRequired,
	setCheckPortfolio: PropTypes.func.isRequired,
	setCurrencyChange: PropTypes.func.isRequired,
};

PortfolioCurrencySelect.defaultProps = {
	currencies: null,
	currencyChange: false,
	isChild: false,
};
