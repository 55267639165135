import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import {
	closeInfoBox,
	fetchInfoBox,
} from '../../../utils/api';
import getCookieValue from '../../../utils/getCookieValue';
import setCookieValue from '../../../utils/setCookieValue';
import useFetchAuth from '../../../utils/useFetchAuth';
import useRequestAuth from '../../../utils/useRequestAuth';
import { Modal } from '../../ui';

import styles from './InfoBox.module.scss';

const useFetchInfoBox = useFetchAuth(fetchInfoBox);

const INFOBOX_COOKIE_NAME = 'fondee_infobox';

export default function InfoBox({
	clientZone,
	isLogged,
	setHasHeaderInfoBox,
}) {
	const country = useCountry();
	const language = useLanguage();
	const [infoBox, loading, error] = useFetchInfoBox(null, country, language, clientZone);
	const closeInfoBoxAuth = useRequestAuth(closeInfoBox);
	const [closedIds, setClosedIds] = useState(JSON.parse(getCookieValue(INFOBOX_COOKIE_NAME) || '[]'));

	useEffect(() => {
		if (closedIds.length > 0) {
			setCookieValue(INFOBOX_COOKIE_NAME, 365, JSON.stringify(closedIds));
		}
	}, [closedIds]);

	const headerInfoBoxes = infoBox && infoBox.filter((i) => i.type === 'header' && !closedIds.includes(i.id));
	const modalInfoBoxes = infoBox && infoBox.filter((i) => i.type === 'pop-up' && !closedIds.includes(i.id));
	const activeModal = modalInfoBoxes?.length > 0 ? modalInfoBoxes[0].id : null;
	const hasHeaderInfoBox = headerInfoBoxes?.length > 0;

	useEffect(() => {
		if (!hasHeaderInfoBox) {
			return undefined;
		}

		setHasHeaderInfoBox(true);
		return () => {
			setHasHeaderInfoBox(false);
		};
	}, [hasHeaderInfoBox]);

	const handleClose = async (id) => {
		setClosedIds((ids) => [...ids, id]);

		if (isLogged) {
			try {
				await closeInfoBoxAuth(id);
			} catch {
				// do nothing
			}
		}
	};

	if (loading || error || (headerInfoBoxes.length === 0 && modalInfoBoxes.length === 0)) {
		return null;
	}

	return (
		<>
			{headerInfoBoxes.map((item) => (
				<div key={item.id} className={styles.header}>
					<div className={styles.container}>
						<div className={styles.icon}>
							<svg
								viewBox="0 0 576 512"
								width={31}
								height={28}
							>
								<path
									fill="currentColor"
									d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
								/>
							</svg>
						</div>
						<div className={styles.content}>
							{item.caption && (
								<p>
									<strong>{item.caption}</strong>
								</p>
							)}
							{/* eslint-disable-next-line react/no-danger */}
							<div dangerouslySetInnerHTML={{ __html: item.text }} />
						</div>
						<button
							type="button"
							className={styles.close}
							onClick={() => handleClose(item.id)}
						>
							×
						</button>
					</div>
				</div>
			))}
			{modalInfoBoxes.map((item) => (
				<Modal
					key={item.id}
					title={item.caption}
					isVisible={activeModal === item.id}
					onClose={() => handleClose(item.id)}
				>
					{/* eslint-disable-next-line react/no-danger */}
					<div className={styles.modalBody} dangerouslySetInnerHTML={{ __html: item.text }} />
				</Modal>
			))}
		</>
	);
}

InfoBox.propTypes = {
	clientZone: PropTypes.bool,
	isLogged: PropTypes.bool,
	setHasHeaderInfoBox: PropTypes.func.isRequired,
};

InfoBox.defaultProps = {
	clientZone: false,
	isLogged: false,
};
