import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { useUserReload } from '../../../contexts/UserContext';
import { saveProfileChildRegistration, saveProfileOnboardingChildRegistration } from '../../../utils/api';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import useRequestAuth from '../../../utils/useRequestAuth';
import {
	checkPersonalIdentificationNumber,
	validateChildData,
	validatePhoneWarning,
} from '../../../utils/validators';
import {
	BankAccountField,
	CheckboxField,
	PhoneField,
	TextField,
} from '../../forms';
import { Col, Row } from '../../layout';
import {
	Alert,
	Button,
	ExternalLink,
} from '../../ui';

import styles from './ChildData.module.scss';

const initialValues = {
	bankAccount: '',
	bankAccountIBAN: '',
	bankAccountType: 'local',
	bankCode: '',
	bankPrefix: '',
	birthDateDay: '',
	birthDateMonth: '',
	birthDateYear: '',
	conditionsAgreed: false,
	email: '',
	firstName: '',
	lastName: '',
	personalIdentificationNumber: '',
	personalInfoAgreed: false,
	phoneNumber: '',
	phonePrefix: '',
	referenceCode: '',
};

export default function ChildData({
	conditionsUrl,
	newAccount,
	personalInfoAgreementUrl,
	product,
	setCheckOnboarding,
}) {
	const saveProfileChildRegistrationAuth = useRequestAuth(
		newAccount ? saveProfileOnboardingChildRegistration : saveProfileChildRegistration,
	);
	const [, setSearchParams] = useSearchParams();
	const [error, setError] = useState(false);
	const [errorMinority, setErrorMinority] = useState(false);
	const [t] = useTranslation();
	const trackGTMEvent = useTrackGTMEvent();
	const reloadUser = useUserReload();
	const country = useCountry();
	const language = useLanguage();

	return (
		<div className={styles.root}>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={async (values, { setErrors }) => {
					const birthDate = `${values.birthDateYear}-${values.birthDateMonth}-${values.birthDateDay}`;
					const isTypeIBAN = values.bankAccountType === 'iban';
					const bankPrefix = !isTypeIBAN && country !== 'PL' && values.bankPrefix.length > 0 ? values.bankPrefix : null;
					const bankAccount = !isTypeIBAN ? values.bankAccount.replace(/\s+/g, '') : null;
					const bankCode = !isTypeIBAN && country !== 'PL' ? values.bankCode : null;
					const bankAccountIBAN = isTypeIBAN ? values.bankAccountIBAN.replace(/\s+/g, '') : null;

					setError(false);
					setErrorMinority(false);
					try {
						const result = await saveProfileChildRegistrationAuth(
							language,
							product,
							values.email,
							values.firstName,
							values.lastName,
							values.phonePrefix,
							values.phoneNumber,
							birthDate,
							values.personalIdentificationNumber,
							values.referenceCode,
							values.personalInfoAgreed,
							values.conditionsAgreed,
							bankPrefix,
							bankAccount,
							bankCode,
							bankAccountIBAN,
						);

						if (!newAccount && result.childId) {
							setSearchParams({ childId: result.childId });
						}
					} catch (e) {
						const fieldErrors = {};
						const errorMessage = e.responseJson?.message;

						if (typeof errorMessage === 'string') {
							if (errorMessage.indexOf('Bank code') !== -1) {
								fieldErrors.bankCode = 'forms.fields.bankAccount.invalidCode';
							} else if (errorMessage.indexOf('Failed assertion \'minority\'') !== -1) {
								setErrorMinority(true);
							} else if (errorMessage.indexOf('e-mail uniqueness') !== -1) {
								fieldErrors.email = 'forms.fields.child.email.unique';
							} else if (errorMessage.indexOf('reference code validity') !== -1) {
								fieldErrors.referenceCode = 'forms.fields.child.referenceCode.invalid';
							}
						}

						const hasFieldErrors = Object.keys(fieldErrors).length > 0;
						if (hasFieldErrors) {
							setErrors(fieldErrors);
						}
						setError(!hasFieldErrors);
						return;
					}
					trackGTMEvent('conversionFunnel', {
						eventCategory: 'checkedPersonalInformation',
						eventAction: '',
						eventLabel: '',
					});
					setCheckOnboarding(true);
					reloadUser();
				}}
				validate={(values) => validateChildData(values, country)}
			>
				{({
					errors,
					handleBlur,
					handleChange,
					handleSubmit,
					isSubmitting,
					setFieldValue,
					touched,
					values,
				}) => (
					<form onSubmit={handleSubmit}>
						{error && !errorMinority && (
							<Alert type="danger">
								{t('forms.error')}
							</Alert>
						)}
						{errorMinority && (
							<Alert type="danger">
								{t('forms.fields.birthDate.minority')}
							</Alert>
						)}
						<div className={styles.section}>
							<Row>
								<Col xs={6}>
									<TextField
										error={
											errors.firstName
											&& touched.firstName
											&& t(errors.firstName)
										}
										id="firstName"
										label={t('forms.fields.child.firstName.label')}
										name="firstName"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										type="text"
										value={values.firstName}
									/>
								</Col>
								<Col xs={6}>
									<TextField
										error={
											errors.lastName
											&& touched.lastName
											&& t(errors.lastName)
										}
										id="lastName"
										label={t('forms.fields.child.lastName.label')}
										name="lastName"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										type="text"
										value={values.lastName}
									/>
								</Col>
							</Row>
							<div className={styles.labelWrap}>
								<label className={styles.label} htmlFor="birthDate">
									{t('forms.fields.birthDate.label')}
								</label>
							</div>
							<Row>
								<Col xs={4}>
									<TextField
										error={
											errors.birthDateDay
											&& touched.birthDateDay
											&& t(errors.birthDateDay)
										}
										id="birthDateDay"
										label={t('forms.fields.birthDate.day.label')}
										name="birthDateDay"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										type="text"
										value={values.birthDateDay}
									/>
								</Col>
								<Col xs={4}>
									<TextField
										error={
											errors.birthDateMonth
											&& touched.birthDateMonth
											&& t(errors.birthDateMonth)
										}
										id="birthDateMonth"
										label={t('forms.fields.birthDate.month.label')}
										name="birthDateMonth"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										type="text"
										value={values.birthDateMonth}
									/>
								</Col>
								<Col xs={4}>
									<TextField
										error={
											errors.birthDateYear
											&& touched.birthDateYear
											&& t(errors.birthDateYear)
										}
										id="birthDateYear"
										label={t('forms.fields.birthDate.year.label')}
										name="birthDateYear"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										type="text"
										value={values.birthDateYear}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={6}>
									<TextField
										error={
											errors.email
											&& touched.email
											&& t(errors.email)
										}
										id="email"
										label={t('forms.fields.child.email.label')}
										name="email"
										onBlur={handleBlur}
										onChange={handleChange}
										type="text"
										value={values.email}
									/>
								</Col>
								<Col xs={6}>
									<PhoneField
										error={(
											errors.phonePrefix
											&& touched.phonePrefix
											&& t(errors.phonePrefix)
										) || (
											errors.phoneNumber
											&& touched.phoneNumber
											&& t(errors.phoneNumber)
										)}
										helper={t('forms.fields.phoneNumber.helper')}
										id="phoneNumber"
										label={t('forms.fields.child.phone.label')}
										name="phoneNumber"
										onBlur={handleBlur}
										onChange={setFieldValue}
										phonePrefixId="phonePrefix"
										phonePrefixName="phonePrefix"
										phonePrefixValue={values.phonePrefix}
										value={values.phoneNumber}
										warning={
											values.phoneNumber !== '' && !validatePhoneWarning(values.phonePrefix, values.phoneNumber)
												? t('forms.fields.phoneNumber.warning') : ''
										}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={6}>
									<TextField
										error={
											errors.referenceCode
											&& touched.referenceCode
											&& t(errors.referenceCode)
										}
										id="referenceCode"
										label={t('forms.fields.child.referenceCode.label')}
										name="referenceCode"
										onBlur={handleBlur}
										onChange={handleChange}
										tooltip={t('forms.fields.child.referenceCode.tooltip')}
										type="text"
										value={values.referenceCode}
									/>
								</Col>
							</Row>
						</div>
						<div className={styles.section}>
							<h3 className={styles.title}>{t('onboarding.steps.childData.form.personalIdentificationNumber.title')}</h3>
							<p className={styles.text}>
								{t('onboarding.steps.childData.form.personalIdentificationNumber.text')}
							</p>
							<TextField
								error={
									errors.personalIdentificationNumber
									&& touched.personalIdentificationNumber
									&& t(errors.personalIdentificationNumber)
								}
								id="personalIdentificationNumber"
								isLabelHidden
								label={t('forms.fields.personalIdentificationNumber.label')}
								name="personalIdentificationNumber"
								onBlur={handleBlur}
								onChange={handleChange}
								required
								type="text"
								value={values.personalIdentificationNumber}
								warning={
									touched.personalIdentificationNumber
									&& !errors.personalIdentificationNumber
									&& !checkPersonalIdentificationNumber(values.personalIdentificationNumber, country)
										? t('forms.fields.personalIdentificationNumber.warning')
										: ''
								}
							/>
						</div>
						<div className={styles.section}>
							<h3 className={styles.title}>{t('onboarding.steps.childData.form.bankAccount.title')}</h3>
							<div className={styles.warning}>
								<svg
									height={18}
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 576 512"
								>
									<path
										fill="currentColor"
										d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
									/>
								</svg>
								{t('onboarding.steps.personalData.form.bankAccount.text')}
							</div>
							<p className={`${styles.warning} ${styles.textRed}`.trim()}>
								<Trans i18nKey="onboarding.steps.personalData.form.bankAccount.childWarning">
									<strong />
								</Trans>
							</p>
							<p className={styles.warning}>
								<Trans i18nKey="onboarding.steps.personalData.form.bankAccount.ibanText">
									<strong />
								</Trans>
							</p>
							<BankAccountField
								country={country}
								errorBankAccount={(
									errors.bankPrefix
									&& touched.bankPrefix
									&& t(errors.bankPrefix)
								) || (
									errors.bankAccount
									&& touched.bankAccount
									&& t(errors.bankAccount)
								) || (
									errors.bankCode
									&& touched.bankCode
									&& t(errors.bankCode)
								)}
								errorBankAccountIBAN={
									errors.bankAccountIBAN
									&& touched.bankAccountIBAN
									&& t(errors.bankAccountIBAN)
								}
								idAccount="bankAccount"
								idAccountIBAN="bankAccountIBAN"
								idAccountType="bankAccountType"
								idCode="bankCode"
								idPrefix="bankPrefix"
								isLabelHidden
								label={t('forms.fields.bankAccount.label')}
								nameAccount="bankAccount"
								nameAccountIBAN="bankAccountIBAN"
								nameAccountType="bankAccountType"
								nameCode="bankCode"
								namePrefix="bankPrefix"
								onBlur={handleBlur}
								onChange={setFieldValue}
								valueAccount={values.bankAccount}
								valueAccountIBAN={values.bankAccountIBAN}
								valueAccountType={values.bankAccountType}
								valueCode={values.bankCode}
								valuePrefix={values.bankPrefix}
								warning={
									touched.bankAccountIBAN
									&& !errors.bankAccountIBAN
									&& values.bankAccountType === 'iban'
									&& values.bankAccountIBAN
									&& values.bankAccountIBAN.length !== (country === 'PL' ? 28 : 24)
										? t('forms.fields.bankAccountIBAN.warning')
										: ''
								}
							/>
						</div>
						<div className={styles.confirm}>
							<CheckboxField
								checked={values.personalInfoAgreed}
								error={
									errors.personalInfoAgreed
									&& touched.personalInfoAgreed
									&& t(errors.personalInfoAgreed)
								}
								id="personalInfoAgreed"
								label={(
									<Trans i18nKey="forms.fields.personalInfoAgreed.label">
										<ExternalLink
											to={personalInfoAgreementUrl}
											blank
										/>
									</Trans>
								)}
								name="personalInfoAgreed"
								onBlur={handleBlur}
								onChange={handleChange}
								required
							/>
							<CheckboxField
								checked={values.conditionsAgreed}
								error={
									errors.conditionsAgreed
									&& touched.conditionsAgreed
									&& t(errors.conditionsAgreed)
								}
								id="conditionsAgreed"
								label={(
									<Trans i18nKey="forms.fields.conditionsAgreed.label">
										<ExternalLink
											to={conditionsUrl}
											blank
										/>
									</Trans>
								)}
								name="conditionsAgreed"
								onBlur={handleBlur}
								onChange={handleChange}
								required
							/>
						</div>
						<div className={styles.section}>
							<Button
								disabled={isSubmitting}
								isSubmit
								label={t('onboarding.steps.personalData.form.submit')}
							/>
						</div>
					</form>
				)}
			</Formik>
		</div>
	);
}

ChildData.propTypes = {
	conditionsUrl: PropTypes.string.isRequired,
	newAccount: PropTypes.bool,
	personalInfoAgreementUrl: PropTypes.string.isRequired,
	product: PropTypes.string.isRequired,
	setCheckOnboarding: PropTypes.func.isRequired,
};

ChildData.defaultProps = {
	newAccount: false,
};
