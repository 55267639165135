import routes, { subpages } from '../routes';

export const countries = Object.keys(routes);

const languagesByCountry = countries.reduce((acc, country) => {
	acc[country] = Object.keys(routes[country]);
	return acc;
}, {});
export const countryLanguages = (country) => languagesByCountry[country] ?? [];
export const defaultLanguage = (country) => countryLanguages(country)[0] ?? null;

const slashed = (route) => (route !== '' && route !== null ? `/${route}` : route);
export const languageRoute = (country, language) => slashed(language !== defaultLanguage(country) || language === null ? language : '');
export const pageRoute = (country, language, page) => slashed(routes[country]?.[language]?.[page] ?? null);
export const slugRoute = slashed;

export function urlLanguage(country, url, prefix = '') {
	const defaultLang = defaultLanguage(country);
	return countryLanguages(country).find((language) => (
		language !== defaultLang && url.startsWith(`${prefix}${languageRoute(country, language)}/`)
	)) ?? defaultLang;
}

export function pageUrl(country, language, page, slug = null) {
	const route = pageRoute(country, language, page);
	const suffix = slugRoute(slug) ?? '';
	return route !== null ? `${languageRoute(country, language)}${route !== '' ? route : '/'}${suffix}` : null;
}

export function subpageUrl(country, language, page, subpage) {
	const slug = subpages[country]?.[language]?.[page]?.[subpage] ?? null;
	return slug !== null ? pageUrl(country, language, page, slug) : null;
}

export function pageOrRootUrl(country, language, page = null, slug = null) {
	return (page !== null ? pageUrl(country, language, page, slug) : null) ?? `${languageRoute(country, language)}/`;
}

export function changeLanguageUrl(baseUrl, country, language, page = null, isSwitch = true) {
	return countryLanguages(country).includes(language)
		? `${baseUrl}${pageOrRootUrl(country, language, page)}?changeLanguage=${isSwitch ? 'switch' : 'auto'}`
		: null;
}

export function changeCountryUrl(baseUrls = null, country = null, language = null, page = null, isSwitch = true) {
	if (baseUrls === null || country === null || (baseUrls[country] ?? null) === null) {
		return null;
	}

	return language !== null
		? changeLanguageUrl(baseUrls[country], country, language, page, isSwitch)
		: `${baseUrls[country]}${pageOrRootUrl(country, defaultLanguage(country), page)}`;
}
