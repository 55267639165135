import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useFormatNumber from '../../../utils/useFormatNumber';
import {
	Col,
	Container,
	Row,
	Section,
} from '../../layout';
import { Tooltip } from '../../ui';
import PortfolioOverviewChart from './PortfolioOverviewChart';
import PortfolioOverviewGrowth from './PortfolioOverviewGrowth';

import styles from './PortfolioOverview.module.scss';

export default function PortfolioOverview({ portfolio }) {
	const [t] = useTranslation();
	const formatCurrency = useFormatNumber({ style: 'currency', currency: portfolio.currency.code });

	return (
		<Section>
			<Container>
				<div className={styles.root}>
					<Row>
						<Col lg={8} xl={7}>
							<div className={styles.valueTitle}>
								{t('portfolio.overview.valueTitle', { count: 1 })}
							</div>
							<div className={styles.value}>
								{formatCurrency(portfolio.totalValue ?? null)}
							</div>
							<Row>
								<Col md={6}>
									<div className={styles.netGrowthWrap}>
										<div className={styles.netGrowthTitle}>
											{t('portfolio.overview.netGrowthTitle')}
											<span className={styles.netGrowthTitleIcon}>
												<Tooltip
													text={t('portfolio.overview.netGrowthTitleTooltip')}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 512 512"
														width={15}
													>
														<path
															fill="currentColor"
															d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
														/>
													</svg>
												</Tooltip>
											</span>
										</div>
										<PortfolioOverviewGrowth
											currency={portfolio.currency ?? null}
											value={portfolio.netGrowth ?? null}
										/>
									</div>
								</Col>
								<Col md={6}>
									<div className={styles.netGrowthWrap}>
										<div className={styles.netGrowthTitle}>
											{t('portfolio.overview.netGrowthRatioTitle')}
											<span className={styles.netGrowthTitleIcon}>
												<Tooltip
													text={t('portfolio.overview.netGrowthRatioTitleTooltip')}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 512 512"
														width={15}
													>
														<path
															fill="currentColor"
															d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
														/>
													</svg>
												</Tooltip>
											</span>
										</div>
										<PortfolioOverviewGrowth
											value={portfolio.netGrowthRatio ?? null}
											ratio
										/>
									</div>
								</Col>
							</Row>
						</Col>
						<Col lg={4} xl={5}>
							<PortfolioOverviewChart categories={portfolio.categories ?? []} />
						</Col>
					</Row>
				</div>
			</Container>
		</Section>
	);
}

PortfolioOverview.propTypes = {
	portfolio: PropTypes.shape({
		categories: PropTypes.arrayOf(
			PropTypes.shape({
				volume: PropTypes.number.isRequired,
				titleText: PropTypes.string.isRequired,
			}),
		),
		currency: PropTypes.shape({
			code: PropTypes.string.isRequired,
			codeShort: PropTypes.string.isRequired,
			codeShortBefore: PropTypes.bool.isRequired,
		}),
		totalValue: PropTypes.number,
		netGrowth: PropTypes.number,
		netGrowthRatio: PropTypes.number,
	}).isRequired,
};
