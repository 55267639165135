import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import BlogPostPreview from '../../BlogPostPreview';
import blogPostShape from '../../blogPostShape';

import styles from './BlogPostRecommended.module.scss';

export default function BlogPostRecommended({
	blogPostId,
	blogPostUrl,
	handleTagClick,
	postsRecommended,
	topReadPosts,
}) {
	const [t] = useTranslation();

	if (!postsRecommended && !topReadPosts) {
		return null;
	}

	const combinedPosts = (postsRecommended ?? [])
		.concat(topReadPosts ?? [])
		.reduce((acc, post) => {
			if (!acc.find((p) => p.id === post.id) && post.id !== blogPostId) {
				acc.push(post);
			}
			return acc;
		}, []);

	return (
		<div className={styles.root}>
			<h3 className={styles.title}>
				{t('blogPost.recommended.title')}
			</h3>
			<ul className={styles.list}>
				{combinedPosts.slice(0, 3).map((post) => (
					<BlogPostPreview
						key={post.id}
						blogPostUrl={blogPostUrl}
						handleTagClick={handleTagClick}
						post={post}
					/>
				))}
			</ul>
		</div>
	);
}

BlogPostRecommended.propTypes = {
	blogPostId: PropTypes.string.isRequired,
	blogPostUrl: PropTypes.func.isRequired,
	handleTagClick: PropTypes.func.isRequired,
	postsRecommended: PropTypes.arrayOf(blogPostShape.isRequired),
	topReadPosts: PropTypes.arrayOf(blogPostShape.isRequired),
};

BlogPostRecommended.defaultProps = {
	postsRecommended: null,
	topReadPosts: null,
};
