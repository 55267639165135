import React from 'react';
import PropTypes from 'prop-types';

import {
	Documents,
	FooterCta,
} from '../../components/common';

export default function DocumentsPage({
	conditionsUrl,
	termsUrl,
	personalInfoAgreementUrl,
	informationSheetUrl,
	complaintsProcedureUrl,
	esgInfolistUrl,
	esgSustainabilityUrl,
	registrationUrl,
}) {
	return (
		<>
			<Documents
				conditionsUrl={conditionsUrl}
				termsUrl={termsUrl}
				personalInfoAgreementUrl={personalInfoAgreementUrl}
				informationSheetUrl={informationSheetUrl}
				complaintsProcedureUrl={complaintsProcedureUrl}
				esgInfolistUrl={esgInfolistUrl}
				esgSustainabilityUrl={esgSustainabilityUrl}
			/>
			<FooterCta registrationUrl={registrationUrl} />
		</>
	);
}

DocumentsPage.propTypes = {
	conditionsUrl: PropTypes.string.isRequired,
	termsUrl: PropTypes.string.isRequired,
	personalInfoAgreementUrl: PropTypes.string.isRequired,
	informationSheetUrl: PropTypes.string.isRequired,
	complaintsProcedureUrl: PropTypes.string.isRequired,
	esgInfolistUrl: PropTypes.string.isRequired,
	esgSustainabilityUrl: PropTypes.string.isRequired,
	registrationUrl: PropTypes.string.isRequired,
};
