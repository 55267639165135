import React from 'react';
import PropTypes from 'prop-types';

export default function ExternalLink({
	blank,
	children,
	onClick,
	to,
	...props
}) {
	return (
		<a
			href={to}
			onClick={
				(e) => {
					if (onClick) {
						onClick(e);
					}
					e.currentTarget.blur();
				}
			}
			rel="noopener noreferrer"
			target={blank ? '_blank' : undefined}
			{...props} // eslint-disable-line react/jsx-props-no-spreading
		>
			{children}
		</a>
	);
}

ExternalLink.propTypes = {
	blank: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	onClick: PropTypes.func,
	to: PropTypes.string.isRequired,
};

ExternalLink.defaultProps = {
	blank: false,
	children: null,
	onClick: null,
};
