/* global sessionStorage */

export function checkChildCheckSessionDate() {
	const timeCheck = sessionStorage.getItem('childAgeCheckWarning');
	return !(timeCheck && new Date().getTime() < +timeCheck + (10 * 60 * 1000)); // 10 minutes
}

export function saveChildCheckSessionDate() {
	const date = new Date();
	sessionStorage.setItem('childAgeCheckWarning', date.getTime().toString());
}
