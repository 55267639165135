/* global window */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { useUserContext, useUserReload } from '../../../contexts/UserContext';
import {
	fetchProfileIdentity,
	getProfileGenerateContract,
	getProfileGenerateLegalRepresentativeDeclaration,
	signContract,
	updatePhoneNumber,
	validateSmsCode,
} from '../../../utils/api';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import useRequestAuth from '../../../utils/useRequestAuth';
import {
	validateCode,
	validatePhoneChange,
	validatePhoneWarning,
	validateReadAgreement,
} from '../../../utils/validators';
import { Col, Row } from '../../layout';
import { CheckboxField, PhoneField, TextField } from '../../forms';
import {
	Alert,
	Button,
	EmailLink,
	ExternalLink,
	Modal,
} from '../../ui';
import DocumentFile from '../DocumentFile';

import styles from './OnboardingAgreement.module.scss';

export default function OnboardingAgreement({
	category,
	childId,
	legacySign,
	newAgreement,
	setCheckOnboarding,
}) {
	const country = useCountry();
	const fetchProfileIdentityAuth = useRequestAuth(fetchProfileIdentity);
	const signContractAuth = useRequestAuth(signContract);
	const updatePhoneNumberAuth = useRequestAuth(updatePhoneNumber);
	const validateSmsCodeAuth = useRequestAuth(validateSmsCode);
	const smsTimeoutIdRef = useRef(null);
	const [changePhone, setChangePhone] = useState(false);
	const [codeActive, setCodeActive] = useState(false);
	const [codeError, setCodeError] = useState(false);
	const [error, setError] = useState(false);
	const [smsBlocked, setSmsBlockedError] = useState(false);
	const [disabledSms, setDisabledSms] = useState(false);
	const [t] = useTranslation();
	const [user, loadingUser] = useUserContext();
	const language = useLanguage();
	const trackGTMEvent = useTrackGTMEvent();
	const reloadUser = useUserReload();
	const defaultCategory = childId !== null ? 'child' : 'standard';
	const newCategory = newAgreement ? category : null;
	const templateCategory = category ?? defaultCategory;

	useEffect(() => () => {
		if (smsTimeoutIdRef.current !== null) {
			clearTimeout(smsTimeoutIdRef.current);
			smsTimeoutIdRef.current = null;
		}
	}, []);

	return (
		<div className={styles.root}>
			{error && (
				<Alert type="danger">
					{t('forms.error')}
				</Alert>
			)}
			<Formik
				initialValues={{
					readAgreement: false,
					legalRepresentative: false,
				}}
				validate={(values) => validateReadAgreement(values)}
				onSubmit={async () => {
					setDisabledSms(true);
					setSmsBlockedError(false);
					setError(false);

					if (smsTimeoutIdRef.current !== null) {
						clearTimeout(smsTimeoutIdRef.current);
					}
					smsTimeoutIdRef.current = setTimeout(() => {
						if (smsTimeoutIdRef.current !== null) {
							smsTimeoutIdRef.current = null;
							setDisabledSms(false);
						}
					}, 60000);

					try {
						await signContractAuth(childId, newCategory);
					} catch (e) {
						const errorMessage = e.responseJson?.message;
						if (typeof errorMessage === 'string' && errorMessage.indexOf('SMS has already been sent') !== -1) {
							setSmsBlockedError(true);
						} else {
							setError(true);
						}
						return;
					}

					setCodeActive(true);
				}}
			>
				{({
					handleChange, handleBlur, handleSubmit, isSubmitting, values, errors, touched,
				}) => (
					<form onSubmit={handleSubmit}>
						<Row>
							<Col>
								<ExternalLink
									className={styles.download}
									onClick={(e) => {
										const url = e.currentTarget.href;
										e.preventDefault();
										fetchProfileIdentityAuth(language).then(() => {
											window.location = url;
										}, () => {});
									}}
									to={getProfileGenerateContract(childId, newCategory)}
									download
								>
									<svg viewBox="0 0 384 384" width="25" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
										<path d="M72 8h304v272l-96 96H72V8z" fill="#f2f2f2" fillRule="nonzero" />
										<path d="M280 280h96l-96 96v-96z" fill="#ababab" fillRule="nonzero" />
										<path fill="#cb5641" d="M8 88h256v128H8z" />
										<path d="M64 0v80H0v144h64v160h224l96-96V0H64zM16 96h240v112H16V96zm272 265.376V288h73.376L288 361.376zM368 272h-96v96H80V224h192V80H80V16h288v256z" fill="#010101" fillRule="nonzero" />
										<g fill="#fff" fillRule="nonzero">
											<path d="M72.992 117.856c3.52 0 6.464.592 8.88 1.776 2.416 1.184 4.336 2.736 5.808 4.656 1.472 1.92 2.528 4.112 3.168 6.576.624 2.448.96 4.976.96 7.536 0 3.52-.528 6.592-1.584 9.216-1.056 2.624-2.528 4.768-4.416 6.416-1.888 1.664-4.176 2.928-6.864 3.744-2.688.816-5.664 1.264-8.928 1.264h-7.392v27.36H48.8v-68.544h24.192zm-4.224 31.04c2.752 0 4.992-.848 6.672-2.512 1.696-1.664 2.544-4.256 2.544-7.776 0-3.456-.736-6.08-2.208-7.872-1.472-1.776-3.936-2.688-7.392-2.688h-5.76v20.848h6.144zM131.552 117.84c4.816 0 8.672.8 11.632 2.368 2.944 1.568 5.232 3.792 6.864 6.72 1.648 2.912 2.736 6.4 3.312 10.512.576 4.096.864 8.672.864 13.712 0 6.016-.352 11.248-1.088 15.696-.736 4.432-2 8.112-3.792 10.992-1.792 2.896-4.192 5.024-7.2 6.432-3.008 1.408-6.816 2.112-11.424 2.112h-21.824V117.84h22.656zm-2.688 58.4c2.432 0 4.384-.416 5.856-1.248 1.472-.832 2.64-2.208 3.504-4.128.88-1.92 1.456-4.448 1.728-7.6.288-3.12.432-7.024.432-11.696 0-3.904-.144-7.328-.384-10.288-.256-2.944-.784-5.392-1.584-7.344-.816-1.952-1.984-3.424-3.568-4.4-1.568-.992-3.664-1.504-6.288-1.504h-5.84v48.208h6.144zM211.232 117.856v11.328h-24.48v16.128h23.04v11.328h-23.04v29.76h-13.824v-68.544h38.304z" />
										</g>
									</svg>
									{t('onboarding.steps.agreement.download')}
								</ExternalLink>
								<p className={styles.text}>
									{t(`onboarding.steps.agreement.formAgreement.${legacySign || newAgreement ? 'signAgreementInfoText' : 'infoText'}`)}
								</p>
								{childId !== null && (
									<ExternalLink
										className={styles.download}
										onClick={(e) => {
											const url = e.currentTarget.href;
											e.preventDefault();
											fetchProfileIdentityAuth(language).then(() => {
												window.location = url;
											}, () => {});
										}}
										to={getProfileGenerateLegalRepresentativeDeclaration(childId, newCategory)}
										download
									>
										<svg viewBox="0 0 384 384" width="25" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
											<path d="M72 8h304v272l-96 96H72V8z" fill="#f2f2f2" fillRule="nonzero" />
											<path d="M280 280h96l-96 96v-96z" fill="#ababab" fillRule="nonzero" />
											<path fill="#cb5641" d="M8 88h256v128H8z" />
											<path d="M64 0v80H0v144h64v160h224l96-96V0H64zM16 96h240v112H16V96zm272 265.376V288h73.376L288 361.376zM368 272h-96v96H80V224h192V80H80V16h288v256z" fill="#010101" fillRule="nonzero" />
											<g fill="#fff" fillRule="nonzero">
												<path d="M72.992 117.856c3.52 0 6.464.592 8.88 1.776 2.416 1.184 4.336 2.736 5.808 4.656 1.472 1.92 2.528 4.112 3.168 6.576.624 2.448.96 4.976.96 7.536 0 3.52-.528 6.592-1.584 9.216-1.056 2.624-2.528 4.768-4.416 6.416-1.888 1.664-4.176 2.928-6.864 3.744-2.688.816-5.664 1.264-8.928 1.264h-7.392v27.36H48.8v-68.544h24.192zm-4.224 31.04c2.752 0 4.992-.848 6.672-2.512 1.696-1.664 2.544-4.256 2.544-7.776 0-3.456-.736-6.08-2.208-7.872-1.472-1.776-3.936-2.688-7.392-2.688h-5.76v20.848h6.144zM131.552 117.84c4.816 0 8.672.8 11.632 2.368 2.944 1.568 5.232 3.792 6.864 6.72 1.648 2.912 2.736 6.4 3.312 10.512.576 4.096.864 8.672.864 13.712 0 6.016-.352 11.248-1.088 15.696-.736 4.432-2 8.112-3.792 10.992-1.792 2.896-4.192 5.024-7.2 6.432-3.008 1.408-6.816 2.112-11.424 2.112h-21.824V117.84h22.656zm-2.688 58.4c2.432 0 4.384-.416 5.856-1.248 1.472-.832 2.64-2.208 3.504-4.128.88-1.92 1.456-4.448 1.728-7.6.288-3.12.432-7.024.432-11.696 0-3.904-.144-7.328-.384-10.288-.256-2.944-.784-5.392-1.584-7.344-.816-1.952-1.984-3.424-3.568-4.4-1.568-.992-3.664-1.504-6.288-1.504h-5.84v48.208h6.144zM211.232 117.856v11.328h-24.48v16.128h23.04v11.328h-23.04v29.76h-13.824v-68.544h38.304z" />
											</g>
										</svg>
										{t('onboarding.steps.agreement.formAgreement.legalRepresentative.download')}
									</ExternalLink>
								)}
								{country === 'CZ' && language !== 'cs' && (
									<DocumentFile
										label={t(`documents.agreementTemplate.${templateCategory}`, {
											defaultValue: t(`documents.agreementTemplate.${defaultCategory}`),
											fallbackLng: [],
										})}
										to={t(`documentUrls.agreementTemplate.${templateCategory}`, {
											defaultValue: t(`documentUrls.agreementTemplate.${defaultCategory}`),
											fallbackLng: [],
										})}
									/>
								)}
								{country !== 'CZ' && (
									<DocumentFile
										label={t(`documents.agreementTemplateCzech.${templateCategory}`, {
											defaultValue: t(`documents.agreementTemplateCzech.${defaultCategory}`),
											fallbackLng: [],
										})}
										to={t(`documentUrls.agreementTemplateCzech.${templateCategory}`, {
											defaultValue: t(`documentUrls.agreementTemplateCzech.${defaultCategory}`),
											fallbackLng: [],
										})}
									/>
								)}
							</Col>
						</Row>
						<Row>
							<Col>
								{childId !== null && (
									<div className={styles.check}>
										<CheckboxField
											id="legalRepresentative"
											name="legalRepresentative"
											error={(
												errors.legalRepresentative
												&& touched.legalRepresentative
												&& t(errors.legalRepresentative)
											)}
											onBlur={handleBlur}
											onChange={handleChange}
											checked={values.legalRepresentative}
											label={t('onboarding.steps.agreement.formAgreement.legalRepresentative.label')}
											required
										/>
									</div>
								)}
								<div className={styles.check}>
									<CheckboxField
										id="readAgreement"
										name="readAgreement"
										error={
											(
												errors.readAgreement
												&& touched.readAgreement
												&& t(errors.readAgreement)
											) || (
												!touched.readAgreement
												&& !values.readAgreement
												&& t('onboarding.steps.agreement.formAgreement.readAgreement.blocked')
											) || ''
										}
										onBlur={handleBlur}
										onChange={handleChange}
										checked={values.readAgreement}
										disabled={codeActive}
										label={t('onboarding.steps.agreement.formAgreement.readAgreement.label')}
										required
									/>
								</div>
								<div className={values.readAgreement || codeActive ? styles.visible : styles.hidden}>
									<Button
										isSubmit
										disabled={isSubmitting || disabledSms}
										label={t('onboarding.steps.agreement.formAgreement.smsSend')}
									/>
									{smsBlocked && (
										<div className={styles.smsBlocked}>
											{t('onboarding.steps.agreement.formAgreement.smsBlocked')}
										</div>
									)}
									{!loadingUser && (
										<>
											<p className={styles.smsText}>
												<Trans i18nKey="onboarding.steps.agreement.formAgreement.smsText">
													<strong />
													{{ phoneNumber: user !== null ? `${user.phone_prefix} ${user.phone_number}` : '' }}
												</Trans>
											</p>
											<p className={styles.smsText}>
												<Trans i18nKey="onboarding.steps.agreement.formAgreement.phoneChange.text">
													<a
														href="#phoneChange"
														onClick={(e) => {
															e.preventDefault();
															setChangePhone(!changePhone);
														}}
													>
														{t('onboarding.steps.agreement.formAgreement.phoneChange.text')}
													</a>
												</Trans>
											</p>
										</>
									)}
								</div>
							</Col>
						</Row>
					</form>
				)}
			</Formik>
			<Formik
				initialValues={{
					code: '',
				}}
				validate={(values) => validateCode(values)}
				onSubmit={async (values) => {
					setCodeError(false);
					setError(false);

					try {
						await validateSmsCodeAuth(
							childId,
							newCategory,
							values.code,
						);
					} catch (e) {
						const errorMessage = e.responseJson?.message;
						if (typeof errorMessage === 'string' && errorMessage.indexOf('Bad SMS code') !== -1) {
							setCodeError(true);
						} else {
							setError(true);
						}
						return;
					}

					trackGTMEvent('conversionFunnel', {
						eventCategory: 'agreementSigned',
						eventAction: '',
						eventLabel: '',
					});
					setCheckOnboarding(true);
				}}
			>
				{({
					handleChange, handleBlur, handleSubmit, isSubmitting, values, errors, touched,
				}) => (
					<form onSubmit={handleSubmit} className={codeActive ? styles.visible : styles.hidden}>
						<Row>
							<Col lg={6}>
								<TextField
									onBlur={handleBlur}
									onChange={handleChange}
									error={
										errors.code
										&& touched.code
										&& t(errors.code)
									}
									id="code"
									name="code"
									label={t('onboarding.steps.agreement.formCode.code.label')}
									required
									type="text"
									value={values.code}
								/>
								{codeError && (
									<Alert type="danger">
										<Trans i18nKey="onboarding.steps.agreement.formCode.error">
											<EmailLink />
										</Trans>
									</Alert>
								)}
							</Col>
							<Col lg={6}>
								<div className={styles.submit}>
									<Button
										label={t('onboarding.steps.agreement.formCode.submit')}
										isSubmit
										disabled={isSubmitting}
									/>
								</div>
							</Col>
						</Row>
					</form>
				)}
			</Formik>
			<Formik
				enableReinitialize
				initialValues={{
					phonePrefix: {
						CZ: '+420',
						PL: '+48',
						SK: '+421',
					}[country] ?? '+420',
					phoneNumber: '',
				}}
				validate={(values) => validatePhoneChange(values)}
				onSubmit={async (values, { setErrors }) => {
					setError(false);
					try {
						await updatePhoneNumberAuth(
							childId,
							newCategory,
							values.phonePrefix,
							values.phoneNumber,
						);
					} catch (e) {
						const fieldErrors = {};
						const errorMessage = e.responseJson?.message;
						if (typeof errorMessage === 'string') {
							if (errorMessage.indexOf('phone prefix validity') !== -1) {
								fieldErrors.phoneNumber = 'forms.fields.phoneNumber.invalid';
							}
						}

						const hasFieldErrors = Object.keys(fieldErrors).length > 0;
						if (hasFieldErrors) {
							setErrors(fieldErrors);
						}
						setError(!hasFieldErrors);
					}
					setChangePhone(!changePhone);
					setCodeActive(false);
					reloadUser();
				}}
			>
				{({
					errors,
					handleBlur,
					handleSubmit,
					handleReset,
					isSubmitting,
					setFieldValue,
					touched,
					values,
				}) => (
					<Modal
						isVisible={changePhone}
						onClose={() => {
							setChangePhone(false);
							handleReset();
						}}
						title={t('onboarding.steps.agreement.formAgreement.phoneChange.modal.title')}
					>
						<form onSubmit={handleSubmit} className={styles.textCenter}>
							{error && (
								<Alert type="danger">
									{t('forms.error')}
								</Alert>
							)}
							<PhoneField
								error={
									(
										errors.phonePrefix
										&& touched.phonePrefix
										&& t(errors.phonePrefix)
									) || (
										errors.phoneNumber
										&& touched.phoneNumber
										&& t(errors.phoneNumber)
									)
								}
								helper={t('forms.fields.phoneNumber.helper')}
								id="phoneNumber"
								label={t('forms.fields.phoneNumber.label')}
								name="phoneNumber"
								onBlur={handleBlur}
								onChange={setFieldValue}
								phonePrefixId="phonePrefix"
								phonePrefixName="phonePrefix"
								phonePrefixValue={values.phonePrefix}
								placeholder={t('onboarding.steps.agreement.formAgreement.phoneChange.modal.placeholder')}
								required
								value={values.phoneNumber}
								warning={
									values.phoneNumber !== '' && !validatePhoneWarning(values.phonePrefix, values.phoneNumber)
										? t('forms.fields.phoneNumber.warning') : ''
								}
							/>
							<Button
								label={t('onboarding.steps.agreement.formAgreement.phoneChange.modal.button')}
								disabled={isSubmitting}
								isSubmit
							/>
						</form>
					</Modal>
				)}
			</Formik>
		</div>
	);
}

OnboardingAgreement.propTypes = {
	category: PropTypes.string,
	childId: PropTypes.number,
	legacySign: PropTypes.bool,
	newAgreement: PropTypes.bool,
	setCheckOnboarding: PropTypes.func.isRequired,
};

OnboardingAgreement.defaultProps = {
	category: null,
	childId: null,
	legacySign: false,
	newAgreement: false,
};
