import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { getProfileClientCheckDocumentRedirect } from '../../../utils/api';
import { ButtonLink, Modal, Toggle } from '../../ui';
import DocumentCheckUpload from '../DocumentCheckUpload';

import styles from './PersonalInformationModal.module.scss';

export default function PersonalInformationModal({
	childId,
	reloadUser,
	showNameModal,
	setShowNameModal,
}) {
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const [manualCheck, setManualCheck] = useState(false);

	const handleClose = () => {
		setShowNameModal(false);
	};

	const handleSuccess = () => {
		handleClose();
		reloadUser();
	};

	return (
		<Modal
			isVisible={showNameModal}
			onClose={handleClose}
			title={t('account.personalData.modalNameChange.title')}
		>
			<div className={styles.modal}>
				<p className={styles.modalText}>
					{t('account.personalData.modalNameChange.text')}
				</p>
				<div className={styles.modalControl}>
					<Toggle
						inputNameDefault="toggle-personal-information-modal-redirect"
						inputNameSecondary="toggle-personal-information-modal-upload"
						labelFirst={t('onboarding.steps.documents.redirect.title')}
						labelSecond={t('onboarding.steps.documents.upload.title')}
						setToggle={setManualCheck}
						toggle={manualCheck}
					/>
					{manualCheck ? (
						<DocumentCheckUpload
							childId={childId}
							id="personalInformationModal"
							onSuccess={handleSuccess}
						/>
					) : (
						<div>
							<ButtonLink
								label={t('account.personalData.modalNameChange.button')}
								to={getProfileClientCheckDocumentRedirect(childId, country, language)}
								external
							/>
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
}

PersonalInformationModal.propTypes = {
	childId: PropTypes.number,
	reloadUser: PropTypes.func.isRequired,
	showNameModal: PropTypes.bool.isRequired,
	setShowNameModal: PropTypes.func.isRequired,
};

PersonalInformationModal.defaultProps = {
	childId: null,
};
