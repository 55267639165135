import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import useFormatNumber from '../../../utils/useFormatNumber';
import {
	Col,
	Container,
	Row,
	Section,
} from '../../layout';
import { Tooltip } from '../../ui';

import styles from './HowItWorksRebalancing.module.scss';

export default function HowItWorksRebalancing() {
	const [t] = useTranslation();
	const formatPercent = useFormatNumber({ style: 'percent', fractionDigits: 0 });

	return (
		<Section bordered id={t('anchors.rebalancing')}>
			<div className={styles.root}>
				<Container center>
					<h2 className={styles.title}>
						{t('howItWorksRebalancing.title')}
					</h2>
					<div className={styles.descriptionWrap}>
						<Trans i18nKey="howItWorksRebalancing.description">
							<p className={styles.description}>
								<strong />
							</p>
						</Trans>
					</div>
					<Row center>
						<Col sm={10} md={8} lg={12}>
							<div className={styles.wrap}>
								<div className={styles.pieChartWrap}>
									<div className={styles.pieChart}>
										<span className={styles.part}>
											<span className={styles.percent}>
												{formatPercent(0.5)}
											</span>
											<br />
											{t('howItWorksRebalancing.chart.bond')}
											<br />
											{t('howItWorksRebalancing.chart.etf')}
										</span>
										<span className={styles.part}>
											<span className={styles.percent}>
												{formatPercent(0.5)}
											</span>
											<br />
											{t('howItWorksRebalancing.chart.share')}
											<br />
											{t('howItWorksRebalancing.chart.etf')}
										</span>
									</div>
									<div className={styles.text}>
										<span className={styles.date}>
											{t('howItWorksRebalancing.chart.portfolioToday')}
										</span>
										<span className={styles.value}>
											{t('howItWorksRebalancing.chart.portfolioValueToday')}
										</span>
									</div>
								</div>
								<div className={styles.arrowWrap}>
									<Tooltip text={t('howItWorksRebalancing.chart.tooltipWhy')}>
										<i className={styles.arrow} />
									</Tooltip>
									<p>
										{t('howItWorksRebalancing.chart.why')}
										<Tooltip text={t('howItWorksRebalancing.chart.tooltipWhy')}>
											<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={16} height={16} viewBox="0 0 16 16">
												<path
													fill="#555"
													d="M15.27 8A7.27 7.27 0 1 1 .74 8a7.27 7.27 0 0 1 14.53 0ZM8.19 3.14A3.8 3.8 0 0 0 4.78
													5c-.1.16-.07.37.08.48l1.02.77c.15.12.37.09.48-.06.53-.66.89-1.05 1.68-1.05.6 0 1.34.39 1.34.97 0
													.44-.36.66-.95 1-.69.38-1.6.86-1.6 2.06v.12c0 .2.16.35.35.35h1.64c.2 0 .35-.16.35-.35v-.04c0-.83
													2.44-.87 2.44-3.13 0-1.7-1.76-2.98-3.42-2.98ZM8 10.4a1.35 1.35 0 1 0 0 2.7 1.35 1.35 0 0 0 0-2.7Z"
												/>
											</svg>
										</Tooltip>
									</p>
								</div>
								<div className={styles.pieChartWrap}>
									<div className={styles.pieChart}>
										<span className={styles.part}>
											<span className={styles.percent}>
												{formatPercent(0.45)}
											</span>
											<br />
											{t('howItWorksRebalancing.chart.bond')}
											<br />
											{t('howItWorksRebalancing.chart.etf')}
										</span>
										<span className={styles.part}>
											<span className={styles.percent}>
												{formatPercent(0.55)}
											</span>
											<br />
											{t('howItWorksRebalancing.chart.share')}
											<br />
											{t('howItWorksRebalancing.chart.etf')}
										</span>
									</div>
									<div className={styles.text}>
										<span className={styles.date}>
											{t('howItWorksRebalancing.chart.portfolioAfterMonth')}
										</span>
										<span className={styles.value}>
											{t('howItWorksRebalancing.chart.portfolioValueAfterMonth')}
										</span>
									</div>
								</div>
								<div className={styles.arrowWrap}>
									<Tooltip text={t('howItWorksRebalancing.chart.tooltipHow')}>
										<i className={styles.arrow} />
									</Tooltip>
									<p>
										{t('howItWorksRebalancing.chart.how')}
										<Tooltip text={t('howItWorksRebalancing.chart.tooltipHow')}>
											<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={16} height={16} viewBox="0 0 16 16">
												<path
													fill="#555"
													d="M15.27 8A7.27 7.27 0 1 1 .74 8a7.27 7.27 0 0 1 14.53 0ZM8.19 3.14A3.8 3.8 0 0 0 4.78
													5c-.1.16-.07.37.08.48l1.02.77c.15.12.37.09.48-.06.53-.66.89-1.05 1.68-1.05.6 0 1.34.39 1.34.97 0
													.44-.36.66-.95 1-.69.38-1.6.86-1.6 2.06v.12c0 .2.16.35.35.35h1.64c.2 0 .35-.16.35-.35v-.04c0-.83
													2.44-.87 2.44-3.13 0-1.7-1.76-2.98-3.42-2.98ZM8 10.4a1.35 1.35 0 1 0 0 2.7 1.35 1.35 0 0 0 0-2.7Z"
												/>
											</svg>
										</Tooltip>
									</p>
								</div>
								<div className={styles.pieChartWrap}>
									<div className={styles.pieChart}>
										<span className={styles.part}>
											<span className={styles.percent}>
												{formatPercent(0.5)}
											</span>
											<br />
											{t('howItWorksRebalancing.chart.bond')}
											<br />
											{t('howItWorksRebalancing.chart.etf')}
										</span>
										<span className={styles.part}>
											<span className={styles.percent}>
												{formatPercent(0.5)}
											</span>
											<br />
											{t('howItWorksRebalancing.chart.share')}
											<br />
											{t('howItWorksRebalancing.chart.etf')}
										</span>
									</div>
									<div className={styles.text}>
										<span className={styles.date}>
											{t('howItWorksRebalancing.chart.portfolioAfterRebalancing')}
										</span>
										<span className={styles.value}>
											{t('howItWorksRebalancing.chart.portfolioValueAfterRebalancing')}
										</span>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</Section>
	);
}
