import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { CheckboxField, OnChangeSubmit, RadioList } from '../../../components/forms';
import { Alert } from '../../../components/ui';
import {
	fetchProfilePreference,
	updateProfilePreference,
} from '../../../utils/api';
import useFetchAuth from '../../../utils/useFetchAuth';
import useRequestAuth from '../../../utils/useRequestAuth';

import styles from './ProfilePreference.module.scss';

const useFetchProfilePreference = useFetchAuth(fetchProfilePreference);

const statementPeriodicityOptions = [
	{
		label: 'forms.fields.statementPeriodicity.monthly',
		value: 'monthly',
	},
	{
		label: 'forms.fields.statementPeriodicity.quarterly',
		value: 'quarterly',
	},
];

export default function ProfilePreference() {
	const [preference, loading, errorFetch] = useFetchProfilePreference({});
	const updateProfilePreferenceAuth = useRequestAuth(updateProfilePreference);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [t] = useTranslation();

	if (loading || errorFetch) return null;

	return (
		<div className={styles.root}>
			<h2 className={styles.title}>{t('account.profilePreference.title')}</h2>
			<h3 className={styles.subtitle}>{t('account.profilePreference.subtitle')}</h3>
			<Formik
				initialValues={preference}
				onSubmit={async (values) => {
					setError(false);
					setSuccess(false);

					try {
						await updateProfilePreferenceAuth(
							values.statementPeriodicity,
							values.survey,
							values.marketingEmailsUnsubscribe,
						);
					} catch {
						setError(true);
						return;
					}
					setSuccess(true);
				}}
			>
				{({
					handleChange, handleSubmit, isSubmitting, values,
				}) => (
					<form onSubmit={handleSubmit}>
						<OnChangeSubmit />
						{error && (
							<Alert type="danger">
								{t('forms.error')}
							</Alert>
						)}
						<RadioList
							name="statementPeriodicity"
							id="statementPeriodicity"
							disabled={isSubmitting}
							onChange={handleChange}
							options={statementPeriodicityOptions}
							value={values.statementPeriodicity}
						/>
						<CheckboxField
							id="survey"
							name="survey"
							onChange={handleChange}
							checked={values.survey}
							disabled={isSubmitting}
							label={t('forms.fields.survey.label')}
						/>
						<CheckboxField
							id="marketingEmailsUnsubscribe"
							name="marketingEmailsUnsubscribe"
							onChange={handleChange}
							checked={values.marketingEmailsUnsubscribe}
							disabled={isSubmitting}
							label={t('forms.fields.marketingEmailsUnsubscribe.label')}
						/>
						{success && (
							<Alert
								autoClose
								close
								type="success"
								onClose={() => setSuccess(false)}
							>
								{t('forms.success')}
							</Alert>
						)}
					</form>
				)}
			</Formik>
		</div>
	);
}
