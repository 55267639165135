/* global fetch */
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { changeLanguageUrl } from '../utils/routes';

const noop = () => {};
const LocaleContext = React.createContext(['CZ', 'cs', noop]);

export function useCountry() {
	const [country] = useContext(LocaleContext);
	return country;
}

export function useLanguage() {
	const [, language] = useContext(LocaleContext);
	return language;
}

export function useChangeLanguage() {
	const [, , changeLanguage] = useContext(LocaleContext);
	return changeLanguage;
}

export function LocaleProvider({
	children,
	country,
	language,
	publicUrl,
	switched: switchedInitial,
}) {
	const switched = useRef(switchedInitial);
	const [, i18n] = useTranslation();
	const i18nLangauge = i18n.language;

	const changeLanguage = useCallback((lang, isSwitch = true) => {
		if (!isSwitch && switched.current) {
			return false;
		}

		const url = changeLanguageUrl(publicUrl, country, lang, null, isSwitch);
		if (url === null) {
			return false;
		}

		fetch(url, { redirect: 'manual' });
		i18n.changeLanguage(lang);
		if (isSwitch) {
			switched.current = true;
		}

		return true;
	}, [country, i18n, publicUrl]);

	useEffect(() => {
		if (language !== i18nLangauge) {
			changeLanguage(language);
		}
	}, [changeLanguage, i18nLangauge, language]);

	const value = useMemo(() => [
		country,
		language,
		changeLanguage,
	], [country, language, changeLanguage]);

	return (
		<LocaleContext.Provider value={value}>
			{children}
		</LocaleContext.Provider>
	);
}

LocaleProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	country: PropTypes.string.isRequired,
	language: PropTypes.string.isRequired,
	publicUrl: PropTypes.string.isRequired,
	switched: PropTypes.bool,
};

LocaleProvider.defaultProps = {
	children: null,
	switched: false,
};
