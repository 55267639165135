import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { LoginForm } from '../../components/common';

export default function EmailAlias({
	dashboardUrl,
	registrationUrl,
	social,
}) {
	const navigate = useNavigate();

	useEffect(() => {
		if (social === '') {
			navigate(registrationUrl);
		}
	}, [social, navigate]);

	return (
		<LoginForm
			dashboardUrl={dashboardUrl}
			emailAliasSocial={social}
		/>
	);
}

EmailAlias.propTypes = {
	dashboardUrl: PropTypes.string.isRequired,
	registrationUrl: PropTypes.string.isRequired,
	social: PropTypes.string.isRequired,
};
