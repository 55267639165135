import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useFocusOnError from '../../../utils/useFocusOnError';
import RadioField from '../RadioField';

import styles from './RadioList.module.scss';

export default function RadioList({
	onChange,
	disabled,
	id,
	name,
	options,
	value,
	horizontal,
}) {
	const [t] = useTranslation();
	const elementRef = useRef();
	useFocusOnError(name, null, elementRef);

	return (
		<div ref={elementRef} className={`${styles.root} ${horizontal ? styles.horizontal : ''}`.trim()}>
			{options.map((option) => (
				<RadioField
					key={option.value}
					checked={value === option.value}
					disabled={disabled}
					id={id}
					label={t(option.label)}
					name={name}
					onChange={onChange}
					value={option.value}
				/>
			))}
		</div>
	);
}

RadioList.propTypes = {
	onChange: PropTypes.func,
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		}).isRequired,
	).isRequired,
	value: PropTypes.string,
	horizontal: PropTypes.bool,
};

RadioList.defaultProps = {
	onChange: null,
	disabled: false,
	value: null,
	horizontal: false,
};
