import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { FormLayout } from '../../components/layout';
import {
	Button,
	ButtonLink,
} from '../../components/ui';
import { unsubscribe } from '../../utils/api';

import styles from './Unsubscribe.module.scss';

export default function Unsubscribe({
	code,
	email,
	accountUrl,
	loginUrl,
}) {
	const [t] = useTranslation();
	const [success, setSuccess] = useState(false);

	const handleUnsubscribe = () => {
		unsubscribe(code, email).then(() => {
			setSuccess(true);
		}).catch(() => {
			setSuccess(false);
		});
	};

	return (
		<FormLayout title={t(`unsubscribe.${success ? 'success' : 'default'}.title`)}>
			<p className={styles.content}>
				<Trans i18nKey={`unsubscribe.${success ? 'success' : 'default'}.text`}>
					<Link to={accountUrl} />
				</Trans>
			</p>
			<p className={styles.content}>
				{success ? (
					<ButtonLink label={t('unsubscribe.success.button')} to={loginUrl} />
				) : (
					<Button label={t('unsubscribe.default.button')} onClick={handleUnsubscribe} />
				)}
			</p>
		</FormLayout>
	);
}

Unsubscribe.propTypes = {
	code: PropTypes.string,
	email: PropTypes.string,
	accountUrl: PropTypes.string.isRequired,
	loginUrl: PropTypes.string.isRequired,
};

Unsubscribe.defaultProps = {
	code: '',
	email: '',
};
