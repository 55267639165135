import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { TextField } from '../../forms';
import { Col, Row } from '../../layout';
import { Alert, Button } from '../../ui';
import {
	childEmailChange,
	childEmailVerification,
	childEmailVerificationCancel,
} from '../../../utils/api';
import useRequestAuth from '../../../utils/useRequestAuth';
import { validateChildEmailChange } from '../../../utils/validators';

import styles from './ChildEmailVerification.module.scss';

export default function ChildEmailVerification({
	childEmail,
	childId,
	setCheckOnboarding,
}) {
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [t] = useTranslation();
	const [emailChange, setEmailChange] = useState(false);
	const childEmailVerificationAuth = useRequestAuth(childEmailVerification);
	const childEmailChangeAuth = useRequestAuth(childEmailChange);
	const childEmailVerificationCancelAuth = useRequestAuth(childEmailVerificationCancel);

	const handleSkipVerification = async () => {
		setError(false);
		try {
			await childEmailVerificationCancelAuth(childId);
		} catch {
			setError(true);
			return;
		}
		setCheckOnboarding(true);
	};

	return (
		<div className={styles.root}>
			<div className={styles.section}>
				<h3 className={styles.title}>{t('onboarding.steps.childEmailVerification.title')}</h3>
				<p className={styles.text}>{t('onboarding.steps.childEmailVerification.text')}</p>
			</div>
			{error && (
				<Alert type="danger">
					{t('forms.error')}
				</Alert>
			)}

			<Formik
				enableReinitialize
				initialValues={{
					email: childEmail,
				}}
				validate={(values) => validateChildEmailChange(values)}
				onSubmit={async (values, { setErrors }) => {
					setSuccess(false);
					setError(false);

					try {
						await childEmailChangeAuth(
							childId,
							values.email,
						);
					} catch (e) {
						const fieldErrors = {};
						const errorMessage = e.responseJson?.message;

						if (typeof errorMessage === 'string') {
							if (errorMessage.indexOf('e-mail uniqueness') !== -1) {
								fieldErrors.email = 'forms.fields.child.email.unique';
							}
						}

						const hasFieldErrors = Object.keys(fieldErrors).length > 0;
						if (hasFieldErrors) {
							setErrors(fieldErrors);
						}
						setError(!hasFieldErrors);
						return;
					}

					setSuccess(true);
					setEmailChange(false);
					setCheckOnboarding(true);
				}}
			>
				{({
					errors,
					handleBlur,
					handleChange,
					handleSubmit,
					isSubmitting,
					touched,
					values,
				}) => (
					<form onSubmit={handleSubmit}>
						{success && (
							<Alert type="success">
								{t('onboarding.steps.childEmailVerification.form.email.success')}
							</Alert>
						)}
						<Row middle start>
							<Col sm={6}>
								<TextField
									error={
										errors.email
										&& touched.email
										&& t(errors.email)
									}
									id="email"
									label={t('onboarding.steps.childEmailVerification.form.email.label')}
									name="email"
									onBlur={handleBlur}
									onChange={(e) => {
										handleChange(e);
										setEmailChange(e.target.value !== childEmail);
									}}
									type="text"
									value={values.email}
									required
								/>
							</Col>
							{emailChange && (
								<div className={styles.changeEmail}>
									<Button
										disabled={isSubmitting}
										isSubmit
										label={t('onboarding.steps.childEmailVerification.form.email.change')}
									/>
								</div>
							)}
						</Row>
					</form>
				)}
			</Formik>

			<Formik
				initialValues={{
					confirmationCode: '',
				}}
				onSubmit={async (values, { setErrors }) => {
					setSuccess(false);
					setError(false);

					try {
						await childEmailVerificationAuth(
							childId,
							values.confirmationCode,
						);
					} catch (e) {
						const fieldErrors = {};
						if (e.responseJson?.success === false) {
							fieldErrors.confirmationCode = 'onboarding.steps.childEmailVerification.form.code.invalid';
						}

						const hasFieldErrors = Object.keys(fieldErrors).length > 0;
						if (hasFieldErrors) {
							setErrors(fieldErrors);
						}
						setError(!hasFieldErrors);
						return;
					}

					setCheckOnboarding(true);
				}}
			>
				{({
					errors,
					handleBlur,
					handleChange,
					handleSubmit,
					isSubmitting,
					touched,
					values,
				}) => (
					<form onSubmit={handleSubmit}>
						<div className={styles.section}>
							<Row>
								<Col sm={6}>
									<TextField
										error={
											errors.confirmationCode
											&& touched.confirmationCode
											&& t(errors.confirmationCode)
										}
										id="confirmationCode"
										label={t('onboarding.steps.childEmailVerification.form.code.label')}
										name="confirmationCode"
										onBlur={handleBlur}
										onChange={handleChange}
										type="text"
										value={values.confirmationCode}
										required
									/>
								</Col>
							</Row>
						</div>
						<div className={styles.buttons}>
							<Button
								disabled={isSubmitting || emailChange}
								isSubmit={!emailChange}
								label={t('onboarding.steps.childEmailVerification.form.code.confirm')}
							/>
							<Button
								outline
								label={t('onboarding.steps.childEmailVerification.form.code.skip')}
								onClick={handleSkipVerification}
							/>
						</div>
					</form>
				)}
			</Formik>
		</div>
	);
}

ChildEmailVerification.propTypes = {
	childEmail: PropTypes.string.isRequired,
	childId: PropTypes.number.isRequired,
	setCheckOnboarding: PropTypes.func.isRequired,
};
