import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { useUserContext, useUserReload } from '../../../contexts/UserContext';
import { confirmPersonalInformation } from '../../../utils/api';
import { checkSessionDate, saveSessionDate } from '../../../utils/sessionPersonalData';
import useRequestAuth from '../../../utils/useRequestAuth';
import {
	Alert,
	Button,
	ButtonLink,
	Modal,
} from '../../ui';

import styles from './PersonalInformationCheck.module.scss';

export default function PersonalInformationCheck({
	clientCheckUrl,
}) {
	const [error, setError] = useState(false);
	const [t] = useTranslation();
	const [user] = useUserContext();
	const confirmPersonalInformationAuth = useRequestAuth(confirmPersonalInformation);
	const reloadUser = useUserReload();

	if (user === null || user.contract_missing) {
		return null;
	}

	const handleConfirm = async () => {
		setError(false);

		try {
			await confirmPersonalInformationAuth();
			reloadUser();
		} catch {
			setError(true);
		}
	};

	return (
		<Modal
			isVisible={user.personal_information_check_due && checkSessionDate()}
			title={t('account.personalData.modalInfo.title')}
		>
			<div className={styles.modal}>
				<Trans i18nKey="account.personalData.modalInfo.text">
					<p className={styles.text} />
					<ul className={styles.list}>
						<li className={styles.listText} />
					</ul>
					{{ firstName: user.first_name }}
					{{ lastName: user.last_name }}
					{{ phonePrefix: user.phone_prefix }}
					{{ phoneNumber: user.phone_number }}
					{{ streetName: user.street_name }}
					{{ streetNumber: user.street_number }}
					{{ city: user.city }}
					{{ postalCode: user.postal_code }}
				</Trans>
				<div className={styles.modalControl}>
					{error && (
						<Alert type="danger">
							{t('forms.error')}
						</Alert>
					)}
					<div className={styles.modalControlItem}>
						<Button
							label={t('account.personalData.modalInfo.confirm')}
							onClick={() => handleConfirm()}
						/>
					</div>
					<div className={styles.modalControlItem}>
						<ButtonLink
							label={t('account.personalData.modalInfo.edit')}
							outline
							to={clientCheckUrl}
							onClick={() => saveSessionDate()}
						/>
					</div>
				</div>
			</div>
		</Modal>
	);
}

PersonalInformationCheck.propTypes = {
	clientCheckUrl: PropTypes.string.isRequired,
};
