import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { DocumentUpload } from '../../../components/common';
import {
	Alert,
	ButtonLink,
	Toggle,
	Tooltip,
} from '../../../components/ui';
import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { getProfileOnboardingIdentificationRedirect } from '../../../utils/api';
import useQuery from '../../../utils/useQuery';

import styles from './OnboardingAuthentication.module.scss';

export default function OnboardingAuthentication({
	childId,
	setCheckOnboarding,
}) {
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const query = useQuery();
	const [manualCheck, setManualCheck] = useState(false);

	return (
		<>
			<div className={styles.stepContent}>
				{!!query.get('amlError') && (
					<Alert type="danger">
						{t('onboarding.steps.documents.error')}
					</Alert>
				)}
			</div>
			<div className={styles.stepContent}>
				<Toggle
					labelFirst={t('onboarding.steps.documents.redirect.title')}
					labelSecond={t('onboarding.steps.documents.upload.title')}
					setToggle={setManualCheck}
					toggle={manualCheck}
				/>
				{manualCheck ? (
					<>
						<div className={styles.infoBox}>
							<span className={styles.infoIcon}>
								<svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g clipPath="url(#clip0_4993_6316)">
										<path d="M9.16602 5.83366H10.8327V7.50033H9.16602V5.83366ZM9.16602 9.16699H10.8327V14.167H9.16602V9.16699ZM9.99935 1.66699C5.39935 1.66699 1.66602 5.40033 1.66602 10.0003C1.66602 14.6003 5.39935 18.3337 9.99935 18.3337C14.5993 18.3337 18.3327 14.6003 18.3327 10.0003C18.3327 5.40033 14.5993 1.66699 9.99935 1.66699ZM9.99935 16.667C6.32435 16.667 3.33268 13.6753 3.33268 10.0003C3.33268 6.32533 6.32435 3.33366 9.99935 3.33366C13.6743 3.33366 16.666 6.32533 16.666 10.0003C16.666 13.6753 13.6743 16.667 9.99935 16.667Z" fill="#47202A" />
									</g>
									<defs>
										<clipPath id="clip0_4993_6316">
											<rect width="20" height="20" fill="white" />
										</clipPath>
									</defs>
								</svg>
							</span>
							<div className={styles.infoList}>
								<ul>
									<Trans i18nKey="onboarding.steps.documents.upload.list.formats">
										<li className={styles.infoListTitle}>{t('onboarding.steps.documents.upload.list.formats')}</li>
										<li className={styles.infoListItem}>{t('onboarding.steps.documents.upload.list.formats')}</li>
									</Trans>
								</ul>
							</div>
							<div className={styles.infoList}>
								<ul>
									<Trans i18nKey="onboarding.steps.documents.upload.list.size">
										<li className={styles.infoListTitle}>{t('onboarding.steps.documents.upload.list.size')}</li>
										<li className={styles.infoListItem}>{t('onboarding.steps.documents.upload.list.size')}</li>
									</Trans>
								</ul>
							</div>
						</div>
						<DocumentUpload
							childId={childId}
							setCheckOnboarding={setCheckOnboarding}
						/>
					</>
				) : (
					<>
						<p className={styles.text}>
							{t('onboarding.steps.documents.redirect.text')}
						</p>
						<div className={styles.infoBox}>
							<span className={styles.infoIcon}>
								<svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g clipPath="url(#clip0_4993_6316)">
										<path d="M9.16602 5.83366H10.8327V7.50033H9.16602V5.83366ZM9.16602 9.16699H10.8327V14.167H9.16602V9.16699ZM9.99935 1.66699C5.39935 1.66699 1.66602 5.40033 1.66602 10.0003C1.66602 14.6003 5.39935 18.3337 9.99935 18.3337C14.5993 18.3337 18.3327 14.6003 18.3327 10.0003C18.3327 5.40033 14.5993 1.66699 9.99935 1.66699ZM9.99935 16.667C6.32435 16.667 3.33268 13.6753 3.33268 10.0003C3.33268 6.32533 6.32435 3.33366 9.99935 3.33366C13.6743 3.33366 16.666 6.32533 16.666 10.0003C16.666 13.6753 13.6743 16.667 9.99935 16.667Z" fill="#47202A" />
									</g>
									<defs>
										<clipPath id="clip0_4993_6316">
											<rect width="20" height="20" fill="white" />
										</clipPath>
									</defs>
								</svg>
							</span>
							<div className={styles.infoList}>
								<ul>
									<li className={styles.infoListTitle}>
										{t('onboarding.steps.documents.redirect.listTitle')}
									</li>
									<Trans i18nKey={childId !== null ? 'onboarding.steps.documents.redirect.listChild' : 'onboarding.steps.documents.redirect.list'}>
										<li className={`${styles.infoListItem} ${styles.isOrdered}`.trim()} />
									</Trans>
								</ul>
							</div>
						</div>
						<div className={styles.wrap}>
							<ButtonLink
								label={t('onboarding.steps.documents.redirect.title')}
								to={getProfileOnboardingIdentificationRedirect(childId, country, language)}
								external
							/>
							<span className={styles.tooltip}>
								<Tooltip text={(
									<Trans i18nKey="onboarding.steps.documents.tooltip">
										<p className={styles.tooltipText} />
										<ul className={styles.tooltipList}>
											<li className={styles.tooltipItem} />
										</ul>
									</Trans>
								)}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 512 512"
										width={15}
									>
										<path
											fill="currentColor"
											d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655
												90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947
												12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0
												14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0
												12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365
												0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
										/>
									</svg>
								</Tooltip>
							</span>
						</div>
					</>
				)}
			</div>
		</>
	);
}

OnboardingAuthentication.propTypes = {
	childId: PropTypes.number,
	setCheckOnboarding: PropTypes.func.isRequired,
};

OnboardingAuthentication.defaultProps = {
	childId: null,
};
