import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { Container } from '../../../components/layout';
import isScreenWidthUp from '../../../utils/isScreenWidthUp';
import useFormatNumber from '../../../utils/useFormatNumber';
import useWindowSize from '../../../utils/useWindowSize';
import InvestingPriceChange from '../InvestingPriceChange';

import styles from './InvestingTable.module.scss';

const formatDate = (dateString) => {
	if (typeof dateString !== 'string') {
		return null;
	}

	const match = dateString.match(/^(\d{4,})-(\d{2})-(\d{2})$/);
	return match !== null ? `${match[2]}/${match[1]}` : null;
};

const getDatePrice = (prices, key) => {
	const value = prices[key] ?? null;
	if (value !== null) {
		return value;
	}

	const keys = Object.keys(prices);
	if (keys.length === 0) {
		return 1;
	}

	return key < keys[0] ? prices[keys[0]] : prices[keys[keys.length - 1]];
};

export default function InvestingTable({
	data,
	fromDate,
	income,
	values,
	toDate,
}) {
	const [t] = useTranslation();
	const formatCurrency = useFormatNumber({ style: 'currency', currency: 'CZK' });
	const windowSize = useWindowSize();

	const getValue = (i) => values[i] / 100;

	const getPrice = (i) => (
		data[i]?.prices ? getDatePrice(data[i].prices, toDate) / getDatePrice(data[i].prices, fromDate) : 1
	);

	const assetValue = (i) => getValue(i) * getPrice(i);

	const assetRevaluation = (i) => getPrice(i) - 1;

	const submitted = values !== null && fromDate !== null && toDate !== null && income !== null;
	const finalResult = submitted
		? data.reduce((sum, _, i) => (values[i] > 0 ? sum + income * assetValue(i) : sum), 0)
		: null;

	const fromLabel = formatDate(fromDate);
	const toLabel = formatDate(toDate);

	return (
		<Container variant="narrower" center>
			<h5 className={styles.title}>
				{t('investingTable.title')}
			</h5>
			{submitted ? (
				<>
					<p>
						<Trans i18nKey="investingTable.fromTo">
							<strong />
							{{ from: fromLabel }}
							{{ to: toLabel }}
						</Trans>
					</p>
					<p className={styles.evaluation}>
						{formatCurrency(finalResult)}
					</p>
					<div className={styles.wrap}>
						<InvestingPriceChange
							value={finalResult - income}
							arrow
						/>
						<InvestingPriceChange
							value={(finalResult / income) - 1}
							arrow
							ratio
						/>
					</div>
					<div className={styles.tableWrap}>
						<table className={styles.table}>
							<thead>
								<tr>
									<th className={`${styles.th} ${styles.hidden}`}>
										{t('investingTable.name')}
									</th>
									<th className={styles.th}>
										{t('investingTable.begin')}
										&nbsp;
										<span className={styles.bold}>
											{fromLabel}
										</span>
										{!isScreenWidthUp(windowSize.width, 'md') && (
											<>
												<br />
												{t('investingTable.end')}
												&nbsp;
												<span className={styles.bold}>
													{toLabel}
												</span>
											</>
										)}
									</th>
									{isScreenWidthUp(windowSize.width, 'md') && (
										<th className={styles.th}>
											{t('investingTable.end')}
											&nbsp;
											<span className={styles.bold}>
												{toLabel}
											</span>
										</th>
									)}
									<th className={`${styles.th} ${styles.semibold}`}>
										{t('investingTable.evaluation')}
									</th>
								</tr>
							</thead>
							<tbody className={styles.tbody}>
								{data !== null ? data.map((item, index) => !!values[index] && (
									<tr key={item.name}>
										<td>
											<span className={styles.name}>
												<span className={styles.indicator} style={{ backgroundColor: `#${item.colorHex}` }} />
												{item.name}
											</span>
										</td>
										<td>
											{formatCurrency(income * getValue(index))}
											{!isScreenWidthUp(windowSize.width, 'md') && (
												<>
													<br />
													{formatCurrency(income * assetValue(index))}
												</>
											)}
										</td>
										{isScreenWidthUp(windowSize.width, 'md') && (
											<td>
												{formatCurrency(income * assetValue(index))}
											</td>
										)}
										<td>
											<InvestingPriceChange
												value={assetRevaluation(index)}
												ratio
											/>
										</td>
									</tr>
								)) : (
									<tr>
										<td colSpan={4} className={styles.center}>
											{t('investingTable.empty')}
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</>
			) : <p className={styles.paragraph}>{t('investingTable.default')}</p>}
		</Container>
	);
}

InvestingTable.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			colorHex: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			prices: PropTypes.objectOf(PropTypes.number.isRequired).isRequired,
		}),
	).isRequired,
	fromDate: PropTypes.string,
	income: PropTypes.number,
	values: PropTypes.arrayOf(PropTypes.number.isRequired),
	toDate: PropTypes.string,
};

InvestingTable.defaultProps = {
	fromDate: null,
	income: null,
	values: null,
	toDate: null,
};
