import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import useFormatNumber from '../../../utils/useFormatNumber';
import { Container, Section } from '../../layout';
import { ExternalLink, Tooltip } from '../../ui';

import styles from './PortfolioComposition.module.scss';

export default function PortfolioComposition({
	accountUrl,
	portfolioComposition,
	portfolioCurrency,
}) {
	const [t] = useTranslation();
	const formatNumber = useFormatNumber();
	const formatCurrency = (value, currency, fractionDigits = null) => formatNumber(value, {
		style: 'currency',
		currency: currency?.code,
		fractionDigits,
	});

	return (
		<Section bordered>
			<Container>
				<h2 className={styles.title}>
					{t('portfolioComposition.title')}
				</h2>
				<div className={styles.profile}>
					<strong>
						{t('portfolioComposition.profile')}
						{' '}
						<Tooltip
							text={(
								<Trans i18nKey="portfolioComposition.profileTooltip">
									<Link to={accountUrl} />
								</Trans>
							)}
						>
							{portfolioComposition?.investorProfileName}
						</Tooltip>
					</strong>
					{portfolioComposition?.investorProfileId !== portfolioComposition?.investorProfilePlannedId && (
						<>
							<br />
							{t('portfolioComposition.plannedProfile')}
							{' '}
							{portfolioComposition?.investorProfilePlannedName}
						</>
					)}
				</div>
				<div className={styles.tableWrap}>
					<table className={styles.table}>
						<thead>
							<tr>
								<th className={styles.name}>
									{t('portfolioComposition.table.columns.name')}
								</th>
								<th className={styles.right}>
									{t('portfolioComposition.table.columns.isin')}
								</th>
								<th className={styles.rightPartSmall}>
									{t('portfolioComposition.table.columns.amount')}
								</th>
								<th className={styles.rightPart}>
									{t('portfolioComposition.table.columns.pricePerVolume')}
								</th>
								<th className={styles.rightPartSmall}>
									{t('portfolioComposition.table.columns.totalValue')}
								</th>
								<th className={styles.rightPart}>
									{t('portfolioComposition.table.columns.information')}
								</th>
							</tr>
						</thead>
						<tbody>
							{(portfolioComposition?.overviewEtf ?? []).filter((etf) => etf.volume).map((etf, i) => {
								const key = `etf${i}`;
								return (
									<tr key={key}>
										<td className={styles.name} title={etf.etfName}>
											{etf.etfName}
										</td>
										<td className={styles.right}>
											{etf.etfIsin}
										</td>
										<td className={styles.right}>
											{formatNumber(etf.amount)}
										</td>
										<td className={styles.right}>
											{formatCurrency(etf.price, etf.etfCurrency, 2)}
										</td>
										<td className={styles.right}>
											{etf.originalVolume ? (
												<Tooltip
													text={formatCurrency(etf.originalVolume, etf.originalCurrency, 2)}
												>
													{formatCurrency(etf.volume, portfolioCurrency)}
												</Tooltip>
											) : formatCurrency(etf.volume, portfolioCurrency)}
										</td>
										<td className={styles.right}>
											<ExternalLink
												to={`${process.env.PUBLIC_URL}/${etf.kiidUrl}`}
												blank
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 512 512"
													width={15}
												>
													<path
														fill="currentColor"
														d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
													/>
												</svg>
											</ExternalLink>
										</td>
									</tr>
								);
							})}
							{(portfolioComposition?.overviewCash ?? []).filter((cash) => cash.amount).map((cash, i) => {
								const key = `cash${i}`;
								return (
									<tr key={key} className={i === 0 ? styles.divider : null}>
										<td className={styles.name}>
											{i === 0 && t('portfolioComposition.table.rows.cash')}
										</td>
										<td className={styles.right} />
										<td className={styles.right} />
										<td className={styles.right} />
										<td className={styles.right}>
											{cash.exchangeRate ? (
												<Tooltip
													text={`${t('portfolioComposition.table.rows.exchange')} ${
														formatCurrency(cash.exchangeRate.quantity, cash.cashCurrency)
													} = ${
														formatCurrency(cash.exchangeRate.rate, portfolioCurrency)
													}`}
												>
													{formatCurrency(cash.amount, cash.cashCurrency)}
												</Tooltip>
											) : formatCurrency(cash.amount, cash.cashCurrency)}
										</td>
										<td className={styles.right} />
									</tr>
								);
							})}
						</tbody>
						<tfoot>
							<tr>
								<th>
									{t('portfolioComposition.table.rows.total')}
									<Tooltip text={t('portfolioComposition.table.rows.totalTooltip')}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 512 512"
											width={20}
										>
											<path
												fill="currentColor"
												d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
											/>
										</svg>
									</Tooltip>
								</th>
								<th>
									{' '}
								</th>
								<th>
									{' '}
								</th>
								<th>
									{' '}
								</th>
								<th className={styles.right}>
									{formatCurrency(portfolioComposition?.totalValue, portfolioCurrency)}
								</th>
								<th>
									{' '}
								</th>
							</tr>
						</tfoot>
					</table>
				</div>
			</Container>
		</Section>
	);
}

PortfolioComposition.propTypes = {
	accountUrl: PropTypes.string.isRequired,
	portfolioComposition: PropTypes.shape({
		investorProfileId: PropTypes.number,
		investorProfileName: PropTypes.string,
		investorProfilePlannedId: PropTypes.number,
		investorProfilePlannedName: PropTypes.string,
		overviewEtf: PropTypes.arrayOf(PropTypes.object.isRequired),
		overviewCash: PropTypes.arrayOf(PropTypes.object.isRequired),
		totalValue: PropTypes.number,
	}),
	portfolioCurrency: PropTypes.shape({
		code: PropTypes.string.isRequired,
		codeShort: PropTypes.string.isRequired,
		codeShortBefore: PropTypes.bool.isRequired,
	}),
};

PortfolioComposition.defaultProps = {
	portfolioComposition: null,
	portfolioCurrency: null,
};
