import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { BankAccountField, RadioField } from '../../../components/forms';
import {
	Alert,
	Button,
	Modal,
	Tooltip,
} from '../../../components/ui';
import { useCountry } from '../../../contexts/LocaleContext';
import { changeMainAccount, addBankAccount } from '../../../utils/api';
import formatAccountNumber from '../../../utils/formatAccountNumber';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import useRequestAuth from '../../../utils/useRequestAuth';
import { validateBankAccount } from '../../../utils/validators';

import styles from './BankAccountsControl.module.scss';

export default function BankAccountsControl({
	accounts,
	reloadUser,
}) {
	const addBankAccountAuth = useRequestAuth(addBankAccount);
	const changeMainAccountAuth = useRequestAuth(changeMainAccount);
	const [changeError, setChangeError] = useState(false);
	const [addError, setAddError] = useState(false);
	const [addAccount, setAddAccount] = useState(false);
	const [confirmChange, setConfirmChange] = useState(false);
	const [t] = useTranslation();
	const trackGTMEvent = useTrackGTMEvent();
	const country = useCountry();

	useEffect(() => {
		setAddError(false);
	}, [addAccount]);

	const userAccounts = Object.values(accounts ?? {}).filter((a) => a.status !== 2);
	const main = userAccounts.filter((a) => !!a.main)[0]?.id ?? null;

	return (
		<div className={styles.root}>
			<Formik
				enableReinitialize
				initialValues={{
					main: main !== null ? String(main) : null,
				}}
				onSubmit={async (values) => {
					setChangeError(false);
					try {
						await changeMainAccountAuth(values.main);
					} catch (e) {
						setChangeError(true);
						return;
					}
					setConfirmChange(false);
					reloadUser();
				}}
			>
				{({
					handleChange, handleReset, handleSubmit, isSubmitting, values,
				}) => (
					<form onSubmit={() => setConfirmChange(true)}>
						{changeError && (
							<Alert type="danger">
								{t('forms.error')}
							</Alert>
						)}
						<div className={styles.head}>
							<div className={styles.title}>
								{t('account.bankAccountsControl.title')}
							</div>
							<div className={styles.title}>
								{t('account.bankAccountsControl.withdrawal')}
								<span className={styles.titleIcon}>
									<Tooltip
										text={t('account.bankAccountsControl.withdrawalTooltip')}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 512 512"
											width={15}
										>
											<path
												fill="currentColor"
												d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
											/>
										</svg>
									</Tooltip>
								</span>
							</div>
						</div>
						{userAccounts.map((account) => (
							<div key={account.id} className={styles.row}>
								<div className={styles.accountStatus}>
									{account.status === 1 ? (
										<svg
											width={18}
											height={18}
											viewBox="0 0 30 30"
											xmlns="http://www.w3.org/2000/svg"
											fillRule="evenodd"
											clipRule="evenodd"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeMiterlimit="1.5"
										>
											<path d="M7.247 15l4.454 4.269L22.837 8.774" fill="none" stroke="#48ba58" strokeWidth="3.75" />
										</svg>
									) : (
										<Tooltip
											text={t('account.bankAccountsControl.unconfirmedTooltip')}
										>
											<svg
												width={18}
												height={18}
												viewBox="0 0 64 64"
												xmlns="http://www.w3.org/2000/svg"
												fillRule="evenodd"
												clipRule="evenodd"
												strokeLinejoin="round"
												strokeMiterlimit="2"
											>
												<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(5.074 -26.936) scale(1.02762)" />
												<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(-17.538 -26.619) scale(1.20166)" />
												<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="matrix(.3802 0 0 .3802 -2.085 19.179)" />
												<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="matrix(.48317 0 0 .48317 1.289 33.752)" />
												<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(16.138 36.19) scale(.60537)" />
												<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(30.551 25.014) scale(.74228)" />
												<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(33.962 3.65) scale(.84072)" />
												<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(25.674 -17.054) scale(.9284)" />
											</svg>
										</Tooltip>
									)}
								</div>
								<div className={styles.accountNumber}>
									{formatAccountNumber(account, country)}
								</div>
								<div className={styles.accountControl}>
									<RadioField
										checked={values.main === String(account.id)}
										disabled={account.status === 0}
										id="main"
										label="main"
										name="main"
										onChange={(e) => {
											handleChange(e);
											setConfirmChange(true);
										}}
										value={String(account.id)}
										isLabelHidden
									/>
								</div>
							</div>
						))}
						<Modal
							isVisible={confirmChange}
							onClose={() => {
								setConfirmChange(false);
								handleReset();
							}}
							title={t('account.bankAccountsControl.confirmChange.title')}
						>
							<p className={styles.modalText}>
								{t('account.bankAccountsControl.confirmChange.text')}
							</p>
							<div className={styles.modalControl}>
								<div className={styles.modalControlItem}>
									<Button
										label={t('account.bankAccountsControl.confirmChange.submit')}
										disabled={isSubmitting}
										onClick={handleSubmit}
									/>
								</div>
								<div className={styles.modalControlItem}>
									<Button
										label={t('account.bankAccountsControl.confirmChange.cancel')}
										disabled={isSubmitting}
										outline
										onClick={() => {
											setConfirmChange(false);
											handleReset();
										}}
									/>
								</div>
							</div>
						</Modal>
					</form>
				)}
			</Formik>
			{userAccounts.length < 3 && (
				<div className={styles.buttonWrap}>
					<Button
						label={t('account.bankAccountsControl.addAccount.button')}
						outline
						isFlat
						onClick={() => {
							trackGTMEvent('interactions', {
								eventCategory: 'interactions',
								eventAction: 'buttonClick',
								eventLabel: 'pridat_bankovni_ucet',
							});
							setAddAccount(true);
						}}
					/>
					<span className={styles.titleIcon}>
						<Tooltip
							text={(
								<>
									{Object.keys(t('account.bankAccountsControl.addAccount.buttonTooltip', { returnObjects: true })).map((item) => (
										<Fragment key={item}>
											{item > 0 && <br />}
											{t(`account.bankAccountsControl.addAccount.buttonTooltip.${item}`)}
										</Fragment>
									))}
								</>
							)}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512"
								width={15}
							>
								<path
									fill="currentColor"
									d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
								/>
							</svg>
						</Tooltip>
					</span>
					<Formik
						initialValues={{
							bankAccount: '',
							bankAccountIBAN: '',
							bankAccountType: 'local',
							bankCode: '',
							bankPrefix: '',
						}}
						validate={(values) => validateBankAccount(values, country)}
						onSubmit={async (values, { resetForm, setErrors }) => {
							const isTypeIBAN = values.bankAccountType === 'iban';
							const bankPrefix = !isTypeIBAN && country !== 'PL' && values.bankPrefix.length > 0 ? values.bankPrefix : null;
							const bankAccount = !isTypeIBAN ? values.bankAccount.replace(/\s+/g, '') : null;
							const bankCode = !isTypeIBAN && country !== 'PL' ? values.bankCode : null;
							const bankAccountIBAN = isTypeIBAN ? values.bankAccountIBAN.replace(/\s+/g, '') : null;
							setAddError(false);

							try {
								await addBankAccountAuth(
									bankPrefix,
									bankAccount,
									bankCode,
									bankAccountIBAN,
								);
							} catch (e) {
								const fieldErrors = {};
								const errorMessage = e.responseJson?.message;
								if (typeof errorMessage === 'string') {
									if (errorMessage.indexOf('Bank code') !== -1) {
										fieldErrors.bankCode = 'forms.fields.bankAccount.invalidCode';
									}
								}

								const hasFieldErrors = Object.keys(fieldErrors).length > 0;
								if (hasFieldErrors) {
									setErrors(fieldErrors);
								}
								setAddError(!hasFieldErrors);
								return;
							}
							setAddAccount(false);
							resetForm();
							reloadUser();
						}}
					>
						{({
							errors,
							handleBlur,
							handleReset,
							handleSubmit,
							isSubmitting,
							setFieldValue,
							touched,
							values,
						}) => (
							<Modal
								isVisible={addAccount}
								onClose={() => {
									setAddAccount(false);
									handleReset();
								}}
								size="large"
								title={t('account.bankAccountsControl.addAccount.title')}
							>
								<form onSubmit={handleSubmit}>
									{addError && (
										<Alert type="danger">
											{t('forms.error')}
										</Alert>
									)}
									{Object.keys(t('account.bankAccountsControl.addAccount.text', { returnObjects: true })).map((item) => (
										<p key={item} className={styles.modalText}>
											{t(`account.bankAccountsControl.addAccount.text.${item}`)}
										</p>
									))}
									<div className={styles.modalAccount}>
										<BankAccountField
											country={country}
											errorBankAccount={
												(
													errors.bankPrefix
													&& touched.bankPrefix
													&& t(errors.bankPrefix)
												) || (
													errors.bankAccount
													&& touched.bankAccount
													&& t(errors.bankAccount)
												) || (
													errors.bankCode
													&& touched.bankCode
													&& t(errors.bankCode)
												)
											}
											errorBankAccountIBAN={
												errors.bankAccountIBAN
												&& touched.bankAccountIBAN
												&& t(errors.bankAccountIBAN)
											}
											idAccount="bankAccount"
											idAccountIBAN="bankAccountIBAN"
											idAccountType="bankAccountType"
											idCode="bankCode"
											idPrefix="bankPrefix"
											label={t('forms.fields.bankAccount.label')}
											nameAccount="bankAccount"
											nameAccountIBAN="bankAccountIBAN"
											nameAccountType="bankAccountType"
											nameCode="bankCode"
											namePrefix="bankPrefix"
											onBlur={handleBlur}
											onChange={setFieldValue}
											required
											valueAccount={values.bankAccount}
											valueAccountIBAN={values.bankAccountIBAN}
											valueAccountType={values.bankAccountType}
											valueCode={values.bankCode}
											valuePrefix={values.bankPrefix}
											warning={
												touched.bankAccountIBAN
												&& !errors.bankAccountIBAN
												&& values.bankAccountType === 'iban'
												&& values.bankAccountIBAN
												&& values.bankAccountIBAN.length !== (country === 'PL' ? 28 : 24)
													? t('forms.fields.bankAccountIBAN.warning')
													: ''
											}
										/>
									</div>
									<div className={styles.modalControl}>
										<div className={styles.modalControlItem}>
											<Button
												label={t('account.bankAccountsControl.addAccount.submit')}
												disabled={isSubmitting}
												isSubmit
											/>
										</div>
										<div className={styles.modalControlItem}>
											<Button
												label={t('account.bankAccountsControl.addAccount.cancel')}
												disabled={isSubmitting}
												outline
												onClick={() => {
													setAddAccount(false);
													handleReset();
												}}
											/>
										</div>
									</div>
								</form>
							</Modal>
						)}
					</Formik>
				</div>
			)}
		</div>
	);
}

BankAccountsControl.propTypes = {
	accounts: PropTypes.objectOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			prefix: PropTypes.string,
			number: PropTypes.string.isRequired,
			bankCode: PropTypes.string.isRequired,
			main: PropTypes.bool.isRequired,
			status: PropTypes.number.isRequired,
		}).isRequired,
	),
	reloadUser: PropTypes.func.isRequired,
};

BankAccountsControl.defaultProps = {
	accounts: {},
};
