/* global window */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { saveCookieConsent } from '../../../utils/api';
import { getCookieConsentValue, setCookieConsentValue } from '../../../utils/cookieConsent';
import { useSendDataToGTM } from '../../../utils/GTMProvider';
import runCookieConsentScripts from '../../../utils/runCookieConsentScripts';
import useWindowSize from '../../../utils/useWindowSize';
import { CheckboxField } from '../../forms';
import {
	Alert,
	Button,
	ExternalLink,
	Logo,
} from '../../ui';

import styles from './CookieConsent.module.scss';

const cookieConsentValue = getCookieConsentValue();

const HEADER_HEIGHT = 80;
const COOKIE_CONSENT_SPACING = 15;

export default function CookieConsent({
	cookiesUrl,
	investing,
	personalInfoAgreementUrl,
	showCookies,
	setShowCookies,
}) {
	const categories = investing ? ['functionality', 'analytics'] : ['functionality', 'analytics', 'marketing'];
	const [active, settingsOpen] = showCookies;
	const setActive = useCallback(
		(newActive) => setShowCookies(([, currentSettingsOpen]) => [newActive, currentSettingsOpen]),
		[setShowCookies],
	);
	const setSettingsOpen = useCallback(
		(newSettingsOpen) => setShowCookies(([currentActive]) => [currentActive, newSettingsOpen]),
		[setShowCookies],
	);
	const [error, setError] = useState(false);
	const [selected, setSelected] = useState(cookieConsentValue ?? ['functionality']);
	const sendDataToGTM = useSendDataToGTM();
	const location = !investing ? useLocation() : { pathname: '/' };
	const [t] = useTranslation();
	const windowSize = useWindowSize();

	const pushToGtm = (cat, pageView = true) => {
		const data = {
			functionality_consent: 1,
			analytics_consent: cat.indexOf('analytics') !== -1 ? 1 : 0,
			marketing_consent: cat.indexOf('marketing') !== -1 ? 1 : 0,
		};

		sendDataToGTM({
			...data,
			...(pageView ? {
				event: 'pageview',
				page: {
					url: location.pathname,
				},
			} : {}),
		});
	};

	const handleSubmit = async (cat) => {
		let uuid;
		setError(false);

		try {
			const response = await saveCookieConsent(cat, window.location.href);
			if (response?.uuid) {
				uuid = response.uuid;
			} else {
				setError(true);
				return;
			}
		} catch {
			setError(true);
			return;
		}

		setCookieConsentValue(cat, uuid, investing);
		setSelected(cat);
		setActive(false);
		pushToGtm(cat);
		runCookieConsentScripts(cat);
	};

	useEffect(() => {
		if (cookieConsentValue) {
			pushToGtm(cookieConsentValue, false);
			runCookieConsentScripts(cookieConsentValue);
		}
	}, []);

	if (!active) return null;

	const style = windowSize.height !== null
		? { maxHeight: `${windowSize.height - HEADER_HEIGHT - COOKIE_CONSENT_SPACING}px` }
		: null;

	return (
		<div className={styles.root} style={style}>
			<div className={`${styles.header} ${investing ? styles.headerInvesting : ''}`.trim()}>
				{settingsOpen && (
					<button
						type="button"
						className={styles.back}
						onClick={() => setSettingsOpen(false)}
					>
						{t('Back')}
					</button>
				)}
				{!investing && (
					<div className={styles.logo}>
						<Logo />
					</div>
				)}
				<button
					type="button"
					className={styles.close}
					aria-label="Close"
					onClick={() => setActive(false)}
				>
					<span aria-hidden="true">×</span>
				</button>
			</div>
			<div className={styles.body}>
				{error && (
					<Alert type="danger" autoScroll={false}>
						{t('forms.error')}
					</Alert>
				)}
				{settingsOpen ? (
					<>
						{categories.map((cat) => (
							<div key={cat}>
								<CheckboxField
									checked={selected.indexOf(cat) > -1}
									disabled={cat === 'functionality'}
									id={cat}
									isLarge
									label={t(`cookieConsent.categories.${cat}.title`)}
									name={cat}
									noError
									onChange={() => {
										setSelected(
											selected.includes(cat)
												? selected.filter((i) => i !== cat) : [...selected, cat],
										);
									}}
								/>
								<p className={styles.categoryText}>
									{t(`cookieConsent.categories.${cat}.text`)}
								</p>
							</div>
						))}
					</>
				) : (
					<Trans i18nKey="cookieConsent.message">
						<p className={styles.text}>
							<ExternalLink
								className={styles.link}
								blank
								to={personalInfoAgreementUrl}
							/>
							{!investing ? (
								<Link
									className={styles.link}
									to={cookiesUrl}
								/>
							) : (
								<ExternalLink
									className={styles.link}
									blank
									to={cookiesUrl}
								/>
							)}
						</p>
					</Trans>
				)}
			</div>
			<div className={styles.footer}>
				{settingsOpen ? (
					<div className={styles.control}>
						<Button onClick={() => handleSubmit(selected)} label={t('cookieConsent.controls.selected')} outline isSmall />
					</div>
				) : (
					<>
						<a
							href="#details"
							onClick={(e) => {
								e.preventDefault();
								setSettingsOpen(true);
							}}
							className={styles.controlLink}
						>
							{t('cookieConsent.controls.details')}
						</a>
						<div className={styles.control}>
							<Button onClick={() => handleSubmit(['functionality'])} label={t('cookieConsent.controls.necessary')} outline isSmall />
						</div>
					</>
				)}
				<div className={styles.control}>
					<Button onClick={() => handleSubmit(categories)} label={t('cookieConsent.controls.all')} isSmall />
				</div>
			</div>
		</div>
	);
}

CookieConsent.propTypes = {
	cookiesUrl: PropTypes.string.isRequired,
	investing: PropTypes.bool,
	personalInfoAgreementUrl: PropTypes.string.isRequired,
	showCookies: PropTypes.arrayOf(
		PropTypes.bool.isRequired,
	).isRequired,
	setShowCookies: PropTypes.func.isRequired,
};

CookieConsent.defaultProps = {
	investing: false,
};
