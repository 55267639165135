/* global document */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
	ChildData,
	ChildEmailVerification,
	OnboardingAgreement,
	OnboardingPayment,
	OnboardingProfile,
	OnboardingQuestionnaire,
	ProgressIcon,
} from '../../../components/common';
import { Button, Toggle } from '../../../components/ui';
import { useUserReload } from '../../../contexts/UserContext';
import { childOnboardingCloseAccount } from '../../../utils/api';
import useRequestAuth from '../../../utils/useRequestAuth';
import OnboardingAuthentication from '../../Onboarding/OnboardingAuthentication';

import styles from './OnboardingSteps.module.scss';

const steps = [
	{
		id: 1,
		title: 'onboarding.steps.profileType.title',
	},
	{
		id: 2,
		title: 'onboarding.steps.childData.title',
	},
	{
		id: 20,
		title: 'onboarding.steps.questionnaires.childTitle',
	},
	{
		id: 30,
		title: 'onboarding.steps.documents.childTitle',
	},
	{
		id: 50,
		title: 'onboarding.steps.agreement.title',
	},
	{
		id: 60,
		title: 'onboarding.steps.payment.title',
	},
];

const questionnaires = [
	'AML',
	'ESG',
];

export default function OnboardingSteps({
	blogPostEsgRegulationUrl,
	childId,
	conditionsUrl,
	dashboardUrl,
	faqUrl,
	personalInfoAgreementUrl,
	process,
	setCheckOnboarding,
}) {
	const [t] = useTranslation();
	const navigate = useNavigate();
	const [esg, setEsg] = useState(false);
	const [product, setProduct] = useState(null);
	const closeAccountAuth = useRequestAuth(childOnboardingCloseAccount);
	const reloadUser = useUserReload();
	const step = (process?.step === 1 ? 2 : null)
		?? (process !== null ? process.step * 10 : null)
		?? (product !== null ? 2 : 1);

	useEffect(() => {
		if (step || questionnaires.includes(process?.questionnaire)) {
			setTimeout(() => {
				const elementId = `step${step}`;
				document.getElementById(elementId)?.scrollIntoView({
					behavior: 'smooth',
				});
			}, 200);
		}
	}, [step]);

	const handleCloseAccount = async () => {
		await closeAccountAuth(childId);
		navigate(dashboardUrl);
		reloadUser();
	};

	return (
		<ul className={styles.root}>
			{steps.map((item, index) => (
				<li key={item.id} id={`step${item.id}`} className={`${styles.step} ${item.id === step ? styles.isStepActive : ''}`.trim()}>
					<div className={styles.stepHeader}>
						<div className={styles.stepNumber}>
							{`0${index + 1}`}
							{item.id <= step && (
								<span className={styles.stepIcon}>
									<ProgressIcon completed={item.id < step} />
								</span>
							)}
						</div>
						<div className={styles.stepInfo}>
							<h2 className={styles.stepTitle}>{t(item.title)}</h2>
						</div>
					</div>

					{item.id === 1 && item.id === step && (
						<div className={styles.stepContent}>
							<div className={styles.toggleWrap}>
								<div className={styles.toggleLabel}>
									{t('investorProfilePreview.toggleLabel')}
								</div>
								<Toggle
									labelFirst={t('investorProfilePreview.classicPortfolios')}
									labelSecond={t('investorProfilePreview.esgPortfolios')}
									setToggle={setEsg}
									toggle={esg}
									tooltipFirst={t('investorProfileChoice.tooltip.classic')}
									tooltipSecond={t('investorProfileChoice.tooltip.esg')}
								/>
							</div>
							{esg ? (
								<p>
									{t('investorProfileChoice.category.esg')}
								</p>
							) : (
								<p>
									{t('investorProfileChoice.category.classic')}
								</p>
							)}
							<Button
								label={t('portfolio.new.modal.add')}
								onClick={() => setProduct(esg ? 'esg' : 'classic')}
							/>
						</div>
					)}

					{item.id === 2 && item.id === step && (process === null ? (
						<ChildData
							conditionsUrl={conditionsUrl}
							personalInfoAgreementUrl={personalInfoAgreementUrl}
							product={product}
							setCheckOnboarding={setCheckOnboarding}
						/>
					) : (
						<ChildEmailVerification
							childEmail={process?.emailPlanned}
							childId={childId}
							setCheckOnboarding={setCheckOnboarding}
						/>
					))}

					{item.id === 20 && (
						<>
							<div className={styles.stepContent}>
								{item.id === step && (
									<p>
										{t('onboarding.steps.questionnaire.activeChild')}
									</p>
								)}
								{item.id < step && (
									<>
										<p>
											{t('onboarding.steps.questionnaire.done')}
										</p>
										{process?.investorProfileId && (
											<OnboardingProfile selectedProfileId={process?.investorProfileId} />
										)}
									</>
								)}
							</div>
							{item.id === step && (
								<OnboardingQuestionnaire
									blogPostEsgRegulationUrl={blogPostEsgRegulationUrl}
									childId={childId}
									closeAccount={handleCloseAccount}
									faqUrl={faqUrl}
									isStarted={questionnaires.includes(process?.questionnaire)}
									processQuestionnaires={process?.questionnaires ?? []}
									product={process?.product ?? product ?? 'classic'}
									setCheckOnboarding={setCheckOnboarding}
									type={process?.questionnaire ?? 'investment'}
								/>
							)}
						</>
					)}

					{item.id === 30 && item.id === step && (
						<OnboardingAuthentication
							childId={childId}
							setCheckOnboarding={setCheckOnboarding}
						/>
					)}

					{item.id === 50 && item.id === step && (
						<>
							<div className={styles.stepContent}>
								<p>
									{t('onboarding.steps.agreement.active')}
								</p>
								<p>
									{t('onboarding.steps.agreement.timeOutText')}
								</p>
							</div>
							<div className={styles.stepContent}>
								<OnboardingAgreement
									category={process?.category}
									childId={childId}
									setCheckOnboarding={setCheckOnboarding}
								/>
							</div>
						</>
					)}

					{item.id === 60 && item.id === step && (
						<>
							<div className={styles.stepContent}>
								<p>
									{t('onboarding.steps.payment.active')}
								</p>
								<Trans i18nKey="onboarding.steps.payment.info">
									<span className={styles.listTitle} />
									<ul className={styles.list}>
										<li className={styles.listItem} />
									</ul>
								</Trans>
							</div>
							<div className={styles.stepContent}>
								<OnboardingPayment
									childId={childId}
									portfolioId={process?.portfolioId ?? null}
									setCheckOnboarding={setCheckOnboarding}
								/>
							</div>
						</>
					)}
				</li>
			))}
		</ul>
	);
}

OnboardingSteps.propTypes = {
	blogPostEsgRegulationUrl: PropTypes.string,
	childId: PropTypes.number,
	conditionsUrl: PropTypes.string.isRequired,
	dashboardUrl: PropTypes.string.isRequired,
	faqUrl: PropTypes.string.isRequired,
	personalInfoAgreementUrl: PropTypes.string.isRequired,
	process: PropTypes.shape({
		category: PropTypes.string,
		emailPlanned: PropTypes.string,
		investorProfileId: PropTypes.number,
		portfolioId: PropTypes.number,
		product: PropTypes.string,
		questionnaire: PropTypes.string,
		questionnaires: PropTypes.arrayOf(PropTypes.shape({
			questionnaire: PropTypes.string.isRequired,
			finished: PropTypes.bool.isRequired,
		}).isRequired),
		step: PropTypes.number,
	}),
	setCheckOnboarding: PropTypes.func.isRequired,
};

OnboardingSteps.defaultProps = {
	blogPostEsgRegulationUrl: null,
	childId: null,
	process: null,
};
