import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ButtonLink } from '../../../components/ui';

import styles from '../Products.module.scss';

export default function ClassicCard({
	classicProductUrl,
}) {
	const [t] = useTranslation();

	return (
		<div className={styles.content}>
			<div className={styles.icon}>
				<svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} viewBox="0 0 32 32" fill="none">
					<g clipPath="url(#classic-other-product-a)">
						<path fill="#F3920B" fillRule="evenodd" d="M15.838.738C7.418.738.568 7.588.568 16.006c0 8.418 6.85 15.27 15.27 15.27s15.27-6.85 15.27-15.27S24.258.738 15.838.738Zm0 27.239c-6.6 0-11.97-5.369-11.97-11.97 0-6.603 5.37-11.969 11.97-11.969 6.6 0 11.97 5.369 11.97 11.968 0 6.6-5.368 11.97-11.97 11.97Z" clipRule="evenodd" />
						<mask id="classic-other-product-b" width="17" height="5" x="7" y="19" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
							<path fill="#F3920B" d="M23.687 19.551H7.19v2.113c0 .023.003.047.008.07.152.973 3.495 2.029 8.24 2.029s8.087-1.056 8.24-2.029a.307.307 0 0 0 .008-.07V19.55Z" />
						</mask>
						<g mask="url(#classic-other-product-b)">
							<path fill="#F3920B" d="M23.687 19.551H7.19v2.113c0 .023.003.047.008.07.152.973 3.495 2.029 8.24 2.029s8.087-1.056 8.24-2.029a.307.307 0 0 0 .008-.07V19.55Z" />
							<path fill="#9C5B00" d="M7.086 19.601h2.905v4.226H7.086z" opacity=".9" />
							<path fill="#9C5B00" d="M9.991 19.601h2.641v4.226H9.991z" opacity=".7" />
							<path fill="#9C5B00" d="M12.632 19.601h2.641v4.226h-2.641z" opacity=".6" />
							<path fill="#9C5B00" d="M15.273 19.601h2.641v4.226h-2.641z" opacity=".4" />
							<path fill="#9C5B00" d="M17.913 19.601h2.905v4.226h-2.905z" opacity=".2" />
						</g>
						<path fill="#FCC10A" d="M23.687 19.551a.305.305 0 0 1-.009.07c-.152.973-3.494 2.03-8.24 2.03-4.744 0-8.087-1.057-8.239-2.03a.303.303 0 0 1-.008-.07c0-.054.011-.108.033-.162a.678.678 0 0 1 .144-.236c.75-.868 3.856-1.7 8.07-1.7 4.216 0 7.321.832 8.072 1.7a.68.68 0 0 1 .143.236.43.43 0 0 1 .034.162Z" />
						<mask id="classic-other-product-c" width="18" height="5" x="8" y="15" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
							<path fill="#F3920B" d="M25.15 15.318H8.655v2.113c0 .024.002.047.008.07.152.973 3.495 2.03 8.24 2.03 4.744 0 8.087-1.057 8.239-2.03a.305.305 0 0 0 .008-.07v-2.113Z" />
						</mask>
						<g mask="url(#classic-other-product-c)">
							<path fill="#F3920B" d="M25.15 15.318H8.655v2.113c0 .024.002.047.008.07.152.973 3.495 2.03 8.24 2.03 4.744 0 8.087-1.057 8.239-2.03a.305.305 0 0 0 .008-.07v-2.113Z" />
							<path fill="#9C5B00" d="M8.549 15.368h2.905v4.226H8.549z" opacity=".9" />
							<path fill="#9C5B00" d="M11.454 15.368h2.641v4.226h-2.641z" opacity=".7" />
							<path fill="#9C5B00" d="M14.095 15.368h2.641v4.226h-2.641z" opacity=".6" />
							<path fill="#9C5B00" d="M16.736 15.368h2.641v4.226h-2.641z" opacity=".4" />
							<path fill="#9C5B00" d="M19.377 15.368h2.905v4.226h-2.905z" opacity=".2" />
						</g>
						<path fill="#FCC10A" d="M25.15 15.319a.307.307 0 0 1-.008.07c-.152.973-3.495 2.029-8.24 2.029s-8.087-1.056-8.24-2.03a.305.305 0 0 1-.007-.07c0-.054.01-.108.033-.162a.679.679 0 0 1 .143-.236c.751-.868 3.857-1.7 8.072-1.7 4.215 0 7.32.832 8.07 1.7.07.08.12.156.144.236a.428.428 0 0 1 .033.163Z" />
						<mask id="classic-other-product-d" width="17" height="5" x="7" y="11" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
							<path fill="#F3920B" d="M23.964 11.046H7.468v2.113c0 .024.003.047.008.07.152.973 3.495 2.029 8.24 2.029s8.088-1.056 8.24-2.029a.306.306 0 0 0 .008-.07v-2.113Z" />
						</mask>
						<g mask="url(#classic-other-product-d)">
							<path fill="#F3920B" d="M23.964 11.046H7.468v2.113c0 .024.003.047.008.07.152.973 3.495 2.029 8.24 2.029s8.088-1.056 8.24-2.029a.306.306 0 0 0 .008-.07v-2.113Z" />
							<path fill="#9C5B00" d="M7.362 11.096h2.905v4.226H7.362z" opacity=".9" />
							<path fill="#9C5B00" d="M10.268 11.096h2.641v4.226h-2.641z" opacity=".7" />
							<path fill="#9C5B00" d="M12.909 11.096h2.641v4.226h-2.641z" opacity=".6" />
							<path fill="#9C5B00" d="M15.55 11.096h2.641v4.226H15.55z" opacity=".4" />
							<path fill="#9C5B00" d="M18.19 11.096h2.905v4.226H18.19z" opacity=".2" />
						</g>
						<path fill="#FCC10A" d="M23.964 11.046a.307.307 0 0 1-.009.07c-.151.974-3.494 2.03-8.24 2.03-4.744 0-8.087-1.056-8.239-2.03a.304.304 0 0 1-.008-.07c0-.054.011-.108.033-.162a.679.679 0 0 1 .144-.236c.75-.868 3.856-1.7 8.07-1.7 4.216 0 7.321.832 8.072 1.7.069.08.119.156.143.236a.428.428 0 0 1 .034.162Z" />
					</g>
					<defs>
						<clipPath id="classic-other-product-a">
							<path fill="#fff" d="M0 0h32v32H0z" />
						</clipPath>
					</defs>
				</svg>
			</div>
			<p className={styles.subTitle}>
				{t('products.list.classic.title')}
			</p>
			<p className={styles.text}>
				{t('products.list.classic.text')}
			</p>
			<ButtonLink
				label={t('products.list.classic.button')}
				to={classicProductUrl}
				outline
			/>
		</div>
	);
}

ClassicCard.propTypes = {
	classicProductUrl: PropTypes.string.isRequired,
};
