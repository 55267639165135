import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
	Col,
	Container,
	Row,
	Section,
} from '../../layout';
import { SectionAnchor } from '../../ui';

import styles from './OurApproachHero.module.scss';

const icons = [
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={78} height={55} viewBox="0 0 78 55">
		<circle cx="9" cy="20" r="5" stroke="#E94549" strokeWidth="2" />
		<path stroke="#E94549" strokeWidth="2" d="M1 41v-9a3 3 0 1 1 6 0v23m10-14v-9a3 3 0 1 0-6 0v23" />
		<circle cx="29" cy="20" r="5" stroke="#E94549" strokeWidth="2" />
		<path stroke="#E94549" strokeWidth="2" d="M21 41v-9a3 3 0 1 1 6 0v23m10-14v-9a3 3 0 1 0-6 0v23" />
		<circle cx="49" cy="20" r="5" stroke="#E94549" strokeWidth="2" />
		<path stroke="#E94549" strokeWidth="2" d="M41 41v-9a3 3 0 1 1 6 0v23m10-14v-9a3 3 0 1 0-6 0v23" />
		<circle cx="69" cy="20" r="5" stroke="#E94549" strokeWidth="2" />
		<path stroke="#E94549" strokeWidth="2" d="M61 41v-9a3 3 0 1 1 6 0v23m10-14v-9a3 3 0 1 0-6 0v23" />
		<circle cx="59" cy="6" r="5" stroke="#E94549" strokeWidth="2" />
		<circle cx="39" cy="6" r="5" stroke="#E94549" strokeWidth="2" />
		<circle cx="19" cy="6" r="5" stroke="#E94549" strokeWidth="2" />
	</svg>,
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={70} height={60} viewBox="0 0 70 60">
		<g clipPath="url(#regulations-icon)">
			<path fill="#E94549" d="M15.19 60A15.28 15.28 0 0 1 0 44.67c0-2.15.44-4.2 1.24-6.05l9.23-24.54.08-.17.06-.27c1-2.8 3.15-4.97 5.78-6.05l.04-.11 1.42-3.79.03-.06c0-.04.02-.08.04-.13A5.27 5.27 0 0 1 22.86 0c2.9 0 5.25 2.38 5.25 5.3v4.6c0 .2-.06.4-.16.57a10.29 10.29 0 0 1 2.43 6.66v2.95h9.24v-2.95c0-2.54.92-4.87 2.43-6.66-.1-.17-.16-.38-.16-.58V5.3c0-2.92 2.35-5.3 5.25-5.3a5.27 5.27 0 0 1 4.94 3.5l.04.14.03.06 1.42 3.78.04.12a10.25 10.25 0 0 1 5.78 6.05c.03.08.05.17.05.26.04.05.06.1.09.17l9.23 24.52A15.37 15.37 0 0 1 70 44.67C70 53.13 63.19 60 54.81 60a15.28 15.28 0 0 1-15.19-15.33V33.3h-9.24v11.38c0 8.46-6.82 15.33-15.2 15.33l.01-.01Zm5-53.15c2.16 0 4.16.68 5.8 1.83V5.3c0-1.74-1.4-3.16-3.13-3.16a3.14 3.14 0 0 0-2.95 2.09l-.03.08a.94.94 0 0 1-.04.14l-.93 2.48c.42-.05.85-.08 1.28-.08Zm-5 22.5c5.55 0 10.42 3.01 13.07 7.52V17.13a8.1 8.1 0 0 0-8.06-8.14 8.09 8.09 0 0 0-7.74 5.85L5.74 32.67a15.03 15.03 0 0 1 9.45-3.33v.01Zm0 2.13a13.1 13.1 0 0 0-11.97 7.9l-.43 1.15-.03.06a13.15 13.15 0 0 0 12.43 17.28c7.2 0 13.07-5.93 13.07-13.2s-5.87-13.19-13.07-13.19Zm34.9-27.25a3.14 3.14 0 0 0-2.95-2.1c-1.71 0-3.1 1.4-3.14 3.11v3.44a10.06 10.06 0 0 1 7.1-1.75l-.94-2.47-.04-.16a1.11 1.11 0 0 1-.03-.07Zm7.3 10.14A8.1 8.1 0 0 0 49.8 9a8.1 8.1 0 0 0-8.06 8.14v19.74a15.17 15.17 0 0 1 13.07-7.53c3.57 0 6.85 1.25 9.45 3.34l-6.72-17.84a1.08 1.08 0 0 1-.06-.28 1.08 1.08 0 0 1-.09-.19v-.01ZM54.8 31.48c-7.2 0-13.07 5.92-13.07 13.2 0 7.26 5.87 13.18 13.07 13.18a13.14 13.14 0 0 0 12.42-17.31l-.43-1.13a13.09 13.09 0 0 0-11.98-7.93l-.01-.01Zm-24.43-9.25v8.92h9.24v-8.92h-9.24Z" />
		</g>
		<defs>
			<clipPath id="regulations-icon">
				<path fill="#fff" d="M0 0h70v60H0z" />
			</clipPath>
		</defs>
	</svg>,
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={210} height={49} viewBox="0 0 210 49">
		<g fill="#A8A8A8" clipPath="url(#j&t-bank-logo)">
			<path d="M6.99 9.65V30.2H0v8.77h10.03c.84 0 1.36-.07 1.36-.07a6.44 6.44 0 0 0 5.52-6.22V9.65H7Zm24.28 26.66c-6.92 0-7.82-.42-7.82-6.15 0-4.65.42-5.24 4.71-6.22l11.46 9.4c-.66 3.25-5.17 2.97-8.35 2.97Zm11.78-3.84a20 20 0 0 0 .35-4.1c0-.76-.04-1.53-.07-2.3h-3.04c0 .67.14 1.37.07 2.03l-.1 1.96-11.4-9.58c-1.54-1.3-2.02-2.02-2.02-4.09 0-3.88 2.48-3.98 5.66-3.98 4.36 0 4.92.73 4.96 4.44v1.6h3.32v-1.6c0-6.19-2.97-7.37-8.28-7.37-5.73 0-8.98 1.15-8.98 6.91 0 3.29.73 4.2 2.65 5.8-3.74 1.05-5.97.88-5.97 7.97 0 9.16 4.64 9.12 11.14 9.12 4.68 0 9.26 0 10.94-3.84l4.33 3.6 1.78-2.31-5.34-4.26Zm1.54-22.82v8.8h5.62v20.52h9.96V18.46h5.63V9.65H44.59Zm33.57 15.86h10.87c4.02.04 4.47 1.79 4.5 5.52-.03 4.4-1.56 4.69-5.13 4.72H78.16V25.51Zm9.4-3.21h-9.4v-9.44h9.75c3.46.04 4.76.32 4.8 4.16-.04 4.51-1.2 5.24-5.15 5.28Zm5.42 1.3c2.76-1.1 3.35-3.19 3.35-6.12 0-2.55-.31-4.48-1.36-5.84-1.05-1.33-2.8-1.99-5.52-1.99H74.57v29.32h15.58c2.48 0 4.23-.74 5.38-2.14 1.12-1.4 1.6-3.45 1.6-6.11.04-3.46-.55-6.29-4.15-7.13Zm24.88 6.21h-12.4l6.14-16.84 6.26 16.84Zm-8.74-20.16L98.47 38.97h3.77l2.24-6.15h14.43l2.27 6.15h3.74L113.98 9.65h-4.86Zm93.47 20.16h-12.4l6.15-16.84 6.25 16.84Zm-8.73-20.16L183.2 38.97h3.77l2.24-6.15h14.43l2.27 6.15h3.78L198.75 9.65h-4.9Zm-44.7 26.24-16.1-26.24h-5.63v29.32H131V12.93l16.14 26.04h5.63V9.65h-3.6v26.24Z" />
			<path d="m168.42 23.84 13.42-14.19h-4.72l-11.85 12.68h-2.62V9.65h-3.6v29.32h3.6V25.55h2.62l13.28 13.42h4.9l-15.03-15.13Z" />
		</g>
		<defs>
			<clipPath id="j&t-bank-logo">
				<path fill="#fff" d="M0 0h210v49H0z" />
			</clipPath>
		</defs>
	</svg>,
];

export default function OurApproachHero({
	teamSectionAnchor,
}) {
	const [t] = useTranslation();

	return (
		<Section bordered>
			<Container center>
				<div>
					<h1 className={styles.title}>{t('ourApproachHero.title')}</h1>
					<p className={styles.subtitle}>{t('ourApproachHero.text')}</p>
					<Row center>
						{Object.keys(t('ourApproachHero.list', { returnObjects: true })).map((item) => (
							<Col key={item} md={4}>
								<div className={styles.icon}>
									{icons[+item] ?? null}
								</div>
								<h3 className={styles.itemTitle}>
									{t(`ourApproachHero.list.${item}.title`)}
								</h3>
								<p className={styles.itemText}>
									{t(`ourApproachHero.list.${item}.text`)}
								</p>
							</Col>
						))}
					</Row>
				</div>
			</Container>
			<SectionAnchor title={t('ourApproachHero.anchor')} target={teamSectionAnchor} />
		</Section>
	);
}

OurApproachHero.propTypes = {
	teamSectionAnchor: PropTypes.string.isRequired,
};
