export function getToday(daysAgo = 0) {
	const date = new Date();
	date.setDate(date.getDate() - daysAgo);
	date.setHours(0, 0, 0, 0);
	return date;
}

export function fromISODate(dateString) {
	if (typeof dateString !== 'string') {
		return null;
	}

	const match = dateString.match(/^(\d{4,})-(\d{2})-(\d{2})$/);
	if (match === null) {
		return null;
	}

	return new Date(+match[1], +match[2] - 1, +match[3], 0, 0, 0, 0);
}

const pad = (value, length) => `${'0'.repeat(Math.max(length - String(value).length, 0))}${value}`;

export function toISODate(date) {
	if (!(date instanceof Date)) {
		return null;
	}

	return `${pad(date.getFullYear(), 4)}-${pad(date.getMonth() + 1, 2)}-${pad(date.getDate(), 2)}`;
}
