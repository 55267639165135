import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { TextField } from '../../components/forms';
import { FormLayout } from '../../components/layout';
import {
	Alert,
	Button,
} from '../../components/ui';
import { forgotPassword } from '../../utils/api';
import { validateForgotPassword } from '../../utils/validators';

import styles from './ForgotPassword.module.scss';

const initialValues = {
	email: '',
};

export default function ForgotPassword() {
	const [done, setDone] = useState(false);
	const [t] = useTranslation();

	return (
		<FormLayout title={t('forgotPassword.title')}>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validate={validateForgotPassword}
				onSubmit={async ({ email }) => {
					setDone(false);

					try {
						await forgotPassword(email);
					} finally {
						setDone(true);
					}
				}}
			>
				{({
					errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values,
				}) => (
					<form onSubmit={handleSubmit}>
						{done && (
							<Alert type="success">
								{t('forgotPassword.form.success')}
							</Alert>
						)}
						<p className={styles.text}>
							{t('forgotPassword.form.text')}
						</p>
						<TextField
							onBlur={handleBlur}
							onChange={handleChange}
							error={
								errors.email
								&& touched.email
								&& t(errors.email)
							}
							id="email"
							name="email"
							label={t('forms.fields.email.label')}
							required
							type="email"
							value={values.email}
						/>
						<Button
							label={t('forgotPassword.form.button')}
							disabled={isSubmitting}
							isSubmit
							icon={(
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 512 512"
								>
									<path
										fill="currentColor"
										d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
									/>
								</svg>
							)}
						/>
					</form>
				)}
			</Formik>
		</FormLayout>
	);
}
