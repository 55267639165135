/* global window */
import { useState, useEffect } from 'react';

export default function useWindowSize() {
	const hasWindow = typeof window === 'object';
	const [windowSize, setWindowSize] = useState({
		width: (hasWindow && window.innerWidth) || null,
		height: (hasWindow && window.innerHeight) || null,
	});

	useEffect(() => {
		if (!hasWindow) {
			return undefined;
		}

		function handleResize() {
			setWindowSize({
				height: window.innerHeight || null,
				width: window.innerWidth || null,
			});
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [hasWindow]);

	return windowSize;
}
