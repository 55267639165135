import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';

import { useLanguage } from '../../../contexts/LocaleContext';
import { fromISODate } from '../../../utils/date';
import isScreenWidthUp from '../../../utils/isScreenWidthUp';
import { toInt } from '../../../utils/number';
import useFormatNumber from '../../../utils/useFormatNumber';
import useWindowSize from '../../../utils/useWindowSize';
import { Container, Section } from '../../layout';
import { Loader } from '../../ui';

import styles from './EvaluationChart.module.scss';

function roundPercent(value) {
	return value / 100;
}

const chartOptions = (formatPercent, formatDate, min, max, tooltipValue, annotations) => ({
	chart: {
		animations: {
			enabled: false,
		},
		toolbar: {
			show: false,
		},
		zoom: {
			enabled: false,
		},
	},
	colors: ['#333B45'],
	dataLabels: {
		enabled: false,
	},
	fontFamily: 'Rajdhani, sans-serif',
	legend: {
		show: false,
	},
	stroke: {
		width: 3,
	},
	markers: {
		size: 0,
		colors: 'transparent',
		strokeColor: 'transparent',
	},
	tooltip: {
		custom: ({ series, dataPointIndex, w: { config } }) => {
			const value = series[0][dataPointIndex];
			const dataPoint = config.series[0].data[dataPointIndex];

			return (
				`<div>
						<div class="apexcharts-tooltip-title">
							${formatDate(dataPoint.x)} ${formatDate(dataPoint.toDate) !== formatDate(dataPoint.x) ? `- ${formatDate(dataPoint.toDate)}` : ''}
						</div>
						<div class="apexcharts-tooltip-series-group ${styles.tooltipGroup}">
							<div class="apexcharts-tooltip-text">
								${tooltipValue}:&nbsp;<strong>${formatPercent(roundPercent(value))}</strong>
							</div>
						</div>
					</div>`
			);
		},
		marker: {
			show: false,
		},
	},
	xaxis: {
		type: 'datetime',
		labels: {
			rotateAlways: true,
			minHeight: 100,
			offsetX: 5,
			style: {
				colors: '#999',
				fontSize: '13px',
			},
			formatter(value, timestamp) {
				return formatDate(new Date(timestamp));
			},
		},
		axisTicks: {
			show: false,
		},
		tooltip: {
			enabled: false,
		},
	},
	yaxis: {
		tickAmount: Math.min(5, max - min),
		min,
		max,
		forceNiceScale: true,
		labels: {
			style: {
				color: '#999',
				fontSize: '15px',
			},
			formatter(val) {
				return val !== null ? formatPercent(roundPercent(val)) : null;
			},
		},
	},
	annotations: {
		position: 'front',
		points: annotations,
	},
});

function EvaluationChart({
	data,
}) {
	const [t] = useTranslation();
	const language = useLanguage();
	const formatDate = (date) => date.toLocaleDateString(language);
	const windowSize = useWindowSize();
	const periodIncrement = isScreenWidthUp(windowSize.width, 'md') ? 20 : 15;
	const [period, setPeriod] = useState(periodIncrement);
	const periodData = period < data.length
		? data.slice(data.length - period)
		: data;
	const formatPercent = useFormatNumber({ style: 'percent', fractionDigits: 1 });
	let min = null;
	let max = 0;

	const handlePeriod = (loadAll = false) => {
		setPeriod(loadAll ? data.length : (oldPeriod) => (
			Math.min(oldPeriod + periodIncrement, data.length)
		));
	};

	const lineChartData = periodData ? periodData.map((day) => {
		const value = day.netGrowthRatio !== null ? toInt(day.netGrowthRatio) : null;

		if (value !== null) {
			if (min === null || value < min) {
				min = value;
			}

			if (value > max) {
				max = value;
			}
		}
		return {
			x: fromISODate(day.toDate),
			y: value,
			toDate: fromISODate(day.toDate),
		};
	}) : [];

	const valueLabel = periodData ? periodData.map((day) => {
		const value = day.netGrowthRatio !== null ? toInt(day.netGrowthRatio) : null;
		const ratio = day.yearlyNetGrowthRatio !== null ? day.yearlyNetGrowthRatio : null;
		let offsetX = 0;
		let paddingLeft = 0;
		let paddingRight = 0;

		if (ratio >= 0 && ratio <= 9.9) {
			offsetX = -5.5;
			paddingLeft = 15.9;
			paddingRight = 15.9;
		} else if (ratio <= -0.1 && ratio >= -9.9) {
			offsetX = -3.2;
			paddingLeft = 13.4;
			paddingRight = 13.4;
		} else if (ratio >= 10) {
			offsetX = -2;
			paddingLeft = 13;
			paddingRight = 13;
		} else if (ratio <= -10) {
			offsetX = 0;
			paddingLeft = 10;
			paddingRight = 10;
		}

		return {
			x: fromISODate(day.toDate).getTime(),
			y: value,
			marker: {
				size: 5,
				fillColor: value >= 0 ? '#008000' : '#99556a',
				strokeColor: '#FFF',
				radius: 2,
			},
			label: {
				borderColor: 'transparent',
				offsetY: -4,
				offsetX,
				textAnchor: 'end',
				style: {
					background: ratio >= 0 ? '#48b958' : '#99556a',
					color: '#fff',
					fontSize: isScreenWidthUp(windowSize.width, 'md') ? '12px' : '10px',
					cssClass: 'point',
					padding: {
						left: paddingLeft,
						right: paddingRight,
						top: 2,
						bottom: 5,
					},
				},
				text: ratio ? `${fromISODate(day.toDate).getFullYear()}: ${formatPercent(roundPercent(ratio))}` : '',
			},
		};
	}) : [];

	const annotations = [...valueLabel];

	min = min ?? 0;
	const chartMin = Math.round(min - Math.max(Math.abs(min / 20), 1));
	const chartMax = Math.round(max + Math.max(Math.abs(max / 20), 1));

	const tooltipValue = t('dashboard.chart.toggle.evaluation');

	const chart = (
		<div className={styles.root}>
			{data ? (
				<>
					<div className={styles.chartWrap}>
						<div className={styles.chart}>
							<button
								className={styles.loadButton}
								type="button"
								onClick={() => handlePeriod()}
								disabled={period >= data.length}
							>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" width="10" height="12" viewBox="0 0 10 12">
									<path fill="currentColor" d="m5.7 10.4-.4.6c-.3.2-.6.2-.8 0L.2 6.4a.6.6 0 0 1 0-.8L4.5 1c.2-.2.5-.2.8 0l.4.6c.3.2.3.6 0 .8L3 5h6.5c.3 0 .5.2.5.5v.8c0 .3-.2.5-.5.5H3l2.7 2.7c.3.2.3.6 0 .8Z" />
								</svg>
							</button>
							<Chart
								height={300}
								options={chartOptions(
									formatPercent,
									formatDate,
									chartMin,
									chartMax,
									tooltipValue,
									annotations,
								)}
								series={[
									{
										name: 'line',
										type: 'line',
										data: lineChartData,
									},
								]}
								type="line"
							/>
						</div>
					</div>
				</>
			) : (
				<div className={styles.loading}>
					<p>
						{t('historyChart.loading')}
					</p>
					<Loader />
				</div>
			)}
		</div>
	);

	return (
		<Section
			bordered
			condensed
		>
			<Container>
				{chart}
			</Container>
		</Section>
	);
}

EvaluationChart.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			toDate: PropTypes.string,
			netGrowth: PropTypes.number,
			netGrowthRatio: PropTypes.number,
			yearlyNetGrowthRatio: PropTypes.number,
			totalValue: PropTypes.number,
		}).isRequired,
	).isRequired,
};

export default memo(EvaluationChart);
