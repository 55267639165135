import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import { toNumber } from '../../../utils/number';
import useFormatNumber from '../../../utils/useFormatNumber';

import styles from './InvestorProfileChart.module.scss';

const chartOptions = (colors, labels, formatLabel, formatTooltip) => ({
	colors,
	dataLabels: {
		formatter: formatLabel,
	},
	labels,
	legend: {
		show: false,
	},
	plotOptions: {
		pie: {
			expandOnClick: false,
		},
	},
	responsive: [
		{
			breakpoint: 4000,
			options: {
				chart: {
					height: 370,
					width: 350,
				},
			},
		},
		{
			breakpoint: 992,
			options: {
				chart: {
					height: 330,
					width: 300,
				},
			},
		},
		{
			breakpoint: 576,
			options: {
				chart: {
					height: 290,
					width: 260,
				},
				tooltip: {
					enabled: false,
				},
			},
		},
	],
	tooltip: {
		enabledOnSeries: [0],
		y: {
			formatter: formatTooltip,
		},
	},
});

export default function InvestorProfileChart({
	categories,
	onlyChart,
	profile,
}) {
	const [t] = useTranslation();
	const formatPercent = useFormatNumber({ style: 'percent' });
	const formatLabel = (value) => (value >= 3.5 ? formatPercent(value / 100, { fractionDigits: 1 }) : '');
	const formatTooltip = (value) => (value !== undefined ? formatPercent(value, { fractionDigits: 2 }) : null);

	const series = profile.chart.map(({ ratio }) => toNumber(ratio));
	const colors = profile.chart.map(({ colorHex }) => `#${colorHex}`);
	const labels = profile.chart.map(({ productCategoryId }) => (
		categories.filter(({ id }) => id === productCategoryId).map(({ name }) => name)[0]
	));

	return (
		<div className={styles.root}>
			<div className={styles.chartWrap}>
				<Chart
					className={styles.chart}
					height={350}
					options={chartOptions(colors, labels, formatLabel, formatTooltip)}
					series={series}
					type="donut"
					width={350}
				/>
				{!onlyChart && (
					<div className={styles.chartOverlay}>
						<div>{t('investorProfilePreview.chartOverlayTitle')}</div>
						<div className={styles.chartOverlayNumber}>
							<span>{formatPercent(profile.interestLastTenYears, { fractionDigits: 2 })}</span>
						</div>
						<div className={styles.chartOverlayPeriod}>{t('investorProfilePreview.chartOverlayPeriod')}</div>
					</div>
				)}
				{!onlyChart && (
					<div className={styles.tooltip}>
						{t('investorProfilePreview.chartTooltip')}
					</div>
				)}
			</div>
		</div>
	);
}

InvestorProfileChart.propTypes = {
	categories: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
		}),
	).isRequired,
	onlyChart: PropTypes.bool,
	profile: PropTypes.shape({
		interestLastTenYears: PropTypes.string.isRequired,
		chart: PropTypes.arrayOf(
			PropTypes.shape({
				colorHex: PropTypes.string.isRequired,
				productCategoryId: PropTypes.number.isRequired,
				ratio: PropTypes.string.isRequired,
			}),
		).isRequired,
	}).isRequired,
};

InvestorProfileChart.defaultProps = {
	onlyChart: false,
};
