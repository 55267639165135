import React from 'react';
import PropTypes from 'prop-types';

import styles from './Section.module.scss';

export default function Section({
	bordered,
	children,
	condensed,
	id,
	variant,
}) {
	let variantClass = '';

	if (variant === 'wine-dark') {
		variantClass = styles.isRootWineDark;
	} else if (variant === 'orange-lighter') {
		variantClass = styles.isRootOrangeLighter;
	} else if (variant === 'blue-dark') {
		variantClass = styles.isRootBlueDark;
	} else if (variant === 'gray-extra-light') {
		variantClass = styles.isRootGrayExtraLight;
	}

	return (
		<section
			className={
				`${styles.root} ${bordered ? styles.isRootBordered : ''} ${condensed ? styles.isRootCondensed : ''} ${variantClass}`.trim()
			}
			id={id}
		>
			{children}
		</section>
	);
}

Section.propTypes = {
	bordered: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	condensed: PropTypes.bool,
	id: PropTypes.string,
	variant: PropTypes.oneOf(['blue-dark', 'gray-extra-light', 'orange-lighter', 'wine-dark']),
};

Section.defaultProps = {
	bordered: false,
	children: null,
	condensed: false,
	id: null,
	variant: null,
};
