import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useUserLogin } from '../../../contexts/UserContext';
import { validateLogin } from '../../../utils/validators';
import { emailAlias, login } from '../../../utils/api';
import { TextField } from '../../forms';
import { Col, FormLayoutFull, Row } from '../../layout';
import {
	Alert, Button, EmailLink, Modal,
} from '../../ui';
import SocialLogin from '../SocialLogin';

import styles from './LoginForm.module.scss';

const initialValues = {
	email: '',
	password: '',
	twoFactorCode: '',
};

export default function LoginForm({
	dashboardUrl,
	emailAliasSocial,
	forgotPasswordUrl,
}) {
	const [error, setError] = useState(null);
	const [has2fa, setHas2fa] = useState(false);
	const [t] = useTranslation();
	const handleLogin = useUserLogin();
	return (
		<FormLayoutFull
			title={t(emailAliasSocial ? 'register.emailAlias.title' : 'login.title')}
			text={t('login.text')}
		>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validate={validateLogin}
				onSubmit={async ({ email, password, twoFactorCode }, { setErrors, setFieldValue }) => {
					setError(null);
					try {
						await login(
							email,
							password,
							has2fa ? twoFactorCode : null,
						);
					} catch (e) {
						const fieldErrors = {};
						let show2fa = false;
						const errorMessage = e.responseJson?.message;
						if (typeof errorMessage === 'string' && errorMessage.indexOf('Bad credentials') !== -1) {
							setFieldValue('password', '', false);
							fieldErrors.password = 'forms.fields.password.incorrect';
						}

						if (typeof errorMessage === 'string' && errorMessage.indexOf('Two factor authentication code needed') !== -1) {
							setHas2fa(true);
							show2fa = true;
						}

						if (typeof errorMessage === 'string' && errorMessage.indexOf('Bad two factor authentication code') !== -1) {
							fieldErrors.twoFactorCode = 'forms.twoFactorAuthentication.error';
						}

						const hasFieldErrors = Object.keys(fieldErrors).length > 0;
						if (hasFieldErrors) {
							setErrors(fieldErrors);
						} else if (!show2fa) {
							const formError = e.response?.status === 404
								? 'forms.fields.email.closed'
								: 'forms.error';
							setError(formError);
						}
						return;
					}

					if (emailAliasSocial) {
						try {
							await emailAlias(emailAliasSocial);
						} catch {
							setError('forms.error');
							return;
						}
					}

					handleLogin(dashboardUrl || null);
				}}
			>
				{({
					errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values,
				}) => (
					<form onSubmit={handleSubmit}>
						<Row center>
							<Col lg={6} xl={4}>
								{error !== null && (
									<Alert type="danger">
										<Trans i18nKey={error}>
											<EmailLink />
										</Trans>
									</Alert>
								)}
								<TextField
									onBlur={handleBlur}
									onChange={handleChange}
									error={
										errors.email
										&& touched.email
										&& t(errors.email)
									}
									id="email"
									name="email"
									label={t('forms.fields.email.label')}
									required
									type="email"
									value={values.email}
								/>
								<TextField
									onBlur={handleBlur}
									onChange={handleChange}
									error={
										errors.password
										&& touched.password
										&& t(errors.password)
									}
									id="password"
									name="password"
									label={t('forms.fields.password.label')}
									required
									type="password"
									value={values.password}
								/>
								<Modal
									isVisible={has2fa}
									onClose={() => setHas2fa(false)}
									title={t('forms.twoFactorAuthentication.title')}
								>
									<div className={styles.modal}>
										<p className={styles.text}>
											{t('forms.twoFactorAuthentication.text')}
										</p>
										<TextField
											autocomplete="one-time-code"
											autoFocus={has2fa}
											onBlur={handleBlur}
											onChange={handleChange}
											error={
												errors.twoFactorCode
												&& touched.twoFactorCode
												&& t(errors.twoFactorCode)
											}
											id="twoFactorCode"
											name="twoFactorCode"
											label={t('forms.twoFactorAuthentication.text')}
											isLabelHidden
											required={has2fa}
											type="text"
											inputmode="numeric"
											pattern="[0-9]*"
											value={values.twoFactorCode}
										/>
										<Button
											label={t('forms.twoFactorAuthentication.button')}
											disabled={isSubmitting}
											isSubmit
										/>
									</div>
								</Modal>
								<Button
									label={t(t(emailAliasSocial ? 'register.emailAlias.button' : 'login.button'))}
									disabled={isSubmitting}
									isSubmit
								/>
								{!emailAliasSocial && forgotPasswordUrl && (
									<p>
										<Link
											className={styles.link}
											to={forgotPasswordUrl}
										>
											{t('login.forgotPassword')}
										</Link>
									</p>
								)}
							</Col>
						</Row>
						{!emailAliasSocial && (
							<Row center>
								<Col lg={6} xl={4}>
									<SocialLogin />
								</Col>
							</Row>
						)}
					</form>
				)}
			</Formik>
		</FormLayoutFull>
	);
}

LoginForm.propTypes = {
	dashboardUrl: PropTypes.string.isRequired,
	emailAliasSocial: PropTypes.string,
	forgotPasswordUrl: PropTypes.string,
};

LoginForm.defaultProps = {
	emailAliasSocial: '',
	forgotPasswordUrl: null,
};
