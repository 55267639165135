import React from 'react';
import PropTypes from 'prop-types';

import {
	Col,
	Container,
	Row,
	Section,
} from '../../layout';
import PortfolioFeesEtfs from './PortfolioFeesEtfs';
import PortfolioFeesOverview from './PortfolioFeesOverview';

export default function PortfolioFees({ feesEtfs, feesOverview, isProxy }) {
	return (
		<Section bordered>
			<Container>
				<Row>
					<Col lg={6}>
						{feesOverview !== null && (
							<PortfolioFeesOverview feesOverview={feesOverview} />
						)}
					</Col>
					<Col lg={6}>
						{feesEtfs !== null && (
							<PortfolioFeesEtfs feesEtfs={feesEtfs} isProxy={isProxy} />
						)}
					</Col>
				</Row>
			</Container>
		</Section>
	);
}

PortfolioFees.propTypes = {
	feesEtfs: PropTypes.shape({
		feeTer: PropTypes.string,
		products: PropTypes.arrayOf(PropTypes.shape({
			etfName: PropTypes.string,
			etfTer: PropTypes.string,
		}).isRequired).isRequired,
	}),
	feesOverview: PropTypes.shape({
		currency: PropTypes.shape({
			code: PropTypes.string,
			codeShort: PropTypes.string,
			codeShortBefore: PropTypes.bool,
		}).isRequired,
		totalValue: PropTypes.number,
		cash: PropTypes.number,
		netGrowth: PropTypes.number,
		netGrowthRatio: PropTypes.string,
		feeRatio: PropTypes.string,
		feeThisMonth: PropTypes.number,
		feeTer: PropTypes.string,
		feeTerThisMonth: PropTypes.number,
		cashBonusThisMonth: PropTypes.number,
		bonusToDate: PropTypes.string,
	}),
	isProxy: PropTypes.bool,
};

PortfolioFees.defaultProps = {
	feesEtfs: null,
	feesOverview: null,
	isProxy: null,
};
