import Hotjar from '@hotjar/browser';

const scripts = [
	{
		category: 'analytics',
		script: () => {
			const hotjarId = process.env.REACT_APP_HOTJAR_ID ?? null;
			const hotjarVersion = 6;

			if (hotjarId !== null && hotjarId !== '') {
				Hotjar.init(hotjarId, hotjarVersion);
			}
		},
	},
];

export default function runCookieConsentScripts(categories) {
	scripts.filter(({ category, executed = false }) => !executed && categories.indexOf(category) !== -1)
		.forEach((script) => {
			const currentScript = script;
			currentScript.executed = true;
			(currentScript.script)();
		});
}
