import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useLanguage } from '../../../../contexts/LocaleContext';
import { toNumber } from '../../../../utils/number';
import useFormatNumber from '../../../../utils/useFormatNumber';

import styles from '../PortfolioFees.module.scss';

function sum(...values) {
	const valid = values.filter((value) => !Number.isNaN(value));
	return valid.length > 0 ? valid.reduce((a, b) => a + b, 0) : Number.NaN;
}

export default function PortfolioFeesOverview({ feesOverview }) {
	const [t] = useTranslation();
	const language = useLanguage();
	const formatCurrency = useFormatNumber({ style: 'currency', currency: feesOverview.currency?.code });
	const formatPercent = useFormatNumber({ style: 'percent', fractionDigits: 2 });

	const fondeeFeeYearly = toNumber(feesOverview.feeRatio);
	const etfFeeYearly = toNumber(feesOverview.feeTer);
	const fondeeFeeMonthly = toNumber(feesOverview.feeThisMonth);
	const etfFeeMonthly = toNumber(feesOverview.feeTerThisMonth);
	const totalFeeYearly = sum(fondeeFeeYearly, etfFeeYearly);
	const totalFeeMonthly = sum(fondeeFeeMonthly, etfFeeMonthly);

	return (
		<>
			<h2 className={styles.title}>
				{t('portfolioFeesOverview.title')}
			</h2>
			<table className={`${styles.table} ${styles.tableOverview}`}>
				<thead>
					<tr>
						<th>
							{' '}
						</th>
						<th>
							{t('portfolioFeesOverview.table.columns.year')}
						</th>
						<th>
							{t('portfolioFeesOverview.table.columns.month')}
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							{t('portfolioFeesOverview.table.rows.fondee')}
						</td>
						<td>
							{fondeeFeeYearly < 0.009 ? (
								<strong>
									{formatPercent(fondeeFeeYearly)}
								</strong>
							) : formatPercent(fondeeFeeYearly)}
						</td>
						<td>
							{feesOverview.bonusToDate ? (
								<>
									<strong>
										<span className={styles.highlight}>
											{formatCurrency(fondeeFeeMonthly)}
										</span>
									</strong>
									<span className={styles.bonus}>
										{t('portfolioFeesOverview.table.discount', {
											date: new Date(feesOverview.bonusToDate).toLocaleDateString(language),
										})}
									</span>
								</>
							) : (
								<span className={styles.highlight}>
									{formatCurrency(fondeeFeeMonthly)}
								</span>
							)}
						</td>
					</tr>
					<tr>
						<td>
							{t('portfolioFeesOverview.table.rows.etf')}
						</td>
						<td>
							{formatPercent(etfFeeYearly)}
						</td>
						<td>
							{formatCurrency(etfFeeMonthly)}
						</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<th>
							{t('portfolioFeesOverview.table.rows.total')}
						</th>
						<th>
							{formatPercent(totalFeeYearly)}
						</th>
						<th>
							{formatCurrency(totalFeeMonthly)}
						</th>
					</tr>
				</tfoot>
			</table>
			<p className={styles.note}>
				{t('portfolioFeesOverview.note')}
			</p>
			<p className={styles.infoText}>
				{t('portfolioFeesOverview.text')}
			</p>
		</>
	);
}

PortfolioFeesOverview.propTypes = {
	feesOverview: PropTypes.shape({
		currency: PropTypes.shape({
			code: PropTypes.string,
			codeShort: PropTypes.string,
			codeShortBefore: PropTypes.bool,
		}).isRequired,
		totalValue: PropTypes.number,
		cash: PropTypes.number,
		netGrowth: PropTypes.number,
		netGrowthRatio: PropTypes.string,
		feeRatio: PropTypes.string,
		feeThisMonth: PropTypes.number,
		feeTer: PropTypes.string,
		feeTerThisMonth: PropTypes.number,
		cashBonusThisMonth: PropTypes.number,
		bonusToDate: PropTypes.string,
	}).isRequired,
};
