/* global fetch */
import { getApiBaseUrl } from './apiSettings';
import { COOKIE_CONSENT_REVISION } from './cookieConsent';
import { toISODate } from './date';

const apiBaseUrl = getApiBaseUrl();
const apiUrl = (url) => `${apiBaseUrl}/v1/${url}`;
const proxyUrl = (userId, url) => `${process.env.REACT_APP_PROXY_URL}/${userId}/v1/${url}`;
const apiOrProxyUrl = (api, proxy, userId) => (userId !== null ? proxy(userId) : api);

const CONTACT_FORM_URL = apiUrl('contact-form');
const CONTACT_US_URL = apiUrl('contact-us');
const COOKIE_CONSENT_URL = apiUrl('cookie-consent');
const EMAIL_ALIAS_URL = apiUrl('user/email-alias');
const EMAIL_VERIFICATION_URL = apiUrl('user/email-verification');
const FAQ_URL = apiUrl('faq');
const FEES_COMPARISON_URL = apiUrl('fee-comparison');
const INFO_BOX_URL = apiUrl('user/info-box');
const INVESTMENT_MICROSITE_INSTRUMENT_URL = apiUrl('investment-microsite/instrument');
const INVESTOR_PROFILE_HISTORY_CHART_URL = apiUrl('investor-profile-history-chart');
const INVESTOR_PROFILE_URL = apiUrl('investor-profile');
const MEDIA_MENTION_URL = apiUrl('media-mention');
const OFFERS_URL = apiUrl('affiliate-offer');
const OUR_APPROACH_URL = apiUrl('our-approach');
const PHONE_PREFIX_URL = apiUrl('user/phone-prefix');
const REGISTER_URL = apiUrl('user/registration');
const REGISTER_URL_APPLE = apiUrl('user/social-endpoint/apple');
const REGISTER_URL_FACEBOOK = apiUrl('user/social-endpoint/facebook');
const REGISTER_URL_GOOGLE = apiUrl('user/social-endpoint/google');
const REGISTER_URL_LINKEDIN = apiUrl('user/social-endpoint/linkedin');
const LOGIN_SOCIAL_URL = apiUrl('user/social-auth');
const LOGIN_URL = apiUrl('user/login');
const LOGIN_URL_APPLE = apiUrl('user/social-auth/apple');
const LOGIN_URL_FACEBOOK = apiUrl('user/social-auth/facebook');
const LOGIN_URL_GOOGLE = apiUrl('user/social-auth/google');
const LOGIN_URL_LINKEDIN = apiUrl('user/social-auth/linkedin');
const LOGOUT_URL = apiUrl('user/logout');
const FORGOT_PASSWORD_URL = apiUrl('user/password-reset-request');
const NATIONALITY_URL = apiUrl('user/country-nationality');
const MOBILE_INVALIDATE_TOKEN = apiUrl('mobile/invalidate-token');
const MOBILE_USER_DEVICE_TOKEN = apiUrl('mobile/user-device-token');
const PROFILE_AFFILIATE_OFFER_SEEN = apiUrl('profile/affiliate-offer-seen');
const PROFILE_BROKER_ACCOUNTS = apiUrl('profile/broker-accounts');
const PROFILE_CANCEL_PORTFOLIO_WITHDRAWAL = apiUrl('profile/cancel-portfolio-withdrawal');
const PROFILE_CHECK_EMPLOYER = apiUrl('profile/check-employer');
const PROFILE_CHECK_SHOW_ESG_WARNING = apiUrl('profile/check-show-esg-warning');
const PROFILE_CHANGE_PASSWORD_URL = apiUrl('profile/change-password');
const PROFILE_CHANGE_PORTFOLIO_FACE_IMAGE = apiUrl('profile/change-portfolio-face-image');
const PROFILE_CHANGE_PORTFOLIO_INVESTOR_PROFILE = apiUrl('profile/change-portfolio-investor-profile');
const PROFILE_CHANGE_PORTFOLIO_KEEP_CASH_RATIO = apiUrl('profile/change-portfolio-keep-cash-ratio');
const PROFILE_CHANGE_PORTFOLIO_NAME = apiUrl('profile/change-portfolio-name');
const PROFILE_CHANGE_PORTFOLIO_SKIP_REBALANCING = apiUrl('profile/change-portfolio-skip-rebalancing');
const PROFILE_CHECK_REGISTRATION_PROCESS = apiUrl('profile/check-registration-process');
const PROFILE_CHILDREN_LIST = apiUrl('profile/children-list');
const PROFILE_CHILD_EMAIL_CHANGE = apiUrl('profile/child-email-change');
const PROFILE_CHILD_EMAIL_VERIFICATION = apiUrl('profile/child-email-verification');
const PROFILE_CHILD_EMAIL_VERIFICATION_CANCEL = apiUrl('profile/child-email-verification-cancel');
const PROFILE_CHILD_ONBOARDING_CLOSE_ACCOUNT = apiUrl('profile/child-onboarding-close-account');
const PROFILE_CHILD_ONLY_CLIENT_PRODUCT = apiUrl('profile/child-only-client-product');
const PROFILE_CHILD_PERSONAL_INFORMATION = apiUrl('profile/child-personal-information');
const PROFILE_CLIENT_CHECK = apiUrl('profile/client-check');
const PROFILE_CLIENT_CHECK_AML = apiUrl('profile/client-check-aml');
const PROFILE_CLIENT_CHECK_DOCUMENT_REDIRECT = apiUrl('profile/client-check-document-redirect');
const PROFILE_CLIENT_CHECK_DOCUMENT_UPLOAD = apiUrl('profile/client-check-document-upload');
const PROFILE_CLIENT_CHECK_ESG_PREFERENCE_OVERWRITE_ANSWERS = apiUrl('profile/client-check-esg-preference-overwrite-answers');
const PROFILE_CLIENT_CHECK_ESG_PREFERENCE_RESET_QUESTIONNAIRE = apiUrl('profile/client-check-esg-preference-reset-questionnaire');
const PROFILE_CLIENT_CHECK_INVESTMENT_QUESTIONNAIRE = apiUrl('profile/client-check-investment-questionnaire');
const PROFILE_CLIENT_CHECK_INVESTMENT_QUESTIONNAIRE_FINISH = apiUrl('profile/client-check-investment-questionnaire-finish');
const PROFILE_CLIENT_CHECK_INVESTMENT_QUESTIONNAIRE_MANUAL = apiUrl('profile/client-check-investment-questionnaire-manual');
const PROFILE_CLIENT_CHECK_INVESTMENT_QUESTIONNAIRE_RESET = apiUrl('profile/client-check-investment-questionnaire-reset');
const PROFILE_CLIENT_CHECK_PORTFOLIO_INVESTOR_PROFILE = apiUrl('profile/client-check-portfolio-investor-profile');
const PROFILE_CLOSE_INFO_BOX = apiUrl('profile/close-info-box');
const PROFILE_CONTRACT = apiUrl('profile/contract');
const PROFILE_CONTRACT_LIST = apiUrl('profile/contract-list');
const PROFILE_CHILD_REGISTRATION = apiUrl('profile/child-registration');
const PROFILE_DASHBOARD = apiUrl('profile/dashboard');
const PROFILE_DEPOSIT_DEFAULT_PORTFOLIO = apiUrl('profile/change-deposit-default-portfolio');
const PROFILE_DOCUMENT_UPLOAD = apiUrl('profile/document-upload');
const PROFILE_EMPLOYER = apiUrl('profile/employer');
const PROFILE_EMPLOYER_ACTIVATION = apiUrl('profile/employer-activation');
const PROFILE_GENERATE_CONTRACT = apiUrl('profile/generate-contract');
const PROFILE_GENERATE_LEGAL_REPRESENTATIVE_DECLARATION = apiUrl('profile/generate-legal-representative-declaration');
const PROFILE_IDENTITY = apiUrl('profile/identity');
const PROFILE_INVESTMENT_PROFILE = apiUrl('profile/investment-profile');
const PROFILE_LEGAL_REPRESENTATIVE_DECLARATION = apiUrl('profile/legal-representative-declaration');
const PROFILE_ONBOARDING_CHILD_REGISTRATION = apiUrl('profile/onboarding-child-registration');
const PROFILE_ONBOARDING_CLOSE_ACCOUNT = apiUrl('profile/onboarding-close-account');
const PROFILE_ONBOARDING_ESG_PREFERENCE_OVERWRITE_ANSWERS = apiUrl('profile/onboarding-esg-preference-overwrite-answers');
const PROFILE_ONBOARDING_ESG_PREFERENCE_RESET_QUESTIONNAIRE = apiUrl('profile/onboarding-esg-preference-reset-questionnaire');
const PROFILE_ONBOARDING_FEEDBACK = apiUrl('profile/onboarding-feedback');
const PROFILE_ONBOARDING_IDENTIFICATION_REDIRECT = apiUrl('profile/onboarding-identification-redirect');
const PROFILE_ONBOARDING_INVESTMENT_PROFILE = apiUrl('profile/onboarding-investment-profile');
const PROFILE_ONBOARDING_INVESTMENT_QUESTIONNAIRE_RESET = apiUrl('profile/onboarding-investment-questionnaire-reset');
const PROFILE_ONBOARDING_PHONE_NUMBER = apiUrl('profile/onboarding-phone-number');
const PROFILE_ONBOARDING_QUESTIONNAIRE = apiUrl('profile/onboarding-questionnaire');
const PROFILE_ONBOARDING_USER_ACCOUNT = apiUrl('profile/onboarding-user-account');
const PROFILE_PAYMENT_CONFIRMATION = apiUrl('profile/payment-confirmation');
const PROFILE_PENSION_CONFIRMATION = apiUrl('profile/pension-confirmation');
const PROFILE_PERSONAL_DATA = apiUrl('profile/personal-data');
const PROFILE_PERSONAL_INFORMATION = apiUrl('profile/personal-information');
const PROFILE_PERSONAL_INFORMATION_CHECK = apiUrl('profile/personal-information-check');
const PROFILE_PHONE_CHANGE_CODE = apiUrl('profile/phone-change-code');
const PROFILE_PHONE_NUMBER_CHANGE = apiUrl('profile/phone-number-change');
const PROFILE_PHONE_NUMBER_CANCEL_CHANGE = apiUrl('profile/phone-number-cancel-change');
const PROFILE_PORTFOLIO = apiUrl('profile/portfolio');
const PROFILE_PORTFOLIO_CLOSE_ACCOUNT = apiUrl('profile/close-account');
const PROFILE_PORTFOLIO_CANCEL_ACCOUNT_CLOSURE = apiUrl('profile/cancel-account-closure');
const PROFILE_PORTFOLIO_CURRENCY = apiUrl('profile/portfolio-currency');
const PROFILE_PORTFOLIO_HISTORY = apiUrl('profile/portfolio-history');
const PROFILE_PORTFOLIO_HISTORY_PDF = apiUrl('profile/portfolio-history-pdf');
const PROFILE_PORTFOLIO_WITHDRAWAL = apiUrl('profile/portfolio-withdrawal');
const PROFILE_PORTFOLIO_WITHDRAWAL_PENSION_CHECK = apiUrl('profile/portfolio-withdrawal-pension-check');
const PROFILE_PORTFOLIO_WITHDRAWAL_RATIO = apiUrl('profile/portfolio-withdrawal-ratio-check');
const PROFILE_PORTFOLIO_WITHDRAWAL_USER_ACCOUNT_CHECK = apiUrl('profile/portfolio-withdrawal-user-account-check');
const PROFILE_PORTFOLIO_WITHDRAWAL_VOLUME = apiUrl('profile/portfolio-withdrawal-volume-check');
const PROFILE_PRODUCT = apiUrl('profile/product');
const PROFILE_PREFERENCE = apiUrl('profile/user-preference');
const PROFILE_SEND_PAYMENT_INFORMATION = apiUrl('profile/send-payment-information');
const PROFILE_SEND_RECOMMENDATION = apiUrl('profile/send-recommendation');
const PROFILE_SENT_RECOMMENDATION = apiUrl('profile/sent-recommendation');
const PROFILE_SIGN_CONTRACT = apiUrl('profile/sign-contract');
const PROFILE_STATISTICS = apiUrl('profile/statistics');
const PROFILE_SURVEY = apiUrl('profile/survey');
const PROFILE_SURVEY_ANSWER = apiUrl('profile/survey-answer');
const PROFILE_SURVEY_LOG = apiUrl('profile/survey-log');
const PROFILE_TAX_CHILDREN_LIST = apiUrl('profile/tax-children-list');
const PROFILE_TAX_PROGRESS = apiUrl('profile/tax-progress');
const PROFILE_TAX_QUESTIONNAIRE = apiUrl('profile/tax-questionnaire');
const PROFILE_TAX_QUESTIONNAIRE_ANSWER = apiUrl('profile/tax-questionnaire');
const PROFILE_TAX_QUESTIONNAIRE_RESET = apiUrl('profile/tax-questionnaire-reset');
const PROFILE_TAX_RESULT_FILE = apiUrl('profile/tax-result-file');
const PROFILE_TAX_STATEMENT = apiUrl('profile/tax-statement');
const PROFILE_TWO_FACTOR_AUTHENTICATION = apiUrl('profile/two-factor-authentication');
const PROFILE_USER_ACCOUNT = apiUrl('profile/user-account');
const PROFILE_USER_CURRENCY = apiUrl('profile/user-currency');
const PROFILE_VALIDATE_SMS_CODE = apiUrl('profile/validate-sms-code');
const RESET_PASSWORD_URL = apiUrl('user/password-reset');
const UNSUBSCRIBE_URL = apiUrl('user/marketing-unsubscribe');
const UTM_CAMPAIGN = apiUrl('utm-campaign');
const VALIDATION_FIRST_NAME = apiUrl('validation/first-name');
const VALIDATION_LAST_NAME = apiUrl('validation/last-name');
const WEBFLOW_URL = apiUrl('webflow');

const PROXY_PROFILE_DASHBOARD = (userId) => proxyUrl(userId, 'profile/dashboard');
const PROXY_PROFILE_PORTFOLIO = (userId) => proxyUrl(userId, 'profile/portfolio');
const PROXY_PROFILE_PORTFOLIO_HISTORY = (userId) => proxyUrl(userId, 'profile/portfolio-history');

const CSRF_HEADER = 'X-CSRF-Token';
const CSRF_TOKEN_URL = apiUrl('csrf-token');
let csrfToken = null;
let csrfTokenRequest = null;

function createQueryString(query) {
	const params = new URLSearchParams();
	const keys = Object.keys(query);
	for (let i = 0; i < keys.length; i += 1) {
		const key = keys[i];
		if (query[key] == null) { // null or undefined
			// skip
		} else if (typeof query[key] === 'boolean') {
			params.append(key, query[key] ? '1' : '0');
		} else {
			params.append(key, query[key]);
		}
	}

	const queryString = params.toString();
	return queryString !== '' ? `?${queryString}` : '';
}

async function handleResponse(response) {
	if (!(response.status >= 200 && response.status < 300)) {
		const error = new Error(response.statusText);
		error.response = response;
		try {
			error.responseJson = await response.json();
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
		}
		throw error;
	}

	if (response.headers.has(CSRF_HEADER)) {
		csrfToken = response.headers.get(CSRF_HEADER);
	}

	return response.json();
}

function fetchCsrfToken() {
	if (csrfTokenRequest !== null) {
		return csrfTokenRequest;
	}

	csrfTokenRequest = fetch(CSRF_TOKEN_URL, {
		credentials: 'include',
	}).then((csrf) => {
		if (csrf.headers.has(CSRF_HEADER)) {
			csrfToken = csrf.headers.get(CSRF_HEADER);
		}
	}).finally(() => {
		csrfTokenRequest = null;
	});

	return csrfTokenRequest;
}

async function fetchJson(url, options = {}, retry = false, skipCsrf = false) {
	if (!skipCsrf && csrfToken === null) {
		await fetchCsrfToken();
	}

	const response = await fetch(url, {
		credentials: 'include',
		...options,
		headers: {
			Accept: 'application/json',
			...(!skipCsrf && csrfToken !== null ? { [CSRF_HEADER]: csrfToken } : {}),
			...options.headers,
		},
	});

	if (!skipCsrf && response.status === 403) {
		csrfToken = null;
		if (!retry) {
			return fetchJson(url, options, true);
		}
	}

	return handleResponse(response);
}

function postJson(url, data, options = {}) {
	return fetchJson(url, {
		method: 'POST',
		body: JSON.stringify(data),
		...options,
		headers: {
			'Content-Type': 'application/json',
			...options.headers,
		},
	});
}

function putJson(url, id, data, options = {}) {
	return postJson(`${url}/${id}`, data, {
		method: 'PUT',
		...options,
	});
}

export function getLoginUrlApple(country, language) {
	return `${LOGIN_URL_APPLE}${createQueryString({
		country,
		language,
	})}`;
}

export function getLoginUrlFacebook(country, language) {
	return `${LOGIN_URL_FACEBOOK}${createQueryString({
		country,
		language,
	})}`;
}

export function getLoginUrlGoogle(country, language) {
	return `${LOGIN_URL_GOOGLE}${createQueryString({
		country,
		language,
	})}`;
}

export function getLoginUrlLinkedIn(country, language) {
	return `${LOGIN_URL_LINKEDIN}${createQueryString({
		country,
		language,
	})}`;
}

export function getProfileClientCheckDocumentRedirect(childId, country, language) {
	return `${PROFILE_CLIENT_CHECK_DOCUMENT_REDIRECT}${createQueryString({
		childId,
		country,
		language,
	})}`;
}

export function getProfileContract(contractId, contractType, country, language) {
	const url = contractType === 'legalRepresentativeDeclarationSigned'
		? PROFILE_LEGAL_REPRESENTATIVE_DECLARATION
		: PROFILE_CONTRACT;
	return url !== null ? `${url}/${contractId}${createQueryString({ country, language })}` : null;
}

export function getProfileGenerateContract(childId = null, category = null) {
	return `${PROFILE_GENERATE_CONTRACT}${createQueryString({ childId, category })}`;
}

export function getProfileGenerateLegalRepresentativeDeclaration(childId = null, category = null) {
	return `${PROFILE_GENERATE_LEGAL_REPRESENTATIVE_DECLARATION}${createQueryString({ childId, category })}`;
}

export function getProfileOnboardingIdentificationRedirect(childId, country, language) {
	return `${PROFILE_ONBOARDING_IDENTIFICATION_REDIRECT}${createQueryString({
		childId,
		country,
		language,
	})}`;
}

export function getProfilePortfolioHistoryPDF(portfolioId, type, country, language, fromDate, toDate) {
	return `${PROFILE_PORTFOLIO_HISTORY_PDF}/${portfolioId}${createQueryString({
		type,
		country,
		language,
		fromDate: toISODate(fromDate),
		toDate: toISODate(toDate),
	})}`;
}

export function getProfileTaxResultFile(language) {
	return `${PROFILE_TAX_RESULT_FILE}${createQueryString({
		language,
	})}`;
}

export function getProfileTaxStatement(language, childId = null) {
	return `${PROFILE_TAX_STATEMENT}${createQueryString({
		language,
		childId,
	})}`;
}

export function getPensionConfirmation(portfolioId) {
	return `${PROFILE_PENSION_CONFIRMATION}/${portfolioId}`;
}

export function fetchAppleUserData() {
	return fetchJson(REGISTER_URL_APPLE);
}

export function fetchFacebookUserData() {
	return fetchJson(REGISTER_URL_FACEBOOK);
}

export function fetchGoogleUserData() {
	return fetchJson(REGISTER_URL_GOOGLE);
}

export function fetchLinkedinUserData() {
	return fetchJson(REGISTER_URL_LINKEDIN);
}

export function fetchFaqs(country, language, page = 'public') {
	return fetchJson(`${FAQ_URL}${createQueryString({
		country,
		language,
		page,
	})}`);
}

export function fetchOurApproach(
	country,
	language,
	period,
) {
	return fetchJson(`${OUR_APPROACH_URL}${createQueryString({
		country,
		language,
		period,
	})}`);
}

export function fetchFeesComparison(country, language) {
	return fetchJson(`${FEES_COMPARISON_URL}${createQueryString({
		country,
		language,
	})}`);
}

export function fetchInvestorProfile(country, language) {
	return fetchJson(`${INVESTOR_PROFILE_URL}${createQueryString({
		country,
		language,
	})}`);
}

export function fetchInvestmentMicrositeInstrument(country, language, type) {
	return fetchJson(`${INVESTMENT_MICROSITE_INSTRUMENT_URL}${createQueryString({
		country,
		language,
		type,
	})}`);
}

export function fetchMediaMention(country, language) {
	return fetchJson(`${MEDIA_MENTION_URL}${createQueryString({
		country,
		language,
	})}`);
}

export function fetchOffers(country, language) {
	return fetchJson(`${OFFERS_URL}${createQueryString({
		country,
		language,
	})}`);
}

export function fetchPhonePrefixes(country) {
	return fetchJson(`${PHONE_PREFIX_URL}${createQueryString({
		country,
	})}`);
}

export function fetchInfoBox(country, language, isClientZone = false) {
	return fetchJson(`${INFO_BOX_URL}${createQueryString({
		country,
		language,
		isClientZone,
	})}`);
}

export function fetchValidateFirstName(country, firstName) {
	return fetchJson(`${VALIDATION_FIRST_NAME}${createQueryString({
		country,
		name: firstName,
	})}`).then(({ success }) => !!success);
}

export function fetchValidateLastName(country, lastName) {
	return fetchJson(`${VALIDATION_LAST_NAME}${createQueryString({
		country,
		name: lastName,
	})}`).then(({ success }) => !!success);
}

export function fetchUserDeviceToken() {
	return fetchJson(MOBILE_USER_DEVICE_TOKEN);
}

export function fetchProfileRegistrationProcess(childId = null) {
	return fetchJson(`${PROFILE_CHECK_REGISTRATION_PROCESS}${createQueryString({ childId })}`);
}

export function fetchClientCheck(childId = null) {
	return fetchJson(`${PROFILE_CLIENT_CHECK}${createQueryString({ childId })}`);
}

export function fetchClientCheckAMLQuestionnaire(language, childId = null) {
	return fetchJson(`${PROFILE_CLIENT_CHECK_AML}${createQueryString({ language, childId })}`);
}

export function fetchClientCheckInvestmentQuestionnaire(language, childId = null) {
	return fetchJson(`${PROFILE_CLIENT_CHECK_INVESTMENT_QUESTIONNAIRE}${createQueryString({ language, childId })}`);
}

export function fetchOnboardingFeedback(childId = null) {
	return fetchJson(`${PROFILE_ONBOARDING_FEEDBACK}${createQueryString({ childId })}`);
}

export function fetchOnboardingInvestmentProfile(childId = null) {
	return fetchJson(`${PROFILE_ONBOARDING_INVESTMENT_PROFILE}${createQueryString({ childId })}`);
}

export function fetchOnboardingQuestionnaire(language, childId = null) {
	return fetchJson(`${PROFILE_ONBOARDING_QUESTIONNAIRE}${createQueryString({ language, childId })}`);
}

export function fetchProfileIdentity(language = null) {
	return fetchJson(`${PROFILE_IDENTITY}${createQueryString({ language })}`);
}

export function fetchProfileContract() {
	return fetchJson(PROFILE_CONTRACT_LIST);
}

export function fetchProfilePortfolio(language, childId = null, isProxy = false) {
	if (isProxy) {
		return Promise.resolve(null);
	}

	return fetchJson(`${PROFILE_PORTFOLIO}${createQueryString({ language, childId })}`);
}

export function fetchProfilePortfolioDetail(portfolioId, language, proxyId = null) {
	if (portfolioId === null) {
		return Promise.resolve(null);
	}

	const url = apiOrProxyUrl(PROFILE_PORTFOLIO, PROXY_PROFILE_PORTFOLIO, proxyId);
	return fetchJson(`${url}/${portfolioId}${createQueryString({ language })}`, {}, false, proxyId !== null);
}

export function fetchProfilePortfolioHistory(
	portfolioId,
	language,
	fromDate,
	toDate,
	aggregationPeriod,
	proxyId = null,
) {
	if (portfolioId === null) {
		return Promise.resolve(null);
	}

	const url = apiOrProxyUrl(PROFILE_PORTFOLIO_HISTORY, PROXY_PROFILE_PORTFOLIO_HISTORY, proxyId);
	return fetchJson(`${url}/${portfolioId}${createQueryString({
		language,
		fromDate: toISODate(fromDate),
		toDate: toISODate(toDate),
		aggregationPeriod,
	})}`, {}, false, proxyId !== null);
}

export function fetchProfilePreference() {
	return fetchJson(PROFILE_PREFERENCE);
}

export function fetchProfileStatistics() {
	return fetchJson(PROFILE_STATISTICS);
}

export function fetchProfileSurvey(language) {
	return fetchJson(`${PROFILE_SURVEY}${createQueryString({ language })}`);
}

export function fetchProfileInvestmentProfile(portfolioId = null, childId = null) {
	return fetchJson(`${PROFILE_INVESTMENT_PROFILE}${portfolioId !== null ? `/${portfolioId}` : ''}${createQueryString({ childId })}`);
}

export function fetchProfilePersonalData() {
	return fetchJson(PROFILE_PERSONAL_DATA);
}

export function fetchProfileBrokerAccounts(language) {
	return fetchJson(`${PROFILE_BROKER_ACCOUNTS}${createQueryString({
		language,
	})}`);
}

export function fetchProfileBrokerAccountsPortfolio(portfolioId, childId = null) {
	if (portfolioId === null) {
		return Promise.resolve(null);
	}

	return fetchJson(`${PROFILE_BROKER_ACCOUNTS}/${portfolioId}${createQueryString({ childId })}`);
}

export function fetchProfileTwoFactorAuthentication() {
	return fetchJson(PROFILE_TWO_FACTOR_AUTHENTICATION);
}

export function fetchSentRecommendation() {
	return fetchJson(PROFILE_SENT_RECOMMENDATION);
}

export function fetchProfilePortfolioWithdrawal(portfolioId, language) {
	return fetchJson(`${PROFILE_PORTFOLIO_WITHDRAWAL}${createQueryString({
		portfolioId,
		language,
	})}`);
}

export function fetchProfilePortfolioWithdrawalPensionCheck(portfolio = null) {
	if (portfolio === null) {
		return fetchJson(PROFILE_PORTFOLIO_WITHDRAWAL_PENSION_CHECK).then(({ success }) => !!success);
	}

	const { id, categoryName } = portfolio;
	if (categoryName !== 'pension') {
		return Promise.resolve(true);
	}

	return fetchJson(`${PROFILE_PORTFOLIO_WITHDRAWAL_PENSION_CHECK}/${id}`).then(({ success }) => !!success);
}

export function fetchProfilePortfolioWithdrawalUserAccountCheck(portfolioId) {
	return Promise.all([
		fetchJson(`${PROFILE_PORTFOLIO_WITHDRAWAL_USER_ACCOUNT_CHECK}/${portfolioId}?type=1`), // volume
		fetchJson(`${PROFILE_PORTFOLIO_WITHDRAWAL_USER_ACCOUNT_CHECK}/${portfolioId}?type=2`), // all
		fetchJson(`${PROFILE_PORTFOLIO_WITHDRAWAL_USER_ACCOUNT_CHECK}/${portfolioId}?type=3`), // allAndClosePortfolio
	]).then(([volume, all, allAndClosePortfolio]) => ({
		volume: !!volume.success,
		all: !!all.success,
		allAndClosePortfolio: !!allAndClosePortfolio.success,
	}));
}

export function fetchProfilePortfolioCurrency(portfolioId, language, isProxy = false) {
	if (portfolioId === null || isProxy) {
		return Promise.resolve(null);
	}

	return fetchJson(`${PROFILE_PORTFOLIO_CURRENCY}/${portfolioId}${createQueryString({
		language,
	})}`);
}

export function updatePortfolioCurrency(portfolioId, currency) {
	return putJson(PROFILE_PORTFOLIO_CURRENCY, portfolioId, { currency });
}

export function fetchTaxChildrenList(isChild = false) {
	if (isChild) {
		return Promise.resolve(null);
	}

	return fetchJson(PROFILE_TAX_CHILDREN_LIST);
}

export function fetchTaxProgress() {
	return fetchJson(PROFILE_TAX_PROGRESS);
}

export function fetchTaxQuestionnaire(language) {
	return fetchJson(`${PROFILE_TAX_QUESTIONNAIRE}${createQueryString({ language })}`);
}

export function fetchWebflow(country, languages) {
	return Promise.all(languages.map((language) => (
		fetchJson(`${WEBFLOW_URL}${createQueryString({
			country,
			language,
		})}`)
	))).then((data) => {
		const result = {};
		languages.forEach((language, i) => {
			result[language] = {};
			const languageData = data[i] ?? {};
			Object.keys(languageData).forEach((key) => {
				result[language][key] = languageData[key]?.items ?? [];
			});
		});
		return result;
	});
}

export function fetchDashboardData(period, language, proxyId = null) {
	const url = apiOrProxyUrl(PROFILE_DASHBOARD, PROXY_PROFILE_DASHBOARD, proxyId);
	return fetchJson(`${url}${createQueryString({ period, language })}`, {}, false, proxyId !== null);
}

export function clientCheckOverwriteEsgPreferenceAnswers(childId = null) {
	return postJson(`${PROFILE_CLIENT_CHECK_ESG_PREFERENCE_OVERWRITE_ANSWERS}${createQueryString({ childId })}`, {});
}

export function clientCheckResetEsgPreferenceQuestionnaire(childId = null) {
	return postJson(`${PROFILE_CLIENT_CHECK_ESG_PREFERENCE_RESET_QUESTIONNAIRE}${createQueryString({ childId })}`, {});
}

export function clientCheckFinishInvestmentQuestionnaire(childId = null) {
	return postJson(`${PROFILE_CLIENT_CHECK_INVESTMENT_QUESTIONNAIRE_FINISH}${createQueryString({ childId })}`, {});
}

export function clientCheckManualStartInvestmentQuestionnaire(childId = null) {
	return postJson(`${PROFILE_CLIENT_CHECK_INVESTMENT_QUESTIONNAIRE_MANUAL}${createQueryString({ childId })}`, {});
}

export function clientCheckResetInvestmentQuestionnaire(childId = null) {
	return postJson(`${PROFILE_CLIENT_CHECK_INVESTMENT_QUESTIONNAIRE_RESET}${createQueryString({ childId })}`, {});
}

export function clientCheckSavePortfolioInvestorProfile(portfolioId, profile) {
	return putJson(PROFILE_CLIENT_CHECK_PORTFOLIO_INVESTOR_PROFILE, portfolioId, { profile });
}

export function clientCheckSaveAMLAnswer(questionId, answerId, answerValue, answerText, childId = null) {
	return postJson(`${PROFILE_CLIENT_CHECK_AML}${createQueryString({ childId })}`, {
		questionId,
		answerId: answerId ?? null,
		answerValue: answerValue ?? null,
		answerText: answerText ?? null,
	});
}

export function clientCheckSaveInvestmentAnswer(questionId, answerId, answerValue, answerText, childId = null) {
	return postJson(`${PROFILE_CLIENT_CHECK_INVESTMENT_QUESTIONNAIRE}${createQueryString({ childId })}`, {
		questionId,
		answerId: answerId ?? null,
		answerValue: answerValue ?? null,
		answerText: answerText ?? null,
	});
}

export function fetchUserCurrency(language, isProxy) {
	if (isProxy) {
		return Promise.resolve(null);
	}

	return fetchJson(`${PROFILE_USER_CURRENCY}${createQueryString({
		language,
	})}`);
}

export function fetchCountryNationality(country, language) {
	return fetchJson(`${NATIONALITY_URL}${createQueryString({
		country,
		language,
	})}`);
}

export function fetchChildrenList(isChild = false) {
	if (isChild) {
		return Promise.resolve(null);
	}

	return fetchJson(PROFILE_CHILDREN_LIST);
}

export function fetchShowEsgWarning(childId = null) {
	return fetchJson(`${PROFILE_CHECK_SHOW_ESG_WARNING}${createQueryString({ childId })}`).then((result) => !!result?.show);
}

export function twoFactorSocialLogin(country, language, twoFactorCode, social) {
	return postJson(LOGIN_SOCIAL_URL, {
		country,
		language,
		twoFactorCode,
		social,
	});
}

export function emailAlias(social) {
	return postJson(EMAIL_ALIAS_URL, {
		social,
	});
}

export function login(email, password, twoFactorCode) {
	return postJson(LOGIN_URL, {
		email,
		password,
		twoFactorCode,
	});
}

export function logout() {
	return postJson(LOGOUT_URL, {});
}

export function forgotPassword(email) {
	return postJson(FORGOT_PASSWORD_URL, { email });
}

export function emailVerification(code, email) {
	return postJson(EMAIL_VERIFICATION_URL, { email, code });
}

export function resetPassword(email, code, password, passwordConfirm) {
	return postJson(RESET_PASSWORD_URL, {
		email,
		code,
		password,
		passwordConfirm,
	});
}

export function changePassword(currentPassword, newPassword, checkNewPassword) {
	return postJson(PROFILE_CHANGE_PASSWORD_URL, {
		currentPassword,
		newPassword,
		checkNewPassword,
	});
}

export function register(
	country,
	language,
	firstName,
	lastName,
	email,
	password,
	passwordConfirm,
	phonePrefix,
	phoneNumber,
	referenceCode,
	personalInfoAgreed,
	conditionsAgreed,
	affiliateSource,
	affiliateCode,
) {
	return postJson(REGISTER_URL, {
		country,
		language,
		firstName,
		lastName,
		email,
		password,
		passwordConfirm,
		phonePrefix,
		phoneNumber,
		referenceCode,
		personalInfoAgreed,
		conditionsAgreed,
		affiliateSource,
		affiliateCode,
	});
}

export function postContactForm(country, fullName, phone, email) {
	return postJson(CONTACT_FORM_URL, {
		country,
		fullName,
		phone,
		email,
	});
}

export function postContactUs(country, language, name, email, text, recaptchaToken) {
	return postJson(CONTACT_US_URL, {
		country,
		language,
		name,
		email,
		text,
		recaptchaToken,
	});
}

export function invalidateUserDeviceToken(tokenId) {
	return postJson(MOBILE_INVALIDATE_TOKEN, {
		tokenId,
	});
}

export function updateProfilePreference(statementPeriodicity, survey, marketingEmailsUnsubscribe) {
	return postJson(PROFILE_PREFERENCE, {
		statementPeriodicity,
		survey,
		marketingEmailsUnsubscribe,
	});
}

export function saveQuestionnaireAnswer(questionId, answerId, answerValue, answerText, childId = null) {
	return postJson(`${PROFILE_ONBOARDING_QUESTIONNAIRE}${createQueryString({ childId })}`, {
		questionId,
		answerId: answerId ?? null,
		answerValue: answerValue ?? null,
		answerText: answerText ?? null,
	});
}

export function saveTaxQuestionnaireAnswer(questionId, answerId) {
	return postJson(PROFILE_TAX_QUESTIONNAIRE_ANSWER, {
		questionId,
		answerId,
	});
}

export function resetTaxQuestionnaire() {
	return postJson(PROFILE_TAX_QUESTIONNAIRE_RESET, {});
}

export function saveOnboardingInvestorProfile(childId, profile) {
	return postJson(`${PROFILE_ONBOARDING_INVESTMENT_PROFILE}${createQueryString({ childId })}`, {
		profile,
	});
}

export function saveProfilePersonalData(
	name,
	contactAddress,
	streetName,
	streetNumber,
	city,
	postalCode,
	contactAddressStreetName,
	contactAddressStreetNumber,
	contactAddressCity,
	contactAddressPostalCode,
	birthDate,
	personalIdentificationNumber,
	nationality,
	placeOfBirth,
	bankPrefix,
	bankAccount,
	bankCode,
	iban,
) {
	return postJson(PROFILE_PERSONAL_DATA, {
		...(name !== null ? { name } : {}),
		streetName,
		streetNumber,
		city,
		postalCode,
		contactAddressStreetName: contactAddress ? contactAddressStreetName : streetName,
		contactAddressStreetNumber: contactAddress ? contactAddressStreetNumber : streetNumber,
		contactAddressCity: contactAddress ? contactAddressCity : city,
		contactAddressPostalCode: contactAddress ? contactAddressPostalCode : postalCode,
		...(birthDate !== null ? { birthDate } : {}),
		personalIdentificationNumber,
		nationality,
		placeOfBirth,
		bankPrefix,
		bankAccount,
		bankCode,
		iban,
	});
}

export function saveProfilePersonalInformation(
	firstName,
	lastName,
	phonePrefix,
	phoneNumber,
	streetName,
	streetNumber,
	city,
	postalCode,
	contactAddressStreetName,
	contactAddressStreetNumber,
	contactAddressCity,
	contactAddressPostalCode,
	childId = null,
) {
	const data = {
		firstName,
		lastName,
		phonePrefix: phoneNumber !== '' ? phonePrefix : null,
		phoneNumber: phoneNumber !== '' ? phoneNumber : null,
		streetName,
		streetNumber,
		city,
		postalCode,
		contactAddressStreetName,
		contactAddressStreetNumber,
		contactAddressCity,
		contactAddressPostalCode,
	};

	return postJson(`${PROFILE_PERSONAL_INFORMATION}${createQueryString({ childId })}`, data);
}

export function confirmPersonalInformation() {
	return postJson(PROFILE_PERSONAL_INFORMATION_CHECK, {});
}

export function sendPhoneChangeCode(childId = null) {
	return postJson(`${PROFILE_PHONE_CHANGE_CODE}${createQueryString({ childId })}`, {});
}

export function validatePhoneNumberChange(code, childId = null) {
	return postJson(`${PROFILE_PHONE_NUMBER_CHANGE}${createQueryString({ childId })}`, {
		code,
	});
}

export function cancelPhoneNumberChange(childId = null) {
	return postJson(`${PROFILE_PHONE_NUMBER_CANCEL_CHANGE}${createQueryString({ childId })}`, {});
}

export function signContract(childId, category) {
	return postJson(`${PROFILE_SIGN_CONTRACT}${createQueryString({ childId })}`, {
		category,
	});
}

export function validateSmsCode(childId, category, code) {
	return postJson(`${PROFILE_VALIDATE_SMS_CODE}${createQueryString({ childId })}`, {
		category,
		code,
	});
}

export function paymentConfirmation(childId = null) {
	return postJson(`${PROFILE_PAYMENT_CONFIRMATION}${createQueryString({ childId })}`, {});
}

export function feedback(childId, status, message) {
	return postJson(`${PROFILE_ONBOARDING_FEEDBACK}${createQueryString({ childId })}`, {
		status,
		message,
	});
}

export function sendRecommendation(language, email, message, recaptchaToken) {
	return postJson(PROFILE_SEND_RECOMMENDATION, {
		language,
		email,
		message,
		recaptchaToken,
	});
}

export function changeFaceImage(portfolioId, faceImageFile) {
	return putJson(PROFILE_CHANGE_PORTFOLIO_FACE_IMAGE, portfolioId, { faceImageFile });
}

export function changeInvestorProfile(portfolioId, profile) {
	return putJson(PROFILE_CHANGE_PORTFOLIO_INVESTOR_PROFILE, portfolioId, { investorProfilePlannedId: profile });
}

export function changePortfolioName(portfolioId, name) {
	return putJson(PROFILE_CHANGE_PORTFOLIO_NAME, portfolioId, { name });
}

export function changeCashRatio(portfolioId, keepCashRatioPlanned) {
	return putJson(PROFILE_CHANGE_PORTFOLIO_KEEP_CASH_RATIO, portfolioId, { keepCashRatioPlanned });
}

export function changeSkipRebalancing(portfolioId, skipRebalancing) {
	return putJson(PROFILE_CHANGE_PORTFOLIO_SKIP_REBALANCING, portfolioId, { skipRebalancing });
}

export function portfolioWithdrawal(
	portfolioId,
	type,
	volume,
	twoFactorCode,
	currency,
	transferRatio,
	transferToPortfolioId,
	newUserDefaultPortfolioId,
	newOwnerDefaultPortfolioId,
) {
	let data = {
		portfolioId,
		type,
		transferToPortfolioId,
		twoFactorCode,
		currency,
		newUserDefaultPortfolioId,
		newOwnerDefaultPortfolioId,
	};
	if (type === 1 && volume) {
		data = {
			portfolioId,
			type,
			volume,
			twoFactorCode,
			currency,
		};
	} else if (type === 4 && volume) {
		data = {
			portfolioId,
			type,
			transferToPortfolioId,
			volume,
			twoFactorCode,
			currency,
		};
	} else if (type === 6 && transferRatio) {
		data = {
			portfolioId,
			type,
			transferToPortfolioId,
			twoFactorCode,
			transferRatio,
			currency,
		};
	}
	return postJson(PROFILE_PORTFOLIO_WITHDRAWAL, data);
}

export function closeAccount(twoFactorCode, currency, childId = null, newDefaultPortfolioId = null) {
	return postJson(`${PROFILE_PORTFOLIO_CLOSE_ACCOUNT}${createQueryString({ childId })}`, {
		twoFactorCode,
		currency,
		newDefaultPortfolioId,
	});
}

export function portfolioWithdrawalVolume(portfolioId, currency, volume) {
	return fetchJson(`${PROFILE_PORTFOLIO_WITHDRAWAL_VOLUME}/${portfolioId}${createQueryString({
		currency,
		volume,
	})}`);
}

export function portfolioWithdrawalRatio(portfolioId, transferRatio) {
	return fetchJson(`${PROFILE_PORTFOLIO_WITHDRAWAL_RATIO}/${portfolioId}${createQueryString({
		transferRatio,
	})}`);
}

export function cancelAccountClosure(childId = null) {
	return postJson(`${PROFILE_PORTFOLIO_CANCEL_ACCOUNT_CLOSURE}${createQueryString({ childId })}`, {});
}

export function cancelPortfolioWithdrawal(portfolioId) {
	return putJson(PROFILE_CANCEL_PORTFOLIO_WITHDRAWAL, portfolioId, { portfolioId });
}

export function changeMainAccount(mainId) {
	return fetchJson(`${PROFILE_USER_ACCOUNT}/${mainId}`, {
		method: 'PUT',
	});
}

export function addBankAccount(
	bankPrefix,
	bankAccount,
	bankCode,
	iban,
) {
	return postJson(PROFILE_USER_ACCOUNT, {
		bankPrefix,
		bankAccount,
		bankCode,
		iban,
	});
}

export function onboardingAddBankAccount(childId, bankPrefix, bankAccount, bankCode, iban) {
	return postJson(`${PROFILE_ONBOARDING_USER_ACCOUNT}${createQueryString({ childId })}`, {
		bankPrefix,
		bankAccount,
		bankCode,
		iban,
	});
}

export function onboardingCloseAccount() {
	return postJson(PROFILE_ONBOARDING_CLOSE_ACCOUNT, {});
}

export function onboardingOverwriteEsgPreferenceAnswers(childId = null) {
	return postJson(`${PROFILE_ONBOARDING_ESG_PREFERENCE_OVERWRITE_ANSWERS}${createQueryString({ childId })}`, {});
}

export function onboardingResetEsgPreferenceQuestionnaire(childId = null) {
	return postJson(`${PROFILE_ONBOARDING_ESG_PREFERENCE_RESET_QUESTIONNAIRE}${createQueryString({ childId })}`, {});
}

export function onboardingResetInvestmentQuestionnaire(childId = null) {
	return postJson(`${PROFILE_ONBOARDING_INVESTMENT_QUESTIONNAIRE_RESET}${createQueryString({ childId })}`, {});
}

export function set2fa(
	twoFactorEnabled,
	twoFactorCode = null,
) {
	return postJson(PROFILE_TWO_FACTOR_AUTHENTICATION, {
		twoFactorEnabled,
		twoFactorCode,
	});
}

export function closeInfoBox(id) {
	return postJson(PROFILE_CLOSE_INFO_BOX, {
		id,
	});
}

export function saveSurveyAnswer(survey, choiceNumber, text) {
	return postJson(PROFILE_SURVEY_ANSWER, {
		survey,
		choiceNumber,
		text,
	});
}

export function saveSurveyLog(survey, type) {
	return postJson(PROFILE_SURVEY_LOG, {
		survey,
		type,
	});
}

export function saveUtmCampaign(source, medium, campaign, term, content) {
	return postJson(UTM_CAMPAIGN, {
		source,
		medium,
		campaign,
		term,
		content,
	});
}

export function unsubscribe(code, email) {
	return postJson(UNSUBSCRIBE_URL, { email, code });
}

export function saveCookieConsent(categories, url) {
	return postJson(COOKIE_CONSENT_URL, {
		categories,
		revision: COOKIE_CONSENT_REVISION,
		url,
	});
}

export function updateAffiliateOfferSeen(language, affiliateOfferIds) {
	return postJson(PROFILE_AFFILIATE_OFFER_SEEN, {
		language,
		affiliateOfferIds,
	});
}

export function updateDepositDefaultPortfolio(portfolio) {
	return putJson(PROFILE_DEPOSIT_DEFAULT_PORTFOLIO, portfolio, { portfolio });
}

export function updateUserCurrency(currency) {
	return postJson(PROFILE_USER_CURRENCY, {
		currency,
	});
}

export function updatePhoneNumber(childId, category, phonePrefix, phoneNumber) {
	return postJson(`${PROFILE_ONBOARDING_PHONE_NUMBER}${createQueryString({ childId })}`, {
		category,
		phonePrefix,
		phoneNumber,
	});
}

export function createPortfolio(childId, category, name, profile, currency, skipRebalancing, consent) {
	return postJson(PROFILE_PORTFOLIO, {
		childId,
		category,
		name,
		profile,
		currency,
		skipRebalancing,
		consent,
	});
}

export function saveChildPersonalInformation(childId, email, phonePrefix, phoneNumber) {
	return putJson(PROFILE_CHILD_PERSONAL_INFORMATION, childId, {
		email,
		phonePrefix,
		phoneNumber,
	});
}

export function fetchInvestorProfileHistory(
	investorProfileId,
	country,
	language,
) {
	return fetchJson(`${INVESTOR_PROFILE_HISTORY_CHART_URL}${createQueryString({
		investorProfileId,
		country,
		language,
	})}`);
}

export function saveProfileChildRegistration(
	language,
	product,
	email,
	firstName,
	lastName,
	phonePrefix,
	phoneNumber,
	birthDate,
	personalIdentificationNumber,
	referenceCode,
	personalInfoAgreed,
	conditionsAgreed,
	bankPrefix,
	bankAccount,
	bankCode,
	iban,
) {
	return postJson(PROFILE_CHILD_REGISTRATION, {
		language,
		product,
		email: email !== '' ? email : null,
		firstName,
		lastName,
		phonePrefix: phoneNumber !== '' ? phonePrefix : null,
		phoneNumber: phoneNumber !== '' ? phoneNumber : null,
		birthDate,
		personalIdentificationNumber,
		referenceCode: referenceCode !== '' ? referenceCode : null,
		personalInfoAgreed,
		conditionsAgreed,
		bankPrefix: bankPrefix !== '' ? bankPrefix : null,
		bankAccount: bankAccount !== '' ? bankAccount : null,
		bankCode: bankCode !== '' ? bankCode : null,
		iban: iban !== '' ? iban : null,
	});
}

export function postClientCheckDocumentUpload(childId, formData) {
	return fetchJson(`${PROFILE_CLIENT_CHECK_DOCUMENT_UPLOAD}${createQueryString({ childId })}`, {
		method: 'POST',
		body: formData,
	});
}

export function postDocumentUpload(childId, formData) {
	return fetchJson(`${PROFILE_DOCUMENT_UPLOAD}${createQueryString({ childId })}`, {
		method: 'POST',
		body: formData,
	});
}

export function saveProfileProduct(esg, category) {
	return postJson(PROFILE_PRODUCT, {
		product: esg ? 'esg' : 'classic',
		category,
	});
}

export function saveProfileOnboardingChildRegistration(
	language,
	product,
	email,
	firstName,
	lastName,
	phonePrefix,
	phoneNumber,
	birthDate,
	personalIdentificationNumber,
	referenceCode,
	personalInfoAgreed,
	conditionsAgreed,
	bankPrefix,
	bankAccount,
	bankCode,
	iban,
) {
	return postJson(PROFILE_ONBOARDING_CHILD_REGISTRATION, {
		language,
		product,
		email: email !== '' ? email : null,
		firstName,
		lastName,
		phonePrefix: phoneNumber !== '' ? phonePrefix : null,
		phoneNumber: phoneNumber !== '' ? phoneNumber : null,
		birthDate,
		personalIdentificationNumber,
		referenceCode: referenceCode !== '' ? referenceCode : null,
		personalInfoAgreed,
		conditionsAgreed,
		bankPrefix: bankPrefix !== '' ? bankPrefix : null,
		bankAccount: bankAccount !== '' ? bankAccount : null,
		bankCode: bankCode !== '' ? bankCode : null,
		iban: iban !== '' ? iban : null,
	});
}

export function saveProfileChildOnlyClientProduct(esg, category) {
	return postJson(PROFILE_CHILD_ONLY_CLIENT_PRODUCT, {
		product: esg ? 'esg' : 'classic',
		category,
	});
}

export function childOnboardingCloseAccount(childId) {
	return postJson(PROFILE_CHILD_ONBOARDING_CLOSE_ACCOUNT, {
		childId,
	});
}

export function addEmployer(employerId) {
	return postJson(PROFILE_EMPLOYER, {
		employerId,
	});
}

export function fetchEmployers() {
	return fetchJson(PROFILE_EMPLOYER);
}

export function activateEmployer(employerId, active) {
	return postJson(PROFILE_EMPLOYER_ACTIVATION, {
		employerId,
		active,
	});
}

export function checkEmployer(businessId) {
	return postJson(PROFILE_CHECK_EMPLOYER, {
		businessId,
	});
}

export function sendPaymentInformation(portfolioId, language, currency) {
	return postJson(PROFILE_SEND_PAYMENT_INFORMATION, {
		portfolioId,
		language,
		currency,
	});
}

export function childEmailChange(childId, email) {
	return postJson(`${PROFILE_CHILD_EMAIL_CHANGE}${createQueryString({ childId })}`, {
		email,
	});
}

export function childEmailVerification(childId, code) {
	return postJson(`${PROFILE_CHILD_EMAIL_VERIFICATION}${createQueryString({ childId })}`, {
		code,
	});
}

export function childEmailVerificationCancel(childId) {
	return postJson(`${PROFILE_CHILD_EMAIL_VERIFICATION_CANCEL}${createQueryString({ childId })}`, {});
}
