/* global document window */

import isScreenWidthUp from './isScreenWidthUp';
import useWindowSize from './useWindowSize';

const HEADER_OFFSET = 80;

export default function useScrollToTop(elementId) {
	const windowSize = useWindowSize();

	return () => {
		const element = document.getElementById(elementId);
		if (element === null) {
			return;
		}

		if (isScreenWidthUp(windowSize.width, 'md')) {
			element.scrollIntoView({
				behavior: 'smooth',
			});
		} else {
			const elementPosition = element.getBoundingClientRect();
			window.scrollTo({
				top: elementPosition.top + window.scrollY - HEADER_OFFSET,
				left: elementPosition.left,
				behavior: 'smooth',
			});
		}
	};
}
