import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { useCountry } from '../../../contexts/LocaleContext';
import { onboardingAddBankAccount } from '../../../utils/api';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import useRequestAuth from '../../../utils/useRequestAuth';
import { validateBankAccount } from '../../../utils/validators';
import { BankAccountField } from '../../forms';
import {
	Alert,
	Button,
	Modal,
} from '../../ui';

import styles from './OnboardingBankAccount.module.scss';

export default function OnboardingBankAccount({
	childId,
	reloadUser,
}) {
	const addBankAccountAuth = useRequestAuth(onboardingAddBankAccount);
	const [addError, setAddError] = useState(false);
	const [addAccount, setAddAccount] = useState(false);
	const [t] = useTranslation();
	const trackGTMEvent = useTrackGTMEvent();
	const country = useCountry();

	useEffect(() => {
		setAddError(false);
	}, [addAccount]);

	return (
		<div className={styles.root}>
			<Button
				label={t('account.bankAccountsControl.addAccount.button')}
				outline
				isFlat
				onClick={() => {
					trackGTMEvent('interactions', {
						eventCategory: 'interactions',
						eventAction: 'buttonClick',
						eventLabel: 'pridat_bankovni_ucet',
					});
					setAddAccount(true);
				}}
			/>
			<Formik
				initialValues={{
					bankAccount: '',
					bankAccountIBAN: '',
					bankAccountType: 'local',
					bankCode: '',
					bankPrefix: '',
				}}
				validate={(values) => validateBankAccount(values, country)}
				onSubmit={async (values, { resetForm, setErrors }) => {
					const isTypeIBAN = values.bankAccountType === 'iban';
					const bankPrefix = !isTypeIBAN && country !== 'PL' && values.bankPrefix.length > 0 ? values.bankPrefix : null;
					const bankAccount = !isTypeIBAN ? values.bankAccount.replace(/\s+/g, '') : null;
					const bankCode = !isTypeIBAN && country !== 'PL' ? values.bankCode : null;
					const bankAccountIBAN = isTypeIBAN ? values.bankAccountIBAN.replace(/\s+/g, '') : null;
					setAddError(false);

					try {
						await addBankAccountAuth(
							childId,
							bankPrefix,
							bankAccount,
							bankCode,
							bankAccountIBAN,
						);
					} catch (e) {
						const fieldErrors = {};
						const errorMessage = e.responseJson?.message;
						if (typeof errorMessage === 'string') {
							if (errorMessage.indexOf('Bank code') !== -1) {
								if (isTypeIBAN) {
									fieldErrors.bankAccountIBAN = 'forms.fields.bankAccount.invalidCode';
								} else {
									fieldErrors.bankCode = 'forms.fields.bankAccount.invalidCode';
								}
							}
						}

						const hasFieldErrors = Object.keys(fieldErrors).length > 0;
						if (hasFieldErrors) {
							setErrors(fieldErrors);
						}
						setAddError(!hasFieldErrors);
						return;
					}
					setAddAccount(false);
					resetForm();
					reloadUser();
				}}
			>
				{({
					errors,
					handleBlur,
					handleReset,
					handleSubmit,
					isSubmitting,
					setFieldValue,
					touched,
					values,
				}) => (
					<Modal
						isVisible={addAccount}
						onClose={() => {
							setAddAccount(false);
							handleReset();
						}}
						size="large"
						title={t('account.bankAccountsControl.addAccount.title')}
					>
						<form onSubmit={handleSubmit}>
							{addError && (
								<Alert type="danger">
									{t('forms.error')}
								</Alert>
							)}
							<p className={styles.modalText}>
								{t(`account.bankAccountsControl.addAccount.text.${0}`)}
							</p>
							<div className={styles.modalAccount}>
								<BankAccountField
									country={country}
									errorBankAccount={
										(
											errors.bankPrefix
											&& touched.bankPrefix
											&& t(errors.bankPrefix)
										) || (
											errors.bankAccount
											&& touched.bankAccount
											&& t(errors.bankAccount)
										) || (
											errors.bankCode
											&& touched.bankCode
											&& t(errors.bankCode)
										)
									}
									errorBankAccountIBAN={
										errors.bankAccountIBAN
										&& touched.bankAccountIBAN
										&& t(errors.bankAccountIBAN)
									}
									idAccount="bankAccount"
									idAccountIBAN="bankAccountIBAN"
									idAccountType="bankAccountType"
									idCode="bankCode"
									idPrefix="bankPrefix"
									label={t('forms.fields.bankAccount.label')}
									nameAccount="bankAccount"
									nameAccountIBAN="bankAccountIBAN"
									nameAccountType="bankAccountType"
									nameCode="bankCode"
									namePrefix="bankPrefix"
									onBlur={handleBlur}
									onChange={setFieldValue}
									required
									valueAccount={values.bankAccount}
									valueAccountIBAN={values.bankAccountIBAN}
									valueAccountType={values.bankAccountType}
									valueCode={values.bankCode}
									valuePrefix={values.bankPrefix}
									warning={
										touched.bankAccountIBAN
										&& !errors.bankAccountIBAN
										&& values.bankAccountType === 'iban'
										&& values.bankAccountIBAN
										&& values.bankAccountIBAN.length !== (country === 'PL' ? 28 : 24)
											? t('forms.fields.bankAccountIBAN.warning')
											: ''
									}
								/>
							</div>
							<div className={styles.modalControl}>
								<div className={styles.modalControlItem}>
									<Button
										label={t('account.bankAccountsControl.addAccount.submit')}
										disabled={isSubmitting}
										isSubmit
									/>
								</div>
								<div className={styles.modalControlItem}>
									<Button
										label={t('account.bankAccountsControl.addAccount.cancel')}
										disabled={isSubmitting}
										outline
										onClick={() => {
											setAddAccount(false);
											handleReset();
										}}
									/>
								</div>
							</div>
						</form>
					</Modal>
				)}
			</Formik>
		</div>
	);
}

OnboardingBankAccount.propTypes = {
	childId: PropTypes.number,
	reloadUser: PropTypes.func.isRequired,
};

OnboardingBankAccount.defaultProps = {
	childId: null,
};
