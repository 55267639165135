import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { filterChild, useUserContext } from '../../../contexts/UserContext';
import { fetchChildrenList, fetchProfilePortfolio, fetchUserCurrency } from '../../../utils/api';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import isScreenWidthUp from '../../../utils/isScreenWidthUp';
import { setSessionPortfolioId, useSessionPortfolio } from '../../../utils/sessionPortfolio';
import useFetchAuth from '../../../utils/useFetchAuth';
import { useFetchWithHardReload } from '../../../utils/useFetchWithReload';
import useWindowSize from '../../../utils/useWindowSize';
import { Tooltip } from '../../ui';
import PortfolioCreate from '../PortfolioCreate';

import styles from './PortfolioSelect.module.scss';

const useFetchChildrenList = useFetchAuth(fetchChildrenList);
const useFetchProfilePortfolio = useFetchWithHardReload((language) => fetchProfilePortfolio(language));
const useFetchUserCurrency = useFetchAuth(fetchUserCurrency);

export default function PortfolioSelect({
	accountUrl,
	childOnboardingUrl,
	conditionsUrl,
	isOpen,
	label,
	onBackClick,
	onNavLinkClick,
	onSubNavClick,
	pageUrl,
	parentOnboardingUrl,
	signNewAgreementUrl,
	subNavOpen,
}) {
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const trackGTMEvent = useTrackGTMEvent();
	const windowSize = useWindowSize();
	const [portfolios, loading, error, , reloadPortfolios] = useFetchProfilePortfolio(null, language);
	const [currencies] = useFetchUserCurrency(null, language);
	const [portfolioId] = useSessionPortfolio(!loading && !error ? portfolios : null);
	const [user] = useUserContext();
	const clickToSubNav = !isScreenWidthUp(windowSize.width, 'md');
	const [childrenList] = useFetchChildrenList(null, user.child);

	if (loading || error) {
		return null;
	}

	const { active = [] } = portfolios ?? {};
	const [owners, portfoliosByOwner] = active.reduce((acc, portfolio) => {
		const [ownerAcc, portfolioAcc] = acc;
		const key = filterChild(portfolio.child, user) ? portfolio.child.id : 'own';

		if (!portfolioAcc[key]) {
			ownerAcc.push({ key, child: portfolio.child });
			portfolioAcc[key] = [];
		}
		portfolioAcc[key].push(portfolio);

		return [ownerAcc, portfolioAcc];
	}, [[{ key: 'own', child: null }], { own: [] }]);

	const ownerPortfolios = (owner) => portfoliosByOwner[owner.key].map((portfolio) => (
		<li key={portfolio.id} className={styles.item}>
			<Link
				className={styles.name}
				label={portfolio.name}
				onClick={(e) => {
					onNavLinkClick(e);
					if (portfolio.id !== portfolioId) {
						trackGTMEvent('interactions', {
							eventCategory: 'interactions',
							eventAction: 'buttonClick',
							eventLabel: 'show_another_portfolio',
						});
					}
					setSessionPortfolioId(portfolio.id);
				}}
				to={pageUrl}
			>
				<div className={styles.nameInner}>
					{portfolio.categoryName === 'pension' && (country === 'CZ' ? (
						<svg width="28" height="28" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M26 24.825C23.1882 26.8635 19.298 28.125 15 28.125C10.702 28.125 6.81177 26.8635 4 24.825" stroke="#F29C39" strokeWidth="2" strokeLinecap="round" />
							<path d="M6.75 27.475L10.05 20.325" stroke="#F29C39" strokeWidth="2" />
							<path d="M22.15 27.475L18.85 20.325" stroke="#F29C39" strokeWidth="2" />
							<path d="M18.6133 20.325H7.43875C7.37049 20.325 7.32229 20.2581 7.34388 20.1934L7.94415 18.3925C8.21638 17.5759 8.98066 17.025 9.84152 17.025H16.9887C17.785 17.025 18.5054 16.5527 18.8228 15.8225L22.9587 6.31003C23.4159 5.25851 24.6617 4.80585 25.6873 5.31863L25.8501 5.40003C25.9368 5.4434 25.9799 5.54275 25.9524 5.63573L22.4485 17.4614C21.9452 19.1598 20.3848 20.325 18.6133 20.325Z" fill="#FAD7B0" stroke="#FAD7B0" strokeWidth="2" strokeLinecap="round" />
							<path d="M19.4 13.725C14.5952 13.725 11.5742 13.0905 9.93703 12.7853C9.2507 12.6573 8.63084 13.0661 8.4 13.725V13.725" stroke="#F29C39" strokeWidth="2" strokeLinecap="round" />
							<path d="M11.15 17.025V13.175" stroke="#F29C39" strokeWidth="2" strokeLinecap="round" />
							<rect x="8.25" y="19.25" width="23.5" height="12.5" rx="6.25" fill="#F39200" />
							<rect x="8.25" y="19.25" width="23.5" height="12.5" rx="6.25" stroke="#47202A" strokeWidth="0.5" />
							<path d="M14.2006 28.1292H15.7681C16.3017 28.1292 16.5686 27.855 16.5686 27.3065V23.6935C16.5686 23.1451 16.3017 22.8708 15.7681 22.8708H14.2006C14.1191 22.8708 14.0783 22.9079 14.0783 22.982V28.0069C14.0783 28.0884 14.1191 28.1292 14.2006 28.1292ZM13 28.8851V22.1149C13 21.9889 13.063 21.9259 13.189 21.9259H15.9238C16.4648 21.9259 16.8872 22.0778 17.1911 22.3817C17.5024 22.6782 17.658 23.1006 17.658 23.649V27.351C17.658 27.892 17.5024 28.3145 17.1911 28.6183C16.8872 28.9222 16.4648 29.0741 15.9238 29.0741H13.189C13.063 29.0741 13 29.0111 13 28.8851Z" fill="white" />
							<path d="M20.5892 22.1149V28.8851C20.5892 29.0111 20.5262 29.0741 20.4002 29.0741H19.6998C19.5738 29.0741 19.5108 29.0111 19.5108 28.8851V22.1149C19.5108 21.9889 19.5738 21.9259 19.6998 21.9259H20.4002C20.5262 21.9259 20.5892 21.9889 20.5892 22.1149Z" fill="white" />
							<path d="M23.3314 29.0741H22.631C22.505 29.0741 22.442 29.0111 22.442 28.8851V22.1149C22.442 21.9889 22.505 21.9259 22.631 21.9259H25.2769C25.8179 21.9259 26.2403 22.0778 26.5442 22.3817C26.8481 22.6782 27 23.1006 27 23.649V24.8052C27 25.3462 26.8481 25.7687 26.5442 26.0725C26.2403 26.3764 25.8179 26.5283 25.2769 26.5283H23.6538C23.5648 26.5283 23.5204 26.5654 23.5204 26.6395V28.8851C23.5204 29.0111 23.4574 29.0741 23.3314 29.0741ZM23.6427 25.5723H25.1212C25.6474 25.5723 25.9105 25.2981 25.9105 24.7496V23.6935C25.9105 23.1451 25.6474 22.8708 25.1212 22.8708H23.6427C23.5611 22.8708 23.5204 22.9079 23.5204 22.982V25.4611C23.5204 25.5352 23.5611 25.5723 23.6427 25.5723Z" fill="white" />
						</svg>
					) : (
						<svg width="25" height="25" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M26 24.825C23.1882 26.8635 19.298 28.125 15 28.125C10.702 28.125 6.81177 26.8635 4 24.825" stroke="#F29C39" strokeWidth="2" strokeLinecap="round" />
							<path d="M6.75 27.475L10.05 20.325" stroke="#F29C39" strokeWidth="2" />
							<path d="M22.15 27.475L18.85 20.325" stroke="#F29C39" strokeWidth="2" />
							<path d="M18.6133 20.325H7.43879C7.37054 20.325 7.32234 20.2581 7.34392 20.1934L7.9442 18.3925C8.21643 17.5759 8.98071 17.025 9.84157 17.025H16.9888C17.785 17.025 18.5054 16.5527 18.8229 15.8225L22.9587 6.31003C23.4159 5.25851 24.6617 4.80585 25.6873 5.31863L25.8501 5.40003C25.9368 5.4434 25.98 5.54275 25.9524 5.63573L22.4485 17.4614C21.9453 19.1598 20.3848 20.325 18.6133 20.325Z" fill="#FAD7B0" stroke="#FAD7B0" strokeWidth="2" strokeLinecap="round" />
							<path d="M19.4 13.725C14.5952 13.725 11.5742 13.0905 9.93705 12.7853C9.25073 12.6573 8.63086 13.0661 8.40002 13.725V13.725" stroke="#F29C39" strokeWidth="2" strokeLinecap="round" />
							<path d="M11.15 17.025V13.175" stroke="#F29C39" strokeWidth="2" strokeLinecap="round" />
						</svg>
					))}
					{owner.child !== null && (
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={25} height={25} viewBox="0 0 25 25">
							<path fill="#F29C39" d="M12.73 9.19a3.9 3.9 0 0 0-2.57-6.85A3.9 3.9 0 0 0 7.58 9.2a4.25 4.25 0 0 0-2.9 4.02v7.1c0 .87.7 1.57 1.57 1.57h7.81c.87 0 1.56-.7 1.56-1.57v-7.1a4.25 4.25 0 0 0-2.89-4.02Z" opacity=".4" />
							<path fill="#F29C39" d="M17.58 13.57a2.73 2.73 0 1 0-3.13 0 3.47 3.47 0 0 0-2.73 3.39v3.35c0 .87.7 1.57 1.56 1.57h5.47c.86 0 1.56-.7 1.56-1.57v-3.35a3.47 3.47 0 0 0-2.73-3.4Z" />
						</svg>
					)}
					{portfolio.name}
				</div>
			</Link>
		</li>
	));

	const inactiveChildren = (childrenList ?? []).filter((child) => !child.activeClient && !child.accountClosure).map(
		(child) => (
			<Tooltip
				key={child.id}
				text={t('portfolio.select.disabledTooltip')}
			>
				<div className={styles.list}>
					<div className={styles.disabled}>
						<Link className={styles.link} to={`${childOnboardingUrl}?childId=${child.id}`}>
							<p className={styles.name}>
								<span className={styles.nameInner}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={25} height={25} viewBox="0 0 25 25">
										<path fill="#F29C39" d="M12.73 9.19a3.9 3.9 0 0 0-2.57-6.85A3.9 3.9 0 0 0 7.58 9.2a4.25 4.25 0 0 0-2.9 4.02v7.1c0 .87.7 1.57 1.57 1.57h7.81c.87 0 1.56-.7 1.56-1.57v-7.1a4.25 4.25 0 0 0-2.89-4.02Z" opacity=".4" />
										<path fill="#F29C39" d="M17.58 13.57a2.73 2.73 0 1 0-3.13 0 3.47 3.47 0 0 0-2.73 3.39v3.35c0 .87.7 1.57 1.56 1.57h5.47c.86 0 1.56-.7 1.56-1.57v-3.35a3.47 3.47 0 0 0-2.73-3.4Z" />
									</svg>
									<span className={styles.linkWrap}>
										{child.firstName}
										<span className={styles.linkText}>
											{t('account.childList.finishOnboarding')}
										</span>
									</span>
								</span>
							</p>
						</Link>
					</div>
				</div>
			</Tooltip>
		),
	);

	return (
		<div className={`${styles.root} ${isOpen ? styles.isOpen : ''}`.trim()}>
			<button
				className={styles.subNavClose}
				onClick={(e) => onBackClick(e, label)}
				type="button"
			>
				{t('portfolio.select.back')}
			</button>
			<div className={styles.wrap}>
				{owners.map((owner) => portfoliosByOwner[owner.key]?.length > 0 && (owners.length > 1 ? (
					<button
						key={owner.key}
						className={`${styles.list} ${owner.key === 'own' ? styles.listOwn : ''}`.trim()}
						onClick={clickToSubNav ? (e) => onSubNavClick(e, label, owner.key) : null}
						type="button"
					>
						<p className={styles.name}>
							<span className={styles.nameInner}>
								{owner.child !== null && (
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={25} height={25} viewBox="0 0 25 25">
										<path fill="#F29C39" d="M12.73 9.19a3.9 3.9 0 0 0-2.57-6.85A3.9 3.9 0 0 0 7.58 9.2a4.25 4.25 0 0 0-2.9 4.02v7.1c0 .87.7 1.57 1.57 1.57h7.81c.87 0 1.56-.7 1.56-1.57v-7.1a4.25 4.25 0 0 0-2.89-4.02Z" opacity=".4" />
										<path fill="#F29C39" d="M17.58 13.57a2.73 2.73 0 1 0-3.13 0 3.47 3.47 0 0 0-2.73 3.39v3.35c0 .87.7 1.57 1.56 1.57h5.47c.86 0 1.56-.7 1.56-1.57v-3.35a3.47 3.47 0 0 0-2.73-3.4Z" />
									</svg>
								)}
								{owner.child !== null ? owner.child.firstName : t('portfolio.select.title')}
							</span>
							<span className={styles.icon}>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={8} height={11} viewBox="0 0 8 11">
									<path stroke="#F29C39" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1.75 10 4.5-4.5L1.75 1" />
								</svg>
							</span>
						</p>
						<ul className={`${styles.inner} ${owner.key === subNavOpen ? styles.innerOpen : ''}`.trim()}>
							{ownerPortfolios(owner)}
						</ul>
					</button>
				) : (
					<ul key={owner.key} className={styles.listOnly}>
						{ownerPortfolios(owner)}
					</ul>
				)))}
				{inactiveChildren}
				{!user.child && (
					<div className={styles.buttonWrap}>
						<PortfolioCreate
							accountUrl={accountUrl}
							childOnboardingUrl={childOnboardingUrl}
							conditionsUrl={conditionsUrl}
							currencies={currencies}
							label={label}
							parentOnboardingUrl={parentOnboardingUrl}
							portfolios={portfolios}
							reloadPortfolios={reloadPortfolios}
							signNewAgreementUrl={signNewAgreementUrl}
							header
						/>
					</div>
				)}
			</div>
		</div>
	);
}

PortfolioSelect.propTypes = {
	accountUrl: PropTypes.string.isRequired,
	childOnboardingUrl: PropTypes.string.isRequired,
	conditionsUrl: PropTypes.string.isRequired,
	isOpen: PropTypes.bool,
	label: PropTypes.string.isRequired,
	onBackClick: PropTypes.func.isRequired,
	onNavLinkClick: PropTypes.func.isRequired,
	onSubNavClick: PropTypes.func.isRequired,
	pageUrl: PropTypes.string.isRequired,
	parentOnboardingUrl: PropTypes.string.isRequired,
	signNewAgreementUrl: PropTypes.string.isRequired,
	subNavOpen: PropTypes.string,
};

PortfolioSelect.defaultProps = {
	isOpen: false,
	subNavOpen: null,
};
