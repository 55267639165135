import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './ButtonLink.module.scss';

export default function ButtonLink({
	disabled,
	download,
	external,
	icon,
	isFlat,
	isNarrow,
	isSmall,
	label,
	onClick,
	outline,
	outlineHeavy,
	secondary,
	state,
	to,
}) {
	const routerLink = !download && !external && to[0] !== '#';
	const Component = routerLink ? Link : 'a';

	return (
		<Component
			className={`${styles.root} ${isNarrow ? styles.narrow : ''} ${secondary ? styles.secondary : ''} ${outline ? styles.outline : ''} ${outlineHeavy ? styles.outlineHeavy : ''} ${isFlat ? styles.flat : ''} ${isSmall ? styles.small : ''} ${disabled ? styles.disabled : ''}`.trim()}
			download={download}
			href={routerLink ? undefined : to}
			onClick={onClick}
			state={routerLink ? state : undefined}
			to={routerLink ? to : undefined}
		>
			{icon}
			{label}
		</Component>
	);
}

ButtonLink.propTypes = {
	disabled: PropTypes.bool,
	download: PropTypes.bool,
	external: PropTypes.bool,
	icon: PropTypes.node,
	isFlat: PropTypes.bool,
	isNarrow: PropTypes.bool,
	isSmall: PropTypes.bool,
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	outline: PropTypes.bool,
	outlineHeavy: PropTypes.bool,
	secondary: PropTypes.bool,
	state: PropTypes.oneOfType([PropTypes.any]),
	to: PropTypes.string.isRequired,
};

ButtonLink.defaultProps = {
	disabled: false,
	download: false,
	external: false,
	icon: undefined,
	isFlat: false,
	isNarrow: false,
	isSmall: false,
	onClick: null,
	outline: false,
	outlineHeavy: false,
	secondary: false,
	state: undefined,
};
