/* global document */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { OnboardingFinal } from '../../components/common';
import {
	Container,
	Section,
} from '../../components/layout';
import { fetchProfileRegistrationProcess } from '../../utils/api';
import { toNumber } from '../../utils/number';
import useFetchAuth from '../../utils/useFetchAuth';
import OnboardingSteps from './OnboardingSteps';

import styles from './OnboardingChild.module.scss';

const useFetchProfileRegistrationProcess = useFetchAuth(
	(childId) => (childId !== null ? fetchProfileRegistrationProcess(childId) : Promise.resolve(null)),
);

export default function OnboardingChild({
	blogPostEsgRegulationUrl,
	conditionsUrl,
	dashboardUrl,
	documentsUrl,
	faqUrl,
	personalInfoAgreementUrl,
}) {
	const [t] = useTranslation();
	const navigate = useNavigate();
	const [checkOnboarding, setCheckOnboarding] = useState(false);
	const [searchParams] = useSearchParams();
	const childIdParam = searchParams.get('childId') ?? null;
	const childId = childIdParam !== null ? toNumber(childIdParam) || null : null;
	const [registrationProcess, loading, error] = useFetchProfileRegistrationProcess(null, childId, checkOnboarding);
	const finalStep = !loading && registrationProcess?.step > 6;

	useEffect(() => {
		if (!loading && registrationProcess?.completeRegistration) {
			navigate(dashboardUrl);
			return;
		}

		if (finalStep) {
			document.getElementById('final')?.scrollIntoView({
				behavior: 'smooth',
			});
		}
		setCheckOnboarding(false);
	}, [registrationProcess, loading, finalStep, navigate]);

	if (loading || error) return null;

	return (
		<Section>
			<Container>
				{!finalStep ? (
					<>
						<h1 className={styles.title}>
							{t('onboarding.title')}
						</h1>
						<p className={styles.text}>
							{t('onboarding.text')}
						</p>
						<p className={styles.textSmaller}>
							{t('onboarding.info')}
						</p>
						<OnboardingSteps
							blogPostEsgRegulationUrl={blogPostEsgRegulationUrl}
							childId={childId}
							conditionsUrl={conditionsUrl}
							dashboardUrl={dashboardUrl}
							faqUrl={faqUrl}
							personalInfoAgreementUrl={personalInfoAgreementUrl}
							process={registrationProcess}
							setCheckOnboarding={setCheckOnboarding}
						/>
					</>
				) : (
					<div id="final">
						<h1 className={styles.title}>
							{t('onboarding.final.title')}
							<br />
							{t('onboarding.title')}
						</h1>
						<OnboardingFinal
							childId={childId}
							documentsUrl={documentsUrl}
							portfolioId={registrationProcess?.portfolioId}
						/>
					</div>
				)}
			</Container>
		</Section>
	);
}

OnboardingChild.propTypes = {
	blogPostEsgRegulationUrl: PropTypes.string,
	conditionsUrl: PropTypes.string.isRequired,
	dashboardUrl: PropTypes.string.isRequired,
	documentsUrl: PropTypes.string.isRequired,
	faqUrl: PropTypes.string.isRequired,
	personalInfoAgreementUrl: PropTypes.string.isRequired,
};

OnboardingChild.defaultProps = {
	blogPostEsgRegulationUrl: null,
};
