import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { fetchOnboardingFeedback, feedback } from '../../../utils/api';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import useFetchAuth from '../../../utils/useFetchAuth';
import useRequestAuth from '../../../utils/useRequestAuth';
import { TextareaField } from '../../forms';
import { Button } from '../../ui';

import styles from './OnboardingFeedback.module.scss';

const useFetchOnboardingFeedback = useFetchAuth(fetchOnboardingFeedback);

export default function OnboardingFeedback({ childId }) {
	const [onboardingFeedback, loading, error] = useFetchOnboardingFeedback({}, childId);
	const feedbackAuth = useRequestAuth(feedback);
	const [success, setSuccess] = useState(false);
	const [t] = useTranslation();
	const trackGTMEvent = useTrackGTMEvent();

	useEffect(() => {
		setSuccess(onboardingFeedback.feedback?.exists);
	}, [onboardingFeedback]);

	if (loading || error) return null;

	return (
		<div>
			{success ? (
				<div className={styles.done}>
					{t('onboarding.final.feedback.done')}
				</div>
			) : (
				<Formik
					initialValues={{
						status: 0,
						message: '',
					}}
					onSubmit={async (values) => {
						try {
							await feedbackAuth(
								childId,
								values.status,
								values.message,
							);
						} catch {
							return;
						}
						setSuccess(true);
					}}
				>
					{({
						handleChange, handleBlur, handleSubmit, isSubmitting, values,
					}) => (
						<form onSubmit={handleSubmit}>
							<p className={styles.text}>{t('onboarding.final.feedback.form.intro')}</p>
							<div className={styles.statusList}>
								{[...Array(10).keys()].map((i) => (
									<label key={i + 1} htmlFor={`status${i + 1}`} className={styles.statusItem}>
										<input
											checked={values.status === String(i + 1)}
											className={styles.statusInput}
											id={`status${i + 1}`}
											name="status"
											onChange={(e) => {
												trackGTMEvent('interactions', {
													eventCategory: 'interactions',
													eventAction: 'buttonClick',
													eventLabel: 'hodnoceni_registrace_skala',
													eventValue: i + 1,
												});
												handleChange(e);
											}}
											type="radio"
											value={String(i + 1)}
											required
										/>
										<span className={styles.statusNumber}>{i + 1}</span>
									</label>
								))}
							</div>
							<div className={styles.statusInfo}>
								<div>{t('onboarding.final.feedback.form.statusBad')}</div>
								<div>{t('onboarding.final.feedback.form.statusGood')}</div>
							</div>
							<TextareaField
								onBlur={handleBlur}
								onChange={(e) => {
									trackGTMEvent('interactions', {
										eventCategory: 'interactions',
										eventAction: 'buttonClick',
										eventLabel: 'hodnoceni_registrace_text',
										eventValue: '',
									});
									handleChange(e);
								}}
								id="message"
								name="message"
								label={t('onboarding.final.feedback.form.message')}
								value={values.message}
							/>
							<Button
								label={t('onboarding.final.feedback.form.submit')}
								disabled={isSubmitting}
								isSubmit
								outline
								isNarrow
							/>
						</form>
					)}
				</Formik>
			)}
		</div>
	);
}

OnboardingFeedback.propTypes = {
	childId: PropTypes.number,
};

OnboardingFeedback.defaultProps = {
	childId: null,
};
