import React from 'react';
import PropTypes from 'prop-types';

import styles from './Badge.module.scss';

export default function Badge({
	label,
	size,
	variant,
}) {
	return (
		<span className={(`${styles.root} ${styles[size]} ${styles[variant]}`).trim()}>
			{label}
		</span>
	);
}

Badge.propTypes = {
	label: PropTypes.string.isRequired,
	size: PropTypes.oneOf(['tiny', 'smaller', 'small', 'medium']),
	variant: PropTypes.oneOf(['white', 'orange', 'wine']),
};

Badge.defaultProps = {
	size: 'medium',
	variant: 'white',
};
