import React, {
	useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { useLanguage } from '../../../contexts/LocaleContext';
import { fetchProfileSurvey, saveSurveyAnswer, saveSurveyLog } from '../../../utils/api';
import useFetchAuth from '../../../utils/useFetchAuth';
import useRequestAuth from '../../../utils/useRequestAuth';
import { validateSurvey } from '../../../utils/validators';
import { Alert, Button } from '../../ui';

import styles from './Survey.module.scss';

const useFetchProfileSurvey = useFetchAuth(fetchProfileSurvey);

const choiceNumbers = Array.from(Array(10).keys(), (n) => n + 1);

const choicesInclude = (values, value) => (Array.isArray(values) ? values.includes(value) : values === value);

export default function Survey() {
	const language = useLanguage();
	const [surveys, loading] = useFetchProfileSurvey([], language);
	const saveSurveyAnswerAuth = useRequestAuth(saveSurveyAnswer);
	const saveSurveyLogAuth = useRequestAuth(saveSurveyLog);
	const otherInputRef = useRef(null);
	const [active, setActive] = useState(null);
	const [visible, setVisible] = useState(false);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [t] = useTranslation();

	const initialValues = useMemo(() => {
		const type = !loading && surveys.length > 0 && active !== null ? surveys[active]?.type : null;

		let choices = null;
		if (type === 'multipleChoice') {
			choices = [];
		} else if (type === 'input') {
			choices = '0';
		}

		return {
			choices,
			text: '',
		};
	}, [loading, surveys, active]);

	useEffect(async () => {
		if (visible) {
			try {
				await saveSurveyLogAuth(surveys[active].id, 'firstShow');
			} catch {
				// do nothing
			}
		}
	}, [visible]);

	useEffect(() => {
		if (surveys && surveys.length > 0) {
			setActive(0);

			setTimeout(() => {
				setVisible(true);
			}, 1500);
		}
	}, [loading]);

	const getNextSurvey = () => {
		setVisible(false);

		setTimeout(() => {
			setError(false);
			setSuccess(false);
			setActive(active + 1 < surveys.length ? active + 1 : null);
			setVisible(true);
		}, 1500);
	};

	const handleClose = async () => {
		try {
			await saveSurveyLogAuth(surveys[active].id, 'closed');
		} catch {
			// do nothing
		}
		getNextSurvey();
	};

	if (loading || surveys.length === 0 || active === null) return null;

	return (
		<div className={`${styles.root} ${visible ? styles.rootVisible : ''}`.trim()}>
			{success ? (
				<Alert type="success">
					{t('survey.thanks')}
				</Alert>
			) : (
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validate={validateSurvey}
					onSubmit={async (values, { resetForm }) => {
						setError(false);
						setSuccess(false);
						try {
							await saveSurveyAnswerAuth(
								surveys[active].id,
								Array.isArray(values.choices)
									? values.choices.filter((val) => val !== '0')
									: values.choices,
								values.text,
							);
						} catch {
							setError(true);
							return;
						}
						setSuccess(true);
						resetForm();

						setTimeout(getNextSurvey, 2000);
					}}
				>
					{({
						handleChange, handleSubmit, isSubmitting, values, setFieldValue, errors, touched,
					}) => (
						<form onSubmit={handleSubmit}>
							<div className={styles.title}>
								{surveys[active].title}
							</div>
							<div className={styles.question}>
								{surveys[active].question}
							</div>
							{error && (
								<Alert type="danger">
									{t('forms.error')}
								</Alert>
							)}
							<button type="button" className={styles.close} onClick={handleClose}>{t('survey.close')}</button>
							{surveys[active].type === 'input' ? (
								// eslint-disable-next-line jsx-a11y/label-has-associated-control
								<label htmlFor="text" className={styles.checkButton}>
									<input
										type="text"
										onChange={handleChange}
										name="text"
										id="text"
										value={values.text}
									/>
								</label>
							) : (
								<div>
									{choiceNumbers.map((i) => surveys[active][`choice${i}`] && (
										<label key={i} htmlFor={`choice${i}`} className={styles.check}>
											<input
												checked={choicesInclude(values.choices, String(i))}
												id={`choice${i}`}
												name="choices"
												onChange={(e) => {
													if (surveys[active].type === 'singleChoice' && e.currentTarget.checked) {
														setFieldValue('text', '', false);
													}
													handleChange(e);
												}}
												type={surveys[active].type === 'multipleChoice' ? 'checkbox' : 'radio'}
												value={String(i)}
											/>
											<span className={styles.checkButton}>
												{surveys[active][`choice${i}`]}
											</span>
										</label>
									))}
								</div>
							)}
							{surveys[active].type !== 'input' && surveys[active].otherOption && (
								<>
									<label htmlFor="choicesOther" className={styles.check}>
										<input
											checked={choicesInclude(values.choices, '0')}
											id="choicesOther"
											name="choices"
											onChange={(e) => {
												if (e.currentTarget.checked) {
													setTimeout(() => {
														otherInputRef.current?.focus();
													}, 300);
												} else {
													setFieldValue('text', '', false);
												}
												handleChange(e);
											}}
											type={surveys[active].type === 'multipleChoice' ? 'checkbox' : 'radio'}
											value="0"
										/>
										<span className={styles.checkButton}>
											{t('survey.other')}
										</span>
									</label>
									{choicesInclude(values.choices, '0') && (
										<span className={styles.checkButton}>
											<input
												ref={otherInputRef}
												type="text"
												onChange={handleChange}
												name="text"
												id="text"
												placeholder={t('survey.otherPlaceholder')}
												value={values.text}
											/>
										</span>
									)}
								</>
							)}
							{errors.survey && (touched.choices || touched.text) && (
								<div className={styles.validateError}>
									{t('survey.validateError')}
								</div>
							)}
							<div className={styles.submit}>
								<Button
									disabled={isSubmitting || !visible}
									isSubmit
									label={t('survey.submit')}
								/>
							</div>
						</form>
					)}
				</Formik>
			)}
		</div>
	);
}
