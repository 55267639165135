/* global window */
import React, {
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BlogHeader } from '../../../components/common';
import { Container, Section } from '../../../components/layout';
import { Button } from '../../../components/ui';
import BlogPostPreview from '../BlogPostPreview';
import blogPostShape from '../blogPostShape';
import BlogMenu from './BlogMenu';

import styles from './BlogList.module.scss';

const POSTS_PER_PAGE = 4;

function normalizeText(query) {
	return query
		.toLowerCase()
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '');
}

export default function BlogList({
	blogPostUrl,
	handleTagClick,
	posts,
	searchQuery,
	searchTags,
	searchTopRead,
	setSearchQuery,
	setSearchTopRead,
	topics,
	topReadPosts,
}) {
	const [t] = useTranslation();
	const [page, setPage] = useState(1);
	const [firstLoad, setFirstLoad] = useState(true);
	const sectionRef = useRef(null);

	const postsFiltered = useMemo(() => {
		const normalizedQuery = normalizeText(searchQuery);

		return posts.filter((post) => {
			const matchesQuery = !searchQuery
				|| normalizeText(post.contentText).includes(normalizedQuery)
				|| normalizeText(post.title).includes(normalizedQuery);
			const postTags = post.topics?.map((postTag) => postTag.slug) ?? [];
			const matchesTags = searchTags.length === 0 || searchTags.some((tag) => postTags.includes(tag));

			const matchesTopRead = !searchTopRead || post.topRead;

			return matchesQuery && matchesTags && matchesTopRead;
		});
	}, [posts, searchQuery, searchTags, searchTopRead]);
	const postsSliced = postsFiltered.slice(0, POSTS_PER_PAGE * page);

	useEffect(() => {
		const section = sectionRef.current;
		function handleScroll() {
			if (section) {
				const rect = section.getBoundingClientRect();
				const windowHeight = window.innerHeight;
				if (rect.bottom <= windowHeight && !firstLoad) {
					setPage(page + 1);
				}
			}
		}

		if (postsSliced.length < postsFiltered.length && !firstLoad) {
			window.addEventListener('scroll', handleScroll);
		}
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [postsFiltered, postsSliced, page, firstLoad]);

	return (
		<div
			ref={sectionRef}
			className={styles.root}
		>
			<div className={styles.menuMobile}>
				<BlogMenu
					blogPostUrl={blogPostUrl}
					handleTagClick={handleTagClick}
					searchQuery={searchQuery}
					searchTags={searchTags}
					searchTopRead={searchTopRead}
					setSearchQuery={setSearchQuery}
					setSearchTopRead={setSearchTopRead}
					topics={topics}
					topReadPosts={topReadPosts}
				/>
			</div>
			<Section condensed>
				<BlogHeader />
				<Container>
					<div className={styles.wrap}>
						<div className={styles.menu}>
							<BlogMenu
								blogPostUrl={blogPostUrl}
								handleTagClick={handleTagClick}
								searchQuery={searchQuery}
								searchTags={searchTags}
								searchTopRead={searchTopRead}
								setSearchQuery={setSearchQuery}
								setSearchTopRead={setSearchTopRead}
								topics={topics}
								topReadPosts={topReadPosts}
							/>
						</div>
						<div className={styles.content}>
							<div className={styles.list}>
								{postsSliced.map((post, index) => (
									<BlogPostPreview
										key={post.id}
										blogPostUrl={blogPostUrl}
										handleTagClick={handleTagClick}
										largePreview={index === 0}
										post={post}
									/>
								))}
							</div>
							{postsSliced.length >= 1 && postsSliced.length < postsFiltered.length && firstLoad && (
								<div className={styles.buttonWrap}>
									<Button
										label={t('blogPost.list.button')}
										onClick={() => {
											setPage(page + 1);
											setFirstLoad(false);
										}}
										outline
									/>
								</div>
							)}
							{postsSliced.length === 0 && (
								<div className={styles.buttonWrap}>
									<p className={styles.text}>{t('blog.menu.search.empty')}</p>
								</div>
							)}
						</div>
					</div>
					{posts.length <= 1 && (
						<p className={styles.description}>{t('blog.comingSoon')}</p>
					)}
				</Container>
			</Section>
		</div>
	);
}

BlogList.propTypes = {
	blogPostUrl: PropTypes.func.isRequired,
	handleTagClick: PropTypes.func.isRequired,
	posts: PropTypes.arrayOf(blogPostShape.isRequired).isRequired,
	searchQuery: PropTypes.string,
	searchTags: BlogMenu.propTypes.searchTags,
	searchTopRead: PropTypes.bool.isRequired,
	setSearchQuery: PropTypes.func.isRequired,
	setSearchTopRead: PropTypes.func.isRequired,
	topics: BlogMenu.propTypes.topics,
	topReadPosts: BlogMenu.propTypes.topReadPosts,
};

BlogList.defaultProps = {
	searchQuery: '',
	searchTags: null,
	topics: null,
	topReadPosts: null,
};
