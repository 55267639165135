import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import {
	Container,
	Section,
} from '../../layout';
import {
	ButtonLink,
	SectionAnchor,
	ToggleMulti,
} from '../../ui';
import TransparentFee from '../TransparentFee';

import styles from './FeesProducts.module.scss';

export default function FeesProducts({
	comparisonAnchor,
	productsAnchor,
	registrationUrl,
}) {
	const [selected, setSelected] = useState(0);
	const [t] = useTranslation();
	const toggleOptions = t('feesProducts.toggleOptions', { returnObjects: true });
	const selectedKey = Object.keys(toggleOptions)[selected] ?? 0;
	const product = toggleOptions[selectedKey]?.value ?? 'classic';

	return (
		<Section id={productsAnchor} bordered>
			<Container>
				<div className={styles.root}>
					<h1 className={styles.title}>
						{t('feesProducts.title')}
					</h1>
					<ToggleMulti
						options="feesProducts.toggleOptions"
						selected={selected}
						setSelected={setSelected}
						isVertical
					/>
				</div>
				<div className={styles.products}>
					<div className={styles.productWrap}>
						<div className={styles.product}>
							<h3 className={styles.productTitle}>
								{t(`feesProducts.products.${product}.title`)}
							</h3>
							<p className={styles.productText}>
								<Trans i18nKey={`feesProducts.products.${product}.text`}>
									<strong />
								</Trans>
							</p>
							<ButtonLink
								label={t(`feesProducts.products.${product}.button`)}
								to={registrationUrl}
							/>
						</div>
						<TransparentFee product={product} />
					</div>
				</div>
			</Container>
			<SectionAnchor title={t('feesHero.anchor')} target={comparisonAnchor} />
		</Section>
	);
}

FeesProducts.propTypes = {
	comparisonAnchor: PropTypes.string.isRequired,
	productsAnchor: PropTypes.string.isRequired,
	registrationUrl: PropTypes.string.isRequired,
};
