import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import { RadioList, TextField } from '../../../components/forms';
import {
	Col,
	Container,
	Row,
} from '../../../components/layout';
import {
	Alert,
	Button,
	Loader,
	Modal,
} from '../../../components/ui';
import {
	cancelAccountClosure,
	closeAccount,
} from '../../../utils/api';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import useRequestAuth from '../../../utils/useRequestAuth';

import styles from './Closure.module.scss';

export default function Closure({
	childClosure,
	currencies,
	pensionCheck,
	portfolioUrl,
	reloadChildrenList,
	reloadUser,
	userChild,
	userClosure,
	userClosureClosedChange,
	userCurrency,
}) {
	const [t] = useTranslation();
	const trackGTMEvent = useTrackGTMEvent();
	const [confirmCancel, setConfirmCancel] = useState(false);
	const [cancelError, setCancelError] = useState(false);
	const [cancelHas2fa, setCancelHas2fa] = useState(false);

	const closeAccountAuth = useRequestAuth(closeAccount);
	const cancelAccountClosureAuth = useRequestAuth(cancelAccountClosure);

	const loading = currencies === null || userCurrency === null;

	const currencySelect = currencies !== null && currencies.length > 1;
	const currencyDefault = currencies !== null
		? currencies.find(({ code }) => code === userCurrency) ?? currencies[0] ?? null
		: null;
	const currencyDefaultValue = currencyDefault !== null ? currencyDefault.code : null;
	const currencyOptions = currencies !== null ? currencies.map(({ code }) => ({
		label: code,
		value: code,
	})) : null;

	const handleCancelClosure = async () => {
		await cancelAccountClosureAuth();
		reloadChildrenList();
		reloadUser();
	};

	const cancelClosure = (
		<Row>
			<Col xl={6} lg={8}>
				<h2 className={styles.title}>
					{t('account.withdrawals.cancel.activeTitle')}
				</h2>
				<p className={styles.text}>
					{t(userChild ? 'account.withdrawals.cancel.activeTextChild' : 'account.withdrawals.cancel.activeText')}
				</p>
				<Button
					disabled={
						userClosureClosedChange
						|| userChild
					}
					label={t('account.withdrawals.cancel.cancel')}
					onClick={handleCancelClosure}
				/>
			</Col>
		</Row>
	);

	const cancel = (
		<Formik
			initialValues={{
				twoFactorCode: '',
				currency: currencySelect ? currencyDefaultValue : null,
			}}
			onSubmit={async (values, { setErrors }) => {
				const currency = currencySelect ? values.currency : currencyDefaultValue;
				if (currency === null) {
					return;
				}

				let show2fa = false;
				setCancelError(false);
				try {
					await closeAccountAuth(
						cancelHas2fa ? values.twoFactorCode : null,
						currency,
					);
				} catch (e) {
					const fieldErrors = {};
					const errorMessage = e.responseJson?.message;
					if (typeof errorMessage === 'string' && errorMessage.indexOf('Two factor authentication code needed') !== -1) {
						setCancelHas2fa(true);
						show2fa = true;
					}

					if (typeof errorMessage === 'string' && errorMessage.indexOf('Bad two factor authentication code') !== -1) {
						fieldErrors.twoFactorCode = 'forms.twoFactorAuthentication.error';
					}

					const hasFieldErrors = Object.keys(fieldErrors).length > 0;
					if (hasFieldErrors) {
						setErrors(fieldErrors);
					}
					setCancelError(!hasFieldErrors && !show2fa);
					return;
				}
				trackGTMEvent('interactions', {
					eventCategory: 'interactions',
					eventAction: 'buttonClick',
					eventLabel: 'insert_withdrawal_closure_account',
				});
				reloadChildrenList();
				reloadUser();
				setCancelHas2fa(false);
				setConfirmCancel(false);
			}}
		>
			{({
				errors,
				handleBlur,
				handleChange,
				handleSubmit,
				isSubmitting,
				touched,
				values,
			}) => (
				<form onSubmit={handleSubmit}>
					<Row>
						<Col xl={6} lg={8}>
							<h2 className={styles.title}>
								{t('account.withdrawals.cancel.title')}
							</h2>
							<p className={styles.text}>
								{t(userChild ? 'account.withdrawals.cancel.textChild' : 'account.withdrawals.cancel.text')}
							</p>
							{!userChild && (
								<p className={styles.note}>
									<Trans i18nKey="account.withdrawals.cancel.note">
										<Link to={portfolioUrl} />
									</Trans>
								</p>
							)}
							{pensionCheck === false && (
								<Alert
									autoScroll={false}
									type="danger"
								>
									<h4 className={styles.listTitle}>
										{t('account.withdrawals.withdrawals.pensionWarningTitle')}
									</h4>
									<ul className={styles.list}>
										<Trans i18nKey="account.withdrawals.withdrawals.pensionWarning">
											<li className={styles.listItem} />
										</Trans>
									</ul>
								</Alert>
							)}
							{currencySelect && (
								<RadioList
									id="accountClosureCurrency"
									name="currency"
									onChange={handleChange}
									options={currencyOptions}
									value={values.currency}
								/>
							)}
							<Modal
								isVisible={confirmCancel}
								onClose={() => {
									setCancelHas2fa(false);
									setConfirmCancel(false);
								}}
								title={t('account.withdrawals.cancel.popup.title')}
							>
								<div className={styles.modal}>
									<p className={styles.modalText}>
										{t('account.withdrawals.cancel.popup.text')}
									</p>
									{cancelError && (
										<Alert type="danger">
											{t('forms.error')}
										</Alert>
									)}
									{cancelHas2fa && (
										<TextField
											autoFocus={cancelHas2fa}
											autocomplete="one-time-code"
											error={
												errors.twoFactorCode
												&& touched.twoFactorCode
												&& t(errors.twoFactorCode)
											}
											id="accountClosureTwoFactorCode"
											inputMode="numeric"
											label={t('account.withdrawals.cancel.popup.twoFactorAuthentication')}
											name="twoFactorCode"
											onBlur={handleBlur}
											onChange={handleChange}
											pattern="[0-9]*"
											required={cancelHas2fa}
											type="text"
											value={values.twoFactorCode}
										/>
									)}
									<Button
										disabled={isSubmitting}
										isSubmit={confirmCancel}
										label={t('account.withdrawals.cancel.popup.button')}
									/>
								</div>
							</Modal>
							{childClosure && (
								<Alert type="danger">
									{t('account.withdrawals.cancel.childClosure')}
								</Alert>
							)}
							<Button
								disabled={
									childClosure
									|| userChild
									|| userClosureClosedChange
									|| isSubmitting
									|| (currencySelect ? values.currency : currencyDefaultValue) === null
								}
								isSubmit={!confirmCancel}
								label={t('account.withdrawals.cancel.confirm')}
								onClick={(e) => {
									e.preventDefault();
									setConfirmCancel(true);
								}}
							/>
						</Col>
					</Row>
				</form>
			)}
		</Formik>
	);

	return (
		<div id="cancellation">
			<Container>
				<div className={loading ? styles.loader : ''}>
					{loading ? <Loader /> : (
						<>
							{userClosure ? cancelClosure : cancel}
						</>
					)}
				</div>
			</Container>
		</div>
	);
}

Closure.propTypes = {
	childClosure: PropTypes.bool,
	currencies: PropTypes.arrayOf(PropTypes.shape({
		code: PropTypes.string.isRequired,
		codeShort: PropTypes.string.isRequired,
		codeShortBefore: PropTypes.bool.isRequired,
	}).isRequired),
	pensionCheck: PropTypes.bool,
	portfolioUrl: PropTypes.string.isRequired,
	reloadChildrenList: PropTypes.func.isRequired,
	reloadUser: PropTypes.func.isRequired,
	userChild: PropTypes.bool,
	userClosure: PropTypes.bool,
	userClosureClosedChange: PropTypes.bool,
	userCurrency: PropTypes.string,
};

Closure.defaultProps = {
	childClosure: false,
	currencies: null,
	pensionCheck: null,
	userChild: null,
	userClosure: null,
	userClosureClosedChange: null,
	userCurrency: null,
};
