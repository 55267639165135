import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '../../components/forms';
import {
	Col,
	Container,
	Section,
	Row,
} from '../../components/layout';
import { ExternalLink, Loader } from '../../components/ui';
import { useCountry, useLanguage } from '../../contexts/LocaleContext';
import { useUserReload } from '../../contexts/UserContext';
import { fetchOffers, updateAffiliateOfferSeen } from '../../utils/api';
import { useTrackGTMEvent } from '../../utils/GTMProvider';
import useFetch from '../../utils/useFetch';
import useRequestAuth from '../../utils/useRequestAuth';

import styles from './Offers.module.scss';

const useFetchOffers = useFetch(fetchOffers);

export default function Offers() {
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const languageRef = useRef(language);
	const [offers, loading] = useFetchOffers([], country, language);
	const updateAffiliateOfferSeenAuth = useRequestAuth(updateAffiliateOfferSeen);
	const trackGTMEvent = useTrackGTMEvent();
	const reloadUser = useUserReload();
	const reloadUserRef = useRef(reloadUser);

	// language changes before offers, including it in seen request dependencies would send old offers with new language
	useEffect(() => {
		languageRef.current = language;
	}, [language]);

	// reloadUser changes when called, including it in seen request dependencies would cause an infinite loop
	useEffect(() => {
		reloadUserRef.current = reloadUser;
	}, [reloadUser]);

	useEffect(() => {
		if (offers.length > 0) {
			const ids = offers.map((offer) => offer.id);
			updateAffiliateOfferSeenAuth(
				languageRef.current,
				ids,
			).then(() => (reloadUserRef.current)());
		}
	}, [offers, updateAffiliateOfferSeenAuth]);

	if (loading || offers.length === 0) return <Loader fullPage />;

	const handleLinkClick = (e, id, link) => {
		if (id && link && e?.target?.href && e.target.href === link) {
			trackGTMEvent('interactions', {
				eventCategory: 'interactions',
				eventAction: 'linkClick',
				eventLabel: `link_${id}`,
			});
		}
	};

	return (
		<Section>
			<Container center>
				<div className={styles.header}>
					<h1 className={styles.title}>{t('offers.title')}</h1>
					<p className={styles.text}>{t('offers.text')}</p>
				</div>
			</Container>
			<Container>
				<Row>
					{offers.map((offer) => (
						<Col xs={12} lg={6} key={offer.id}>
							<div className={styles.box}>
								<h2 className={styles.boxTitle}>{offer.title}</h2>
								<ExternalLink
									className={styles.boxImage}
									to={offer.link}
									blank
								>
									<img alt={offer.title} src={offer.image} />
								</ExternalLink>
								<div className={styles.boxContent}>
									{offer.text.split('\n').map((line, index) => {
										const lineText = line.replace(':link', offer.link);
										const key = `offer${index}`;
										return (
											// eslint-disable-next-line max-len
											// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
											<p
												key={key}
												onClick={(e) => handleLinkClick(e, offer.id, offer.link)}
												className={`${styles.text} ${styles.boxLine}`}
												dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
													__html: lineText,
												}}
											/>
										);
									})}
									{offer.referenceCode && (
										<div className={styles.boxCode}>
											<TextField
												center
												id={`code${offer.id}`}
												name={`code${offer.id}`}
												label={t('offers.code')}
												isLabelHidden
												readonly
												value={offer.referenceCode}
											/>
										</div>
									)}
								</div>
							</div>
						</Col>
					))}
				</Row>
			</Container>
		</Section>
	);
}
