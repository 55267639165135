const settings = {
	baseUrl: process.env.REACT_APP_API_URL,
	sealed: false,
};

export function getApiBaseUrl() {
	settings.sealed = true;
	return settings.baseUrl;
}

export function setApiBaseUrl(baseUrl) {
	if (settings.sealed) {
		throw new Error('API settings have been already sealed.');
	}

	settings.baseUrl = baseUrl;
}
