import PropTypes from 'prop-types';

import blogTagShape from './blogTagShape';

export default PropTypes.shape({
	contentText: PropTypes.string,
	date: PropTypes.string,
	id: PropTypes.string.isRequired,
	perex: PropTypes.string,
	previewImage: PropTypes.shape({
		alt: PropTypes.string,
		src: PropTypes.string.isRequired,
		src2x: PropTypes.string,
		src2xWebP: PropTypes.string,
		srcBreakpoint: PropTypes.string,
		srcBreakpoint2x: PropTypes.string,
		srcBreakpoint2xWebP: PropTypes.string,
		srcBreakpointWebP: PropTypes.string,
		srcWebP: PropTypes.string,
	}).isRequired,
	slug: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	topics: PropTypes.arrayOf(blogTagShape.isRequired),
	updated: PropTypes.bool,
});
