import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useHref } from 'react-router-dom';

import { ExternalLink } from '../../../components/ui';

import styles from './CampaignVocabulary.module.scss';

export default function CampaignVocabularyItem({
	id,
	description,
	howItWorksUrl,
	name,
	openItem,
	setOpenItem,
}) {
	const [t] = useTranslation();
	const howItWorksUrlHref = useHref(howItWorksUrl);
	const itemContentEl = useRef(null);
	const [itemContentHeight, setItemContentHeight] = useState(null);
	const isOpen = openItem === id;

	useEffect(() => {
		setItemContentHeight(isOpen ? itemContentEl.current.scrollHeight : null);
	}, [isOpen]);

	const handleClick = (e) => {
		e.preventDefault();
		if (isOpen) {
			setOpenItem(null);
		} else {
			setOpenItem(id);
		}
	};

	return (
		<>
			<h4 className={styles.itemTitle}>
				<button
					type="button"
					onClick={handleClick}
					className={styles.collapseTrigger}
				>
					<span
						id={`title${id}`}
					>
						{t(name)}
					</span>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="currentColor"
						viewBox="0 0 16 16"
						className={`${styles.icon} ${isOpen ? styles.iconOpen : ''}`.trim()}
					>
						<path
							fillRule="evenodd"
							d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
						/>
					</svg>
				</button>
			</h4>
			<div
				ref={itemContentEl}
				style={{ maxHeight: itemContentHeight }}
				className={`${styles.collapse} ${isOpen ? styles.isCollapseActive : ''}`.trim()}
				role="tabpanel"
				aria-labelledby={`title${id}`}
			>
				<p className={styles.description}>
					<Trans i18nKey={description}>
						<ExternalLink
							to={howItWorksUrlHref}
							blank
						/>
					</Trans>
				</p>
			</div>
		</>
	);
}

CampaignVocabularyItem.propTypes = {
	id: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	howItWorksUrl: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	openItem: PropTypes.string,
	setOpenItem: PropTypes.func.isRequired,
};

CampaignVocabularyItem.defaultProps = {
	openItem: null,
};
