import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Icon from '../Icon';

import styles from './Accordion.module.scss';

export default function Accordion({
	children,
	defaultOpen,
	disabled,
	disabledMessage,
	icon,
	id,
	small,
	title,
}) {
	const [t] = useTranslation();
	const [open, setOpen] = useState(null);

	const handleClick = (e) => {
		e.preventDefault();
		setOpen((prevOpen) => !(prevOpen ?? defaultOpen));
	};

	return (
		<div
			id={id}
			className={`
				${styles.root}
				${open ?? defaultOpen ? styles.isRootOpen : ''}
				${small ? styles.isSmall : ''}
				${disabled ? styles.isRootDisabled : ''}
			`.trim()}
		>
			<button
				className={styles.collapseTrigger}
				disabled={disabled}
				onClick={handleClick}
				type="button"
			>
				{icon && (
					<div className={styles.icon}>
						<Icon type={icon} width={22} height={20} />
					</div>
				)}
				<h2 className={`${styles.title} ${small ? styles.isSmall : ''}`.trim()}>
					{t(title)}
				</h2>
				{disabled && disabledMessage && (
					<span className={styles.disabledMessage}>
						{t(disabledMessage)}
					</span>
				)}
			</button>
			<div
				className={styles.collapse}
				role="tabpanel"
				aria-labelledby={t(title)}
			>
				{children}
			</div>
		</div>
	);
}

Accordion.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	defaultOpen: PropTypes.bool,
	disabled: PropTypes.bool,
	disabledMessage: PropTypes.string,
	icon: PropTypes.oneOf(['danger']),
	id: PropTypes.string,
	small: PropTypes.bool,
	title: PropTypes.string.isRequired,
};

Accordion.defaultProps = {
	defaultOpen: false,
	disabled: false,
	disabledMessage: null,
	icon: null,
	id: null,
	small: false,
};
