import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { ProgressIcon } from '../../../components/common';
import { useLanguage } from '../../../contexts/LocaleContext';

import styles from './Check.module.scss';

export default function Check({
	active,
	children,
	title,
	lastCheckAt,
	nextCheckAt,
}) {
	const [t] = useTranslation();
	const language = useLanguage();
	const formatDate = (date) => new Date(date).toLocaleDateString(language);

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<div className={styles.icon}>
					<ProgressIcon completed={!active} />
				</div>
				<h2
					className={styles.title}
				>
					{t(title)}
				</h2>
				{!active && (
					<div className={styles.checks}>
						{lastCheckAt && (
							<p className={styles.text}>
								<Trans i18nKey="clientCheck.checkAt">
									<span className={styles.date} />
									{{ date: formatDate(lastCheckAt) }}
								</Trans>
							</p>
						)}
						{nextCheckAt && (
							<p className={styles.text}>
								<Trans i18nKey="clientCheck.checkNext">
									<span className={styles.date} />
									{{ date: formatDate(nextCheckAt) }}
								</Trans>
							</p>
						)}
					</div>
				)}
			</div>
			<div className={styles.wrap}>
				{active && children}
			</div>
		</div>
	);
}

Check.propTypes = {
	active: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	title: PropTypes.string.isRequired,
	nextCheckAt: PropTypes.string,
	lastCheckAt: PropTypes.string,
};

Check.defaultProps = {
	active: false,
	nextCheckAt: null,
	lastCheckAt: null,
};
