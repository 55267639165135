import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
	FooterCta,
	OurApproachAccount,
	OurApproachHero,
	OurApproachTeam,
} from '../../components/common';

export default function OurApproach({
	howItWorksUrl,
	registrationUrl,
}) {
	const [t] = useTranslation();
	const teamSectionAnchor = t('anchors.ourApproachTeam');

	return (
		<>
			<OurApproachHero teamSectionAnchor={teamSectionAnchor} />
			<OurApproachTeam teamSectionAnchor={teamSectionAnchor} />
			<OurApproachAccount howItWorksUrl={howItWorksUrl} />
			<FooterCta registrationUrl={registrationUrl} />
		</>
	);
}

OurApproach.propTypes = {
	howItWorksUrl: PropTypes.string.isRequired,
	registrationUrl: PropTypes.string.isRequired,
};
