import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import {
	Col,
	Container,
	Row,
	Section,
} from '../../layout';
import { ExternalLink } from '../../ui';

import styles from './HowItWorksInvestment.module.scss';

const icons = [
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69 58" width="69" height="58">
		<path fill="#e94549" d="M61.7 57.17h-9.47a1 1 0 0 1-1.01-1V51.8H17.89v4.37a1 1 0 0 1-1.01 1H7.4a1 1 0 0 1-1.01-1v-4.38a6.75 6.75 0 0 1-6.33-6.71V7.46C.07 3.76 3.11.74 6.83.74h55.44a6.75 6.75 0 0 1 6.76 6.72v37.62c0 3.56-2.8 6.49-6.32 6.71v4.38a1 1 0 0 1-1.01 1zm-1.01-2V51.8h-7.45v3.37zm-52.27 0h7.45V51.8H8.42zm53.85-5.37a4.74 4.74 0 0 0 4.74-4.72V7.46c0-2.6-2.12-4.72-4.74-4.72H6.83A4.74 4.74 0 0 0 2.1 7.46v37.62c0 2.6 2.13 4.72 4.74 4.72zm-4.17-3.81H11a4.6 4.6 0 0 1-4.6-4.59V11.14A4.6 4.6 0 0 1 11 6.56h47.1a4.6 4.6 0 0 1 4.6 4.58V41.4A4.6 4.6 0 0 1 58.1 46zM11 8.56a2.59 2.59 0 0 0-2.58 2.58V41.4A2.59 2.59 0 0 0 11 43.98H58.1a2.59 2.59 0 0 0 2.59-2.58V11.14a2.59 2.59 0 0 0-2.59-2.58zm14.7 28.7a1 1 0 0 1-1.02-1v-1.32a8.77 8.77 0 0 1-4.65-2l-1.09 1.08a1.01 1.01 0 0 1-1.42 0 1 1 0 0 1 0-1.42l1.13-1.13a8.65 8.65 0 0 1-1.67-4.18h-1.3c-.56 0-1.01-.45-1.01-1s.45-1.01 1-1.01h1.3a8.65 8.65 0 0 1 1.79-4.35l-.97-.96a1 1 0 0 1 0-1.42 1.01 1.01 0 0 1 1.43 0l.95.94a8.76 8.76 0 0 1 4.51-1.89v-1.29a1 1 0 0 1 1.01-1 1 1 0 0 1 1.01 1v1.3a8.77 8.77 0 0 1 4.67 2.01l1.07-1.07a1.01 1.01 0 0 1 1.43 0 1 1 0 0 1 0 1.42l-1.12 1.12a8.65 8.65 0 0 1 1.66 4.18h1.3c.55 0 1 .45 1 1s-.45 1-1 1h-1.3a8.65 8.65 0 0 1-1.72 4.26l1.07 1.07a1 1 0 0 1 0 1.42 1.01 1.01 0 0 1-1.43 0l-1.04-1.04a8.76 8.76 0 0 1-4.59 1.96v1.32a1 1 0 0 1-1 1zm0-17.7a6.74 6.74 0 0 0-6.76 6.71 6.75 6.75 0 0 0 13.51 0c0-3.7-3.03-6.72-6.76-6.72zm0 10.96a4.26 4.26 0 0 1-4.28-4.25 4.26 4.26 0 0 1 8.54 0 4.27 4.27 0 0 1-4.27 4.25zm0-6.5a2.25 2.25 0 0 0-2.26 2.25 2.25 2.25 0 0 0 2.25 2.24 2.25 2.25 0 0 0 2.26-2.24 2.25 2.25 0 0 0-2.26-2.24zm27.62 6h-9.87a1 1 0 0 1-1-1v-5.49a1 1 0 0 1 1-1h9.87a1 1 0 0 1 1.01 1v5.48a1 1 0 0 1-1 1zM44.46 28h7.85v-3.48h-7.85z" />
	</svg>,
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 60" width="70" height="60">
		<path fill="#e94549" d="M15.19 60A15.28 15.28 0 0 1 0 44.67c0-2.15.44-4.2 1.24-6.05l9.23-24.54.08-.17.06-.27c1-2.8 3.15-4.97 5.78-6.05l.04-.11 1.42-3.79.03-.06c0-.04.02-.08.04-.13A5.27 5.27 0 0 1 22.86 0c2.9 0 5.25 2.38 5.25 5.3v4.6c0 .2-.06.4-.16.57a10.29 10.29 0 0 1 2.43 6.66v2.95h9.24v-2.95c0-2.54.92-4.87 2.43-6.66a1.07 1.07 0 0 1-.16-.58V5.3c0-2.92 2.35-5.3 5.25-5.3a5.27 5.27 0 0 1 4.94 3.5l.04.14.03.06 1.42 3.78.04.12a10.25 10.25 0 0 1 5.78 6.05c.03.08.05.17.05.26.04.05.06.1.09.17l9.23 24.52A15.37 15.37 0 0 1 70 44.67C70 53.13 63.19 60 54.81 60a15.28 15.28 0 0 1-15.19-15.33V33.3h-9.24v11.38c0 8.46-6.82 15.33-15.2 15.33zm5-53.15c2.16 0 4.16.68 5.8 1.83V5.3c0-1.74-1.4-3.16-3.13-3.16a3.14 3.14 0 0 0-2.95 2.09l-.03.08a.94.94 0 0 1-.04.14l-.93 2.48c.42-.05.85-.08 1.28-.08zm-5 22.5c5.55 0 10.42 3.01 13.07 7.52V17.13a8.1 8.1 0 0 0-8.06-8.14 8.09 8.09 0 0 0-7.74 5.85L5.74 32.67a15.03 15.03 0 0 1 9.45-3.33zm0 2.13c-5.34 0-9.95 3.26-11.97 7.9l-.43 1.15a1.1 1.1 0 0 1-.03.06 13.25 13.25 0 0 0-.64 4.08c0 7.27 5.86 13.2 13.07 13.2 7.2 0 13.07-5.93 13.07-13.2s-5.87-13.19-13.07-13.19zm34.9-27.25a3.14 3.14 0 0 0-2.95-2.1c-1.71 0-3.1 1.4-3.14 3.11v3.44a10.06 10.06 0 0 1 7.1-1.75l-.94-2.47-.04-.16a1.1 1.1 0 0 1-.03-.07zm7.3 10.14A8.09 8.09 0 0 0 49.8 9a8.1 8.1 0 0 0-8.06 8.07 1.1 1.1 0 0 1 0 .07v19.74a15.17 15.17 0 0 1 13.07-7.53c3.57 0 6.85 1.25 9.45 3.34l-6.72-17.84a1.08 1.08 0 0 1-.06-.28 1.08 1.08 0 0 1-.09-.19zM54.8 31.48c-7.2 0-13.07 5.92-13.07 13.2 0 7.26 5.87 13.18 13.07 13.18A13.14 13.14 0 0 0 67.24 40.6a1.07 1.07 0 0 1-.02-.05l-.43-1.13a13.09 13.09 0 0 0-11.98-7.93zm-24.43-9.25v8.92h9.24v-8.92z" />
	</svg>,
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 63" width="63" height="63">
		<path fill="#e94549" d="M63 29.1v15.3H60v12.09c0 3.3-2.71 6-6.04 6H6.76a6.02 6.02 0 0 1-6.03-6V15.3c0-3.3 2.7-6 6.03-6H24.7L40.75.44A3.65 3.65 0 0 1 42.51 0c1.32 0 2.53.7 3.17 1.85l4.18 7.47h4.09a6.02 6.02 0 0 1 6.04 6v13.8zM51.2 11.71l1.8 3.2h4.53a3.6 3.6 0 0 0-3.58-3.2zm-31.67 3.2h30.7L43.57 3a1.23 1.23 0 0 0-1.64-.47zm38.04 41.58v-12.1h-8.83a7.42 7.42 0 0 1-7.43-7.38v-.52a7.42 7.42 0 0 1 7.43-7.39h8.83V17.3H7.52a1.2 1.2 0 0 1-1.2-1.2c0-.65.53-1.2 1.2-1.2h7.05l5.79-3.19H6.76c-2 0-3.62 1.62-3.62 3.6v41.18c0 1.98 1.63 3.6 3.62 3.6h47.19c2 0 3.62-1.62 3.62-3.6zM60.6 31.5H48.74a5.01 5.01 0 0 0-5.02 5v.5a5.01 5.01 0 0 0 5.02 5H60.6zm-8.15 5.35a2.66 2.66 0 0 1-2.67 2.65 2.66 2.66 0 0 1-2.66-2.65 2.66 2.66 0 0 1 2.67-2.65 2.66 2.66 0 0 1 2.66 2.65z" />
	</svg>,
];

export default function HowItWorksInvestment({
	ourApproachUrl,
}) {
	const [t] = useTranslation();

	return (
		<Section>
			<Container center>
				<h2 className={styles.title}>
					{t('howItWorksInvestment.title')}
				</h2>
				<Row center>
					{Object.keys(t('howItWorksInvestment.list', { returnObjects: true })).map((item) => (
						<Col key={item} md={4}>
							<div className={styles.icon}>
								{icons[+item] ?? null}
							</div>
							<h3 className={styles.itemTitle}>
								{t(`howItWorksInvestment.list.${item}.title`)}
							</h3>
							<p className={styles.description}>
								<Trans i18nKey={`howItWorksInvestment.list.${item}.description`}>
									<strong />
									{ourApproachUrl ? <Link to={ourApproachUrl} target="_blank" /> : <></>}
									<ExternalLink to="https://www.knf.gov.pl/podmioty/wyszukiwarka_podmiotow#3126856" blank>
										{t(`howItWorksInvestment.list.${item}.description`)}
									</ExternalLink>
								</Trans>
							</p>
						</Col>
					))}
				</Row>
			</Container>
		</Section>
	);
}

HowItWorksInvestment.propTypes = {
	ourApproachUrl: PropTypes.string,
};

HowItWorksInvestment.defaultProps = {
	ourApproachUrl: null,
};
