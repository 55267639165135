import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { RangerDouble } from '../../../../components/forms';
import { fromISODate, toISODate } from '../../../../utils/date';

import styles from './InvestingPortfolioRanger.module.scss';

const getNextQuarter = (date) => {
	const month = date.getMonth();
	return new Date(date.getFullYear(), month - (month % 3) + 3, 1);
};

const createDates = (minDate, maxDate) => {
	const minDateObject = fromISODate(minDate);
	const maxDateObject = fromISODate(maxDate);

	if (minDateObject === null || maxDateObject === null) {
		return [];
	}

	const dates = [];

	for (let date = minDateObject; date < maxDateObject; date = getNextQuarter(date)) {
		dates.push(date);
	}

	if (minDateObject <= maxDateObject) {
		dates.push(maxDateObject);
	}

	return dates;
};

const formatDate = (date, getMonth = true) => {
	if (date === null) {
		return null;
	}

	if (getMonth) {
		const month = date.getMonth() + 1;

		return `${month < 10 ? '0' : ''}${month}/${date.getFullYear()}`;
	}
	return `${date.getFullYear()}`;
};

export default function InvestingPortfolioRanger({
	fromDate,
	maxDate,
	minDate,
	toDate,
	setFromDate,
	setToDate,
}) {
	const [t] = useTranslation();
	const dates = useMemo(() => createDates(minDate, maxDate), [minDate, maxDate]);
	const indexes = useMemo(() => dates.reduce((acc, date, index) => {
		acc[toISODate(date)] = index;
		return acc;
	}), [dates]);
	const steps = useMemo(
		() => dates.map((date, value) => ({ date, value }))
			.slice(1, dates.length - 2)
			.filter(({ date }) => date.getDate() === 1 && date.getMonth() === 0 && date.getFullYear() % 2 === 0)
			.map(({ date, value }) => ({ value, label: String(date.getFullYear()) })),
		[dates],
	);

	if (dates.length <= 1) {
		return null;
	}

	const minLabel = formatDate(fromISODate(minDate), false);
	const maxLabel = formatDate(fromISODate(maxDate), false);

	const handleChange = (_, newFrom, newTo) => {
		const newFromDate = dates[newFrom] ?? dates[0];
		const newToDate = dates[newTo] ?? dates[dates.length - 1];
		setFromDate(toISODate(newFromDate));
		setToDate(toISODate(newToDate));
	};

	return (
		<div className={styles.rangerWrap}>
			<p className={styles.text}>
				{t('investingPortfolio.rangerTitle')}
			</p>
			<RangerDouble
				id="ranger"
				min={0}
				minLabel={minLabel}
				max={dates.length - 1}
				maxLabel={maxLabel}
				name="ranger"
				onChange={handleChange}
				steps={steps}
				valueFrom={indexes[fromDate] ?? 0}
				valueFromLabel={formatDate(fromISODate(fromDate)) ?? minLabel}
				valueTo={indexes[toDate] ?? dates.length - 1}
				valueToLabel={formatDate(fromISODate(toDate)) ?? maxLabel}
			/>
		</div>
	);
}

InvestingPortfolioRanger.propTypes = {
	fromDate: PropTypes.string.isRequired,
	maxDate: PropTypes.string.isRequired,
	minDate: PropTypes.string.isRequired,
	setFromDate: PropTypes.func.isRequired,
	setToDate: PropTypes.func.isRequired,
	toDate: PropTypes.string.isRequired,
};
