/* global document */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useTrackGTMEvent } from '../../../utils/GTMProvider';

import styles from './FaqList.module.scss';

export default function FaqListItem({
	answer,
	id,
	openItem,
	question,
	scrollToOpen,
	setOpenItem,
	spacy,
}) {
	const itemContentEl = useRef(null);
	const [itemContentHeight, setItemContentHeight] = useState(null);
	const isOpen = openItem === id;
	const trackGTMEvent = useTrackGTMEvent();

	useEffect(() => {
		setItemContentHeight(isOpen ? itemContentEl.current.scrollHeight : null);
	}, [isOpen]);

	const handleClick = (e) => {
		e.preventDefault();
		if (isOpen) {
			setOpenItem(null);
		} else {
			setOpenItem(id);

			if (scrollToOpen) {
				setTimeout(() => {
					const elementId = `faq-item-${id}`;
					document.getElementById(elementId)?.scrollIntoView({
						behavior: 'smooth',
					});
				}, 200);
			}

			trackGTMEvent('interactions', {
				eventCategory: 'interactions',
				eventAction: 'buttonClick',
				eventLabel: `faq_${id}`,
			});
		}
	};

	return (
		<div id={`faq-item-${id}`} className={`${styles.item} ${isOpen ? styles.isItemActive : ''}`.trim()}>
			<h4 className={styles.itemTitle}>
				<button
					type="button"
					onClick={handleClick}
					className={styles.collapseTrigger}
				>
					<span className={styles.icon}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 448 512"
							width={12}
							height={14}
						>
							{isOpen ? (
								<path
									fill="currentColor"
									d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
								/>
							) : (
								<path
									fill="currentColor"
									d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
								/>
							)}
						</svg>
					</span>
					<span
						id={`title${id}`}
						className={styles.itemTitleText}
					>
						{question}
					</span>
				</button>
			</h4>
			<div
				ref={itemContentEl}
				style={{ maxHeight: itemContentHeight }}
				className={`${styles.collapse} ${isOpen ? styles.isCollapseActive : ''}`.trim()}
				role="tabpanel"
				aria-labelledby={`title${id}`}
			>
				{/* eslint-disable-next-line react/no-danger */}
				<div className={`${styles.itemBody} ${spacy ? styles.itemBodySpacy : ''}`.trim()} dangerouslySetInnerHTML={{ __html: answer }} />
			</div>
		</div>
	);
}

FaqListItem.propTypes = {
	answer: PropTypes.string.isRequired,
	id: PropTypes.number.isRequired,
	openItem: PropTypes.number,
	question: PropTypes.string.isRequired,
	scrollToOpen: PropTypes.bool,
	setOpenItem: PropTypes.func.isRequired,
	spacy: PropTypes.bool,
};

FaqListItem.defaultProps = {
	openItem: null,
	scrollToOpen: false,
	spacy: false,
};
