import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export default function initSentry() {
	const sentryUrl = process.env.REACT_APP_SENTRY_URL ?? null;
	const sentryEnvironment = process.env.REACT_APP_SENTRY_ENVIRONMENT ?? null;

	if (sentryUrl !== null && sentryUrl !== '') {
		Sentry.init({
			dsn: sentryUrl,
			...(sentryEnvironment !== null && sentryEnvironment !== '' ? { environment: sentryEnvironment } : {}),
			integrations: [
				new BrowserTracing(),
				new Sentry.Replay({
					maskAllText: false,
					blockAllMedia: false,
				}),
			],
			tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.001 : 1.0,
			replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.001 : 1.0,
			replaysOnErrorSampleRate: 1.0,
		});
	}
}
