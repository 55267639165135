import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { useLanguage } from '../../../contexts/LocaleContext';
import { usePage } from '../../../contexts/PageContext';

export default function Meta({
	baseUrl,
	referral,
}) {
	const [t, i18n] = useTranslation();
	const language = useLanguage();
	const page = usePage();

	const title = page !== null && i18n.exists(`meta.titles.${page}`, { fallbackLng: [] })
		? `${t(`meta.titles.${page}`)}${t('meta.titleSuffix')}`
		: t('meta.titleExtended');

	const description = page !== null && i18n.exists(`meta.descriptions.${page}`, { fallbackLng: [] })
		? `${t(`meta.descriptions.${page}`)}`
		: t('meta.description');

	const metaImage = `${baseUrl}/images/fondee-og.png`;

	return (
		<Helmet>
			<html lang={language} />
			<title>
				{title}
			</title>
			<meta
				content={description}
				name="description"
			/>
			<meta
				content={referral ? t('meta.og.referralTitle') : t('meta.og.title')}
				property="og:title"
			/>
			<meta
				content={referral ? t('meta.og.referralDescription') : t('meta.og.description')}
				property="og:description"
			/>
			<meta content={metaImage} property="og:image" />
			<meta content={metaImage} name="twitter:image" />
		</Helmet>
	);
}

Meta.propTypes = {
	baseUrl: PropTypes.string.isRequired,
	referral: PropTypes.bool,
};

Meta.defaultProps = {
	referral: false,
};
