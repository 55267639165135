import React from 'react';
import PropTypes from 'prop-types';

import { useUserContext } from '../../../contexts/UserContext';
import { ExternalLink } from '../../ui';

import styles from './DocumentFile.module.scss';

const PdfIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 384 512"
		width="15"
		height="20"
	>
		<path
			fill="currentColor"
			d="M370 98l-84-84c-9-9-21-14-34-14H48C22 0 0 22 0 48v416c0 27 22 48 48 48h288c27 0 48-21 48-48V132c0-13-5-25-14-34zm-38 30h-76V52l76 76zM48 464V48h160v104c0 13 11 24 24 24h104v288H48zm250-144c-12-12-47-8-64-6a87 87 0 01-37-46c4-17 10-41 5-56-4-27-37-24-42-6-5 16-1 38 7 67-10 24-25 56-36 74-20 10-47 26-51 46-3 16 26 56 77-31 22-7 46-16 68-20 19 10 41 17 56 17 25 0 28-28 17-39zm-198 78c5-14 25-29 30-35-18 30-30 36-30 35zm82-190c7 0 6 32 1 40-4-14-4-40-1-40zm-25 136c10-17 18-37 25-55 8 16 19 28 30 36-21 4-39 13-55 19zm132-5s-5 6-37-8c35-2 40 6 37 8z"
		/>
	</svg>
);

const XlsxIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 1000 1000" width="20" height="20">
		<path d="M254 14c-37 9-67 36-82 74-5 14-5 15-6 185v171h91V115l7-8 6-7 121-1 120-1v112c0 105 0 113 5 127 10 35 38 62 74 75 15 5 20 5 127 6l111 1v230c0 254 1 241-14 248-6 3-38 4-275 3H270l-6-8c-6-6-7-10-7-19v-12h-92l2 19c4 53 37 93 87 106 20 5 555 5 575 0 42-11 73-42 84-83 3-11 4-60 4-312V291L776 150 636 10H452c-149 0-187 1-198 4zm468 313c-80 0-106-1-110-3-12-6-12-7-12-120V100l113 113 113 113-104 1z" />
		<path d="M138 502c-21 6-41 24-50 44-5 11-5 13-5 107v95l7 13c8 17 25 33 42 39 13 5 17 5 273 5s260 0 273-5c17-6 34-22 42-39l7-13v-95c0-94 0-96-5-107-7-15-23-31-37-39l-12-6-265-1c-145 0-267 1-270 2zm350 94c14 7 20 14 24 30l1 7h-13c-13 0-14-1-16-7-6-15-34-19-45-6-3 3-5 7-5 9 0 6 12 13 29 17 29 6 38 10 46 18 6 7 7 10 7 21 0 16-4 25-15 33a76 76 0 0 1-72 2c-12-7-21-18-21-29 0-7 0-7 13-7 12 0 12 0 15 8 4 10 11 13 28 13 29 0 37-23 10-30l-28-8c-23-6-31-12-35-27-6-23 9-44 35-49 11-3 32 0 42 5zm-274 17 12 20c1 1 7-8 13-19l12-21h16c12 0 15 1 14 3l-20 32c-14 22-18 29-16 32a3280 3280 0 0 0 40 65l-16 1h-17l-12-22-13-22-14 22-13 22h-15c-8 0-14-1-14-2l20-34c17-28 19-32 17-36l-19-30-17-28c-1-2 2-3 15-2h17l10 19zm115 34v53h65v26h-93V593h28v54zm239-38c17 30 14 30 28 5l12-21h16c12 0 15 1 14 3l-20 32c-16 25-18 29-16 33a1930 1930 0 0 0 40 64l-17 1h-16l-12-22-14-22-13 22-13 22h-15c-8 0-15-1-15-2l20-34c18-27 20-32 18-37l-19-29-17-28c-1-2 2-3 15-3h16l8 16z" />
	</svg>
);

export default function DocumentFile({
	childContract,
	firstName,
	label,
	onClick,
	to,
	xlsx,
}) {
	const [user] = useUserContext();

	return (
		<ExternalLink
			className={`${styles.root} ${xlsx ? styles.xlsx : ''}`.trim()}
			onClick={onClick}
			to={to}
			blank
		>
			{xlsx ? (
				<XlsxIcon />
			) : (
				<PdfIcon />
			)}
			<span>
				{label}
				{!user?.child && childContract && (
					<>
						{' - '}
						{ firstName }
					</>
				)}
			</span>
			{xlsx ? (
				<strong>(xlsx)</strong>
			) : (
				<strong>(pdf)</strong>
			)}
		</ExternalLink>
	);
}

DocumentFile.propTypes = {
	childContract: PropTypes.bool,
	firstName: PropTypes.string,
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	to: PropTypes.string.isRequired,
	xlsx: PropTypes.bool,
};

DocumentFile.defaultProps = {
	childContract: false,
	firstName: '',
	onClick: null,
	xlsx: false,
};
