import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import { TopUp } from '../../common';
import Button from '../Button';
import Modal from '../Modal';

import styles from './TopUpModal.module.scss';

export default function TopUpModal({
	dashboard,
	defaultModalOpen,
	defaultPortfolioId,
	employer,
	onModalClose,
	portfolios,
	small,
}) {
	const [modalOpen, setModalOpen] = useState(false);
	const [t] = useTranslation();
	const trackGTMEvent = useTrackGTMEvent();

	const handleOpenModal = () => {
		setModalOpen(true);
		trackGTMEvent('interactions', {
			eventCategory: 'interactions',
			eventAction: 'buttonClick',
			eventLabel: dashboard
				? 'chci_vlozit_penize_portfolios'
				: 'chci_vlozit_penize',
		});
	};

	const handleCloseModal = () => {
		setModalOpen(false);
		if (onModalClose !== null) {
			onModalClose();
		}
	};

	return (
		<>
			<button
				className={`${styles.root} ${small ? styles.small : ''}`.trim()}
				onClick={handleOpenModal}
				type="button"
			>
				{employer ? t('topUp.employer.open') : (
					<>
						<svg viewBox="0 0 26 24" width="20" height="22" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
							<path d="M14.695 9.805V1.5h-3.39v8.305H3v3.39h8.305V21.5h3.39v-8.305H23v-3.39h-8.305z" fill="currentColor" />
						</svg>
						{t(small ? 'topUp.short' : 'topUp.triggerButton')}
					</>
				)}
			</button>
			<Modal
				isVisible={defaultModalOpen || modalOpen}
				onClose={handleCloseModal}
				size="large"
				title={employer ? t('topUp.employer.modal.title') : t('topUp.modal.title')}
			>
				<TopUp
					defaultPortfolioId={defaultPortfolioId}
					employer={employer}
					portfolios={portfolios}
				/>
				<div className={styles.modalClose}>
					{!employer && (
						<Button
							label={t('topUp.modal.close')}
							onClick={handleCloseModal}
						/>
					)}
				</div>
			</Modal>
		</>
	);
}

TopUpModal.propTypes = {
	dashboard: PropTypes.bool,
	defaultModalOpen: PropTypes.bool,
	defaultPortfolioId: PropTypes.number,
	employer: PropTypes.bool,
	onModalClose: PropTypes.func,
	portfolios: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number.isRequired,
		name: PropTypes.string,
		faceImageFile: PropTypes.string.isRequired,
	}).isRequired),
	small: PropTypes.bool,
};

TopUpModal.defaultProps = {
	dashboard: false,
	defaultModalOpen: false,
	defaultPortfolioId: null,
	employer: false,
	onModalClose: null,
	portfolios: null,
	small: false,
};
