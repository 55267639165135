import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, ButtonLink } from '../../../ui';

import styles from './ChildList.module.scss';

export default function ChildList({
	childOnboardingUrl,
	childrenList,
	modalClose,
	selectChild,
}) {
	const [t] = useTranslation();

	return (
		<div className={styles.wrap}>
			<div className={styles.title}>
				{t('portfolio.new.childList.title')}
			</div>
			{(childrenList ?? []).map((child) => (
				<div
					key={child.id}
					className={styles.item}
				>
					<div className={styles.name}>
						{child.firstName}
						{' '}
						{child.lastName}
					</div>
					{!child.activeClient && !child.accountClosure && (
						<Link
							className={styles.button}
							onClick={modalClose}
							to={`${childOnboardingUrl}?childId=${child.id}`}
						>
							{t('account.childList.finishOnboarding')}
						</Link>
					)}
					{child.activeClient && (
						<Button
							isFlat
							label={t('portfolio.new.childList.select')}
							onClick={() => {
								selectChild(child.id);
							}}
							outlineHeavy
						/>
					)}
				</div>
			))}
			{childrenList?.length < 5 && (
				<div className={styles.item}>
					<div className={styles.name}>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={19} height={19} viewBox="0 0 19 19">
							<path stroke="#F39200" strokeLinecap="round" strokeWidth="2" d="M1.5 9.5h16m-8 8v-16" />
						</svg>
						{t('portfolio.new.childList.addChild')}
					</div>
					<ButtonLink
						isFlat
						label={t('portfolio.new.childList.select')}
						onClick={modalClose}
						outlineHeavy
						to={childOnboardingUrl}
					/>
				</div>
			)}
		</div>
	);
}

ChildList.propTypes = {
	childOnboardingUrl: PropTypes.string.isRequired,
	childrenList: PropTypes.arrayOf(PropTypes.shape({
		firstName: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
		lastName: PropTypes.string.isRequired,
	}).isRequired),
	modalClose: PropTypes.func.isRequired,
	selectChild: PropTypes.func.isRequired,
};

ChildList.defaultProps = {
	childrenList: null,
};
