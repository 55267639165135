/* global document, window */
export default function scrollToTheElementIfNeeded(element) {
	if (!element) {
		return;
	}

	const clientRect = element.getBoundingClientRect();
	const isInViewport = clientRect.top >= 0
		&& clientRect.left >= 0
		&& clientRect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
		&& clientRect.right <= (window.innerWidth || document.documentElement.clientWidth);

	if (isInViewport) {
		return;
	}

	element.scrollIntoView({
		behavior: 'smooth',
		block: 'center',
	});
}
