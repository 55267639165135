import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { fetchFeesComparison } from '../../../utils/api';
import useFetch from '../../../utils/useFetch';
import { Container, Section } from '../../layout';
import { SectionAnchor } from '../../ui';

import styles from './FeesComparison.module.scss';

const useFetchFeesComparison = useFetch(fetchFeesComparison);

function getColumnValue(row, columnId) {
	return row.filter((col) => col.columnId === columnId).map((item) => item.value);
}

export default function FeesComparison({
	comparisonAnchor,
	chartAnchor,
}) {
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const [feesComparison, loading] = useFetchFeesComparison({}, country, language);

	return (
		<Section id={comparisonAnchor} bordered>
			<Container>
				<h3 className={styles.title}>
					{t('feesComparison.title')}
				</h3>
				{!loading && (
					<div className={styles.tableWrap}>
						<table className={styles.table}>
							<thead>
								<tr>
									<th>{' '}</th>
									{(feesComparison.columns ?? []).map((feesComparisonColumn) => (
										<th key={`headColumn${feesComparisonColumn.id}`}>
											<strong>{feesComparisonColumn.title}</strong>
											{feesComparisonColumn.subtitle}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{(feesComparison.rows ?? []).map((feesComparisonRow) => (
									<tr key={`row${feesComparisonRow.id}`}>
										<td>{feesComparisonRow.title}</td>
										{(feesComparison.columns ?? []).map((feesComparisonColumn) => (
											<td key={`row${feesComparisonRow.id}col${feesComparisonColumn.id}`}>
												{getColumnValue(feesComparisonRow.values, feesComparisonColumn.id)}
											</td>
										))}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)}
				<div className={styles.footer}>
					{t('feesComparison.footerNote') !== '' && (
						<p>
							{t('feesComparison.footerNote')}
						</p>
					)}
					<p>
						*
						{' '}
						{t('feesComparison.footerTextNote1')}
					</p>
					<p>
						**
						{' '}
						{t('feesComparison.footerTextNote2')}
					</p>
					<p>
						{t('feesComparison.footerTextNotePart2')}
					</p>
					<p>
						***
						{' '}
						{t('feesComparison.footerTextNote3')}
					</p>
					<p>
						{t('feesComparison.footerTextNotePart3')}
					</p>
					<p>
						*******
						{' '}
						{t('feesComparison.footerTextNote4')}
					</p>
				</div>
			</Container>
			<SectionAnchor target={chartAnchor} title={t('feesChart.chartAnchor')} />
		</Section>
	);
}

FeesComparison.propTypes = {
	comparisonAnchor: PropTypes.string.isRequired,
	chartAnchor: PropTypes.string.isRequired,
};
