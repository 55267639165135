/* global document */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import {
	ClientHeader,
	DashboardChart,
	DashboardOverview,
	NoActivePortfolio,
	PortfolioCreate,
	PortfolioTable,
} from '../../components/common';
import { useLanguage } from '../../contexts/LocaleContext';
import { useUserContext } from '../../contexts/UserContext';
import {
	fetchDashboardData,
	fetchProfilePortfolio,
	fetchUserCurrency,
} from '../../utils/api';
import useFetchAuth from '../../utils/useFetchAuth';
import { useFetchWithHardReload } from '../../utils/useFetchWithReload';
import { useTrackGTMEvent } from '../../utils/GTMProvider';
import { Loader, Toggle } from '../../components/ui';
import { Container } from '../../components/layout';

import styles from './Dashboard.module.scss';

const useFetchDashboardData = useFetchWithHardReload(fetchDashboardData);
const useFetchProfilePortfolio = useFetchWithHardReload(fetchProfilePortfolio);
const useFetchUserCurrency = useFetchAuth(fetchUserCurrency);

export default function Dashboard({
	accountUrl,
	childOnboardingUrl,
	conditionsUrl,
	faqUrl,
	parentOnboardingUrl,
	portfolioUrl,
	proxyId,
	signNewAgreementUrl,
}) {
	const isProxy = proxyId !== null;
	const [user] = useUserContext();
	const [period, setPeriod] = useState(isProxy ? 'all' : null);
	const language = useLanguage();
	const [dashboardData, loading, error, , reloadDashboard] = useFetchDashboardData(null, period, language, proxyId);
	const [
		portfolios,
		loadingPortfolios,
		errorPortfolios,,
		reloadPortfolios,
	] = useFetchProfilePortfolio(null, language, null, isProxy);
	const [currencies] = useFetchUserCurrency(null, language, isProxy);
	const [evaluation, setEvaluation] = useState(false);
	const [scrollDashboard, setScrollDashboard] = useState(false);
	const [t] = useTranslation();
	const trackGTMEvent = useTrackGTMEvent();

	useEffect(() => {
		if (!loading && scrollDashboard) {
			setTimeout(() => {
				document.getElementById('portfolioTable')?.scrollIntoView({
					behavior: 'smooth',
				});
			}, 500);
		}
		setScrollDashboard(false);
	}, [scrollDashboard, loading]);

	if (loading || error || loadingPortfolios || errorPortfolios) {
		return <Loader fullPage />;
	}

	if (!isProxy && portfolios?.active?.length === 0) {
		return (
			<NoActivePortfolio
				accountUrl={accountUrl}
				childOnboardingUrl={childOnboardingUrl}
				conditionsUrl={conditionsUrl}
				currencies={currencies}
				parentOnboardingUrl={parentOnboardingUrl}
				portfolios={portfolios}
				reloadDashboard={reloadDashboard}
				reloadPortfolios={reloadPortfolios}
				setScrollDashboard={setScrollDashboard}
				signNewAgreementUrl={signNewAgreementUrl}
			/>
		);
	}

	return (
		<div className={styles.root}>
			{!isProxy && (
				<ClientHeader
					faqUrl={faqUrl}
					portfolios={portfolios?.active ?? null}
				/>
			)}
			{dashboardData !== null && (
				<>
					<DashboardOverview
						dashboardData={dashboardData}
						setPeriod={setPeriod}
					/>
					<Container>
						<div className={styles.toggleWrap}>
							<Toggle
								labelFirst={t('dashboard.chart.toggle.value')}
								labelSecond={t('dashboard.chart.toggle.evaluation')}
								setToggle={setEvaluation}
								toggle={evaluation}
								onClick={() => trackGTMEvent('interactions', {
									eventCategory: 'interactions',
									eventAction: 'buttonClick',
									eventLabel: evaluation ? 'history_chart_to_value' : 'history_chart_to_growth',
								})}
							/>
						</div>
					</Container>
					<DashboardChart
						currency={dashboardData.currency}
						data={dashboardData.valueGraph}
						evaluation={evaluation}
					/>
					<PortfolioTable
						dashboardData={dashboardData}
						portfolioUrl={portfolioUrl}
						setPeriod={setPeriod}
						setScrollDashboard={setScrollDashboard}
						isProxy={isProxy}
					/>
				</>
			)}
			{!isProxy && !user.child && portfolios !== null && currencies !== null && (
				<PortfolioCreate
					accountUrl={accountUrl}
					childOnboardingUrl={childOnboardingUrl}
					conditionsUrl={conditionsUrl}
					currencies={currencies}
					parentOnboardingUrl={parentOnboardingUrl}
					portfolios={portfolios}
					reloadDashboard={reloadDashboard}
					reloadPortfolios={reloadPortfolios}
					setScrollDashboard={setScrollDashboard}
					signNewAgreementUrl={signNewAgreementUrl}
				/>
			)}
		</div>
	);
}

Dashboard.propTypes = {
	accountUrl: PropTypes.string.isRequired,
	childOnboardingUrl: PropTypes.string.isRequired,
	conditionsUrl: PropTypes.string.isRequired,
	faqUrl: PropTypes.string.isRequired,
	parentOnboardingUrl: PropTypes.string.isRequired,
	portfolioUrl: PropTypes.string.isRequired,
	proxyId: PropTypes.number,
	signNewAgreementUrl: PropTypes.string.isRequired,
};

Dashboard.defaultProps = {
	proxyId: null,
};
