import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import {
	Container,
	Section,
} from '../../layout';
import { ButtonLink } from '../../ui';

import styles from './HomepageServices.module.scss';

const icons = [
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={137} height={120} viewBox="0 0 137 120">
		<defs>
			<path id="simply-icon-b" fill="#E66F64" d="M26.38 84c6 7.5 12 16 8 30 1.5 1 4.7 1.8 5.5-3 .8-4.8-2.34-16-4-21l-7.5-14.5c9.5.5 13.5-1.5 13-5s-12-4.5-19-4-2 10 4 17.5Z" />
		</defs>
		<circle cx="70.88" cy="60" r="59" fill="#F4DFBF" stroke="#47202A" strokeWidth="2" />
		<circle cx="88.88" cy="55.5" r="47.5" fill="#F29C39" />
		<path stroke="#fff" strokeLinecap="round" strokeWidth="3" d="M89.38 13v3.5m0 77.5v3.5m42.75-42.25h-3.5m-77.5 0h-3.5m78.84 21.13-3.04-1.75M56.32 35.88l-3.03-1.76M111 91.84l-1.75-3.03M70.5 21.7l-1.75-3.04m42.25 0-1.75 3.03M70.5 88.8l-1.75 3.04m57.72-57.71-3.04 1.74M56.32 74.63l-3.03 1.75" />
		<path stroke="#99556A" strokeLinecap="round" strokeWidth="3" d="M89.38 55h20m-20 4V25" />
		<circle cx="89.38" cy="55" r="3.5" fill="#99556A" stroke="#99556A" />
		<mask id="simply-icon-a" width="121" height="120" x="10" y="0" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
			<circle cx="70.88" cy="60" r="60" fill="#F4DFBF" />
		</mask>
		<g mask="url(#simply-icon-a)">
			<path fill="#E66F64" stroke="#F4DFBF" strokeLinecap="round" d="m59.69 122.89.1-.08.05-.12c.77-1.94 1.27-4.62 1.63-7.63a204.29 204.29 0 0 0 .88-9.8c.27-3.4.55-6.8 1-9.86.46-3.06 1.08-5.74 1.98-7.69 1.55-3.36 1.67-6.96 1.73-10.26l.01-.4c.06-3.19.11-6.04 1.25-8.33 1.27-2.54 1.95-5.16 1.48-7.39-.46-2.19-2.02-3.89-4.93-4.72a10.48 10.48 0 0 0-1.68-5.32c-1.24-1.78-3.26-3.07-6.36-2.84a9.68 9.68 0 0 0-1.88-4.77 6.07 6.07 0 0 0-2.64-1.96 7 7 0 0 0-3.42-.31 7.5 7.5 0 0 0-1.3-4.3 4.59 4.59 0 0 0-2.43-1.7 7.24 7.24 0 0 0-3.91.1 6.3 6.3 0 0 0-3.48 2.15 6.11 6.11 0 0 0-1.2 3.27c-.2 2.2.52 4.55 1.26 6.1L26.4 79.33l-.08.25.15.21 6.45 8.93 2.47 9.4h0v.01c.58 2.06 1.2 5.3 1.17 8.38 0 1.53-.18 3-.58 4.24a5.32 5.32 0 0 1-1.9 2.84l-.66.47.72.38c1.14.61 2.59 1.86 4.32 3.35l.2.17a54.3 54.3 0 0 0 6.07 4.71c2.22 1.42 4.64 2.53 7.18 2.75a10.6 10.6 0 0 0 7.78-2.53Z" />
			<rect width="35" height="68" x="1.38" y="47.24" fill="#41272D" rx="5" transform="rotate(-45 1.38 47.24)" />
			<rect width="28" height="61" x="6.38" y="47.24" fill="#F8B23C" rx="2" transform="rotate(-45 6.38 47.24)" />
		</g>
		<mask id="simply-icon-c" width="78" height="91" x="0" y="13" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
			<path fill="#D9D9D9" d="m72.38 103.5-46-3L.88 53V41l31-28 46 40-5.5 50.5Z" />
		</mask>
		<g mask="url(#simply-icon-c)">
			<path fill="#E66F64" d="M64.88 87.5c-3.74 8.08-2.5 27.5-5.5 35-10 8-20-5.83-25-8.5 4-2.8 2.66-11.83 1.5-16l-2.5-9.5-6.5-9L38.38 47c-1.5-3-3-9.4 3-11 6-1.6 7.16 3.33 7 6 6-1.2 7.83 4.17 8 7 6.4-.8 8 5 8 8 6 1.5 6 6.5 3.5 11.5s0 12.5-3 19Z" />
			<rect width="35" height="68" x="1.38" y="47.24" fill="#41272D" rx="5" transform="rotate(-45 1.38 47.24)" />
			<rect width="28" height="61" x="6.38" y="47.24" fill="#FFEAC7" rx="2" transform="rotate(-45 6.38 47.24)" />
			<mask id="simply-icon-d" width="62" height="62" x="7" y="28" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
				<rect width="28" height="61" x="6.38" y="47.24" fill="#F8B23C" rx="2" transform="rotate(-45 6.38 47.24)" />
			</mask>
			<g stroke="#F29C39" strokeLinecap="round" strokeWidth="3" mask="url(#simply-icon-d)">
				<path d="M14.88 48.5 27.38 36m-7.5 17.5L32.38 41m-7.5 17.5 6.5-6.5" />
			</g>
		</g>
		<path stroke="#47202A" strokeWidth="2" d="M71 119c-32.66 0-59.13-26.42-59.13-59" />
	</svg>,
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={139} height={133} viewBox="0 0 139 133">
		<mask id="smart-icon-a" width="119" height="120" x="10" y="13" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
			<ellipse cx="69.73" cy="73" fill="#F4DFBF" rx="59.17" ry="60" />
		</mask>
		<g mask="url(#smart-icon-a)">
			<path fill="#F4DFBF" stroke="#47202A" strokeWidth="2" d="M127.9 73c0 32.6-26.05 59-58.17 59-32.11 0-58.17-26.4-58.17-59s26.06-59 58.17-59c32.12 0 58.18 26.4 58.18 59Z" />
			<path fill="#99556A" stroke="#99556A" d="M65.82 103.88a4.5 4.5 0 0 1 7.83 0l21.29 37.4a4.5 4.5 0 0 1-3.91 6.72H48.44a4.5 4.5 0 0 1-3.9-6.73l21.28-37.4Z" />
		</g>
		<path stroke="#47202A" strokeWidth="2" d="M28.86 115a57.36 57.36 0 0 0 40.88 17 57.3 57.3 0 0 0 40.88-17" />
		<path stroke="#99556A" strokeLinecap="round" strokeWidth="5" d="M9.58 101.5h121.3" />
		<path fill="#F29C39" stroke="#47202A" strokeWidth="2" d="M119.05 97c10.36 0 18.75-8.52 18.75-19s-8.39-19-18.76-19c-10.37 0-18.75 8.52-18.75 19s8.38 19 18.75 19Z" />
		<path fill="#E94549" d="M119.05 60a17.54 17.54 0 0 1 12.55 5.27A18.02 18.02 0 0 1 136.8 78h-17.76V60Z" />
		<path fill="#F29C39" stroke="#47202A" strokeWidth="2" d="M20.42 97c10.37 0 18.76-8.52 18.76-19s-8.39-19-18.76-19C10.05 59 1.67 67.52 1.67 78s8.38 19 18.75 19Z" />
		<path fill="#E94549" d="M20.42 60c2.92 0 5.8.73 8.37 2.13A17.84 17.84 0 0 1 35.2 68a18.16 18.16 0 0 1-3.5 23.91 17.65 17.65 0 0 1-16.42 3.31 17.7 17.7 0 0 1-7.4-4.5L20.42 78V60Z" />
		<path fill="#FAB43D" d="M71.19 72.88c.7-1.95 2.48-3.23 4.48-3.74a19.86 19.86 0 0 0 13.72-12.55c3.76-10.4-1.49-21.9-11.72-25.7a19.67 19.67 0 0 0-25.32 11.93 20.27 20.27 0 0 0 2.48 18.57c1.2 1.7 1.73 3.85 1.03 5.8-1 2.74.42 5.8 3.16 6.82l5.4 2a5.24 5.24 0 0 0 6.77-3.13Zm-15.62 4.9a1.98 1.98 0 0 1 2.68-1.93l5.6 2.08c1.31.5 1.74 2.17.8 3.22l-.7.8a2 2 0 0 1-2.17.54l-4.86-1.8a2.02 2.02 0 0 1-1.32-1.85l-.03-1.06Z" />
		<path stroke="#F29C39" strokeLinecap="round" strokeWidth="2" d="M60.37 72c6.4-12 13.3-26 6.4-24s0 10 3.46 10.5c3.45.5 10.35-5 6.4-7-3.15-1.6-9.85 12.33-11.83 19.5" />
		<path fill="#F29C39" stroke="#fff" strokeLinecap="round" d="M31.2 19.81a1.48 1.48 0 0 0-2.1.28c-.5.66-.37 1.6.28 2.1l14.8 11.5c.65.5 1.58.38 2.08-.28s.38-1.6-.27-2.1l-14.8-11.5ZM51.34 4.83a1.48 1.48 0 0 0-2-.67 1.5 1.5 0 0 0-.66 2l7.89 16a1.48 1.48 0 0 0 2 .68 1.5 1.5 0 0 0 .66-2l-7.89-16ZM81.05 2.8a1.49 1.49 0 1 0-2.92-.59L74.7 19.7c-.16.8.35 1.6 1.16 1.76.81.17 1.6-.37 1.75-1.18l3.45-17.5ZM91.5 27.68l.31-.4-.3.4c.64.51 1.58.4 2.08-.26l10.36-13.5-.4-.3.4.3c.5-.65.38-1.6-.26-2.1a1.48 1.48 0 0 0-2.1.26l-10.35 13.5c-.5.65-.38 1.6.26 2.1Zm26.88 2.98.22.45-.22-.45-15.78 8a1.5 1.5 0 0 0-.66 2 1.48 1.48 0 0 0 2 .68l15.77-8a1.5 1.5 0 0 0 .67-2 1.48 1.48 0 0 0-2-.68Z" />
	</svg>,
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={121} height={128} viewBox="0 0 121 128">
		<mask id="security-icon-a" width="121" height="120" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
			<circle cx="60.38" cy="60" r="60" fill="#F4DFBF" />
		</mask>
		<g mask="url(#security-icon-a)">
			<circle cx="60.38" cy="60" r="59" fill="#F4DFBF" stroke="#47202A" strokeWidth="2" />
			<path fill="#E66F64" d="M11.57 96.1c1-9-.5-11-3.5-27l25.5 2.5c.8 4-1.33 18-2.5 24.5-5.6 6.4-15.33 2.67-19.5 0Z" />
			<path fill="#F68C82" d="M29.07 80.6c21 6 22-26 19-59.5-27-1.5-48.5 27-51 38.5 2 6.4 6.84 7 9 6.5.67 2.84 6.2 9.7 23 14.5Z" />
			<path fill="#47212A" d="m47.73 39.6-3.9-8.5c-6.8 23.2-24.6 24.5-34.6 23.5 1.2 3.2-2.84 8.67-5 11l3.5 1c4 3.6 3.76 19.84 4.6 27-6.5 2-9 2.5-12.5 0s-21.57-35-6.6-67c13.1-28 45.6-17.6 54.5-12 14 8.8 5 20.5 0 25Z" />
			<path fill="#F29C39" d="M12.37 93c-2.5 1-8 1.5-13.5-.5-1.2 1.2-.83 3.17-.5 4 1 .33 3.5 1.1 5.5 1.5l-5 2 4.5 4.5 20 12.5 12.5 2.5h16l14.5 2.5-7-19c-.8-6-22.33-7-28.5-7-7.2 5.2-15.33 2.5-18.5.5V93Z" />
		</g>
		<circle cx="60.38" cy="60" r="59" stroke="#47202A" strokeWidth="2" />
		<path fill="#F29C39" stroke="#47202A" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M43.37 66.22a1 1 0 0 0-.32.95l6.4 30.75A30.2 30.2 0 0 0 61.8 116.6l14.75 10.22a1 1 0 0 0 1.14 0l14.75-10.22a30.2 30.2 0 0 0 12.35-18.68l6.4-30.75a1 1 0 0 0-.32-.95l-.7-.62a49.65 49.65 0 0 0-66.1 0l.66.75-.67-.75-.7.62Z" />
		<path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5" d="m65.44 90 9.73 7.78 14.6-19.46" />
	</svg>,
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={121} height={120} viewBox="0 0 121 120">
		<mask id="mask0_3816_1783" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="121" height="120">
			<circle cx="60.125" cy="60" r="60" fill="#F4DFBF" />
		</mask>
		<g mask="url(#mask0_3816_1783)">
			<circle cx="60.125" cy="60" r="60" fill="#F4DFBF" />
			<rect x="22.125" y="-38" width="106" height="135" rx="5" fill="white" stroke="#47202A" strokeWidth="2" />
			<rect x="31.125" y="25" width="89" height="8" rx="2" fill="#F1F1F1" />
			<rect x="31.125" y="36" width="49" height="8" rx="2" fill="#F1F1F1" />
			<rect x="31.125" y="2" width="89" height="8" rx="2" fill="#F1F1F1" />
			<rect x="31.125" y="13" width="89" height="8" rx="2" fill="#F1F1F1" />
			<rect x="44.125" y="82" width="72" height="8" rx="2" fill="#F1F1F1" />
			<circle cx="60.125" cy="60" r="59" stroke="#47202A" strokeWidth="2" />
		</g>
		<path d="M34.6518 74.8133V74.5519C34.6518 74.184 34.8588 74 35.2729 74H36.8257C37.2398 74 37.4468 74.184 37.4468 74.5519V74.8133C37.4468 75.5879 37.2605 76.3916 36.8878 77.2242C36.8257 77.3792 36.7636 77.5341 36.7015 77.689C36.6394 77.8246 36.5669 77.9504 36.4841 78.0666C36.422 78.1634 36.3599 78.2603 36.2977 78.3571C36.2563 78.4345 36.2253 78.5023 36.2046 78.5604C36.7636 77.9214 37.1777 77.4857 37.4468 77.2533C38.0265 76.7498 38.8236 76.3431 39.8381 76.0333C40.19 75.9365 40.4488 76.0624 40.6144 76.4109L41.0803 77.8052C41.2045 78.1731 41.0699 78.4152 40.6766 78.5314C39.9519 78.7831 39.217 78.909 38.4716 78.909C37.6021 78.8896 36.9085 78.8218 36.3909 78.7057C38.0058 79.2285 39.1755 80.0321 39.9002 81.1166C40.1279 81.4264 40.0865 81.6878 39.776 81.9009L38.5027 82.7432C38.1714 82.9369 37.8816 82.8981 37.6331 82.627C36.6394 81.5039 36.1425 80.1677 36.1425 78.6185C36.0804 79.8579 35.9147 80.739 35.6456 81.2618C35.4593 81.6878 35.1694 82.1913 34.776 82.7723C34.569 83.0434 34.2791 83.0724 33.9065 82.8594L32.6643 81.988C32.2709 81.7943 32.2191 81.5329 32.509 81.2037C32.9852 80.584 33.3889 80.1677 33.7202 79.9547C34.0514 79.7223 34.3206 79.5383 34.5276 79.4028C34.7553 79.2672 34.9624 79.1607 35.1487 79.0833C35.335 78.9864 35.4903 78.909 35.6145 78.8509C35.7595 78.7734 35.9354 78.696 36.1425 78.6185C34.362 79.0833 32.9127 79.1414 31.7947 78.7928L31.5152 78.7057C31.1633 78.5895 31.0494 78.3474 31.1736 77.9795L31.6394 76.5852C31.7637 76.2173 32.0225 76.1011 32.4158 76.2366C33.658 76.5658 34.8381 77.3017 35.9561 78.4442L36.0804 78.5604C36.039 78.5023 35.9872 78.4345 35.9251 78.3571C35.8837 78.2603 35.8216 78.1634 35.7388 78.0666C35.6559 77.9504 35.5731 77.8246 35.4903 77.689C35.4282 77.5341 35.3557 77.3792 35.2729 77.2242C35.1901 77.05 35.1073 76.866 35.0245 76.6723C34.9624 76.4787 34.9003 76.285 34.8381 76.0914C34.7139 75.7041 34.6518 75.2781 34.6518 74.8133Z" fill="#E94549" />
		<path d="M8.90952 5.24915C7.94255 4.26358 6.35971 4.24851 5.37415 5.21548C4.38858 6.18245 4.37351 7.76529 5.34048 8.75085L109.34 114.751C110.307 115.736 111.89 115.751 112.876 114.785C113.861 113.818 113.876 112.235 112.91 111.249L8.90952 5.24915Z" fill="#E94549" stroke="white" strokeLinecap="round" />
	</svg>,
];

export default function HomepageServices({
	howItWorksUrl,
}) {
	const [t] = useTranslation();

	return (
		<Section condensed>
			<Container>
				<div className={styles.wrap}>
					{Object.keys(t('homepageServices.list', { returnObjects: true })).map((item) => (
						<div
							key={item}
							className={styles.item}
						>
							<div className={styles.icon}>
								{icons[+item] ?? null}
							</div>
							<div className={styles.content}>
								<h3 className={styles.title}>
									{t(`homepageServices.list.${item}.title`)}
								</h3>
								<p className={styles.text}>
									<Trans i18nKey={`homepageServices.list.${item}.text`}>
										<strong />
									</Trans>
								</p>
							</div>
						</div>
					))}
				</div>
				<div className={styles.buttonWrap}>
					<ButtonLink label={t('homepageServices.button')} to={howItWorksUrl} />
				</div>
			</Container>
		</Section>
	);
}

HomepageServices.propTypes = {
	howItWorksUrl: PropTypes.string.isRequired,
};
