import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { saveTaxQuestionnaireAnswer } from '../../../../utils/api';
import { toInt } from '../../../../utils/number';
import useRequestAuth from '../../../../utils/useRequestAuth';
import { Alert, Button } from '../../../ui';

import styles from '../../Questionnaire/StepQuestions/StepQuestions.module.scss';

const MAX_CHARACTERS = 90;

function toIntOrNull(value) {
	const intValue = toInt(value);
	return Number.isNaN(intValue) ? null : intValue;
}

function toAnswer(value) {
	const answerId = value !== null ? toIntOrNull(value) : null;
	return [answerId, answerId, null];
}

function toFormValue({ userAnswer }, answer) {
	const answerId = answer ?? userAnswer?.id ?? null;
	return answerId !== null ? String(answerId) : null;
}

function toFormValues(questions, answers) {
	return questions.reduce((acc, question) => {
		const value = toFormValue(question, answers[question.id]);
		if (value !== undefined) {
			acc[question.id] = value;
		}
		return acc;
	}, {});
}

export default function TaxStepQuestions({
	answers,
	nextPosition,
	questions,
	setAnswers,
	setPosition,
	updateResult,
}) {
	const saveQuestionnaireAnswerAuth = useRequestAuth(saveTaxQuestionnaireAnswer);
	const [error, setError] = useState(false);
	const [t] = useTranslation();
	const initAnswers = toFormValues(questions, answers);
	const getValue = ({ id }, values) => (id in values.answers ? values.answers[id] : initAnswers[id]);

	return (
		<>
			<Formik
				initialValues={{
					answers: initAnswers,
				}}
				enableReinitialize
				onSubmit={async (values) => {
					setError(false);
					for (let i = 0; i < questions.length; i += 1) {
						const question = questions[i];
						const [answer, answerId] = toAnswer(getValue(question, values));

						try {
							// eslint-disable-next-line no-await-in-loop
							const resultData = await saveQuestionnaireAnswerAuth(
								question.id,
								answerId,
							);
							updateResult(resultData);
						} catch {
							setError(true);
							return;
						}
						if (answer !== null) {
							setAnswers((prevState) => ({
								...prevState,
								[question.id]: answer,
							}));
						}
					}

					if (nextPosition) {
						setPosition(nextPosition);
					}
				}}
				className={styles.root}
			>
				{({
					handleChange,
					handleSubmit,
					isSubmitting,
					values,
				}) => (
					<form onSubmit={handleSubmit}>
						{error && (
							<Alert type="danger">
								{t('forms.error')}
							</Alert>
						)}
						{questions.map((question) => (
							<Fragment key={question.id}>
								{question.answers && (
									<>
										<h3
											className={`${styles.question} ${question.text.length > MAX_CHARACTERS ? styles.smaller : ''}`.trim()}
											// eslint-disable-next-line react/no-danger
											dangerouslySetInnerHTML={{ __html: question.text }}
										/>
										<div className={styles.singleChoice}>
											{question.answers.sort((a, b) => a.id - b.id)
												.map((answer) => (
													<label
														key={answer.id}
														htmlFor={`answers[${question.id}]__${answer.id}`}
														className={styles.label}
													>
														<input
															checked={
																getValue(question, values) === String(answer.id)
															}
															className={styles.input}
															disabled={isSubmitting}
															id={`answers[${question.id}]__${answer.id}`}
															name={`answers[${question.id}]`}
															onChange={handleChange}
															onClick={() => handleSubmit()}
															type="radio"
															value={String(answer.id)}
														/>
														<span className={styles.button}>
															{answer.text}
														</span>
													</label>
												))}
										</div>
										{question.note && question.note.split('\n\n').map((paragraph) => (
											<div
												key={paragraph}
												className={styles.note}
												// eslint-disable-next-line react/no-danger
												dangerouslySetInnerHTML={{ __html: paragraph }}
											/>
										))}
									</>
								)}
							</Fragment>
						))}
						{((questions[0].answers.length === 0)) && (
							<Button
								disabled={isSubmitting}
								isSubmit
								label={t('taxQuestionnaire.continue')}
								onClick={() => setPosition(nextPosition)}
							/>
						)}
					</form>
				)}
			</Formik>
		</>
	);
}

TaxStepQuestions.propTypes = {
	answers: PropTypes.objectOf(PropTypes.number).isRequired,
	nextPosition: PropTypes.number,
	questions: PropTypes.arrayOf(
		PropTypes.shape({
			answers: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.number.isRequired,
					text: PropTypes.string.isRequired,
				}),
			).isRequired,
			id: PropTypes.number.isRequired,
			note: PropTypes.string,
			text: PropTypes.string.isRequired,
			userAnswer: PropTypes.shape({
				id: PropTypes.number,
				value: PropTypes.number,
			}),
		}),
	).isRequired,
	setAnswers: PropTypes.func.isRequired,
	setPosition: PropTypes.func.isRequired,
	updateResult: PropTypes.func.isRequired,
};

TaxStepQuestions.defaultProps = {
	nextPosition: null,
};
