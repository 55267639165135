import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

import { useCountry, useLanguage } from '../../../../contexts/LocaleContext';
import { filterChild, useUserContext } from '../../../../contexts/UserContext';
import {
	changeInvestorProfile,
	fetchInvestorProfile,
	fetchProfileInvestmentProfile,
	fetchShowEsgWarning,
} from '../../../../utils/api';
import { useTrackGTMEvent } from '../../../../utils/GTMProvider';
import useFetch from '../../../../utils/useFetch';
import useFetchAuth from '../../../../utils/useFetchAuth';
import useRequestAuth from '../../../../utils/useRequestAuth';
import {
	Alert,
	Button,
	Modal,
	Toggle,
	Tooltip,
} from '../../../ui';
import InvestorProfileAvatar from '../../InvestorProfileAvatar';
import InvestorProfileDetailSelection from '../../InvestorProfileDetail/InvestorProfileDetailSelection';
import InvestorProfileDetailSlider from '../../InvestorProfileDetail/InvestorProfileDetailSlider';

import styles from './InvestorProfileControl.module.scss';

const useFetchInvestorProfile = useFetch(fetchInvestorProfile);
const useFetchProfileInvestmentProfile = useFetchAuth(fetchProfileInvestmentProfile);
const useFetchShowEsgWarning = useFetchAuth(fetchShowEsgWarning);

const initialEsg = [null, true];

const updateEsgToggle = (nextEsgToggle) => (esg) => {
	const [esgToggle] = esg;
	return nextEsgToggle !== esgToggle ? [nextEsgToggle, nextEsgToggle === null] : esg;
};

const updateEsgWarningDismissed = (esg) => {
	const [esgToggle, esgWarningDismissed] = esg;
	return !esgWarningDismissed ? [esgToggle, true] : esg;
};

const getProfileIndex = (profiles, profileId, defaultIndex = null) => {
	if (!profiles) {
		return defaultIndex;
	}

	for (let i = 0; i < profiles.length; i += 1) {
		if (profiles[i].id === profileId) {
			return i;
		}
	}

	return defaultIndex;
};

const getInvestorProfile = (profiles, profileId) => profiles.investorProfiles.filter(
	(investorProfile) => investorProfile.id === profileId,
)[0] ?? profiles.investorProfilesEsg?.filter(
	(investorProfile) => investorProfile.id === profileId,
)[0] ?? null;

const noop = () => {};

export default function InvestorProfileControl({
	isChild,
	portfolio,
	setCheckPortfolio,
}) {
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const trackGTMEvent = useTrackGTMEvent();
	const [user] = useUserContext();

	const [modalOpen, setModalOpen] = useState(false);
	const [[esgToggle, esgWarningDismissed], setEsg] = useState(initialEsg);
	const [selectedProfile, setSelectedProfile] = useState(null);
	const [notRecommendedModalOpen, setNotRecommendedModalOpen] = useState(false);
	const [changeWarningModalOpen, setChangeWarningModalOpen] = useState(false);
	const [error, setError] = useState(false);
	const [avatarModalOpen, setAvatarModalOpen] = useState(false);

	const [profiles, loading] = useFetchInvestorProfile({}, country, language);
	const [investmentProfile, loadingInvestmentProfile] = useFetchProfileInvestmentProfile({}, portfolio.id);
	const [showEsgWarning] = useFetchShowEsgWarning(false, filterChild(portfolio.child, user)?.id);
	const changeInvestorProfileAuth = useRequestAuth(changeInvestorProfile);

	const plannedProfile = portfolio.investorProfilePlannedId;
	const esgPlanned = !!profiles.investorProfilesEsg?.filter(({ id }) => id === plannedProfile)[0];

	const setEsgToggle = useCallback((nextEsgToggle) => {
		const nextRecommendedProfiles = nextEsgToggle
			? investmentProfile.recommendedProfilesEsg
			: investmentProfile.recommendedProfiles;
		const nextDefaultProfile = nextEsgToggle
			? investmentProfile.defaultProfileEsg
			: investmentProfile.defaultProfile;

		if (nextEsgToggle !== esgPlanned && (
			loadingInvestmentProfile
			|| !nextRecommendedProfiles
			|| nextRecommendedProfiles.length === 0
			|| !nextDefaultProfile
		)) {
			return;
		}

		setSelectedProfile(null);
		setEsg(updateEsgToggle(nextEsgToggle));
	}, [investmentProfile, loadingInvestmentProfile, esgPlanned]);
	const setEsgWarningDismissed = useCallback(() => setEsg(updateEsgWarningDismissed), []);

	const esgAvailable = esgPlanned || (
		!loadingInvestmentProfile
		&& !!investmentProfile.recommendedProfilesEsg
		&& investmentProfile.recommendedProfilesEsg.length > 0
		&& !!investmentProfile.defaultProfileEsg
	);
	const esgActive = esgAvailable && (esgToggle ?? esgPlanned);

	const investorProfiles = esgActive ? profiles.investorProfilesEsg : profiles.investorProfiles;
	const defaultProfile = (esgActive === esgPlanned ? plannedProfile : null)
		?? (esgActive ? investmentProfile.defaultProfileEsg : investmentProfile.defaultProfile);
	const recommendedProfiles = esgActive
		? investmentProfile.recommendedProfilesEsg
		: investmentProfile.recommendedProfiles;
	const currentProfile = selectedProfile ?? defaultProfile ?? null;
	const currentSlide = getProfileIndex(investorProfiles, currentProfile, 3);

	const setCurrentSlide = useCallback((profileId) => {
		const { id = null } = investorProfiles?.[profileId] ?? {};
		if (id === null || id === selectedProfile) {
			return;
		}

		setSelectedProfile(id);
		if (id !== plannedProfile && (recommendedProfiles === null || recommendedProfiles?.includes(id) === false)) {
			setNotRecommendedModalOpen(true);
		}
	}, [selectedProfile, plannedProfile, investorProfiles, recommendedProfiles]);

	if (loading || loadingInvestmentProfile) {
		return null;
	}

	const handleClose = (e) => {
		e?.preventDefault();
		setModalOpen(false);
		setEsg(initialEsg);
		setSelectedProfile(null);
		setNotRecommendedModalOpen(false);
		setChangeWarningModalOpen(false);
		setError(false);
	};

	return (
		<div className={styles.root}>
			<div className={styles.inputWrap}>
				<div className={styles.title}>
					{t('account.investorProfileControl.title')}
				</div>
				{portfolio.investorProfileClosedChange ? (
					<>
						<Button
							disabled
							isFlat
							label={t('account.investorProfileControl.button')}
							outline
						/>
						<span className={styles.titleIcon}>
							<Tooltip text={t('account.investorProfileControl.closedTooltip')}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 512 512"
									width={15}
								>
									<path
										fill="currentColor"
										d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
									/>
								</svg>
							</Tooltip>
						</span>
					</>
				) : (
					<>
						<Button
							disabled={isChild}
							isFlat
							label={t('account.investorProfileControl.button')}
							onClick={() => setModalOpen(true)}
							outline
						/>
						<Modal
							isVisible={modalOpen}
							onClose={() => handleClose()}
							size="large"
							title={t('account.investorProfileControl.modal.title')}
						>
							<Formik
								initialValues={{}}
								onSubmit={async () => {
									setError(false);
									try {
										await changeInvestorProfileAuth(portfolio.id, currentProfile);
									} catch {
										setError(true);
										return;
									}
									trackGTMEvent('interactions', {
										eventCategory: 'interactions',
										eventAction: 'buttonClick',
										eventLabel: 'zmenit_investicni_profil',
									});
									setCheckPortfolio(true);
								}}
							>
								{({
									handleSubmit,
									isSubmitting,
								}) => (
									<>
										<form>
											{error && (
												<Alert type="danger">
													{t('forms.error')}
												</Alert>
											)}
											{esgAvailable && (
												<div className={styles.toggleWrap}>
													<Toggle
														labelFirst={t('investorProfilePreview.classicPortfolios')}
														labelSecond={t('investorProfilePreview.esgPortfolios')}
														setToggle={setEsgToggle}
														toggle={esgActive}
														tooltipFirst={t('investorProfileChoice.tooltip.classic')}
														tooltipSecond={t('investorProfileChoice.tooltip.esg')}
													/>
												</div>
											)}
											<InvestorProfileDetailSlider
												currentSlide={currentSlide}
												products={profiles.products ?? []}
												profiles={investorProfiles ?? []}
												setCurrentSlide={modalOpen ? setCurrentSlide : noop}
											/>
											<InvestorProfileDetailSelection
												currentSlide={currentSlide}
												onSelectionChange={modalOpen ? setCurrentSlide : noop}
												profiles={investorProfiles ?? []}
												profilesRecommend={recommendedProfiles ?? null}
											/>
											<div className={styles.modalControl}>
												<Button
													label={t('account.investorProfileControl.modal.submit')}
													onClick={() => setChangeWarningModalOpen(true)}
												/>
												<div className={styles.modalClose}>
													<a
														href="#close"
														onClick={(e) => handleClose(e)}
													>
														{t('account.investorProfileControl.modal.cancel')}
													</a>
												</div>
											</div>
										</form>
										<Modal
											isVisible={showEsgWarning && esgActive && !esgWarningDismissed}
											onClose={setEsgWarningDismissed}
											title={t('general.esgWarningModal.title')}
										>
											<div className={styles.modal}>
												<p>{t('general.esgWarningModal.text')}</p>
												<div className={styles.modalFooter}>
													<Button
														label={t('general.esgWarningModal.button')}
														onClick={setEsgWarningDismissed}
													/>
												</div>
											</div>
										</Modal>
										<Modal
											isVisible={notRecommendedModalOpen}
											onClose={() => {
												setSelectedProfile(null);
												setNotRecommendedModalOpen(false);
											}}
											title={t('investorProfileChoice.notRecommended.title')}
										>
											<div className={styles.modal}>
												<Trans i18nKey="investorProfileChoice.notRecommended.clientMessage">
													<p className={styles.modalText} />
												</Trans>
												<Button
													label={t('investorProfileChoice.notRecommended.clientClose')}
													onClick={() => {
														setSelectedProfile(null);
														setNotRecommendedModalOpen(false);
													}}
												/>
											</div>
										</Modal>
										<Modal
											isVisible={changeWarningModalOpen}
											onClose={() => setChangeWarningModalOpen(false)}
											title={t('account.investorProfileControl.changeWarning.title')}
										>
											<div className={styles.modal}>
												<p>{t('account.investorProfileControl.changeWarning.subtitle')}</p>
												<div className={styles.modalFooter}>
													<Button
														disabled={isSubmitting}
														label={t('account.investorProfileControl.modal.submit')}
														onClick={handleSubmit}
													/>
												</div>
											</div>
										</Modal>
									</>
								)}
							</Formik>
						</Modal>
					</>
				)}
			</div>
			<div className={styles.inputWrap}>
				<div className={styles.imageWrap}>
					<img
						className={styles.image}
						src={`${process.env.PUBLIC_URL}/images/faces/${portfolio.faceImageFile}`}
						alt={portfolio.name}
						height={70}
					/>
					<button
						className={styles.changeWrap}
						onClick={() => setAvatarModalOpen(true)}
						type="button"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 18 18"
							width={18}
							height={18}
						>
							<path
								fill="#555"
								d="M18 16V2c0-1.1-.9-2-2-2H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2ZM5.5 10.5 8 13.51 11.5 9l4.5 6H2l3.5-4.5Z"
							/>
						</svg>
					</button>
				</div>
				<div className={styles.name}>
					{filterChild(portfolio.child, user) && (
						<span className={styles.childIcon}>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={20} height={25} viewBox="0 0 20 25">
								<path fill="#F29C39" d="M10.3 8.76A4.99 4.99 0 0 0 7 0a5 5 0 0 0-3.3 8.76A5.44 5.44 0 0 0 0 13.91V23c0 1.1.9 2 2 2h10a2 2 0 0 0 2-2v-9.09a5.44 5.44 0 0 0-3.7-5.15Z" opacity=".4" />
								<path fill="#F29C39" d="M16.5 14.37a3.5 3.5 0 1 0-4 0A4.44 4.44 0 0 0 9 18.7V23c0 1.1.9 2 2 2h7a2 2 0 0 0 2-2v-4.3a4.43 4.43 0 0 0-3.5-4.33Z" />
							</svg>
						</span>
					)}
					{getInvestorProfile(profiles, portfolio.investorProfileId)?.name}
					<span className={styles.titleIcon}>
						<Tooltip text={t('account.investorProfileControl.titleTooltip')}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512"
								width={15}
							>
								<path
									fill="currentColor"
									d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
								/>
							</svg>
						</Tooltip>
					</span>
				</div>
				<InvestorProfileAvatar
					avatarModalOpened={avatarModalOpen}
					esg={esgPlanned}
					portfolioId={portfolio.id}
					setAvatarModalOpened={setAvatarModalOpen}
					setCheckPortfolio={setCheckPortfolio}
				/>
			</div>
			{plannedProfile !== portfolio.investorProfileId && (
				<div className={styles.title}>
					{t('account.investorProfileControl.titlePlanned')}
					<div className={styles.name}>
						{filterChild(portfolio.child, user) && (
							<span className={styles.childIcon}>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={20} height={25} viewBox="0 0 20 25">
									<path fill="#F29C39" d="M10.3 8.76A4.99 4.99 0 0 0 7 0a5 5 0 0 0-3.3 8.76A5.44 5.44 0 0 0 0 13.91V23c0 1.1.9 2 2 2h10a2 2 0 0 0 2-2v-9.09a5.44 5.44 0 0 0-3.7-5.15Z" opacity=".4" />
									<path fill="#F29C39" d="M16.5 14.37a3.5 3.5 0 1 0-4 0A4.44 4.44 0 0 0 9 18.7V23c0 1.1.9 2 2 2h7a2 2 0 0 0 2-2v-4.3a4.43 4.43 0 0 0-3.5-4.33Z" />
								</svg>
							</span>
						)}
						{getInvestorProfile(profiles, plannedProfile)?.name}
						<span className={styles.titleIcon}>
							<Tooltip text={t('account.investorProfileControl.titlePlannedTooltip')}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 512 512"
									width={15}
								>
									<path
										fill="currentColor"
										d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
									/>
								</svg>
							</Tooltip>
						</span>
					</div>
				</div>
			)}
		</div>
	);
}

InvestorProfileControl.propTypes = {
	isChild: PropTypes.bool,
	portfolio: PropTypes.shape({
		child: PropTypes.shape({
			id: PropTypes.number.isRequired,
		}),
		faceImageFile: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
		investorProfileClosedChange: PropTypes.bool.isRequired,
		investorProfileId: PropTypes.number.isRequired,
		investorProfilePlannedId: PropTypes.number.isRequired,
		name: PropTypes.string,
	}).isRequired,
	setCheckPortfolio: PropTypes.func.isRequired,
};

InvestorProfileControl.defaultProps = {
	isChild: false,
};
