import { useLanguage } from '../contexts/LocaleContext';
import { format } from './number';

export default function useFormatNumber(defaultOptions = null) {
	const language = useLanguage();
	return defaultOptions !== null
		? (number, options = {}) => format(number, language, {
			...defaultOptions,
			...options,
		})
		: (number, options = {}) => format(number, language, options);
}
