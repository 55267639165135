import { useEffect } from 'react';
import { useFormikContext } from 'formik';

export default function OnChangeSubmit() {
	const { initialValues, submitForm, values } = useFormikContext();

	useEffect(() => {
		if (values !== initialValues) {
			submitForm();
		}
	}, [values, submitForm]);

	return null;
}
