import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import scrollToTheElementIfNeeded from '../../../utils/scrollToElementIfNeeded';

import styles from './Alert.module.scss';

export default function Alert({
	autoClose,
	autoScroll,
	close,
	children,
	type,
	onClose,
}) {
	const rootRef = useRef();
	const scroll = autoScroll ?? type === 'danger';

	useEffect(() => {
		if (autoClose && onClose) {
			const timer = setTimeout(() => {
				onClose();
			}, 5000);
			return () => clearTimeout(timer);
		}
		return undefined;
	}, []);

	useEffect(() => {
		if (scroll) {
			scrollToTheElementIfNeeded(rootRef.current);
		}
	}, [scroll]);

	return (
		<div ref={rootRef} className={`${styles.root} ${styles[type]} ${close ? styles.hasClose : ''}`.trim()}>
			{children}
			{close && onClose && (
				<button
					type="button"
					className={styles.close}
					data-dismiss="alert"
					aria-label="Close"
					onClick={onClose}
				>
					<span aria-hidden="true">
						&times;
					</span>
				</button>
			)}
		</div>
	);
}

Alert.propTypes = {
	autoClose: PropTypes.bool,
	autoScroll: PropTypes.bool,
	close: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	type: PropTypes.oneOf(['success', 'danger', 'warning']),
	onClose: PropTypes.func,
};

Alert.defaultProps = {
	autoClose: false,
	autoScroll: null,
	close: false,
	type: 'success',
	onClose: null,
};
