import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { FaqList, TaxQuestionnaire } from '../../components/common';
import { Container, Section } from '../../components/layout';
import { ExternalLink, Modal } from '../../components/ui';
import { useCountry, useLanguage } from '../../contexts/LocaleContext';
import { useUserContext } from '../../contexts/UserContext';
import { fetchFaqs, fetchTaxChildrenList, getProfileTaxStatement } from '../../utils/api';
import useFetchAuth from '../../utils/useFetchAuth';

import styles from './Taxes.module.scss';

const useFetchFaqs = useFetchAuth(fetchFaqs);
const useFetchTaxChildrenList = useFetchAuth(fetchTaxChildrenList);

export default function Taxes({ blogPostTaxesUrl }) {
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const [user] = useUserContext();
	const [info, setInfo] = useState(false);
	const [faqs] = useFetchFaqs(null, country, language, 'tax');
	const [childrenList] = useFetchTaxChildrenList(null, !!user?.child);
	const statements = [
		{ id: null, label: null },
		...(childrenList ?? []).map((child) => ({ id: child.id, label: ` ${child.firstName} ${child.lastName}` })),
	];

	return (
		<>
			<Section bordered condensed>
				<Container center variant="narrower">
					<Trans i18nKey="taxes.intro">
						<p className={styles.text}>
							<span className={styles.semibold} />
						</p>
					</Trans>
					<div className={styles.linkList}>
						{statements.map(({ id, label }) => (
							<div
								key={id}
								className={styles.linkWrap}
							>
								<svg width="43" height="52" viewBox="0 0 43 52" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M1.04053 3.76309C1.04053 2.25157 2.28258 1.02625 3.81472 1.02625H29.6889C30.4395 1.02625 31.158 1.32627 31.6805 1.85785L41.1773 11.5195C41.6792 12.0302 41.9599 12.7135 41.9599 13.4248V48.2368C41.9599 49.7483 40.7178 50.9736 39.1857 50.9736H3.81472C2.28258 50.9736 1.04053 49.7483 1.04053 48.2368V3.76309Z"
										fill="url(#paint0_linear_627_959)"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M3.81452 2.05263C2.85693 2.05263 2.08065 2.81846 2.08065 3.76316V48.2368C2.08065 49.1815 2.85693 49.9474 3.81452 49.9474H39.1855C40.1431 49.9474 40.9194 49.1815 40.9194 48.2368V13.4248C40.9194 12.9803 40.7439 12.5532 40.4302 12.2341L30.9335 2.57238C30.6069 2.24015 30.1578 2.05263 29.6887 2.05263H3.81452ZM0 3.76316C0 1.68483 1.70782 0 3.81452 0H29.6887C30.7207 0 31.7087 0.412537 32.4272 1.14345L41.9239 10.8051C42.6141 11.5072 43 12.4468 43 13.4248V48.2368C43 50.3152 41.2922 52 39.1855 52H3.81452C1.70782 52 0 50.3152 0 48.2368V3.76316Z"
										fill="#47202A"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M27.395 12.6579V1.36841H29.4757V12.6579C29.4757 13.2247 29.9414 13.6842 30.516 13.6842H41.6128V15.7368H30.516C28.7923 15.7368 27.395 14.3583 27.395 12.6579Z"
										fill="#47202A"
									/>
									<path
										d="M12.8269 37.8915H11.5585C11.3496 37.8915 11.2451 37.7884 11.2451 37.5823V28.7346C11.2451 28.5285 11.3496 28.4254 11.5585 28.4254H15.1996C15.9756 28.4254 16.5775 28.6364 17.0052 29.0584C17.433 29.4707 17.6469 30.0595 17.6469 30.825V32.2383C17.6469 33.0039 17.433 33.5976 17.0052 34.0197C16.5775 34.4319 15.9756 34.638 15.1996 34.638H13.3194C13.2 34.638 13.1403 34.6919 13.1403 34.7999V37.5823C13.1403 37.7884 13.0358 37.8915 12.8269 37.8915ZM13.3044 33.0039H14.8862C15.2046 33.0039 15.4284 32.9401 15.5577 32.8125C15.6871 32.6751 15.7517 32.4542 15.7517 32.15V30.9134C15.7517 30.5993 15.6871 30.3785 15.5577 30.2509C15.4284 30.1135 15.2046 30.0448 14.8862 30.0448H13.3044C13.195 30.0448 13.1403 30.0988 13.1403 30.2067V32.8419C13.1403 32.9499 13.195 33.0039 13.3044 33.0039Z"
										fill="url(#paint1_linear_627_959)"
									/>
									<path
										d="M21.2175 36.2721H22.8441C23.1624 36.2721 23.3862 36.2083 23.5156 36.0807C23.6549 35.9433 23.7245 35.7176 23.7245 35.4035V30.9134C23.7245 30.5993 23.6549 30.3785 23.5156 30.2509C23.3862 30.1135 23.1624 30.0448 22.8441 30.0448H21.2175C21.1081 30.0448 21.0533 30.0988 21.0533 30.2067V36.1101C21.0533 36.2181 21.1081 36.2721 21.2175 36.2721ZM19.1582 37.5823V28.7346C19.1582 28.5285 19.2626 28.4254 19.4716 28.4254H23.1574C23.9334 28.4254 24.5353 28.6364 24.9631 29.0584C25.4008 29.4707 25.6197 30.0595 25.6197 30.825V35.4918C25.6197 36.2574 25.4008 36.8511 24.9631 37.2731C24.5353 37.6854 23.9334 37.8915 23.1574 37.8915H19.4716C19.2626 37.8915 19.1582 37.7884 19.1582 37.5823Z"
										fill="url(#paint2_linear_627_959)"
									/>
									<path
										d="M28.9882 37.8915H27.7198C27.5109 37.8915 27.4064 37.7884 27.4064 37.5823V28.7346C27.4064 28.5285 27.5109 28.4254 27.7198 28.4254H32.4801C32.6791 28.4254 32.7785 28.5285 32.7785 28.7346V29.7356C32.7785 29.9417 32.6791 30.0448 32.4801 30.0448H29.4807C29.3613 30.0448 29.3016 30.0988 29.3016 30.2067V32.4886C29.3016 32.6064 29.3613 32.6653 29.4807 32.6653H32.0324C32.2413 32.6653 32.3458 32.7683 32.3458 32.9744V33.9608C32.3458 34.1767 32.2413 34.2846 32.0324 34.2846H29.4807C29.3613 34.2846 29.3016 34.3386 29.3016 34.4466V37.5823C29.3016 37.7884 29.1971 37.8915 28.9882 37.8915Z"
										fill="url(#paint3_linear_627_959)"
									/>
									<defs>
										<linearGradient
											id="paint0_linear_627_959"
											x1="1.04053"
											y1="25.6578"
											x2="41.9599"
											y2="25.6578"
											gradientUnits="userSpaceOnUse"
										>
											<stop stopColor="white" />
											<stop offset="1" stopColor="#FFF2DF" />
										</linearGradient>
										<linearGradient
											id="paint1_linear_627_959"
											x1="10.0566"
											y1="28.7368"
											x2="34.6557"
											y2="33.3023"
											gradientUnits="userSpaceOnUse"
										>
											<stop stopColor="#EA4D42" />
											<stop offset="1" stopColor="#F2870B" />
										</linearGradient>
										<linearGradient
											id="paint2_linear_627_959"
											x1="10.0566"
											y1="28.7368"
											x2="34.6557"
											y2="33.3023"
											gradientUnits="userSpaceOnUse"
										>
											<stop stopColor="#EA4D42" />
											<stop offset="1" stopColor="#F2870B" />
										</linearGradient>
										<linearGradient
											id="paint3_linear_627_959"
											x1="10.0566"
											y1="28.7368"
											x2="34.6557"
											y2="33.3023"
											gradientUnits="userSpaceOnUse"
										>
											<stop stopColor="#EA4D42" />
											<stop offset="1" stopColor="#F2870B" />
										</linearGradient>
									</defs>
								</svg>
								<ExternalLink
									className={styles.link}
									title={`${t('taxes.link')}${label ?? ''}`}
									to={getProfileTaxStatement(language, id)}
									download
								>
									{t('taxes.link')}
									{label}
								</ExternalLink>
							</div>
						))}
					</div>
				</Container>
			</Section>
			<Section bordered>
				<Container center variant="narrower">
					{country !== 'PL' && (
						<>
							<Trans i18nKey="taxes.title">
								<p className={`${styles.text} ${styles.semibold}`} />
							</Trans>
							<TaxQuestionnaire />
						</>
					)}
					<Trans i18nKey="taxes.text">
						<p className={styles.subText}>
							<a
								href="#modal"
								title={t('taxes.modal.title')}
								onClick={(e) => {
									e.preventDefault();
									setInfo(true);
								}}
							>
								{null}
							</a>
							<Link to="#faq" />
							{blogPostTaxesUrl ? <Link to={blogPostTaxesUrl} target="_blank" /> : <></>}
							<ExternalLink to={t('taxes.infoLink')} blank />
						</p>
					</Trans>
					<Modal
						isVisible={info}
						onClose={() => setInfo(false)}
						title={t('taxes.modal.title')}
						size="large"
					>
						<Trans i18nKey="taxes.modal.text">
							<p className={styles.modalText} />
							<ul className={styles.modalList}>
								<li className={styles.modalListItem} />
							</ul>
							<p className={styles.modalSubText}>
								<strong />
							</p>
							<p className={`${styles.modalSubText} ${styles.offset}`}>
								<strong />
							</p>
						</Trans>
					</Modal>
				</Container>
			</Section>
			{faqs && (
				<FaqList
					id="faq"
					scrollToOpen
					sectionRows
					sections={faqs.sections ?? []}
					spacy
				/>
			)}
		</>
	);
}

Taxes.propTypes = {
	blogPostTaxesUrl: PropTypes.string,
};

Taxes.defaultProps = {
	blogPostTaxesUrl: null,
};
