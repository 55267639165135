import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
	Alert,
	Button,
	Modal,
} from '../../../components/ui';
import { childOnboardingCloseAccount } from '../../../utils/api';
import useRequestAuth from '../../../utils/useRequestAuth';

import styles from './ChildOnboardingCancel.module.scss';

export default function ChildOnboardingCancel({
	childId,
	reloadChildrenList,
	reloadUser,
}) {
	const [t] = useTranslation();
	const [confirmCancel, setConfirmCancel] = useState(false);
	const [cancelError, setCancelError] = useState(false);
	const childOnboardingCloseAccountAuth = useRequestAuth(childOnboardingCloseAccount);

	const handleChildOnboardingCloseAccount = async () => {
		setCancelError(false);
		try {
			await childOnboardingCloseAccountAuth(childId);
		} catch {
			setCancelError(true);
			return;
		}
		setConfirmCancel(false);
		reloadChildrenList();
		reloadUser();
	};

	return (
		<div>
			<a
				className={styles.cancelLink}
				href="#childOnboardingClosure"
				onClick={(e) => {
					e.preventDefault();
					setConfirmCancel(true);
				}}
			>
				{t('account.onboardingClosure.cancel')}
			</a>
			<Modal
				isVisible={confirmCancel}
				onClose={() => {
					setConfirmCancel(false);
				}}
				title={t('account.onboardingClosure.title')}
			>
				<div className={styles.modal}>
					<p className={styles.modalText}>
						{t('account.onboardingClosure.text')}
					</p>
					{cancelError && (
						<Alert type="danger">
							{t('forms.error')}
						</Alert>
					)}
					<Button
						onClick={handleChildOnboardingCloseAccount}
						label={t('account.onboardingClosure.confirm')}
					/>
				</div>
			</Modal>
		</div>
	);
}

ChildOnboardingCancel.propTypes = {
	childId: PropTypes.number.isRequired,
	reloadChildrenList: PropTypes.func.isRequired,
	reloadUser: PropTypes.func.isRequired,
};
