/* global document */
import { useEffect } from 'react';

import useWindowSize from './useWindowSize';

export default function useScrollingOnBodyDisable(state) {
	const windowSize = useWindowSize();

	useEffect(() => {
		if (state) {
			const offset = windowSize.width !== null ? `${windowSize.width - document.body.clientWidth}px` : '';
			document.documentElement.style.overflow = 'hidden';
			document.body.style.overflow = 'hidden';
			document.body.style.paddingRight = offset;
		}

		return () => {
			document.documentElement.style.overflow = '';
			document.body.style.overflow = '';
			document.body.style.paddingRight = '';
		};
	}, [state]);
}
