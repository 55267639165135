import React from 'react';

import { Container } from '../../../components/layout';
import {
	ShimmerPill,
	ShimmerRect,
	ShimmerText,
	ShimmerTitle,
} from '../../../components/ui/Shimmer';

import styles from './BlogPost.module.scss';

export default function BlogPostSkeleton() {
	return (
		<article className={styles.root}>
			<Container variant="narrow">
				<header>
					<div className={styles.headerContent}>
						<div className={styles.date}>
							<ShimmerRect minWidth={77} height={26} gap={2} />
						</div>
						<ShimmerPill width={110} height={22} />
						<h1 className={styles.title}>
							<ShimmerTitle lines={2} gap={2} />
						</h1>
						<div className={styles.perex}>
							<ShimmerText lines={4} gap={2} />
						</div>
					</div>
				</header>
				<div className={`${styles.content} ${styles.contentSkeleton}`.trim()}>
					<ShimmerText lines={8} gap={2} />
				</div>
			</Container>
		</article>
	);
}
