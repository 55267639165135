import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import useFocusOnError from '../../../utils/useFocusOnError';
import { Col, Row } from '../../layout';
import TextField from '../TextField';

import styles from './DobField.module.scss';

export default function DobField({
	disabled,
	error,
	helper,
	id,
	isLabelHidden,
	label,
	name,
	onBlur,
	onChange,
	readonly,
	required,
	value,
	warning,
}) {
	const [t] = useTranslation();
	const [yearValue, monthValue, dayValue] = value ? value.split('-') : [];

	const elementRef = useRef();
	useFocusOnError(name, null, elementRef);

	useEffect(() => {
		if (onChange && !value) {
			const defaultDob = new Date();
			onChange(name, [defaultDob.getFullYear() - 18, defaultDob.getMonth() + 1, defaultDob.getDate()].join('-'));
		}
	}, []);

	const onItemChange = useCallback((e, item) => {
		if (!onChange) {
			return;
		}

		const tmp = [yearValue, monthValue, dayValue];
		tmp[item] = e.currentTarget.value;
		onChange(name, tmp.join('-'));
	}, [onChange, yearValue, monthValue, dayValue]);

	const onYearChange = useCallback((e) => onItemChange(e, 0), [onItemChange]);
	const onMonthChange = useCallback((e) => onItemChange(e, 1), [onItemChange]);
	const onDayChange = useCallback((e) => onItemChange(e, 2), [onItemChange]);

	return (
		<div className={`${styles.root} ${error && styles.rootError}`.trim()}>
			<div className={`${styles.labelWrap} ${isLabelHidden ? styles.labelWrapHidden : ''} ${disabled ? styles.disabled : ''}`.trim()}>
				<label className={styles.label} htmlFor={id}>{label}</label>
				{helper && <div className={styles.helper}>{helper}</div>}
			</div>
			<div ref={elementRef} className={styles.inputWrap}>
				<Row>
					<Col xs={4}>
						<TextField
							disabled={disabled}
							id={`${id}-day`}
							label={t('forms.fields.birthDate.day.label')}
							name={`${name}-day`}
							noError
							onBlur={onBlur}
							onChange={onDayChange}
							readonly={readonly}
							required={required}
							type="number"
							value={dayValue}
						/>
					</Col>
					<Col xs={4}>
						<TextField
							disabled={disabled}
							id={`${id}-month`}
							label={t('forms.fields.birthDate.month.label')}
							name={`${name}-month`}
							noError
							onBlur={onBlur}
							onChange={onMonthChange}
							readonly={readonly}
							required={required}
							type="number"
							value={monthValue}
						/>
					</Col>
					<Col xs={4}>
						<TextField
							disabled={disabled}
							id={`${id}-year`}
							label={t('forms.fields.birthDate.year.label')}
							name={`${name}-year`}
							noError
							onBlur={onBlur}
							onChange={onYearChange}
							readonly={readonly}
							required={required}
							type="number"
							value={yearValue}
						/>
					</Col>
				</Row>
			</div>
			{error && (
				<div className={styles.error}>{error}</div>
			)}
			{warning && (
				<div className={styles.warning}>{warning}</div>
			)}
		</div>
	);
}

DobField.propTypes = {
	disabled: PropTypes.bool,
	error: PropTypes.string,
	helper: PropTypes.string,
	id: PropTypes.string.isRequired,
	isLabelHidden: PropTypes.bool,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	readonly: PropTypes.bool,
	required: PropTypes.bool,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	warning: PropTypes.string,
};

DobField.defaultProps = {
	disabled: false,
	error: '',
	helper: '',
	isLabelHidden: false,
	onBlur: null,
	onChange: null,
	readonly: false,
	required: false,
	value: undefined,
	warning: '',
};
