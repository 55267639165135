import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export default function initTranslator(translations, initialLanguage = null) {
	const languages = Object.keys(translations);

	i18n
		.use(initReactI18next)
		.init({
			lng: initialLanguage ?? languages[0],
			fallbackLng: languages[0],
			interpolation: {
				escapeValue: false,
			},
			resources: translations,
			supportedLngs: languages,
		});

	return i18n;
}
