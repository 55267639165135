/* global document */
import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import styles from './Tooltip.module.scss';

export default function Tooltip({
	children,
	text,
	touch,
	trigger,
}) {
	return (
		<Tippy
			content={text}
			interactive
			className={styles.tippy}
			touch={touch}
			trigger={trigger}
			appendTo={document.body}
		>
			{typeof children === 'string'
				? (<span>{children}</span>)
				: children}
		</Tippy>
	);
}

Tooltip.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	text: PropTypes.oneOfType([
		PropTypes.shape(),
		PropTypes.string,
	]).isRequired,
	touch: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.string,
	]),
	trigger: PropTypes.string,
};

Tooltip.defaultProps = {
	touch: true,
	trigger: 'mouseenter focus',
};
