import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { useCountry } from '../../../contexts/LocaleContext';
import { childEmailVerification, childEmailVerificationCancel, saveChildPersonalInformation } from '../../../utils/api';
import useRequestAuth from '../../../utils/useRequestAuth';
import {
	validateChildInformation,
	validatePhoneWarning,
} from '../../../utils/validators';
import { CheckboxField, PhoneField, TextField } from '../../forms';
import { Alert, Button } from '../../ui';

import styles from './ChildPersonalInformationForm.module.scss';

export default function ChildPersonalInformationForm({
	child,
	confirm,
	reloadChildrenList,
}) {
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [confirmationCode, setConfirmationCode] = useState('');
	const [t] = useTranslation();
	const country = useCountry();
	const saveChildPersonalInformationAuth = useRequestAuth(saveChildPersonalInformation);
	const childEmailVerificationAuth = useRequestAuth(childEmailVerification);
	const childEmailVerificationCancelAuth = useRequestAuth(childEmailVerificationCancel);

	const initialValues = {
		email: child.email ?? child.emailPlanned ?? '@',
		phonePrefix: child.phonePrefix ?? {
			CZ: '+420',
			PL: '+48',
			SK: '+421',
		}[country] ?? '+420',
		phoneNumber: child.phoneNumber ?? '',
		...(confirm ? { confirm: false } : {}),
	};

	const handleEmailVerification = async () => {
		setError(false);
		setSubmitting(true);
		try {
			await childEmailVerificationAuth(child.id, confirmationCode);
		} catch {
			setError(true);
			return;
		} finally {
			setSubmitting(false);
		}
		reloadChildrenList();
	};

	const handleCancelEmailVerification = async () => {
		setError(false);
		setSubmitting(true);
		try {
			await childEmailVerificationCancelAuth(child.id);
		} catch {
			setError(true);
			return;
		} finally {
			setSubmitting(false);
		}
		reloadChildrenList();
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={async (values, { setErrors }) => {
				setError(false);
				setSuccess(false);
				try {
					await saveChildPersonalInformationAuth(
						child.id,
						values.email,
						values.phonePrefix,
						values.phoneNumber,
					);
					reloadChildrenList();
					setSuccess(true);
				} catch (e) {
					const fieldErrors = {};
					const errorMessage = e.responseJson?.message;
					if (typeof errorMessage === 'string') {
						if (errorMessage.indexOf('e-mail uniqueness') !== -1) {
							fieldErrors.email = 'forms.fields.email.unique';
						}
						if (errorMessage.indexOf('phone prefix validity') !== -1) {
							fieldErrors.phoneNumber = 'forms.fields.phoneNumber.invalid';
						}
					}

					const hasFieldErrors = Object.keys(fieldErrors).length > 0;
					if (hasFieldErrors) {
						setErrors(fieldErrors);
					}
					setError(!hasFieldErrors);
				}
			}}
			validate={validateChildInformation}
		>
			{({
				errors,
				handleBlur,
				handleChange,
				handleSubmit,
				isSubmitting,
				setFieldValue,
				touched,
				values,
			}) => (
				<form onSubmit={handleSubmit}>
					{error && (
						<Alert type="danger">
							{t('forms.error')}
						</Alert>
					)}
					<PhoneField
						error={(
							errors.phonePrefix
							&& touched.phonePrefix
							&& t(errors.phonePrefix)
						) || (
							errors.phoneNumber
							&& touched.phoneNumber
							&& t(errors.phoneNumber)
						)}
						disabled={child.phonePrefix !== null && child.phoneNumber !== null}
						helper={t('forms.fields.phoneNumber.helper')}
						id={`childPersonalInformationPhoneNumber-${child.id}`}
						label={t('forms.fields.phoneNumber.label')}
						name="phoneNumber"
						onBlur={handleBlur}
						onChange={setFieldValue}
						phonePrefixId={`childPersonalInformationPhonePrefix-${child.id}`}
						phonePrefixName="phonePrefix"
						phonePrefixValue={values.phonePrefix}
						readonly={child.phonePrefix !== null && child.phoneNumber !== null}
						required
						value={values.phoneNumber}
						warning={(
							(child.phonePrefix === null || child.phoneNumber === null)
							&& values.phoneNumber !== ''
							&& !validatePhoneWarning(values.phonePrefix, values.phoneNumber)
						) ? t('forms.fields.phoneNumber.warning') : ''}
					/>
					<TextField
						autoComplete="email"
						disabled={child.email !== null}
						error={
							errors.email
							&& touched.email
							&& t(errors.email)
						}
						id={`childPersonalInformationEmail-${child.id}`}
						label={t('forms.fields.email.label')}
						name="email"
						onBlur={handleBlur}
						onChange={handleChange}
						readonly={child.email !== null}
						required
						type="email"
						value={values.email}
					/>
					{child.emailPlanned && (
						<div className={styles.codeWrap}>
							<TextField
								id="confirmationCode"
								label={t('onboarding.steps.childEmailVerification.form.code.label')}
								name="confirmationCode"
								onBlur={handleBlur}
								onChange={(e) => {
									setConfirmationCode(e.target.value);
								}}
								type="text"
								value={confirmationCode}
								required
							/>
							<div className={styles.codeButtonWrap}>
								<Button
									disabled={isSubmitting || submitting || confirmationCode === ''}
									onClick={handleEmailVerification}
									label={t('onboarding.steps.childEmailVerification.form.code.confirm')}
									isFlat
								/>
								<Button
									disabled={isSubmitting || submitting}
									onClick={handleCancelEmailVerification}
									label={t('onboarding.steps.childEmailVerification.form.code.cancel')}
									isFlat
									outline
								/>
							</div>
						</div>
					)}
					{confirm && (
						<CheckboxField
							checked={values.confirm}
							error={
								errors.confirm
								&& touched.confirm
								&& t(errors.confirm)
							}
							id="confirm"
							label={t('childAgeCheck.modal.checkbox')}
							name="confirm"
							onBlur={handleBlur}
							onChange={handleChange}
							required
						/>
					)}
					{success && (
						<Alert
							autoClose
							close
							onClose={() => setSuccess(false)}
							type="success"
						>
							{child.email === null && values.email !== null
								? t('onboarding.steps.childEmailVerification.text')
								: t('forms.success')}
						</Alert>
					)}
					<div className={styles.buttonWrap}>
						<Button
							disabled={isSubmitting || submitting || (
								(child.email !== null || values.email === child.emailPlanned)
								&& child.phonePrefix !== null
								&& child.phoneNumber !== null
							)}
							isSubmit
							isFlat
							label={t('account.childList.personalInformation.confirm')}
						/>
					</div>
				</form>
			)}
		</Formik>
	);
}

ChildPersonalInformationForm.propTypes = {
	child: PropTypes.shape({
		email: PropTypes.string,
		emailPlanned: PropTypes.string,
		id: PropTypes.number.isRequired,
		phoneNumber: PropTypes.string,
		phonePrefix: PropTypes.string,
	}).isRequired,
	confirm: PropTypes.bool,
	reloadChildrenList: PropTypes.func.isRequired,
};

ChildPersonalInformationForm.defaultProps = {
	confirm: false,
};
