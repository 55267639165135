import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ButtonLink } from '../../../components/ui';

import styles from '../Products.module.scss';

export default function EsgCard({
	esgProductUrl,
}) {
	const [t] = useTranslation();

	return (
		<div className={styles.content}>
			<div className={styles.icon}>
				<svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} viewBox="0 0 32 32" fill="none">
					<g clipPath="url(#esg-other-product-a)">
						<path fill="#AECC53" fillRule="evenodd" d="M.732 16.028c0-8.42 6.85-15.27 15.27-15.27s15.27 6.85 15.27 15.27-6.85 15.27-15.27 15.27-15.27-6.85-15.27-15.27Zm15.27 11.239c-6.199 0-11.24-5.042-11.24-11.239 0-6.196 5.041-11.24 11.24-11.24 6.2 0 11.241 5.041 11.241 11.24 0 6.2-5.044 11.239-11.24 11.239Z" clipRule="evenodd" />
						<path fill="#AECC53" d="M9.091 20.402C7.337 10.737 14.01 7.519 24.106 8.807c-2.813 2.278-2.738 2.9-3.448 5.598-.669 2.543-2.918 7.716-11.567 5.997Z" />
						<path fill="#629218" d="M8.89 23.608c.994-4.052 3.474-10.03 10.866-13.18-3.86.536-9.093 3.842-11.768 12.8l.901.38Z" />
					</g>
					<defs>
						<clipPath id="esg-other-product-a">
							<path fill="#fff" d="M0 0h32v32H0z" />
						</clipPath>
					</defs>
				</svg>
			</div>
			<p className={styles.subTitle}>
				{t('products.list.esg.title')}
			</p>
			<p className={styles.text}>
				{t('products.list.esg.text')}
			</p>
			<ButtonLink
				label={t('products.list.esg.button')}
				to={esgProductUrl}
				outline
			/>
		</div>
	);
}

EsgCard.propTypes = {
	esgProductUrl: PropTypes.string.isRequired,
};
