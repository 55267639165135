import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { TextField } from '../../../../components/forms';
import { Button } from '../../../../components/ui';
import { validateInvestingPortfolioIncome } from '../../../../utils/validators';

import styles from './InvestingPortfolioIncome.module.scss';

const MIN_INCOME = 1000;
const MAX_INCOME = 1000000000;

export default function InvestingPortfolioIncome({
	defaultIncome,
	onSubmit,
}) {
	const [t] = useTranslation();

	return (
		<Formik
			initialValues={{
				income: defaultIncome,
			}}
			onSubmit={async ({ income }) => onSubmit(income)}
			validate={(values) => validateInvestingPortfolioIncome(values, MIN_INCOME, MAX_INCOME)}
		>
			{({
				errors,
				handleBlur,
				handleChange,
				handleSubmit,
				touched,
				values,
			}) => (
				<form className={styles.form} onSubmit={handleSubmit}>
					<div className={styles.inputWrap}>
						<p className={styles.label}>
							{t('investingPortfolio.income.label')}
						</p>
						<div className={styles.input}>
							<TextField
								error={
									errors.income
									&& touched.income
									&& t(errors.income)
								}
								id="income"
								label={t('investingPortfolio.income.label')}
								max={1000000000}
								min={1000}
								name="income"
								onBlur={handleBlur}
								onChange={handleChange}
								type="number"
								unit="Kč"
								value={values.income}
								isLabelHidden
								required
							/>
						</div>
					</div>
					<Button
						disabled={!values.income}
						label={t('investingPortfolio.income.button')}
						onClick={(e) => e.currentTarget.blur()}
						variant="orange"
						isSubmit
					/>
				</form>
			)}
		</Formik>
	);
}

InvestingPortfolioIncome.propTypes = {
	defaultIncome: PropTypes.number,
	onSubmit: PropTypes.func,
};

InvestingPortfolioIncome.defaultProps = {
	defaultIncome: null,
	onSubmit: null,
};
