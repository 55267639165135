import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import {
	getLoginUrlApple,
	getLoginUrlFacebook,
	getLoginUrlGoogle,
	getLoginUrlLinkedIn,
} from '../../../utils/api';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import { ExternalLink } from '../../ui';

import styles from './SocialLogin.module.scss';

export default function SocialLogin({ isRegistration }) {
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const trackGTMEvent = useTrackGTMEvent();

	return (
		<div className={styles.root}>
			<p className={styles.title}>
				{t(`${isRegistration ? 'register' : 'login'}.social.title`)}
			</p>
			<div className={styles.row}>
				<ExternalLink
					className={styles.button}
					onClick={() => {
						trackGTMEvent('interactions', {
							eventCategory: 'interactions',
							eventAction: 'buttonClick',
							eventLabel: isRegistration ? 'social_registration_facebook' : 'social_login_facebook',
						});
					}}
					to={getLoginUrlFacebook(country, language)}
				>
					<span className={styles.icon}>
						<svg
							viewBox="0 0 1024 1024"
							width={18}
							height={18}
							xmlns="http://www.w3.org/2000/svg"
							fillRule="evenodd"
							clipRule="evenodd"
							strokeLinejoin="round"
							strokeMiterlimit="2"
						>
							<path
								d="M1024 512C1024 229.23 794.77 0 512 0S0 229.23 0 512c0 255.554 187.231 467.37 432 505.78V660H302V512h130V399.2C432 270.88 508.438 200 625.39 200 681.407 200 740 210 740 210v126h-64.562C611.835 336 592 375.467 592 415.957V512h142l-22.7 148H592v357.78c244.769-38.41 432-250.226 432-505.78z"
								fill="#1877f2"
							/>
							<path
								d="M711.3 660L734 512H592v-96.043c0-40.49 19.835-79.957 83.438-79.957H740V210s-58.593-10-114.61-10C508.438 200 432 270.88 432 399.2V512H302v148h130v357.78a515.834 515.834 0 0080 6.22c27.216 0 53.933-2.13 80-6.22V660h119.3z"
								fill="#fff"
							/>
						</svg>
					</span>
					Facebook
				</ExternalLink>
				<ExternalLink
					className={styles.button}
					onClick={() => {
						trackGTMEvent('interactions', {
							eventCategory: 'interactions',
							eventAction: 'buttonClick',
							eventLabel: isRegistration ? 'social_registration_linkedin' : 'social_login_linkedin',
						});
					}}
					to={getLoginUrlLinkedIn(country, language)}
				>
					<span className={styles.icon}>
						<svg
							viewBox="0 0 1024 1024"
							width={18}
							height={18}
							xmlns="http://www.w3.org/2000/svg"
							fillRule="evenodd"
							clipRule="evenodd"
							strokeLinejoin="round"
							strokeMiterlimit="2"
						>
							<circle cx="56.098" cy="56.097" r="56.098" fill="#007ab9" transform="translate(0 .008) scale(9.12689)" />
							<path
								d="M817.915 553.198v211.087H695.533v-196.94c0-49.45-17.67-83.22-61.98-83.22-33.816 0-53.904 22.736-62.776 44.75-3.221 7.867-4.052 18.792-4.052 29.827v205.574H444.334s1.642-333.552 0-368.079h122.4v52.16c-.246.411-.593.813-.812 1.205h.812v-1.205c16.264-25.025 45.27-60.803 110.299-60.803 80.517 0 140.882 52.608 140.882 165.644zM316.301 218.771c-41.865 0-69.254 27.48-69.254 63.587 0 35.339 26.595 63.614 67.648 63.614h.794c42.687 0 69.228-28.275 69.228-63.614-.813-36.106-26.541-63.587-68.416-63.587zm-61.98 545.514h122.346V396.197H254.32v368.088z"
								fill="#f1f2f2"
								fillRule="nonzero"
							/>
						</svg>
					</span>
					LinkedIn
				</ExternalLink>
				<ExternalLink
					className={styles.button}
					onClick={() => {
						trackGTMEvent('interactions', {
							eventCategory: 'interactions',
							eventAction: 'buttonClick',
							eventLabel: isRegistration ? 'social_registration_google' : 'social_login_google',
						});
					}}
					to={getLoginUrlGoogle(country, language)}
				>
					<span className={styles.icon}>
						<svg
							viewBox="0 0 18 18"
							width={18}
							height={18}
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g clipPath="url(#google-social-logo)">
								<path fill="#4285F4" d="M18 9.2a7.2 7.2 0 0 0-.2-1.84H9.19v3.34h5.06c-.1.82-.65 2.07-1.87 2.9l-.02.12 2.73 2.06.19.02A8.68 8.68 0 0 0 18 9.2Z" />
								<path fill="#34A853" d="M9.18 17.97c2.48 0 4.56-.8 6.09-2.17l-2.9-2.2c-.78.54-1.82.9-3.19.9a5.52 5.52 0 0 1-5.22-3.73l-.1.01-2.84 2.15-.04.1a9.2 9.2 0 0 0 8.2 4.94Z" />
								<path fill="#FBBC05" d="M3.96 10.77a5.41 5.41 0 0 1-.01-3.55v-.11L1.06 4.93l-.09.04a8.82 8.82 0 0 0 0 8.06l2.98-2.26Z" />
								<path fill="#EB4335" d="M9.18 3.5c1.73 0 2.9.72 3.55 1.33l2.6-2.47A8.94 8.94 0 0 0 9.18.03a9.2 9.2 0 0 0-8.2 4.94l2.97 2.25A5.54 5.54 0 0 1 9.18 3.5Z" />
							</g>
							<defs>
								<clipPath id="google-social-logo">
									<path fill="#fff" d="M0 0h18v18H0z" />
								</clipPath>
							</defs>
						</svg>
					</span>
					Google
				</ExternalLink>
				<ExternalLink
					className={styles.button}
					onClick={() => {
						trackGTMEvent('interactions', {
							eventCategory: 'interactions',
							eventAction: 'buttonClick',
							eventLabel: isRegistration ? 'social_registration_apple' : 'social_login_apple',
						});
					}}
					to={getLoginUrlApple(country, language)}
				>
					<span className={styles.icon}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							width={18}
							height={18}
							viewBox="0 0 16 19"
						>
							<path fill="#000" d="M10.88 3.04a3.7 3.7 0 0 1-2.93 1.34A3.92 3.92 0 0 1 9 1.45C9.7.63 10.92.05 11.9 0a4.17 4.17 0 0 1-1.02 3.04ZM11.9 4.6c-1.61-.1-3 .88-3.77.88s-1.95-.84-3.23-.82A4.8 4.8 0 0 0 .83 7.04c-1.74 2.9-.45 7.2 1.23 9.55.83 1.17 1.82 2.45 3.12 2.4 1.23-.04 1.72-.77 3.21-.77 1.5 0 1.93.78 3.24.76 1.35-.03 2.2-1.17 3.02-2.34A10.08 10.08 0 0 0 16 13.95c-.02-.02-2.6-.97-2.63-3.85-.02-2.4 2.04-3.55 2.13-3.62a4.67 4.67 0 0 0-3.6-1.88Z" />
						</svg>
					</span>
					Apple
				</ExternalLink>
			</div>
			<div className={`${styles.line} ${isRegistration ? '' : styles.lineTop}`.trim()}>
				<span>{t(`${isRegistration ? 'register' : 'login'}.social.or`)}</span>
			</div>
		</div>
	);
}

SocialLogin.propTypes = {
	isRegistration: PropTypes.bool,
};

SocialLogin.defaultProps = {
	isRegistration: false,
};
