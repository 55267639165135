import { useContext, useEffect, useRef } from 'react';
import { FormikContext } from 'formik';

import scrollToTheElementIfNeeded from './scrollToElementIfNeeded';

export default function useFocusOnError(name, fieldRef = null, elementRef = fieldRef) {
	const formik = useContext(FormikContext);
	const initialRef = useRef(true);
	const formikRef = useRef(formik);
	const nameRef = useRef(name);

	useEffect(() => {
		formikRef.current = formik;
	}, [formik]);

	useEffect(() => {
		nameRef.current = name;
	}, [name]);

	const isSubmitting = formik?.isSubmitting === true;

	useEffect(() => {
		if (!formikRef.current) {
			return;
		}

		if (isSubmitting || initialRef.current) {
			initialRef.current = false;
			return;
		}

		const firstErrorKey = Object.keys(formikRef.current.errors)[0];
		if (nameRef.current !== firstErrorKey) {
			return;
		}

		scrollToTheElementIfNeeded(elementRef.current);
		if (fieldRef !== null) {
			setTimeout(() => fieldRef.current?.focus(), 100);
		}
	}, [isSubmitting]);
}
