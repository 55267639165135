import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
	Alert,
	Button,
	Modal,
} from '../../../components/ui';
import { invalidateUserDeviceToken } from '../../../utils/api';
import useRequestAuth from '../../../utils/useRequestAuth';

import styles from './DeviceTokens.module.scss';

export default function DeviceTokens({
	deviceData,
	reloadTokens,
}) {
	const invalidateUserDeviceTokenAuth = useRequestAuth(invalidateUserDeviceToken);
	const [error, setError] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);
	const [t] = useTranslation();

	const invalidateToken = async (tokenId) => {
		try {
			setDisabled(true);
			const result = await invalidateUserDeviceTokenAuth(tokenId);
			if (result.success) {
				setError(false);
				reloadTokens();
			}
		} catch (e) {
			setError(true);
		} finally {
			setDisabled(false);
		}
	};

	return (
		<div className={styles.root}>
			<Button
				onClick={() => {
					setModalOpen(true);
				}}
				label={t('account.deviceTokens.button')}
				outline
			/>
			<Modal
				isVisible={isModalOpen}
				onClose={() => {
					setError(false);
					setModalOpen(false);
				}}
				size="large"
				title={t('account.deviceTokens.modal.title')}
			>
				<div className={styles.modal}>
					{error && (
						<Alert type="danger">
							{t('forms.error')}
						</Alert>
					)}
					<p className={styles.modalText}>
						{t('account.deviceTokens.modal.text')}
					</p>
					<div className={styles.tableWrap}>
						<table className={styles.table}>
							<thead>
								<tr>
									<th>
										{t('account.deviceTokens.modal.table.device')}
									</th>
									<th>
										{t('account.deviceTokens.modal.table.active')}
									</th>
								</tr>
							</thead>
							<tbody>
								{deviceData.map((device) => (
									<tr key={device.tokenId}>
										<td>{device.deviceModel}</td>
										<td>{device.lastUsedAt}</td>
										<td className={styles.right}>
											<Button
												disabled={disabled}
												label={t('account.deviceTokens.modal.disconnect')}
												isFlat
												isNarrow
												isSmall
												onClick={() => {
													invalidateToken(device.tokenId);
												}}
												outline
											/>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</Modal>
		</div>
	);
}

DeviceTokens.propTypes = {
	deviceData: PropTypes.arrayOf(PropTypes.shape({
		deviceModel: PropTypes.string.isRequired,
		lastUsedAt: PropTypes.string.isRequired,
		tokenId: PropTypes.number.isRequired,
	}).isRequired).isRequired,
	reloadTokens: PropTypes.func.isRequired,
};
