import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { toInt } from '../../../utils/number';

import styles from './InvestorProfileDetail.module.scss';

export default function InvestorProfileDetailSelection({
	currentSlide,
	onSelectionChange,
	profiles,
	profilesRecommend,
}) {
	const [t] = useTranslation();
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [sliderVal, setSliderVal] = useState(currentSlide);
	const [touchState, setTouchState] = useState(null);

	useEffect(() => {
		setSliderVal(currentSlide);
	}, [currentSlide]);

	useEffect(() => {
		if (touchState === 'up') {
			onSelectionChange(sliderVal);
		}
	}, [touchState]);

	const profilesRecommendIndexes = profilesRecommend ? profilesRecommend.map((profileRecommend) => {
		const profileRecommendId = profiles.filter(({ id }) => id === profileRecommend);
		return profileRecommendId.length ? profiles.indexOf(profileRecommendId[0]) : -1;
	}).filter((index) => index >= 0) : [];

	const barWidthClass = profilesRecommendIndexes.length > 0
		? styles[`rangerRecommendBar${Math.max(...profilesRecommendIndexes) - Math.min(...profilesRecommendIndexes) + 1}`] ?? ''
		: '';
	const barOffsetClass = profilesRecommendIndexes.length > 0
		? styles[`rangerRecommendBarOffset${Math.min(...profilesRecommendIndexes)}`] ?? ''
		: '';

	const handleDropdownChange = (index) => {
		onSelectionChange(index);
		setDropdownOpen(false);
	};

	return (
		<>
			<div className={styles.ranger}>
				<div
					className={styles.rangerTitle}
					title={t('investorProfileDetail.ranger.info')}
				>
					{t('investorProfileDetail.ranger.less1')}
					<br />
					{t('investorProfileDetail.ranger.less2')}
				</div>
				<div className={styles.rangerWrap}>
					<div className={styles.rangerSeparators}>
						{profilesRecommend && profilesRecommendIndexes.length > 0 && (
							<div className={styles.rangerRecommend}>
								<div className={`${styles.rangerRecommendBar} ${barWidthClass} ${barOffsetClass}`.trim()} />
							</div>
						)}
						{profiles.map((profile) => (
							<span key={`separator${profile.id}`} />
						))}
					</div>
					<input
						type="range"
						min="0"
						max={profiles.length - 1}
						value={String(sliderVal)}
						onChange={(e) => setSliderVal(toInt(e.currentTarget.value))}
						onMouseDown={() => setTouchState('down')}
						onTouchStart={() => setTouchState('down')}
						onMouseUp={() => setTouchState('up')}
						onTouchEnd={() => setTouchState('up')}
						onKeyDown={() => setTouchState('down')}
						onKeyUp={() => setTouchState('up')}
						onWheel={(e) => e.preventDefault()}
						className={styles.rangerInput}
					/>
				</div>
				<div
					className={styles.rangerTitle}
					title={t('investorProfileDetail.ranger.info')}
				>
					{t('investorProfileDetail.ranger.more1')}
					<br />
					{t('investorProfileDetail.ranger.more2')}
				</div>
			</div>
			<div className={`${styles.dropdown} ${dropdownOpen ? styles.isDropdownOpen : ''}`.trim()}>
				<button type="button" className={styles.dropdownTrigger} onClick={() => setDropdownOpen(true)}>
					{profiles[currentSlide]?.name}
				</button>
				<svg viewBox="0 0 512 512" width={18} height={18} xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
					<path d="M386.235 248.308L140.902 2.975c-4.267-4.053-10.987-3.947-15.04.213a10.763 10.763 0 000 14.827l237.76 237.76-237.76 237.867c-4.267 4.053-4.373 10.88-.213 15.04 4.053 4.267 10.88 4.373 15.04.213l.213-.213 245.333-245.333a10.624 10.624 0 000-15.041z" fill="#47202a" fillRule="nonzero" stroke="#47202a" />
				</svg>
				<div className={styles.dropdownList}>
					{profiles.map((profile, index) => (
						<button
							key={profile.id}
							type="button"
							className={`${styles.dropdownListItem} ${currentSlide === index ? styles.isItemActive : ''} ${profilesRecommend && profilesRecommend.indexOf(profile.id) > -1 ? styles.isItemRecommended : ''}`.trim()}
							onClick={() => handleDropdownChange(index)}
						>
							{profile.name}
							{' '}
							{t('investorProfileDetail.investorPostfix')}
						</button>
					))}
				</div>
			</div>
			{profilesRecommend && profilesRecommendIndexes.length > 0 && (
				<>
					<p className={styles.rangerRecommendInfo}>
						<Trans i18nKey="investorProfileDetail.ranger.recommend">
							<strong />
						</Trans>
					</p>
					<p className={styles.rangerRecommendInfo}>
						{t('investorProfileDetail.ranger.recommendWarning')}
					</p>
				</>
			)}
		</>
	);
}

InvestorProfileDetailSelection.propTypes = {
	currentSlide: PropTypes.number.isRequired,
	onSelectionChange: PropTypes.func.isRequired,
	profiles: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
		}),
	).isRequired,
	profilesRecommend: PropTypes.arrayOf(PropTypes.number.isRequired),
};

InvestorProfileDetailSelection.defaultProps = {
	profilesRecommend: null,
};
