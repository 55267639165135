import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';

import useFormatNumber from '../../../../utils/useFormatNumber';

import styles from './InvestingPortfolioChart.module.scss';

const chartOptions = (colors, labels, formatLabel, formatTooltip, setActiveChartItem) => ({
	chart: {
		events: setActiveChartItem !== null ? {
			dataPointSelection: (event, chartContext, { dataPointIndex }) => {
				setActiveChartItem(dataPointIndex);
			},
		} : {},
	},
	colors,
	dataLabels: {
		formatter: formatLabel,
	},
	labels,
	legend: {
		show: false,
	},
	plotOptions: {
		pie: {
			expandOnClick: false,
		},
	},
	responsive: [
		{
			breakpoint: 4000,
			options: {
				chart: {
					height: 370,
					width: 350,
				},
			},
		},
		{
			breakpoint: 992,
			options: {
				chart: {
					height: 330,
					width: 300,
				},
			},
		},
		{
			breakpoint: 576,
			options: {
				chart: {
					height: 310,
					width: 260,
				},
				tooltip: {
					enabled: false,
				},
			},
		},
	],
	tooltip: {
		enabledOnSeries: [0],
		y: {
			formatter: formatTooltip,
		},
	},
});

export default function InvestingPortfolioChart({
	data,
	setCurrentSlide,
	values,
}) {
	const formatPercent = useFormatNumber({ style: 'percent', fractionDigits: 0 });
	const formatLabel = (value) => (value >= 3.5 ? formatPercent(value / 100) : '');
	const formatTooltip = (value) => (value !== undefined ? formatPercent(value / 100) : null);

	const colors = data.map(({ colorHex }) => `#${colorHex}`);
	const labels = data.map(({ name }) => name);

	const setActiveChartItem = setCurrentSlide !== null ? (dataPointIndex) => {
		if (dataPointIndex >= 0 && dataPointIndex < data.length - 1) {
			setCurrentSlide(dataPointIndex);
		}
	} : null;

	return (
		<div className={styles.chartWrap}>
			<Chart
				className={styles.chart}
				height={350}
				options={chartOptions(colors, labels, formatLabel, formatTooltip, setActiveChartItem)}
				series={values}
				type="donut"
				width={350}
			/>
		</div>
	);
}

InvestingPortfolioChart.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			colorHex: PropTypes.string.isRequired,
			description: PropTypes.string,
			name: PropTypes.string.isRequired,
		}),
	).isRequired,
	setCurrentSlide: PropTypes.func,
	values: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
};

InvestingPortfolioChart.defaultProps = {
	setCurrentSlide: null,
};
