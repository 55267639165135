import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useTrackGTMEvent } from '../../../../utils/GTMProvider';
import useFormatNumber from '../../../../utils/useFormatNumber';
import { Box, Modal } from '../../../ui';

import styles from '../PortfolioFees.module.scss';

export default function PortfolioFeesEtfs({ feesEtfs, isProxy }) {
	const [isModalOpen, setModalOpen] = useState(false);
	const [t] = useTranslation();
	const trackGTMEvent = useTrackGTMEvent();
	const formatPercent = useFormatNumber({ style: 'percent', fractionDigits: 2 });

	return (
		<Box>
			<div className={styles.root}>
				<h3 className={styles.boxTitle}>
					{t('portfolioFeesEtfs.title')}
				</h3>
				<p className={styles.text}>
					{t('portfolioFeesEtfs.text')}
				</p>
				{feesEtfs.feeTer && (
					<p className={styles.text}>
						{t('portfolioFeesEtfs.userFee', { fee: formatPercent(feesEtfs.feeTer) })}
					</p>
				)}
				{feesEtfs.products && (
					<>
						<a
							href="#etfs"
							className={styles.link}
							onClick={(e) => {
								e.preventDefault();
								if (!isProxy) {
									trackGTMEvent('interactions', {
										eventCategory: 'interactions',
										eventAction: 'buttonClick',
										eventLabel: 'tabulka_poplatku_etf',
									});
								}
								setModalOpen(true);
							}}
						>
							{t('portfolioFeesEtfs.link')}
						</a>
						<Modal
							isVisible={isModalOpen}
							onClose={() => setModalOpen(false)}
							title={t('portfolioFeesEtfs.modal.title')}
						>
							<table className={styles.table}>
								<thead>
									<tr>
										<th>
											{t('portfolioFeesEtfs.modal.name')}
										</th>
										<th className={styles.textRight}>
											{t('portfolioFeesEtfs.modal.ter')}
										</th>
									</tr>
								</thead>
								<tbody>
									{feesEtfs.products.map((product, index) => {
										const productKey = `product${index};`;
										return (
											<tr key={productKey}>
												<td>
													<div className={styles.productTitle}>
														{product.etfName}
													</div>
												</td>
												<td className={styles.textRight}>
													{formatPercent(product.etfTer)}
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</Modal>
					</>
				)}
			</div>
		</Box>
	);
}

PortfolioFeesEtfs.propTypes = {
	feesEtfs: PropTypes.shape({
		feeTer: PropTypes.string,
		products: PropTypes.arrayOf(PropTypes.shape({
			etfName: PropTypes.string,
			etfTer: PropTypes.string,
		}).isRequired).isRequired,
	}).isRequired,
	isProxy: PropTypes.bool,
};

PortfolioFeesEtfs.defaultProps = {
	isProxy: false,
};
