import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { changeFaceImage } from '../../../utils/api';
import useRequestAuth from '../../../utils/useRequestAuth';
import { Button, Modal, Alert } from '../../ui';
import ProfileAvatars from '../ProfileAvatars';

import styles from './InvestorProfileAvatar.module.scss';

export default function InvestorProfileAvatar({
	esg,
	portfolioId,
	avatarModalOpened,
	setAvatarModalOpened,
	setCheckPortfolio,
}) {
	const changeFaceImageAuth = useRequestAuth(changeFaceImage);
	const [error, setError] = useState(false);
	const [selectedAvatar, setSelectedAvatar] = useState(null);
	const [success, setSuccess] = useState(false);
	const [t] = useTranslation();

	const handleClose = (e) => {
		e?.preventDefault();
		setAvatarModalOpened(false);
	};

	const handleClick = async () => {
		setError(false);
		setSuccess(false);
		try {
			await changeFaceImageAuth(portfolioId, selectedAvatar);
		} catch {
			setError(true);
			return;
		}
		setSuccess(true);
		setCheckPortfolio(true);
	};

	return (
		<Modal
			isVisible={avatarModalOpened}
			onClose={() => setAvatarModalOpened(false)}
			title={t('investorProfileAvatar.modal.title')}
			size="large"
		>
			<div className={styles.wrapper}>
				{error && (
					<Alert type="danger">
						{t('forms.error')}
					</Alert>
				)}
				<ProfileAvatars
					esg={esg}
					selectedAvatar={selectedAvatar}
					setSelectedAvatar={setSelectedAvatar}
				/>
				<div className={styles.buttonsWrap}>
					{success && (
						<Alert
							autoClose
							close
							type="success"
							onClose={() => setSuccess(false)}
						>
							{t('forms.success')}
						</Alert>
					)}
					<Button
						label={t('investorProfileAvatar.modal.submit')}
						onClick={handleClick}
					/>
					<div className={styles.modalClose}>
						<a
							href="#close"
							onClick={(e) => handleClose(e)}
						>
							{t('investorProfileAvatar.modal.cancel')}
						</a>
					</div>
				</div>
			</div>
		</Modal>
	);
}

InvestorProfileAvatar.propTypes = {
	avatarModalOpened: PropTypes.bool.isRequired,
	esg: PropTypes.bool.isRequired,
	portfolioId: PropTypes.number.isRequired,
	setAvatarModalOpened: PropTypes.func.isRequired,
	setCheckPortfolio: PropTypes.func.isRequired,
};
