import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

import { TextField } from '../../../../components/forms';
import { SliderArrow } from '../../../../components/ui';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './InvestingPortfolioTabs.module.scss';

const INCREMENT = 10;

export default function InvestingPortfolioTabs({
	currentSlide,
	data,
	setCurrentSlide,
	updateValue,
	valueRemaining,
	values,
}) {
	const sliderRef = useRef();
	const [t] = useTranslation();

	useEffect(() => {
		sliderRef.current?.slickGoTo(currentSlide);
	}, [currentSlide]);

	const canIncreaseValue = valueRemaining >= INCREMENT;

	return (
		<div>
			<Slider
				ref={sliderRef}
				beforeChange={(_, newSlide) => setCurrentSlide(newSlide)}
				centerPadding={0}
				className={styles.slider}
				infinite={false}
				initialSlide={data.length > 0 ? Math.floor((data.length - 1) / 2) : 0}
				nextArrow={<SliderArrow disabled={currentSlide >= data.length - 1} text="next" type="next" horizontal />}
				prevArrow={<SliderArrow disabled={currentSlide <= 0} text="prev" type="prev" horizontal />}
				slidesToScroll={1}
				slidesToShow={1}
				speed={300}
				arrows
				centerMode
				swipeToSlide
				variableWidth
			>
				{data.map((item, id) => (
					<div key={item.id}>
						<button
							style={{ color: (id === currentSlide) ? `#${item.colorHex}` : '' }}
							className={`${id === currentSlide ? styles.tabActive : ''} ${styles.tab}`.trim()}
							id={item.name}
							onClick={() => setCurrentSlide(id)}
							type="button"
						>
							{item.name}
						</button>
					</div>
				))}
			</Slider>
			{/* eslint-disable-next-line react/no-danger */}
			<div className={styles.text} dangerouslySetInnerHTML={{ __html: data[currentSlide].description }} />
			<div className={styles.counterWrap}>
				<button
					className={`${styles.counter} ${styles.counterDecrement}`}
					onClick={() => updateValue(currentSlide, -INCREMENT)}
					type="button"
					disabled={values[currentSlide] === 0}
				>
					-
				</button>
				<div className={styles.counterInput}>
					<TextField
						id="investing-portoflio-tabs"
						label={t('investingPortfolio.portfolioComposition')}
						name="investing-portfolio-tabs"
						value={`${values[currentSlide]} %`}
						center
						isLabelHidden
						readonly
					/>
				</div>
				<button
					className={`${styles.counter} ${styles.counterIncrement}`}
					onClick={() => updateValue(currentSlide, INCREMENT)}
					type="button"
					disabled={!canIncreaseValue}
				>
					+
				</button>
			</div>
			<p className={canIncreaseValue ? styles.hidden : ''}>
				{t('investingPortfolio.maximumLimit')}
			</p>
		</div>
	);
}

InvestingPortfolioTabs.propTypes = {
	currentSlide: PropTypes.number.isRequired,
	data: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			colorHex: PropTypes.string.isRequired,
			description: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}).isRequired,
	).isRequired,
	setCurrentSlide: PropTypes.func.isRequired,
	updateValue: PropTypes.func.isRequired,
	valueRemaining: PropTypes.number.isRequired,
	values: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
};
