/* global document, window */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './ScrollToTop.module.scss';

export default function ScrollToTop({
	title,
}) {
	const [scrolled, setScrolled] = useState(false);

	useEffect(() => {
		const handleScroll = () => setScrolled(window.pageYOffset !== 0);

		document.addEventListener('scroll', handleScroll);
		return () => {
			document.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<button
			className={(`${scrolled ? styles.active : ''} ${styles.button}`).trim()}
			onClick={() => window.scrollTo(0, 0)}
			type="button"
		>
			<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
				<g>
					<g><path fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="20" strokeWidth="2" d="M1.148 6v0l4.307-4.306v0L9.76 6v0" /></g>
					<g><path fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="20" strokeWidth="2" d="M5.5 3.5v6" /></g>
				</g>
			</svg>
			{title}
		</button>
	);
}

ScrollToTop.propTypes = {
	title: PropTypes.string.isRequired,
};
