import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import { Container, Section } from '../../components/layout';
import { EmailLink } from '../../components/ui';
import { useUserContext, useUserReload } from '../../contexts/UserContext';
import SignNewAgreementSteps from './SignNewAgreementSteps';

import styles from './SignNewAgreement.module.scss';

export default function SignNewAgreement({
	dashboardUrl,
}) {
	const [t] = useTranslation();
	const { state } = useLocation();
	const [searchParams] = useSearchParams();
	const [user] = useUserContext();
	const reloadUser = useUserReload();
	const category = searchParams.get('category') ?? null;

	const setCheckOnboarding = useCallback((check) => {
		if (check) {
			reloadUser();
		}
	}, [reloadUser]);

	if (user === null) {
		return null;
	}

	if (category === null || !(user.documents?.contractRequired?.[category] ?? false)) {
		const nextState = state?.createPortfolio && category !== null ? {
			createPortfolio: true,
			category,
		} : null;

		return <Navigate state={nextState} to={dashboardUrl} />;
	}

	return (
		<Section id="top">
			<Container>
				<h1 className={styles.title}>
					{t('signNewAgreement.title')}
				</h1>
				<p className={styles.text}>
					{t(`signNewAgreement.text.${category}`, {
						defaultValue: t('signNewAgreement.text.standard'),
						fallbackLng: [],
					})}
				</p>
				<p className={styles.textSmaller}>
					<Trans i18nKey="signNewAgreement.info">
						<EmailLink />
					</Trans>
				</p>
				<SignNewAgreementSteps
					category={category}
					setCheckOnboarding={setCheckOnboarding}
				/>
			</Container>
		</Section>
	);
}

SignNewAgreement.propTypes = {
	dashboardUrl: PropTypes.string.isRequired,
};
