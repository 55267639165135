import React from 'react';
import PropTypes from 'prop-types';

import styles from './Box.module.scss';

export default function Box({
	children,
}) {
	return (
		<div className={styles.root}>
			{children}
		</div>
	);
}

Box.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};
