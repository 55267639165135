import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useLanguage } from '../../../contexts/LocaleContext';
import {
	clientCheckFinishInvestmentQuestionnaire,
	clientCheckResetInvestmentQuestionnaire,
	fetchProfilePortfolio,
} from '../../../utils/api';
import useFetchWithReload from '../../../utils/useFetchWithReload';
import useRequestAuth from '../../../utils/useRequestAuth';
import { Button, Modal } from '../../ui';
import PortfolioSetting from './PortfolioSetting/PortfolioSetting';

import styles from './AllPortfoliosCheck.module.scss';

const useFetchProfilePortfolio = useFetchWithReload(fetchProfilePortfolio);

export default function AllPortfoliosCheck({
	childId,
	disabled,
	faqUrl,
	investorProfileData,
	reloadClientCheck,
	reloadQuestionnaire,
}) {
	const [t] = useTranslation();
	const language = useLanguage();
	const [portfolios, loading, error, , reloadPortfolios] = useFetchProfilePortfolio(null, language, childId);
	const finishInvestmentQuestionnaireAuth = useRequestAuth(clientCheckFinishInvestmentQuestionnaire);
	const resetInvestmentQuestionnaireAuth = useRequestAuth(clientCheckResetInvestmentQuestionnaire);
	const disabledOrWarning = disabled || !!investorProfileData.warning;
	const ownPortfolios = portfolios?.active.filter(({ child }) => (child?.id ?? null) === childId) ?? [];

	if (loading || error) {
		return null;
	}

	const handleFinishInvestmentQuestionnaire = async () => {
		await finishInvestmentQuestionnaireAuth(childId);
		reloadClientCheck();
	};

	const handleResetInvestmentQuestionnaire = async () => {
		await resetInvestmentQuestionnaireAuth(childId);
		reloadQuestionnaire();
		reloadClientCheck();
	};

	return (
		<div className={styles.root}>
			<div className={styles.legend}>
				<p>
					<svg xmlns="http://www.w3.org/2000/svg" width="21" height="17" viewBox="0 0 21 17" fill="none">
						<path d="M11.7672 15.4336C11.1783 16.3635 9.82171 16.3635 9.23277 15.4336L1.54979 3.30258C0.917263 2.30385 1.63484 1 2.81702 1H18.183C19.3652 1 20.0827 2.30385 19.4502 3.30258L11.7672 15.4336Z" fill="#D84E4B" stroke="white" />
					</svg>
					{t('allPortfoliosCheck.legend.negative')}
				</p>
				<p>
					<svg xmlns="http://www.w3.org/2000/svg" width="21" height="17" viewBox="0 0 21 17" fill="none">
						<path d="M11.7672 1.5664C11.1783 0.636504 9.82171 0.636499 9.23277 1.5664L1.54979 13.6974C0.917263 14.6961 1.63484 16 2.81702 16H18.183C19.3652 16 20.0827 14.6962 19.4502 13.6974L11.7672 1.5664Z" fill="#48B958" stroke="white" />
					</svg>
					{t('allPortfoliosCheck.legend.positive')}
				</p>
			</div>

			<div className={styles.tabHeader}>
				<div className={styles.tabHeadline}>
					{t('allPortfoliosCheck.table.title')}
				</div>
				<div className={styles.tabTitles}>
					<div
						className={styles.tabTitle}
						title={t('investorProfileDetail.ranger.info')}
					>
						{t('investorProfileDetail.ranger.less1')}
						<br />
						{t('investorProfileDetail.ranger.less2')}
					</div>
					<div
						className={styles.tabTitle}
						title={t('investorProfileDetail.ranger.info')}
					>
						{t('investorProfileDetail.ranger.more1')}
						<br />
						{t('investorProfileDetail.ranger.more2')}
					</div>
				</div>
				<div className={styles.hiddenButton}>
					<Button
						isFlat
						label={t('account.investorProfileControl.button')}
					/>
				</div>
			</div>

			{ownPortfolios.map((portfolio) => (
				<PortfolioSetting
					key={portfolio.id}
					childId={childId}
					disabled={disabledOrWarning || portfolio.isChild}
					investorProfileData={investorProfileData}
					portfolio={portfolio}
					portfolioSettingsAnchor={t('anchors.portfolioSettings')}
					reloadPortfolios={reloadPortfolios}
					resetQuestionnaire={handleResetInvestmentQuestionnaire}
				/>
			))}

			<p className={styles.recommendInfo}>
				<Trans i18nKey="allPortfoliosCheck.recommend">
					<strong />
				</Trans>
			</p>
			<p className={styles.recommendInfo}>
				{t('allPortfoliosCheck.recommendWarning')}
			</p>
			<div className={styles.confirmButton}>
				<Button
					disabled={disabledOrWarning}
					label={t('allPortfoliosCheck.confirm')}
					onClick={handleFinishInvestmentQuestionnaire}
				/>
			</div>
			<div className={styles.resetButton}>
				<Button
					disabled={disabledOrWarning}
					isLink
					label={t('allPortfoliosCheck.reset')}
					onClick={handleResetInvestmentQuestionnaire}
				/>
			</div>
			<Modal
				isVisible={!disabled && investorProfileData.warning === 'littleExperience'}
				title={t('investorProfileChoice.littleExperience.title')}
			>
				<div className={styles.modal}>
					<p className={styles.modalText}>
						<Trans i18nKey="investorProfileChoice.littleExperience.message">
							<Link to={faqUrl} />
						</Trans>
					</p>
					<Button
						disabled={disabled}
						label={t('investorProfileChoice.littleExperience.reset')}
						onClick={handleResetInvestmentQuestionnaire}
					/>
				</div>
			</Modal>
			<Modal
				isVisible={!disabled && investorProfileData.warning === 'notSuitable'}
				title={t('investorProfileChoice.notSuitable.title')}
			>
				<div className={styles.modal}>
					<p className={styles.modalText}>
						{t('investorProfileChoice.notSuitable.message')}
					</p>
					<p className={styles.modalText}>
						{t('investorProfileChoice.notSuitable.text')}
					</p>
					<Button
						disabled={disabled}
						label={t('investorProfileChoice.notSuitable.back')}
						onClick={handleResetInvestmentQuestionnaire}
					/>
				</div>
			</Modal>
		</div>
	);
}

AllPortfoliosCheck.propTypes = {
	childId: PropTypes.number,
	disabled: PropTypes.bool,
	faqUrl: PropTypes.string.isRequired,
	investorProfileData: PropTypes.shape({
		defaultProfile: PropTypes.number,
		defaultProfileEsg: PropTypes.number,
		recommendedProfiles: PropTypes.arrayOf(PropTypes.number.isRequired),
		recommendedProfilesEsg: PropTypes.arrayOf(PropTypes.number.isRequired),
		warning: PropTypes.string,
	}).isRequired,
	reloadClientCheck: PropTypes.func.isRequired,
	reloadQuestionnaire: PropTypes.func.isRequired,
};

AllPortfoliosCheck.defaultProps = {
	childId: null,
	disabled: false,
};
