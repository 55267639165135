import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { fetchMediaMention } from '../../../utils/api';
import useFetch from '../../../utils/useFetch';
import { Container, Section } from '../../layout';
import { ExternalLink, Loader } from '../../ui';

import styles from './HomepageMedia.module.scss';

const useFetchMediaMention = useFetch(fetchMediaMention);

export default function HomepageMedia() {
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const [mediaMentions, loading] = useFetchMediaMention([], country, language);

	if (loading || mediaMentions.length === 0) return <Loader variant="large" />;

	return (
		<Section condensed>
			<Container center>
				<h2 className={styles.title}>{t('media.title')}</h2>
				<div className={styles.row}>
					{mediaMentions.map((mediaMention) => (
						<ExternalLink
							key={mediaMention.id}
							className={styles.itemLink}
							to={mediaMention.link}
							blank
						>
							<div className={styles.item}>
								<div className={styles.itemIllustration}>
									<img src={mediaMention.logo} alt={mediaMention.caption} loading="lazy" />
								</div>
							</div>
						</ExternalLink>
					))}
				</div>
			</Container>
		</Section>
	);
}
