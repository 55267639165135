import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import { getPensionConfirmation } from '../../../../utils/api';
import { ExternalLink } from '../../../ui';

import styles from './PensionConfirmation.module.scss';

export default function PensionConfirmation({
	portfolioId,
}) {
	return (
		<div className={styles.root}>
			<p className={styles.text}>
				<Trans i18nKey="portfolio.settings.pensionConfirmation.download">
					<ExternalLink
						to={getPensionConfirmation(portfolioId)}
					/>
				</Trans>
			</p>
		</div>
	);
}

PensionConfirmation.propTypes = {
	portfolioId: PropTypes.number.isRequired,
};
