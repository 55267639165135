import React, {
	useCallback,
	useContext,
	useMemo,
	useReducer,
} from 'react';
import PropTypes from 'prop-types';

const noop = () => {};
const empty = () => ({});
const PageContext = React.createContext([{}, empty, noop, noop]);

const ADD_SUBPAGES = 'ADD_SUBPAGES';
const REMOVE_SUBPAGES = 'REMOVE_SUBPAGES';

export function subpageReducer(state, [actionType, page, payload]) {
	switch (actionType) {
		case ADD_SUBPAGES: {
			return { ...state, [page]: payload };
		}
		case REMOVE_SUBPAGES: {
			const { [page]: _, ...otherSubpages } = state;
			return otherSubpages;
		}
		default:
			return state;
	}
}

export function usePage() {
	const [page] = useContext(PageContext);
	return page;
}

export function useSubpages(language) {
	const [, getSubpages] = useContext(PageContext);
	return getSubpages(language);
}

export function useSetSubpages() {
	const [, , addSubpages, removeSubpages] = useContext(PageContext);
	return [addSubpages, removeSubpages];
}

export function PageProvider({ children, page }) {
	const [subpages, dispatch] = useReducer(subpageReducer, {});

	const getSubpages = useCallback((language) => subpages[page]?.[language] ?? {}, [subpages, page]);

	const addSubpages = useCallback((pageSubpages) => {
		dispatch([ADD_SUBPAGES, page, pageSubpages]);
	}, [page]);

	const removeSubpages = useCallback(() => {
		dispatch([REMOVE_SUBPAGES, page]);
	}, [page]);

	const value = useMemo(
		() => [page, getSubpages, addSubpages, removeSubpages],
		[page, getSubpages, addSubpages, removeSubpages],
	);

	return (
		<PageContext.Provider value={value}>
			{children}
		</PageContext.Provider>
	);
}

PageProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	page: PropTypes.string,
};

PageProvider.defaultProps = {
	children: null,
	page: null,
};
