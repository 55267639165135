import React from 'react';
import {
	Parser as HtmlToReactParser,
	IsValidNodeDefinitions,
	ProcessNodeDefinitions,
} from 'html-to-react';
import { DomUtils, parseDocument } from 'htmlparser2';

import webalize from './webalize';

const { alwaysValid } = IsValidNodeDefinitions;
const { processDefaultNode } = ProcessNodeDefinitions();

function reactToText(element, trim = false) {
	if (React.isValidElement(element)) {
		return React.Children.map(element.props.children, (child) => reactToText(child, trim))?.join('') ?? '';
	}

	if (typeof element !== 'string') {
		return '';
	}

	return trim ? element.trim() : element;
}

function nestListItems(items, elements, listType) {
	return items.map(({ index, prefix, children }, i) => {
		const key = `nestedList-${i}`;
		const element = index !== null ? (elements[index] ?? null) : null;
		const listElement = children.length > 0
			? React.createElement(listType, { key }, nestListItems(children, elements, listType))
			: null;

		if (element === null) {
			return listElement !== null ? React.createElement('li', { key }, listElement) : null;
		}

		if (listElement === null && (prefix === null || prefix === '')) {
			return element;
		}

		const elementChildren = React.isValidElement(element)
			? React.Children.toArray(element.props.children)
			: [element];
		let modified = false;

		if (typeof elementChildren[0] === 'string' && elementChildren[0].startsWith(prefix)) {
			elementChildren[0] = elementChildren[0].slice(prefix.length);
			modified = true;
		}

		if (listElement !== null) {
			elementChildren.push(listElement);
			modified = true;
		}

		if (!modified) {
			return element;
		}

		if (!React.isValidElement(element)) {
			return elementChildren.length === 1
				? elementChildren[0]
				: React.createElement(React.Fragment, { key }, elementChildren);
		}

		return React.cloneElement(element, null, elementChildren);
	});
}

function parseContent(html, root = true) {
	const htmlToReactParser = new HtmlToReactParser();
	const content = htmlToReactParser.parseWithInstructions(html, alwaysValid, (root ? [{
		shouldProcessNode(node) {
			return node.name === 'h2' && node.parent?.type === 'root';
		},
		processNode(node, children, index) {
			// eslint-disable-next-line no-param-reassign
			node.attribs.id = node.attribs.id ?? webalize(DomUtils.textContent(node));
			return processDefaultNode(node, children, index);
		},
	}] : []).concat([{
		shouldProcessNode(node) {
			return node.name === 'ol' || node.name === 'ul';
		},
		processNode(node, children, index) {
			const items = [];
			let lastItem = null;
			let nested = false;

			children.forEach((item, childIndex) => {
				if (!React.isValidElement(item) || item.type !== 'li') {
					lastItem = null;
					items.push({
						index: childIndex,
						prefix: null,
						parent: null,
						children: [],
					});
					return;
				}

				const itemChildren = React.Children.toArray(item.props.children);
				const prefix = typeof itemChildren[0] === 'string'
					? (itemChildren[0].match(/^[~]*/)?.[0] ?? '')
					: '';

				while (lastItem !== null && (
					lastItem.prefix === null
					|| lastItem.prefix.length >= prefix.length
					|| !prefix.startsWith(lastItem.prefix)
				)) {
					lastItem = lastItem.parent;
				}

				let target = lastItem !== null ? lastItem.children : items;
				let targetLength = lastItem !== null ? (lastItem.prefix?.length ?? 0) + 1 : 0;
				for (; targetLength <= prefix.length - 1; targetLength += 1) {
					target.push(lastItem = {
						index: null,
						prefix: prefix.slice(0, targetLength),
						parent: lastItem,
						children: [],
					});
					target = lastItem.children;
				}

				if (lastItem !== null) {
					nested = true;
				}

				target.push(lastItem = {
					index: childIndex,
					prefix,
					parent: lastItem,
					children: [],
				});
			});

			return processDefaultNode(node, nested ? nestListItems(items, children, node.name) : children, index);
		},
	}, {
		shouldProcessNode(node) {
			return node.name === 'img' && (
				node?.parent?.parent?.name === 'figure'
				|| node?.parent?.parent?.parent?.name === 'figure'
			);
		},
		processNode(node, children, index) {
			const alt = node.attribs?.alt;
			const figure = node.parent.parent.name === 'figure' ? node.parent.parent : node.parent.parent.parent;
			if (typeof alt !== 'string') {
				return processDefaultNode(node, children, index);
			}

			const tags = ['desktop', 'mobile'];
			for (let i = 0; i < tags.length; i += 1) {
				const tag = `[${tags[i]}]`;
				if (alt.startsWith(tag)) {
					if (figure.attribs) {
						figure.attribs.class = `${figure.attribs.class ?? ''} figure-${tags[i]}`.trim();
					}
					node.attribs.alt = alt.substring(tag.length); // eslint-disable-line no-param-reassign
					node.attribs.loading = 'eager'; // eslint-disable-line no-param-reassign
					break;
				}
			}

			return processDefaultNode(node, children, index);
		},
	}, {
		shouldProcessNode(node) {
			return node.name === 'div' && (node?.parent?.name === 'figure' || node?.parent?.parent?.name === 'figure');
		},
		processNode(node, children, index) {
			const allChildren = node.data !== null ? [node.data].concat(children) : children;
			return React.createElement(React.Fragment, { key: index }, allChildren);
		},
	}, {
		shouldProcessNode: alwaysValid,
		processNode: processDefaultNode,
	}]));
	return Array.isArray(content) ? content : [content];
}

function contentToReact(html, elementTags) {
	const content = parseContent(html);
	const contentTags = content.map((child) => {
		const text = (React.isValidElement(child) && child.type === 'p') || typeof child === 'string'
			? reactToText(child)
			: null;

		if (text === '' || text === null) {
			return null;
		}

		return Object.keys(elementTags).find((tag) => tag === text) ?? null;
	});

	const { length } = content;
	const result = [];
	let index = 0;

	while (index < length) {
		const child = content[index];
		let tag = contentTags[index];
		let closingIndex;

		if (tag !== null) {
			const closingTag = elementTags[tag].closing ?? tag;
			closingIndex = index + 1;
			while (closingIndex < length && contentTags[closingIndex] !== closingTag) {
				closingIndex += 1;
			}

			if (closingIndex >= length) {
				tag = null;
			}
		}

		if (tag !== null) {
			const { type = null, props = null, raw = false } = elementTags[tag];
			const childElements = content.slice(index + 1, closingIndex);
			const children = raw
				? parseContent(childElements.map((element) => reactToText(element, true)).join(''), false)
				: childElements;
			const element = React.createElement(type ?? React.Fragment, { ...props, key: `custom-${index}` }, children);

			result.push(element);
			index = closingIndex + 1;
		} else {
			result.push(child);
			index += 1;
		}
	}

	return result;
}

function htmlToReact(html) {
	const htmlToReactParser = new HtmlToReactParser();
	return htmlToReactParser.parse(html);
}

function htmlToText(html) {
	return DomUtils.textContent(parseDocument(html));
}

function slugify(text) {
	return text
		.normalize('NFD')
		.toLowerCase()
		.replace(/[^a-z0-9\s]/g, '')
		.trim()
		.replace(/\s+/g, '-');
}

function getContentHeadings(content) {
	const headings = [];
	const headingSlugs = [];

	const contentWithSlugs = content.map((child) => {
		if (React.isValidElement(child) && child.type === 'h2') {
			const heading = reactToText(child);
			const id = child.props.id || slugify(heading);
			headings.push(heading);
			headingSlugs.push(id);

			if (!child.props.id) {
				return React.cloneElement(child, { id });
			}
		}

		return child;
	});

	return [contentWithSlugs, headings, headingSlugs];
}

function compareBlogPostDate({ 'datum-zverejneni': dateA }, { 'datum-zverejneni': dateB }) {
	if (dateA > dateB) {
		return 1;
	}

	if (dateA < dateB) {
		return -1;
	}

	return 0;
}

function processBlogTag(tag) {
	return {
		id: tag._id, // eslint-disable-line no-underscore-dangle
		name: tag.name,
		slug: tag.slug,
	};
}

function processBlogPost(itemsByLanguage, item, elementTags, postLanguage, otherLanguages) {
	const content = contentToReact(item['clanek-zde'] ?? '', elementTags);
	const [contentWithSlugs, headings, headingSlugs] = getContentHeadings(content);

	return {
		id: item._id, // eslint-disable-line no-underscore-dangle
		content: contentWithSlugs.length === 1 ? contentWithSlugs[0] : contentWithSlugs,
		contentText: item['clanek-zde'] !== null ? htmlToText(item['clanek-zde']) : '',
		date: item['datum-zverejneni'] ?? '',
		headings,
		headingSlugs,
		languageVersions: otherLanguages.reduce((acc, language) => {
			const languageItemId = item[`${language}-version`] ?? null;
			acc[language] = languageItemId !== null
				? itemsByLanguage[language]?.posts?.find(({ _id: id }) => id === languageItemId)?.slug ?? null
				: null;
			return acc;
		}, {}),
		metaDescription: item['meta-description'] ?? null,
		metaImage: item['og-image']?.url ?? null,
		perex: (item['perex-clanku'] ?? null) !== null ? htmlToText(item['perex-clanku']) : '',
		perexRich: (item['perex-clanku'] ?? null) !== null ? htmlToReact(item['perex-clanku']) : null,
		previewImage: {
			src: item['og-image']?.url ?? '',
		},
		published: item['zverejnit-v-seznamu-clanku'] ?? item.published ?? false,
		slug: item.slug,
		title: item.name,
		topics: item['tagy-clanku']?.reduce((acc, tagId) => {
			const tag = itemsByLanguage[postLanguage]?.tags?.find(({ _id: id }) => id === tagId) ?? null;
			if (tag !== null) {
				acc.push(processBlogTag(tag));
			}
			return acc;
		}, []),
		topRead: item.nejctenejsi ?? false,
		updated: item.updated ?? false,
	};
}

export function processBlogPostData(languages, itemsByLanguage, elementTags) {
	return languages.reduce((acc, language) => {
		const otherLanguages = languages.filter((lang) => lang !== language);
		acc[language] = itemsByLanguage[language].posts?.sort((itemA, itemB) => -compareBlogPostDate(itemA, itemB)).map(
			(item) => processBlogPost(itemsByLanguage, item, elementTags, language, otherLanguages),
		) ?? [];
		return acc;
	}, {});
}

export function processBlogTagData(languages, itemsByLanguage) {
	return languages.reduce((acc, language) => {
		acc[language] = itemsByLanguage[language].tags?.map((tag) => processBlogTag(tag)) ?? [];
		return acc;
	}, {});
}
