import React from 'react';
import PropTypes from 'prop-types';

import Col from '../Col';
import Container from '../Container';
import Row from '../Row';
import Section from '../Section';

import styles from './FormLayout.module.scss';

export default function FormLayout({
	children,
	title,
}) {
	return (
		<Section>
			<Container center>
				{title && (<h1 className={styles.title}>{title}</h1>)}
				<Row center>
					<Col lg={6} xl={4}>
						{children}
					</Col>
				</Row>
			</Container>
		</Section>
	);
}

FormLayout.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	title: PropTypes.string,
};

FormLayout.defaultProps = {
	title: '',
};
