import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import { TextField } from '../../components/forms';
import { FormLayout } from '../../components/layout';
import {
	Alert,
	Button,
} from '../../components/ui';
import { resetPassword } from '../../utils/api';
import { validateEmail, validateResetPassword } from '../../utils/validators';

const initialValues = {
	email: '',
	code: '',
	password: '',
	passwordConfirm: '',
};

export default function ResetPassword({
	code,
	dashboardUrl,
	email,
	forgotPasswordUrl,
}) {
	const [error, setError] = useState(false);
	const [t] = useTranslation();
	const navigate = useNavigate();

	useEffect(() => {
		if (!(email && validateEmail(email) && code) && forgotPasswordUrl) {
			navigate(forgotPasswordUrl);
		}
	}, [email, code, forgotPasswordUrl, navigate]);

	return (
		<FormLayout title={t('resetPassword.title')}>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validate={validateResetPassword}
				onSubmit={async (values) => {
					try {
						await resetPassword(
							email,
							code,
							values.password,
							values.passwordConfirm,
						);
					} catch {
						setError(true);
						return;
					}

					if (dashboardUrl) {
						navigate(dashboardUrl);
					}
				}}
			>
				{({
					errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values,
				}) => (
					<form onSubmit={handleSubmit}>
						{error && (
							<Alert type="danger">
								{t('forms.error')}
							</Alert>
						)}
						<TextField
							id="email"
							name="email"
							label={t('forms.fields.email.label')}
							required
							readonly
							disabled
							type="email"
							value={email}
						/>
						<TextField
							onBlur={handleBlur}
							onChange={handleChange}
							error={
								errors.password
								&& touched.password
								&& t(errors.password)
							}
							helper={t('forms.fields.password.helper')}
							id="password"
							name="password"
							label={t('forms.fields.password.label')}
							required
							type="password"
							value={values.password}
						/>
						<TextField
							onBlur={handleBlur}
							onChange={handleChange}
							error={
								errors.passwordConfirm
								&& touched.passwordConfirm
								&& t(errors.passwordConfirm)
							}
							id="passwordConfirm"
							name="passwordConfirm"
							label={t('forms.fields.passwordConfirm.label')}
							required
							type="password"
							value={values.passwordConfirm}
						/>
						<Button
							label={t('resetPassword.form.button')}
							disabled={isSubmitting}
							isSubmit
							icon={(
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 512 512"
								>
									<path fill="currentColor" d="M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z" />
								</svg>
							)}
						/>
					</form>
				)}
			</Formik>
		</FormLayout>
	);
}

ResetPassword.propTypes = {
	code: PropTypes.string,
	dashboardUrl: PropTypes.string.isRequired,
	email: PropTypes.string,
	forgotPasswordUrl: PropTypes.string.isRequired,
};

ResetPassword.defaultProps = {
	code: '',
	email: '',
};
