export default {
	account: 'moje-konto',
	blog: 'blog',
	childProduct: 'produkty/konto-dziecka',
	classicProduct: 'produkty/klasyczne-konto',
	clientCheck: 'client-check',
	contact: 'zapytaj',
	cookies: 'cookies',
	dashboard: 'przeglad',
	documents: 'dokumenty',
	emailAlias: 'laczenie-kont',
	emailVerification: 'rejestracja/weryfikacja-emaila',
	esgProduct: 'produkty/esg-konto',
	faq: 'czesto-zadawane-pytania',
	fees: 'oplaty',
	forgotPassword: 'logowanie/resetowanie-hasla',
	history: 'historia',
	homepage: '',
	howItWorks: 'jak-to-dziala',
	login: 'logowanie',
	offers: 'cos-ekstra',
	onboarding: 'dokonczenie-rejestracji',
	onboardingChild: 'rejestracja-dziecka',
	onboardingParent: 'rejestracja-rodzica',
	ourApproach: 'nasz-dostep',
	pensionProduct: 'produkty/emerytalne-konto-inwestycyjne',
	portfolio: 'portfel',
	privacyPolicy: 'ochrona-danych-osobowych',
	referrals: 'polecenia',
	registration: 'rejestracja',
	resetPassword: 'logowanie/zmiana-hasla',
	signAgreement: 'podpisanie-umowy',
	signNewAgreement: 'podpisanie-nowej-umowy',
	unsubscribe: 'unsubscribe',
};

export const subpages = {
	blog: {
		esg: 'czym-jest-esg-inwestowanie-i-dlaczego-warto-inwestowac-w-sposob-zrownowazony',
		esgVolatility: '5-powodow-dla-zrownowazonego-inwestowania#esg-chroni-cie-przed-ryzykiem-inwestycyjnym-odpowiedzialne-inwestycje-spadaja-o-20-mniej',
		etf: 'co-to-sa-etf',
	},
};
