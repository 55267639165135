/* global document, window */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router-dom';

import {
	FooterCta,
	HowItWorksHero,
	HowItWorksInvestment,
	HowItWorksPrinciples,
	HowItWorksRebalancing,
	InvestorProfileDetail,
} from '../../components/common';

export default function HowItWorks({
	blogPostETFUrl,
	childProductUrl,
	classicProductUrl,
	esgProductUrl,
	ourApproachUrl,
	registrationUrl,
}) {
	const [t] = useTranslation();
	const portfolioSectionAnchor = t('anchors.portfolio');
	const { key, hash, pathname } = useLocation();

	useEffect(() => {
		const anchor = window.location.hash.split('#')[1];

		setTimeout(() => {
			document.getElementById(anchor)?.scrollIntoView({
				behavior: 'smooth',
			});
		}, 500);
	}, [key, hash, pathname]);

	return (
		<>
			<HowItWorksHero
				blogPostETFUrl={blogPostETFUrl}
				portfolioSectionAnchor={portfolioSectionAnchor}
			/>
			<InvestorProfileDetail
				childProductUrl={childProductUrl}
				classicProductUrl={classicProductUrl}
				esgProductUrl={esgProductUrl}
				registrationUrl={registrationUrl}
			/>
			<HowItWorksPrinciples />
			<HowItWorksRebalancing />
			<HowItWorksInvestment ourApproachUrl={ourApproachUrl} />
			<FooterCta registrationUrl={registrationUrl} />
		</>
	);
}

HowItWorks.propTypes = {
	blogPostETFUrl: PropTypes.string,
	childProductUrl: PropTypes.string.isRequired,
	classicProductUrl: PropTypes.string.isRequired,
	esgProductUrl: PropTypes.string.isRequired,
	ourApproachUrl: PropTypes.string,
	registrationUrl: PropTypes.string.isRequired,
};

HowItWorks.defaultProps = {
	blogPostETFUrl: null,
	ourApproachUrl: null,
};
