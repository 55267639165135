import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ButtonLink } from '../../../components/ui';

import styles from '../Products.module.scss';

export default function PensionCard({
	pensionProductUrl,
}) {
	const [t] = useTranslation();

	return (
		<div className={styles.content}>
			<div className={styles.icon}>
				<svg id="url(#pension-other-product-a)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.24 128.03">
					<path fill="#a46114" d="m64.54,1.97C30.24,1.97,2.43,29.78,2.43,64.08s27.81,62.11,62.11,62.11,62.11-27.81,62.11-62.11S98.84,1.97,64.54,1.97Zm.12,110.41c-26.71,0-48.36-21.66-48.36-48.36S37.95,15.66,64.66,15.66s48.36,21.65,48.36,48.36-21.65,48.36-48.36,48.36Z" />
					<path fill="#a46114" d="m90.82,36.18v-2.78c0-.41-.33-.75-.75-.75h-51.06c-.41,0-.75.34-.75.75v2.78c0,.42.34.75.75.75h6.38c-.02.06-.04.12-.04.19l-.2,15.96c0,.22.09.42.26.57l12.09,10.72-12.09,10.73c-.17.14-.26.35-.26.57l.19,15.55h-6.33c-.41,0-.75.34-.75.75v2.78c0,.42.34.75.75.75h51.06c.42,0,.75-.33.75-.75v-2.78c0-.41-.33-.75-.75-.75h-5.93l.18-15.55c0-.22-.09-.43-.25-.57l-12.09-10.73,12.09-10.72c.16-.15.26-.35.25-.57l-.19-15.96c0-.07-.02-.13-.04-.19h5.98c.42,0,.75-.33.75-.75Zm-8.01,16.58l-12.46,11.05c-.11.1-.18.23-.21.36-.03.07-.04.13-.04.2,0,.22.09.42.25.57l12.47,11.06-.11,9.03-.07,5.84h-35.8l-.08-6.24-.1-8.63,12.47-11.06c.16-.15.25-.35.25-.57,0-.16-.05-.31-.14-.44-.03-.04-.07-.08-.11-.12l-12.47-11.06v-.05l.18-14.82h35.8l.18,14.87h-.01Z" />
					<path fill="#fcc00" d="m66.08,76.8l16.63,8.23-.07,5.84h-35.8l-.08-6.24,16.9-7.66v-9.12l-4.42-3.92s-.07-.08-.11-.12l-12.47-11.06v-.05l36.15.06-12.46,11.05c-.11.1-.18.23-.21.36l-4.06,3.62v9.01Z" />
				</svg>
			</div>
			<p className={styles.subTitle}>
				{t('products.list.pension.title')}
			</p>
			<p className={styles.text}>
				{t('products.list.pension.text')}
			</p>
			<ButtonLink
				label={t('products.list.pension.button')}
				to={pensionProductUrl}
				outline
			/>
		</div>
	);
}

PensionCard.propTypes = {
	pensionProductUrl: PropTypes.string.isRequired,
};
