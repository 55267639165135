import React from 'react';
import PropTypes from 'prop-types';

import styles from './SectionAnchor.module.scss';

export default function SectionAnchor({
	target,
	title,
}) {
	return (
		<div className={styles.root}>
			<a href={`#${target}`} className={styles.link}>
				{title}
			</a>
		</div>
	);
}

SectionAnchor.propTypes = {
	target: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
};
