import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { Container, Section, SectionAdaptable } from '../../layout';
import { ButtonLink, Picture } from '../../ui';

import bratislava from './images/bratislava.png';
import bratislava2x from './images/bratislava@2x.png';
import bratislavaWebP from './images/bratislava.webp';
import bratislavaWebP2x from './images/bratislava@2x.webp';
import bratislavaMobile from './images/bratislavaMobile.png';
import bratislavaMobile2x from './images/bratislavaMobile@2x.png';
import bratislavaMobileWebP from './images/bratislavaMobile.webp';
import bratislavaMobileWebP2x from './images/bratislavaMobile@2x.webp';

import brno from './images/brno.png';
import brno2x from './images/brno@2x.png';
import brnoWebP from './images/brno.webp';
import brnoWebP2x from './images/brno@2x.webp';
import brnoMobile from './images/brnoMobile.png';
import brnoMobile2x from './images/brnoMobile@2x.png';
import brnoMobileWebP from './images/brnoMobile.webp';
import brnoMobileWebP2x from './images/brnoMobile@2x.webp';

import space from './images/space.png';
import space2x from './images/space@2x.png';
import spaceWebP from './images/space.webp';
import spaceWebP2x from './images/space@2x.webp';
import spaceMobile from './images/spaceMobile.png';
import spaceMobile2x from './images/spaceMobile@2x.png';
import spaceMobileWebP from './images/spaceMobile.webp';
import spaceMobileWebP2x from './images/spaceMobile@2x.webp';

import phone from './images/heroPhone.png';
import phone2x from './images/heroPhone@2x.png';
import phoneWebP from './images/heroPhone.webp';
import phoneWebP2x from './images/heroPhone@2x.webp';

import phoneEn from './images/heroPhoneEn.png';
import phone2xEn from './images/heroPhoneEn@2x.png';
import phoneWebPEn from './images/heroPhoneEn.webp';
import phoneWebP2xEn from './images/heroPhoneEn@2x.webp';

import phoneSk from './images/heroPhoneSk.png';
import phone2xSk from './images/heroPhoneSk@2x.png';
import phoneWebPSk from './images/heroPhoneSk.webp';
import phoneWebP2xSk from './images/heroPhoneSk@2x.webp';

import phonePl from './images/heroPhonePl.png';
import phone2xPl from './images/heroPhonePl@2x.png';
import phoneWebPPl from './images/heroPhonePl.webp';
import phoneWebP2xPl from './images/heroPhonePl@2x.webp';

import styles from './HomepageHero.module.scss';

const futureImages = {
	CZ: {
		brno: {
			image: brno,
			image2x: brno2x,
			imageMobile: brnoMobile,
			imageMobile2x: brnoMobile2x,
			imageWebP: brnoWebP,
			imageWebP2x: brnoWebP2x,
			imageMobileWebP: brnoMobileWebP,
			imageMobileWebP2x: brnoMobileWebP2x,
		},
		space: {
			image: space,
			image2x: space2x,
			imageMobile: spaceMobile,
			imageMobile2x: spaceMobile2x,
			imageWebP: spaceWebP,
			imageWebP2x: spaceWebP2x,
			imageMobileWebP: spaceMobileWebP,
			imageMobileWebP2x: spaceMobileWebP2x,
		},
	},
	SK: {
		bratislava: {
			image: bratislava,
			image2x: bratislava2x,
			imageMobile: bratislavaMobile,
			imageMobile2x: bratislavaMobile2x,
			imageWebP: bratislavaWebP,
			imageWebP2x: bratislavaWebP2x,
			imageMobileWebP: bratislavaMobileWebP,
			imageMobileWebP2x: bratislavaMobileWebP2x,
		},
	},
};

const heroPhoneImage = {
	cs: {
		src: phone,
		src2x: phone2x,
		srcWebP: phoneWebP,
		src2xWebP: phoneWebP2x,
	},
	en: {
		src: phoneEn,
		src2x: phone2xEn,
		srcWebP: phoneWebPEn,
		src2xWebP: phoneWebP2xEn,
	},
	sk: {
		src: phoneSk,
		src2x: phone2xSk,
		srcWebP: phoneWebPSk,
		src2xWebP: phoneWebP2xSk,
	},
	pl: {
		src: phonePl,
		src2x: phone2xPl,
		srcWebP: phoneWebPPl,
		src2xWebP: phoneWebP2xPl,
	},
};

const randomKey = (object) => {
	const keys = Object.keys(object);
	return keys[Math.floor(Math.random() * keys.length)];
};

const resolveFuture = (future, country) => {
	if (typeof future === 'string' && country in futureImages && future in futureImages[country]) {
		return future;
	}

	if (!future || !(country in futureImages)) {
		return false;
	}

	return randomKey(futureImages[country]);
};

export default function HomepageHero({
	future: initialFuture,
	howItWorksUrl,
	productSectionAnchor,
	setFuture,
}) {
	const country = useCountry();
	const language = useLanguage();
	const [t] = useTranslation();
	const [future] = useState(() => resolveFuture(initialFuture, country));

	useEffect(() => {
		if (initialFuture && initialFuture !== true) {
			setFuture(true);
		}
	}, [initialFuture, setFuture]);

	if (!future) {
		return (
			<Section condensed>
				<Container>
					<div className={styles.wrap}>
						<div className={styles.content}>
							<h1 className={styles.title}>
								<Trans i18nKey="homepageHero.title">
									<span className={styles.highlight} />
								</Trans>
							</h1>
							<h2 className={styles.subtitle}>
								<Trans i18nKey="homepageHero.subtitle">
									<span className={styles.highlight} />
								</Trans>
							</h2>
							<p className={styles.text}>
								<Trans i18nKey="homepageHero.text">
									<strong />
								</Trans>
							</p>
							<div className={styles.buttonWrap}>
								<ButtonLink
									label={t('homepageHero.aboutButton')}
									to={howItWorksUrl}
								/>
								<ButtonLink
									label={t('homepageHero.productsButton')}
									to={`#${productSectionAnchor}`}
									outline
								/>
							</div>
						</div>
						<div className={styles.heroImage}>
							<Picture
								alt={`${t('homepageHero.future.title')} ${t('homepageHero.future.subtitle')}`}
								src={heroPhoneImage[language].src}
								src2x={heroPhoneImage[language].src2x}
								srcWebP={heroPhoneImage[language].srcWebP}
								src2xWebP={heroPhoneImage[language].src2xWebP}
							/>
						</div>
					</div>
				</Container>
				<div className={styles.bubbles}>
					<div className={`${styles.bubble} ${styles.bubble1}`.trim()}>
						<div className={`${styles.bubble} ${styles.bubble2}`.trim()} />
					</div>
				</div>
			</Section>
		);
	}

	const images = futureImages[country][future];

	return (
		<SectionAdaptable>
			<Container>
				<div className={`${styles.futureWrap} ${future === 'bratislava' ? styles.futureWide : ''}`.trim()}>
					<h1 className={styles.futureTitle}>
						{t('homepageHero.future.title')}
					</h1>
					<h2 className={styles.futureSubtitle}>
						{t('homepageHero.future.subtitle')}
					</h2>
					<p className={styles.futureText}>
						{t('homepageHero.future.text')}
					</p>
					<ButtonLink
						label={t('homepageHero.aboutButton')}
						isNarrow
						secondary
						to={`#${productSectionAnchor}`}
					/>
				</div>
			</Container>
			<div className={styles.futureImage}>
				<Picture
					alt={`${t('homepageHero.future.title')} ${t('homepageHero.future.subtitle')}`}
					breakpoint="sm"
					src={images.imageMobile}
					src2x={images.imageMobile2x}
					srcWebP={images.imageMobileWebP}
					src2xWebP={images.imageMobileWebP2x}
					srcBreakpoint={images.image}
					srcBreakpoint2x={images.image2x}
					srcBreakpointWebP={images.imageWebP}
					srcBreakpoint2xWebP={images.imageWebP2x}
				/>
			</div>
		</SectionAdaptable>
	);
}

HomepageHero.propTypes = {
	future: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.string,
	]),
	howItWorksUrl: PropTypes.string.isRequired,
	productSectionAnchor: PropTypes.string.isRequired,
	setFuture: PropTypes.func,
};

HomepageHero.defaultProps = {
	future: null,
	setFuture: null,
};
