import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { postContactUs } from '../../../utils/api';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import { validateContactUs } from '../../../utils/validators';
import useRecaptchaToken from '../../../utils/useRecaptchaToken';
import {
	Col,
	Container,
	Row,
	Section,
} from '../../layout';
import { Alert, Button, ExternalLink } from '../../ui';
import { CheckboxField, TextareaField, TextField } from '../../forms';

import styles from './ContactUs.module.scss';

const initialValues = {
	name: '',
	email: '',
	text: '',
	conditions: false,
};

export default function ContactUs({
	contactFormSectionAnchor,
	personalInfoAgreementUrl,
	termsUrl,
}) {
	const country = useCountry();
	const language = useLanguage();
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [t] = useTranslation();
	const trackGTMEvent = useTrackGTMEvent();
	const { getRecaptchaToken } = useRecaptchaToken('formSubmit');

	return (
		<Section id={contactFormSectionAnchor}>
			<Container>
				<Row center>
					<Col lg={8}>
						<h2 className={styles.title}>{t('contactUs.title')}</h2>
						<Formik
							enableReinitialize
							initialValues={initialValues}
							validate={validateContactUs}
							onSubmit={async (values, { setFieldValue }) => {
								setError(false);
								setSuccess(false);

								const recaptchaToken = await getRecaptchaToken();

								if (!recaptchaToken) {
									setError(true);
									return;
								}

								try {
									await postContactUs(
										country,
										language,
										values.name,
										values.email,
										values.text,
										recaptchaToken,
									);
								} catch (e) {
									setError(true);
									return;
								}
								setSuccess(true);

								trackGTMEvent('interactions', {
									eventCategory: 'softConversions',
									eventAction: 'formSend',
									eventLabel: 'question_form',
								});

								setFieldValue('text', '', false);
								setFieldValue('name', '', false);
								setFieldValue('email', '', false);
								setFieldValue('conditions', false, false);
							}}
						>
							{({
								errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values,
							}) => (
								<form onSubmit={handleSubmit}>
									{error && (
										<Alert type="danger">
											{t('forms.error')}
										</Alert>
									)}
									{success && (
										<Alert type="success">
											{t('contactUs.success')}
										</Alert>
									)}
									<TextareaField
										onBlur={handleBlur}
										onChange={handleChange}
										error={
											errors.text
											&& touched.text
											&& t(errors.text)
										}
										id="text"
										name="text"
										label={t('contactUs.text.label')}
										required
										value={values.text}
									/>
									<Row>
										<Col lg={6}>
											<TextField
												onBlur={handleBlur}
												onChange={handleChange}
												error={
													errors.name
													&& touched.name
													&& t(errors.name)
												}
												id="name"
												name="name"
												label={t('contactUs.name.label')}
												required
												type="text"
												value={values.name}
											/>
										</Col>
										<Col lg={6}>
											<TextField
												onBlur={handleBlur}
												onChange={handleChange}
												error={
													errors.email
													&& touched.email
													&& t(errors.email)
												}
												id="email"
												name="email"
												label={t('contactUs.email.label')}
												required
												type="email"
												value={values.email}
											/>
										</Col>
									</Row>
									<Row middle>
										<Col lg={8}>
											<CheckboxField
												onBlur={handleBlur}
												onChange={handleChange}
												checked={values.conditions}
												error={
													errors.conditions
													&& touched.conditions
													&& t(errors.conditions)
												}
												label={(
													<Trans i18nKey="contactUs.conditions.label">
														<ExternalLink
															to={personalInfoAgreementUrl}
															blank
														/>
														<ExternalLink
															to={termsUrl}
															blank
														/>
													</Trans>
												)}
												id="conditions"
												name="conditions"
												required
											/>
										</Col>
										<Col lg={4}>
											<div className={styles.buttonWrap}>
												<Button
													label={t('contactUs.submit')}
													disabled={isSubmitting}
													isSubmit
												/>
											</div>
										</Col>
									</Row>
								</form>
							)}
						</Formik>
					</Col>
				</Row>
			</Container>
		</Section>
	);
}

ContactUs.propTypes = {
	contactFormSectionAnchor: PropTypes.string.isRequired,
	personalInfoAgreementUrl: PropTypes.string.isRequired,
	termsUrl: PropTypes.string.isRequired,
};
