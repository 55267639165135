import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { getProfilePortfolioHistoryPDF } from '../../../utils/api';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import useFormatNumber from '../../../utils/useFormatNumber';
import {
	Col,
	Container,
	Row,
	Section,
} from '../../layout';
import { ButtonLink, Loader, Tooltip } from '../../ui';

import styles from './HistoryProgress.module.scss';

const PDF_TYPE = 'value-history';

export default function HistoryProgress({
	fromDate,
	toDate,
	currency,
	portfolioId,
	progress,
	isProxy,
}) {
	const [limit, setLimit] = useState(20);
	const [open, setOpen] = useState(null);
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const trackGTMEvent = useTrackGTMEvent();
	const formatNumber = useFormatNumber();
	const formatCurrency = useFormatNumber({ style: 'currency', currency: currency?.code });

	const transactionDescription = (transaction) => {
		const tt = (string) => t(`historyProgress.table.rows.${string}`);
		switch (transaction.category) {
			case 'deposit':
				return transaction.employer !== null
					? `${tt('employerDeposit')} ${transaction.employer.businessId} ${transaction.employer.title}`
					: transaction.name;
			case 'trading': {
				const product = `${formatNumber(transaction.amount)} ${transaction.product?.name}, ${transaction.product?.isin}`;
				const price = `${formatNumber(transaction.price)} ${transaction.product?.currencyCode}`;
				return `${transaction.name} ${product} ${tt('for')} ${price}`;
			}
			case 'dividend':
				return `${transaction.name} ${transaction.product?.name}, ${transaction.product?.isin}`;
			case 'fx':
				return transaction.fx !== null
					? `${transaction.name} ${tt('rate')} ${
						formatNumber(transaction.fx.quantity ?? 1)
					} ${transaction.fx.currencyCode} / ${
						formatNumber(transaction.fx.rate, { fractionDigits: 4 })
					} ${transaction.fx.currencyFromCode}`
					: transaction.name;
			case 'transferEtfOut': {
				const product = `${formatNumber(transaction.amount)} ${transaction.product?.name}, ${transaction.product?.isin}`;
				return `${tt('transfer')} ${product} ${tt('toPortfolio')} ${transaction.portfolioTo}`;
			}
			case 'transferCashOut':
				return `${tt('transfer')} ${tt('toPortfolio')} ${transaction.portfolioTo}`;
			case 'transferEtfIn': {
				const product = `${formatNumber(transaction.amount)} ${transaction.product?.name}, ${transaction.product?.isin}`;
				return `${tt('transfer')} ${product} ${tt('fromPortfolio')} ${transaction.portfolioFrom}`;
			}
			case 'transferCashIn':
				return `${tt('transfer')} ${tt('fromPortfolio')} ${transaction.portfolioFrom}`;
			default:
				return transaction.name;
		}
	};

	return (
		<Section bordered>
			<Container>
				<Row>
					<Col lg={4}>
						<h2 className={styles.title}>
							{t('historyProgress.title')}
						</h2>
					</Col>
					{!isProxy && (
						<Col lg={8}>
							<div className={styles.button}>
								{portfolioId !== null && progress && progress.length > 0 && (
									<ButtonLink
										label={t('historyProgress.downloadPdf')}
										onClick={(e) => {
											trackGTMEvent('interactions', {
												eventCategory: 'interactions',
												eventAction: 'buttonClick',
												eventLabel: 'historie_vyvoj_stahnout_pdf',
											});
											e.currentTarget.blur();
										}}
										to={getProfilePortfolioHistoryPDF(
											portfolioId,
											PDF_TYPE,
											country,
											language,
											fromDate,
											toDate,
										)}
										download
										outline
									/>
								)}
							</div>
						</Col>
					)}
				</Row>

				{progress && currency && progress.length > 0 && (
					<>
						<div className={styles.tableWrap}>
							<table className={styles.table}>
								<thead>
									<tr>
										<th>
											{t('historyProgress.table.columns.date')}
										</th>
										<th className={styles.right}>
											{t('historyProgress.table.columns.externalTransactionTotal')}
										</th>
										<th className={styles.right}>
											{t('historyProgress.table.columns.internalTransactionTotal')}
										</th>
										<th className={styles.right}>
											{t('historyProgress.table.columns.value')}
											<span className={styles.tableIcon}>
												<Tooltip text={t('historyProgress.table.columns.valueTooltip')}>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 512 512"
														width={15}
													>
														<path
															fill="currentColor"
															d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
														/>
													</svg>
												</Tooltip>
											</span>
										</th>
										<th className={styles.right}>
											{t('historyProgress.table.columns.valueProgress')}
											<span className={styles.tableIcon}>
												<Tooltip text={t('historyProgress.table.columns.valueProgressTooltip')}>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 512 512"
														width={15}
													>
														<path
															fill="currentColor"
															d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
														/>
													</svg>
												</Tooltip>
											</span>
										</th>
									</tr>
								</thead>
								<tbody>
									{progress.map((day, i) => {
										const key = `day${i}`;
										if (i >= limit) return null;
										return (
											<Fragment key={key}>
												<tr
													className={open === i ? styles.highlight : styles.tableToggle}
													onClick={() => setOpen(open === i ? null : i)}
												>
													<td>
														{new Date(day.date).toLocaleDateString(language)}
													</td>
													<td className={styles.right}>
														{formatCurrency(day.externalTransactionTotal)}
													</td>
													<td className={styles.right}>
														{formatCurrency(day.internalTransactionTotal)}
													</td>
													<td className={styles.right}>
														{formatCurrency(day.value)}
													</td>
													<td className={styles.right}>
														{formatCurrency(day.change)}
													</td>
												</tr>
												{day.transactions && open === i
													&& day.transactions.map((transaction, index) => {
														const transactionKey = `transaction${index}`;
														return (
															<tr key={transactionKey}>
																<td className={styles.hideMobile}>
																	{' '}
																</td>
																<td className={styles.hideMobile} colSpan={3}>
																	{transactionDescription(transaction)}
																</td>
																<td className={styles.hideDesktop} colSpan={4}>
																	{transactionDescription(transaction)}
																</td>
																<td className={styles.right}>
																	{formatCurrency(transaction.volume)}
																</td>
															</tr>
														);
													})}
												{day.investorProfile && open === i && (
													<tr>
														<td className={styles.hideMobile}>
															{' '}
														</td>
														<td className={styles.hideMobile} colSpan={3}>
															{t('historyProgress.table.rows.investorProfile')}
															{' '}
															{day.investorProfile}
														</td>
														<td className={styles.hideDesktop} colSpan={4}>
															{t('historyProgress.table.rows.investorProfile')}
															{' '}
															{day.investorProfile}
														</td>
														<td />
													</tr>
												)}
												{day.skipRebalancing && open === i && (
													<tr>
														<td className={styles.hideMobile}>
															{' '}
														</td>
														<td className={styles.hideMobile} colSpan={3}>
															{t('historyProgress.table.rows.skipRebalancing')}
														</td>
														<td className={styles.hideDesktop} colSpan={4}>
															{t('historyProgress.table.rows.skipRebalancing')}
														</td>
														<td />
													</tr>
												)}
												{day.keepCashRatio > 0.02 && open === i && (
													<tr>
														<td className={styles.hideMobile}>
															{' '}
														</td>
														<td className={styles.hideMobile} colSpan={3}>
															{t('historyProgress.table.rows.cashOver')}
															{' '}
															{formatNumber(day.keepCashRatio, { style: 'percent' })}
														</td>
														<td className={styles.hideDesktop} colSpan={4}>
															{t('historyProgress.table.rows.cashOver')}
															{' '}
															{formatNumber(day.keepCashRatio, { style: 'percent' })}
														</td>
														<td />
													</tr>
												)}
											</Fragment>
										);
									})}
								</tbody>
							</table>
						</div>
						{limit < progress.length && (
							<button
								type="button"
								className={styles.loadMore}
								onClick={() => setLimit((prevState) => prevState + 20)}
							>
								{t('historyProgress.table.loadMore')}
							</button>
						)}
					</>
				)}
				{progress && currency && progress.length === 0 && (
					<div className={styles.noResult}>
						{t('historyProgress.noResult')}
					</div>
				)}
				{!(progress && currency) && (
					<Loader />
				)}
			</Container>
		</Section>
	);
}

HistoryProgress.propTypes = {
	fromDate: PropTypes.instanceOf(Date),
	toDate: PropTypes.instanceOf(Date),
	currency: PropTypes.shape({
		code: PropTypes.string.isRequired,
		codeShort: PropTypes.string.isRequired,
		codeShortBefore: PropTypes.bool.isRequired,
	}),
	portfolioId: PropTypes.number,
	progress: PropTypes.arrayOf(
		PropTypes.shape({
			change: PropTypes.number,
			date: PropTypes.string,
			externalTransactionTotal: PropTypes.number,
			internalTransactionTotal: PropTypes.number,
			investorProfile: PropTypes.string,
			keepCashRatio: PropTypes.number,
			skipRebalancing: PropTypes.bool,
			value: PropTypes.number,
			transactions: PropTypes.arrayOf(
				PropTypes.shape({
					amount: PropTypes.number,
					category: PropTypes.string,
					employer: PropTypes.shape({
						businessId: PropTypes.string,
						title: PropTypes.string,
					}),
					name: PropTypes.string,
					portfolioFrom: PropTypes.string,
					portfolioTo: PropTypes.string,
					price: PropTypes.number,
					product: PropTypes.shape({
						currencyCode: PropTypes.string,
						isin: PropTypes.string,
						name: PropTypes.string,
					}),
					volume: PropTypes.number,
				}),
			),
		}),
	),
	isProxy: PropTypes.bool,
};

HistoryProgress.defaultProps = {
	fromDate: null,
	toDate: null,
	currency: null,
	portfolioId: null,
	progress: null,
	isProxy: false,
};
