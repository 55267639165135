/* global window */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getToday, toISODate } from '../../../utils/date';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import { DateField, RadioList, SelectField } from '../../forms';

import { Col, Container } from '../../layout';
import styles from './HistoryFilter.module.scss';

export default function HistoryFilter({
	aggregationPeriod,
	fromDate,
	minDate,
	toDate,
	loading,
	setAggregationPeriod,
	setFromDate,
	setToDate,
}) {
	const wrapRef = useRef();
	const [t] = useTranslation();
	const [fixed, setFixed] = useState(false);
	const [collapsed, setCollapsed] = useState(true);
	const trackGTMEvent = useTrackGTMEvent();

	const options = [
		{
			label: `${t('historyFilter.last')}90\u00a0${t('historyFilter.days')}`,
			value: '90',
		}, {
			label: `${t('historyFilter.last')}30\u00a0${t('historyFilter.days')}`,
			value: '30',
		}, {
			label: t('historyFilter.beginning'),
			value: '0',
		}];

	const periodOptions = [{
		label: t('dashboard.filter.options.day'),
		value: 'day',
	}, {
		label: t('dashboard.filter.options.week'),
		value: 'week',
	}, {
		label: t('dashboard.filter.options.month'),
		value: 'month',
	}];

	useEffect(() => {
		function handleScroll() {
			if (wrapRef.current) {
				setFixed(window.pageYOffset >= wrapRef.current.offsetTop);
			}
		}

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	const setDays = (days = null) => {
		const from = days !== null ? getToday(days) : null;
		setFromDate(from >= minDate ? from : minDate);
		setToDate(null);
	};

	const today = getToday();

	const select = () => {
		const diffDays = Math.ceil(Math.abs(today - fromDate) / (1000 * 60 * 60 * 24)).toString();
		if (toISODate(getToday(diffDays)) === toISODate(minDate)) {
			return '0';
		}

		return diffDays;
	};

	const handleChange = (e) => {
		setDays(e.currentTarget.value !== '0' ? e.currentTarget.value : null);
	};

	return (
		<div className={styles.wrap} ref={wrapRef}>
			<div className={`${styles.root} ${fixed ? styles.fixed : ''} ${collapsed ? styles.collapsed : ''}`.trim()}>
				<Container>
					<div className={styles.grid}>
						<div className={styles.dateWrap}>
							<Col lg={6}>
								<DateField
									disabled={loading}
									id="fromDate"
									label={t('historyFilter.fromDate')}
									minDate={minDate}
									maxDate={toDate ?? today}
									name="fromDate"
									onChange={setFromDate}
									value={fromDate ?? minDate}
								/>
							</Col>
							<Col lg={6}>
								<DateField
									disabled={loading}
									id="toDate"
									label={t('historyFilter.toDate')}
									minDate={fromDate ?? minDate}
									maxDate={today}
									name="toDate"
									onChange={setToDate}
									value={toDate ?? today}
								/>
							</Col>
						</div>
						<div className={styles.selectWrap}>
							<Col lg={12}>
								<div className={styles.select}>
									<SelectField
										id="timeRange"
										label={t('historyFilter.aggregation')}
										isSmall
										options={periodOptions}
										name="name"
										onChange={(_, value) => {
											setAggregationPeriod(value);
											trackGTMEvent('interactions', {
												eventCategory: 'interactions',
												eventAction: 'buttonClick',
												eventLabel: `change_period_of_x_axis_${value}`,
											});
										}}
										value={aggregationPeriod}
									/>
								</div>
							</Col>
						</div>
						<div className={styles.radioWrap}>
							<Col lg={12}>
								<RadioList
									id="historyFilter"
									name="historyFilter"
									options={options}
									onChange={(value) => handleChange(value)}
									value={select()}
									horizontal
								/>
							</Col>
						</div>
					</div>
					{fixed && (
						<button
							type="button"
							onClick={() => setCollapsed(!collapsed)}
							className={styles.collapseTrigger}
						>
							{t('historyFilter.collapse')}
						</button>
					)}
				</Container>
			</div>
		</div>
	);
}

HistoryFilter.propTypes = {
	aggregationPeriod: PropTypes.string.isRequired,
	fromDate: PropTypes.instanceOf(Date),
	minDate: PropTypes.instanceOf(Date),
	toDate: PropTypes.instanceOf(Date),
	loading: PropTypes.bool.isRequired,
	setAggregationPeriod: PropTypes.func.isRequired,
	setFromDate: PropTypes.func.isRequired,
	setToDate: PropTypes.func.isRequired,
};

HistoryFilter.defaultProps = {
	fromDate: null,
	minDate: null,
	toDate: null,
};
