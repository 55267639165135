import React from 'react';
import PropTypes from 'prop-types';

import styles from './Row.module.scss';

export default function Row({
	center,
	children,
	middle,
	start,
}) {
	return (
		<div className={`${styles.root} ${center ? styles.center : ''} ${middle ? styles.middle : ''} ${start ? styles.start : ''}`.trim()}>
			{children}
		</div>
	);
}

Row.propTypes = {
	center: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	middle: PropTypes.bool,
	start: PropTypes.bool,
};

Row.defaultProps = {
	center: false,
	children: null,
	middle: false,
	start: false,
};
