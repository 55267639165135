import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Container, Section } from '../../../components/layout';
import { Picture } from '../../../components/ui';
import isScreenWidthUp from '../../../utils/isScreenWidthUp';
import useWindowSize from '../../../utils/useWindowSize';

import preview from './images/preview.png';
import preview2x from './images/preview@2x.png';
import previewWebP from './images/preview.webp';
import previewWebP2x from './images/preview@2x.webp';

import styles from './NewspaperEmbed.module.scss';

export default function NewspaperEmbed({
	pdfUrl,
}) {
	const [t] = useTranslation();
	const windowSize = useWindowSize();

	return (
		<Section condensed>
			<Container center>
				<div className={styles.wrap}>
					{isScreenWidthUp(windowSize.width, 'sm') ? (
						<div className={styles.embedContainer}>
							<iframe className={styles.embed} title={t('newspaper.embed.title')} src={pdfUrl} allow="autoplay" loading="lazy" />
						</div>
					) : (
						<Picture
							alt={t('newspaper.embed.alt')}
							className={styles.preview}
							src={preview}
							src2x={preview2x}
							srcWebP={previewWebP}
							src2xWebP={previewWebP2x}
						/>
					)}
				</div>
			</Container>
		</Section>
	);
}

NewspaperEmbed.propTypes = {
	pdfUrl: PropTypes.string.isRequired,
};
