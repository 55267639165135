/* global document */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useUserLogout } from '../../../contexts/UserContext';
import {
	clientCheckResetInvestmentQuestionnaire,
	onboardingResetInvestmentQuestionnaire,
} from '../../../utils/api';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import useScrollToTop from '../../../utils/useScrollToTop';
import useRequestAuth from '../../../utils/useRequestAuth';
import { Button, Modal } from '../../ui';
import ProgressIcon from '../ProgressIcon';
import Steps from '../Steps';
import StepQuestions from './StepQuestions';

import styles from './Questionnaire.module.scss';

const ESG_RATIO_WARNING_ID = 28;
const ESG_RATIO_WARNING_ANSWER_ID = 123;

const getNextPosition = (list, id) => {
	const currentIndex = list.findIndex((q) => q.id === id);
	return currentIndex >= 0 && currentIndex < list.length - 1 ? list[currentIndex + 1].position : null;
};

export default function Questionnaire({
	blogPostEsgRegulationUrl,
	childId,
	closeAccount,
	disabled,
	faqUrl,
	isStarted,
	overwriteEsgPreferenceAnswers,
	processQuestionnaires,
	product,
	questionnaireData,
	reloadClientCheck,
	reloadQuestionnaire,
	resetEsgPreferenceQuestionnaire,
	saveQuestionnaireAnswer,
	type,
}) {
	const [t] = useTranslation();
	const [position, setPosition] = useState(null);
	const [answers, setAnswers] = useState({});
	const [esgRatioWarning, setEsgRatioWarning] = useState(false);
	const [answerSkippedQuestionIds, setSkippedQuestionIds] = useState(null);
	const [answerUsPerson, setUsPerson] = useState(null);
	const [answerNotConsistent, setNotConsistent] = useState(null);
	const scrollToTop = useScrollToTop('questionnaire');
	const trackGTMEvent = useTrackGTMEvent();
	const logout = useUserLogout();
	const resetInvestmentQuestionnaireAuth = useRequestAuth(onboardingResetInvestmentQuestionnaire);
	const clientCheckResetInvestmentQuestionnaireAuth = useRequestAuth(clientCheckResetInvestmentQuestionnaire);

	const skippedQuestionIds = answerSkippedQuestionIds ?? questionnaireData.skippedQuestionIds;
	const usPerson = answerUsPerson ?? questionnaireData.usPerson;
	const notConsistent = answerNotConsistent ?? questionnaireData.notConsistent;

	const questionnaireList = useMemo(
		() => [...(questionnaireData?.questionnaire ?? [])].sort((a, b) => a.position - b.position),
		[questionnaireData?.questionnaire],
	);
	const positionList = useMemo(
		// first question of each position
		() => questionnaireList.filter((v, i, a) => a.findIndex((q) => (q.position === v.position)) === i)
			.map((q) => q.position),
		[questionnaireList],
	);
	const questionnaireLength = positionList.length;

	const disabledPositions = useMemo(
		() => positionList.filter((pos) => (
			questionnaireList.filter((question) => question.position === pos)
				.every((question) => skippedQuestionIds.includes(question.id))
		)),
		[skippedQuestionIds, questionnaireList, positionList],
	);

	useEffect(() => {
		if (questionnaireData !== null && isStarted && (!position || position === positionList[0])) {
			document.getElementById('questionnaire')?.scrollIntoView({
				behavior: 'smooth',
			});
		}
	}, [position, isStarted]);

	if (questionnaireData === null) {
		return null;
	}

	let activePosition = position;
	if (activePosition === null && questionnaireData.lastAnsweredQuestionId !== null) {
		activePosition = getNextPosition(questionnaireList, questionnaireData.lastAnsweredQuestionId);
	}
	if (activePosition === null) {
		activePosition = positionList[0] ?? null;
	}

	const questions = questionnaireList.filter((question) => question.position === activePosition);
	const profileChoice = questions[0]?.type === 'investmentProfileChoice';

	const subStep = (finished) => processQuestionnaires.length > 1 && processQuestionnaires.filter(
		(questionnaire) => (questionnaire.questionnaire === type || questionnaire.finished) === finished,
	).map((questionnaire) => (
		<div key={questionnaire.questionnaire} className={styles.stepContent}>
			<div className={styles.subStep}>
				<span className={styles.subStepIcon}>
					<ProgressIcon completed={questionnaire.finished} />
				</span>
				<h3 className={styles.subStepTitle}>
					{t(`onboarding.steps.questionnaires.${questionnaire.questionnaire}`)}
				</h3>
			</div>
		</div>
	));

	const handleReloadQuestionnaire = (resetPosition = false) => {
		setPosition(resetPosition ? positionList[0] ?? null : null);
		setAnswers({});
		setEsgRatioWarning(false);
		setSkippedQuestionIds(null);
		setUsPerson(null);
		setNotConsistent(null);
		reloadQuestionnaire();
	};

	const handleResetPosition = () => {
		setPosition(positionList[0] ?? null);
	};

	const handleResetInvestmentQuestionnaire = async () => {
		if (reloadClientCheck) {
			await clientCheckResetInvestmentQuestionnaireAuth(childId);
			reloadClientCheck();
		} else {
			await resetInvestmentQuestionnaireAuth(childId);
		}

		reloadQuestionnaire();
	};

	const handleSaveQuestionnaireAnswer = async (questionId, answer, answerId, answerValue, answerText) => {
		if (questionId === ESG_RATIO_WARNING_ID && answerId === ESG_RATIO_WARNING_ANSWER_ID) {
			setEsgRatioWarning(true);
		}

		const result = await saveQuestionnaireAnswer(questionId, answerId, answerValue, answerText, childId);

		if (answer !== null) {
			setAnswers((prevState) => ({
				...prevState,
				[questionId]: answer,
			}));
		}

		if (result.skippedQuestionIds) {
			setSkippedQuestionIds(result.skippedQuestionIds);
		}

		if (result.usPerson) {
			setUsPerson(result.usPerson);
		}

		if (result.notConsistent) {
			setNotConsistent(result.notConsistent);
		}

		return result;
	};

	const handleSaveQuestionnaireAnswerResult = (result) => {
		const nextPosition = getNextPosition(questionnaireList, result.lastAnsweredQuestionId);

		if (nextPosition !== null) {
			setPosition(nextPosition);
			scrollToTop();
		} else if (type === 'AML') {
			trackGTMEvent('conversionFunnel', {
				eventCategory: 'questionnaireFinished',
				eventAction: '',
				eventLabel: '',
			});

			handleReloadQuestionnaire();
		} else {
			handleReloadQuestionnaire();
		}
	};

	const handleLogout = () => {
		logout(null, true, t('onboarding.steps.questionnaire.logout'));
	};

	return (
		<>
			{subStep(true)}
			<div id="questionnaire">
				{questionnaireLength > 0 && (
					<div className={styles.box}>
						<Steps
							disabled={disabled || esgRatioWarning || usPerson || notConsistent || profileChoice}
							disabledPositions={disabledPositions}
							position={activePosition}
							setPosition={setPosition}
							positionList={positionList}
						/>
						<StepQuestions
							answers={answers}
							childId={childId}
							disabled={disabled || esgRatioWarning || usPerson || notConsistent}
							faqUrl={faqUrl}
							overwriteEsgPreferenceAnswers={overwriteEsgPreferenceAnswers}
							product={product}
							questions={questions}
							reloadQuestionnaire={handleReloadQuestionnaire}
							resetEsgPreferenceQuestionnaire={resetEsgPreferenceQuestionnaire}
							resetPosition={handleResetPosition}
							saveQuestionnaireAnswer={handleSaveQuestionnaireAnswer}
							saveQuestionnaireAnswerResult={handleSaveQuestionnaireAnswerResult}
						/>
					</div>
				)}
			</div>
			{subStep(false)}

			<Modal
				isVisible={!disabled && usPerson}
				title={t('onboarding.steps.questionnaire.americanModal.title')}
			>
				<div className={styles.modal}>
					<p className={`${styles.modalText} ${styles.smallOffset}`.trim()}>
						{t('onboarding.steps.questionnaire.americanModal.text')}
					</p>
					<div className={styles.modalFooter}>
						<Button
							disabled={disabled}
							label={t('onboarding.steps.questionnaire.americanModal.link')}
							onClick={closeAccount}
							isLink
						/>
						<Button
							disabled={disabled}
							label={t('onboarding.steps.questionnaire.americanModal.button')}
							onClick={handleLogout}
						/>
					</div>
				</div>
			</Modal>

			<Modal
				isVisible={!disabled && !usPerson && notConsistent}
				title={t('investorProfileChoice.notConsistent.title')}
			>
				<div className={styles.modal}>
					<p className={styles.modalText}>
						{t('investorProfileChoice.notConsistent.text')}
					</p>
					<Button
						disabled={disabled}
						label={t('investorProfileChoice.notSuitable.back')}
						onClick={handleResetInvestmentQuestionnaire}
					/>
				</div>
			</Modal>

			<Modal
				isVisible={!disabled && !usPerson && !notConsistent && esgRatioWarning}
				title={t('onboarding.steps.questionnaire.esgRatioModal.title')}
			>
				<div className={styles.modal}>
					<Trans i18nKey="onboarding.steps.questionnaire.esgRatioModal.text">
						<p className={`${styles.modalText} ${styles.smallOffset}`.trim()} />
						<p className={`${styles.modalText} ${styles.smallOffset} ${styles.modalTextSmall}`.trim()}>
							{blogPostEsgRegulationUrl ? <Link to={blogPostEsgRegulationUrl} target="_blank" /> : <></>}
						</p>
					</Trans>
					<div className={styles.modalFooter}>
						<Button
							disabled={disabled}
							label={t('onboarding.steps.questionnaire.esgRatioModal.button')}
							onClick={() => {
								setEsgRatioWarning(false);
								handleResetPosition();
							}}
							outline
						/>
						<Button
							disabled={disabled}
							label={t('onboarding.steps.questionnaire.esgRatioModal.continue')}
							onClick={() => {
								setEsgRatioWarning(false);
							}}
						/>
					</div>
				</div>
			</Modal>
		</>
	);
}

Questionnaire.propTypes = {
	blogPostEsgRegulationUrl: PropTypes.string,
	childId: PropTypes.number,
	closeAccount: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	faqUrl: PropTypes.string.isRequired,
	isStarted: PropTypes.bool.isRequired,
	overwriteEsgPreferenceAnswers: PropTypes.func.isRequired,
	processQuestionnaires: PropTypes.arrayOf(PropTypes.shape({
		questionnaire: PropTypes.string.isRequired,
		finished: PropTypes.bool.isRequired,
	}).isRequired),
	product: PropTypes.string,
	questionnaireData: PropTypes.shape({
		lastAnsweredQuestionId: PropTypes.number,
		questionnaire: PropTypes.arrayOf(
			PropTypes.shape({
				answers: PropTypes.arrayOf(
					PropTypes.shape({
						id: PropTypes.number.isRequired,
						answer: PropTypes.string.isRequired,
						position: PropTypes.number.isRequired,
					}),
				).isRequired,
				description: PropTypes.string,
				id: PropTypes.number.isRequired,
				note: PropTypes.string,
				position: PropTypes.number.isRequired,
				question: PropTypes.string.isRequired,
				questionnaire: PropTypes.string.isRequired,
				tooltip: PropTypes.string,
				type: PropTypes.string.isRequired,
				userAnswer: PropTypes.shape({
					id: PropTypes.number,
					value: PropTypes.number,
					text: PropTypes.string,
				}),
			}),
		),
		notConsistent: PropTypes.bool,
		skippedQuestionIds: PropTypes.arrayOf(PropTypes.number.isRequired),
		usPerson: PropTypes.bool,
	}),
	reloadClientCheck: PropTypes.func,
	reloadQuestionnaire: PropTypes.func.isRequired,
	resetEsgPreferenceQuestionnaire: PropTypes.func.isRequired,
	saveQuestionnaireAnswer: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
};

Questionnaire.defaultProps = {
	blogPostEsgRegulationUrl: null,
	childId: null,
	disabled: false,
	processQuestionnaires: [],
	product: null,
	questionnaireData: null,
	reloadClientCheck: null,
};
