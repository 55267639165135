import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { cancelAccountClosure } from '../../../utils/api';
import { useUserLogout, useUserReload } from '../../../contexts/UserContext';
import useRequestAuth from '../../../utils/useRequestAuth';
import {
	Button,
	EmailLink,
	Modal,
} from '../../ui';

import styles from './QuestionnaireClosedAccount.module.scss';

export default function QuestionnaireClosedAccount({
	accountUrl,
	childId,
	isVisible,
	onCancelClosure,
}) {
	const [t] = useTranslation();
	const logout = useUserLogout();
	const reloadUser = useUserReload();
	const cancelAccountClosureAuth = useRequestAuth(cancelAccountClosure);

	const handleLogout = () => {
		logout();
	};

	const handleCancelClosure = async () => {
		await cancelAccountClosureAuth(childId);
		if (onCancelClosure !== null) {
			onCancelClosure();
		} else {
			reloadUser();
		}
	};

	return (
		<Modal isVisible={isVisible}>
			<div className={styles.modal}>
				<div className={styles.icon}>
					<svg
						height={30}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 576 512"
					>
						<path
							fill="currentColor"
							d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
						/>
					</svg>
				</div>
				<h4 className={styles.title}>
					{t('onboarding.steps.questionnaire.cancelModal.title')}
				</h4>
				<div className={styles.text}>
					<p>
						<Trans i18nKey="onboarding.steps.questionnaire.cancelModal.text">
							<EmailLink />
						</Trans>
					</p>
				</div>
				<div className={styles.modalFooter}>
					{accountUrl !== null && (
						<Button
							label={t('onboarding.steps.questionnaire.cancelModal.return')}
							outline
							onClick={handleCancelClosure}
						/>
					)}
					<Button
						label={t('onboarding.steps.questionnaire.cancelModal.logout')}
						onClick={handleLogout}
					/>
				</div>
			</div>
		</Modal>
	);
}

QuestionnaireClosedAccount.propTypes = {
	accountUrl: PropTypes.string,
	childId: PropTypes.number,
	isVisible: PropTypes.bool.isRequired,
	onCancelClosure: PropTypes.func,
};

QuestionnaireClosedAccount.defaultProps = {
	accountUrl: null,
	childId: null,
	onCancelClosure: null,
};
