import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import {
	logout,
	fetchProfileIdentity,
} from '../utils/api';
import { useTrackGTMUser } from '../utils/GTMProvider';
import { toNumber } from '../utils/number';
import { setSessionPortfolioId } from '../utils/sessionPortfolio';
import useFetchWithReload from '../utils/useFetchWithReload';
import { useLanguage } from './LocaleContext';

const noop = () => {};
const UserContext = React.createContext([null, false, noop, noop, noop]);

const useFetchProfileIdentity = useFetchWithReload(({ current: language }) => fetchProfileIdentity(language));

export const filterChild = (child, user) => (child && toNumber(child.id) !== toNumber(user?.id) ? child : null);

export function useUserContext() {
	const [user, loading] = useContext(UserContext);
	return [user, loading];
}

export function useLogoutMessage() {
	const [, , message, , , , dismiss] = useContext(UserContext);
	return [message, dismiss];
}

export function useUserLogin() {
	const [, , , handleLogin] = useContext(UserContext);
	return handleLogin;
}

export function useUserLogout() {
	const [, , , , handleLogout] = useContext(UserContext);
	return handleLogout;
}

export function useUserReload() {
	const [, , , , , reload] = useContext(UserContext);
	return reload;
}

export function UserProvider({ children }) {
	const [active, setActive] = useState(true);
	const [logoutMessage, setLogoutMessage] = useState(null);
	const language = useLanguage();
	const initialRef = useRef(true);
	const loggingOutRef = useRef(null);
	const languageRef = useRef(language);
	const [user, loading, error, , reload] = useFetchProfileIdentity(null, languageRef);
	const location = useLocation();
	const navigate = useNavigate();
	const navigateRef = useRef(navigate);
	const trackGTMUser = useTrackGTMUser();

	const loggedIn = active && !loading && !error && user !== null;
	const effectiveUser = loggedIn ? user : null;
	const effectiveLoading = active && loading;
	const locationFrom = location.state?.from ?? null;

	// reload user identity `in the background` when the language changes
	useEffect(() => {
		if (initialRef.current) {
			initialRef.current = false;
		} else {
			languageRef.current = language;
			reload();
		}
	}, [language]);

	// navigate changes with each page change, keep it in ref instead of including it in useCallback dependencies
	useEffect(() => {
		navigateRef.current = navigate;
	}, [navigate]);

	useEffect(() => {
		if (effectiveUser !== null) {
			trackGTMUser(
				effectiveUser.id,
				effectiveUser.created_at,
				effectiveUser.first_name,
				effectiveUser.last_name,
				effectiveUser.email,
				effectiveUser.country_id,
			);
		}
	}, [effectiveUser, trackGTMUser]);

	const handleLogin = useCallback((url = null) => {
		setSessionPortfolioId(null);
		reload(true);
		setActive(true);
		if (locationFrom !== null) {
			(navigateRef.current)(locationFrom, { replace: true });
		} else if (url !== null) {
			(navigateRef.current)(url, { replace: true });
		}
	}, [reload, locationFrom]);

	const handleLogout = useCallback((url = null, apiLogout = true, message = null) => {
		setSessionPortfolioId(null);
		if (url !== null) {
			(navigateRef.current)(url);
		}
		setActive(false);
		setLogoutMessage(message);

		if (!apiLogout) {
			return Promise.resolve().then(() => trackGTMUser(null));
		}

		if (loggingOutRef.current !== null) {
			return loggingOutRef.current;
		}

		loggingOutRef.current = logout()
			.then(() => trackGTMUser(null))
			.finally(() => {
				loggingOutRef.current = null;
			});
		return loggingOutRef.current;
	}, [trackGTMUser]);

	const dismissLogoutMessage = useCallback(() => {
		setLogoutMessage(null);
	}, []);

	const value = useMemo(() => [
		effectiveUser,
		effectiveLoading,
		logoutMessage,
		handleLogin,
		handleLogout,
		reload,
		dismissLogoutMessage,
	], [effectiveUser, effectiveLoading, logoutMessage, handleLogin, handleLogout, reload, dismissLogoutMessage]);

	return (
		<UserContext.Provider value={value}>
			{children}
		</UserContext.Provider>
	);
}

UserProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

UserProvider.defaultProps = {
	children: null,
};
