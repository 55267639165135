import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './ProductTerms.module.scss';

export default function ProductTerms({ blogPostLongTermUrl, product }) {
	const [t] = useTranslation();

	return (
		<div className={styles.wrap}>
			<h3 className={styles.title}>
				{t(`products.${product}.terms.title`)}
			</h3>
			<Trans i18nKey={`products.${product}.terms.text`}>
				<p className={styles.subTitle} />
				<p className={styles.text}>
					<strong />
					{blogPostLongTermUrl ? <Link to={blogPostLongTermUrl} target="_blank" /> : <></>}
				</p>
			</Trans>
		</div>
	);
}

ProductTerms.propTypes = {
	blogPostLongTermUrl: PropTypes.string,
	product: PropTypes.string.isRequired,
};

ProductTerms.defaultProps = {
	blogPostLongTermUrl: null,
};
