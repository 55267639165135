import React from 'react';
import PropTypes from 'prop-types';

import styles from './SliderArrow.module.scss';

export default function SliderArrow({
	disabled,
	horizontal,
	onClick,
	text,
	type,
}) {
	let className = '';
	if (type === 'next') {
		className = horizontal ? styles.nextHorizontal : styles.next;
	} else {
		className = horizontal ? styles.prevHorizontal : styles.prev;
	}

	return (
		<button
			className={`${styles.arrow} ${className}`}
			disabled={disabled}
			onClick={onClick}
			type="button"
		>
			{text}
		</button>
	);
}

SliderArrow.propTypes = {
	disabled: PropTypes.bool,
	horizontal: PropTypes.bool,
	onClick: PropTypes.func,
	text: PropTypes.string.isRequired,
	type: PropTypes.oneOf(['prev', 'next']).isRequired,
};

SliderArrow.defaultProps = {
	disabled: false,
	horizontal: false,
	onClick: null,
};
