import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import {
	Col,
	Container,
	Row,
	Section,
} from '../../../components/layout';
import { ButtonLink, ExternalLink } from '../../../components/ui';

import styles from './InvestingTryout.module.scss';

export default function InvestingTryout({
	homepageUrl,
}) {
	const [t] = useTranslation();

	return (
		<Section condensed>
			<Container center>
				<Row>
					<Col lg={5}>
						<div className={styles.wrap}>
							<h3 className={styles.title}>
								{t('investingTryout.title')}
							</h3>
							<Trans i18nKey="investingTryout.description">
								<p className={styles.description}>
									<ExternalLink
										className={styles.descriptionSemibold}
										to={homepageUrl}
									/>
								</p>
							</Trans>
						</div>
					</Col>
					<Col lg={7}>
						<div className={`${styles.wrap} ${styles.wrapBorder}`}>
							<h4 className={styles.subtitle}>
								{t('investingTryout.subtitle')}
							</h4>
							<ul className={styles.list}>
								{Object.keys(t('investingTryout.list', { returnObjects: true })).map((item) => (
									<li key={item} className={styles.listItem}>
										<span className={styles.listTitle}>
											{t(`investingTryout.list.${item}.title`)}
										</span>
										<p className={styles.listText}>
											{t(`investingTryout.list.${item}.text`)}
										</p>
									</li>
								))}
							</ul>
						</div>
					</Col>
				</Row>
				<div className={styles.cta}>
					<ButtonLink
						label={t('investingTryout.button')}
						to={homepageUrl}
						external
					/>
				</div>
			</Container>
		</Section>
	);
}

InvestingTryout.propTypes = {
	homepageUrl: PropTypes.string.isRequired,
};
