import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useLanguage } from '../../../contexts/LocaleContext';
import useFormatNumber from '../../../utils/useFormatNumber';
import {
	Col,
	Container,
	Row,
	Section,
} from '../../layout';

import styles from './TradingStates.module.scss';

export default function TradingStates({
	portfolioCurrency,
	portfolioSettingsAnchor,
	portfolioWithdrawalsAnchor,
	states,
}) {
	const [t] = useTranslation();
	const language = useLanguage();
	const formatNumber = useFormatNumber();
	const formatCurrency = (value, currency = null) => formatNumber(value, {
		style: 'currency',
		currency: currency ?? portfolioCurrency?.code,
	});
	const formatPercent = useFormatNumber({ style: 'percent', fractionDigits: 2 });
	const formatDate = (date) => new Date(date).toLocaleDateString(language);

	return (
		<Section bordered id="trading">
			<Container>
				<Row>
					<Col md={6}>
						<h2 className={styles.title}>
							{t('tradingStates.title')}
						</h2>
						{states.hadInitial === 0 && (
							<p className={styles.text}>
								{t('tradingStates.states.initial')}
							</p>
						)}
						{states.hasDeposit === 1 && states.nextDepositScheduledFor && (
							<p className={styles.text}>
								{t('tradingStates.states.deposit', {
									sum: formatCurrency(states.sumDeposits),
									scheduled: formatDate(states.nextDepositScheduledFor),
								})}
							</p>
						)}
						{states.withdrawalStatus === 1 && states.nextWithdrawalScheduledFor && (
							<p className={styles.text}>
								<Trans i18nKey="tradingStates.states.withdrawalPart">
									<Link to={`#${portfolioWithdrawalsAnchor}`} />
									{{ sum: formatCurrency(states.sumCashWithdrawals) }}
									{{ scheduled: formatDate(states.nextWithdrawalScheduledFor) }}
								</Trans>
							</p>
						)}
						{states.withdrawalStatus === 2 && states.nextWithdrawalScheduledFor && (
							<p className={styles.text}>
								<Trans i18nKey="tradingStates.states.withdrawalAll">
									<Link to={`#${portfolioWithdrawalsAnchor}`} />
									{{ scheduled: formatDate(states.nextWithdrawalScheduledFor) }}
								</Trans>
							</p>
						)}
						{states.withdrawalStatus === 3 && states.nextWithdrawalScheduledFor && (
							<p className={styles.text}>
								<Trans i18nKey="tradingStates.states.withdrawalCancel">
									<Link to={`#${portfolioWithdrawalsAnchor}`} />
									{{ scheduled: formatDate(states.nextWithdrawalScheduledFor) }}
								</Trans>
							</p>
						)}
						{states.withdrawalStatus === 4 && (
							<p className={styles.text}>
								{t('tradingStates.states.withdrawalPartClosed', {
									sum: formatCurrency(states.sumCashWithdrawals),
								})}
							</p>
						)}
						{states.withdrawalStatus === 5 && (
							<p className={styles.text}>
								{t('tradingStates.states.withdrawalAllClosed')}
							</p>
						)}
						{states.withdrawalStatus === 6 && (
							<p className={styles.text}>
								{t('tradingStates.states.withdrawalCancelClosed')}
							</p>
						)}
						{states.hasDepositAndWithdrawal === 1 && (
							<p className={styles.text}>
								{t('tradingStates.states.depositAndWithdrawal')}
							</p>
						)}
						{states.investorProfileChange === 1 && (
							<p className={styles.text}>
								{t('tradingStates.states.profileChange', {
									profile: states.investorProfile,
									planned: states.investorProfilePlanned,
								})}
							</p>
						)}
						{states.skipRebalancing === 0 && states.nextRebalancingDate && (
							<p className={styles.text}>
								{t('tradingStates.states.rebalancing', {
									date: formatDate(states.nextRebalancingDate),
								})}
							</p>
						)}
						{states.skipRebalancing === 1 && (
							<p className={styles.text}>
								<Trans i18nKey="tradingStates.states.rebalancingSkip">
									<Link to={`#${portfolioSettingsAnchor}`} />
								</Trans>
							</p>
						)}
						{states.keepCashRatioChange === 1 && (
							<p className={styles.text}>
								{t('tradingStates.states.cash', {
									cash: formatPercent(states.keepCashRatio),
									planned: formatPercent(states.keepCashRatioPlanned),
								})}
							</p>
						)}
						{states.keepCashRatioChange === 2 && (
							<p className={styles.text}>
								<Trans i18nKey="tradingStates.states.cashOver">
									<Link to={`#${portfolioSettingsAnchor}`} />
									{{ cash: formatPercent(states.keepCashRatio) }}
								</Trans>
							</p>
						)}
						{states.allocatedDeposits > 0 && (
							<p className={styles.text}>
								{t('tradingStates.states.allocatedDeposit', {
									sum: states.sumAllocatedDeposits.map(({ volume, currency }) => (
										formatCurrency(volume, currency)
									)).join(', '),
									date: formatDate(states.nextTradingDate),
								})}
							</p>
						)}
						{states.withdrawalTransferStatus === 1 && (
							<p className={styles.text}>
								<Trans i18nKey="tradingStates.states.withdrawalTransferPart">
									<Link to={`#${portfolioSettingsAnchor}`} />
								</Trans>
							</p>
						)}
						{states.withdrawalTransferStatus === 2 && (
							<p className={styles.text}>
								<Trans i18nKey="tradingStates.states.withdrawalTransferAll">
									<Link to={`#${portfolioSettingsAnchor}`} />
								</Trans>
							</p>
						)}
						{states.withdrawalTransferStatus === 3 && (
							<p className={styles.text}>
								<Trans i18nKey="tradingStates.states.withdrawalTransferPartClosed">
									<Link to={`#${portfolioSettingsAnchor}`} />
								</Trans>
							</p>
						)}
						{states.withdrawalTransferStatus === 4 && (
							<p className={styles.text}>
								<Trans i18nKey="tradingStates.states.withdrawalTransferAllClosed">
									<Link to={`#${portfolioSettingsAnchor}`} />
								</Trans>
							</p>
						)}
					</Col>
				</Row>
			</Container>
		</Section>
	);
}

TradingStates.propTypes = {
	portfolioCurrency: PropTypes.shape({
		code: PropTypes.string.isRequired,
		codeShort: PropTypes.string.isRequired,
		codeShortBefore: PropTypes.bool.isRequired,
	}).isRequired,
	states: PropTypes.shape({
		allocatedDeposits: PropTypes.number,
		hadInitial: PropTypes.number,
		hasDeposit: PropTypes.number,
		hasDepositAndWithdrawal: PropTypes.number,
		investorProfile: PropTypes.string,
		investorProfileChange: PropTypes.number,
		investorProfilePlanned: PropTypes.string,
		keepCashRatio: PropTypes.number,
		keepCashRatioChange: PropTypes.number,
		keepCashRatioPlanned: PropTypes.number,
		nextDepositScheduledFor: PropTypes.string,
		nextRebalancingDate: PropTypes.string,
		nextWithdrawalScheduledFor: PropTypes.string,
		skipRebalancing: PropTypes.number,
		sumAllocatedDeposits: PropTypes.arrayOf(
			PropTypes.shape({
				volume: PropTypes.number,
				currency: PropTypes.string,
			}).isRequired,
		).isRequired,
		sumCashWithdrawals: PropTypes.number,
		sumDeposits: PropTypes.number,
		withdrawalStatus: PropTypes.number,
		withdrawalTransferStatus: PropTypes.number,
		nextTradingDate: PropTypes.string,
	}).isRequired,
	portfolioSettingsAnchor: PropTypes.string.isRequired,
	portfolioWithdrawalsAnchor: PropTypes.string.isRequired,
};
