import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

import {
	portfolioWithdrawal,
	portfolioWithdrawalVolume,
} from '../../../../utils/api';
import { useTrackGTMEvent } from '../../../../utils/GTMProvider';
import { toInt } from '../../../../utils/number';
import useRequestAuth from '../../../../utils/useRequestAuth';
import { RadioList, SelectField, TextField } from '../../../forms';
import { Container } from '../../../layout';
import {
	Alert,
	Button,
	Loader,
	Modal,
} from '../../../ui';

import styles from '../PortfolioWithdrawals.module.scss';

export default function PortfolioWithdrawal({
	currencies,
	disabledAll,
	disabledVolume,
	pensionCheck,
	portfolio,
	setCheck,
}) {
	const [confirm, setConfirm] = useState(false);
	const [confirmVolume, setConfirmVolume] = useState(false);
	const [volumeError, setVolumeError] = useState(false);
	const [withdrawalError, setWithdrawalError] = useState(null);
	const [orderError, setOrderError] = useState(false);
	const [has2fa, setHas2fa] = useState(false);
	const portfolioWithdrawalAuth = useRequestAuth(portfolioWithdrawal);
	const portfolioWithdrawalVolumeAuth = useRequestAuth(portfolioWithdrawalVolume);
	const [t] = useTranslation();
	const trackGTMEvent = useTrackGTMEvent();

	const loading = currencies === null;

	const currencySelect = currencies !== null && currencies.length > 1;
	const currencyDefault = currencies !== null
		? currencies.find(({ code }) => code === portfolio.currency) ?? currencies[0] ?? null
		: null;
	const currencyDefaultValue = currencyDefault !== null ? currencyDefault.code : null;
	const currencyOptions = currencies !== null ? currencies.map(({ code }) => ({
		label: code,
		value: code,
	})) : null;

	const checkVolume = async (currency, volume) => {
		setVolumeError(false);
		try {
			const result = await portfolioWithdrawalVolumeAuth(
				portfolio.id,
				currency,
				volume,
			);
			if (result.success && result.warning === null) {
				setConfirm(true);
			} else if (result.warning === 'almostTotalValue') {
				setConfirmVolume(true);
			} else if (result.warning === 'overTotalValue') {
				setVolumeError(true);
			}
		} catch (e) {
			const errorMessage = e.responseJson?.message;
			if (typeof errorMessage === 'string') {
				if (errorMessage.indexOf('This type of withdrawal cannot be created now') !== -1) {
					setOrderError(true);
				}
			}
			setWithdrawalError('forms.error');
		}
	};

	const withdrawal = (
		<Formik
			initialValues={{
				twoFactorCode: '',
				type: 1,
				volume: '',
				currency: currencySelect ? currencyDefaultValue : null,
			}}
			onSubmit={async (values, { setErrors, setFieldValue }) => {
				const currency = currencySelect ? values.currency : currencyDefaultValue;
				const volume = values.type === 1 ? toInt(values.volume) || 0 : null;
				if (currency === null || (values.type === 1 && volume <= 0)) {
					return;
				}

				let show2fa = false;
				setWithdrawalError(null);
				try {
					await portfolioWithdrawalAuth(
						portfolio.id,
						values.type,
						volume,
						has2fa ? values.twoFactorCode : null,
						currency,
					);
				} catch (e) {
					const fieldErrors = {};
					const errorMessage = e.responseJson?.message;
					let formError = 'forms.error';

					if (typeof errorMessage === 'string') {
						if (errorMessage.indexOf('higher than the current value of the portfolio') !== -1) {
							fieldErrors.volume = 'account.withdrawals.withdrawals.part.overError';
							setFieldValue('twoFactorCode', '', false);
							setHas2fa(false);
							setConfirm(false);
						} else if (errorMessage.indexOf('Two factor authentication code needed') !== -1) {
							setHas2fa(true);
							show2fa = true;
						} else if (errorMessage.indexOf('Bad two factor authentication code') !== -1) {
							fieldErrors.twoFactorCode = 'forms.twoFactorAuthentication.error';
						} else if (errorMessage.indexOf('This type of withdrawal cannot be created now') !== -1) {
							setOrderError(true);
							setConfirm(false);
						} else if (errorMessage.indexOf('The 10% limit for child withdrawals has been exceeded') !== -1) {
							formError = 'account.withdrawals.withdrawals.childRatioError';
						}
					}

					const hasFieldErrors = Object.keys(fieldErrors).length > 0;
					if (hasFieldErrors) {
						setErrors(fieldErrors);
					}
					setWithdrawalError(!hasFieldErrors && !show2fa ? formError : null);
					return;
				}
				trackGTMEvent('interactions', {
					eventCategory: 'interactions',
					eventAction: 'buttonClick',
					eventLabel: 'insert_withdrawal_cash',
				});
				setCheck(true);
				setHas2fa(false);
				setConfirm(false);
				setConfirmVolume(false);
			}}
		>
			{({
				handleSubmit, values, setFieldValue, errors, touched, handleBlur, handleChange, isSubmitting,
			}) => (
				<form onSubmit={handleSubmit}>
					{pensionCheck === false && (
						<Alert
							autoScroll={false}
							type="danger"
						>
							<h4 className={styles.listTitle}>
								{t('account.withdrawals.withdrawals.pensionWarningTitle')}
							</h4>
							<ul className={styles.list}>
								<Trans i18nKey="account.withdrawals.withdrawals.pensionWarning">
									<li className={styles.listItem} />
								</Trans>
							</ul>
						</Alert>
					)}
					{portfolio.child && (
						<Alert
							autoScroll={false}
							type="warning"
						>
							<ul className={styles.list}>
								<Trans i18nKey="account.withdrawals.withdrawals.childWarning">
									<li className={styles.listItem} />
								</Trans>
							</ul>
						</Alert>
					)}
					<div className={styles.input}>
						<SelectField
							id="type"
							name="type"
							label={t('account.withdrawals.withdrawals.title')}
							isLabelHidden
							options={[{
								label: t('account.withdrawals.withdrawals.part.title'),
								value: 1,
								disabled: disabledVolume,
							}, {
								label: t('account.withdrawals.withdrawals.all.title'),
								value: 2,
								disabled: disabledAll,
							}]}
							onChange={setFieldValue}
							value={values.type}
						/>
					</div>
					{values.type === 1 && (
						<>
							<div className={styles.input}>
								{!currencySelect && currencyDefault?.codeShortBefore === true && (
									<div className={styles.currency}>
										{currencyDefault.codeShort}
									</div>
								)}
								<TextField
									onBlur={handleBlur}
									onChange={handleChange}
									error={(
										errors.volume
										&& touched.volume
										&& t(errors.volume)
									) || (
										volumeError
											? t('account.withdrawals.withdrawals.part.overError')
											: ''
									)}
									id="volume"
									name="volume"
									label="volume"
									minLength={1}
									maxLength={13}
									isLabelHidden
									required
									type="text"
									pattern="[0-9]+"
									value={values.volume}
								/>
								{!currencySelect && currencyDefault?.codeShortBefore === false && (
									<div className={styles.currency}>
										{currencyDefault.codeShort}
									</div>
								)}
								{currencySelect && (
									<div className={styles.currencySelect}>
										<RadioList
											name="currency"
											id="withdrawalCurrency"
											onChange={handleChange}
											options={currencyOptions}
											value={values.currency}
										/>
									</div>
								)}
							</div>
							{values.currency !== null && values.currency !== portfolio.currency && (
								<Alert type="warning">
									{t('account.withdrawals.withdrawals.currencyWarning')}
								</Alert>
							)}
							<p className={styles.text}>
								{t('account.withdrawals.withdrawals.part.text')}
							</p>
						</>
					)}
					{values.type === 2 && (
						<>
							{currencySelect && (
								<RadioList
									name="currency"
									id="withdrawalCurrency"
									onChange={handleChange}
									options={currencyOptions}
									value={values.currency}
								/>
							)}
							{values.currency !== null && values.currency !== portfolio.currency && (
								<Alert type="warning">
									{t('account.withdrawals.withdrawals.currencyWarning')}
								</Alert>
							)}
							<p className={styles.text}>
								{t('account.withdrawals.withdrawals.all.text')}
							</p>
						</>
					)}
					<Modal
						isVisible={confirmVolume}
						onClose={() => {
							setFieldValue('twoFactorCode', '', false);
							setConfirmVolume(false);
							setHas2fa(false);
							setWithdrawalError(null);
						}}
						title={t('account.withdrawals.withdrawals.allPopup.title')}
					>
						<div className={styles.modal}>
							{withdrawalError !== null && (
								<Alert type="danger">
									{t(withdrawalError)}
								</Alert>
							)}
							{values.currency !== null && values.currency !== portfolio.currency && (
								<Alert type="warning">
									{t('account.withdrawals.withdrawals.currencyWarning')}
								</Alert>
							)}
							<p className={styles.modalText}>
								{t('account.withdrawals.withdrawals.allPopup.text')}
							</p>
							{has2fa && (
								<>
									<TextField
										autocomplete="one-time-code"
										autoFocus={has2fa}
										onBlur={handleBlur}
										onChange={handleChange}
										error={
											errors.twoFactorCode
											&& touched.twoFactorCode
											&& t(errors.twoFactorCode)
										}
										id="twoFactorCode"
										name="twoFactorCode"
										label={t('account.withdrawals.withdrawals.confirmPopup.twoFactorAuthentication')}
										required={has2fa}
										type="text"
										inputMode="numeric"
										pattern="[0-9]*"
										value={values.twoFactorCode}
									/>
									<Button
										disabled={isSubmitting}
										isSubmit
										label={t('account.withdrawals.withdrawals.confirm')}
									/>
								</>
							)}
							{!has2fa && (
								<>
									<Button
										disabled={isSubmitting}
										isSubmit={confirmVolume}
										onClick={() => {
											setFieldValue('type', 2);
										}}
										label={t('account.withdrawals.withdrawals.allPopup.button')}
									/>
									<Button
										disabled={isSubmitting}
										isSubmit={confirmVolume}
										isLink
										label={t('account.withdrawals.withdrawals.allPopup.secondaryButton')}
									/>
								</>
							)}
						</div>
					</Modal>
					<Modal
						isVisible={confirm}
						onClose={() => {
							setFieldValue('twoFactorCode', '', false);
							setConfirm(false);
							setHas2fa(false);
							setWithdrawalError(null);
						}}
						title={t('account.withdrawals.withdrawals.confirmPopup.title')}
					>
						<div className={styles.modal}>
							<p className={styles.modalText}>
								{t('account.withdrawals.withdrawals.confirmPopup.text')}
							</p>
							{withdrawalError !== null && (
								<Alert type="danger">
									{t(withdrawalError)}
								</Alert>
							)}
							{values.currency !== null && values.currency !== portfolio.currency && (
								<Alert type="warning">
									{t('account.withdrawals.withdrawals.currencyWarning')}
								</Alert>
							)}
							{has2fa && (
								<TextField
									autocomplete="one-time-code"
									autoFocus={has2fa}
									onBlur={handleBlur}
									onChange={handleChange}
									error={
										errors.twoFactorCode
										&& touched.twoFactorCode
										&& t(errors.twoFactorCode)
									}
									id="twoFactorCode"
									name="twoFactorCode"
									label={t('account.withdrawals.withdrawals.confirmPopup.twoFactorAuthentication')}
									required={has2fa}
									type="text"
									inputMode="numeric"
									pattern="[0-9]*"
									value={values.twoFactorCode}
								/>
							)}
							<Button
								disabled={isSubmitting}
								isSubmit={confirm}
								label={t('account.withdrawals.withdrawals.confirmPopup.button')}
							/>
						</div>
					</Modal>
					<Modal
						isVisible={orderError}
						onClose={() => {
							setOrderError(false);
						}}
						title={t('account.withdrawals.withdrawals.error.title')}
					>
						<div className={styles.modal}>
							<p className={styles.modalText}>
								{t('account.withdrawals.withdrawals.error.text')}
							</p>
							<Button
								disabled={isSubmitting}
								label={t('account.withdrawals.withdrawals.error.button')}
								onClick={() => setOrderError(false)}
							/>
						</div>
					</Modal>
					<Button
						disabled={
							isSubmitting
							|| (values.type === 1 && (toInt(values.volume) || 0) <= 0)
							|| (currencySelect ? values.currency : currencyDefaultValue) === null
						}
						isSubmit={!confirm}
						label={t('account.withdrawals.withdrawals.confirm')}
						onClick={(e) => {
							e.preventDefault();
							if (values.type === 1) {
								checkVolume(
									currencySelect ? values.currency : currencyDefaultValue,
									values.volume,
								);
							} else {
								setConfirm(true);
							}
						}}
					/>
				</form>
			)}
		</Formik>
	);

	return (
		<Container>
			<div className={loading ? styles.loader : ''}>
				{loading ? <Loader /> : withdrawal}
			</div>
		</Container>
	);
}

PortfolioWithdrawal.propTypes = {
	currencies: PropTypes.arrayOf(PropTypes.shape({
		code: PropTypes.string.isRequired,
		codeShort: PropTypes.string.isRequired,
		codeShortBefore: PropTypes.bool.isRequired,
	}).isRequired),
	disabledAll: PropTypes.bool.isRequired,
	disabledVolume: PropTypes.bool.isRequired,
	pensionCheck: PropTypes.bool,
	portfolio: PropTypes.shape({
		child: PropTypes.shape({}),
		currency: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
	}).isRequired,
	setCheck: PropTypes.func.isRequired,
};

PortfolioWithdrawal.defaultProps = {
	currencies: null,
	pensionCheck: null,
};
