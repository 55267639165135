/* global navigator */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Alert from '../Alert';

import styles from './CopyLink.module.scss';

export default function CopyLink({
	label,
	link,
}) {
	const [t] = useTranslation();
	const [copied, setCopied] = useState(false);
	const onClick = (e) => {
		e.preventDefault();
		navigator.clipboard.writeText(link);
		setCopied(true);
	};

	return (
		<div className={styles.root}>
			<a
				href={link}
				className={styles.link}
				onClick={onClick}
			>
				{label}
			</a>
			{copied && (
				<div className={styles.alert}>
					<Alert
						autoClose
						close
						type="success"
						onClose={() => setCopied(false)}
					>
						{t('general.copied')}
					</Alert>
				</div>
			)}
		</div>
	);
}

CopyLink.propTypes = {
	label: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
};
